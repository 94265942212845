import { cloneDeep } from 'lodash'
import {
  SET_AR_ROW_COUNT,
  SET_SELECTED_ROWS_ID,
  SET_EDIT_MODE,
  SET_EDIT_VALUE,
  SET_UPDATING
} from './AccountReceivable.actionTypes'

export const INITIAL_STATE = {
  orders: [],
  totalRows: 0,
  selectedRowIds: [],
  selectedRows: [],
  isEditMode: false,
  editedValue: '',
  editRow: {},
  isUpdating: false
}

export default (state = cloneDeep(INITIAL_STATE), action = {}) => {
  switch (action.type) {

    case SET_AR_ROW_COUNT:
      return {
        ...state,
        totalRows: action.payload
      }

    case SET_SELECTED_ROWS_ID:
      const { selectedRowId, selectedRow } = action.payload
      return {
        ...state,
        selectedRowIds: selectedRowId,
        selectedRows: selectedRow
      }

    case SET_EDIT_MODE:
      const { isEdit, editRow } = action.payload
      return {
        ...state,
        isEditMode: isEdit,
        editRow: editRow
      }

    case SET_EDIT_VALUE:
      return {
        ...state,
        editedValue: action.payload
      }

    case SET_UPDATING:
      return {
        ...state,
        isUpdating: action.payload
      }

    default:
      return { ...state }
  }
}
