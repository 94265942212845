import {
  Box
} from "@mui/material"
import { withStyles } from "@mui/styles"
import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import ShipmentDetailsHeader from "./ShippmentDetailsHeader"
import { find, isEmpty, isArray } from "lodash"
import CollapsiblePanel from '../../components/CollapsiblePanel'
import SSHNonPaginatedTable from '../../components/SSHNonPaginatedTable'
import { ORDER_DETAILS_API_STATUS_LIST, SHIPPED_ITEM_STATUSES } from '../../config/genericConstants'


const styles = theme => ({
  panelAction: {
    fontFamily: 'canada-type-gibson',
    position: 'absolute',
    right: theme.spacing(4),
    marginTop: theme.spacing(2),
    width: `${theme.spacing(9)}%`
  }
})

class ShippedItems extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      hasShippedItems: false,
      items: []
    }
  }

  static columns = [
    { name: 'itemDesc', title: 'Item name', sorting: false },
    { name: 'itemNumber', title: 'Item ID', sorting: false },
    { name: 'skuId', title: 'SKU ID', sorting: false },
    { name: 'upcNumber', title: 'UPC', sorting: false },
    { name: 'orignalfulfilmentQty', title: 'Ordered', sorting: false },
    { name: 'shipped', title: 'Shipped', sorting: false },
    { name: 'giftInstructionText', title: 'Gift messages', sorting: false },
    // { name: 'Action', title: 'Action', sorting: false }
  ]

  static columnExtensions = [
    { columnName: 'itemDesc' },
    { columnName: 'itemNumber', width: 120, align: 'right' },
    { columnName: 'skuId', width: 120, align: 'right' },
    { columnName: 'upcNumber', width: 120, align: 'right' },
    { columnName: 'orignalfulfilmentQty', width: 120, align: 'right' },
    { columnName: 'shipped', width: 120, align: 'right' },
    { columnName: 'giftInstructionText' }
  ]

  static getDerivedStateFromProps = ({ releaseDetails }) => {
    if (!isEmpty(releaseDetails)) {
      const { shipmentDetails } = releaseDetails
      return {
        hasShippedItems: isArray(shipmentDetails) && shipmentDetails.length > 0
      }
    }
    return {
      hasShippedItems: false
    }
  }

  getShippedItems = ({ shipment }) => {
    const { releaseDetails, carrierMethods } = this.props

    const carrierMethod = find(carrierMethods, { carrierMethodId: shipment.carrierServiceCode })
    const newItems = []
    shipment?.shipmentLines.map(line => {
      const item = find(releaseDetails.itemDetails, (itemStatus) => {
        return itemStatus.primeLineNo === String(line.primeLineNo) &&
          (itemStatus.status === SHIPPED_ITEM_STATUSES[0] || itemStatus.status === ORDER_DETAILS_API_STATUS_LIST[3])
      }
      )
      newItems.push({
        ...item,
        shipped: line.poShipmentWeight.measurementValue
      })
    })

    const carrierMethodName = !isEmpty(carrierMethod) ? carrierMethod?.carrierMethodName : null

    return { items: newItems, carrierMethodName }
  }

  renderShippedItems = () => {
    const { releaseDetails, classes } = this.props

    return releaseDetails?.shipmentDetails.map((shipment, index) => {
      const { items, carrierMethodName } = this.getShippedItems({ shipment })
      return (
        <>
          <Box className={classes.panelAction}>
            <ShipmentDetailsHeader
              trackingNumber={shipment?.trackingNo}
              carrierMethodName={carrierMethodName}
              shipDate={shipment?.actualShipmentDate}
              releaseNumber={releaseDetails?.basicDetails?.releaseNumber}
              poNumber={releaseDetails?.basicDetails?.poNumber} />
          </Box>
          <Box className={classes.box}>
            <CollapsiblePanel
              title={`Shipment - ${index + 1}`}
              padding={4}
              defaultOpen={false}
              caption={`${items.length} items`}>
              <SSHNonPaginatedTable
                columns={ShippedItems.columns}
                rows={items}
                tableColumnExtensions={ShippedItems.columnExtensions}
              />
            </CollapsiblePanel>
          </Box>
        </>
      )
    })
  }

  render = () => {
    const { hasShippedItems } = this.state
    if (!hasShippedItems) {
      return null
    }

    return (
      <>
        {this.renderShippedItems()}
      </>
    )
  }
}

ShippedItems.propTypes = {
  title: PropTypes.string.isRequired,
  shipment: PropTypes.object.isRequired,
  itemDetails: PropTypes.array.isRequired,
  releaseNumber: PropTypes.string.isRequired,
  poNumber: PropTypes.string.isRequired,
  carrierMethods: PropTypes.array.isRequired
}

export default withStyles(styles)(ShippedItems)
