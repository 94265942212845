import { cloneDeep, isEmpty } from 'lodash'
import {
    FETCH_AGGREGATED_QTY_REQUEST,
    FETCH_AGGREGATED_QTY_SUCCESS,
    FETCH_AGGREGATED_QTY_FAILURE,
    FORECAST_FILTER_APPLIED,
    ENABLE_CHART_VIEW,
    RESET_FORECAST_FILTERS
} from './ClubForecastChart.types'
import { FILTER_KEYS } from '../../../../config/genericConstants'

const initialStateFilters = [{
    filterName: FILTER_KEYS.CATEGORY,
    filterValues: []
},
{
    filterName: FILTER_KEYS.CATEGORY_ID,
    filterValues: []
},
{
    filterName: FILTER_KEYS.SUBCATEGORY,
    filterValues: []
},
{
    filterName: FILTER_KEYS.SUBCATEGORY_NUM,
    filterValues: []
},
{
    filterName: FILTER_KEYS.SUPPLIER_ID,
    filterValues: []
},
{
    filterName: FILTER_KEYS.ITEM_ID,
    filterValues: []
}]

export const INITIAL_STATE = {
    isFetching: false,
    weekData: [],
    enableChartView: false,
    forecastFilterApplied: initialStateFilters
}

/**
 * @description get array formatted data week
 * @param {object} response 
 * @returns [{}]
 */
const getFormattedWeekData = (response) => {
    const { weeklyClubInventory } = response
    if (!isEmpty(weeklyClubInventory)) {
        return weeklyClubInventory.map(week => {
            return {
                sams: week?.samsForecastedQty,
                supplier: week?.supplierForecastedQty,
                walmartWeek: `week ${week?.walmartWeek}`,
                endDate: week?.weekEndDate,
                startDate: week?.weekStartDate
            }
        })
    }
    return []
}

/**
 * @description create applied filters array
 * @param {categories, subCategories, suppliers, itemId, state} 
 * @returns [{}] applied filters array
 */
const createFilters = ({ categories, subCategories, suppliers, itemId, state }) => {

    const filters = cloneDeep(state.forecastFilterApplied)
    const allCategorySelected = categories.find(val => val.id === 'All')
    const allSubCategorySelected = subCategories.find(val => val.id === 'All')
    const allSupplierSelected = suppliers.find(val => val.id === 'All')

    let catFilteredValues = filters?.find(key => key.filterName === FILTER_KEYS.CATEGORY).filterValues
    let catIdFilteredValues = filters?.find(key => key.filterName === FILTER_KEYS.CATEGORY_ID).filterValues

    let subcatFilteredValues = filters?.find(key => key.filterName === FILTER_KEYS.SUBCATEGORY).filterValues
    let subcatIdFilteredValues = filters?.find(key => key.filterName === FILTER_KEYS.SUBCATEGORY_NUM).filterValues

    let supplierFilteredValues = filters?.find(key => key.filterName === FILTER_KEYS.SUPPLIER_ID).filterValues
    let itemIdFilteredValues = filters?.find(key => key.filterName === FILTER_KEYS.ITEM_ID).filterValues

    /** always initiaize with empty array before adding new filters */
    catFilteredValues.length = 0
    catIdFilteredValues.length = 0
    subcatFilteredValues.length = 0
    subcatIdFilteredValues.length = 0
    supplierFilteredValues.length = 0
    itemIdFilteredValues.length = 0

    if (!isEmpty(itemId)) {
        if (itemId.includes(',')) {
            const itemIdArr = itemId.replace(/\s+/g, '')?.split(',')
            itemIdFilteredValues.push(...itemIdArr)
        } else {
            itemIdFilteredValues.push(itemId)
        }
    }

    if (!allCategorySelected) {
        categories.forEach(element => {
            catFilteredValues.push(element?.value)
            catIdFilteredValues.push(element?.id)
        })
    }

    if (!allSubCategorySelected) {
        subCategories.forEach(element => {
            subcatFilteredValues.push(element?.value)
            subcatIdFilteredValues.push(element?.number)
        })
    }

    if (!allSupplierSelected) {
        suppliers.forEach(element => {
            supplierFilteredValues.push(element?.id)
        })
    }

    return filters
}

export default (state = cloneDeep(INITIAL_STATE), action) => {
    switch (action.type) {
        case FETCH_AGGREGATED_QTY_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case FETCH_AGGREGATED_QTY_SUCCESS:
            const { response } = action
            return {
                ...state,
                isFetching: false,
                weekData: getFormattedWeekData(response)
            }
        case FETCH_AGGREGATED_QTY_FAILURE:
            return {
                ...state,
                isFetching: false,
            }

        case FORECAST_FILTER_APPLIED:
            const { categories, subCategories, suppliers, itemId } = action.payload
            return {
                ...state,
                forecastFilterApplied: createFilters({ categories, subCategories, suppliers, itemId, state })
            }

        case ENABLE_CHART_VIEW:
            const { enable } = action.payload
            return {
                ...state,
                enableChartView: enable
            }

        case RESET_FORECAST_FILTERS:
            return {
                ...state,
                forecastFilterApplied: initialStateFilters
            }

        default:
            return { ...state }
    }
}
