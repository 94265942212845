/* eslint-disable react/destructuring-assignment */
import { Table } from '@devexpress/dx-react-grid-material-ui'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Grid } from '@mui/material'
import SSHLabel from '../../components/SSHLabel'
import SSHButton from '../../components/SSHButton'
import { showToastMessage } from '../../reduxSetup/commonActions/AppConfig'
import { allowAccess } from '../../utils/common'
import { ROLE_BACK_ACTIONS } from '../../config/genericConstants'
import { updateAROlderMonthsOrders, setEditMode } from '../../pages/AccountReceivable/AccountReceivableActions'
import { ReactComponent as Check } from '../../assets/images/ApproveTick.svg'
import { ReactComponent as Close } from '../../assets/images/Remove.svg'
import { Edit } from '@mui/icons-material'
import { EDITED, WAIVER, APPROVE } from '../../pages/Approvals/commonConstants'
import SSHModal from '../../components/SSHModal'
import PopupActions from '../../templates/PopupActions'

const ApprovalEditAction = ({
    isCompletedView,
    showToastMessage,
    updateAROlderMonthsOrders,
    editMode,
    editRow,
    ...props
}) => {
    const [approved, setApproved] = React.useState(props.row.status === 'approved')
    const [rejected, setRejected] = React.useState(props.row.status === 'waived')
    const [openPopup, setOpenConfirmPopup] = React.useState(false)

    useEffect(() => {
        setRejected(props.row.status === 'waived')
        setApproved(props.row.status === 'approved')
    }, [props.row.status])

    const onReject = () => {
        updateAROlderMonthsOrders({ status: WAIVER, row: props.row })
        setOpenConfirmPopup(false)
    }
    const onApprove = () => updateAROlderMonthsOrders({ status: APPROVE, row: props.row })

    const toggleEditMode = (editRow) => {
        props.setEditMode({ isEdit: !isEmpty(editRow), editRow })
    }

    const onSubmit = () => {
        return updateAROlderMonthsOrders({ status: EDITED, row: props.row })
    }

    return (
        <Table.Cell align="center" {...props}>
            {allowAccess(ROLE_BACK_ACTIONS.UPDATE) &&
                !approved && !rejected && props.isAdmin &&
                (editRow?.taskId !== props.row?.taskId) &&
                !isCompletedView && (
                    <>
                        <SSHButton
                            text="Approve"
                            Icon={Check}
                            disabled={true}
                            style={{ marginRight: 8 }}
                            onClick={onApprove}
                        />
                        <SSHButton
                            text="Waive"
                            Icon={Close}
                            style={{ marginRight: 8 }}
                            onClick={() => setOpenConfirmPopup(true)}
                        />
                        <SSHButton
                            text="Edit"
                            Icon={Edit}
                            onClick={() => toggleEditMode(props.row)}
                        />
                    </>
                )}
            {approved && <SSHLabel value="Approved" />}
            {rejected && <SSHLabel value="Waived" />}
            {!approved && !rejected && isCompletedView && (
                <SSHLabel value={props.row.status} />
            )}
            {(editRow?.taskId === props.row?.taskId) && <>
                <SSHButton
                    text="Save"
                    variant='containedPrimary'
                    style={{ marginRight: 8, padding: '3px 9px' }}
                    onClick={onSubmit} />
                <SSHButton
                    text="Cancel"
                    variant='outlined'
                    onClick={() => toggleEditMode({})} />
            </>}
            {openPopup && <SSHModal
                title={`Do you want to waive this penalty?`}
                showDivider={false}
                onClose={() => setOpenConfirmPopup(false)}
                size="sm"
                actions={
                    <PopupActions
                        alignRight
                        confirmText="Yes"
                        onConfirm={onReject}
                        cancelText="No"
                        onCancel={() => setOpenConfirmPopup(false)}
                    />
                }>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        The penalties for this month will be waived. The penalties will be $0 for the supplier for this month.
                    </Grid>
                </Grid></SSHModal>}
        </Table.Cell>
    )
}

ApprovalEditAction.propTypes = {
    isCompletedView: PropTypes.bool.isRequired,
    showToastMessage: PropTypes.func.isRequired,
    updateAROlderMonthsOrders: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired
}

export const ApprovalEditActionNaked = ApprovalEditAction

const mapStateToProps = state => ({
    isCompletedView: state.Approvals.isCompletedView,
    isAdmin: state.UserInfo.isAdmin,
    editMode: state.AccountReceivable.isEditMode,
    editRow: state.AccountReceivable.editRow
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { showToastMessage, updateAROlderMonthsOrders, setEditMode },
        dispatch
    )
export const ApprovalEditActionCell = connect(mapStateToProps, mapDispatchToProps)(ApprovalEditAction)
