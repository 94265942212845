import { Table } from '@devexpress/dx-react-grid-material-ui'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toggleEditPopup } from '../../pages/OrderPage/OrderDetailsActions'
import EditIcon from '@mui/icons-material/Edit'
import SSHButton from '../../components/SSHButton'
import { allowAccess } from "../../utils/common"
import { ROLE_BACK_ACTIONS } from "../../config/genericConstants"
import { PO_WAITING_MESSAGE } from '../../config/genericConstantsNew'

class CancelEditCell extends Component {

  toggleEditPopup = () => {
    const { toggleEditPopup, row } = this.props
    toggleEditPopup(row.itemNumber)
  }

  render = () => {
    const { legacyPOExists } = this.props
    return (
      <Table.Cell {...this.props} align='center'>
        {allowAccess(ROLE_BACK_ACTIONS.EDIT) &&
          <SSHButton Icon={EditIcon}
            tooltip={legacyPOExists ? 'Change item quantity or cancel' : PO_WAITING_MESSAGE}
            disabled={!legacyPOExists}
            onClick={this.toggleEditPopup} />}
      </Table.Cell>
    )
  }
}

CancelEditCell.propTypes = {
  classes: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  toggleEditPopup: PropTypes.func.isRequired,
  legacyPOExists: PropTypes.bool.isRequired
}

export const CancelEditCellNaked = CancelEditCell

const mapStateToProps = state => ({
  legacyPOExists: state.OrderDetailsReducer.legacyPOExists
})

const mapDispatchToProps = dispatch => bindActionCreators({ toggleEditPopup }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CancelEditCell)
