import { Slide, Box, Alert } from '@mui/material'
import { withStyles } from '@mui/styles'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { closeAlert } from './ErrorHandlerActions'
import { capitalizeSentence } from '../../utils/common'
import clsx from 'clsx'

const style = theme => ({
  box: {
    position: 'fixed',
    top: 0,
    zIndex: 9999,
    width: '97%'
  },
  alert: {
    fontSize: theme.spacing(2),
    borderBottom: `1px solid`

  },
  error: {
    color: theme.palette.primary.downloadLabelText,
    'border-bottom-color': theme.palette.primary.notificationBadge,
    backgroundColor: theme.palette.primary.errorRed,
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`
  },
  success: {
    color: theme.palette.primary.downloadLabelText,
    'border-bottom-color': theme.palette.primary.springGreen,
    backgroundColor: theme.palette.primary.successGreen
  }
})

const ErrorDisplay = ({ errorMessage, showAlert, closeAlert, isInfo, isSuccess, classes }) => {
  if (!showAlert || !errorMessage) return null

  let severity = 'error'
  if (isInfo) {
    severity = 'info'
  } else if (isSuccess) {
    severity = 'success'
  }


  const STYLES = () => {
    return clsx(classes.alert, severity === 'error' ? classes.error : classes.success)
  }

  return (
    <Slide direction="down" in mountOnEnter unmountOnExit>
      <Box p={0} className={classes.box}>
        <>
          <Alert onClose={closeAlert} severity={severity} className={STYLES()} >
            {capitalizeSentence(severity)}: {errorMessage}
          </Alert>
        </>
      </Box>
    </Slide>
  )
}

const mapStateToProps = state => ({
  errorMessage: state.ErrorHandler.errMsg,
  showAlert: state.ErrorHandler.showAlert,
  isInfo: state.ErrorHandler.isInfo,
  isSuccess: state.ErrorHandler.isSuccess,
  pathName: state.router.location.pathname
})

const mapDispatchToProps = dispatch => bindActionCreators({ closeAlert }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ErrorDisplay))
