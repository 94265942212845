export const COMMON_CONFIG = {
  host: process.env.REACT_APP_URL,
  proxyURL: process.env.REACT_APP_API_PROXY_URL,
  retailLink: 'https://retaillink.login.stg.wal-mart.com/',
  clientId: process.env.REACT_APP_FALCON_CLIENT_ID,
  atmtAppId: process.env.REACT_APP_ATMT_APP_ID,
  auth: process.env.REACT_APP_AUTH_API,
  carriers:process.env.REACT_APP_CARRIER_LIST_NAMES
}

export const APP_CONFIG = {
  ENV: (env = process.env.REACT_APP_ENV) => {
    const AVAILABLE_ENVS = {
      LOCAL: {
        host: 'http://vb.samsclublocal.com',
        appInsightsKey: '02356f48-1ed6-4dab-83e3-ab625b999d87',
        ...COMMON_CONFIG
      },
      DEV1: {
        appInsightsKey: '02356f48-1ed6-4dab-83e3-ab625b999d87',
        ...COMMON_CONFIG
      },
      DEV2: {
        appInsightsKey: 'b3a6954e-3f38-4b9d-bc19-39a6f024a417',
        ...COMMON_CONFIG
      },
      QA: {
        appInsightsKey: 'f17b0cd3-1fbe-44ca-8963-584c6f305797',
        ...COMMON_CONFIG
      },
      PROD: {
        host: process.env.REACT_APP_URL,
        proxyURL: process.env.REACT_APP_API_PROXY_URL,
        retailLink: 'https://retaillink.login.wal-mart.com/',
        appInsightsKey: '5e1b2534-4508-4274-90e1-afc961bcbbe7',
        clientId: process.env.REACT_APP_FALCON_CLIENT_ID,
        atmtAppId: process.env.REACT_APP_ATMT_APP_ID,
        auth: process.env.REACT_APP_AUTH_API,
        carriers:process.env.REACT_APP_CARRIER_LIST_NAMES
      }
    }
    return AVAILABLE_ENVS[env] || AVAILABLE_ENVS.LOCAL
  },
  COOKIE_NAME: 'userInfo'
}
