import { filter, findIndex, find } from 'lodash'
import config from '../../config'
// eslint-disable-next-line import/no-cycle
import { showToastMessage, hideToastMessage } from '../../reduxSetup/commonActions/AppConfig'
import {
  FETCH_PAGINATED_TABLE_DATA_FAILURE,
  FETCH_PAGINATED_TABLE_DATA_REQUEST,
  FETCH_PAGINATED_TABLE_DATA_SUCCESS
} from '../../reduxSetup/commonActions/SSHPaginatedTable.actionTypes'
import { aiInventoryEditSave, aiInventorySearch } from '../../reduxSetup/commonActions/TelemetryActions'
import { CALL_API } from '../../reduxSetup/middleware/API'
import { getShipNodeIdFromState, getVendorIdFromState } from '../../utils/common'
import CustomError from '../../utils/CustomError'
import {
  SEARCH_INVENTORY_ITEM_FAILURE,
  SEARCH_INVENTORY_ITEM_REQUEST,
  SEARCH_INVENTORY_ITEM_SUCCESS,
  SET_INVENTORY_ITEM_COUNT,
  UPDATE_INVENTORY_QUANTITY_FAILURE,
  UPDATE_INVENTORY_QUANTITY_REDUX,
  UPDATE_INVENTORY_QUANTITY_REQUEST,
  UPDATE_INVENTORY_QUANTITY_SUCCESS,
  SET_INVENTORY_TAB,
  UPDATE_INVENTORY_INBOUND_REQUEST,
  UPDATE_INVENTORY_INBOUND_SUCCESS,
  UPDATE_INVENTORY_INBOUND_FAILURE,
  SET_WEEK_INBOUND_QTY,
  RESET_WEEK_INBOUND_QTY
} from './Inventory.types'
import { INVENTORY_UPDATE_ERROR } from '../../config/errorConstants'

export const fetchInventoryItems = () => (dispatch, getState) => {
  const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
  const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId

  const IS_SEARCH = getState().SSHPaginatedTableReducer.searchQuery

  if (IS_SEARCH) {
    return null
  }

  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_PAGINATED_TABLE_DATA_REQUEST,
        FETCH_PAGINATED_TABLE_DATA_SUCCESS,
        FETCH_PAGINATED_TABLE_DATA_FAILURE
      ],
      endpoint: `${config.get('inventoryListItems')}/${VENDOR_ID}/shipNode/${SHIP_NODE_ID}?pageNumber=${PAGE_NO +
        1}&pageSize=${PAGE_SIZE}`,
      method: 'GET',
      payload: {
        vendorId: VENDOR_ID,
        shipNodeId: SHIP_NODE_ID,
        PAGE_SIZE,
        PAGE_NO,
        pathName: getState().router.location.pathname
      }
    }
  }).then(({ type, response }) => {
    if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS) {
      return dispatch(setInventoryItemCount({ count: response?.payload?.totalRecords || 0 }))
    }
    return false
  })
}

export const setInventoryItemCount = ({ count }) => dispatch => {
  return dispatch({
    type: SET_INVENTORY_ITEM_COUNT,
    payload: { count }
  })
}

export const updateInventoryBackend = ({ itemDetails, value }) => (dispatch, getState) => {

  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId

  const requestObj = {
    itemId: itemDetails.itemId,
    win: itemDetails.win,
    vendorStockId: itemDetails.vendorStockId,
    NewQuantity: value,
    CurrentQuantity: itemDetails.availableInventory,
    vendorId: VENDOR_ID,
    shipNodeId: SHIP_NODE_ID
  }

  return dispatch({
    [CALL_API]: {
      types: [UPDATE_INVENTORY_QUANTITY_REQUEST,
        UPDATE_INVENTORY_QUANTITY_SUCCESS,
        UPDATE_INVENTORY_QUANTITY_FAILURE],
      endpoint: `${config.get('inventoryItemUpdate')}`,
      method: 'POST',
      data: requestObj,
      payload: { ...requestObj }
    }
  })
    .then(({ type, response }) => {
      dispatch(aiInventoryEditSave({ itemId: itemDetails.itemId }))
      if (type === UPDATE_INVENTORY_QUANTITY_SUCCESS) {
        if (response.payload.success === 'Y') {
          return dispatch(
            updateInventoryUI({
              itemId: response.payload.itemId,
              availableInventory: response.payload.availableInventory
            })
          )
        }
        throw new CustomError(INVENTORY_UPDATE_ERROR, response.payload)
      }
      return Promise.resolve()
    })
    .catch(() => {
      return dispatch(showToastMessage({ message: INVENTORY_UPDATE_ERROR }))
    })
}

export const updateInventoryUI = ({ itemId, availableInventory }) => dispatch => {
  return dispatch({
    type: UPDATE_INVENTORY_QUANTITY_REDUX,
    payload: { itemId, availableInventory }
  })
}

export const searchInventoryItem = ({ searchQuery }) => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId

  return dispatch({
    [CALL_API]: {
      types: [SEARCH_INVENTORY_ITEM_REQUEST, SEARCH_INVENTORY_ITEM_SUCCESS, SEARCH_INVENTORY_ITEM_FAILURE],
      endpoint: `${config.get('inventoryItemSearch')}/${VENDOR_ID}/shipNode/${SHIP_NODE_ID}?WIN=${searchQuery}`,
      method: 'GET',
      payload: { vendorId: VENDOR_ID, shipNodeId: SHIP_NODE_ID, itemNumber: searchQuery }
    }
  }).then(({ type, response }) => {
    dispatch(aiInventorySearch())
    if (type === SEARCH_INVENTORY_ITEM_SUCCESS) {
      return dispatch(setInventoryItemCount({ count: response.payload.itemList.length }))
    }
    return null
  })
}

/**
 * @description Sets the tab active according to the passed tabName
 * @param {String} tabName Selected tab name
 */
export const setInventoryTabActive = ({ tabName }) => dispatch => {
  dispatch({
    type: SET_INVENTORY_TAB,
    payload: { tabName }
  })
}


/**
 * @description update inbound inventory
 * @param {*} {itemDetails ,inBoundWeek }
 * @returns {*}
 */
export const updateInboundInventory = ({ row }) => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId

  const INBOUND_LIST = getState().InventoryNew.inboundList
  INBOUND_LIST.map(val => {
    if (isNaN(val.quantity)) {
      val.quantity = 0
    }
    return val
  })

  const requestObj = {
    itemId: row.itemId,
    win: row.win,
    vendorStockId: row.vendorStockId,
    NewQuantity: row.availableInventory,
    CurrentQuantity: row.availableInventory,
    vendorId: VENDOR_ID,
    shipNodeId: SHIP_NODE_ID,
    inbounds: filter(INBOUND_LIST, { win: row.win }) || []
  }

  return dispatch({
    [CALL_API]: {
      types: [UPDATE_INVENTORY_INBOUND_REQUEST,
        UPDATE_INVENTORY_INBOUND_SUCCESS,
        UPDATE_INVENTORY_INBOUND_FAILURE],
      endpoint: `${config.get('inventoryItemUpdate')}`,
      method: 'POST',
      data: requestObj,
      payload: { ...requestObj }
    }
  })
    .then(({ type, response }) => {
      const { sourceType } = getState().AppConfig
      const sourceTypeSelected = find(sourceType?.type, { 'id': sourceType?.selected })?.source
      dispatch(aiInventoryEditSave({ itemId: row.win, sourceType: sourceTypeSelected }))
      if (type === UPDATE_INVENTORY_INBOUND_SUCCESS) {
        dispatch(resetInboundWeek({ win: row.win }))
        if (response.payload.success === 'Y') {
          dispatch(fetchInventoryItems())
          dispatch(showToastMessage({ message: 'Changes saved successfully.' }))
          setTimeout(() => {
            return dispatch(hideToastMessage())
          }, 2000)
        } else {
          throw new CustomError(INVENTORY_UPDATE_ERROR, response.payload)
        }
      }
      return Promise.resolve()
    })
    .catch(() => {
      return dispatch(showToastMessage({ message: INVENTORY_UPDATE_ERROR }))
    })
}

/**
 * @description update inbound week for replenishment
 * @param {*} param0
 * @returns {*}
 */
export const updateInboundWeek = ({ inboundWeek }) => (dispatch, getState) => {

  const INBOUND_LIST = getState().InventoryNew.inboundList

  const index = findIndex(INBOUND_LIST, { win: inboundWeek.win, walmartWeek: inboundWeek.walmartWeek })

  if (index >= 0) {
    INBOUND_LIST[index].quantity = inboundWeek.quantity
  } else {
    INBOUND_LIST.push({
      walmartWeek: inboundWeek.walmartWeek,
      quantity: inboundWeek.quantity,
      weekStartDate: inboundWeek.weekStartDate,
      weekEndDate: inboundWeek.weekEndDate,
      win: inboundWeek.win
    })
  }
  dispatch({
    type: SET_WEEK_INBOUND_QTY,
    payload: {
      inbound: INBOUND_LIST
    }
  })
}

export const resetInboundWeek = ({ win }) => (dispatch) => {
  dispatch({
    type: RESET_WEEK_INBOUND_QTY,
    payload: { win }
  })
}

