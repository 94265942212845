/* eslint-disable no-lonely-if */
import { Grid, Typography, FormControl, RadioGroup, FormControlLabel } from '@mui/material'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import SSHModal from '../../../components/SSHModal'
import PopupActions from '../../../templates/PopupActions'
import { SSHRadio } from '../../../components/SSHRadio'

class ReflectCarrierTracking extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            isAll: true
        }
    }

    /**
     * @description toggle is all or individual
     */
    tggleIsAll = () => {
        const { isAll } = this.state
        this.setState({ isAll: !isAll })
    }

    render = () => {
        const { closeReflectAll,saveReflectAll } = this.props
        const { isAll } = this.state

        return (
            <SSHModal
                title="Change return info"
                onClose={closeReflectAll}
                size="sm"
                showDivider={false}
                actions={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <PopupActions
                        isLoading={false}
                        alignRight
                        confirmText='Save'
                        cancelText='Cancel'
                        onCancel={()=>closeReflectAll(false)}
                        onConfirm={() => saveReflectAll(isAll)}
                    />
                }>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography >
                            Please select an option on how do you want to apply these return info for other line items in this order.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl component="change-return-info">
                            <RadioGroup
                                aria-label="change-return-info"
                                name="returnInfo"
                                value={isAll ? 1 : 0}
                                onChange={this.tggleIsAll} column>
                                <FormControlLabel
                                    value={1}
                                    disabled={false}
                                    control={<SSHRadio color="primary" />}
                                    label={<Typography variant="body2">Common for all line items in this order</Typography>}
                                />
                                <FormControlLabel
                                    value={0}
                                    disabled={false}
                                    control={<SSHRadio color="primary" />}
                                    label={[
                                        <Typography variant="body2">
                                            Individual at line item level
                                        </Typography>
                                    ]}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </SSHModal>
        )
    }
}

ReflectCarrierTracking.propTypes = {
    closeReflectAll:PropTypes.func.isRequired,
    saveReflectAll:PropTypes.func.isRequired
}

export default ReflectCarrierTracking

export const ReflectCarrierTrackingNaked = ReflectCarrierTracking
