export const SEARCH_INTRANSIT_ITEM_REQUEST = 'SEARCH_INTRANSIT_ITEM_REQUEST'
export const SEARCH_INTRANSIT_ITEM_SUCCESS = 'SEARCH_INTRANSIT_ITEM_SUCCESS'
export const SEARCH_INTRANSIT_ITEM_FAILURE = 'SEARCH_INTRANSIT_ITEM_FAILURE'
export const INTRANSIT_UPDATE_REQUEST = 'INTRANSIT_UPDATE_REQUEST'
export const INTRANSIT_UPDATE_SUCCESS = 'INTRANSIT_UPDATE_SUCCESS'
export const INTRANSIT_UPDATE_FAILURE = 'INTRANSIT_UPDATE_FAILURE'
export const SET_INTRANSIT_ITEM_COUNT = 'SET_INTRANSIT_ITEM_COUNT'
export const SET_ITEM_INVENTORY_PAGE = 'SET_ITEM_INVENTORY_PAGE'
export const SET_EDIT_TABLE = 'SET_EDIT_TABLE'
export const SET_FILTER_OPEN = 'SET_FILTER_OPEN'
export const SET_REQUEST_PAYLOAD = 'SET_REQUEST_PAYLOAD'
export const CLEAR_REQUEST_PAYLOAD = 'CLEAR_REQUEST_PAYLOAD'
export const SET_FILTER_PARAMS = 'SET_FILTER_PARAMS'
export const CLEAR_FILTER_PARAMS = 'CLEAR_FILTER_PARAMS'
export const SET_EDIT_CALENDAR = 'SET_EDIT_CALENDAR'
export const RESET_ITEM_DETAILS = 'RESET_ITEM_DETAILS'
