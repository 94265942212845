import React from 'react'
import ATMTFeedback from './Feedback'

const SSHAtmt = ({ toggleFeedback, isToggleAtmtFeedback, loginId, iamToken }) => {
    return (
        <>
            <ATMTFeedback
                toggleFeedback={toggleFeedback}
                isToggleAtmtFeedback={isToggleAtmtFeedback}
                loginId={loginId}
                iamToken={iamToken} />
        </>
    )
}

export default SSHAtmt
