import React from 'react'
import { Box, Typography, Divider } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { capitalize } from 'lodash'
import { Refresh } from '@mui/icons-material'
import clsx from 'clsx'
import SSHButton from '../../components/SSHButton'
import SSHTooltip from '../../components/SSHTooltip'
import { closeNotificationDrawer } from './NotificationDrawerActions'
import { getExportFile, retryFailedExportFile } from '../../reduxSetup/commonActions/AppConfig'
import { ReactComponent as ReportIcon } from '../../assets/images/Xls_file_export.svg'
import { ReactComponent as DownloadIcon } from '../../assets/images/Download.svg'
import { SSHTypography } from '../../components/SSHTypography'
import { MODULE_TYPES } from '../../config/genericConstants'

const DOWNLOAD_REPORT = 'Download report'
const TRY_AGAIN = 'Try again'

const COMPLETED = 'COMPLETED'
const FAILED = 'FAILED'

const styles = theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  fileIconAndName: {
    gap: theme.spacing(0.5)
  },
  fileName: {
    fontSize: '16px',
    color: theme.palette.primary.blackPearl
  },
  fileDetails: {
    marginLeft: theme.spacing(3.125),
    color: theme.palette.primary.blackPearl,
    fontSize: '15px'
  },
  divider: {
    marginTop: theme.spacing(1.875),
    backgroundColor: theme.palette.background.magnolia,
    height: '2px'
  },
  action: {
    fontSize: theme.spacing(1.875),
    marginLeft: theme.spacing(2.5),
    justifyContent: 'flex-start',
    color: theme.palette.primary.blue,
    fontWeight: 600,
    width: 'fit-content'
  },
  status: {
    borderRadius: '3px',
    padding: `0px ${theme.spacing(1)}`,
    fontSize: theme.spacing(1.85)
  },
  successStatus: {
    color: theme.palette.primary.springGreen,
    backgroundColor: theme.palette.primary.successGreen
  },
  progressStatus: {
    color: theme.palette.primary.blueDark,
    backgroundColor: theme.palette.primary.tumbleWeed
  },
  errorStatus: {
    color: theme.palette.primary.notificationBadge,
    backgroundColor: theme.palette.primary.errorRed
  },
  reportIcon: {
    marginTop: theme.spacing(0.5)
  }
})

const NotificationItem = ({
  fileName,
  requestNumber,
  supplierName,
  shipNodeId,
  module,
  requestedTime,
  isVendor,
  status,
  classes,
  getExportFile,
  retryFailedExportFile,
  downloadInProgress,
  closeNotificationDrawer
}) => {
  const [action, setAction] = React.useState('')
  const [statusText, setStatusText] = React.useState('In progress')

  const setActionAndStatus = () => {
    switch (status) {
      case COMPLETED: {
        setAction(DOWNLOAD_REPORT)
        setStatusText('Success')
        break
      }

      case FAILED: {
        setAction(TRY_AGAIN)
        setStatusText('Error')
        break
      }
      default: {
        setAction('')
        setStatusText('In progress')
      }
    }
  }

  React.useEffect(setActionAndStatus, [status])

  const handleDownloadOrRetry = () => {
    const ackId = {
      ackId: requestNumber,
      status,
      fileName: [null, undefined].includes(fileName)
        ? ''
        : fileName.substr(fileName.lastIndexOf('/') + 1, fileName.length)
    }
    if (action === DOWNLOAD_REPORT && !downloadInProgress[requestNumber]) {
      getExportFile({ ackId, actionTrigger: MODULE_TYPES.notification })
    } else if (action === TRY_AGAIN) {
      retryFailedExportFile({ ackId, actionTrigger: MODULE_TYPES.notification, taskType: module })
      closeNotificationDrawer()
    }
  }

  const getStatusStyle = () => {
    if (status === COMPLETED) {
      return classes.successStatus
    }
    if (status === FAILED) {
      return classes.errorStatus
    }
    return classes.progressStatus
  }

  const reTryBtn = action === TRY_AGAIN ? (
    <SSHButton text={TRY_AGAIN} Icon={Refresh} className={classes.action} onClick={handleDownloadOrRetry} />
  ) : null

  return (
    <Box component="div" display="flex" flexDirection="column" className={classes.container}>
      <Box component="div" display="flex" alignItems="center" className={classes.fileIconAndName}>
        <ReportIcon className={classes.reportIcon} />
        <SSHTooltip title={requestNumber} placement="top">
          <SSHTypography className={classes.fileName} variant="h6">
            Request #{requestNumber}
          </SSHTypography>
        </SSHTooltip>
      </Box>
      <SSHTooltip
        title={
          [null, undefined].includes(fileName) ? '--' : fileName.substr(fileName.lastIndexOf('/') + 1, fileName.length)
        }
        placement="top">
        <SSHTypography className={classes.fileDetails} variant="h6">
          File name:{' '}
          {[null, undefined].includes(fileName)
            ? '--'
            : fileName.substr(fileName.lastIndexOf('/') + 1, fileName.length)}
        </SSHTypography>
      </SSHTooltip>
      {!isVendor && (
        <Typography className={classes.fileDetails} variant="h6">
          Supplier: {supplierName ?? '--'}
        </Typography>
      )}
      <Typography className={classes.fileDetails} variant="h6">
        Ship node:{' '}
        {shipNodeId &&
          module !== capitalize(MODULE_TYPES.performance) &&
          module !== capitalize(MODULE_TYPES.leadTimeApproval)
          ? shipNodeId
          : '--'}
      </Typography>
      <Typography className={classes.fileDetails} variant="h6">
        Module: {module === capitalize(MODULE_TYPES.leadTimeApproval) ? 'Lead Time' : module}
      </Typography>
      <Typography className={classes.fileDetails} variant="h6">
        Requested time: {requestedTime}
      </Typography>
      <Box display="flex" alignItems="center" style={{ gap: '10px' }}>
        <Typography className={classes.fileDetails} variant="h6">
          Current status:
        </Typography>
        <Typography className={clsx(classes.status, getStatusStyle())}>{statusText}</Typography>
      </Box>
      {// eslint-disable-next-line no-nested-ternary
        action === DOWNLOAD_REPORT ? (
          <SSHButton
            loading={downloadInProgress[requestNumber]}
            text={action}
            Icon={DownloadIcon}
            className={classes.action}
            onClick={handleDownloadOrRetry}
          />
        ) : reTryBtn}
      <Divider variant="middle" className={classes.divider} />
    </Box>
  )
}

NotificationItem.propTypes = {
  fileName: PropTypes.string.isRequired,
  requestNumber: PropTypes.string.isRequired,
  supplierName: PropTypes.string.isRequired,
  shipNodeId: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  requestedTime: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  isVendor: PropTypes.bool.isRequired,
  getExportFile: PropTypes.func.isRequired,
  retryFailedExportFile: PropTypes.func.isRequired
}

export const NotificationItemNaked = NotificationItem

const mapStateToProps = state => ({
  downloadInProgress: state.ReportsRequests.downloadInProgress
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ closeNotificationDrawer, getExportFile, retryFailedExportFile }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotificationItem))
