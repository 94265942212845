export const SET_ORDERS_LIST_FOR_DETAILS = 'SET_ORDERS_LIST_FOR_DETAILS'
export const SET_CURRENT_ORDER_DETAILS_INDEX = 'SET_CURRENT_ORDER_DETAILS_INDEX'
export const RESET_ORDER_DETAILS = 'RESET_ORDER_DETAILS'

export const FETCH_ORDER_DETAILS_REQUEST = 'FETCH_ORDER_DETAILS_REQUEST'
export const FETCH_ORDER_DETAILS_FAILURE = 'FETCH_ORDER_DETAILS_FAILURE'
export const FETCH_ORDER_DETAILS_SUCCESS = 'FETCH_ORDER_DETAILS_SUCCESS'

export const UPDATE_FULFILLMENT_RELEASE_REQUEST = 'UPDATE_FULFILLMENT_RELEASE_REQUEST'
export const UPDATE_FULFILLMENT_RELEASE_SUCCESS = 'UPDATE_FULFILLMENT_RELEASE_SUCCESS'
export const UPDATE_FULFILLMENT_RELEASE_FAILURE = 'UPDATE_FULFILLMENT_RELEASE_FAILURE'

export const MARK_ITEM_AS_SELECTED = 'MARK_ITEM_AS_SELECTED'
export const SELECT_CARRIER_METHOD = 'SELECT_CARRIER_METHOD'
export const SET_TRACKING_NUMBER = 'SET_TRACKING_NUMBER'
export const SET_ITEM_SHIP_QUANTITY = 'SET_ITEM_SHIP_QUANTITY'
export const SHOW_HIDE_SHIPMENT_POPUP = 'SHOW_HIDE_SHIPMENT_POPUP'
export const SHOW_HIDE_LABEL_GEN_ERROR_POPUP = 'SHOW_HIDE_LABEL_GEN_ERROR_POPUP'
export const SET_CANCELLED_UNITS = 'SET_CANCELLED_UNITS'
export const SET_SHIPMENT_FLAGS = 'SET_SHIPMENT_FLAGS'

export const SHIP_ITEMS_DISPATCHER_REQUEST = 'SHIP_ITEMS_DISPATCHER_REQUEST'
export const SHIP_ITEMS_DISPATCHER_SUCCESS = 'SHIP_ITEMS_DISPATCHER_SUCCESS'
export const SHIP_ITEMS_DISPATCHER_FAILURE = 'SHIP_ITEMS_DISPATCHER_FAILURE'

export const GET_SHIP_LABEL_REQUEST = 'GET_SHIP_LABEL_REQUEST'
export const GET_SHIP_LABEL_SUCCESS = 'GET_SHIP_LABEL_SUCCESS'
export const GET_SHIP_LABEL_FAILURE = 'GET_SHIP_LABEL_FAILURE'



export const TOGGLE_EDIT_POPUP = 'TOGGLE_EDIT_POPUP'

export const FORCE_UPDATE = 'FORCE_UPDATE'
