import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Grid, IconButton, TextField, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { isEmpty, omit } from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import clsx from 'clsx'
import SSHLoadingAnimation from '../../components/SSHLoadingAnimation'
import SSHTooltip from '../../components/SSHTooltip'
import { updateInventoryBackend } from '../../pages/Inventory/InventoryAction'
import {
  aiInventoryEditCancel,
  aiInventoryEditSave,
  aiInventoryEditStart
} from '../../reduxSetup/commonActions/TelemetryActions'
import { allowAccess } from '../../utils/common'
import { ROLE_BACK_ACTIONS } from '../../config/genericConstants'
import { EditIcon } from '../LeftNav/ColoredSvg'
import { ReactComponent as Refuse } from '../../assets/images/Refuse.svg'
import { ReactComponent as Accept } from '../../assets/images/Accept.svg'

const styles = theme => ({
  iconBtn: {
    padding: 3,
    fontSize: '1.4em',
    lineHeight: '1em',
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.baseBlue
  },
  textField: {
    width: 80
  },
  actions: { flex: 1, display: 'flex', justifyContent: 'center' },
  count: { flex: 1 }
})

const EditableCell = ({ classes, error, isSaving, restProps, toggleEditMode, onSave }) => {
  const inputField = useRef()
  let errorText = null
  let hasError = !!error
  const onSubmit = () => {
    errorText = 'Unable to update inventory'

    const { value } = inputField.current
    if (String(value).length === 0 || !isEmpty(String(value).match(/\D/))) {
      hasError = true
      errorText = 'Only digits are allowed'
      return false
    }
    return onSave({ itemDetails: restProps.row, value })
  }

  return (
    <Table.Cell {...restProps}>
      <Grid container justifyContent="flex-start" alignItems="flex-start">
        <Grid item>
          <TextField
            error={!!hasError}
            helperText={hasError ? errorText : null}
            classes={{ root: classes.textField }}
            type="number"
            required
            size="small"
            onKeyDown={event => ['e', 'E', '+', '-', '.'].includes(event.key) && event.preventDefault()}
            defaultValue={restProps.value}
            inputRef={inputField}
            InputLabelProps={{
              shrink: false
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item className={classes.actions}>
          {isSaving ? (
            <IconButton className={classes.iconBtn} disabled>
              <SSHLoadingAnimation size={20} thickness={5} />
            </IconButton>
          ) : (
            <>
              <SSHTooltip title="Save">
                <IconButton className={clsx(classes.iconBtn, 'save')} onClick={onSubmit} disabled={isSaving}>
                  <Accept fontSize="inherit" />
                </IconButton>
              </SSHTooltip>
              <SSHTooltip title="Cancel">
                <IconButton className={clsx(classes.iconBtn, 'cancel')} onClick={toggleEditMode} disabled={isSaving}>
                  <Refuse />
                </IconButton>
              </SSHTooltip>
            </>
          )}
        </Grid>
      </Grid>
    </Table.Cell>
  )
}

const NonEditableCell = ({ classes, restProps, toggleEditMode }) => {
  return (
    <Table.Cell {...restProps}>
      <Grid container alignItems="center">
        <Grid item className={classes.count}>
          <Typography variant="body2">{restProps.value}</Typography>
        </Grid>
        <Grid item className={classes.actions}>
          {allowAccess(ROLE_BACK_ACTIONS.EDIT) && (
            <SSHTooltip title="Edit Inventory">
              <IconButton className={classes.iconBtn} onClick={toggleEditMode}>
                <EditIcon />
              </IconButton>
            </SSHTooltip>
          )}
        </Grid>
      </Grid>
    </Table.Cell>
  )
}

class EditableCellContainer extends PureComponent {
  // eslint-disable-next-line react/state-in-constructor
  state = { isSaving: false, error: false, editMode: false }

  toggleEditMode = () => {
    const { editMode } = this.state
    const { aiInventoryEditStart, aiInventoryEditCancel, row } = this.props
    this.setState({ editMode: !editMode, error: false, isSaving: false })
    if (editMode) return aiInventoryEditCancel({ itemId: row.itemId })
    return aiInventoryEditStart({ itemId: row.itemId })
  }

  handleSave = requestObj => {
    this.setState({ isSaving: true, error: false })
    // eslint-disable-next-line react/destructuring-assignment
    this.props
      .updateInventoryBackend(requestObj)
      .then(() => {
        this.setState({ isSaving: false, editMode: false })
      })
      .catch(() => {
        this.setState({ isSaving: false, error: true })
      })
  }

  render = () => {
    const { editMode, isSaving, error } = this.state
    const { classes } = this.props
    const restProps = omit(this.props, ['classes', 'updateInventoryBackend'])

    if (editMode) {
      return (
        <>
          <EditableCell
            isSaving={isSaving}
            classes={classes}
            error={error}
            restProps={restProps}
            // eslint-disable-next-line react/jsx-no-bind
            toggleEditMode={this.toggleEditMode.bind(this)}
            // eslint-disable-next-line react/jsx-no-bind
            onSave={this.handleSave.bind(this)}
          />
        </>
      )
    }
    // eslint-disable-next-line react/jsx-no-bind
    return <NonEditableCell classes={classes} restProps={restProps} toggleEditMode={this.toggleEditMode.bind(this)} />
  }
}

EditableCellContainer.propTypes = {
  updateInventoryBackend: PropTypes.func.isRequired,
  aiInventoryEditStart: PropTypes.func.isRequired,
  aiInventoryEditCancel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  aiInventoryEditSave: PropTypes.func.isRequired
}

export const EditableCellContainerNaked = EditableCellContainer

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { updateInventoryBackend, aiInventoryEditStart, aiInventoryEditCancel, aiInventoryEditSave },
    dispatch
  )

export default connect(null, mapDispatchToProps)(withStyles(styles)(EditableCellContainer))
