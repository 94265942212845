import { cloneDeep, filter, isEmpty } from 'lodash'
import {
  RESET_UPDATED_ITEM_LIST,
  SET_INVENTORY_ITEM_COUNT,
  UPDATE_INVENTORY_QUANTITY_FAILURE,
  UPDATE_INVENTORY_QUANTITY_REQUEST,
  UPDATE_INVENTORY_QUANTITY_SUCCESS,
  SET_INVENTORY_TAB,
  SET_WEEK_INBOUND_QTY,
  RESET_WEEK_INBOUND_QTY
} from './Inventory.types'
import { INVENTORY_TABS } from './InventoryTabSwitcher'

export const INITIAL_STATE = {
  totalInventory: 0,
  searchQuery: null,
  updateList: [],
  inboundList: [],
  inventoryTab: INVENTORY_TABS.ACTIVE
}

export default (state = cloneDeep(INITIAL_STATE), action) => {
  switch (action.type) {
    case SET_INVENTORY_ITEM_COUNT:
      return {
        ...state,
        totalInventory: action.payload.count
      }

    case RESET_UPDATED_ITEM_LIST:
      return {
        ...state,
        updateList: []
      }

    case UPDATE_INVENTORY_QUANTITY_REQUEST: {
      const item = state.updateList.find(item => item.itemId === action.payload.itemId)
      let updateList = [...state.updateList]
      if (!item) updateList = [...updateList, { ...action.payload, isFetching: true }]
      else {
        updateList = updateList.map(item => {
          if (item.itemId === action.payload.itemId) return { ...action.payload, isFetching: true }
          return { ...item }
        })
      }

      return {
        ...state,
        updateList
      }
    }

    case UPDATE_INVENTORY_QUANTITY_SUCCESS:
    case UPDATE_INVENTORY_QUANTITY_FAILURE:
      return {
        ...state,
        updateList: [
          ...state.updateList.map(item => {
            if (item.itemId === action.payload.itemId) return { ...action.payload, isFetching: false }
            return { ...item }
          })
        ]
      }

    case SET_INVENTORY_TAB: {
      const { tabName } = action.payload
      return {
        ...state,
        inventoryTab: tabName
      }
    }

    case SET_WEEK_INBOUND_QTY: {
      const { inbound } = action.payload
      return {
        ...state,
        inboundList: [...inbound]
      }
    }

    case RESET_WEEK_INBOUND_QTY: {
      const { win } = action.payload
      const selectedWin = filter(state.inboundList, (item) => item.win !== win)
      return {
        ...state,
        inboundList: isEmpty(win) ? [] : [...selectedWin]
      }
    }

    default:
      return { ...state }
  }
}
