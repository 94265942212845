import { ConnectedRouter } from 'connected-react-router'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Health from '../../pages/Health'
import OrderPage from '../../pages/OrderPage'
import ReturnOrder from '../../pages/ReturnOrder/ReturnOrderDetails/index'
import InTransitInventoryEdit from '../../pages/InTransitInventory/InTransitInventoryEdit/index'
import Unauthorized from '../../pages/Unauthorized'
import { history } from '../../reduxSetup/store'
import { MENU_ROUTES as ROUTES } from '../LeftNav/AppRoutes'
import LoggingIn from '../LoggingIn'
import TelemetryProvider from '../TelemetryProvider'
import PrivateRoute from './PrivateRoute'
import ClubDetails from '../../pages/Club/SaleForecast/ClubDetails/index'
import OrderDemandClubDetails from '../../pages/Club/OrderDemand/ClubDetails/index'
import ChangeLog from '../../pages/Capacity/ChangeLog'

const Router = ({ loginId, sessionId }) => {
  return (
    <ConnectedRouter history={history}>
      <TelemetryProvider trackUser={{ loginId, sessionId }}>
        <Switch>
          <Route exact path="/" name="Authenticating" component={LoggingIn} />
          <Route path="/authenticating" name="Authenticating" component={LoggingIn} />
          <Route path="/unauthorized" name="Access Denied" component={Unauthorized} />
          <Route path="/health" name="Health Check" component={Health} />
          <PrivateRoute
            path="/fulfillment/vendor/:vendorId/shipNode/:shipNodeId/order/:poNumber"
            name="Order"
            component={OrderPage}
          />
          <PrivateRoute
            path="/returns/vendor/:vendorId/shipNode/:shipNodeId/order/:returnNumber"
            name="Order"
            component={ReturnOrder}
          />
          <PrivateRoute
            path="/club/sales-forecast/vendor/:vendorId/item/:itemId"
            name="ClubForecast"
            component={ClubDetails}
          />
          <PrivateRoute
            path="/club/order-demand/vendor/:vendorId/item/:itemId"
            name="OrderDemandClubDetails"
            component={OrderDemandClubDetails}
          />
          <PrivateRoute
            path="/in-transit-inventory/vendor/:vendorId/shipNode/:shipNodeId/item/:itemId"
            name="Order"
            component={InTransitInventoryEdit}
          />
           <PrivateRoute
            path="/lead-time-capacity/changelog/:shipNodeId"
            name="Fcapacity"
            component={ChangeLog}
          />
          {ROUTES.map(route => (
            isEmpty(route.nav) ?
              <PrivateRoute
                key={route.url}
                path={route.url}
                name={route.name}
                enableMastHead={route.enableMastHead}
                component={route.component}
                searchEnabled={route.searchEnabled}
              />
              :
              route.nav.map(item => {
                return <PrivateRoute
                  key={item.url}
                  path={item.url}
                  name={item.name}
                  enableMastHead={item.enableMastHead}
                  component={item.component}
                  searchEnabled={item.searchEnabled}
                />
              })

          ))}
        </Switch>
      </TelemetryProvider>
    </ConnectedRouter>
  )
}

Router.propTypes = {
  loginId: PropTypes.string.isRequired,
  sessionId: PropTypes.string.isRequired
}
export default Router