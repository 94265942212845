import { Box } from '@mui/material'
import { withStyles } from '@mui/styles'
import React from 'react'
import PropTypes from 'prop-types'
import SSHLoadingAnimation from './SSHLoadingAnimation'

const styles = () => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

// eslint-disable-next-line react/prop-types
const SSHPaginatedTableLoading = ({ classes, small }) => {
  return (
    // eslint-disable-next-line react/prop-types
    <Box component="div" className={classes.root}>
      <SSHLoadingAnimation size={small ? 40 : 80} />
    </Box>
  )
}

SSHPaginatedTableLoading.defaultProps = {
  small: false
}

SSHPaginatedTableLoading.propTypes = {
  small: PropTypes.bool
}

export default withStyles(styles)(SSHPaginatedTableLoading)
