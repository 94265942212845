import React, { PureComponent } from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Box } from '@mui/material'
import { withStyles } from "@mui/styles"
import Edit from "./Edit"
import { isEmpty, find } from 'lodash'
import SSHScheduler from "../../components/SSHScheduler"
import SSHPaginatedTableLoading from '../../components/SSHPaginatedTableLoading'
import { displayDateFromToday } from '../../utils/common'
import { yearFirstFormat } from '../../config/genericConstants'

const styles = () => ({
    box: {
        position: 'relative'
    }
})

/**
 * @description flexscape component to customize toolbar for scheduler component
 */
class SchedulerInventory extends PureComponent {
    /**
     * @description Format Data to send to the Scheduler component 
     * @returns {[{ title, startdate, endDate }]}
     */
    schedularData = (data) => {
        const dateArray = []
        for (let i = 1; i <= 30; i++) {
            dateArray.push({
                date: displayDateFromToday(i, yearFirstFormat),
                quantity: find(data.inTransitInventory, val => val.date === displayDateFromToday(i, yearFirstFormat))?.quantity || 0,
            })
        }
        return dateArray?.map(val => {
            const dateParts = val.date?.split('-')
            return {
                title: val.quantity,
                startDate: new Date(dateParts[0], dateParts[1] - 1, dateParts[2], 9, 30),
                endDate: new Date(dateParts[0], dateParts[1] - 1, dateParts[2], 10, 30),
            }
        })
    }

    render() {
        const { onEdit, data, classes, isFetching, selectedItem } = this.props
        if (!isFetching && isEmpty(selectedItem)) {
            return null
        }
        if (isFetching || isEmpty(selectedItem)) {
            return (<SSHPaginatedTableLoading />)
        }
        return (
            <Box className={classes.box}>
                <SSHScheduler data={this.schedularData(data)} />
                <Edit onEdit={onEdit} />
            </Box>
        )
    }
}

export const SchedulerInventoryNaked = SchedulerInventory

SchedulerInventory.propTypes = {
    onEdit: PropTypes.func,
    data: PropTypes.object.isRequired
}

SchedulerInventory.defaultProps = {
    onEdit: () => null
}

const mapStateToProps = state => ({
    selectedItem: state.InTransitInventory.selectedItem,
    isFetching: state.InTransitInventory.isFetching
})

export default connect(mapStateToProps, null)(withStyles(styles)(SchedulerInventory))
