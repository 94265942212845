/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import { isEmpty, capitalize, cloneDeep, orderBy, isArray } from 'lodash'
import moment from 'moment'
import {
  SMART_INVENTORY_DROPDOWN_MODEL,
  FILTER_KEYS,
  CHIP_LABELS,
  yearFirstFormat,
  CHART_DURATION,
  dateTimeFormat
} from '../config/genericConstants'
import { capitalizeSentence, setColumnsNames } from './common'
import { nonSupplierParseSupCat, supplierParseSupCat, getDatesViewDurationQtr, checkAllSelected, checkAllUnSelected, handleCheckUnCheckStatus } from './parseCognitive'

export const taskStatusParser = responseObject => {
  let statusDetails = !isEmpty(responseObject.status_details) ? JSON.parse(responseObject.status_details) : {}
  if (!isEmpty(responseObject.statusDetails)) statusDetails = JSON.parse(responseObject.statusDetails)

  const response = {
    ...responseObject,
    ackId: responseObject.ackId,
    status: responseObject.status,
    statusDetails
  }

  if (!isEmpty(responseObject.fileName)) response.fileName = responseObject.fileName

  return response
}

export const feedListParser = rows => {
  if (isEmpty(rows)) {
    return []
  }

  return rows.map(row => ({
    ...row,
    receivedFormatted: moment(row.recievedDate).format('DD MMM, YY HH:ss:ss')
  }))
}

/**
 * @description Formats values for dateTime, module name, file name and shipnode columns
 * @param {Object[]} rows List of downloaded reports
 * @param {string} vendorId Supplier id
 * @returns {[]} List of reports with the formatted values
 */
export const downloadListParser = rows => {
  if (isEmpty(rows)) {
    return []
  }

  return rows.map(row => ({
    ackId: row?.ackId,
    status: row?.status,
    createdAt: row?.createdAt ? moment(row.createdAt).format(dateTimeFormat) : '',
    taskType: row?.taskType ? capitalize(row.taskType) : '',
    fileName: row?.fileName ? row.fileName?.substr(row.fileName.lastIndexOf('/') + 1, row.fileName.length) : '',
    shipnodeId: row?.shipnodeId || '-',
    vendorId: row?.vendorId || '-'
  }))
}

export const permissionParser = permissions => {
  if (isEmpty(permissions)) {
    return {}
  }

  let resourceBy = permissions.reduce((resource, name) => {
    // eslint-disable-next-line no-param-reassign
    resource[name.resourceName] = [...(resource[name.resourceName] || []), name.action.toLocaleLowerCase()]
    return resource
  }, {})
  resourceBy = { ...resourceBy, '/search': ['view'] }
  return resourceBy
}

/**
 * @description Based on shipment date we need to enable/disable click on PO Number to show order details.
 * @description Only orders with shipment date within T-2 days should show shipment date
 * @param orders
 * @return {{[p: string]: *}[]|*[]}
 */
export const parseFulfillmentOrders = (orders = []) => {
  if (!Array.isArray(orders)) return []
  const dateFormat = 'MM/DD/YYYY'
  const currentDate = moment()
  const dateRange = [
    currentDate.format(dateFormat),
    currentDate
      .clone()
      .subtract(1, 'day')
      .format(dateFormat),
    currentDate
      .clone()
      .subtract(2, 'day')
      .format(dateFormat)
  ]

  return orders.map(order => ({
    ...order,
    enableOrderDetails: dateRange.includes(order.shipmentDate)
  }))
}

/**
 * To avoid manipulation all the times while changing the tabs
 * @param {Array} divisions list of divisions
 * @param {Object} filters
 * @param {Object} formattedDivisions
 * @param {Object} formattedSuppliers
 * @param {Object} formattedCategories
 * @param {Object} formattedSubCategories
 * @param {Array} suppliers list of suppliers
 * @returns New filter object
 */
export const getDataFromExistingMaps = (
  formattedSuppliers,
  suppliers,
  categoryToDivisionMap,
  divisionToSupplierMap,
  categoryToSupplierMap,
  subCategoryToSupplierMap
) => {
  if (isArray(suppliers) && !isEmpty(suppliers) && suppliers[0].supplierId !== undefined) {
    suppliers.forEach(supplier => {
      const sup = {
        id: supplier.supplierId,
        title: supplier.supplierName,
        value: supplier.supplierId,
        active: true
      }
      formattedSuppliers.push(sup)
      if (supplier.categories !== undefined) {
        supplier.categories.forEach(category => {
          categoryToSupplierMap.set(category?.categoryId, [
            ...(categoryToSupplierMap.get(category?.categoryId) || [{ ...SMART_INVENTORY_DROPDOWN_MODEL }]),
            sup
          ])
          const division = categoryToDivisionMap.get(category?.categoryId)
          divisionToSupplierMap.set(division?.divisionId, [
            ...(divisionToSupplierMap.get(division?.divisionId) || [{ ...SMART_INVENTORY_DROPDOWN_MODEL }]),
            sup
          ])
          if (category.subcategories !== undefined) {
            category.subcategories.forEach(subCategory => {
              subCategoryToSupplierMap.set(subCategory?.subcategoryId, [
                ...(subCategoryToSupplierMap.get(subCategory?.subcategoryId) || [
                  { ...SMART_INVENTORY_DROPDOWN_MODEL }
                ]),
                sup
              ])
            })
          }
        })
      }
    })
  }
}

export const parseSuppliersAndCategories = (divisions, suppliers, selectedTab, isSupplier, filters) => {
  const formattedDivisions = []
  const formattedSuppliers = []
  const formattedCategories = []
  const formattedSubCategories = []

  const divisionToCategoryMap = new Map()
  const categoryToDivisionMap = new Map()
  const divisionToSubCategoryMap = new Map()
  const divisionToSupplierMap = new Map()
  const categoryToSubCategoryMap = new Map()
  const categoryToSupplierMap = new Map()
  const subCategoryToSupplierMap = new Map()

  if (filters?.divisionToCategoryMap?.size > 0) {
    getDataFromExistingMaps(
      formattedSuppliers,
      suppliers,
      filters.categoryToDivisionMap,
      divisionToSupplierMap,
      categoryToSupplierMap,
      subCategoryToSupplierMap
    )
  }

  if (!isSupplier) {
    nonSupplierParseSupCat(divisions, {
      formattedDivisions,
      formattedCategories,
      formattedSubCategories,
      categoryToSubCategoryMap,
      divisionToCategoryMap,
      categoryToDivisionMap,
      divisionToSubCategoryMap
    })
  } else {
    supplierParseSupCat(divisions, suppliers, {
      formattedCategories,
      formattedSubCategories,
      formattedDivisions,
      formattedSuppliers,
      divisionToSupplierMap,
      categoryToSubCategoryMap,
      categoryToSupplierMap,
      divisionToCategoryMap,
      categoryToDivisionMap,
      subCategoryToSupplierMap,
      divisionToSubCategoryMap
    })
  }

  const sortedFormattedSuppliers = orderBy(formattedSuppliers, [item => item?.title?.toLowerCase()], ['asc'])
  sortedFormattedSuppliers.splice(0, 0, { ...SMART_INVENTORY_DROPDOWN_MODEL })
  const sortedFormattedCategories = orderBy(formattedCategories, [item => item?.title?.toLowerCase()], ['asc'])
  sortedFormattedCategories.splice(0, 0, { ...SMART_INVENTORY_DROPDOWN_MODEL })
  const sortedFormattedSubcategories = orderBy(formattedSubCategories, [item => item?.title?.toLowerCase()], ['asc'])
  sortedFormattedSubcategories.splice(0, 0, { ...SMART_INVENTORY_DROPDOWN_MODEL })
  formattedDivisions.splice(0, 0, { ...SMART_INVENTORY_DROPDOWN_MODEL })
  return [
    formattedDivisions,
    sortedFormattedSuppliers,
    sortedFormattedCategories,
    sortedFormattedSubcategories,
    divisionToCategoryMap,
    divisionToSubCategoryMap,
    divisionToSupplierMap,
    categoryToSubCategoryMap,
    categoryToSupplierMap,
    subCategoryToSupplierMap,
    categoryToDivisionMap
  ]
}

export const resetSuppliersAndCategoriesSelection = ({
  formattedSuppliers,
  formattedCategories,
  formattedSubCategories,
  categoryToSubCategoryMap,
  categoryToSupplierMap,
  subCategoryToSupplierMap,
  linkedFilter,
  selectedFilters
}) => {
  let resetSupplier = formattedSuppliers
  let resetCategories = formattedCategories
  let resetSubCategories = formattedSubCategories

  if (formattedSuppliers !== undefined) {
    resetSupplier = formattedSuppliers.map(supplier => ({
      ...supplier,
      active:
        linkedFilter && linkedFilter.filterName === FILTER_KEYS.SUPPLIER_ID ? linkedFilter.id === supplier.id : true
    }))
  }
  if (formattedCategories !== undefined) {
    if (linkedFilter && linkedFilter.filterName === FILTER_KEYS.SUPPLIER_ID) {
      if (selectedFilters && !isEmpty(selectedFilters)) {
        const values = selectedFilters.find(item => item.filterName === FILTER_KEYS.CATEGORY)?.filterValues || []
        resetCategories = formattedCategories.map(category => ({
          ...category,
          active: values.includes(category.title)
        }))
      } else {
        resetCategories = [...formattedCategories]
      }
    } else {
      resetCategories = formattedCategories.map(category => ({
        ...category,
        active:
          linkedFilter && linkedFilter.filterName === FILTER_KEYS.CATEGORY ? linkedFilter.id === category.id : true
      }))
    }
  }
  if (formattedSubCategories !== undefined) {
    if (linkedFilter && linkedFilter.filterName === FILTER_KEYS.SUPPLIER_ID) {
      if (selectedFilters && !isEmpty(selectedFilters)) {
        const values = selectedFilters.find(item => item.filterName === FILTER_KEYS.SUBCATEGORY)?.filterValues || []
        resetSubCategories = formattedSubCategories.map(subcategory => ({
          ...subcategory,
          active: !isEmpty(values) ? values.includes(subcategory.title) : true
        }))
      } else {
        resetSubCategories = [...formattedSubCategories]
      }
    } else {
      resetSubCategories = formattedSubCategories.map(subCategory => ({
        ...subCategory,
        active: true
      }))
    }
  }

  const suppliers =
    linkedFilter && linkedFilter.filterName === FILTER_KEYS.CATEGORY
      ? getUpdatedDropdownValues(resetCategories, categoryToSupplierMap)
      : [SMART_INVENTORY_DROPDOWN_MODEL, ...resetSupplier.filter(item => item.active === true)]
  const subcategories =
    linkedFilter && linkedFilter.filterName === FILTER_KEYS.CATEGORY
      ? getUpdatedDropdownValues(resetCategories, categoryToSubCategoryMap)
      : resetSubCategories.filter(item => item.active === true)

  return {
    formattedSuppliers: linkedFilter ? suppliers : resetSupplier,
    formattedCategories: resetCategories,
    formattedSubCategories: linkedFilter ? subcategories : resetSubCategories,
    categoryToSubCategoryMap,
    categoryToSupplierMap,
    subCategoryToSupplierMap
  }
}

export const resetFiltersToDefault = (divisions, suppliers, selectedTab, isVendor, filters, resetDropdown = false) => {
  const [
    formattedDivisions,
    formattedSuppliers,
    formattedCategories,
    formattedSubCategories,
    divisionToCategoryMap,
    divisionToSubCategoryMap,
    divisionToSupplierMap,
    categoryToSubCategoryMap,
    categoryToSupplierMap,
    subCategoryToSupplierMap,
    categoryToDivisionMap
  ] = parseSuppliersAndCategories(divisions, suppliers, selectedTab, isVendor, filters)

  const allColumns = resetDropdown ? filters.allColumns : setColumnsNames(selectedTab, isVendor)
  return {
    formattedDivisions,
    formattedSuppliers,
    formattedCategories,
    formattedSubCategories,
    divisionToCategoryMap,
    divisionToSubCategoryMap,
    divisionToSupplierMap,
    categoryToSubCategoryMap,
    categoryToSupplierMap,
    subCategoryToSupplierMap,
    categoryToDivisionMap,
    allColumns
  }
}

/**
 * Format the filter value to show on chip
 * @param {string} label filter label
 * @param {Array} filterItems corresponding dropdown items
 * @param {string} value selected value from dropdown
 * @returns string
 */
export const formatLabelValue = (label, filterItems, value) => {
  if (filterItems === undefined || filterItems.length === 0) {
    return ''
  }
  const item = filterItems ? filterItems.find(item => item.value === value) : SMART_INVENTORY_DROPDOWN_MODEL
  const itemNumberOrId = `(${label === CHIP_LABELS.SUBCATEGORY ? item.number : item.id})`
  const id =
    item !== undefined && item?.id !== 'All' ? itemNumberOrId : ''
  return `${label}: ${item !== undefined ? capitalizeSentence(item.title) : ''} ${id}`
}

/**
 * Formats the Smart Inventory filter names as per the UX
 */
export const getFilterChipLabelName = (filterName, value, allFilters) => {
  if (filterName === FILTER_KEYS.DIVISION) {
    return formatLabelValue(CHIP_LABELS.DIVISION, allFilters.formattedDivisions, value)
  }
  if (filterName === FILTER_KEYS.CATEGORY) {
    return formatLabelValue(CHIP_LABELS.CATEGORY, allFilters.formattedCategories, value)
  }
  if (filterName === FILTER_KEYS.SUBCATEGORY) {
    return formatLabelValue(CHIP_LABELS.SUBCATEGORY, allFilters.formattedSubCategories, value)
  }
  return formatLabelValue(CHIP_LABELS.SUPPLIER, allFilters.formattedSuppliers, value)
}

/**
 * Format the filters for POST body of aggregated API
 * @param {boolean} isVendor filter label
 * @param {string} vendorId corresponding dropdown items
 * @param {Array} filters selected value from dropdown
 * @param {string} alertType
 * @returns {Array}
 */
export const getAggregatedFiltersData = (isVendor, vendorId, filters, alertType, tab) => {
  const requestedFilters = cloneDeep(filters)
  if (isVendor) {
    requestedFilters.push({
      filterName: FILTER_KEYS.SUPPLIER_ID,
      filterValues: [vendorId]
    })
  }
  const filtersWithAlerts = [
    ...requestedFilters,
    { filterName: 'alertType', filterValues: alertType !== 'All' ? [alertType] : [] }
  ]

  let postBody = { filters: filtersWithAlerts, tab }
  if (isVendor) {
    postBody = { ...postBody, vendorId }
  }
  return postBody
}

/**
 * As soon as user checks/unchecks the category/subcategory dropdown Other dropdown should update
 * @param {Array} updatedFilterValues updated dropdown values on basis of which new values will be returned
 * @param {Map} mappedValues original map saved initially
 * @returns Updated dropdown list
 */
export const getUpdatedDropdownValues = (updatedFilterValues, mappedValues) => {
  const updatedSuppliers = new Map()
  if (mappedValues.size > 0) {
    updatedFilterValues
      .filter(value => value.active === true)
      .forEach(filter => {
        if (filter.id === 'All') {
          updatedSuppliers.set('All', SMART_INVENTORY_DROPDOWN_MODEL)
        } else if (mappedValues.get(filter.id)) {
          mappedValues.get(filter.id).forEach(value => {
            updatedSuppliers.set(value.id, { ...value, active: true })
          })
        }
      })
  }

  const uniqueValues = []
  updatedSuppliers.forEach(k => {
    uniqueValues.push(k)
  })
  if (!isEmpty(uniqueValues) && uniqueValues[0].id === 'All') {
    uniqueValues.splice(0, 1)
  }
  const sortedValues = orderBy(uniqueValues, [item => item.title?.toLowerCase() || ''], ['asc'])
  sortedValues.splice(0, 0, { ...SMART_INVENTORY_DROPDOWN_MODEL })

  return sortedValues
}

/**
 * Updates the values of filters
 * @param {*} input Array of checked values only
 * @param {*} updated State object consists of existing values and returns with updated values
 * @returns updated: array of updated filters AND selected: fotmatted values of filters
 */
export const getUpdatedValues = (input, updated) => {


  const isAllSelected = checkAllSelected({ input, updated })
  const isAllUnchecked = checkAllUnSelected({ input, updated })

  /* Everything should be checked when all is selected */
  if (isAllSelected) {
    for (let j = 0; j < updated?.length; j++) {
      updated[j].active = true
    }
  } else if (isAllUnchecked) {
    /* Everything should be unchecked when all is unchecked */
    for (let j = 0; j < updated?.length; j++) {
      updated[j].active = false
    }
  } else {
    /* Handle check and uncheck status in dropdown */
    handleCheckUnCheckStatus({ input, updated })
  }
  /* When close butto is clicked in dropdown everything should be unchecked */
  if (input?.length === 0) {
    return [updated?.map(item => ({ ...item, active: false })), []]
  }
  /* All checkbox should be unchecked automatically if any other value is checked */
  if (!isAllSelected && input?.length > 0) {
    updated[0].active = false
  }
  const selected = updated?.filter(item => item?.active === true).map(item => item?.value)
  return [updated, selected]
}

export const getUpdateApiFilters = (key, name, filters, apiRequestFilters) => {
  let values = filters[key]?.filter(item => item?.active === true).map(item => item?.value)

  if (values && values.includes('All')) {
    values = []
  }
  if (values && values?.length > 0) {
    const filter = {
      filterName: name,
      filterValues: [...values]
    }
    apiRequestFilters.push(filter)
  } else {
    apiRequestFilters.push({
      filterName: name,
      filterValues: []
    })
  }

  return apiRequestFilters
}

export const setApiFilterForIds = (key, name, filters, apiRequestFilters) => {
  let values = filters[key]?.filter(item => item.active === true).map(item => item.id)
  if (values && values.includes('All')) {
    values = []
  }
  if (values && values.length > 0) {
    apiRequestFilters.push({
      filterName: name,
      filterValues: [...values]
    })
  } else {
    apiRequestFilters.push({
      filterName: name,
      filterValues: []
    })
  }
}

export const setApiFilterForSubcategoryNumbers = (filters, apiRequestFilters) => {
  let values = filters.formattedSubCategories.filter(item => item.active === true).map(item => item.number)
  if (values.includes('-1')) {
    values = []
  }
  if (values.length > 0) {
    apiRequestFilters.push({
      filterName: FILTER_KEYS.SUBCATEGORY_NUM,
      filterValues: [...values]
    })
  } else {
    apiRequestFilters.push({
      filterName: FILTER_KEYS.SUBCATEGORY_NUM,
      filterValues: []
    })
  }
}

export const setTwoDecimalPoint = input => {
  if (typeof input === 'number') {
    input = input.toString()
  }
  if (!input) {
    return ''
  }
  const numbers = input?.split('.')
  let decimalStr = numbers[1]
  if (numbers[1] === undefined) {
    decimalStr = '00'
  } else if (numbers[1].length === 1) {
    decimalStr = `${numbers[1]}0`
  } else if (numbers[1].length > 2) {
    decimalStr = `${numbers[1].substr(0, 2)}`
  }
  return `${numbers[0]}.${decimalStr}`
}

const getDatesFromViewDuration = {
  week: duration => {
    const endDate = moment().day(moment().day() >= 5 ? 5 : -2)
    switch (duration.id) {
      case CHART_DURATION.week[0].id: {
        return {
          toDate: endDate.format(yearFirstFormat),
          fromDate: endDate.day(-1).format(yearFirstFormat)
        }
      }
      case CHART_DURATION.week[1].id: {
        return {
          toDate: endDate.format(yearFirstFormat),
          fromDate: endDate
            .day(-1)
            .subtract(2, 'weeks')
            .format(yearFirstFormat)
        }
      }
      case CHART_DURATION.week[3].id: {
        const startDate = moment(endDate)
        const index =
          startDate
            .subtract(1, 'years')
            .endOf('month')
            .day() >= 5
            ? 5
            : -2
        return {
          toDate: endDate.format(yearFirstFormat),
          fromDate: startDate.day(index).format(yearFirstFormat)
        }
      }
      default: {
        const startDate = moment()
        const index =
          startDate
            .startOf('year')
            .endOf('month')
            .day() >= 5
            ? 5
            : -2
        return {
          toDate: endDate.format(yearFirstFormat),
          fromDate: startDate.day(index).format(yearFirstFormat)
        }
      }
    }
  },
  month: duration => {
    const endDate = moment()
      .startOf('month')
      .subtract(1, 'days')
      .day(
        moment()
          .startOf('month')
          .subtract(1, 'days')
          .day() >= 5
          ? 5
          : -2
      )

    switch (duration.id) {
      case CHART_DURATION.month[0].id: {
        const startDate = moment(endDate)
        const index =
          startDate
            .startOf('month')
            .subtract(1, 'month')
            .endOf('month')
            .day() >= 5
            ? 5
            : -2
        return {
          toDate: endDate.format(yearFirstFormat),
          fromDate: startDate.day(index).format(yearFirstFormat)
        }
      }
      case CHART_DURATION.month[1].id: {
        const startDate = moment(endDate)
        const index =
          startDate
            .startOf('month')
            .subtract(3, 'months')
            .endOf('month')
            .day() >= 5
            ? 5
            : -2
        return {
          toDate: endDate.format(yearFirstFormat),
          fromDate: startDate.day(index).format(yearFirstFormat)
        }
      }
      case CHART_DURATION.month[3].id: {
        const startDate = moment(endDate)
        const index =
          startDate
            .subtract(1, 'years')
            .endOf('month')
            .day() >= 5
            ? 5
            : -2
        return {
          toDate: endDate.format(yearFirstFormat),
          fromDate: startDate.day(index).format(yearFirstFormat)
        }
      }
      default: {
        const startDate = moment()
        const index =
          startDate
            .startOf('year')
            .endOf('month')
            .day() >= 5
            ? 5
            : -2
        return {
          toDate: moment()
            .day(moment().day() >= 5 ? 5 : -2)
            .format(yearFirstFormat),
          fromDate: startDate.day(index).format(yearFirstFormat)
        }
      }
    }
  },
  quarter: duration => {
    return getDatesViewDurationQtr({ duration })
  },
  year: duration => {
    const endDate = moment().day(moment().day() >= 5 ? 5 : -2)
    switch (duration.id) {
      case CHART_DURATION.year[1].id: {
        const endDateIndex =
          endDate
            .startOf('year')
            .endOf('month')
            .day() >= 5
            ? 5
            : -2
        const startDate = moment(endDate)
        const startDateIndex =
          startDate
            .subtract(1, 'years')
            .startOf('year')
            .endOf('month')
            .day() >= 5
            ? 5
            : -2
        return {
          toDate: endDate.day(endDateIndex).format(yearFirstFormat),
          fromDate: startDate.day(startDateIndex).format(yearFirstFormat)
        }
      }
      case CHART_DURATION.year[2].id: {
        const endDateIndex =
          endDate
            .startOf('year')
            .endOf('month')
            .day() >= 5
            ? 5
            : -2
        const startDate = moment(endDate)
        const startDateIndex =
          startDate
            .subtract(2, 'years')
            .startOf('year')
            .endOf('month')
            .day() >= 5
            ? 5
            : -2
        return {
          toDate: endDate.day(endDateIndex).format(yearFirstFormat),
          fromDate: startDate.day(startDateIndex).format(yearFirstFormat)
        }
      }
      default: {
        const startDate = moment()
        const index =
          startDate
            .startOf('year')
            .endOf('month')
            .day() >= 5
            ? 5
            : -2
        return {
          toDate: endDate.format(yearFirstFormat),
          fromDate: startDate.day(index).format(yearFirstFormat)
        }
      }
    }
  }
}

/**
 * @description construct past trends API request body
 * @param {Object} filters
 * @param {boolean} filters.IS_VENDOR is vendor login
 * @param {string} filters.VENDOR_ID vendor id of the logged in vendor
 * @param {Object} filters.apiRequestFilters Division, Category, Subcategory, Supplier filters
 * @param {string} filters.trendType value can be either OOS or LOR
 * @param {Object} filters.chartDuration 'from date' for trends chart
 * @param {Object} filters.chartView 'to date' for trends chart
 * @param {string} filters.toDate view for trends chart
 */

export const setPastTrendsRequestBody = ({
  IS_VENDOR,
  VENDOR_ID,
  apiRequestFilters,
  trendType,
  chartDuration,
  chartView
}) => {
  const { toDate, fromDate } = getDatesFromViewDuration[chartView.value](chartDuration)
  return {
    timeline: chartView.value,
    trend: trendType === 'OOS' ? 'oosPercentage' : 'revenueLoss',
    from: fromDate,
    to: toDate,
    type: 'all',
    vendorId: IS_VENDOR ? VENDOR_ID : '',
    filters: apiRequestFilters
  }
}


