import React from 'react'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Switch from '@mui/material/Switch'

const styles = theme => ({
  switchBase: {
    color: theme.palette.primary.slateGrey,
    '&$checked': {
      color: theme.palette.primary.blue
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.cyanBlue,
      boxShadow: '0 0 4px 0 rgba(0, 39, 81, 0.25)'
    }
  },
  checked: {},
  track: { backgroundColor: theme.palette.primary.slateGrey, border: '1px solid #88919e' }
})

const SSHSwitch = ({ classes, onChange, ...props }) => {
  return (
    <Switch
      data-testid="SSHSwitch"
      onChange={onChange}
      color='primary'
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  )
}

SSHSwitch.defaultProps = {
  checked: false,
  size: 'medium',
  disabled: false
}

SSHSwitch.propTypes = {
  // eslint-disable-next-line react/require-default-props
  classes: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  size: PropTypes.string,
  disabled: PropTypes.bool
}
export default withStyles(styles)(SSHSwitch)
