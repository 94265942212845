import { Grid } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SSHButton from '../../components/SSHButton'
import { getShippingLabel } from './OrderDetailsActions'
import { allowAccess, getDateFromString } from '../../utils/common'
import { ROLE_BACK_ACTIONS } from '../../config/genericConstants'


const styles = theme => ({
  title: {
    fontFamily: 'canada-type-gibson',
    '& div': {
      color: theme.palette.primary.downloadLabelText,
      fontWeight: '500',
      fontSize: theme.spacing(1.9),
      "& span": {
        fontSize: theme.spacing(1.6)
      }
    }

  }
})

const ShipmentDetailsHeader = ({
  trackingNumber,
  carrierMethodName,
  shipDate,
  releaseNumber,
  poNumber,
  getShippingLabel,
  classes
}) => {
  const [fetching, setFetching] = useState(false)
  const getLabel = () => {
    setFetching(true)
    getShippingLabel({
      releaseNumber,
      poNumber,
      trackingNumber
    })
      .then(() => { })
      .catch(() => { })
      .finally(() => {
        setFetching(false)
      })
  }

  return (
    <Grid container spacing={4} justifyContent="flex-end" alignItems="center" className={classes.title}>
      <Grid item>
        Carrier: <span>{carrierMethodName}</span>
      </Grid>
      <Grid item>
        Ship Date: <span>{moment.utc(getDateFromString(shipDate)).format('DD MMM, YYYY')}</span>
      </Grid>
      <Grid item>
        Tracking Number:  <span>{trackingNumber ? String(trackingNumber).trim() : 'NA'}</span>
      </Grid>
      <Grid item>
        {allowAccess(ROLE_BACK_ACTIONS.LABEL_DOWNLOAD) && (
          <SSHButton
            disabled={fetching}
            color="primary"
            tooltip="Download ship label"
            text="Download ship label"
            variant="outlined"
            onClick={getLabel}
            size="medium"
          />
        )}
      </Grid>
    </Grid>
  )
}

ShipmentDetailsHeader.propTypes = {
  trackingNumber: PropTypes.string.isRequired,
  carrierMethodName: PropTypes.string.isRequired,
  shipDate: PropTypes.string.isRequired,
  releaseNumber: PropTypes.string.isRequired,
  poNumber: PropTypes.string.isRequired,
  getShippingLabel: PropTypes.func.isRequired
}

export const ShipmentDetailsHeaderNaked = ShipmentDetailsHeader

const mapDispatchToProps = dispatch => bindActionCreators({ getShippingLabel }, dispatch)
export default connect(null, mapDispatchToProps)(withStyles(styles)(ShipmentDetailsHeader))
