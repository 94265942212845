import { Box, Grid, Paper, Slide, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import SSHPaginatedTableLoading from '../../../components/SSHPaginatedTableLoading'
import ReturnItemsTable from './ReturnItemsTable'
import { pluralize } from '../../../utils/common'
import { isEmpty } from 'lodash'

const styles = theme => ({
    typoInfo: {
        fontSize: theme.spacing(2.4),
        color: theme.palette.primary.baseTextDark
    },
    typoResults: {
        color: theme.palette.primary.mettalicBlue,
        fontSize: theme.spacing(1.8)
    },
    orderHeader: {
        backgroundColor: theme.palette.primary.headerBackground
    }
})

class ReturnDetailsTable extends PureComponent {
    render = () => {

        const { classes,
            carrierMethods,
            isFetching,
            ordersList,
            selectedCarrier,
            selectCarrier,
            onSelectOtherCarrier,
            onChangeTrackingNumber,
            onChangeOrderRecievedQty,
            resetCarrierTracking, ...restProps } = this.props


        return (
            <Slide direction="up" in mountOnEnter unmountOnExit>
                <Paper square style={{ position: 'relative' }} elevation={2}>
                    <Box px={4} py={2} className={classes.orderHeader}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item xs={2}>
                                <Typography className={classes.typoInfo}>Line items</Typography>
                                <Typography className={classes.typoResults}>
                                    {isEmpty(ordersList) ? `0 result` : `${ordersList?.orderLines?.length} ${pluralize('result', 'results', ordersList?.orderLines?.length)}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Paper square style={{ position: 'relative' }}>
                                <ReturnItemsTable
                                    ordersList={ordersList}
                                    carrierMethods={carrierMethods}
                                    selectedCarrier={selectedCarrier}
                                    selectCarrier={selectCarrier}
                                    onSelectOtherCarrier={onSelectOtherCarrier}
                                    onChangeTrackingNumber={onChangeTrackingNumber}
                                    onChangeOrderRecievedQty={onChangeOrderRecievedQty}
                                    resetCarrierTracking={resetCarrierTracking}
                                    onConfirm={restProps.onConfirm}
                                />
                                {isFetching && <SSHPaginatedTableLoading />}
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
            </Slide>
        )
    }
}

ReturnDetailsTable.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    carrierMethods: PropTypes.array.isRequired
}
export default withStyles(styles)(ReturnDetailsTable)
export const ReturnDetailsTableNaked = ReturnDetailsTable
