import { cloneDeep } from 'lodash'
import {
  SET_REPORT_TYPE, SET_REPORT_DATE, SET_SCOPE_TYPE,
  PENALTY_DOWNLOAD_REQUEST,
  PENALTY_DOWNLOAD_SUCCESS,
  PENALTY_DOWNLOAD_FAILURE
} from './PredefinedReports.actionTypes'

export const INITIAL_STATE = {
  isGenrating: false,
  selectedDate: {
    fromDate: null,
    toDate: null
  },
  reportType: [
    {
      name: 'Sales orders',
      reportType: 'salesOrders',
      selected: true
    },
    {
      name: 'Open orders',
      reportType: 'openOrders',
      selected: false
    },
    {
      name: 'Lead time',
      reportType: 'recommendedShipNodeConfig',
      selected: false
    },
    {
      name: 'Penalty',
      reportType: 'penalty',
      selected: false
    }
  ],
  scopes: [
    {
      name: 'Single supplier',
      selected: true,
      value: 'single'
    },
    {
      name: 'Network',
      selected: false,
      value: 'all'
    }
  ]
}

export default (state = cloneDeep(INITIAL_STATE), action) => {
  switch (action.type) {
    case SET_REPORT_TYPE:
      const { reportType } = action.payload
      const newReports = state.reportType.map((report => {
        report.selected = false
        if (String(report.name).trim() === String(reportType).trim()) {
          report.selected = true
        }
        return report
      }))
      return {
        ...state,
        reportType: [...newReports]
      }
    case SET_SCOPE_TYPE:
      const { scope } = action.payload
      const newScope = state.scopes.map((scop => {
        scop.selected = false
        if (String(scop.name).trim() === String(scope).trim()) {
          scop.selected = true
        }
        return scop
      }))
      return {
        ...state,
        scopes: [...newScope]
      }
    case SET_REPORT_DATE:
      const { dates } = action.payload
      return {
        ...state,
        selectedDate: {
          fromDate: dates.from,
          toDate: dates.to
        }
      }
    case PENALTY_DOWNLOAD_REQUEST:
      return {
        ...state,
        isGenrating: true
      }

    case PENALTY_DOWNLOAD_SUCCESS:
    case PENALTY_DOWNLOAD_FAILURE:
      return {
        ...state,
        isGenrating: false
      }

    default:
      return { ...state }
  }
}
