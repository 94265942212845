/* eslint-disable react/destructuring-assignment */
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Typography } from '@mui/material'
import React from 'react'
import SSHTooltip from '../../components/SSHTooltip'

const FeedDetailsCell = props => {
  return (
    <Table.Cell {...props}>
      <SSHTooltip title={props.row.fileName} placement="right">
        <Typography variant="body2">File Name: {props.row.fileName}</Typography>
      </SSHTooltip>
      <SSHTooltip title={props.row.feedId} placement="right">
        <Typography variant="caption" color="textSecondary">
          Feed ID: {props.row.feedId}
        </Typography>
      </SSHTooltip>
    </Table.Cell>
  )
}

export default FeedDetailsCell
