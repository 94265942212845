import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SSHSelectBox from '../../components/SSHSelectBox'
import { SSHTypography } from '../../components/SSHTypography'
import { selectShipNode } from '../../reduxSetup/commonActions/AppConfig'


const useStyle = makeStyles(theme => ({
  form: {
    '& .MuiOutlinedInput-root': {
      height: theme.spacing(5),
      borderRadius: theme.spacing(0.5),
      width: theme.spacing(42.5),
      backgroundColor: theme.palette.primary.lighter
    }
  },
  inputText: {
    '& .MuiOutlinedInput-input': {
      padding: '1px 14px',
      borderRadius: theme.spacing(3.125),
      backgroundColor: theme.palette.primary.lighter
    },
    '& .MuiSelect-root': {
      width: theme.spacing(42.5),
      color: theme.palette.primary.main,
      fontWeight: 600,
      padding: `${theme.spacing(1.2)}px ${theme.spacing(1.75)}px`
    },
    '& .MuiInputLabel-outlined': {
      color: theme.palette.primary.main,
      fontWeight: 600
    },
    '& .Mui-focused': {
      height: theme.spacing(5)
    }
  },
  disabled: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background.disabledGray
    }
  },
  placeholder: {
    position: 'absolute',
    top: theme.spacing(4.125),
    left: theme.spacing(4.6),
    color:  theme.palette.primary.persianBlue,
    opacity:'0.4'
  },
  container: {
    position: 'relative'
  }
}))

const ShipNode = ({ isFetching, shipNodes, selectedVendor, selectShipNode, selectedShipNode, styleClass }) => {
  const classes = useStyle()

  /**
  * @description filter shipnode options
  * @returns {*} filtered array
  */
  const filterShipnodes = () => {
    return shipNodes.filter(val => val.active).map(node => ({
      value: node.shipNodeId,
      name: node.shipNodeName
    }))
  }

  return (
    <Grid container spacing={3} justifyContent="center" className={styleClass}>
      <Grid item xs={12} className={classes.container}>
        <SSHSelectBox
          placeholder="ShipNode name or id"
          options={filterShipnodes()}
          loading={isFetching}
          onSelect={selectShipNode}
          defaultVal={{ name: selectedShipNode.shipNodeName, value: selectedShipNode.shipNodeId }}
          disabled={!selectedVendor.vendorId}
          style={!selectedVendor.vendorId ? clsx(classes.inputText, classes.disabled) : classes.inputText}
          selectStyle={classes.form}
        />
        {!selectedShipNode.shipNodeName && (
          <SSHTypography className={classes.placeholder}>Shipnode name or id</SSHTypography>
        )}
      </Grid>
    </Grid>
  )
}

ShipNode.propTypes = {
  isFetching: PropTypes.bool,
  shipNodes: PropTypes.array,
  selectedVendor: PropTypes.object,
  vendorId: PropTypes.string
}

ShipNode.defaultProps = {
  isFetching: false,
  shipNodes: [],
  selectedVendor: '',
  vendorId: ''
}

export const ShipNodeNaked = ShipNode

const mapStateToProps = state => ({
  isFetching: state.AppConfig.shipNodes.isFetching,
  shipNodes: state.AppConfig.shipNodes.list,
  selectedVendor: state.AppConfig.vendor.selectedVendor,
  selectedShipNode: { ...state.AppConfig.shipNodes.selected }
})

const mapDispatchToProps = dispatch => bindActionCreators({ selectShipNode }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(ShipNode)
