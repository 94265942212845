import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Box, Slide, Grid, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { ReactComponent as LeftArrowIcon } from '../../../assets/images/leftArrow.svg'
import { goToChangeLog, fetchChangelogTasks } from '../CapacityActions'
import { SSHTypography } from '../../../components/SSHTypography'
import ChangeLogTable from '../../Capacity/ChangeLog/ChangeLogTable'
import { formattedColumnsPropType } from '../../../utils/commonPropTypes'
import { changePage } from '../../../reduxSetup/commonActions/AppConfig'
import { ROUTE_MODULES } from '../../../config/genericConstants'

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.primary.headerBackground,
        padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
        borderBottom: `1px solid ${theme.palette.primary.columbiaBlue}`
    },
    title: {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: theme.spacing(2),
        alignItems: 'center',
        position: 'absolute',
        top: theme.spacing(7),
        zIndex: 9999,
        width: '80%'
    },
    arrow: {
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    headerTitle: {
        fontSize: theme.spacing(2.4),
        color: theme.palette.primary.baseTextDark
    }
})

const ChangeLog = ({ classes, pathname, goToChangeLog, changePage, rows, ...restProps }) => {
    const goToLeadTimeCapacityPage = () => {
        changePage(ROUTE_MODULES.fulfilment.leadtime.path)
    }

    if (!restProps?.selectedVendorId) {
        goToLeadTimeCapacityPage()
    }

    useEffect(() => {
        (async () => {
            await restProps?.fetchChangelogTasks(true) //isCountAPI: true for getting the total count
            restProps?.fetchChangelogTasks()
        })();
    }, []);

    return (
        <Slide direction='up' in={true} mountOnEnter unmountOnExit>
            <Grid container>
                <Grid item xs={12}>
                    <Box className={classes.root}>
                        <Typography variant="h5" className={classes.title}>
                            <LeftArrowIcon onClick={goToLeadTimeCapacityPage} cursor={'pointer'} className={classes.arrow} />
                            {restProps?.selectedShipnode?.name}
                        </Typography>
                        <Box className={classes.headerTitle}>
                            <SSHTypography>Change Log</SSHTypography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <ChangeLogTable rows={rows} />
                </Grid>
            </Grid>
        </Slide>
    )
}

ChangeLog.prototype = {
    classes: PropTypes.object,
    goToChangeLog: PropTypes.func,
    pathname: PropTypes.string,
    rows: PropTypes.arrayOf(PropTypes.object),
    column: PropTypes.arrayOf(PropTypes.object),
    formattedColumns: formattedColumnsPropType,
    tableColumnExtensions: PropTypes.array,
    selectedVendorId: PropTypes.string.isRequired,
    changePage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    rows: state.SSHPaginatedTableReducer.rows,
    pathname: state.router.location,
    pageNo: state.SSHPaginatedTableReducer.pageNo,
    pageSize: state.SSHPaginatedTableReducer.pageSize,
    selectedShipnode: state.FCapacity.selectedShipnode,
    selectedVendorId: state.AppConfig.vendor.selectedVendor.vendorId,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    goToChangeLog, changePage, fetchChangelogTasks
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChangeLog))
