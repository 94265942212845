export const SET_PENALTY_PROGRAM_ROW_COUNT = 'SET_PENALTY_PROGRAM_ROW_COUNT'
export const SEARCH_PENALTY_ITEM = 'SEARCH_PENALTY_ITEM'
export const SET_PENALTY_PROGRAM_TAB = 'SET_PENALTY_PROGRAM_TAB'
export const RESET_PENALTY_ITEM_SEARCH = 'RESET_PENALTY_ITEM_SEARCH'
export const SET_PENALTY_TAB = 'SET_PENALTY_TAB'
export const FETCH_PENALTY_REQUEST = 'FETCH_PENALTY_REQUEST'
export const FETCH_PENALTY_FAILURE = 'FETCH_PENALTY_FAILURE'
export const FETCH_PENALTY_SUCCESS = 'FETCH_PENALTY_SUCCESS'
export const FETCH_SUPPLIER_METRICS_REQUEST = 'FETCH_SUPPLIER_METRICS_REQUEST'
export const FETCH_SUPPLIER_METRICS_FAILURES = 'FETCH_SUPPLIER_METRICS_FAILURES'
export const FETCH_SUPPLIER_METRICS_SUCCCESS = 'FETCH_SUPPLIER_METRICS_SUCCCESS'
export const UPDATE_SUPPLIER_METRICS = 'UPDATE_SUPPLIER_METRICS'
export const UPDATE_PENALTIES = 'UPDATE_PENALTIES'
export const UPDATE_PENALTY_TABLE = 'UPDATE_PENALTY_TABLE'
export const SET_PENALTY_SEARCH_KEY_VALUE = 'SET_PENALTY_SEARCH_KEY_VALUE'
export const CLEAR_PENALTY_SEARCH_KEY_VALUE = 'CLEAR_PENALTY_SEARCH_KEY_VALUE'
export const EXCLUDE_PENALTY_REQUEST = 'EXCLUDE_PENALTY_REQUEST'
export const EXCLUDE_PENALTY_FAILURE = 'EXCLUDE_PENALTY_FAILURE'
export const EXCLUDE_PENALTY_SUCCESS = 'EXCLUDE_PENALTY_SUCCESS'
export const SET_SELECTED_PENALTY_ROWS = 'SET_SELECTED_PENALTY_ROWS'
export const GET_CHARGEBACK_REQUEST = 'GET_CHARGEBACK_REQUEST'
export const GET_CHARGEBACK_FAILURE = 'GET_CHARGEBACK_FAILURE'
export const GET_CHARGEBACK_SUCCESS = 'GET_CHARGEBACK_SUCCESS'
export const APPROVE_REJECT_REQUEST = 'APPROVE_REJECT_REQUEST'
export const APPROVE_REJECT_FAILURE = 'APPROVE_REJECT_FAILURE'
export const APPROVE_REJECT_SUCCESS = 'APPROVE_REJECT_SUCCESS'
export const UPDATE_CHARGEBACK_DETAILS = 'UPDATE_CHARGEBACK_DETAILS'
export const SHOW_HIDE_EXCLUDE_POPUP = 'SHOW_HIDE_EXCLUDE_POPUP'
export const SET_EXCLUDE_PENALTY_REASON = 'SET_EXCLUDE_PENALTY_REASON'
export const SET_EXCLUDE_PENALTY_DESCRIPTION = 'SET_EXCLUDE_PENALTY_DESCRIPTION'
export const SET_EXCLUDE_PENALTY_REASON_ERROR = 'SET_EXCLUDE_PENALTY_REASON_ERROR'
export const SET_EXCLUDE_PENALTY_DESCRIPTION_ERROR = 'SET_EXCLUDE_PENALTY_DESCRIPTION_ERROR'
export const SET_PENALTY_HISTORY_TAB = 'SET_PENALTY_HISTORY_TAB'
export const DOWNLOAD_PENALTY_REQUEST = 'DOWNLOAD_PENALTY_REQUEST'
export const DOWNLOAD_PENALTY_SUCCESS = 'DOWNLOAD_PENALTY_SUCCESS'
export const DOWNLOAD_PENALTY_FAILURE = 'DOWNLOAD_PENALTY_FAILURE'
export const SET_PENALTY_STATUS='SET_PENALTY_STATUS'
export const SET_FILTER_KEY_VALUE='SET_FILTER_KEY_VALUE'
export const CLEAR_PENALTY_FILTER='CLEAR_PENALTY_FILTER'
