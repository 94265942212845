import { Grid } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SSHSelectBox from '../../../components/SSHSelectBox'
import { displayPastWeekDays } from '../../../utils/common'
import { setDateChange } from '../../../pages/Club/PricingService/PricingServiceAction'
import { resetTable, resetPagination } from '../../../reduxSetup/commonActions/SSHPaginatedTable'

const PastDaysSelection = ({ selectedDate, setDateChange, resetTable, resetPagination }) => {
    const onDateChange = async (selected) => {
        await setDateChange(selected)
        await resetTable()
        await resetPagination()
    }

    return (
        <Grid item xs={3}>
            <SSHSelectBox
                size="small"
                name="weekday"
                placeholder="Select a date"
                defaultVal={selectedDate}
                onSelect={(selected) => onDateChange(selected)}
                options={displayPastWeekDays(7)}
            />
        </Grid>
    )
}

export const PastDaysSelectionNaked = PastDaysSelection

const mapStateToProps = state => ({
    selectedDate: state.ClubPricingServices.selectedDate
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setDateChange,
            resetTable,
            resetPagination
        },
        dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(PastDaysSelection)
