import { cloneDeep, isEmpty, filter, find } from 'lodash'
import {
  SET_PENALTY_TAB,
  UPDATE_PENALTIES,
  UPDATE_PENALTY_TABLE,
  UPDATE_SUPPLIER_METRICS,
  SET_PENALTY_PROGRAM_ROW_COUNT,
  SET_PENALTY_SEARCH_KEY_VALUE,
  CLEAR_PENALTY_SEARCH_KEY_VALUE,
  SET_SELECTED_PENALTY_ROWS,
  RESET_PENALTY_ITEM_SEARCH,
  UPDATE_CHARGEBACK_DETAILS,
  SHOW_HIDE_EXCLUDE_POPUP,
  SET_EXCLUDE_PENALTY_REASON,
  SET_EXCLUDE_PENALTY_DESCRIPTION,
  SET_EXCLUDE_PENALTY_REASON_ERROR,
  SET_EXCLUDE_PENALTY_DESCRIPTION_ERROR,
  SET_PENALTY_HISTORY_TAB,
  SET_PENALTY_STATUS,
  SET_FILTER_KEY_VALUE,
  CLEAR_PENALTY_FILTER
} from './PenaltyProgram.actionTypes'
import { PENALTY_STATUS_TYPE } from '../../config/genericConstantsNew'
import moment from 'moment'

const parseChargeBackDetails = details => {
  let cancelPenaltyValue
  let cancelPenaltyPercent
  let oneDayLatePenaltyValue
  let oneDayLatePenaltyPercent
  let twoDayLatePenaltyValue
  let twoDayLatePenaltyPercent

  details.forEach(detail => {
    if (detail.penaltyType === 'cancelled') {
      cancelPenaltyValue = detail.chargebackAmount
      cancelPenaltyPercent = detail.chargebackPercentage
    } else if (detail.daysLate === '1') {
      oneDayLatePenaltyValue = detail.chargebackAmount
      oneDayLatePenaltyPercent = detail.chargebackPercentage
    } else {
      twoDayLatePenaltyValue = detail.chargebackAmount
      twoDayLatePenaltyPercent = detail.chargebackPercentage
    }
  })

  return [
    cancelPenaltyValue,
    cancelPenaltyPercent,
    oneDayLatePenaltyValue,
    oneDayLatePenaltyPercent,
    twoDayLatePenaltyValue,
    twoDayLatePenaltyPercent
  ]
}
/** Do not change the sequence of selected and api filters anywhere */
export const INITIAL_STATE = {
  penalties: [
    {
      lateOrderCount: '',
      cancelledOrderCount: '',
      lateOrderCharge: '',
      cancelledOrderCharge: '',
      totalCharges: ''
    },
    {
      lateOrderCount: '',
      cancelledOrderCount: '',
      lateOrderCharge: '',
      cancelledOrderCharge: '',
      totalCharges: ''
    },
    {
      lateOrderCount: '',
      cancelledOrderCount: '',
      lateOrderCharge: '',
      cancelledOrderCharge: '',
      totalCharges: ''
    }
  ],
  metrics: {},
  penaltyOrders: {},
  totalRows: 0,
  downloadFileLimit:null,
  penaltySearch: { searchKey: '', searchValue: '' },
  chargebackDetails: [],
  showPopup: false,
  excludePenaltyReason: { name: '', value: '' },
  excludePenaltyDescription: '',
  isSelectedReasonError: false,
  isSelectedDescriptionError: false,
  excludePenaltySuccess: false,
  selectedHistoryTab: 'Pending',
  selectedRows: [],
  penaltySearchFilter: {
    isSearching: false,
    search: true,
    penaltyStatus: [...PENALTY_STATUS_TYPE],
    status: [],
    penaltyDateRange: {
      from: null,
      to: null
    },
    inValidPenaltyDate: false,
    penaltyDate: false,
    sortType: 'asc'
  }
}

/**
 * @description validate params 
 * @param {*} penaltyfilter 
 * @returns boolean
 */
const validateParams = ({ newPenaltyFilter }) => {
  const checkStatus = filter(newPenaltyFilter.penaltyStatus, { checked: true }, false)

  return !((newPenaltyFilter.penaltyDateRange.from != null && newPenaltyFilter.penaltyDateRange.to != null) || checkStatus.length > 0);
}

/**
 * @description validate dates 
 * @param {*} newPenaltyFilter 
 * @returns boolean
 */
const validateDates = ({ newPenaltyFilter }) => {
  const { from, to } = newPenaltyFilter.penaltyDateRange
  if (!isEmpty(from) && !isEmpty(to)) {
    newPenaltyFilter.inValidPenaltyDate = moment(from).isSameOrAfter(to)
  }
  return newPenaltyFilter.inValidPenaltyDate
}

export default (state = cloneDeep(INITIAL_STATE), action = {}) => {
  switch (action.type) {
    case UPDATE_PENALTIES: {
      state.penalties.splice(action.payload.index, 1, action.payload.value)
      return {
        ...state,
        penalties: [...state.penalties]
      }
    }
    case UPDATE_PENALTY_TABLE: {
      return {
        ...state,
        penaltyOrders: action.payload
      }
    }
    case UPDATE_SUPPLIER_METRICS: {
      return { ...state, metrics: isEmpty(action.payload) ? INITIAL_STATE.metrics : action.payload }
    }

    case SET_PENALTY_TAB: {
      return {
        ...state,
        selectedTab: { ...action.payload }
      }
    }

    case SET_PENALTY_HISTORY_TAB: {
      const { tabName } = action.payload
      return {
        ...state,
        selectedHistoryTab: tabName
      }
    }

    case SET_PENALTY_PROGRAM_ROW_COUNT:
      return {
        ...state,
        totalRows: action.payload.count,
        downloadFileLimit:action.payload.downloadFileLimit || null
      }

    case SET_PENALTY_SEARCH_KEY_VALUE: {
      return {
        ...state,
        penaltySearch: action.payload
      }
    }

    case CLEAR_PENALTY_SEARCH_KEY_VALUE: {
      const existingPair = state.penaltySearch
      return {
        ...state,
        penaltySearch: { searchKey: existingPair.searchKey, searchValue: '' }
      }
    }

    case RESET_PENALTY_ITEM_SEARCH: {
      return {
        ...state,
        penaltySearch: INITIAL_STATE.penaltySearch
      }
    }

    case SET_SELECTED_PENALTY_ROWS: {
      return {
        ...state,
        selectedRows: action.payload
      }
    }

    case UPDATE_CHARGEBACK_DETAILS: {
      return {
        ...state,
        chargebackDetails: parseChargeBackDetails(action.payload)
      }
    }

    case SHOW_HIDE_EXCLUDE_POPUP: {
      return {
        ...state,
        showPopup: action.payload
      }
    }

    case SET_EXCLUDE_PENALTY_REASON: {
      return {
        ...state,
        excludePenaltyReason: action.payload
      }
    }

    case SET_EXCLUDE_PENALTY_DESCRIPTION: {
      return {
        ...state,
        excludePenaltyDescription: action.payload
      }
    }

    case SET_EXCLUDE_PENALTY_REASON_ERROR: {
      return {
        ...state,
        isSelectedReasonError: action.payload
      }
    }

    case SET_EXCLUDE_PENALTY_DESCRIPTION_ERROR: {
      return {
        ...state,
        isSelectedDescriptionError: action.payload
      }
    }

    case SET_PENALTY_STATUS: {
      const status = state.penaltySearchFilter
      const { name, isChecked } = action.payload
      const filter = find(status.penaltyStatus, { name }, false)
      if (filter) {
        filter.checked = isChecked
      }
      return {
        ...state,
        penaltySearchFilter: { ...cloneDeep(status) }
      }
    }

    case SET_FILTER_KEY_VALUE: {
      const { searchKey, searchValue } = action.param
      const newPenaltyFilter = cloneDeep(state.penaltySearchFilter)

      if (searchKey && searchKey.includes('Date')) {
        // contains dates and add dates to respective field
        const dates = searchKey.split('-')
        /* eslint-disable */
        newPenaltyFilter[dates[0]][dates[1]] = moment(searchValue._d).format('YYYY-MM-DD')
        /* eslint-enable */
      }

      const isValid = validateParams({ newPenaltyFilter })
      newPenaltyFilter.search = isValid || validateDates({ newPenaltyFilter })
      return {
        ...state,
        penaltySearchFilter: { ...cloneDeep(newPenaltyFilter) }
      }
    }

    case CLEAR_PENALTY_FILTER:
      {
        const newPenaltyFilter = cloneDeep(state.penaltySearchFilter)
        newPenaltyFilter.search = true // disabled search reset button
        newPenaltyFilter.penaltyDateRange.from = null
        newPenaltyFilter.penaltyDateRange.to = null
        newPenaltyFilter.penaltyStatus.forEach(penalty => penalty.checked = false)
        return {
          ...state,
          penaltySearchFilter: {
            ...cloneDeep(newPenaltyFilter)
          }
        }
      }

    default:
      return { ...state }
  }
}

