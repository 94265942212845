import { Typography, Box } from '@mui/material'
import { withStyles } from '@mui/styles'
import { filter, isEmpty, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import CollapsiblePanel from '../../components/CollapsiblePanel'
import SSHNonPaginatedTable from '../../components/SSHNonPaginatedTable'
import {
  ACTIVE_ITEM_STATUSES,
  CANCELLED_ITEM_STATUSES,
  ORDER_DETAILS_API_STATUS_LIST,
  SHIPPED_ITEM_STATUSES
} from '../../config/genericConstants'

const styles = theme => ({
  panelAction: {
    position: 'absolute',
    right: theme.spacing(4),
    marginTop: theme.spacing(2)
  }
})

class Summary extends PureComponent {
  static columns = [
    { name: 'itemDesc', title: 'Item name', sorting: false },
    { name: 'itemNumber', title: 'Item ID', sorting: false },
    { name: 'skuId', title: 'SKU ID', sorting: false },
    { name: 'upcNumber', title: 'UPC', sorting: false },
    { name: 'orignalfulfilmentQty', title: 'Ordered', sorting: false },
    { name: 'currentfulfilmentQty', title: 'Pending', sorting: false },
    { name: 'shipped', title: 'Shipped', sorting: false },
    { name: 'cancelled', title: 'Cancelled', sorting: false },
    { name: 'includeGiftLabel', title: 'Gift', sorting: false }
  ]

  static columnExtensions = [
    { columnName: 'itemDesc' },
    { columnName: 'itemNumber', width: 120, align: 'right' },
    { columnName: 'skuId', width: 120, align: 'right' },
    { columnName: 'upcNumber', width: 120, align: 'right' },
    { columnName: 'orignalfulfilmentQty', width: 120, align: 'right' },
    { columnName: 'currentfulfilmentQty', width: 120, align: 'right' },
    { columnName: 'shipped', width: 120, align: 'right' },
    { columnName: 'cancelled', width: 120, align: 'right' },
    { columnName: 'includeGiftLabel', width: 80, align: 'center' }
  ]

  constructor(props) {
    super(props)
    this.state = {
      items: [],
      legacyPONumber: ''
    }
  }

  static getDerivedStateFromProps = ({ release }) => {
    if (isEmpty(release)) {
      return {
        items: [],
        legacyPONumber: ''
      }
    }
    const { itemDetails, basicDetails } = release
    const items = uniqBy(itemDetails, 'itemNumber')

    const { legacyPONumber } = basicDetails

    const newItems = []
    // eslint-disable-next-line array-callback-return
    items.forEach(item => {
      const shipped = filter(itemDetails, itemStatus => {
        return (
          itemStatus.itemNumber === item.itemNumber &&
          (itemStatus.status === SHIPPED_ITEM_STATUSES[0] || itemStatus.status === ORDER_DETAILS_API_STATUS_LIST[3])
        )
      })
      const cancelled = filter(itemDetails, { itemNumber: item.itemNumber, status: CANCELLED_ITEM_STATUSES[0] })
      const pending = filter(itemDetails, itemStatus => {
        return itemStatus.itemNumber === item.itemNumber && ACTIVE_ITEM_STATUSES.includes(itemStatus.status)
      })

      newItems.push({
        ...item,
        currentfulfilmentQty: pending.length > 0 ? pending[0].currentfulfilmentQty : 0,
        shipped: shipped.length > 0 ? shipped[0].currentfulfilmentQty : 0,
        cancelled: cancelled.length > 0 ? cancelled[0].currentfulfilmentQty : 0
      })
    })
    return {
      items: newItems,
      legacyPONumber
    }
  }

  render = () => {
    const { isFetching, classes } = this.props
    const { items, legacyPONumber } = this.state

    if (isEmpty(items)) {
      return null
    }

    return (
      <>
        <Box className={classes.panelAction}>
          {!isEmpty(legacyPONumber) && (
            <Typography variant="body2">
              <span>PO number : </span>
              {legacyPONumber}
            </Typography>
          )}
        </Box>
        <Box>
          <CollapsiblePanel title="PO Summary" defaultOpen={false} padding={4} caption={`${items.length} items`}>
            <SSHNonPaginatedTable
              columns={Summary.columns}
              rows={items}
              tableColumnExtensions={Summary.columnExtensions}
              isFetching={isFetching}
            />
          </CollapsiblePanel>
        </Box>
      </>
    )
  }
}

Summary.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  order: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  release: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired
}

export default withStyles(styles)(Summary)
