/* eslint-disable react/prop-types */
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { APP_CONFIG } from '../config/appConfigs'
import { ai, getAppInsights } from '../utils/TelemetryService'

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a
 * Class Component rather than a Functional Component
 */

class TelemetryProvider extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = { initialized: false, isUserSet: false }

  componentDidMount = () => {
    const { initialized } = this.state
    const { history } = this.props
    if (!initialized && Boolean(APP_CONFIG.ENV().appInsightsKey)) {
      ai.initialize(APP_CONFIG.ENV().appInsightsKey, history)
      this.initializeState()
    }
  }

  componentDidUpdate = () => {
    this.initializeUser()
  }

  shouldComponentUpdate = () => {
    const { isUserSet } = this.state
    return !isUserSet
  }

  initializeUser = () => {
    const { initialized, isUserSet } = this.state
    const { trackUser } = this.props

    if (initialized && !isUserSet && Boolean(trackUser.loginId)) {
      getAppInsights().setAuthenticatedUserContext(trackUser.loginId, trackUser.loginId)
      getAppInsights().context.user.id = trackUser.loginId
      getAppInsights().context.session.id = trackUser.sessionId
      this.setState({ isUserSet: true })
    }
  }

  initializeState = () => {
    this.setState({ initialized: true })
  }

  render() {
    const { children } = this.props
    return <>{children}</>
  }
}

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider))
