import React from 'react'
import { Grid, Typography, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { GENERAL_ERROR } from '../../config/errorConstants'
import error from '../../../src/assets/images/ErrorPage.svg'


const useStyles = makeStyles(theme => ({
    grid: {
        height: `${theme.replacePX(theme.spacing(12.5))}vh`
    },
    logo: {
        'text-align': 'center',
        '& img': {
            width: theme.spacing(24),
            height: 'auto',
        }
    },
    text: {
        color: theme.palette.primary.persianBlue,
        fontSize: theme.spacing(2),
        fontWeight: 400,
        width:theme.spacing(69),
        textAlign:'center',
        '& a': {
            color: theme.palette.primary.blue90,
            'text-decoration':'none'
        }
    },
    box: {
        textAlign: 'center',
        margin: '0 auto',
        padding:theme.spacing(1),
        '& h4': {
            fontWeight: 500,
            fontSize: theme.spacing(3)
        }
    },
    refresh: {
        textAlign: 'center',
        margin: theme.spacing(2),
        '& a': {
            margin: '0 auto',
            color: theme.palette.primary.blue90,
            fontSize: theme.spacing(2),
            fontWeight: '500',
            borderRadius: theme.spacing(1)
        }
    }
}))

const ErrorPage = () => {

    const classes = useStyles()

    const email = GENERAL_ERROR.replace('dsvsup1@samsclub.com',
    `<a href="mailto:${process.env.REACT_APP_SUPPORT_EMAIL}" target=_blank"">${process.env.REACT_APP_SUPPORT_EMAIL}</a>`)

    return (
        <Grid container spacing={4} justifyContent="center" alignItems="center" alignContent='center' className={classes.grid}>
            <Grid item>
                <Box className={classes.logo}>
                    <img src={error} alt='sams logo' />
                </Box>
                <Box className={classes.box}>
                    <Typography variant='h4'>
                        {GENERAL_ERROR.substring(0, 20)}
                    </Typography>
                </Box>
                <Typography variant='h4' className={classes.text} dangerouslySetInnerHTML={{
                    __html: email
                }}>
                </Typography>
                <Box className={classes.refresh}>
                    <a href="/" className={classes}>Refresh</a>
                </Box>
            </Grid>
        </Grid>
    )
}

export default ErrorPage
