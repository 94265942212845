/* eslint-disable no-case-declarations */
import { cloneDeep } from 'lodash'
import {
  SET_MODULE_STATUS_TAB,
  SET_ADMIN_TAB,
  TOGGLE_HISTORY_VIEW,
  TOGGLE_REVIEW_BOX,
  SUPPLIER_DATA_SUCCESS,
  SET_AR_MONTH_VALUE,
  SET_SELECTED_SUPPLIER,
  RESET_SUPPLIER_SELECTION
} from './Approvals.actionTypes'
import { PENALTY, PENDING, ACCOUNTS_RECEIVABLE } from './commonConstants'
import { getARMonthValues } from '../../utils/common'
import { SMART_INVENTORY_DROPDOWN_MODEL } from '../../config/genericConstants'

export const INITIAL_STATE = {
  isCompletedView: false, // false or true
  selectedStatusTab: PENDING, // Pending or Resolved
  selectedAdminTab: PENALTY, // LeadTime or Penalty or SupplierMngt or AccountsReceivable
  rejectPopup: {
    show: false,
    task: {}
  },
  selectedARMonth: getARMonthValues()[0],
  selectedSuppliers: [],
  supplierList: [],
  disableBtnGrp: false
}

export default (state = cloneDeep(INITIAL_STATE), { type, payload, response } = action) => {
  switch (type) {
    case SET_MODULE_STATUS_TAB:
      const { tabName } = payload
      return {
        ...state,
        selectedStatusTab: tabName
      }

    case SET_ADMIN_TAB:
      const { selectedAdminTab } = payload
      return {
        ...state,
        selectedAdminTab,
        selectedARMonth: INITIAL_STATE.selectedARMonth,
        disableBtnGrp: (selectedAdminTab === ACCOUNTS_RECEIVABLE && state.selectedARMonth.id === 1) ? true : false
      }

    case TOGGLE_HISTORY_VIEW:
      return {
        ...state,
        isCompletedView: payload.isCompletedView
      }

    case TOGGLE_REVIEW_BOX: {
      const IS_OPEN = state.rejectPopup.show
      return {
        ...state,
        rejectPopup: {
          show: !IS_OPEN,
          task: IS_OPEN ? {} : payload.task
        }
      }
    }

    case SUPPLIER_DATA_SUCCESS: {
      const { payload } = response
      const updatedList = payload.map(item => {
        return {
          value: item?.supplierId,
          id: item?.supplierId,
          title: item?.supplierName,
          active: true
        }
      })
      updatedList.splice(0, 0, { ...SMART_INVENTORY_DROPDOWN_MODEL, active: true })
      return {
        ...state,
        supplierList: updatedList
      }
    }

    case SET_AR_MONTH_VALUE:
      return {
        ...state,
        selectedARMonth: payload,
        disableBtnGrp: (payload.id === 1) ? true : false
      }

    case SET_SELECTED_SUPPLIER:
      const { updated, selected } = payload
      return {
        ...state,
        selectedSuppliers: selected,
        supplierList: updated
      }

    case RESET_SUPPLIER_SELECTION:
      return {
        ...state,
        selectedSuppliers: [],
        supplierList: state.supplierList.map(val => {
          return {
            ...val,
            active: true
          }
        })
      }

    default:
      return { ...state }
  }
}
