import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Grow } from '@mui/material'
import { withStyles } from '@mui/styles'
import { CheckCircle, ErrorOutline, Refresh } from '@mui/icons-material'
import { get, includes, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SSHButton from '../../components/SSHButton'
import SSHTooltip from '../../components/SSHTooltip'
import { downloadErrorFile } from '../../pages/Feed/FeedStatusAction'
import { showBulkUpdatePopup } from '../../reduxSetup/commonActions/AppConfig'
import { aiFeedDownloadError, aiFeedUploadError } from '../../reduxSetup/commonActions/TelemetryActions'
import { allowAccess } from '../../utils/common'
import { ROLE_BACK_ACTIONS, API_MODULE_TYPES, CLUB_MODULE_TYPES } from '../../config/genericConstants'
import { FAILED_UPLOAD_TIP, NO_ERRORS_FEED_MSG } from '../../config/genericConstantsNew'
import { ReactComponent as DownloadIcon } from '../../assets/images/newDownload.svg'
import { ReactComponent as UploadIcon } from '../../assets/images/newUpload.svg'

const styles = (theme) => ({
  errorIcon: { marginBottom: -6 },
  btn: {
    textAlign: 'center',
    '& svg': {
      width: theme.spacing(3),
      height: theme.spacing(2)
    }
  }
})

class FeedActionsCell extends Component {
  constructor(props) {
    super(props)
    this.state = { downloading: false, downloaded: false, error: false, disabled: false, failed: false }
  }

  static getDerivedStateFromProps = newProps => {
    const REDUX_FEED = get(newProps, 'downloads', []).find(feed => feed.ackId === newProps.row.feedId) || {}
    const IS_DISABLED = parseInt(newProps.row.errors, 10) === 0
    const IS_FAILED = String(newProps.row.feedStatus).toLowerCase() === 'failed'

    if (isEmpty(REDUX_FEED)) {
      return { downloading: false, downloaded: false, error: false, disabled: IS_DISABLED, failed: IS_FAILED }
    }

    const IS_DOWNLOADED = get(REDUX_FEED, 'downloaded', false)
    const IS_DOWNLOADING =
      includes(['INITIATED', 'IN_PROGRESS'], get(REDUX_FEED, 'downloadFileExist', null)) ||
      (IS_DOWNLOADED === false && get(REDUX_FEED, 'downloadFileExist', null) !== 'FAILED')
    const IS_ERROR = REDUX_FEED.downloadFileExist === 'FAILED'

    return { downloading: IS_DOWNLOADING, downloaded: IS_DOWNLOADED, error: IS_ERROR, disabled: IS_DISABLED }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const { downloading, downloaded } = this.state
    return (
      downloading !== nextState.downloading ||
      downloaded !== nextState.downloaded ||
      get(nextProps, 'row.feedId', null) !== get(this.props, 'row.feedId', null)
    )
  }

  onUpload = () => {
    const { aiFeedUploadError, showBulkUpdatePopup, row } = this.props
    aiFeedUploadError({ ackId: row.feedId, feedType: row.feedType })
    showBulkUpdatePopup({
      isFulfillment: row.feedType === 'ORDER',
      shipNodeId: row.shipNodeId,
      isPenalty: row.feedType === 'PENALTY',
      isPenaltyApproval: row.feedType === 'PENALTY_APPROVAL',
      isArApproval: row.feedType === 'AR',
      isInTransitInventory: row.feedType === API_MODULE_TYPES.inTransitInventory,
      isClubSaleForecast: row.feedType === CLUB_MODULE_TYPES.salesForecast,
      isDailyCapacity: row.feedType === API_MODULE_TYPES.dailyCapacity
    })
  }

  onDownload = (retry = false) => {
    const { aiFeedDownloadError, downloadErrorFile, row } = this.props
    const { feedId, shipNodeId, feedType } = row
    this.setState({ downloading: true, downloaded: false, error: false })
    aiFeedDownloadError({ ackId: feedId, feedType, retry })
    return downloadErrorFile({ ackId: feedId, shipNodeId, feedType, retry })
  }

  render = () => {
    const { classes, downloads, downloadErrorFile, showBulkUpdatePopup, ...restProps } = this.props
    const { downloading, downloaded, error, disabled, failed } = this.state

    let downloadtip
    let uploadtip
    let icon
    if (downloaded) {
      downloadtip = 'Error file downloaded successfully'
      icon = CheckCircle
    } else {
      downloadtip = disabled ? NO_ERRORS_FEED_MSG : 'Download Error Report'
      icon = DownloadIcon
    }

    if (failed) {
      uploadtip = downloadtip = FAILED_UPLOAD_TIP
    } else {
      uploadtip = disabled ? NO_ERRORS_FEED_MSG : 'Retry Upload'
    }

    const downloadStyle = disabled ? 'Disabled' : classes.btn
    return (
      <Table.Cell {...restProps}>
        {error ? (
          // eslint-disable-next-line react/jsx-boolean-value
          <Grow in={true} mountOnEnter unmountOnExit>
            <>
              <SSHTooltip title="Error occurred while downloading" placement="left">
                <ErrorOutline fontSize="small" color="error" className={classes.errorIcon} />
              </SSHTooltip>
              <SSHButton
                Icon={Refresh}
                size="large"
                tooltip="Click here to retry"
                onClick={() => this.onDownload(true)}
              />
            </>
          </Grow>
        ) : (
          allowAccess(ROLE_BACK_ACTIONS.DOWNLOAD) && (
            <SSHButton
              Icon={icon}
              disabled={downloaded || downloading || disabled || failed}
              size="small"
              className={downloadStyle}
              tooltip={downloadtip}
              loading={downloading}
              onClick={this.onDownload}
            />
          )
        )}
        {allowAccess(ROLE_BACK_ACTIONS.UPLOAD) && (
          <SSHButton
            Icon={UploadIcon}
            disabled={disabled || failed}
            size="small"
            className={disabled ? 'disabled' : classes.btn}
            tooltip={uploadtip}
            onClick={this.onUpload}
          />
        )}
      </Table.Cell>
    )
  }
}

FeedActionsCell.propTypes = {
  classes: PropTypes.object.isRequired,
  downloads: PropTypes.array.isRequired,
  downloadErrorFile: PropTypes.func.isRequired,
  showBulkUpdatePopup: PropTypes.func.isRequired,
  aiFeedDownloadError: PropTypes.func.isRequired,
  aiFeedUploadError: PropTypes.func.isRequired,
  row: PropTypes.shape({
    feedId: PropTypes.string.isRequired,
    feedType: PropTypes.string.isRequired,
    shipNodeId: PropTypes.string.isRequired
  }).isRequired
}

export const FeedActionsCellNaked = FeedActionsCell

const mapStateToProps = state => ({
  downloads: state.FeedNew.downloads
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ downloadErrorFile, showBulkUpdatePopup, aiFeedDownloadError, aiFeedUploadError }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FeedActionsCell))
