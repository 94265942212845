import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { CheckCircle } from '@mui/icons-material'
import React from 'react'

const styles = theme => ({
  textGreen: {
    color: theme.palette.primary.greenLight
  }
})

const Health = ({ classes }) => {
  return (
    <>
      <br />
      <br />
      <br />
      <Typography variant="h1" align="center" className={classes.textGreen}>
        <CheckCircle fontSize="inherit" color="inherit" />
      </Typography>
      <Typography variant="h4" align="center">
        Health Check &mdash; <b>All Good!</b>
      </Typography>
      <br />
    </>
  )
}

export default withStyles(styles)(Health)
