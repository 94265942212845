import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import LoggingIn from '../LoggingIn'
import LoggingOut from '../LoggingOut'
import { Box } from '@mui/material'

const PrivateRoute = ({ component: Component, loggedIn, loggingIn, access, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (loggingIn) return <LoggingIn />
        if (rest.loggingOut) {
          return <LoggingOut />
        }
        if (!loggingIn && !loggedIn && !rest.loggingOut) {
          return <Redirect to="/unauthorized" />
        }
        return (
          <Suspense fallback={<LoggingIn />}>
            <Box style={{marginTop:40}}>  {/* margitop common place */}
            <Component {...props} />
            </Box>
          </Suspense>
        )
      }}
    />
  )
}

PrivateRoute.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  loggingOut: PropTypes.bool.isRequired,
  access: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  loggedIn: state.UserInfo.loggedIn,
  loggingIn: state.UserInfo.loggingIn,
  loggingOut: state.UserInfo.loggingOut,
  access: { ...state.UserInfo.access }
})

export default connect(mapStateToProps, null)(PrivateRoute)
