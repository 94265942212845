import { cloneDeep } from 'lodash'
import {
    SET_ORDER_DEMAND_ITEM_COUNT,
    SET_SELECTED_ITEM,
    SET_CLUB_WEEKLY_DEMAND,
    SET_FILTERS_OPEN
} from './OrderDemand.types'

export const INITIAL_STATE = {
    totalOrderDemandItems: 0,
    selectedItem: {},
    selectedClub: {},
    isFilterOpen: false,
    displayWeeklyDrawer: false
}

export default (state = cloneDeep(INITIAL_STATE), action) => {
    switch (action.type) {
        case SET_ORDER_DEMAND_ITEM_COUNT:
            const { count } = action.payload
            return {
                ...state,
                totalOrderDemandItems: count
            }

        case SET_SELECTED_ITEM:
            const { itemDetails } = action.payload
            return {
                ...state,
                selectedItem: itemDetails
            }

        case SET_CLUB_WEEKLY_DEMAND:
            const { clubDetails, displayWeeklyDrawer } = action.payload
            return {
                ...state,
                selectedClub: clubDetails,
                displayWeeklyDrawer: displayWeeklyDrawer
            }

        case SET_FILTERS_OPEN:
            const { isOpen } = action.payload
            return {
                ...state,
                isFilterOpen: isOpen
            }

        default:
            return { ...state }
    }
}
