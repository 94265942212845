import moment from 'moment'
import { isEmpty } from 'lodash'
import { yearFirstFormat } from '../../config/genericConstants'

export const displayDateTitle = (noOfDays) => {
  const currentDate = new Date()
  return moment(currentDate.setDate(currentDate.getDate() + noOfDays)).format('ll')
}

export const qtyDateValue = (noOfDays) => {
  const currentDate = new Date()
  return moment(currentDate.setDate(currentDate.getDate() + noOfDays)).format(yearFirstFormat)
}

export const columns = [
  { name: 'itemId', title: 'Item ID', sorting: false, isLink: true,alias:'intransit' },
  { name: 'itemName', title: 'Item name', sorting: false },
  { name: 'upc', title: 'UPC', sorting: false },
  {
    name: 'vendorStockId',
    title: 'Vendor Stock ID',
    sorting: false,
    getCellValue: row => {
      return `${!isEmpty(row?.vendorStockId) ? row?.vendorStockId : '-'}`
    }
  },
  {
    name: 'totalQuantity',
    title: '30 days in-transit qty',
    sorting: false,
    getCellValue: row => {
      return `${!isEmpty(row?.totalQuantity) ? row?.totalQuantity : 0}`
    }
  },
  { name: 'inTransitInventoryDate', title: "Qty on " + displayDateTitle(1), sorting: false, date: qtyDateValue(1) },
  { name: 'inTransitInventoryDate', title: "Qty on " + displayDateTitle(2), sorting: false, date: qtyDateValue(2) },
  { name: 'inTransitInventoryDate', title: "Qty on " + displayDateTitle(3), sorting: false, date: qtyDateValue(3) }
]

export const columnExtensions = [
  { columnName: 'itemName', width: '20%' },
  { columnName: 'upc', align: 'right' },
  { columnName: 'vendorStockId', align: 'right' },
  { columnName: 'totalQuantity', align: 'right' }
]

export const formattedColumns = []