import { Box, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import React from 'react'
import moment from 'moment'

const styles = theme => ({
  footer: {
    justifySelf: 'flex-end',
    marginTop: 'auto',
    padding:theme.spacing(2),
    textAlign:'center',
    // color: theme.palette.grey[600],
    // position:'fixed',
    // bottom:0,
    backgroundColor: theme.palette.primary.headerBackground,
    color: theme.palette.primary.softBlue,
    borderTop: `1px solid ${theme.palette.primary.bottiCelli}`,
    // paddingBottom: theme.spacing(1)
  }
})

const Footer = ({ classes }) => {
  return (
    <Box className={classes.footer}>
      <Typography variant="body2" color="inherit">
        &copy; {moment.utc().format('YYYY')} Sam's Club. All rights reserved.
      </Typography>
    </Box>
  )
}

export default withStyles(styles)(Footer)
