import config from '../../config'
import {
  FETCH_PAGINATED_TABLE_DATA_FAILURE,
  FETCH_PAGINATED_TABLE_DATA_REQUEST,
  FETCH_PAGINATED_TABLE_DATA_SUCCESS
} from '../../reduxSetup/commonActions/SSHPaginatedTable.actionTypes'
import { aiInventorySearch, aiUpdateInTransitInventory } from '../../reduxSetup/commonActions/TelemetryActions'
import { CALL_API } from '../../reduxSetup/middleware/API'
import { getShipNodeIdFromState, getVendorIdFromState } from '../../utils/common'
import { isEmpty } from 'lodash'
import {
  SEARCH_INTRANSIT_ITEM_REQUEST,
  SEARCH_INTRANSIT_ITEM_SUCCESS,
  SEARCH_INTRANSIT_ITEM_FAILURE,
  INTRANSIT_UPDATE_REQUEST,
  INTRANSIT_UPDATE_SUCCESS,
  INTRANSIT_UPDATE_FAILURE,
  SET_INTRANSIT_ITEM_COUNT,
  SET_ITEM_INVENTORY_PAGE,
  SET_EDIT_TABLE,
  SET_FILTER_OPEN,
  SET_REQUEST_PAYLOAD,
  CLEAR_REQUEST_PAYLOAD,
  SET_FILTER_PARAMS,
  CLEAR_FILTER_PARAMS,
  SET_EDIT_CALENDAR,
  RESET_ITEM_DETAILS
} from './InTransitInventory.types'
import { changePage } from '../../reduxSetup/commonActions/AppConfig'
import { showAlert } from '../../templates/Error/ErrorHandlerActions'
import { SUCCESS, ERROR } from '../../templates/Error/ErrorHandler.actionTypes'
import { UPDATE_INTRANSIT_SUCCESS_MSG, UPDATE_INTRANSIT_ERROR_MSG } from './commonConstants'

/**
 * @description Action to fetch in-transit items on load
 * @return {function(any, any=): any}
 */
export const fetchInTransitInventoryItems = () => (dispatch, getState) => {
  const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
  const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId
  const IS_SEARCH = getState().SSHPaginatedTableReducer.searchQuery

  if (IS_SEARCH) {
    return dispatch(searchIntransitItem())
  }

  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_PAGINATED_TABLE_DATA_REQUEST,
        FETCH_PAGINATED_TABLE_DATA_SUCCESS,
        FETCH_PAGINATED_TABLE_DATA_FAILURE
      ],
      endpoint: `${config.get('inTransitInventory')}/supplier/${VENDOR_ID}/shipNode/${SHIP_NODE_ID}?pageNo=${PAGE_NO +
        1}&pageSize=${PAGE_SIZE}`,
      method: 'GET',
      payload: {
        vendorId: VENDOR_ID,
        shipNodeId: SHIP_NODE_ID,
        PAGE_SIZE,
        PAGE_NO,
        searchQuery: null,
        pathName: getState().router.location.pathname
      }
    }
  }).then(({ type, response }) => {
    if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS) {
      return dispatch(setIntransitItemCount({ count: response?.payload?.total || 0 }))
    }
    return false
  })
}

export const setIntransitItemCount = ({ count }) => dispatch => {
  return dispatch({
    type: SET_INTRANSIT_ITEM_COUNT,
    payload: { count }
  })
}

/**
 * @description set selected item details
 * @return {function(any, any=): any}
 */
export const setSelectedItem = ({ itemDetails }) => (dispatch, getState) => {
  return dispatch({
    type: SET_ITEM_INVENTORY_PAGE,
    payload: { itemDetails }
  })
}

/**
 * @description reset selected item details
 * @return {function(any, any=): any}
 */
export const resetItemDetails = () => dispatch => {
  return dispatch({
    type: RESET_ITEM_DETAILS
  })
}

/**
 * @description fetch in transit details information from path on page refresh
 * @return {function(any, any=): any}
 */
export const fetchInTransitItemsFromPath = (itemId) => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId
  return dispatch({
    [CALL_API]: {
      types: [SEARCH_INTRANSIT_ITEM_REQUEST, SEARCH_INTRANSIT_ITEM_SUCCESS, SEARCH_INTRANSIT_ITEM_FAILURE],
      endpoint: `${config.get('inTransitInventory')}/supplier/${VENDOR_ID}/shipNode/${SHIP_NODE_ID}?itemIds=${itemId}`,
      method: 'GET',
      payload: {
        vendorId: VENDOR_ID,
        shipNodeId: SHIP_NODE_ID,
        pathName: getState().router.location.pathname
      }
    }
  }).then(({ type, response }) => {
    if (type === SEARCH_INTRANSIT_ITEM_SUCCESS) {
      return dispatch(setSelectedItem({ itemDetails: response?.payload?.items[0] }))
    }
    return false
  })
}

/**
 * @description Set in transit inventory search params
 * @param {object} searchQuery
 * @return {function(any, any=): any}
 */
export const setSearchParams = ({ searchKey, searchValue }) => dispatch => {
  return dispatch({
    type: SET_FILTER_PARAMS,
    param: {
      searchKey,
      searchValue
    }
  })
}

/**
 * @description clear filter parameters
 * @return {function(any, any=): any}
 */
export const clearFilterParams = () => dispatch => {
  return dispatch({
    type: CLEAR_FILTER_PARAMS
  })
}

/**
 * @description clear request payload
 * @return {function(any, any=): any}
 */
export const clearRequestPayload = () => dispatch => {
  return dispatch({
    type: CLEAR_REQUEST_PAYLOAD
  })
}

/**
 * @description Action to search in-transit items based on itemId
 * @param {object} searchQuery
 * @return {function(any, any=): any}
 */
export const searchIntransitItem = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId
  const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
  const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
  const { itemIds, inTransitDate } = getState().InTransitInventory.searchParams
  const pathParams = getSearchParams({ itemIds, inTransitDate })

  return dispatch({
    [CALL_API]: {
      types: [SEARCH_INTRANSIT_ITEM_REQUEST, SEARCH_INTRANSIT_ITEM_SUCCESS, SEARCH_INTRANSIT_ITEM_FAILURE],
      endpoint: `${config.get('inTransitInventory')}/supplier/${VENDOR_ID}/shipNode/${SHIP_NODE_ID}?${pathParams}&pageNo=${PAGE_NO +
        1}&pageSize=${PAGE_SIZE}`,
      method: 'GET',
      payload: {
        vendorId: VENDOR_ID,
        shipNodeId: SHIP_NODE_ID,
        PAGE_SIZE,
        PAGE_NO,
        searchQuery: itemIds,
        pathName: getState().router.location.pathname
      }
    }
  }).then(({ type, response }) => {
    dispatch(aiInventorySearch())
    if (type === SEARCH_INTRANSIT_ITEM_SUCCESS) {
      return dispatch(setIntransitItemCount({ count: response?.payload?.total || 0 }))
    }
    return null
  })
}

/**
 * @description get the search params selected
 * @param {object} {searchQuery, itemIds, inTransitDate}
 * @return path param string
 */
export const getSearchParams = ({ itemIds, inTransitDate }) => {
  let param = ''
  if (!isEmpty(itemIds)) {
    param = param + `itemIds=${itemIds}`
  }
  if (inTransitDate.from && inTransitDate.to) {
    param = param + `&fromDate=${inTransitDate.from}&toDate=${inTransitDate.to}`
  }
  return param
}

/**
 * @description Navigate to Editable Qty of next 30 days for selected item
 * @param {object} itemDetails
 * @return {function(any, any=): any}
 */
export const goToItemInventory = ({ itemDetails }) => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId
  const { itemId } = itemDetails
  dispatch(changePage(`/in-transit-inventory/vendor/${VENDOR_ID}/shipNode/${SHIP_NODE_ID}/item/${itemId}`))
  dispatch(setSelectedItem({ itemDetails }))
}

/**
 * @description set edit table flag
 * @param {boolean} editable
 * @return {function(any, any=): any}
 */
export const setEditTable = (editable) => dispatch => {
  return dispatch({
    type: SET_EDIT_TABLE,
    payload: { editable }
  })
}

/**
 * @description set filter drawer open flag
 * @param {boolean} filterOpen
 * @return {function(any, any=): any}
 */
export const setFilterOpen = (filterOpen) => dispatch => {
  return dispatch({
    type: SET_FILTER_OPEN,
    payload: { filterOpen }
  })
}

/**
 * @description set request payload on update
 * @param {object} itemDetails
 * @return {function(any, any=): any}
 */
export const setRequestPayload = (itemDetails) => dispatch => {
  return dispatch({
    type: SET_REQUEST_PAYLOAD,
    payload: { itemDetails }
  })
}

/**
 * @description enable edit schedular drawer
 * @param {boolean} enable
 * @return {function(any, any=): any}
 */
export const enableEditScheduler = (enable) => dispatch => {
  return dispatch({
    type: SET_EDIT_CALENDAR,
    payload: { enable }
  })
}

/**
 * @description Update in-transit Inventory date
 * @param {object} itemDetails
 * @return {function(any, any=): any}
 */
export const updateInTransitInventory = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId
  const reqObj = getState().InTransitInventory.requestPayload
  const selectedItem = getState().InTransitInventory.selectedItem
  const payload = {
    "shipNodeId": SHIP_NODE_ID,
    "supplierId": VENDOR_ID,
    "items": reqObj
  }
  return dispatch({
    [CALL_API]: {
      types: [INTRANSIT_UPDATE_REQUEST, INTRANSIT_UPDATE_SUCCESS, INTRANSIT_UPDATE_FAILURE],
      endpoint: `${config.get('inTransitInventory')}/supplier/${VENDOR_ID}/shipNode/${SHIP_NODE_ID}`,
      method: 'POST',
      data: payload
    }
  }).then(({ type, response }) => {
    dispatch(aiUpdateInTransitInventory({ payload }))
    if (type === INTRANSIT_UPDATE_SUCCESS) {
      dispatch(showAlert(SUCCESS, UPDATE_INTRANSIT_SUCCESS_MSG))
      setTimeout(() => {
        !isEmpty(selectedItem) ? dispatch(fetchInTransitItemsFromPath(selectedItem?.itemId)) : dispatch(fetchInTransitInventoryItems())
        dispatch(setEditTable(false))
      }, 2000)
    } else {
      dispatch(showAlert(ERROR, UPDATE_INTRANSIT_ERROR_MSG))
    }
  })
}