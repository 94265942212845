import { Grid, Typography } from '@mui/material'
import React, { PureComponent } from 'react'
import SSHLoadingAnimation from '../components/SSHLoadingAnimation'

class LoggingOut extends PureComponent {
  render = () => {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" spacing={4}>
        <Grid item>
          <Typography variant="body1">Signing Out...</Typography>
        </Grid>
        <Grid item>
          <SSHLoadingAnimation />
        </Grid>
      </Grid>
    )
  }
}

export default LoggingOut
