import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Paper } from '@mui/material'
import SSHPaginatedTable from '../../../../components/SSHPaginatedTable'
import SSHPaginatedTableLoading from '../../../../components/SSHPaginatedTableLoading'
import { changePageSize, setCurrentPage } from '../../../../reduxSetup/commonActions/SSHPaginatedTable'
import { columns, columnExtensions } from './columns'
import { fetchOrderDemandClubs } from '../OrderDemandAction'

class OrderDemandClubTable extends PureComponent {
    /**
    * @description set current page for the clubs table
    * @param {number} current Page number
    * @returns {function} setCurrentPage
    */
    setCurrentPage = value => {
        const { setCurrentPage, fetchOrderDemandClubs } = this.props
        return setCurrentPage(value).then(fetchOrderDemandClubs())
    }

    /**
    * @description change page size for the clubs table
    * @param {number} updated page size value
    * @returns {function} changePageSize
    */
    changePageSize = value => {
        const { changePageSize, fetchOrderDemandClubs, totalRows } = this.props
        return changePageSize(value, totalRows).then(fetchOrderDemandClubs())
    }

    render() {
        const { isFetching, pageSize, pageNo, totalRows, rows } = this.props
        return (
            <Paper square style={{ position: 'relative' }} className="pager">
                <SSHPaginatedTable
                    pageNo={pageNo}
                    pageSize={pageSize}
                    rows={rows}
                    noDataMessage="No clubs found for selected item"
                    columns={columns}
                    isFetching={isFetching}
                    tableColumnExtensions={columnExtensions}
                    totalRows={totalRows}
                    setCurrentPage={this.setCurrentPage}
                    changePageSize={this.changePageSize}
                />
                {isFetching && <SSHPaginatedTableLoading />}
            </Paper>
        )
    }
}

export const OrderDemandClubTableNaked = OrderDemandClubTable

OrderDemandClubTable.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    pageNo: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    changePageSize: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    isFetching: state.SSHPaginatedTableReducer.isFetching,
    pageNo: state.SSHPaginatedTableReducer.pageNo,
    pageSize: state.SSHPaginatedTableReducer.pageSize,
    rows: state.SSHPaginatedTableReducer.rows,
    totalRows: state.ClubOrderDemand.totalOrderDemandItems
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchOrderDemandClubs,
            changePageSize,
            setCurrentPage
        },
        dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(OrderDemandClubTable)
