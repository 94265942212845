/* eslint-disable no-case-declarations */
import { cloneDeep, findIndex, filter, some, flatten } from 'lodash'
import moment from 'moment'
import {
    SET_DATE_RANGE,
    RESET_DATE_RANGE,
    TOGGLE_EDIT_CAPACITY,
    SET_EDITED_CAPACITY,
    RESET_EDITED_CAPACITY,
    SET_DOWNLOAD_ALL,
    STORE_CAPACITY_NODES,
    SET_SHIPNODE_SELECTED,
    SET_ACTIVE_TAB,
    SET_CHANGELOG_COUNT,
    SET_FAILED_CAPACITY
} from './Capacity.actionTypes'

const formatDate = 'MM-DD-YYYY'
export const INITIAL_STATE = {
    selectedDate: {
        fromDate: moment().format(formatDate),
        toDate: moment().add(14, 'days').format(formatDate)
    },
    isEditCapacity: false,
    isDownloadAll: true,
    editedCapacity: [],
    capacityShipnodes: [],
    selectedShipnode: {},
    activeTab: 0, //0:leadTime or 1:capacity
    totalCount: 0,
    totalChangelogCount: 0,
    failCapacity: false
}

/**
 * @description normalized the edited capacity
 * @param {*} param0 
 * @returns 
 */
const normalizeEditedCapacity = ({ state, payload }) => {
    const { date, capacity, shipNodeId, shipNodeName } = payload

    const index = findIndex(state.editedCapacity, { shipNodeId, date }) // find existing ship node
    if (index === -1) {
        return [
            ...state.editedCapacity,
            {
                shipNodeId,
                shipNodeName,
                date,
                capacity
            }
        ]
    } else {
        const updatedCapacity = [...state.editedCapacity] // if exisiting udpate the capacity
        updatedCapacity[index].capacity = capacity
        return updatedCapacity
    }

}

export default (state = cloneDeep(INITIAL_STATE), action) => {
    switch (action.type) {
        case SET_DATE_RANGE: {
            const { from, to } = action.payload
            return {
                ...state,
                selectedDate: {
                    fromDate: from,
                    toDate: to
                }
            }
        }

        case RESET_DATE_RANGE: {
            return {
                ...state,
                selectedDate: {
                    fromDate: INITIAL_STATE.selectedDate.fromDate,
                    toDate: INITIAL_STATE.selectedDate.toDate
                }
            }
        }

        case TOGGLE_EDIT_CAPACITY: {
            const { payload } = action
            return {
                ...state,
                isEditCapacity: payload,
                failCapacity: false
            }
        }

        case SET_EDITED_CAPACITY: {
            const { payload } = action
            return {
                ...state,
                editedCapacity: normalizeEditedCapacity({ state, payload })
            }
        }

        case RESET_EDITED_CAPACITY:
            return {
                ...state,
                editedCapacity: []
            }

        case SET_DOWNLOAD_ALL:
            {
                const { payload } = action
                return {
                    ...state,
                    isDownloadAll: payload
                }
            }

        case STORE_CAPACITY_NODES: {
            const { capacityNodes, totalCount } = action.payload
            return {
                ...state,
                capacityShipnodes: [...capacityNodes],
                totalCount
            }
        }

        case SET_SHIPNODE_SELECTED: {
            const { payload } = action
            return {
                ...state,
                selectedShipnode: payload
            }
        }

        case SET_ACTIVE_TAB: {
            const { payload } = action
            return {
                ...state,
                activeTab: payload
            }
        }

        case SET_CHANGELOG_COUNT: {
            const { payload } = action
            return {
                ...state,
                totalChangelogCount: payload
            }
        }

        case SET_FAILED_CAPACITY:
            {
                const { payload } = action
                const { editedCapacity } = state

                let onlyFail = filter(editedCapacity, (o) => {
                    return some(payload, (z) => {
                        return o.shipNodeId === z.shipNodeId && o.date === z.date
                    })
                })
                return {
                    ...state,
                    editedCapacity: onlyFail,
                    failCapacity: true,
                }
            }

        default:
            return { ...state }
    }
}