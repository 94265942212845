import { isEmpty, get } from 'lodash'
import config from '../../config'
// eslint-disable-next-line import/no-cycle
import { CALL_API } from '../../reduxSetup/middleware/API'
import {
    BOARD_SUPPLIER_REQUEST,
    BOARD_SUPPLIER_SUCCESS,
    BOARD_SUPPLIER_FAILURE,
    RESET_ONBOARD,
    OPEN_SHIPNODE_FORM,
    OPEN_DEACTIVATE_FORM,
    SET_REQUEST_DESCRIPTION,
    GET_SHIP_NODES_DETAILS_REQUEST,
    GET_SHIP_NODES_DETAILS_SUCCESS,
    GET_SHIP_NODES_DETAILS_FAILURE,
    CREATE_SHIPNODE_REQUEST,
    CREATE_SHIPNODE_SUCCESS,
    CREATE_SHIPNODE_FAILURE,
    DEACTIVE_SHIPNODE_FAILURE,
    DEACTIVE_SHIPNODE_SUCCESS,
    DEACTIVE_SHIPNODE_REQUEST,
    CLEAR_SHIPNODES_DETAILS,
    SET_HISTORY_ROW_COUNT,
    SET_SHIPNODE_FORMDATA,
    OPEN_VIEW_CHANGES_POPUP,
    APPROVE_REJECT_REQUEST,
    APPROVE_REJECT_FAILURE,
    APPROVE_REJECT_SUCCESS,
    SET_IS_FETCHING,
    SET_SUPPLIER_ROLES,
    SET_SUPPLIER_ROLES_PAYLOAD,
    SUPPLIER_FEATURES_REQUEST,
    SUPPLIER_FEATURES_SUCCESS,
    SUPPLIER_FEATURES_FAILURE,
    SUPPLIER_FEATURES_UPDATE_REQUEST,
    SUPPLIER_FEATURES_UPDATE_SUCCESS,
    SUPPLIER_FEATURES_UPDATE_FAILURE
} from './SupplierOnBoardAction.types'
import {
    FETCH_PAGINATED_TABLE_DATA_FAILURE,
    FETCH_PAGINATED_TABLE_DATA_REQUEST,
    FETCH_PAGINATED_TABLE_DATA_SUCCESS
} from '../../reduxSetup/commonActions/SSHPaginatedTable.actionTypes'
import {
    aiCreateUpdateShipnode,
    aiOnBoardSupplier,
    aiShipnodeDetails,
    aiShipnodeHistory,
    aiDeactivateShipnode,
    aiShipnodeApproveReject,
    aiSupplierFeatures
} from '../../reduxSetup/commonActions/TelemetryActionsNew'
import { getVendorIdFromState } from '../../utils/common'
import { getVendorRoles } from '../../reduxSetup/commonActions/AppConfig'
import { showAlert, closeAlert } from '../../templates/Error/ErrorHandlerActions'
import { SUCCESS, ERROR } from '../../templates/Error/ErrorHandler.actionTypes'
import { ERROR_UPDATING_SHIPNODE } from '../../config/errorConstants'
import { markTaskAsRejected } from '../LeadTime/LeadTimeAction'
import { ROUTE_MODULES } from '../../config/genericConstants'
import { COUNTRY_LIST } from '../../config/genericConstantsNew'
import { OPERATING_CALENDAR, ROLES_UPDATE_ERROR_MESSAGE } from './commonConstants'

/**
 * @description On board supplier onformation in SSH
 * @returns {function(*, *=)}
 */
export const OnBoardSupplier = () => (dispatch, getState) => {
    const SUPPLIER_ID = getVendorIdFromState(getState)
    const { supplierRolesPayload } = getState().SupplierOnBoard

    if (isEmpty(SUPPLIER_ID)) {
        return Promise.resolve()
    }

    return dispatch({
        [CALL_API]: {
            types: [BOARD_SUPPLIER_REQUEST, BOARD_SUPPLIER_SUCCESS, BOARD_SUPPLIER_FAILURE],
            endpoint: `${config.get('onBoard')}`,
            method: 'POST',
            data: {
                supplierId: SUPPLIER_ID,
                payload: dispatch(filterRolesPayload({ supplierRolesPayload, isUpdate: false }))
            }
        }
    }).then(({ type }) => {
        dispatch(aiOnBoardSupplier({ VENDOR_ID: SUPPLIER_ID, STATUS: 'ONBOARD' }))
        if (type === BOARD_SUPPLIER_SUCCESS) {
            dispatch(getVendorRoles()).then(() => {
                dispatch(resetOnBoarding())
                dispatch(getShipNodeDetails())
                setTimeout(() => {
                    dispatch(closeAlert())
                }, 2000)
            })
        }
    })
}

/**
 * @description filter the supplier roles payload based on any features selected or not
 * @returns []
 */
export const filterRolesPayload = ({ supplierRolesPayload, isUpdate }) => () => {
    return supplierRolesPayload.filter(val => {
        delete val.name
        delete val.enabled
        if (isUpdate && val.onboarded) {
            return val.features
        }
        return val.features.some(role => role.enabled)
    })
}

/**
 * @description reset on boarding to initial state
 * @returns {function(*, *=)}
 */
export const resetOnBoarding = () => (dispatch, getState) => {
    return dispatch({
        type: RESET_ONBOARD
    })
}

/**
 * @description On board supplier onformation in SSH
 * @returns {function(*, *=)}
 */
export const OffBoardSupplier = () => (dispatch, getState) => {
    const SUPPLIER_ID = getVendorIdFromState(getState)
    if (isEmpty(SUPPLIER_ID)) {
        return Promise.resolve()
    }

    return dispatch({
        [CALL_API]: {
            types: [BOARD_SUPPLIER_REQUEST, BOARD_SUPPLIER_SUCCESS, BOARD_SUPPLIER_FAILURE],
            endpoint: `${config.get('offBoard')}/${SUPPLIER_ID}`,
            method: 'DELETE'
        }
    }).then(({ type }) => {
        dispatch(aiOnBoardSupplier({ VENDOR_ID: SUPPLIER_ID, STATUS: 'OFFBOARD' }))
        if (type === BOARD_SUPPLIER_SUCCESS) {
            dispatch(getVendorRoles()).then(() => {
                dispatch(resetOnBoarding())
                setTimeout(() => {
                    dispatch(closeAlert())
                }, 2000)
            })
        }
    })
}

/**
 * @description Open shipnode form
 * @returns {function(*, *=)}
 */
export const setOpenShipnodeForm = ({ isOpen, isCreate }) => dispatch => {
    return Promise.resolve(
        dispatch({
            type: OPEN_SHIPNODE_FORM,
            payload: { isOpen, isCreate }
        })).then(dispatch(clearShipnodeForm()))
}

/**
 * @description Set shipnode form Address
 * @param {Object} address
 * @returns {function(*, *=)}
 */
export const setShipnodeAddress = ({ address }) => dispatch => {
    return dispatch({
        type: SET_SHIPNODE_FORMDATA,
        payload: { address }
    })
}

/**
 * @description Set shipnode form Calendar
 * @param {Object} opCalendarList, cutOffTime
 * @returns {function(*, *=)}
 */
export const setShipnodeCalendar = ({ opCalendarList, cutOffTime, timeZone, capacity }) => dispatch => {
    const operatingCalendar = opCalendarList.map(obj => {
        return {
            ...obj,
            cutOffTime: cutOffTime?.value
        }
    })
    return dispatch({
        type: SET_SHIPNODE_FORMDATA,
        payload: { operatingCalendar, timeZone, capacity }
    })
}

/**
 * @description Set shipnode form Contact details
 * @param {Object} contactInfo
 * @returns {function(*, *=)}
 */
export const setShipnodeContactinfo = ({ contactInfo }) => dispatch => {
    return dispatch({
        type: SET_SHIPNODE_FORMDATA,
        payload: { contactInfo }
    })
}

/**
 * @description set a selected shipnode for Edit/deactivate
 * @param {Object} shipnode
 * @returns {function(...[*]=)}
 */
export const setSelectedShipnode = ({ shipnode }) => dispatch => {
    return dispatch({
        type: SET_SHIPNODE_FORMDATA,
        payload: shipnode
    })
}

/**
 * @description clear shipnode form
 * @returns {function(...[*]=)}
 */
export const clearShipnodeForm = () => dispatch => {
    return dispatch({
        type: SET_SHIPNODE_FORMDATA,
        payload: {
            shipNodeId: '',
            shipNodeName: '',
            supplierId: '',
            supplierName: '',
            leadTime: 2,
            capacity: {
                isEnabled: false,
                defaultValue: null
            },
            timeZone: '',
            address: {
                country: COUNTRY_LIST[0].name
            },
            contactInfo: {},
            operatingCalendar: OPERATING_CALENDAR
        }
    })
}

/**
 * @description Open shipnode form
 * @returns {function(*, *=)}
 */
export const setOpenDeactivateForm = isOpen => dispatch => {
    return dispatch({
        type: OPEN_DEACTIVATE_FORM,
        payload: { isOpen }
    })
}

/**
 * @description Open view changes pop up
 * @returns {function(*, *=)}
 */
export const setViewChangesPopup = (isOpen, details) => dispatch => {
    return dispatch({
        type: OPEN_VIEW_CHANGES_POPUP,
        payload: { isOpen, details }
    })
}

/**
 * @description set loading icon
 * @returns {function(*, *=)}
 */
export const setIsFetching = loading => dispatch => {
    return dispatch({
        type: SET_IS_FETCHING,
        payload: { loading }
    })
}

/**
 * @description set request description
 * @returns
 */
export const setDescription = selectedDescription => dispatch => {
    return dispatch({
        type: SET_REQUEST_DESCRIPTION,
        payload: { selectedDescription }
    })
}

/**
 * @description get shipnode's details
 * @returns  {*}
 */
export const getShipNodeDetails = (shipNodeId) => (dispatch, getState) => {
    const SUPPLIER_ID = getVendorIdFromState(getState)
    const { iamToken } = getState().UserInfo.userDetails
    const PATH_PARAM = shipNodeId ? `/${shipNodeId}` : ''
    if (isEmpty(SUPPLIER_ID)) {
        return Promise.resolve()
    }
    dispatch(setIsFetching(true))

    return dispatch({
        [CALL_API]: {
            types: [GET_SHIP_NODES_DETAILS_REQUEST, GET_SHIP_NODES_DETAILS_SUCCESS, GET_SHIP_NODES_DETAILS_FAILURE],
            endpoint: `${config.get('shipnodeDetails')}/${SUPPLIER_ID}/shipnodes${PATH_PARAM}`,
            method: 'GET',
            iamToken
        }
    }).then(({ type, response }) => {
        dispatch(setIsFetching(false))
        dispatch(aiShipnodeDetails({ vendorId: SUPPLIER_ID, shipnode: shipNodeId, response, type }))
    })
}

/**
 * @description create or update shipnode
 * @returns  {*}
 */
export const createOrUpdateShipnode = ({ shipnodeId }) => (dispatch, getState) => {
    const SUPPLIER_ID = getVendorIdFromState(getState)
    const { iamToken } = getState().UserInfo.userDetails
    const IS_VENDOR = getState().UserInfo.isVendor
    const { shipnodeForm, requestDescription, createMode } = getState().SupplierOnBoard
    const PATH_PARAMS = shipnodeId ? `/${shipnodeId}` : ''
    shipnodeForm['message'] = requestDescription
    shipnodeForm.address['state'] = shipnodeForm.address?.['state']?.value
    shipnodeForm.address['country'] = shipnodeForm.address?.['country']?.name
    shipnodeForm['timeZone'] = shipnodeForm['timeZone']?.value
    shipnodeForm.supplierId = shipnodeId ? shipnodeForm.supplierId : SUPPLIER_ID
    if (shipnodeForm['capacity'].isEnabled) {
        shipnodeForm['capacity'].defaultValue = parseInt(shipnodeForm['capacity']?.defaultValue)
    }
    dispatch(setIsFetching(true))

    return dispatch({
        [CALL_API]: {
            types: [CREATE_SHIPNODE_REQUEST, CREATE_SHIPNODE_SUCCESS, CREATE_SHIPNODE_FAILURE],
            endpoint: `${config.get('shipnodeDetails')}/${SUPPLIER_ID}/shipnodes${PATH_PARAMS}`,
            method: choseeMethodType({ shipnodeId }),
            payload: {
                pathName: getState().router.location.pathname
            },
            iamToken,
            data: shipnodeForm
        }
    }).then(({ type, response }) => {
        dispatch(setIsFetching(false))
        if (type === CREATE_SHIPNODE_SUCCESS && String(response?.status).toLowerCase() === 'ok') {
            dispatch(onShipNodeCreation({ shipnodeId, IS_VENDOR, shipnodeForm, createMode }))
            setTimeout(() => {
                dispatch(closeAlert())
                dispatch(setOpenShipnodeForm({ isOpen: false }))
                dispatch(setDescription(''))
            }, 3000)
        } else {
            if (response?.error?.errorCode === '400') {
                dispatch(showAlert(ERROR, response?.error?.errorMessage))
            } else {
                dispatch(showAlert(ERROR, ERROR_UPDATING_SHIPNODE))
            }
        }
        dispatch(aiCreateUpdateShipnode({ vendorId: SUPPLIER_ID, shipnode: shipnodeId, response, type }))
    })
}


/**
 * @description choose method type
 * @param {*} shipNodeId
 * @returns
 */
const choseeMethodType = ({ shipnodeId }) => {
    return shipnodeId ? 'PUT' : 'POST'
}

/**
 * @description on ship node created successfully or edited successfully
 * @param {*} {object}
 * @returns {dispacther}
 */
const onShipNodeCreation = ({ shipnodeId, IS_VENDOR, shipnodeForm, createMode }) => (dispatch) => {
    if (shipnodeId) {
        if (IS_VENDOR) {
            dispatch(showAlert(SUCCESS,
                `Request to ${createMode ? 'create' : 'update'} a shipnode ${shipnodeForm?.shipNodeName} (${shipnodeId}) is submitted successfully.`))
        } else {
            dispatch(showAlert(SUCCESS, `The shipnode ${shipnodeForm?.shipNodeName} (${shipnodeId}) is ${createMode ? 'created' : 'updated'} successfully.`))
        }
    } else {
        if (IS_VENDOR) {
            dispatch(showAlert(SUCCESS, `Request to create a shipnode is submitted successfully.`))
        } else {
            dispatch(showAlert(SUCCESS, `A new shipnode is created successfully.`))
        }
    }
}

/**
 * @description Deactivate shipnode
 * @returns  {*}
 */
export const deactivateShipnode = ({ activateFlag }) => (dispatch, getState) => {
    const SUPPLIER_ID = getVendorIdFromState(getState)
    const { iamToken } = getState().UserInfo.userDetails
    const { shipnodeForm, requestDescription } = getState().SupplierOnBoard
    const PATH_PARAMS = `${SUPPLIER_ID}/shipnodes/${shipnodeForm?.shipNodeId}`
    const reqObj = { activateFlag, message: requestDescription }
    const successMessage = activateFlag ? 'activate' : 'deactivate'
    dispatch(setIsFetching(true))

    return dispatch({
        [CALL_API]: {
            types: [DEACTIVE_SHIPNODE_REQUEST, DEACTIVE_SHIPNODE_SUCCESS, DEACTIVE_SHIPNODE_FAILURE],
            endpoint: `${config.get('shipnodeDetails')}/${PATH_PARAMS}`,
            method: 'PATCH',
            payload: {
                pathName: getState().router.location.pathname
            },
            iamToken,
            data: reqObj
        }
    }).then(({ type, response }) => {
        dispatch(setIsFetching(false))
        if (type === DEACTIVE_SHIPNODE_SUCCESS && response?.status === 'OK') {
            dispatch(setOpenDeactivateForm(false))
            dispatch(clearShipnodes())
            dispatch(setDescription(''))
            dispatch(getShipNodeDetails())
            dispatch(showAlert(SUCCESS, `Request to ${successMessage} a shipnode ${shipnodeForm?.shipNodeName} (${shipnodeForm?.shipNodeId}) is submitted successfully.`))
            setTimeout(() => {
                dispatch(closeAlert())
            }, 2000)
        }
        dispatch(aiDeactivateShipnode({ vendorId: SUPPLIER_ID, shipnode: shipnodeForm?.shipNodeId, response, type }))
    })
}

/**
 * @description clear shipnodes
 * @returns
 */
export const clearShipnodes = () => dispatch => {
    return dispatch({
        type: CLEAR_SHIPNODES_DETAILS
    })
}

/**
 * @description get shipnode requests
 * @returns
 */
export const getShipnodeHistory = () => (dispatch, getState) => {
    const VENDOR_ID = getVendorIdFromState(getState)
    const { iamToken } = getState().UserInfo?.userDetails
    const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
    const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
    const route = getState().router.location.pathname
    const { isCompletedView } = getState().Approvals
    const { shipnodeForm } = getState().SupplierOnBoard

    const PATH_PARAM = (route === ROUTE_MODULES.fulfilment?.supppliermanagement?.path) ? `/${VENDOR_ID}` : ''
    const APPROVALS_PARAM = (route === ROUTE_MODULES.toolsreporting?.approvals?.path) ? `pageNo=${PAGE_NO + 1}&pageSize=${PAGE_SIZE}&complete=${isCompletedView}` : ''
    const QUERY_PARAM2 = (route === ROUTE_MODULES.fulfilment?.supppliermanagement?.path) ? `shipNodeId=${shipnodeForm?.shipNodeId}` : ''

    return dispatch({
        [CALL_API]: {
            types: [
                FETCH_PAGINATED_TABLE_DATA_REQUEST,
                FETCH_PAGINATED_TABLE_DATA_SUCCESS,
                FETCH_PAGINATED_TABLE_DATA_FAILURE
            ],
            endpoint: `${config.get('shipnodeDetails')}${PATH_PARAM}/tasks?${APPROVALS_PARAM}${QUERY_PARAM2}`,
            method: 'GET',
            iamToken,
            payload: {
                pathName: route
            }
        }
    }).then(({ type, response }) => {
        dispatch(aiShipnodeHistory({ type, response, isCompletedView }))
        if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS) {
            return Promise.resolve(dispatch(setHistoryRowCount({ count: parseInt(response?.payload?.totalCount, 10) || 0 })))
        }
        return Promise.resolve()
    })
}

/**
 * @description Set the table's row count to the passed param value
 * @param {Number} count
 * @returns {function(...[*]=)}
 */
export const setHistoryRowCount = ({ count }) => dispatch => {
    return dispatch({
        type: SET_HISTORY_ROW_COUNT,
        payload: { count }
    })
}

/**
 * @description Approve/Reject shipnode requests
 * @returns
 */
export const approveRejectShipnode = ({ task, isApproved }) => (dispatch, getState) => {
    const { iamToken } = getState().UserInfo?.userDetails
    const { requestDescription } = getState().SupplierOnBoard

    const reqObj = {
        "approvalFlag": isApproved,
        "updateReason": requestDescription
    }

    return dispatch({
        [CALL_API]: {
            types: [APPROVE_REJECT_REQUEST, APPROVE_REJECT_SUCCESS, APPROVE_REJECT_FAILURE],
            endpoint: `${config.get('shipnodeDetails')}/tasks/${task?.taskId}`,
            method: 'PUT',
            iamToken,
            payload: {
                pathName: getState().router.location.pathname
            },
            data: reqObj
        }
    }).then(({ type, response }) => {
        if (type === APPROVE_REJECT_SUCCESS) {
            if (!isApproved) {
                dispatch(setDescription(''))
                dispatch(markTaskAsRejected({ updatedTask: task }))
            }
            dispatch(aiShipnodeApproveReject({ type, response }))
            return Promise.resolve()
        }
        const error =
            get(response, 'payload.error.errorMessage', null) || get(response, 'data.payload.error.errorMessage', null)
        throw new Error(error || 'Error occurred while trying to update shipnode request')
    })
}

/**
 * @description Set Supplier Roles
 * @returns
 */
export const selectSupplierRoles = (response) => (dispatch) => {
    return dispatch({
        type: SET_SUPPLIER_ROLES,
        payload: response
    })
}

/**
 * @description Set Supplier Roles
 * @returns
 */
export const setSupplierRoles = (response) => (dispatch) => {
    return dispatch({
        type: SET_SUPPLIER_ROLES_PAYLOAD,
        payload: response
    })
}

/**
 * @description fetch supplier roles
 * @returns {function(*, *=)}
 */
export const getSupplierFeatures = () => (dispatch, getState) => {
    const SUPPLIER_ID = getVendorIdFromState(getState)
    if (isEmpty(SUPPLIER_ID)) {
        return Promise.resolve()
    }
    dispatch(setIsFetching(true))

    return dispatch({
        [CALL_API]: {
            types: [SUPPLIER_FEATURES_REQUEST, SUPPLIER_FEATURES_SUCCESS, SUPPLIER_FEATURES_FAILURE],
            endpoint: `${config.get('getSupplierRoleFeatures')}?supplierId=${SUPPLIER_ID}`,
            method: 'GET',
        }
    }).then(({ type, response }) => {
        dispatch(aiSupplierFeatures({ VENDOR_ID: SUPPLIER_ID, featureList: response }))
        dispatch(setIsFetching(false))
        if (type === SUPPLIER_FEATURES_SUCCESS) {
            dispatch(selectSupplierRoles(response))
        }
    })
}

/**
 * @description update supplier roles
 * @returns {function(*, *=)}
 */
export const UpdateSupplierRoles = () => (dispatch, getState) => {
    const SUPPLIER_ID = getVendorIdFromState(getState)
    const { supplierRolesPayload } = getState().SupplierOnBoard
    if (isEmpty(SUPPLIER_ID)) {
        return Promise.resolve()
    }

    return dispatch({
        [CALL_API]: {
            types: [SUPPLIER_FEATURES_UPDATE_REQUEST, SUPPLIER_FEATURES_UPDATE_SUCCESS, SUPPLIER_FEATURES_UPDATE_FAILURE],
            endpoint: `${config.get('onBoard')}`,
            method: 'POST',
            data: {
                supplierId: SUPPLIER_ID,
                payload: dispatch(filterRolesPayload({ supplierRolesPayload, isUpdate: true }))
            }
        }
    }).then(({ type, response }) => {
        dispatch(aiSupplierFeatures({ VENDOR_ID: SUPPLIER_ID, featureList: supplierRolesPayload }))
        if (type === SUPPLIER_FEATURES_UPDATE_SUCCESS) {
            dispatch(showAlert(SUCCESS, `The roles for the supplier ${SUPPLIER_ID} are updated successfully.`))
            setTimeout(() => {
                dispatch(closeAlert())
                dispatch(getVendorRoles())
            }, 2000)
        } else {
            dispatch(showAlert(ERROR, ROLES_UPDATE_ERROR_MESSAGE))
        }
    })
}
