import { Grid } from '@mui/material'
import React from 'react'
import ErrorPage from '../ErrorPage'

class ErrorBoundary extends React.Component {
  state = { hasError: false }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props
    if (hasError) {
      return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>
            <ErrorPage />
          </Grid>
        </Grid>
      )
    }
    return children
  }
}

export default ErrorBoundary
