import { isEmpty } from 'lodash'
import config from '../../config'
import { CALL_API } from '../../reduxSetup/middleware/API'
import {
  getVendorIdFromState,
  getARMonthValues
} from '../../utils/common'
import { getARMonthwiseParam } from '../AccountReceivable/AccountReceivableActions'

import {
  FETCH_WEEKLY_PERFORMANCE_REQUEST,
  FETCH_WEEKLY_PERFORMANCE_SUCCESS,
  FETCH_WEEKLY_PERFORMANCE_FAILURE,
  FETCH_DAILY_PERFORMANCE_REQUEST,
  FETCH_DAILY_PERFORMANCE_SUCCESS,
  FETCH_DAILY_PERFORMANCE_FAILURE,
  FETCH_OOS_ITEMS_REQUEST,
  FETCH_OOS_ITEMS_SUCCESS,
  FETCH_OOS_ITEMS_FAILURE,
  FETCH_LT_COUNT_REQUEST,
  FETCH_LT_COUNT_SUCCESS,
  FETCH_LT_COUNT_FAILURE,
  CHECK_MTERICS_AVAILABLE,
  FETCH_PENALTY_COUNT_REQUEST,
  FETCH_PENALTY_COUNT_SUCCESS,
  FETCH_PENALTY_COUNT_FAILURE,
  FETCH_SHIPNODE_COUNT_REQUEST,
  FETCH_SHIPNODE_COUNT_SUCCESS,
  FETCH_SHIPNODE_COUNT_FAILURE,
  FETCH_AR_COUNT_REQUEST,
  FETCH_AR_COUNT_SUCCESS,
  FETCH_AR_COUNT_FAILURE
} from './Dashboard.types'

/**
 * @description Fetch all available dashboard records on page load
 * @return {function(*, *=): (Promise<void>)}
 */
export const fetchDashboard = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const IS_ADMIN = getState().UserInfo.isAdmin
  const CHECK_METRICS = getState().Dashboard.isMetrics

  if (!IS_ADMIN && isEmpty(VENDOR_ID)) {
    return Promise.resolve()
  }

  dispatch(fetchOOSItemsCount())//always call as it is live data
  dispatch(getDailyData())

  if (IS_ADMIN) {
    dispatch(fetchLTCount())
    dispatch(fetchPenaltyCount())
    dispatch(fetchShipnodeCount())
    dispatch(fetchARCount())
  }


  if (CHECK_METRICS) {
    return Promise.resolve()
  }

  dispatch(getWeeklyData())
  dispatch(isMetricsAvailable())
  return Promise.resolve()
}

/**
 * @description get weekly data from api
 * @returns Promise
 */
export const getWeeklyData = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const IS_ADMIN = getState().UserInfo.isAdmin

  const PATH_PARAM = !IS_ADMIN ? `/${VENDOR_ID}` : ''

  return dispatch({
    [CALL_API]: {
      types: [FETCH_WEEKLY_PERFORMANCE_REQUEST, FETCH_WEEKLY_PERFORMANCE_SUCCESS, FETCH_WEEKLY_PERFORMANCE_FAILURE],
      endpoint: `${String(process.env.REACT_APP_PERFORMANCE_METRICS) === 'v2' ? config.get('dashboard') : config.get('dashboard').replace('v2', 'v1')}/weekly/suppliers${PATH_PARAM}`,
      method: 'GET'
    }
  })
}

/**
 * @description get daily data from api
 * @returns Promise
 */
export const getDailyData = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const IS_ADMIN = getState().UserInfo.isAdmin

  const PATH_PARAM = !IS_ADMIN ? `/${VENDOR_ID}` : ''

  return dispatch({
    [CALL_API]: {
      types: [FETCH_DAILY_PERFORMANCE_REQUEST, FETCH_DAILY_PERFORMANCE_SUCCESS, FETCH_DAILY_PERFORMANCE_FAILURE],
      endpoint: `${String(process.env.REACT_APP_PERFORMANCE_METRICS) === 'v2' ? config.get('dashboard') : config.get('dashboard').replace('v2', 'v1')}/daily/suppliers${PATH_PARAM}`,
      method: 'GET'
    }
  })
}

/**
 * @description fetch OOS item count from api
 * @returns Promise
 */
export const fetchOOSItemsCount = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const IS_ADMIN = getState().UserInfo.isAdmin

  const filters = [{
    filterName: "category",
    filterValues: []
  },
  {
    filterName: "categoryId",
    filterValues: []
  },
  {
    filterName: "subcategory",
    filterValues: []
  },
  {
    filterName: "subcategoryNmbr",
    filterValues: []
  },
  {
    filterName: "supplierId",
    filterValues: IS_ADMIN ? [] : [`${VENDOR_ID}`]
  },
  {
    filterName: "alertType",
    filterValues: ["outOfStock"]
  }]

  return dispatch({
    [CALL_API]: {
      types: [FETCH_OOS_ITEMS_REQUEST, FETCH_OOS_ITEMS_SUCCESS, FETCH_OOS_ITEMS_FAILURE,],
      endpoint: `${String(process.env.REACT_APP_ALERTS_ITEMS) === 'v2' ? config.get('inventoryItems') : config.get('inventoryItems').replace('smartinventory', 'alerts')}`,
      method: 'POST',
      data: {
        filters,
        sorting: {
          sortBy: "status",
          sortType: 'asc'
        },
        pageNo: 1,
        pageSize: 1,
        vendorId: IS_ADMIN ? '' : `${VENDOR_ID}`
      }
    }
  })
}

/**
 * @description fetch lead time count
 * @returns Promise
 */
export const fetchLTCount = () => (dispatch) => {
  const leadTimeEndPoint = config.get('newLeadTime')
  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_LT_COUNT_REQUEST,
        FETCH_LT_COUNT_SUCCESS,
        FETCH_LT_COUNT_FAILURE
      ],
      endpoint: `${leadTimeEndPoint}/leadtime?pageNumber=1&pageSize=1`,
      method: 'GET'
    }
  })
}

/**
 * @description fetch lead time count
 * @returns Promise
 */
export const fetchPenaltyCount = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_PENALTY_COUNT_REQUEST,
        FETCH_PENALTY_COUNT_SUCCESS,
        FETCH_PENALTY_COUNT_FAILURE
      ],
      endpoint: `${config.get('penalty')}/exclude/suppliers?pageNo=1&pageSize=1`,
      method: 'GET'
    }
  })
}

/**
 * @description fetch shipnode count
 * @returns Promise
 */
export const fetchShipnodeCount = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_SHIPNODE_COUNT_REQUEST,
        FETCH_SHIPNODE_COUNT_SUCCESS,
        FETCH_SHIPNODE_COUNT_FAILURE
      ],
      endpoint: `${config.get('shipnodeDetails')}/tasks?pageNo=1&pageSize=1&complete=false`,
      method: 'GET'
    }
  })
}

/**
 * @description fetch Account Receivable count
 * @returns Promise
 */
export const fetchARCount = () => (dispatch, getState) => {
  const selectedRange = getARMonthValues()
  const { iamToken } = getState().UserInfo.userDetails
  const { fromDate, toDate } = getARMonthwiseParam({ selectedARMonth: selectedRange[2] })
  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_AR_COUNT_REQUEST,
        FETCH_AR_COUNT_SUCCESS,
        FETCH_AR_COUNT_FAILURE
      ],
      endpoint: `${config.get('accountReceivable')}/tasks?fromDate=${fromDate}&toDate=${toDate}&isExecuted=false&pageNo=1&pageSize=1`,
      method: 'POST',
      iamToken,
      data: {
        filters: [
          {
            filterName: "supplierId",
            filterValues: []
          },
          {
            filterName: "status",
            filterValues: []
          }
        ],
        orderBy: 'asc',
        sortBy: 'executedPenalty'
      }
    }
  })
}

/**
 * @description make single call for api
 * @returns Promise
 */
export const isMetricsAvailable = () => (dispatch) => {
  return Promise.resolve(
    dispatch({
      type: CHECK_MTERICS_AVAILABLE
    })
  )
}
