
export const UPDATE_FCAPACITY_REQUEST = 'UPDATE_FCAPACITY_REQUEST'
export const UPDATE_FCAPACITY_SUCCESS = 'UPDATE_FCAPACITY_SUCCESS'
export const UPDATE_FCAPACITY_FAILURE = 'UPDATE_FCAPACITY_FAILURE'
export const SET_DATE_RANGE = 'SET_DATE_RANGE'
export const RESET_DATE_RANGE = 'RESET_DATE_RANGE'
export const TOGGLE_EDIT_CAPACITY = 'TOGGLE_EDIT_CAPACITY'
export const SET_EDITED_CAPACITY = 'SET_EDITED_CAPACITY'
export const SET_FAILED_CAPACITY = 'SET_FAILED_CAPACITY'
export const RESET_EDITED_CAPACITY = 'RESET_EDITED_CAPACITY'
export const SET_DOWNLOAD_ALL = 'SET_DOWNLOAD_ALL'
export const STORE_CAPACITY_NODES = 'STORE_CAPACITY_NODES'
export const SET_SHIPNODE_SELECTED = 'SET_SHIPNODE_SELECTED'
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'
export const SET_CHANGELOG_COUNT = 'SET_CHANGELOG_COUNT'
