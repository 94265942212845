export const FETCH_WEEKLY_PERFORMANCE_REQUEST = 'FETCH_WEEKLY_PERFORMANCE_REQUEST'
export const FETCH_WEEKLY_PERFORMANCE_SUCCESS = 'FETCH_WEEKLY_PERFORMANCE_SUCCESS'
export const FETCH_WEEKLY_PERFORMANCE_FAILURE = 'FETCH_WEEKLY_PERFORMANCE_FAILURE'

export const FETCH_DAILY_PERFORMANCE_REQUEST = 'FETCH_DAILY_PERFORMANCE_REQUEST'
export const FETCH_DAILY_PERFORMANCE_SUCCESS = 'FETCH_DAILY_PERFORMANCE_SUCCESS'
export const FETCH_DAILY_PERFORMANCE_FAILURE = 'FETCH_DAILY_PERFORMANCE_FAILURE'

export const FETCH_OOS_ITEMS_REQUEST = 'FETCH_OOS_ITEMS_REQUEST'
export const FETCH_OOS_ITEMS_SUCCESS = 'FETCH_OOS_ITEMS_SUCCESS'
export const FETCH_OOS_ITEMS_FAILURE = 'FETCH_OOS_ITEMS_FAILURE'

export const FETCH_LT_COUNT_REQUEST = 'FETCH_LT_COUNT_REQUEST'
export const FETCH_LT_COUNT_SUCCESS = 'FETCH_LT_COUNT_SUCCESS'
export const FETCH_LT_COUNT_FAILURE = 'FETCH_LT_COUNT_FAILURE'

export const FETCH_PENALTY_COUNT_REQUEST = 'FETCH_PENALTY_COUNT_REQUEST'
export const FETCH_PENALTY_COUNT_SUCCESS = 'FETCH_PENALTY_COUNT_SUCCESS'
export const FETCH_PENALTY_COUNT_FAILURE = 'FETCH_PENALTY_COUNT_FAILURE'

export const FETCH_SHIPNODE_COUNT_REQUEST = 'FETCH_SHIPNODE_COUNT_REQUEST'
export const FETCH_SHIPNODE_COUNT_SUCCESS = 'FETCH_SHIPNODE_COUNT_SUCCESS'
export const FETCH_SHIPNODE_COUNT_FAILURE = 'FETCH_SHIPNODE_COUNT_FAILURE'

export const FETCH_AR_COUNT_REQUEST = 'FETCH_AR_COUNT_REQUEST'
export const FETCH_AR_COUNT_SUCCESS = 'FETCH_AR_COUNT_SUCCESS'
export const FETCH_AR_COUNT_FAILURE = 'FETCH_AR_COUNT_FAILURE'

export const CHECK_MTERICS_AVAILABLE = 'CHECK_MTERICS_AVAILABLE'
