import React from 'react'
import {
  Business,
  BusinessSelected,
  LocalShipping,
  LocalShippingSelected,
  Search,
  SearchSelected,
  ReturnIcon,
  ReturnSelected,
  Performance,
  PerformanceSelected,
  ExpandMoreSelected,
  ExpandLessSelected,
  Setting,
  SettingSelected,
  Home,
  HomeSelected,
  ReportIcon,
  ReportSelected
} from './ColoredSvg'
import { ROUTE_MODULES } from '../../config/genericConstants'

export const MENU_ROUTES = [
  {
    name: ROUTE_MODULES.search.name,
    url: ROUTE_MODULES.search.path,
    enableMastHead: false,
    icon: Search,
    selectedIcon: SearchSelected,
    nav: [],
    searchEnabled: false,
    sourceType: 'both',
    access: 'all',
    onlyAdmin: false,
    component: React.lazy(() => import('../../pages/SupplierSelection'))
  },
  {
    name: ROUTE_MODULES.performance?.home?.name,
    url: ROUTE_MODULES.performance?.home?.path,
    enableMastHead: false,
    icon: Home,
    selectedIcon: HomeSelected,
    nav: [],
    searchEnabled: true,
    sourceType: 'dsv',
    access: 'all',
    onlyAdmin: false,
    component: React.lazy(() => import('../../pages/Dashboard'))//Dashboard page for
  },
  {
    name: ROUTE_MODULES.performance.key,
    icon: Performance,
    selectedIcon: PerformanceSelected,
    moreIcon: ExpandMoreSelected,
    lessIcon: ExpandLessSelected,
    sourceType: 'dsv',
    nav: [
      {
        key: ROUTE_MODULES.performance.key,
        name: ROUTE_MODULES.performance?.weekmetrics?.name,
        url: ROUTE_MODULES.performance?.weekmetrics?.path,
        enableMastHead: false,
        searchEnabled: true,
        sourceType: 'dsv',
        access: 'role',
        onlyAdmin: false,
        component: React.lazy(() => import('../../pages/Performance'))//weekly metrics page
      },
      {
        key: ROUTE_MODULES.performance.key,
        name: ROUTE_MODULES.performance?.monthpenalty?.name,
        url: ROUTE_MODULES.performance?.monthpenalty?.path,
        enableMastHead: false,
        searchEnabled: true,
        sourceType: 'dsv',
        access: 'all',
        onlyAdmin: false,
        component: React.lazy(() => import('../../pages/PenaltyProgram')),//monthly penalties page
      }
    ]
  },
  {
    name: ROUTE_MODULES.prepurchase?.key,
    enableMastHead: false,
    icon: Business,
    selectedIcon: BusinessSelected,
    moreIcon: ExpandMoreSelected,
    lessIcon: ExpandLessSelected,
    sourceType: 'dsv',
    nav: [
      {
        key: ROUTE_MODULES.prepurchase?.key,
        name: ROUTE_MODULES.prepurchase?.inventory?.name,
        url: ROUTE_MODULES.prepurchase?.inventory?.path,
        enableMastHead: false,
        searchEnabled: true,
        sourceType: 'dsv',
        access: 'role',
        onlyAdmin: false,
        component: React.lazy(() => import('../../pages/Inventory')) // invenotry page
      },
      {
        key: ROUTE_MODULES.prepurchase?.key,
        name: ROUTE_MODULES.prepurchase?.smartinventory?.name,
        url: ROUTE_MODULES.prepurchase?.smartinventory?.path,
        enableMastHead: false,
        searchEnabled: false,
        sourceType: 'dsv',
        access: 'admin',
        onlyAdmin: false,
        component: React.lazy(() => import('../../pages/SmartInventory')),
      },
      {

        name: ROUTE_MODULES.prepurchase?.intransitinventory?.name,
        url: ROUTE_MODULES.prepurchase?.intransitinventory?.path,
        enableMastHead: false,
        searchEnabled: true,
        sourceType: 'dsv',
        access: 'role',
        onlyAdmin: false,
        isPrimary: true,
        component: React.lazy(() => import('../../pages/InTransitInventory')),
      }
    ]
  }, {
    name: ROUTE_MODULES.inventorypricing?.key,
    enableMastHead: false,
    icon: Business,
    selectedIcon: BusinessSelected,
    moreIcon: ExpandMoreSelected,
    lessIcon: ExpandLessSelected,
    sourceType: 'club',
    nav: [
      {
        key: ROUTE_MODULES.inventorypricing?.key,
        name: ROUTE_MODULES.inventorypricing?.inventory?.name,
        url: ROUTE_MODULES.inventorypricing?.inventory?.path,
        enableMastHead: false,
        searchEnabled: true,
        sourceType: 'club',
        access: 'role',
        onlyAdmin: false,
        component: React.lazy(() => import('../../pages/Inventory')) // invenotry page
      },
      {
        key: ROUTE_MODULES.inventorypricing?.key,
        name: ROUTE_MODULES.inventorypricing?.pricingservice?.name,
        url: ROUTE_MODULES.inventorypricing?.pricingservice?.path,
        enableMastHead: false,
        searchEnabled: false,
        sourceType: 'club',
        access: 'admin',
        onlyAdmin: true,
        weekdaysEnabled: true,
        component: React.lazy(() => import('../../pages/Club/PricingService'))
      },
      {
        key: ROUTE_MODULES.inventorypricing?.key,
        name: ROUTE_MODULES.inventorypricing?.outofstock?.name,
        url: ROUTE_MODULES.inventorypricing?.outofstock?.path,
        enableMastHead: false,
        searchEnabled: false,
        sourceType: 'club',
        access: 'admin',
        onlyAdmin: false,
        component: React.lazy(() => import('../../pages/Club/OutofStock'))
      }
    ]
  },
  {
    name: ROUTE_MODULES.forecasting?.key,
    enableMastHead: false,
    icon: ReportIcon,
    selectedIcon: ReportSelected,
    moreIcon: ExpandMoreSelected,
    lessIcon: ExpandLessSelected,
    sourceType: 'club',
    nav: [
      {
        key: ROUTE_MODULES.forecasting?.key,
        name: ROUTE_MODULES.forecasting?.clubsaleforecast?.name,
        url: ROUTE_MODULES.forecasting?.clubsaleforecast?.path,
        enableMastHead: false,
        searchEnabled: false,
        sourceType: 'club',
        access: 'admin',
        onlyAdmin: false,
        component: React.lazy(() => import('../../pages/Club/SaleForecast'))
      },
      {
        key: ROUTE_MODULES.forecasting?.key,
        name: ROUTE_MODULES.forecasting?.cluborderdemand?.name,
        url: ROUTE_MODULES.forecasting?.cluborderdemand?.path,
        enableMastHead: false,
        searchEnabled: false,
        sourceType: 'club',
        access: 'admin',
        onlyAdmin: false,
        component: React.lazy(() => import('../../pages/Club/OrderDemand'))
      }
    ]
  },
  {
    name: ROUTE_MODULES.fulfilment?.key,
    enableMastHead: false,
    icon: LocalShipping,
    selectedIcon: LocalShippingSelected,
    moreIcon: ExpandMoreSelected,
    lessIcon: ExpandLessSelected,
    sourceType: 'both',
    nav: [
      {
        key: ROUTE_MODULES.fulfilment?.key,
        name: ROUTE_MODULES.fulfilment?.orderfulfilment?.name,
        url: ROUTE_MODULES.fulfilment?.orderfulfilment?.path,
        enableMastHead: false,
        searchEnabled: true,
        sourceType: 'dsv',
        access: 'role',
        onlyAdmin: false,
        component: React.lazy(() => import('../../pages/FulfillmentNew')) // fulfilment page
      },
      {
        key: ROUTE_MODULES.fulfilment?.key,
        name: ROUTE_MODULES.fulfilment?.leadtime?.name,
        url: ROUTE_MODULES.fulfilment?.leadtime?.path,
        enableMastHead: false,
        searchEnabled: true,
        sourceType: 'dsv',
        access: 'role',
        onlyAdmin: false,
        component: React.lazy(() => import('../../templates/LeadTimeCapacity'))//lead time page
      },
      {
        key: ROUTE_MODULES.fulfilment?.key,
        name: ROUTE_MODULES.fulfilment?.supppliermanagement?.name,
        url: ROUTE_MODULES.fulfilment?.supppliermanagement?.path,
        enableMastHead: false,
        searchEnabled: true,
        sourceType: 'both',
        access: 'all',
        onlyAdmin: false,
        component: React.lazy(() => import('../../pages/SupplierManagement'))
      }
    ]
  },
  {
    name: ROUTE_MODULES.postpurchase?.key,
    enableMastHead: false,
    icon: ReturnIcon,
    selectedIcon: ReturnSelected,
    moreIcon: ExpandMoreSelected,
    lessIcon: ExpandLessSelected,
    sourceType: 'dsv',
    nav: [
      {
        key: ROUTE_MODULES.postpurchase?.key,
        name: ROUTE_MODULES.postpurchase?.returnprocessing.name,
        url: ROUTE_MODULES.postpurchase?.returnprocessing.path,
        enableMastHead: false,
        searchEnabled: true,
        sourceType: 'dsv',
        access: 'role',
        onlyAdmin: false,
        component: React.lazy(() => import('../../pages/ReturnOrder'))//returns page
      }
    ]
  },
  {
    name: ROUTE_MODULES.toolsreporting?.key,
    enableMastHead: false,
    icon: Setting,
    selectedIcon: SettingSelected,
    moreIcon: ExpandMoreSelected,
    lessIcon: ExpandLessSelected,
    sourceType: 'both',
    nav: [

      {
        key: ROUTE_MODULES.toolsreporting?.key,
        name: ROUTE_MODULES.toolsreporting?.reports?.name,
        url: ROUTE_MODULES.toolsreporting?.reports?.path,
        enableMastHead: false,
        searchEnabled: true,
        sourceType: 'dsv',
        access: 'admin',
        onlyAdmin: false,
        component: React.lazy(() => import('../../pages/PredefinedReports')),
      }, {
        key: ROUTE_MODULES.toolsreporting?.key,
        name: ROUTE_MODULES.toolsreporting?.uploadrequest?.name,
        url: ROUTE_MODULES.toolsreporting?.uploadrequest?.path,
        enableMastHead: false,
        searchEnabled: true,
        sourceType: 'both',
        access: 'role',
        onlyAdmin: false,
        component: React.lazy(() => import('../../pages/Feed'))
      }, {
        key: ROUTE_MODULES.toolsreporting?.key,
        name: ROUTE_MODULES.toolsreporting?.adminuploadrequest?.name,
        url: ROUTE_MODULES.toolsreporting?.adminuploadrequest?.path,
        enableMastHead: false,
        searchEnabled: false,
        sourceType: 'both',
        access: 'all',
        onlyAdmin: true,
        component: React.lazy(() => import('../../pages/AdminFeed'))
      }, {
        key: ROUTE_MODULES.toolsreporting?.key,
        name: ROUTE_MODULES.toolsreporting?.downloads?.name,
        url: ROUTE_MODULES.toolsreporting?.downloads?.path,
        enableMastHead: false,
        searchEnabled: false,
        sourceType: 'both',
        access: 'all',
        onlyAdmin: false,
        component: React.lazy(() => import('../../pages/DownloadHistory'))
      }, {
        key: ROUTE_MODULES.toolsreporting?.key,
        name: ROUTE_MODULES.toolsreporting?.approvals?.name,
        url: ROUTE_MODULES.toolsreporting?.approvals?.path,
        enableMastHead: false,
        searchEnabled: false,
        sourceType: 'dsv',
        access: 'all',
        onlyAdmin: true,
        component: React.lazy(() => import('../../pages/Approvals'))
      }, {
        key: ROUTE_MODULES.toolsreporting?.key,
        name: ROUTE_MODULES.toolsreporting?.leadtimemanagement?.name,
        url: ROUTE_MODULES.toolsreporting?.leadtimemanagement?.path,
        enableMastHead: false,
        searchEnabled: false,
        sourceType: 'dsv',
        access: 'all',
        onlyAdmin: true,
        component: React.lazy(() => import('../../pages/MantisLeadTime'))
      }
    ]
  }
]
