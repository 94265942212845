import { cloneDeep, find } from 'lodash'
import moment from 'moment'
import { PERFORMANCE_STATUS_CODES } from '../../config/genericConstants'
import { convertStatusCodeObjectToArray, resetValueInPerformanceData } from '../../utils/common'
import {
  CHANGE_DATE_DETAILED_VIEW,
  CHANGE_PERFORMANCE_COMPARISON_TYPES,
  CHANGE_PERFORMANCE_TYPE_DISPLAY,
  CHANGE_SELECTED_WEEK_SUMMARY,
  FETCH_PERFORMANCE_DETAILS_FAILURE,
  FETCH_PERFORMANCE_DETAILS_REQUEST,
  FETCH_PERFORMANCE_DETAILS_SUCCESS,
  FETCH_PERFORMANCE_SUMMARY_FAILURE,
  FETCH_PERFORMANCE_SUMMARY_REQUEST,
  FETCH_PERFORMANCE_SUMMARY_SUCCESS,
  RESET_PERFORMANCE_DATA,
  TOGGLE_DETAILED_VIEW
} from './Performance.actionTypes'

export const DEFAULT_SELECTION = { title: 'All', value: 'All' }
export const YEAR_OBJECT = { title: moment().format('YYYY'), value: moment().format('YYYY') }

export const INITIAL_STATE = {
  yearList: [{ ...YEAR_OBJECT }],
  monthList: [{ ...DEFAULT_SELECTION }],
  weekList: [],
  weekListDetailedView: [{ ...DEFAULT_SELECTION }],
  statusCodeList: [...PERFORMANCE_STATUS_CODES],
  selected: {
    year: { ...YEAR_OBJECT },
    month: { ...DEFAULT_SELECTION }, // January / February
    week: {}, // {title: 'Mar 1 to Mar 7' value: '2020-03-01'}
    weekDetailedView: { ...DEFAULT_SELECTION } // {name: 'Mar 1 to Mar 7' value: '2020-03-01'}
  },
  summary: {
    isFetching: false,
    data: [],
    selectedWeekSummary: {
      statusCode: {
        RR: { title: 'Return Rate' },
        NPS: { title: 'Net Promoter Score' },
        FR: { title: 'Fill Rate' },
        OTD: { title: 'On-time Delivery' },
        OTS: { title: 'On-time Shipment' },
        CR: { title: 'Cancellation Rate' }
      }
    }
  },
  detailedView: {
    enabled: false,
    data: [],
    viewBy: null,
    enabledColumns: [...PERFORMANCE_STATUS_CODES.map(statusCode => statusCode.code)],
    enabledRowTypes: []
  }
}

export default (state = cloneDeep(INITIAL_STATE), action) => {
  switch (action.type) {
    case FETCH_PERFORMANCE_SUMMARY_REQUEST:
      return {
        ...state,
        summary: {
          ...INITIAL_STATE.summary,
          isFetching: true
        }
      }

    case FETCH_PERFORMANCE_SUMMARY_SUCCESS:
      return {
        ...state,
        weekList: action.response.payload.listOfWeek,
        selected: { ...state.selected, week: action.response.payload.currentWeek },
        statusCodeList: convertStatusCodeObjectToArray(action.response.payload.statusCode),
        summary: {
          ...state.summary,
          isFetching: false,
          data: action.response.payload.data
        },
        detailedView: {
          ...state.detailedView,
          enabledColumns: convertStatusCodeObjectToArray(action.response.payload.statusCode)
        }
      }

    case FETCH_PERFORMANCE_SUMMARY_FAILURE:
      return {
        ...state,
        summary: {
          ...state.summary,
          isFetching: false,
          data: []
        }
      }

    case CHANGE_SELECTED_WEEK_SUMMARY:
      // eslint-disable-next-line no-case-declarations
      const selectedWeekSummary = find(state.summary.data, { date: action.payload.week.value })
      if (selectedWeekSummary.status !== 'AVAILABLE') {
        selectedWeekSummary.statusCode = { ...cloneDeep(state.summary.selectedWeekSummary.statusCode) }
        resetValueInPerformanceData(selectedWeekSummary.statusCode)
      } else {
        state.statusCodeList.forEach(statusCode => {
          if (!selectedWeekSummary.statusCode[statusCode.code]) {
            selectedWeekSummary.statusCode = {
              ...selectedWeekSummary.statusCode,
              ...resetValueInPerformanceData({ [statusCode.code]: { title: statusCode.title } })
            }
          }
          return selectedWeekSummary
        })
      }

      return {
        ...state,
        selected: { ...state.selected, week: action.payload.week },
        summary: {
          ...state.summary,
          selectedWeekSummary
        }
      }

    case TOGGLE_DETAILED_VIEW: {
      const resetDetailedView =
        !state.detailedView.enabled === false ? { ...INITIAL_STATE.detailedView } : { enabled: true }
      return {
        ...state,
        detailedView: {
          ...state.detailedView,
          ...resetDetailedView,
          enabledColumns: state.detailedView.enabledColumns
        }
      }
    }

    case FETCH_PERFORMANCE_DETAILS_REQUEST:
      return {
        ...state,
        detailedView: {
          ...state.detailedView,
          enabled: true,
          isFetching: true
        }
      }

    case FETCH_PERFORMANCE_DETAILS_SUCCESS: {
      const { viewBy, nextFilter, years } = action.response.payload
      const conditionalObject = {}
      if (viewBy === 'Year') {
        conditionalObject.yearList = years.map(year => ({ title: String(year), value: String(year) }))
        conditionalObject.monthList = [{ ...DEFAULT_SELECTION }, ...nextFilter]
        conditionalObject.weekListDetailedView = [{ ...DEFAULT_SELECTION }]
      } else if (viewBy === 'Month') {
        conditionalObject.weekListDetailedView = [{ ...DEFAULT_SELECTION }, ...nextFilter]
      }

      return {
        ...state,
        detailedView: {
          ...state.detailedView,
          viewBy,
          data: action.response.payload.vendorPerformanceDetailsData,
          enabledRowTypes: [`CURRENT_${viewBy.toUpperCase()}`],
          isFetching: false
        },
        ...conditionalObject
      }
    }

    case FETCH_PERFORMANCE_DETAILS_FAILURE:
      return {
        ...state,
        detailedView: {
          ...state.detailedView,
          isFetching: false
        }
      }

    case CHANGE_DATE_DETAILED_VIEW:
      return {
        ...state,
        selected: {
          ...state.selected,
          ...action.payload.selectedValues
        }
      }

    case CHANGE_PERFORMANCE_TYPE_DISPLAY:
      return {
        ...state,
        detailedView: {
          ...state.detailedView,
          enabledColumns: action.payload.enabledColumns
        }
      }

    case CHANGE_PERFORMANCE_COMPARISON_TYPES:
      return {
        ...state,
        detailedView: {
          ...state.detailedView,
          enabledRowTypes: action.payload.enabledRowTypes
        }
      }

    case RESET_PERFORMANCE_DATA:
      return {
        ...INITIAL_STATE
      }

    default:
      return { ...state }
  }
}
