import { Grid, Slide } from '@mui/material'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import EditHeader from './EditHeader'
import Unauthorized from '../../Unauthorized'
import SchedulerInventory from '../../../templates/FutureInventoryScheduler'
import { resetItemDetails } from '../../InTransitInventory/InTransitInventoryAction'

class InTransitInventoryEdit extends PureComponent {

    componentWillUnmount = () => {
        const { resetItemDetails } = this.props
        return resetItemDetails()
    }

    render = () => {
        const { selectedItem, selectedVendorId, fetchingRoles, hasAccess } = this.props

        if (selectedVendorId && !fetchingRoles && !hasAccess) {
            return <Unauthorized />
        }

        return (
            <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                <Grid container>
                    <EditHeader />
                    <SchedulerInventory data={selectedItem} />
                </Grid>
            </Slide>
        )
    }
}

export const InTransitInventoryEditNaked = InTransitInventoryEdit

InTransitInventoryEdit.propTypes = {
    selectedItem: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    selectedItem: state.InTransitInventory.selectedItem,
    hasAccess: !!state.UserInfo.access['in-transit-inventory'],
    selectedVendorId: state.AppConfig.vendor.selectedVendor.vendorId,
    fetchingRoles: state.UserInfo.fetchingRoles
})

const mapDispatchToProps = dispatch => bindActionCreators({ resetItemDetails }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(InTransitInventoryEdit)
