export const PENALTY_SEARCH_TYPES = [
  { value: 'itemNumber', name: 'Item id', placeHolder: 'Enter an item id', regEx: /[^\w\d-_]+/g },
  { value: 'legacyPoNumber', name: 'PO number', placeHolder: 'Enter PO number', regEx: /[^\d-]+/g },
  { value: 'orderNumber', name: 'SO number', placeHolder: 'Enter SO number', regEx: /[^\d-]+/g }
]

export const EXCLUDE = 'Exclude items from penalty'
export const RESET_SEARCH = 'Reset search'

export const PENALTY_EXCLUSION_REASON_TYPES = [
  { value: '1', name: 'Member Request' },
  { value: '2', name: 'System Error' },
  { value: '3', name: 'Carrier Delay' },
  { value: '4', name: 'Invalid Address' },
  { value: '5', name: 'Other' }]

export const PENALTY_EXCLUSION_CONTENT = `This will exclude all selected items from 
penalty calculation with common (below) reason.
Please make sure it's correct and same for all items.`

export const OTHER = `Other`

export const PENALTY = 'Penalty'

export const PENALTY_EXCLUDE_REASON = `Reason for exclusion`

export const PENALTY_REJECT_REASON = `Reason for rejection`

export const PENALTY_REJECTION_REASON_TYPES = [
  { value: '1', name: 'Tracking information was not received by MSBD' },
  { value: '2', name: 'Member cancelled after MSBD' },
  { value: '3', name: 'We do not waive for inventory out of stock' },
  { value: '4', name: 'Other' }]

export const PENALTY_REJECTION_CONTENT = `This will exclude an item in request from penalty calculation. Please make sure it's correct.`

export const PENALTY_STATUS_PENDING = `Item status is pending for penalty exclusion`

export const HISTORY_TAB_TITLE = 'Requests history'

export const LEAD_TIME_INFO = `You may request a change to the lead time from 
the Lead Time Management page, found in the sidebar.`

export const FCAP_INFO = `Daily unit processing capacity indicates the number of units that can be processed in a day by this node.
If day wise capacity is unavailable, daily unit processing capacity will come into play.`

export const LOCAL_CONTACT_CONTENT = `This contact is someone who works at this ship node location 
and can address issues at this location. Only SamsClub.com associates will contact this individual.`

export const CONFIRM_CREATE_SHIPNODE_CONTENT = `This will raise the request for admin to create shipnode.
Please make sure description is correct.`

export const CONFIRM_EDIT_SHIPNODE_CONTENT = `This will raise the request for admin to edit shipnode.
Please make sure description is correct.`

export const DEACTIVATE_SHIPNODE_CONTENT = `This will raise the request for admin to deactivate shipnode.
Please make sure description is correct.`

export const ACTIVATE_SHIPNODE_CONTENT = `This will raise the request for admin to activate shipnode.
Please make sure description is correct.`

export const CUT_OFF_TIME = [
  { name: '12:00 AM', value: '00:00' },
  { name: '1:00 AM', value: '01:00' },
  { name: '2:00 AM', value: '02:00' },
  { name: '3:00 AM', value: '03:00' },
  { name: '4:00 AM', value: '04:00' },
  { name: '5:00 AM', value: '05:00' },
  { name: '6:00 AM', value: '06:00' },
  { name: '7:00 AM', value: '07:00' },
  { name: '8:00 AM', value: '08:00' },
  { name: '9:00 AM', value: '09:00' },
  { name: '10:00 AM', value: '10:00' },
  { name: '11:00 AM', value: '11:00' },
  { name: '12:00 PM', value: '12:00' },
  { name: '1:00 PM', value: '13:00' },
  { name: '2:00 PM', value: '14:00' },
  { name: '3:00 PM', value: '15:00' },
  { name: '4:00 PM', value: '16:00' },
  { name: '5:00 PM', value: '17:00' },
  { name: '6:00 PM', value: '18:00' },
  { name: '7:00 PM', value: '19:00' },
  { name: '8:00 PM', value: '20:00' },
  { name: '9:00 PM', value: '21:00' },
  { name: '10:00 PM', value: '22:00' },
  { name: '11:00 PM', value: '23:00' }
]

export const ADDRESS_TYPE_OPTIONS = [
  {
    value: 1,
    name: 'US Residential Street'
  },
  {
    value: 2,
    name: 'US Business Street'
  },
  {
    value: 3,
    name: 'US Post Office Box'
  },
  {
    value: 4,
    name: 'US APO - FPO'
  },
  {
    value: 101,
    name: 'INTL Carribean Address'
  }
]

export const COUNTRY_LIST = [{
  value: 1,
  name: 'USA'
}]

export const WEEKDAYS = [
  {
    value: 'sunday',
    name: 'Sunday'
  },
  {
    value: 'monday',
    name: 'Monday'
  },
  {
    value: 'tuesday',
    name: 'Tuesday'
  },
  {
    value: 'wednesday',
    name: 'Wednesday'
  },
  {
    value: 'thursday',
    name: 'Thursday'
  },
  {
    value: 'friday',
    name: 'Friday'
  },
  {
    value: 'saturday',
    name: 'Saturday'
  }
]

export const US_TIME_ZONES = [
  {
    name: 'Central Standard Time (CST)',
    value: 'CST'
  },
  {
    name: 'Pacific Standard Time (PST)',
    value: 'PST'
  },
  {
    name: 'Atlantic Standard Time (AST)',
    value: 'AST'
  },
  {
    name: 'Mountain Standard Time (MST)',
    value: 'MST'
  },
  {
    name: 'Eastern Standard Time (EST)',
    value: 'EST'
  },
  {
    name: 'Alaska Standard Time (AKST)',
    value: 'AKST'
  },
  {
    name: 'Hawaii-Aleutian Standard Time (HST)',
    value: 'HST'
  },
  {
    name: 'Samoa Standard Time (SST)',
    value: 'SST'
  }
]

export const ADDRESS = 'Address'
export const OPERATIONAL_INFO = 'Operational Information'
export const LOCAL_SUPPORT_CONTACT = 'Local Support Contact'

export const SUPPLIER_MNGT_EDIT = 'Edit'
export const SUPPLIER_MNGT_ACTIVATE = 'Activate'
export const SUPPLIER_MNGT_DEACTIVATE = 'Deactivate'

export const MONTHS = [{
  value: '0',
  name: 'Select month'
}, {
  value: '1',
  name: 'January'
}, {
  value: '2',
  name: 'February'
}, {
  value: '3',
  name: 'March'
}, {
  value: '4',
  name: 'April'
}, {
  value: '5',
  name: 'May'
}, {
  value: '6',
  name: 'June'
}, {
  value: '7',
  name: 'July'
}, {
  value: '8',
  name: 'August'
}, {
  value: '9',
  name: 'September'
}, {
  value: '10',
  name: 'October'
}, {
  value: '11',
  name: 'November'
}, {
  value: '12',
  name: 'December'
}]

export const SUPPORTED_SOURCE = ['DSV', 'CLUB']

export const CLEAR_EDIT = 'Clear edit?'
export const CLEAR_EDIT_MSG = `This will discard any changes made. Do you want to continue?`

export const SAVE_BEFORE_EDIT = 'Confirm action'
export const SAVE_BEFORE_EDIT_MSG = `This will discard the previous changes made. Please save or reset the existing inbound week changes before proceeding to update another row.
Do you want to continue?`

export const FAILED_UPLOAD_TIP = `Upload failed. Please retry uploading the file from the respective module.`
export const NO_ERRORS_FEED_MSG = `No errors in this feed item`
export const NETWORK_ERROR_MESSAGE = `We are facing an error while retrieving data, If problem persists please contact  "${process.env.REACT_APP_SUPPORT_EMAIL}" for support.`


export const PENALTY_STATUS_TYPE = [{ label: 'Late', name: 'Late', checked: false },
{ label: 'Cancelled', name: 'Cancelled', checked: false }]

export const PO_WAITING_MESSAGE = 'Action to be enabled after PO is generated'

export const PERFORMANCE_DOWNLOAD_LIMIT = `Only reports containing upto 5000 records can be downloaded here.
Please contact "${process.env.REACT_APP_SUPPORT_EMAIL}" to get everything over email.`
