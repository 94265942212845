export const FETCH_PERFORMANCE_SUMMARY_REQUEST = 'FETCH_PERFORMANCE_SUMMARY_REQUEST'
export const FETCH_PERFORMANCE_SUMMARY_SUCCESS = 'FETCH_PERFORMANCE_SUMMARY_SUCCESS'
export const FETCH_PERFORMANCE_SUMMARY_FAILURE = 'FETCH_PERFORMANCE_SUMMARY_FAILURE'

export const FETCH_PERFORMANCE_DETAILS_REQUEST = 'FETCH_PERFORMANCE_DETAILS_REQUEST'
export const FETCH_PERFORMANCE_DETAILS_SUCCESS = 'FETCH_PERFORMANCE_DETAILS_SUCCESS'
export const FETCH_PERFORMANCE_DETAILS_FAILURE = 'FETCH_PERFORMANCE_DETAILS_FAILURE'

export const CHANGE_SELECTED_WEEK_SUMMARY = 'CHANGE_SELECTED_WEEK_SUMMARY'
export const TOGGLE_DETAILED_VIEW = 'TOGGLE_DETAILED_VIEW'
export const CHANGE_DATE_DETAILED_VIEW = 'CHANGE_DATE_DETAILED_VIEW'
export const CHANGE_PERFORMANCE_TYPE_DISPLAY = 'CHANGE_PERFORMANCE_TYPE_DISPLAY'
export const CHANGE_PERFORMANCE_COMPARISON_TYPES = 'CHANGE_PERFORMANCE_COMPARISON_TYPES'
export const RESET_PERFORMANCE_DATA = 'RESET_PERFORMANCE_DATA'
