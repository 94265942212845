/* eslint-disable no-lonely-if */
import { Grid } from '@mui/material'
import { isArray, isEmpty, values, debounce, filter } from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SSHModal from '../../../../components/SSHModal'
import * as gConstants from '../../../../config/genericConstants'
import PopupActions from '../../../../templates/PopupActions'
import { shipItems, showHideShipmentPopup, fetchOrderDetails, forceUpdate } from '../../OrderDetailsActions'
import ShipmentForm from './ShipmentForm'
import { showAlert, closeAlert } from '../../../../templates/Error/ErrorHandlerActions'
import { SHIPPING_SUCCESS, LABEL_MESSAGE } from '../../../../config/errorConstants'
import { SUCCESS } from '../../../../templates/Error/ErrorHandler.actionTypes'
import ForceUpdatePopup from './ForceUpdatePopup'

const DEFAULT_STATE = {
  manual: true,
  disableAutoShip: false,
  hasPrepaid: false,
  hasCollect: false,
  collectCount: 0,
  prepaidCount: 0,
  items: [],
  carrierMethods: [],
  selectedCarrier: {},
  trackingNumber: '',
  hasError: false,
  isSaved: false,
  isSaving: false,
  disabledClosed: false,
  saveSuccess: {
    manual: false,
    prepaid: false,
    collect: false
  },
  prepaidActive: true,
  isRemoveItems: false
}

class ShipmentPopupContainer extends PureComponent {
  constructor(props) {
    super(props)
    this.debouncedSetTrackingNumber = debounce(this.debouncedSetTrackingNumber, 300)
    this.state = { ...DEFAULT_STATE }
  }

  componentDidMount = () => {
    const { carrierMethods, labelGenCarrierMethods, shipLabelAccount } = this.props
    const { items } = this.state
    const newState = { hasPrepaid: false, hasCollect: false }

    if (!isEmpty(carrierMethods) && isArray(carrierMethods)) {
      newState.carrierMethods = carrierMethods.map(cm => ({
        name: cm.carrierMethodName,
        value: cm.carrierMethodId
      }))
    }

    const findPrepaid = filter(items, paymentTerm => {
      return (
        String(paymentTerm?.vendorPaymentTerm).toLocaleLowerCase() ===
        String(gConstants.PREPAID_SHIPPING_TYPE_FLAG).toLocaleLowerCase()
      )
    })

    if (!isEmpty(findPrepaid)) {
      newState.hasPrepaid = true
    }

    const findCollect = filter(items, paymentTerm => {
      return (
        String(paymentTerm?.vendorPaymentTerm).toLocaleLowerCase() ===
        String(gConstants.COLLECT_SHIPPING_TYPE_FLAG).toLocaleLowerCase()
      )
    })

    if (!isEmpty(findCollect)) {
      newState.hasCollect = true
    }

    let disableAutoShip = false

    if (!newState.hasPrepaid && !newState.hasCollect) {
      disableAutoShip = true
    } else {
      const shipLabelAccountIds = shipLabelAccount.map(carrier => String(carrier.carrierId)) // check scac (carrier id) vendor cuurent account details

      items.forEach(item => {
        if (
          shipLabelAccountIds.length > 0 &&
          String(item?.vendorPaymentTerm).toLowerCase() !== String(gConstants.COLLECT_SHIPPING_TYPE_FLAG).toLowerCase() &&
          shipLabelAccountIds.indexOf(String(item.scac)) === -1
        ) {
          disableAutoShip = true
          // eslint-disable-next-line no-useless-return
          return true
        }
      })

      // check (carrier method id)
      const labelGenCarrierMethodIds = labelGenCarrierMethods.map(method => String(method.carrierMethodId))
      items.forEach(item => {
        if (labelGenCarrierMethodIds.indexOf(String(item.carrierServiceCode)) === -1) {
          disableAutoShip = true
          // eslint-disable-next-line no-useless-return
          return true
        }
      })

      if (newState.hasPrepaid && newState.hasCollect) disableAutoShip = true // selected items are in combination disable auto generation
    }

    this.setState({ ...newState, disableAutoShip })
  }

  static getDerivedStateFromProps = ({ release, selectedRows }, { items, isRemoveItems }) => {
    if (!release) return {}

    const newItems = []

    if (items.length === 0 && !isRemoveItems) {
      selectedRows.map(row => {
        newItems.push({
          ...row,
          updatedFulfillmentQty: 0,
          error: false
        })
      })

      return {
        items: newItems
      }
    }
    return null
  }

  componentWillUnmount = () => {
    this.setState({ ...DEFAULT_STATE })
  }

  toggleShipmentType = () => {
    const { manual, items } = this.state

    const checkPrepaid = filter(items, paymentTerm => {
      return (
        String(paymentTerm?.vendorPaymentTerm).toLowerCase() ===
        String(gConstants.PREPAID_SHIPPING_TYPE_FLAG).toLowerCase()
      )
    }) // find prepaid is present in items

    this.setState({
      manual: !manual,
      prepaidActive: !isEmpty(checkPrepaid)
    })
  }

  handleShipBtn = () => {
    const {
      shipItems,
      fetchOrderDetails,
      showHideShipmentPopup,
      releaseNumber,
      release,
      resetSelectedRow,
      showAlert,
      isForceUpdate,
      forceUpdate
    } = this.props
    const { manual, prepaidActive, items, trackingNumber, selectedCarrier } = this.state

    this.setState({ isSaving: true, isSaved: false, disabledClosed: true })
    shipItems({
      manual,
      items,
      trackingNumber,
      selectedCarrier,
      releaseNumber,
      basicDetails: release?.basicDetails,
      prepaidActive
    })
      .then(response => {
        const MSG = `${SHIPPING_SUCCESS} ${!manual ? LABEL_MESSAGE : ''}`
        showAlert(SUCCESS, MSG) // show succeess message
        this.setState({
          saveSuccess: {
            manual: response.manual === false,
            prepaid: response.prepaid === false,
            collect: response.collect === false
          }
        })
        if (isForceUpdate) {
          forceUpdate(false)
        }

        setTimeout(() => {
          fetchOrderDetails()
          resetSelectedRow()
          showHideShipmentPopup({ releaseNumber })
        }, 5000)
      })
      .catch(error => {
        this.setState({
          saveSuccess: {
            manual: error?.apiResponse?.manual,
            prepaid: error?.apiResponse?.prepaid,
            collect: error?.apiResponse?.collect
          },
          disabledClosed: false
        })
      })
      .finally(() => {
        this.setState({
          isSaving: false,
          isSaved: true
        })
        if (isForceUpdate) {
          forceUpdate(false)
        }
      })
  }

  renderItems = () => {
    const { items, hasPrepaid, hasCollect, prepaidActive, manual } = this.state

    if (hasPrepaid && hasCollect && !manual) {
      if (prepaidActive) {
        return filter(items, paymentTerm => {
          return (
            String(paymentTerm?.vendorPaymentTerm).toLowerCase() ===
            String(gConstants.PREPAID_SHIPPING_TYPE_FLAG).toLowerCase()
          )
        })
      }
      return filter(items, paymentTerm => {
        return (
          String(paymentTerm?.vendorPaymentTerm).toLowerCase() ===
          String(gConstants.COLLECT_SHIPPING_TYPE_FLAG).toLowerCase()
        )
      })
    }

    return items
  }

  isAnyShipmentSuccess = () => {
    const { saveSuccess } = this.state
    return values(saveSuccess).filter(val => val === true).length > 0
  }

  /**
   * @description switch between collect and Tab
   */
  togglePrepaidCollect = () => {
    const { prepaidActive } = this.state
    this.setState({
      prepaidActive: !prepaidActive
    })
  }

  updateQtyToShip = (itemNumber, event) => {
    const { items } = this.state
    const { value } = event.target
    const itemQty = items.map(item => {
      if (item.itemNumber === itemNumber) {
        item.updatedFulfillmentQty = value
        item.error =
          parseInt(value, 10) > parseInt(item.currentfulfilmentQty, 10) ||
          parseInt(value, 10) === 0 ||
          value.trim() === ''
      }
      return item
    })

    this.setState({
      items: itemQty,
      hasError: filter(itemQty, { error: true }).length > 0
    })
  }

  selectCarrierMethod = ({ name, value }) => {
    this.setState({
      selectedCarrier: {
        name,
        value
      }
    })
  }

  setTrackingNumber = event => {
    this.debouncedSetTrackingNumber({ value: event.target?.value })
  }

  debouncedSetTrackingNumber = ({ value }) => {
    this.setState({
      trackingNumber: value
    })
  }

  closeShipmentPopup = () => {
    const { showHideShipmentPopup, releaseNumber, closeAlert, resetSelectedRow } = this.props
    const { disabledClosed } = this.state
    if (!disabledClosed) {
      showHideShipmentPopup({ releaseNumber })
      closeAlert()
      resetSelectedRow()
    }
  }

  removeSelecetdItem = ({ itemNumber }) => {
    const { items } = this.state
    const removeItems = filter(items, item => item.itemNumber !== itemNumber)

    this.setState({
      items: removeItems,
      isRemoveItems: removeItems.length === 0
    })
    if (removeItems.length === 0) {
      this.closeShipmentPopup()
    }
  }

  render = () => {
    const { showCarrierRegistrationPopup, carrierAccount, releaseNumber, flags, isForceUpdate, forceUpdate, errorMessage } = this.props
    const {
      manual,
      hasError,
      isSaving,
      selectedCarrier,
      carrierMethods,
      trackingNumber,
      disableAutoShip,
      prepaidActive,
      hasPrepaid,
      hasCollect
    } = this.state

    if (showCarrierRegistrationPopup) return null

    return (
      <SSHModal
        title="Shipping details"
        onClose={this.closeShipmentPopup}
        size="lg"
        showDivider={false}
        actions={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <PopupActions
            disabled={
              !!hasError ||
              !!isSaving ||
              this.isAnyShipmentSuccess() ||
              (manual && (isEmpty(trackingNumber.trim()) || isEmpty(selectedCarrier.value))) ||
              (!manual && isEmpty(carrierAccount) && prepaidActive)
            }
            isLoading={!!isSaving}
            alignRight
            confirmText={manual ? 'Update items as shipped' : 'Print and ship'}
            onConfirm={this.handleShipBtn}
          />
        }>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ShipmentForm
              items={this.renderItems()}
              manual={manual}
              toggleShipmentType={this.toggleShipmentType}
              carrierMethods={carrierMethods}
              updateQtyToShip={this.updateQtyToShip}
              selectCarrierMethod={this.selectCarrierMethod}
              setTrackingNumber={this.setTrackingNumber}
              trackingNumber={trackingNumber}
              selectedCarrier={selectedCarrier}
              releaseNumber={releaseNumber}
              disableAutoShip={disableAutoShip}
              carrierAccount={carrierAccount}
              prepaidActive={prepaidActive}
              hasPrepaid={hasPrepaid}
              hasCollect={hasCollect}
              togglePrepaidCollect={this.togglePrepaidCollect}
              flags={flags}
              removeSelecetdItem={this.removeSelecetdItem}
            />
          </Grid>
          <Grid>
            {isForceUpdate && <ForceUpdatePopup handleForceUpdate={this.handleShipBtn} cancelShipping={forceUpdate} errorMessage={errorMessage} />}
          </Grid>
        </Grid>
      </SSHModal>
    )
  }
}
export const ShipmentPopupContainerNaked = ShipmentPopupContainer

ShipmentPopupContainer.propTypes = {
  showHideShipmentPopup: PropTypes.func.isRequired,
  showCarrierRegistrationPopup: PropTypes.func.isRequired,
  shipItems: PropTypes.func.isRequired,
  fetchOrderDetails: PropTypes.func.isRequired,
  releaseNumber: PropTypes.string.isRequired,
  release: PropTypes.object.isRequired,
  flags: PropTypes.object.isRequired,
  labelGenCarrierMethods: PropTypes.array.isRequired,
  carrierMethods: PropTypes.array.isRequired,
  carrierAccount: PropTypes.array.isRequired
}
const mapStateToProps = state => ({
  carrierAccount: state.FulfillmentNew.carrierMethods.shipLabelAccount.accountDetails,
  carrierMethods: state.FulfillmentNew.carrierMethods.list,
  labelGenCarrierMethods: state.FulfillmentNew.carrierMethods.supportedCarrierMethods,
  flags: state.OrderDetailsReducer.shipment.flags,
  showCarrierRegistrationPopup: state.FulfillmentNew.carrierMethods.shipLabelAccount.showPopup,
  shipLabelAccount: state.FulfillmentNew.carrierMethods.shipLabelAccount.accountDetails
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showHideShipmentPopup,
      forceUpdate,
      shipItems,
      fetchOrderDetails,
      showAlert,
      closeAlert
    },
    dispatch
  )
export default connect(mapStateToProps, mapDispatchToProps)(ShipmentPopupContainer)
