/* eslint-disable react/destructuring-assignment */
import { Table } from '@devexpress/dx-react-grid-material-ui'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setShipNodeLevelFlag } from '../../pages/MantisLeadTime/MantisLeadTimeAction'
import SSHSwitch from '../../components/SSHSwitch'

const MantisStatusCell = ({ ...props }) => {
  const onChange = e => {
    props.setShipNodeLevelFlag({ shipNodeId: e.target.name })
  }

  return (
    <Table.Cell {...props}>
      <SSHSwitch onChange={onChange} name={props.row.shipNodeId} checked={props.row.enabled} />
    </Table.Cell>
  )
}

MantisStatusCell.propTypes = {
  enabled: PropTypes.bool.isRequired
}

export const MantisStatusCellNaked = MantisStatusCell

const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch => bindActionCreators({ setShipNodeLevelFlag }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MantisStatusCell)
