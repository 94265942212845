import React from 'react'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { setTwoDecimalPoint } from '../../utils/parsers'
import { COLORS } from '../Entry/globalMUIStyles'

const styles = theme => ({
    bgColor: {
        backgroundColor: COLORS.lightOrange
    }
})

const FcapUtilizationCell = ({ classes, goToChangeLog, ...props }) => {
    const STYLES = clsx({
        [classes.bgColor]: props.value ? parseFloat(props.value) > 70 : false
    })
    return (
        <Table.Cell {...props} className={STYLES}>
            <Typography variant="body2" >
                {props.value !== null ? `${setTwoDecimalPoint(props.value)}%` : ''}
            </Typography>
        </Table.Cell>
    )
}

FcapUtilizationCell.propTypes = {
    classes: PropTypes.object.isRequired,
    props: PropTypes.object.isRequired
}

export default withStyles(styles)(FcapUtilizationCell)
export const FcapUtilizationCellNaked = FcapUtilizationCell
