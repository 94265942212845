import { cloneDeep, filter, isEmpty } from 'lodash'
import moment from 'moment'
import config from '../../config'
import * as gConstants from '../../config/genericConstants'
// eslint-disable-next-line import/no-cycle
import { changePage } from '../../reduxSetup/commonActions/AppConfig'
import {
  FETCH_PAGINATED_TABLE_DATA_FAILURE,
  FETCH_PAGINATED_TABLE_DATA_REQUEST,
  FETCH_PAGINATED_TABLE_DATA_SUCCESS
} from '../../reduxSetup/commonActions/SSHPaginatedTable.actionTypes'
import {
  aiReturnOrdersFetch,
  aiReturnOrdersFailure,
  aiReturnOrdersAckSuccess,
  aiReturnOrdersAckFailure
} from '../../reduxSetup/commonActions/TelemetryActionsNew'
import { CALL_API } from '../../reduxSetup/middleware/API'
import { getShipNodeIdFromState, getVendorIdFromState } from '../../utils/common'
import {
  ORDER_CARRIER_TRACKING_REQUEST,
  ORDER_CARRIER_TRACKING_SUCCESS,
  ORDER_CARRIER_TRACKING_FAILURE,
  SET_PENDING_ITEM_COUNT,
  SET_ACKNOWLEDGE_ITEM_COUNT,
  SET_RETURN_TAB,
  SET_SEARCH_QUERY,
  SET_PENDING_ORDERS,
  SET_ACKNOWLEDGE_ORDERS,
  CLEAR_SEARCH_PARAMS,
  SET_FILTER_OPEN
} from './ReturnOrder.types'
import { fetchCarrierMethods } from '../FulfillmentNew/FulfillmentAction'
import { showAlert } from '../../templates/Error/ErrorHandlerActions'
import { UPDATE_ORDER_MESSAGE } from '../../config/errorConstants'
import { SUCCESS } from '../../templates/Error/ErrorHandler.actionTypes'

/**
 * @description Fetching order details of selected tab
 * @param {function} dispatch
 * @param {function} getState
 * @return {function(any, any=): any}
 */
export const fetchReturnOrderItems = status => (dispatch, getState) => {
  const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
  const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId
  const CARRIER_METHODS = getState().FulfillmentNew.carrierMethods.list

  const { searchKey, searchValue } = getState().ReturnOrder?.searchQuery
  let orderDates = getState().ReturnOrder?.orderDateRange
  let url = `${config.get('returnFlow')}/${VENDOR_ID}/${SHIP_NODE_ID}?status=${status}&pageNo=${PAGE_NO +
    1}&pageSize=${PAGE_SIZE}`

  if (searchKey && searchValue) {
    url = `${url}&${searchKey}=${searchValue}`
  }

  if (orderDates.from && orderDates.to) {
    orderDates = {
      from: moment(orderDates.from).format(gConstants.yearFirstFormat),
      to: moment(orderDates.to).format(gConstants.yearFirstFormat)
    }
    url = `${url}&minOrderDate=${orderDates.from}&maxOrderDate=${orderDates.to}`
  }

  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_PAGINATED_TABLE_DATA_REQUEST,
        FETCH_PAGINATED_TABLE_DATA_SUCCESS,
        FETCH_PAGINATED_TABLE_DATA_FAILURE
      ],
      endpoint: url,
      method: 'GET',
      payload: {
        vendorId: VENDOR_ID,
        shipNodeId: SHIP_NODE_ID,
        PAGE_SIZE,
        PAGE_NO,
        pathName: getState().router.location.pathname
      }
    }
  }).then(({ type, response }) => {
    if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS) {
      dispatch(aiReturnOrdersFetch({ type, response }))
      if (isEmpty(CARRIER_METHODS)) {
        dispatch(fetchCarrierMethods())
      }
      if (status === gConstants.ORDER_STATUSES[0].value) {
        dispatch(setPendingOrders({ orders: fetchOrderList(response) }))
        return dispatch(setPendingItemsCount({ count: fetchTotalCount(response) }))
      }
      dispatch(setAcknowledgeOrders({ orders: fetchOrderList(response) }))
      return dispatch(setAcknowledgeItemCount({ count: fetchTotalCount(response) }))
    }
    dispatch(aiReturnOrdersFailure({ type, errorMessage: response }))
    return false
  })
}

const fetchOrderList = (response) => {
  return response?.payload?.ordersList || []
}

const fetchTotalCount = (response) => {
  return response?.payload?.total || 0
}

export const fetchReturnOrderItemsFromPath = orderNo => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId
  const CARRIER_METHODS = getState().FulfillmentNew.carrierMethods.list

  const url = `${config.get('returnFlow')}/${VENDOR_ID}/${SHIP_NODE_ID}?&orderNo=${orderNo}`

  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_PAGINATED_TABLE_DATA_REQUEST,
        FETCH_PAGINATED_TABLE_DATA_SUCCESS,
        FETCH_PAGINATED_TABLE_DATA_FAILURE
      ],
      endpoint: url,
      method: 'GET',
      payload: {
        vendorId: VENDOR_ID,
        shipNodeId: SHIP_NODE_ID,
        pathName: getState().router.location.pathname
      }
    }
  }).then(({ type, response }) => {
    if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS) {
      dispatch(setPendingOrders({ orders: response?.payload?.ordersList }))
      dispatch(aiReturnOrdersFetch({ type, response }))
      if (isEmpty(CARRIER_METHODS)) {
        dispatch(fetchCarrierMethods())
      }
      return dispatch(setPendingItemsCount({ count: response?.payload?.total || 0 }))
    }
    dispatch(aiReturnOrdersFailure({ type, errorMessage: response }))
    return false
  })
}

export const setPendingOrders = ({ orders }) => dispatch => {
  return dispatch({
    type: SET_PENDING_ORDERS,
    payload: {
      orders
    }
  })
}

export const setAcknowledgeOrders = ({ orders }) => dispatch => {
  return dispatch({
    type: SET_ACKNOWLEDGE_ORDERS,
    payload: {
      orders
    }
  })
}

export const setPendingItemsCount = ({ count }) => dispatch => {
  return dispatch({
    type: SET_PENDING_ITEM_COUNT,
    payload: { count }
  })
}

/**
 * @description Setting non active tab ordercount
 * @param {number} count
 * @return {function(any, any=): any}
 */
export const setAcknowledgeItemCount = ({ count }) => dispatch => {
  return dispatch({
    type: SET_ACKNOWLEDGE_ITEM_COUNT,
    payload: { count }
  })
}

/**
 * @description Setting active tab and initial ordercount to 0
 * @param {string} tabName
 * @return {function(any, any=): any}
 */
export const setReturnTab = ({ tabName }) => dispatch => {
  return dispatch({
    type: SET_RETURN_TAB,
    payload: { tabName }
  })
}

/**
 * @description Set Search query params
 * @param {object} searchQuery
 * @return {function(any, any=): any}
 */
export const setReturnSearchQuery = ({ searchQuery }) => dispatch => {
  return dispatch({
    type: SET_SEARCH_QUERY,
    payload: { searchQuery }
  })
}

/**
 * @description Open/Close Filters Panel
 * @param {boolean} filterOpen
 * @return {function(any, any=): any}
 */
export const setFilterOpen = (filterOpen) => dispatch => {
  return dispatch({
    type: SET_FILTER_OPEN,
    payload: { filterOpen }
  })
}

/**
 * @description Navigate to order details page for any given order
 * @param {object} order
 * @param {number} orderIndex
 * @return {function(any, any=): any}
 */
export const goToReturns = ({ returnOrder }) => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId
  const { orderNo } = returnOrder
  return dispatch(changePage(`/returns/vendor/${VENDOR_ID}/shipNode/${SHIP_NODE_ID}/order/${orderNo}`))
}

/**
 * @description fetch return order flow
 * @param {Number} pageNo
 * @param {Number} pageSize
 * @returns {Function} Redux Action
 */
export const updateOrderCarrierTracking = ({ ordersList }) => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId
  const ORDERS = filter(ordersList?.orderLines, { isModified: true })

  const NEW_ORDERS = cloneDeep(ORDERS) // if fails retain modfied orders

  NEW_ORDERS.forEach(o => {
    delete o.isModified
    delete o.carrierNameOthers
    o.status = gConstants.ORDER_STATUSES[1].value
    return o
  })

  const reqObj = {
    orderNo: ordersList?.orderNo,
    salesOrderNo: ordersList?.salesOrderNo,
    status: gConstants.ORDER_STATUSES[1].value,
    shipNodeId: SHIP_NODE_ID,
    orderLines: [...NEW_ORDERS]
  }

  dispatch({
    [CALL_API]: {
      types: [ORDER_CARRIER_TRACKING_REQUEST, ORDER_CARRIER_TRACKING_SUCCESS, ORDER_CARRIER_TRACKING_FAILURE],
      endpoint: `${config.get('returnFlow')}/${VENDOR_ID}/${SHIP_NODE_ID}`,
      method: 'PUT',
      data: reqObj
    }
  })
    .then(({ type, response }) => {
      const { payload } = response
      if (type === ORDER_CARRIER_TRACKING_SUCCESS) {
        if (payload?.updated) {
          dispatch(showAlert(SUCCESS, UPDATE_ORDER_MESSAGE))
          setTimeout(() => {
            window.location.reload()
            return dispatch(aiReturnOrdersAckSuccess({ type, response }))
          }, 1000)
        }
      }
      return dispatch(aiReturnOrdersAckFailure({ type, errorMessage: response }))
    })
    .catch(error => {
      dispatch(aiReturnOrdersAckFailure({ errorMessage: error }))
    })
}

/**
 * @description Clear Search Params on reset search
 * @return {function(any, any=): any}
 */
export const clearSearchParams = () => dispatch => {
  return dispatch({
    type: CLEAR_SEARCH_PARAMS
  })
}
