import { Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

const SSHTooltip = ({  title,className,  placement, children }) => {
  if (!title) {
    return children
  }
  return (
    <Tooltip data-testid="SSHTooltip" className={className}  arrow title={title} placement={placement || 'left'}>
      {children}
    </Tooltip>
  )
}

SSHTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  placement: PropTypes.string,
  children: PropTypes.object.isRequired
}

SSHTooltip.defaultProps = {
  placement: 'left'
}

export default SSHTooltip
