import React, { PureComponent } from 'react'
import { Typography, TextareaAutosize } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SSHButton from '../../components/SSHButton'
import { showToastMessage } from '../../reduxSetup/commonActions/AppConfig'
import { GIFT_MESSAGE } from '../../config/errorConstants'

const styles = theme => ({
    textArea: {
        resize: 'none',
        border: 'none',
        width: theme.spacing(37.25),
        outline: 'none',
        lineHeight: '1.33',
        background: 'none',
        fontFamily: 'canada-type-gibson',
        color: theme.palette.primary.downloadLabelText,
        fontSize: `${theme.spacing(1.75)}px`
    },
    copyText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    }
})


class GiftMessage extends PureComponent {

    constructor(props) {
        super(props);
        this.textAreaRef = React.createRef();
    }

    copyToClipBoard = ((e) => {
        const { showToastMessage } = this.props
        this.textAreaRef.current.select()
        document.execCommand('copy')
        e.stopPropagation()
        showToastMessage({ message: GIFT_MESSAGE })
    })

    render() {
        const { text, classes } = this.props
        return (
            <>
                {
                    <>
                        <Typography variant='body1' className={classes.copyText}>
                            <TextareaAutosize
                                readOnly
                                className={classes.textArea}
                                onClick={(e) => e.stopPropagation()}
                                ref={this.textAreaRef}
                                value={text}
                            />
                            <SSHButton Icon={FileCopyIcon} primary onClick={this.copyToClipBoard} tooltip='Click to copy message' />
                        </Typography>
                    </>
                }
            </>
        )
    }
}
GiftMessage.propTypes = {
    item: PropTypes.object.isRequired,
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { showToastMessage },
        dispatch
    )

export default connect(null, mapDispatchToProps)(withStyles(styles)(GiftMessage))
