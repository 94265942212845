import { Table } from '@devexpress/dx-react-grid-material-ui'
import { IconButton } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ReactComponent as Accept } from '../../assets/images/Accept.svg'

const styles = theme => ({
  icon: {
    padding: theme.spacing(0.2),
    '& svg': {
      width: '24px',
      height: '20.8px'
    }
  }
})

class GiftActionsCell extends Component {
  render = () => {
    const { classes, row } = this.props
    return (
      <Table.Cell {...this.props}>
        {row.includeGiftLabel && (
          <IconButton className={classes.icon} disableRipple>
            <Accept className={classes.icon} />
          </IconButton>
        )}
      </Table.Cell>
    )
  }
}

GiftActionsCell.propTypes = {
  classes: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired
}

export const GiftActionsCellNaked = GiftActionsCell

export default withStyles(styles)(GiftActionsCell)
