import React from 'react'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SSHTooltip from '../../components/SSHTooltip'
import { ArrayFormatter } from './CustomFieldTypeProvider'
import { setViewChangesPopup } from '../../pages/SupplierManagement/SupplierOnBoardAction'

const styles = theme => ({
    link: {
        color: theme.palette.primary.blue,
        cursor: 'pointer'
    }
})

const ViewChangesCell = ({ classes, row, setViewChangesPopup, ...props }) => {

    const onClick = () => {
        try {
            const changes = JSON.parse(row?.details)
            if(changes?.address || changes?.contactInfo || changes?.operatingCalendar){
                setViewChangesPopup(true, row?.details)
            }
        } catch(e) {
            console.log('INVALID JSON', e)
        }
    }


    return (
        <Table.Cell {...props}>
            {(String(row?.requestType).toLowerCase() === 'update' || String(row?.requestType).toLowerCase() === 'create') ?
                <SSHTooltip title='Click to view changes' placement="top-start">
                    <Typography variant="body2" className={classes.link} onClick={onClick}>
                        <ArrayFormatter value="View changes" noTooltip />
                    </Typography>
                </SSHTooltip>
                : <span>-</span>}
        </Table.Cell>
    )
}

ViewChangesCell.propTypes = {
    classes: PropTypes.object.isRequired,
    props: PropTypes.object.isRequired
}

const mapDispatchToProps = dispatch => bindActionCreators({ setViewChangesPopup }, dispatch)

export default connect(null, mapDispatchToProps)(withStyles(styles)(ViewChangesCell))

export const ViewChangesCellNaked = ViewChangesCell
