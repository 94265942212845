import { isEmpty, uniq } from 'lodash'
import config from '../../../config'
import {
    FETCH_PAGINATED_TABLE_DATA_FAILURE,
    FETCH_PAGINATED_TABLE_DATA_REQUEST,
    FETCH_PAGINATED_TABLE_DATA_SUCCESS
} from '../../../reduxSetup/commonActions/SSHPaginatedTable.actionTypes'
import { CALL_API } from '../../../reduxSetup/middleware/API'
import { getVendorIdFromState } from '../../../utils/common'
import { changePage } from '../../../reduxSetup/commonActions/AppConfig'
import {
    SET_FORECAST_ITEM_COUNT,
    SET_SELECTED_ITEM,
    SET_CLUB_WEEKLY_FORECAST,
    SET_FILTERS_OPEN
} from './Forecast.types'
import { FILTER_KEYS } from '../../../config/genericConstants'

/**
* @description fetch forecasted Items
* @returns {function}
*/
export const fetchForecastItems = () => (dispatch, getState) => {
    const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
    const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
    const { isAdmin } = getState().UserInfo
    const VENDOR_ID = isAdmin ? null : getVendorIdFromState(getState)
    const filters = getState().ClubForecastChart.forecastFilterApplied
    if (!isAdmin && !VENDOR_ID) {
        return
    }

    return dispatch({
        [CALL_API]: {
            types: [
                FETCH_PAGINATED_TABLE_DATA_REQUEST,
                FETCH_PAGINATED_TABLE_DATA_SUCCESS,
                FETCH_PAGINATED_TABLE_DATA_FAILURE
            ],
            endpoint: `${config.get('clubInventoryForecast')}?type=sales&pageNo=${PAGE_NO + 1}&pageSize=${PAGE_SIZE}`,
            method: 'POST',
            payload: {
                vendorId: VENDOR_ID,
                PAGE_SIZE,
                PAGE_NO,
                pathName: getState().router.location.pathname
            },
            data: {
                supplierId: VENDOR_ID,
                filters
            }
        }
    }).then(({ type, response }) => {
        if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS) {
            return dispatch(setForecastItemCount({ count: response?.totalRecords || 0 }))
        }
        return false
    })
}

/**
* @description set forecast items count
* @param {object} {count}
* @return {function}
*/
export const setForecastItemCount = ({ count }) => dispatch => {
    return dispatch({
        type: SET_FORECAST_ITEM_COUNT,
        payload: { count }
    })
}

/**
* @description change page to goto club details page
* @param {object} itemDetails
* @returns {function}
*/
export const goToClubDetails = ({ itemDetails }) => (dispatch, getState) => {
    const { isAdmin } = getState().UserInfo
    const VENDOR_ID = getVendorIdFromState(getState)
    const VENDOR_ID_PATH_PARAM = isAdmin ? '0000' : `${VENDOR_ID}`
    const { itemId } = itemDetails
    dispatch(setSelectedItem({ itemDetails }))
    dispatch(changePage(`/club/sales-forecast/vendor/${VENDOR_ID_PATH_PARAM}/item/${itemId}`))
}

/**
* @description set selected item based on itemId click
* @param {object} itemDetails
* @returns {function}
*/
export const setSelectedItem = ({ itemDetails }) => (dispatch, getState) => {
    return dispatch({
        type: SET_SELECTED_ITEM,
        payload: { itemDetails }
    })
}

/**
* @description fetch forecasted clubs for selected ItemId
* @param {string} itemId - optional
* @returns {function}
*/
export const fetchForecastClubs = (itemId, clubId) => (dispatch, getState) => {
    const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
    const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
    const { isAdmin } = getState().UserInfo
    const VENDOR_ID = isAdmin ? null : getVendorIdFromState(getState)
    let selectedItemId = null
    let clubFilter = []
    if (!isAdmin && !VENDOR_ID) {
        return
    }

    if (!isEmpty(itemId)) {
        selectedItemId = itemId
    } else {
        selectedItemId = getState().ClubSaleForecast.selectedItem?.itemId
    }

    if (!isEmpty(clubId)) {
        if (clubId.includes(',')) {
            clubFilter = uniq(clubId.replace(/\s+/g, '')?.split(','))
        } else {
            clubFilter.push(clubId)
        }
    }

    return dispatch({
        [CALL_API]: {
            types: [
                FETCH_PAGINATED_TABLE_DATA_REQUEST,
                FETCH_PAGINATED_TABLE_DATA_SUCCESS,
                FETCH_PAGINATED_TABLE_DATA_FAILURE
            ],
            endpoint: `${config.get('clubInventoryForecast')}/${selectedItemId}?type=sales&pageNo=${PAGE_NO + 1}&pageSize=${PAGE_SIZE}`,
            method: 'POST',
            payload: {
                vendorId: VENDOR_ID,
                PAGE_SIZE,
                PAGE_NO,
                pathName: getState().router.location.pathname
            },
            data: {
                supplierId: VENDOR_ID,
                filters: [{
                    filterName: FILTER_KEYS.CLUB_ID,
                    filterValues: clubFilter
                }]
            }
        }
    }).then(({ type, response }) => {
        if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS) {
            return dispatch(setForecastItemCount({ count: response?.totalRecords || 0 }))
        }
        return false
    })
}

/**
* @description set weekly forecast display in the right drawer for selected club
* @param {object} clubDetails
* @returns {function}
*/
export const displayWeeklyForecast = ({ clubDetails, displayForecastDrawer }) => dispatch => {
    return dispatch({
        type: SET_CLUB_WEEKLY_FORECAST,
        payload: { clubDetails, displayForecastDrawer }
    })
}

/**
* @description set filters drawer open
* @param {boolean} {isOpen}
* @return {function}
*/
export const setFilterOpen = (isOpen) => dispatch => {
    return dispatch({
        type: SET_FILTERS_OPEN,
        payload: { isOpen }
    })
}


