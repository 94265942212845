import { Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SSHLoadingAnimation from '../components/SSHLoadingAnimation'
import { changePage } from '../reduxSetup/commonActions/AppConfig'

class LoggingIn extends PureComponent {
  componentDidUpdate = () => {
    const { loggingIn, loggedIn, changePage } = this.props
    if (loggingIn) return false
    if (loggedIn) {
      return changePage('/search')
    }
    if (!loggedIn && !loggingIn) return changePage('/unauthorized')

    return changePage('/unauthorized') // everything false
  }

  render = () => {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column"  sx={{minHeight:'70vh'}}  spacing={4}>
        <Grid item>
          <Typography variant="body1">Authenticating your session ... </Typography>
        </Grid>
        <Grid item>
          <SSHLoadingAnimation />
        </Grid>
      </Grid>
    )
  }
}

LoggingIn.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  changePage: PropTypes.func.isRequired
}

export const LoggingInNaked = LoggingIn

const mapStateToProps = state => ({
  loggedIn: state.UserInfo.loggedIn,
  loggingIn: state.UserInfo.loggingIn
})

const mapDispatchToProps = dispatch => bindActionCreators({ changePage }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(LoggingIn)
