import { routerMiddleware } from 'connected-react-router'
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import createRootReducer from './combinedReducers'
import API from './middleware/API'
import reduxActionsLogger from './middleware/logger'

export const history = createBrowserHistory()
const middlewares = [routerMiddleware(history), thunk, API]

// dev debug
let composeEnhancers = compose

if (module.hot) {
  middlewares.push(reduxActionsLogger)
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}

export default createStore(createRootReducer(history), composeEnhancers(applyMiddleware(...middlewares)))
