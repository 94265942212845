import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Typography } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import SSHTooltip from '../../components/SSHTooltip'

/**
 * @description Component to render custom table cell for Downloaded file name
 * @param {Object} row Data object with list item details
 * @param {Object} ackIds State Data object with list of reports
 * @returns React element with the value of downloaded file name once received
 */
const DownloadedFileCell = ({ row, ackIds, ...props }) => {
  const ackStateObj = ackIds.find(ackIdObj => ackIdObj.ackId === row.ackId)

  return (
    <Table.Cell {...props}>
      <SSHTooltip
        title={
          ackStateObj && ackStateObj.fileName
            ? ackStateObj.fileName.substr(ackStateObj.fileName.lastIndexOf('/') + 1, ackStateObj.fileName.length)
            : row.fileName
        }
        placement="left">
        <Typography variant="body2">
          {ackStateObj && ackStateObj.fileName
            ? ackStateObj.fileName.substr(ackStateObj.fileName.lastIndexOf('/') + 1, ackStateObj.fileName.length)
            : row.fileName}
        </Typography>
      </SSHTooltip>
    </Table.Cell>
  )
}

const mapStateToProps = state => ({
  ackIds: state.AppConfig.exportOrders.ackIds
})

export default connect(mapStateToProps, null)(DownloadedFileCell)
