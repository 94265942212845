/* eslint-disable import/no-cycle */
import { cloneDeep, isEmpty } from 'lodash'
import config from '../../config'
import { FILTER_KEYS, ITEM_ACTION_TYPES, OOS_STATUS, PAST_TRENDS_TYPES, ROUTE_MODULES } from '../../config/genericConstants'
import {
  FETCH_PAGINATED_TABLE_DATA_FAILURE,
  FETCH_PAGINATED_TABLE_DATA_REQUEST,
  FETCH_PAGINATED_TABLE_DATA_SUCCESS,
  CLIENT_PAGINATION,
  CLIENT_SORTING,
  UPDATE_SORT_ARROW
} from '../../reduxSetup/commonActions/SSHPaginatedTable.actionTypes'
import {
  aiSmartInventoryFetchSuppliers,
  aiSmartInventoryFetchAggregated,
  aiSmartInventoryFetchItems,
  aiSmartInventoryAggregatedSearch,
  aiSmartInventoryItemSearch,
  aiSmartInventoryRemoveFilter,
  aiSmartInventoryReset,
  aiSmartInventoryTableAction,
  aiSmartInventoryFetchDivisions,
  aiSmartInventoryPastTrendsAction
} from '../../reduxSetup/commonActions/TelemetryActionsNew'

import { CALL_API } from '../../reduxSetup/middleware/API'
import { ERROR_OCCURRED } from '../../templates/Error/ErrorHandler.actionTypes'
import {
  capitalizeSentence,
  getVendorIdFromState,
  isAggregatedCall,
  prepareSmartInventoryApiRequest
} from '../../utils/common'
import { getAggregatedFiltersData, setPastTrendsRequestBody } from '../../utils/parsers'
import {
  SET_SMART_INVENTORY_ROW_COUNT,
  SEARCH_SMART_INVENTORY_ITEM,
  SET_SMART_INVENTORY_TAB,
  SET_FILTERS,
  REMOVE_FILTER,
  FETCH_SUPPLIERS_REQUEST,
  FETCH_SUPPLIERS_SUCCESS,
  FETCH_SUPPLIERS_FAILURE,
  SET_SELECTED_FILTERS,
  RESET_FILTER,
  SHOW_FILTERS_POPUP,
  HIDE_FILTERS_POPUP,
  SET_SMART_INVENTORY_AGGREGATED_ROWS,
  RESET_TAB,
  UPDATE_ALERT_TYPE,
  UPDATE_ALERT_TYPE_CHIP,
  RESET_SMART_INVENTORY_ITEM_SEARCH,
  UPDATE_API_FILTER,
  SHOW_ACTION_CONFIRMATION_POPUP,
  HIDE_ACTION_CONFIRMATION_POPUP,
  SHOW_SUSPEND_ALERT_POPUP,
  HIDE_SUSPEND_ALERT_POPUP,
  SHOW_TERMINATE_ALERT_POPUP,
  HIDE_TERMINATE_ALERT_POPUP,
  SET_ITEM_DETAILS,
  SET_ALERT_ACTION_REQUEST,
  SET_ALERT_ACTION_SUCCESS,
  SET_ALERT_ACTION_FAILURE,
  SET_OPEN_FILTER,
  FETCH_DIVISIONS_REQUEST,
  FETCH_DIVISIONS_SUCCESS,
  FETCH_DIVISIONS_FAILURE,
  UPDATE_COLUMN_VISIBILITY,
  FETCH_PAST_TRENDS_OOS_DATA_REQUEST,
  FETCH_PAST_TRENDS_OOS_DATA_SUCCESS,
  FETCH_PAST_TRENDS_OOS_DATA_FAILURE,
  FETCH_PAST_TRENDS_RL_DATA_REQUEST,
  FETCH_PAST_TRENDS_RL_DATA_SUCCESS,
  FETCH_PAST_TRENDS_RL_DATA_FAILURE,
  UPDATE_CHART_VIEW,
  UPDATE_CHART_DURATION
} from './SmartInventory.actionTypes'
import { INITIAL_STATE } from './SmartInventoryReducer'
import { SMART_INVENTORY_TABS } from './SmartInventoryTabSwitcher'

/**
 * @description Set the table's row count to the passed param value
 * @param {Number} count
 * @returns {function(...[*]=)}
 */
export const setSmartInventoryRowCount = ({ count }) => dispatch => {
  return dispatch({
    type: SET_SMART_INVENTORY_ROW_COUNT,
    payload: { count }
  })
}

/**
 * @description Fetch list of smart inventory for items
 * @returns List of inventory items
 */
export const fetchSmartInventory = ({ sortColumn = '', sortType = '', searchQuery = '' } = {}) => (
  dispatch,
  getState
) => {
  const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
  const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
  const VENDOR_ID = getVendorIdFromState(getState)
  const IS_VENDOR = getState().UserInfo.isVendor
  const filters = getState().SmartInventory.apiRequestFilters
  const alertType = getState().SmartInventory.selectedAlertType
  const selectedTab = getState().SmartInventory.smartInventoryTab
  const { lastItemSortColumn } = getState().SSHPaginatedTableReducer

  if (sortColumn === '' && sortType === '') {
    // eslint-disable-next-line no-param-reassign
    sortColumn = lastItemSortColumn.columnName
    // eslint-disable-next-line no-param-reassign
    sortType = lastItemSortColumn.direction
  }

  const requestedFilters = cloneDeep(filters)
  if (IS_VENDOR) {
    requestedFilters.push({
      filterName: FILTER_KEYS.SUPPLIER_ID,
      filterValues: [VENDOR_ID]
    })
  }

  if (!isEmpty(searchQuery)) {
    dispatch({
      type: SEARCH_SMART_INVENTORY_ITEM,
      payload: { searchQuery }
    })
  } else {
    dispatch({
      type: SEARCH_SMART_INVENTORY_ITEM,
      payload: { searchQuery: '' }
    })
  }

  const filtersWithAlerts = [
    ...requestedFilters,
    { filterName: 'alertType', filterValues: alertType?.value === 'All' ? [] : [alertType.value] }
  ]

  const allDigitsPattern = /^\d+$/
  const searchFilters = [
    ...filtersWithAlerts,
    allDigitsPattern.test(searchQuery)
      ? { filterName: 'itemNbr', filterValues: [searchQuery] }
      : { filterName: 'itemDesc', filterValues: [searchQuery] }
  ]

  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_PAGINATED_TABLE_DATA_REQUEST,
        FETCH_PAGINATED_TABLE_DATA_SUCCESS,
        FETCH_PAGINATED_TABLE_DATA_FAILURE
      ],
      endpoint: `${String(process.env.REACT_APP_ALERTS_ITEMS) === 'v2' ? config.get('inventoryItems') : config.get('inventoryItems').replace('smartinventory', 'alerts')}`,
      method: 'POST',
      payload: {
        vendorId: IS_VENDOR ? VENDOR_ID : '',
        PAGE_SIZE,
        PAGE_NO,
        pathName: getState().router.location.pathname,
        selectedTab
      },
      data: {
        filters: !isEmpty(searchQuery) ? searchFilters : filtersWithAlerts,
        sorting: {
          sortBy: getQuerySortColumn(sortColumn),
          sortType
        },
        vendorId: IS_VENDOR ? VENDOR_ID : '',
        pageSize: PAGE_SIZE,
        pageNo: PAGE_NO + 1
      }
    }
  }).then(({ type, response }) => {
    if (!isEmpty(searchQuery)) {
      dispatch(aiSmartInventoryItemSearch({ type, response, searchQuery }))
    } else {
      dispatch(aiSmartInventoryFetchItems({ type, response, requestedFilters, alertType }))
    }
    if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS) {
      dispatch({
        type: UPDATE_SORT_ARROW,
        payload: {
          colName: sortColumn,
          sortType
        }
      })
      return Promise.resolve(
        dispatch(setSmartInventoryRowCount({ count: parseInt(response.payload.totalRecords, 10) }))
      )
    }
    return Promise.resolve()
  })
}

const getQuerySortColumn = columnName => {
  switch (columnName) {
    case 'smartInventoryCategory':
      return 'category'
    case 'smartInventorySubcategory':
      return 'subcategory'
    case 'smartInventorySupplier':
      return 'supplierName'
    case 'smartInventoryStatus':
      return 'status'
    default:
      return 'itemDesc'
  }
}

/**
 * @description Fetch list of smart inventory for category and subcategory
 * @param {Array} filters
 * @returns List of inventory
 */
export const fetchSmartInventoryAggregated = (
  filters = INITIAL_STATE.apiRequestFilters,
  alertType = INITIAL_STATE.selectedAlertType.value
) => (dispatch, getState) => {
  const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
  const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
  const VENDOR_ID = getVendorIdFromState(getState)
  const IS_VENDOR = getState().UserInfo.isVendor
  const SELECTED_TAB = getState()?.SmartInventory?.smartInventoryTab
  const { searchQuery } = getState()?.SSHPaginatedTableReducer

  const requestedTab = SELECTED_TAB

  if (searchQuery && searchQuery.length > 0) {
    dispatch(resetSearch())
  }

  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_PAGINATED_TABLE_DATA_REQUEST,
        FETCH_PAGINATED_TABLE_DATA_SUCCESS,
        FETCH_PAGINATED_TABLE_DATA_FAILURE
      ],
      endpoint: `${String(process.env.REACT_APP_ALERTS_AGGREGATED) === 'v2' ? config.get('aggregatedSmartInvetoryData') : config.get('aggregatedSmartInvetoryData').replace('smartinventory', 'alerts')}`,
      method: 'POST',
      data: getAggregatedFiltersData(IS_VENDOR, VENDOR_ID, filters, alertType, requestedTab),
      payload: {
        selectedTab: SELECTED_TAB,
        pathName: getState()?.router?.location?.pathname,
        PAGE_SIZE,
        PAGE_NO
      }
    }
  }).then(({ type, response }) => {
    dispatch(aiSmartInventoryFetchAggregated({ type, response, filters, alertType }))
    if (response && type === FETCH_PAGINATED_TABLE_DATA_SUCCESS) {
      dispatch({
        type: SET_SMART_INVENTORY_AGGREGATED_ROWS,
        payload: {
          aggregatedRows: response?.payload?.aggregatedAlerts
        }
      })
      dispatch(setSmartInventoryRowCount({ count: response.payload.aggregatedAlerts.length }))
    }
    return Promise.resolve()
  })
}

/**
 * This function does client side search of Smart Inventory data of Categories and Subcategories tab
 * @param {String} searchQuery
 * @returns {function(...[*]=)}
 */
export const searchSmartInventory = ({ searchQuery, selectedTab }) => (dispatch, getState) => {
  dispatch({
    type: SEARCH_SMART_INVENTORY_ITEM,
    payload: { searchQuery }
  })
  const aggregatedRows = getState().SmartInventory.smartInventoryAggregatedRows
  let updatedAggregatedRows
  if (isEmpty(searchQuery)) {
    updatedAggregatedRows = aggregatedRows
  } else if (selectedTab === SMART_INVENTORY_TABS.CATEGORIES) {
    updatedAggregatedRows = aggregatedRows.filter(
      row => row.category?.toLowerCase()?.includes(searchQuery?.toLowerCase()) || row.categoryId?.includes(searchQuery)
    )
  } else if (selectedTab === SMART_INVENTORY_TABS.SUPPLIERS) {
    updatedAggregatedRows = aggregatedRows.filter(
      row =>
        row.supplierId?.includes(searchQuery) || row.supplierName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    )
  }
  dispatch(aiSmartInventoryAggregatedSearch({ type: 'ok', response: updatedAggregatedRows, searchQuery }))

  return Promise.resolve(
    dispatch({
      type: FETCH_PAGINATED_TABLE_DATA_SUCCESS,
      payload: {
        type: 'search',
        updatedAggregatedRows,
        pathName: getState().router.location.pathname,
        selectedTab
      }
    })
  ).then(({ type }) => {
    if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS) {
      dispatch(setSmartInventoryRowCount({ count: updatedAggregatedRows.length }))
    }
  })
}

/**
 * @description Sets the tab active according to the passed tabName
 * @param {String} tabName Selected tab name
 */
export const setSmartInventoryTabActive = ({ tabName }) => dispatch => {
  dispatch({
    type: SET_SMART_INVENTORY_TAB,
    payload: { tabName }
  })
}

/**
 * @description Fetch list of suppliers
 * @returns List of suppliers
 */
export const fetchSuppliers = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const IS_VENDOR = getState().UserInfo.isVendor
  const FILTER_URL_PATH = IS_VENDOR ? `/${VENDOR_ID}` : ''

  return dispatch({
    [CALL_API]: {
      types: [FETCH_SUPPLIERS_REQUEST, FETCH_SUPPLIERS_SUCCESS, FETCH_SUPPLIERS_FAILURE],
      endpoint: `${String(process.env.REACT_APP_ALERTS_CATEGORY) === 'v2' ? config.get('allSuppliers') : config.get('allSuppliers').replace('smartinventory', 'alerts')}${FILTER_URL_PATH}`,
      method: 'GET',
      payload: {
        pathName: getState().router.location.pathname
      }
    }
  }).then(({ type, response }) => {
    dispatch(aiSmartInventoryFetchSuppliers({ type, response }))
    if (type === FETCH_SUPPLIERS_SUCCESS) {
      return dispatch({
        type: FETCH_SUPPLIERS_SUCCESS,
        payload: {
          suppliers: IS_VENDOR ? [response.payload] : response.payload.suppliers
        }
      })
    }
    return Promise.resolve()
  })
}

/**
 * @description Fetch the graph data for past trends
 * @returns array of trends data
 */
export const fetchPastTrendsData = (selectedFilters, apiFilters, trendType = PAST_TRENDS_TYPES.OOS) => (
  dispatch,
  getState
) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const IS_VENDOR = getState().UserInfo.isVendor
  const { chartView, chartDuration, filters, smartInventoryTab } = getState().SmartInventory
  let types = [
    FETCH_PAST_TRENDS_OOS_DATA_REQUEST,
    FETCH_PAST_TRENDS_OOS_DATA_SUCCESS,
    FETCH_PAST_TRENDS_OOS_DATA_FAILURE
  ]
  if (trendType === PAST_TRENDS_TYPES.RL) {
    types = [FETCH_PAST_TRENDS_RL_DATA_REQUEST, FETCH_PAST_TRENDS_RL_DATA_SUCCESS, FETCH_PAST_TRENDS_RL_DATA_FAILURE]
  }

  if (selectedFilters && apiFilters) {
    dispatch({
      type: SET_SELECTED_FILTERS,
      payload: { selectedFilters, apiRequestFilters: apiFilters }
    })
  }

  return dispatch({
    [CALL_API]: {
      types,
      endpoint: config.get('pastTrends'),
      method: 'POST',
      data: setPastTrendsRequestBody({
        IS_VENDOR,
        VENDOR_ID,
        apiRequestFilters:
          apiFilters || prepareSmartInventoryApiRequest(filters, [], IS_VENDOR, VENDOR_ID, smartInventoryTab),
        trendType,
        chartDuration,
        chartView
      })
    }
  }).then(({ type, response }) => {
    dispatch(
      aiSmartInventoryPastTrendsAction({
        type,
        trendType,
        filters: apiFilters || prepareSmartInventoryApiRequest(filters, [], IS_VENDOR, VENDOR_ID, smartInventoryTab),
        response
      })
    )
    if (type === FETCH_PAST_TRENDS_RL_DATA_SUCCESS) {
      return dispatch({
        type: FETCH_PAST_TRENDS_RL_DATA_SUCCESS,
        payload: {
          trendData: response?.payload?.trendData[0]?.data
        }
      })
    }
    if (type === FETCH_PAST_TRENDS_OOS_DATA_SUCCESS) {
      return dispatch({
        type: FETCH_PAST_TRENDS_OOS_DATA_SUCCESS,
        payload: {
          trendData: response?.payload?.trendData[0]?.data
        }
      })
    }
    return Promise.resolve()
  })
}

/**
 * @description Fetch list of divisions
 * @returns List of divisions and details
 */
export const fetchDivisions = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const IS_VENDOR = getState().UserInfo.isVendor
  const FILTER_URL_PATH = IS_VENDOR ? `/suppliers/${VENDOR_ID}` : ''

  return dispatch({
    [CALL_API]: {
      types: [FETCH_DIVISIONS_REQUEST, FETCH_DIVISIONS_SUCCESS, FETCH_DIVISIONS_FAILURE],
      endpoint: `${String(process.env.REACT_APP_ALERTS_DIVISION) === 'v2' ? config.get('divisions') : config.get('divisions').replace('smartinventory', 'alerts')}${FILTER_URL_PATH}`,
      method: 'GET',
      payload: {
        pathName: getState().router.location.pathname
      }
    }
  }).then(({ response }) => {
    dispatch(aiSmartInventoryFetchDivisions({ type: response.type, response }))
    return Promise.resolve()
  })
}

/**
 * @description Apply filters selected by user
 * @param {Array} filters List of filters
 */
export const applyFilters = (selectedFilters, apiRequestFilters) => (dispatch, getState) => {
  const selectedTab = getState().SmartInventory.smartInventoryTab
  const selectedAlertType = getState().SmartInventory.alertType
  dispatch({
    type: UPDATE_ALERT_TYPE_CHIP,
    payload: selectedAlertType
  })
  return Promise.resolve(
    dispatch({
      type: SET_SELECTED_FILTERS,
      payload: { selectedFilters, apiRequestFilters }
    })
  )
    .then(() => {
      if (selectedTab === SMART_INVENTORY_TABS.ITEMS) {
        dispatch(fetchSmartInventory())
      } else if (
        [SMART_INVENTORY_TABS.CATEGORIES, SMART_INVENTORY_TABS.SUPPLIERS, SMART_INVENTORY_TABS.DIVISION].includes(
          selectedTab
        )
      ) {
        dispatch(fetchSmartInventoryAggregated(apiRequestFilters, selectedAlertType.value))
      }
    })
    .then(() => {
      dispatch(updateColumnVisibility())
    })
}

/**
 * @description Update filter selection
 * @param {Array} filters List of filters
 */
export const updateFilters = (
  formattedDivisions,
  formattedSuppliers,
  formattedCategories,
  formattedSubCategories,
  divisionToCategoryMap,
  divisionToSubCategoryMap,
  divisionToSupplierMap,
  categoryToSubCategoryMap,
  categoryToSupplierMap,
  subCategoryToSupplierMap,
  categoryToDivisionMap,
  allColumns
) => dispatch => {
  return Promise.resolve(
    dispatch({
      type: SET_FILTERS,
      payload: {
        formattedDivisions,
        formattedSuppliers,
        formattedCategories,
        formattedSubCategories,
        divisionToCategoryMap,
        divisionToSubCategoryMap,
        divisionToSupplierMap,
        categoryToSubCategoryMap,
        categoryToSupplierMap,
        subCategoryToSupplierMap,
        categoryToDivisionMap,
        allColumns
      }
    })
  )
}

/**
 * @description Clear all filters selected by user
 */
export const removeFilter = (filterName, value) => (dispatch, getState) => {
  const { filters } = getState().SmartInventory
  const selectedTab = getState().SmartInventory.smartInventoryTab
  const alertType = getState().SmartInventory.selectedAlertType
  const isSupplier = getState().UserInfo.isVendor
  const supplierId = isSupplier ? getVendorIdFromState(getState) : ''

  Promise.resolve(
    dispatch({
      type: REMOVE_FILTER,
      payload: { filterName, value }
    })
  ).then(async () => {
    await dispatch({
      type: UPDATE_API_FILTER
    })
    dispatch(aiSmartInventoryRemoveFilter({ type: 'ok', selectedTab }))
    if (selectedTab === SMART_INVENTORY_TABS.ITEMS) {
      dispatch(fetchSmartInventory())
    } else if (isAggregatedCall(selectedTab)) {
      const apiRequestFilters = []
      prepareSmartInventoryApiRequest(filters, apiRequestFilters, isSupplier, supplierId, selectedTab)
      dispatch(fetchSmartInventoryAggregated(apiRequestFilters, alertType.value))
    } else if (selectedTab === SMART_INVENTORY_TABS.PAST_TRENDS) {
      dispatch(fetchPastTrendsData(undefined, undefined, PAST_TRENDS_TYPES.RL))
      dispatch(fetchPastTrendsData(undefined, undefined, PAST_TRENDS_TYPES.OOS))
    }
  })
}

/**
 * @description Updates applied filters in api request format
 */
export const updateApiFilters = () => dispatch => {
  dispatch({
    type: UPDATE_API_FILTER
  })
}

/**
 * @description Reset all filters dropdown and clear all applied filters too
 */
export const resetFilters = (linkedFilter, isVendor) => (dispatch, getState) => {
  const selectedTab = getState().SmartInventory.smartInventoryTab
  const isSupplier = getState().UserInfo.isVendor
  const supplierId = isSupplier ? getVendorIdFromState(getState) : ''
  const pathname = getState().router.location.pathname

  dispatch({
    type: UPDATE_ALERT_TYPE,
    payload: OOS_STATUS[0]
  })
  dispatch({
    type: UPDATE_ALERT_TYPE_CHIP,
    payload: OOS_STATUS[0]
  })
  return Promise.resolve(
    dispatch({
      type: RESET_FILTER,
      payload: { linkedFilter, isVendor }
    })
  ).then(async () => {
    await dispatch(updateColumnVisibility())
    if (selectedTab === SMART_INVENTORY_TABS.ITEMS) {
      dispatch(fetchSmartInventory())
    } else if (isAggregatedCall(selectedTab)) {
      const { filters } = getState().SmartInventory
      const apiRequestFilters = []
      prepareSmartInventoryApiRequest(filters, apiRequestFilters, isSupplier, supplierId, selectedTab)
      if (pathname === ROUTE_MODULES?.prepurchase?.smartinventory?.path) {
        dispatch(fetchSmartInventoryAggregated(apiRequestFilters, 'All'))
      }
    } else if (selectedTab === SMART_INVENTORY_TABS.PAST_TRENDS) {
      dispatch(fetchPastTrendsData(undefined, undefined, PAST_TRENDS_TYPES.RL))
      dispatch(fetchPastTrendsData(undefined, undefined, PAST_TRENDS_TYPES.OOS))
    }
    dispatch(aiSmartInventoryReset({ type: RESET_FILTER }))
  })
}

/**
 * @description Reset all filters dropdowns, without API call
 */
export const resetDropdowns = () => (dispatch, getState) => {
  const { isVendor } = getState().UserInfo
  const resetDropdown = true

  return Promise.resolve(
    dispatch({
      type: RESET_FILTER,
      payload: { isVendor, resetDropdown }
    })
  )
}

/**
 * @description Show all filters popup selected by user
 */
export const openFiltersPopup = () => {
  return {
    type: SHOW_FILTERS_POPUP
  }
}

/**
 * @description Hide all filters popup selected by user
 */
export const closeFiltersPopup = () => {
  return {
    type: HIDE_FILTERS_POPUP
  }
}

/**
 * @description Show action confirmation popup in items tab
 */
export const openActionConfirmationPopup = ({ alertActionType, noOfDays, selectedReplenishmentDate, reason }) => {
  return {
    type: SHOW_ACTION_CONFIRMATION_POPUP,
    payload: {
      alertActionType,
      noOfDays,
      selectedReplenishmentDate,
      reason
    }
  }
}

export const setAlertAction = () => (dispatch, getState) => {
  const itemName = capitalizeSentence(getState().SmartInventory.selectedItemDetails?.itemName)
  const { selectedReplenishmentDate } = getState().SmartInventory
  const alertId = getState().SmartInventory.selectedItemDetails?.alertId
  const itemId = getState().SmartInventory.selectedItemDetails?.itemId
  const status = getState().SmartInventory.alertActionType
  const reason = getState().SmartInventory.selectedReason
  const suspendDays = getState().SmartInventory.noOfDays
  const vendorId = getState().UserInfo.isVendor ? getVendorIdFromState(getState) : ''

  let data = {
    alertId,
    status,
    reason,
    vendorId
  }

  if (status === ITEM_ACTION_TYPES.SUSPEND) {
    data = { ...data, suspendDays }
  }

  return dispatch({
    [CALL_API]: {
      types: [SET_ALERT_ACTION_REQUEST, SET_ALERT_ACTION_SUCCESS, SET_ALERT_ACTION_FAILURE],
      endpoint: `${String(process.env.REACT_APP_ALERTS) === 'v2' ? config.get('alertAction') : config.get('alertAction').replace('smartinventory', 'alerts')}`,
      method: 'POST',
      data
    }
  }).then(async ({ type, response }) => {
    dispatch(aiSmartInventoryTableAction({ type, action: status, response }))
    if (type === SET_ALERT_ACTION_SUCCESS) {
      const suspendMsg = selectedReplenishmentDate.toString().includes('week')
        ? `for ${selectedReplenishmentDate} from now.`
        : `until ${selectedReplenishmentDate}.`
      await Promise.resolve(dispatch(fetchSmartInventory()))

      dispatch({
        type: ERROR_OCCURRED,
        payload: {
          isAlertAction: true,
          isSuccess: true,
          message:
            status === ITEM_ACTION_TYPES.SUSPEND
              ? `An alert for ${itemName} (${itemId}) is paused ${suspendMsg}`
              : `An alert for ${itemName} (${itemId}) is ended now.`
        }
      })
    } else if (type === SET_ALERT_ACTION_FAILURE) {
      dispatch({
        type: ERROR_OCCURRED,
        payload: {
          isAlertAction: true,
          isFailure: true,
          message:
            status === ITEM_ACTION_TYPES.SUSPEND
              ? `An error occurred while pausing an alert for ${itemName} (${itemId}). Try again later`
              : `An error occurred while ending an alert for ${itemName} (${itemId}). Try again later`
        }
      })
    }
    return Promise.resolve()
  })
}

/**
 * @description Hide action confirmation popup in items tab
 */
export const closeActionConfirmationPopup = () => {
  return {
    type: HIDE_ACTION_CONFIRMATION_POPUP
  }
}

/**
 * @description Show suspend alert action popup in items tab
 */
export const openSuspendAlertPopup = () => {
  return {
    type: SHOW_SUSPEND_ALERT_POPUP
  }
}

/**
 * @description Hide suspend alert action popup in items tab
 */
export const closeSuspendAlertPopup = () => {
  return {
    type: HIDE_SUSPEND_ALERT_POPUP
  }
}

/**
 * @description Show terminate alert action popup in items tab
 */
export const openTerminateAlertPopup = () => {
  return {
    type: SHOW_TERMINATE_ALERT_POPUP
  }
}

/**
 * @description Hide terminate alert action popup in items tab
 */
export const closeTerminateAlertPopup = () => {
  return {
    type: HIDE_TERMINATE_ALERT_POPUP
  }
}

/**
 * @description Hide terminate alert action popup in items tab
 * @param itemId
 * @param itemName
 */
export const setSelectedItemDetails = (alertId, itemId, itemName) => {
  return {
    type: SET_ITEM_DETAILS,
    payload: {
      itemDetails: {
        alertId,
        itemId,
        itemName
      }
    }
  }
}

/**
 * @description Reset filters and tab
 */
export const resetSmartInventory = isVendor => dispatch => {
  dispatch({
    type: UPDATE_ALERT_TYPE,
    payload: OOS_STATUS[0]
  })
  dispatch({
    type: UPDATE_ALERT_TYPE_CHIP,
    payload: OOS_STATUS[0]
  })
  return Promise.resolve(
    dispatch({
      type: RESET_FILTER,
      payload: { linkedFilter: undefined, isVendor }
    })
  ).then(dispatch({ type: RESET_TAB }))
}

/**
 * @description Remove alertType
 */
export const removeAlertType = () => (dispatch, getState) => {
  const selectedTab = getState().SmartInventory.smartInventoryTab
  const { apiRequestFilters } = getState().SmartInventory
  return Promise.resolve(
    dispatch({
      type: UPDATE_ALERT_TYPE,
      payload: OOS_STATUS[0]
    })
  )
    .then(
      dispatch({
        type: UPDATE_ALERT_TYPE_CHIP,
        payload: OOS_STATUS[0]
      })
    )
    .then(() => {
      if (selectedTab === SMART_INVENTORY_TABS.ITEMS) {
        dispatch(fetchSmartInventory())
      } else if (isAggregatedCall(selectedTab)) {
        dispatch(fetchSmartInventoryAggregated(apiRequestFilters, OOS_STATUS[0].value))
      }
    })
}

/**
 * @description Remove alertType
 */
export const updateAlertType = alertType => (dispatch, getState) => {
  return Promise.resolve(
    dispatch({
      type: UPDATE_ALERT_TYPE,
      payload: alertType || getState().SmartInventory.alertType
    })
  )
}

/**
 * @description Update chart view
 */
export const updateChartView = view => dispatch => {
  return Promise.resolve(
    dispatch({
      type: UPDATE_CHART_VIEW,
      payload: view
    })
  )
}

/**
 * @description Update chart duration
 */
export const updateChartDuration = duration => dispatch => {
  return Promise.resolve(
    dispatch({
      type: UPDATE_CHART_DURATION,
      payload: duration
    })
  )
}

/**
 * @description Change selected alert type to update the chip
 */
export const updateAlertTypeChip = () => (dispatch, getState) => {
  return Promise.resolve(
    dispatch({
      type: UPDATE_ALERT_TYPE_CHIP,
      payload: getState().SmartInventory.alertType
    })
  )
}

/**
 * @description Set selected column's visibility
 */
export const updateColumnVisibility = columns => (dispatch, getState) => {
  const allColumns = cloneDeep(columns) || cloneDeep(getState().SmartInventory.filters.allColumns)
  const visibleColumns = allColumns?.filter(column => column.active === true)
  return Promise.resolve(
    dispatch({
      type: UPDATE_COLUMN_VISIBILITY,
      payload: visibleColumns || []
    })
  )
}

export const resetSearch = () => dispatch => {
  dispatch({ type: RESET_SMART_INVENTORY_ITEM_SEARCH })
}

/**
 * @description client side pagination
 * @returns {*}
 */
export const setClientPagination = () => dispatch => {
  dispatch({
    type: CLIENT_PAGINATION
  })
}

/**
 * @description client side sorting
 * @returns {*}
 */
export const OOSClientSorting = column => dispatch => {
  dispatch({
    type: CLIENT_SORTING,
    payload: {
      colName: column.columnName,
      colSortType: column.direction
    }
  })
}

/**
 * @description When filter button is clicked
 * @returns {*}
 */
export const setOpenFilter = isOpen => dispatch => {
  dispatch({
    type: SET_OPEN_FILTER,
    payload: isOpen
  })
}
