/* eslint-disable react/destructuring-assignment */
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Cancel, CheckCircle } from '@mui/icons-material'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@mui/styles'
import clsx from 'clsx'
import SSHLabel from '../../components/SSHLabel'
import { approveRejectLeadTimeRequest } from '../../pages/LeadTime/LeadTimeAction'
import { showToastMessage } from '../../reduxSetup/commonActions/AppConfig'
import { allowAccess } from '../../utils/common'
import { ROLE_BACK_ACTIONS, ROUTE_MODULES } from '../../config/genericConstants'
import SSHTooltip from '../../components/SSHTooltip'
import { approveRejectPenalty } from '../../pages/PenaltyProgram/PenaltyProgramActions'
import { toggleReviewBox } from '../../pages/Approvals/ApprovalsActions'
import { approveRejectShipnode } from '../../pages/SupplierManagement/SupplierOnBoardAction'
import { LEADTIME, PENALTY, SUPPLIER_MNGT } from '../../pages/Approvals/commonConstants'

const styles = theme => ({
  icon: {
    color: theme.palette.primary.progressGreen,
  },
  cursor: {
    cursor: 'pointer',
    width:'1.6em',
    height:'1.6em'
  }
})

const LTMAdminAction = ({
  isCompletedView,
  hasAccess,
  toggleReviewBox,
  approveRejectLeadTimeRequest,
  showToastMessage,
  approveRejectPenalty,
  approveRejectShipnode,
  selectedAdminTab,
  penaltyHistoryView,
  ...props
}) => {
  const [approving, setApproving] = React.useState(false)
  const [approved, setApproved] = React.useState(props.row.status === 'approved')
  const [rejected, setRejected] = React.useState(props.row.status === 'rejected')
  const onReject = () => toggleReviewBox({ task: { ...props.row } })

  useEffect(() => {
    setRejected(props.row.status === 'rejected')
    setApproved(props.row.status === 'approved')
  }, [props.row.status])

  const onApprove = () => {
    if (selectedAdminTab === PENALTY) {
      setApproving(true)
      approveRejectPenalty({
        task: props.row,
        isApproved: true
      })
        .then(() => setApproved(true))
        .catch(err => {
          handleError(err)
        })
    } else if (selectedAdminTab === LEADTIME) {
      approveRejectLeadTimeRequest({
        task: props.row,
        isApproved: true,
        reason: null
      })
        .then(() => setApproved(true))
        .catch(err => {
          handleError(err)
        })
    } else if (selectedAdminTab === SUPPLIER_MNGT) {
      approveRejectShipnode({
        task: props.row,
        isApproved: true,
        reason: null
      })
        .then(() => setApproved(true))
        .catch(err => {
          handleError(err)
        })
    }
  }

  const handleError = err => {
    setApproving(false)
    setApproved(false)
    showToastMessage({ message: err?.message })
  }

  return (
    <Table.Cell {...props} align="center">
      {allowAccess(ROLE_BACK_ACTIONS.UPDATE) && !approved && !rejected && props.isAdmin &&
        !isCompletedView && props.pathName === ROUTE_MODULES.toolsreporting?.approvals?.path && (
          <>
            <SSHTooltip title="Approve request">
              <CheckCircle
                color="progressGreen"
                fontSize="14"
                onClick={onApprove}
                className={clsx(props.classes.icon, props.classes.cursor)}
                loading={approving}
              />
            </SSHTooltip>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <SSHTooltip title="Reject request">
              <Cancel
                color="error"
                fontSize="14"
                className={props.classes.cursor}
                disabled={approving}
                onClick={onReject}
              />
            </SSHTooltip>
          </>
        )}
      {approved && <SSHLabel value="Approved" />}
      {rejected && <SSHLabel value="Rejected" />}
      {!approved && !rejected &&
        (isCompletedView || penaltyHistoryView === 'Resolved' || props.pathName === ROUTE_MODULES.fulfilment?.supppliermanagement?.path) && (
          <SSHLabel value={props.row.status} />
        )}
    </Table.Cell>
  )
}

LTMAdminAction.propTypes = {
  isCompletedView: PropTypes.bool.isRequired,
  hasAccess: PropTypes.bool.isRequired,
  approveRejectLeadTimeRequest: PropTypes.func.isRequired,
  toggleReviewBox: PropTypes.func.isRequired,
  showToastMessage: PropTypes.func.isRequired,
  selectedAdminTab: PropTypes.string.isRequired,
  approveRejectPenalty: PropTypes.func.isRequired,
  penaltyHistoryView: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired
}

export const LTMAdminActionCellNaked = LTMAdminAction

const mapStateToProps = state => ({
  isCompletedView: state.Approvals.isCompletedView,
  selectedAdminTab: state.Approvals.selectedAdminTab,
  penaltyHistoryView: state.PenaltyProgram.selectedHistoryTab,
  isAdmin: state.UserInfo.isAdmin,
  hasAccess: true,
  pathName: state.router.location.pathname
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { toggleReviewBox, showToastMessage, approveRejectLeadTimeRequest, approveRejectPenalty, approveRejectShipnode },
    dispatch
  )
export const LTMAdminActionCell = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LTMAdminAction))
