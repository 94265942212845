import { Table } from '@devexpress/dx-react-grid-material-ui'
import React from 'react'
import SSHLabel from '../../components/SSHLabel'

/**
 * @description Component to render custom table cell for smart inventory download status
 * @returns React element with the inventory status information
 */
const PenaltyTypeCell = ({ row, ...props }) => {
  return (
    <Table.Cell {...props} value={row.penaltyType}>
      <SSHLabel value={row.penaltyType} isPenalty />
    </Table.Cell>
  )
}

export default PenaltyTypeCell
