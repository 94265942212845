import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { cloneDeep } from 'lodash'
import { Paper } from '@mui/material'
import { withStyles } from '@mui/styles'
import SSHPaginatedTable from '../../../components/SSHPaginatedTable'
import SSHPaginatedTableLoading from '../../../components/SSHPaginatedTableLoading'
import { NO_CHANGE_LOG } from '../../../config/errorConstants'
import { column, columnExtensions, formattedColumns } from './column'
import { fetchChangelogTasks } from '../CapacityActions'
import { setCurrentPage } from '../../../reduxSetup/commonActions/SSHPaginatedTable'

const styles = theme => ({
    actionBtn: {
        color: theme.palette.primary.blue,
        marginTop: theme.spacing(1),
        fontSize: 14,
        fontWeight: 400,
        cursor: 'pointer'
    }
})

class ChangeLogTable extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            columns: cloneDeep(column),
            columnExtensions: cloneDeep(columnExtensions),
            formattedColumns: cloneDeep(formattedColumns)
        }
    }

    setCurrentPage = value => {
        const { setCurrentPage, fetchChangelogTasks } = this.props
        return setCurrentPage(value).then(fetchChangelogTasks())
    }

    render = () => {
        const { isFetching, rows, pageNo, totalRows } = this.props
        const { columns, columnExtensions, formattedColumns } = this.state

        return (
            <Paper square style={{ position: 'relative' }} className="pager">
                <SSHPaginatedTable
                    pageNo={pageNo}
                    pageSize={10}
                    rows={rows}
                    noDataMessage={NO_CHANGE_LOG}
                    columns={columns}
                    isFetching={isFetching}
                    formattedColumns={formattedColumns}
                    tableColumnExtensions={columnExtensions}
                    totalRows={totalRows}
                    setCurrentPage={this.setCurrentPage}
                />
                {(isFetching) && <SSHPaginatedTableLoading />}
            </Paper>
        )
    }
}

ChangeLogTable.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    pageNo: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalRows: PropTypes.number.isRequired,
}

export const ChangeLogTableNaked = ChangeLogTable

const mapStateToProps = state => ({
    isFetching: state.SSHPaginatedTableReducer.isFetching,
    rows: state.SSHPaginatedTableReducer.rows,
    pageNo: state.SSHPaginatedTableReducer.pageNo,
    pageSize: state.SSHPaginatedTableReducer.pageSize,
    totalRows: state.FCapacity.totalChangelogCount
})

const mapDispatchToProps = dispatch =>
    bindActionCreators({ setCurrentPage, fetchChangelogTasks }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChangeLogTable))
