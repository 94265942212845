import React from 'react'
import { ListItem, ListItemText } from '@mui/material'
import { withStyles } from '@mui/styles'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { COLORS } from '../../templates/Entry/globalMUIStyles'
import clsx from 'clsx'
import { disabledLink, getSourceOptions } from './commonMethods'


const styles = (theme) => ({
    root: {
        background: theme.palette.primary.cobalt,
        color: COLORS.white,
        '& .MuiListItemText-root': {
            paddingLeft: theme.spacing(4),
        }
    },
    active: {
        background: theme.palette.primary.prusianBlue
    },
    hover: {
        '&:hover': {
            background: theme.palette.primary.prusianBlue
        },
    }
})


const NavItem = ({ nav, pathName, handleSubMenu, isAdmin, classes, ...restProps }) => {
    if (isEmpty(nav)) {
        return null
    }

    const sourceOptions = getSourceOptions(restProps.selectedSourceType)

    const renderSubmenu = () => {
        return nav.map(item => {
            if (!isAdmin && item.onlyAdmin !== isAdmin) {//render admin modules when IAM login
                return null
            }
            if (item.sourceType === 'both' || sourceOptions === item.sourceType) {
                return (<ListItem button
                    disabled={disabledLink(item,
                        isAdmin,
                        restProps.access)}
                    onClick={() => handleSubMenu(item.url)}
                    className={clsx(classes.root,
                        classes.hover, pathName === item.url ?
                        classes.active :
                        '')}>
                    <ListItemText
                        primary={item.name}
                        data-testid="itemText"
                    />
                </ListItem>)
            }
        })
    }

    return (
        <>
            {
                renderSubmenu()
            }
        </>
    )
}


NavItem.propTypes = {
    item: PropTypes.object.isRequired,
    access: PropTypes.object.isRequired,
    fetchingRoles: PropTypes.bool.isRequired,
    changePage: PropTypes.func.isRequired,
    isVendorSelected: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    pathName: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
    pathName: state.router.location.pathname,
    selectedSourceType: state.AppConfig.sourceType
})

export default connect(mapStateToProps, null)(withStyles(styles)(React.memo(NavItem)))
export const NavItemNaked = NavItem
