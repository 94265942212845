import React from 'react'
import PropTypes from 'prop-types'
import Popover from '@mui/material/Popover'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPopover-paper': {
      boxShadow: '0 4px 8px 0 rgba(0, 39, 81, 0.15)',
      border: 'none',
      borderRadius: '4px'
    }
  }
}))

export default function SSHPopover({ anchorEl, isOpen, handleClose, children, anchorOrigin, transformOrigin,...restProps }) {
  const classes = useStyles()
  return (
    <Popover
      open={isOpen}
      className={classes.root}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}>
      {children}
    </Popover>
  )
}

SSHPopover.propTypes = {
  anchorEl: PropTypes.element.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  anchorOrigin: PropTypes.object.isRequired,
  transformOrigin: PropTypes.object.isRequired
}
