/* eslint-disable react/no-unused-prop-types */
import { Box, Grid, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import React from 'react'
import PropTypes from 'prop-types'
import SSHTooltip from '../../../../components/SSHTooltip'
import { SSHTypography } from '../../../../components/SSHTypography'

const styles = theme => ({
  greyBox: { backgroundColor: theme.palette.grey[200] }
})

const ItemCancellationDetails = ({ classes, name, currentfulfilmentQty, cancelledUnits }) => (
  <Box p={2} className={classes.greyBox}>
    <Grid container spacing={2} justifyContent="space-between">
      <Grid item xs={8}>
        <Typography variant="body2">
          <b>Item Description</b>
        </Typography>
        <SSHTooltip title={name}>
          <SSHTypography variant="body2">{name}</SSHTypography>
        </SSHTooltip>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" align="right">
          <b>Units to Cancel</b>
        </Typography>
        <Typography variant="body2" align="right">
          {cancelledUnits} / {currentfulfilmentQty}
        </Typography>
      </Grid>
    </Grid>
  </Box>
)

ItemCancellationDetails.propTypes = {
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
}

export const ItemCancellationDetailsNaked = ItemCancellationDetails

export default withStyles(styles)(ItemCancellationDetails)
