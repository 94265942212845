import { cloneDeep } from 'lodash'
import moment from 'moment'
import {
  ORDER_CARRIER_TRACKING_REQUEST,
  ORDER_CARRIER_TRACKING_SUCCESS,
  ORDER_CARRIER_TRACKING_FAILURE,
  SET_RETURN_TAB,
  SET_PENDING_ITEM_COUNT,
  SET_ACKNOWLEDGE_ITEM_COUNT,
  SET_SEARCH_QUERY,
  SET_PENDING_ORDERS,
  SET_ACKNOWLEDGE_ORDERS,
  CLEAR_SEARCH_PARAMS,
  SET_FILTER_OPEN
} from './ReturnOrder.types'
import { RETURNS_TABS } from './ReturnOrderTabSwitcher'
import { validateDateInMonths } from '../../utils/common'

/** Do not change the sequence of selected and api filters anywhere */
export const INITIAL_STATE = {
  isUpdating: false,
  success: '',
  failure: '',
  pendingReturnsCount: 0,
  acknowledgeReturnsCount: 0,
  searchQuery: { searchKey: '', searchValue: '' },
  selectedReturnTab: RETURNS_TABS.PENDING,
  pendingOrders: [],
  acknowledgeOrders: [],
  orderDateRange: {
    from: null,
    to: null
  },
  inValidOrderDate: false,
  orderDate: false,
  search: true,
  isFilterOpen: true
}

/**
 * @description get order date selection
 * @param {string} newSearchState
 * */
const getOrderDate = newSearchState => {
  newSearchState.orderDate = false
  if (
    (newSearchState.orderDateRange.from !== null && newSearchState.orderDateRange.to === null) ||
    (newSearchState.orderDateRange.to !== null && newSearchState.orderDateRange.from === null)
  ) {
    newSearchState.orderDate = true
  }

  if (newSearchState.orderDateRange.from != null && newSearchState.orderDateRange.to != null) {
    newSearchState.inValidOrderDate = !validateDateInMonths(
      newSearchState.orderDateRange.from,
      newSearchState.orderDateRange.to
    )
  }
}

/**
 * @description set search key and value and format date
 * @param (action, state)
 * @return {state}
 * */
const setSearchKeyValue = (action, state) => {
  const { searchKey, searchValue } = action.payload.searchQuery
  const newSearchState = cloneDeep(state)
  const dateFirstFormat = 'MM/DD/YYYY'

  if (searchKey && searchKey.includes('Date')) {
    const dates = searchKey.split('-')
    /* eslint-disable */
    newSearchState[dates[0]][dates[1]] = moment(searchValue._d).format(dateFirstFormat)
    /* eslint-enable */
    getOrderDate(newSearchState)
    const isValid = (newSearchState.orderDateRange.from != null && newSearchState.orderDateRange.to != null) ? false : true
    /* eslint-disable */
    newSearchState.search = isValid || newSearchState.inValidOrderDate // allow search click
  } else {
    newSearchState.searchQuery = { searchKey, searchValue }
  }
  /* eslint-enable */
  return newSearchState
}

/**
 * @description Reset Search params
 * @param (action, state)
 * @return {state}
 * */
const resetSearch = (state) => {
  const newSearchState = cloneDeep(state)
  newSearchState.orderDateRange.from = null
  newSearchState.orderDateRange.to = null
  newSearchState.search = true
  return newSearchState
}

export default (state = cloneDeep(INITIAL_STATE), action = {}) => {
  switch (action.type) {
    case ORDER_CARRIER_TRACKING_REQUEST: {
      return {
        ...state,
        isUpdating: true
      }
    }

    case ORDER_CARRIER_TRACKING_SUCCESS:
    case ORDER_CARRIER_TRACKING_FAILURE: {
      return {
        ...state,
        isUpdating: false
      }
    }

    case SET_PENDING_ITEM_COUNT: {
      return {
        ...state,
        pendingReturnsCount: action.payload.count
      }
    }

    case SET_ACKNOWLEDGE_ITEM_COUNT: {
      return {
        ...state,
        acknowledgeReturnsCount: action.payload.count
      }
    }

    case SET_RETURN_TAB: {
      const { tabName } = action.payload
      return {
        ...state,
        selectedReturnTab: tabName
      }
    }

    case SET_SEARCH_QUERY: {
      const newSearchState = setSearchKeyValue(action, state)
      return {
        ...newSearchState
      }
    }

    case SET_PENDING_ORDERS: {
      const { orders } = action.payload
      return {
        ...state,
        pendingOrders: orders
      }
    }

    case SET_ACKNOWLEDGE_ORDERS: {
      const { orders } = action.payload
      return {
        ...state,
        acknowledgeOrders: orders
      }
    }

    case CLEAR_SEARCH_PARAMS: {
      const resetSearchState = resetSearch(state)
      return {
        ...resetSearchState
      }
    }

    case SET_FILTER_OPEN: {
      const { filterOpen } = action.payload
      return {
        ...state,
        isFilterOpen: filterOpen
      }
    }

    default:
      return { ...state }
  }
}
