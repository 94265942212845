/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { push } from 'connected-react-router'
import { capitalize, debounce, differenceWith, find, get, includes, isEmpty, isEqual } from 'lodash'
import moment from 'moment'
import config from '../../config'
import { APP_CONFIG } from '../../config/appConfigs'
import { DOWNLOAD_REPORT_INITIATED, FULFILLMENT_DOWNLOAD_FILE_FAILED } from '../../config/errorConstants'
import { RESET_FEED_ITEM_SEARCH } from '../../pages/Feed/FeedStatus.types'
import { fetchFeedItems } from '../../pages/Feed/FeedStatusAction'
import {
  fetchCarrierAccount,
  fetchCarrierMethods,
  fetchFulfillmentData,
  resetFulfillmentData,
  resetSearch
} from '../../pages/FulfillmentNew/FulfillmentAction'
import { fetchOrderDetails, setOrderList } from '../../pages/OrderPage/OrderDetailsActions'
import { fetchPerformanceSummary } from '../../pages/Performance/PerformanceAnalyticsAction'
import { closeAlert } from '../../templates/Error/ErrorHandlerActions'
import { fetchNetworkLevelFlag, clearMantisShipNode } from '../../pages/MantisLeadTime/MantisLeadTimeAction'
import { getUserInfoFromCookie, getUserInformation, skipRetailLink } from '../../templates/UserInfo/UserInfoAction'
import { resetDownloadSearch, downloadReportRequest } from '../../pages/DownloadHistory/DownloadHistoryAction'
import { resetSmartInventory } from '../../pages/SmartInventory/SmartInventoryAction'
import {
  downloadFile,
  getAuthorizationCode,
  getDataFromURL,
  getOrderSearchParameter,
  getOrderTypeFromName,
  getRouteNameFromURL,
  getShipNodeIdFromState,
  getVendorIdFromState,
  getVisibleModuleName,
  isProdEnvironment,
  redirectToLogin
} from '../../utils/common'
import { getCookie } from '../../utils/cookies'
import { CALL_API } from '../middleware/API'
import {
  BULK_UPLOAD_FILES_FAILURE,
  BULK_UPLOAD_FILES_REQUEST,
  BULK_UPLOAD_FILES_SUCCESS,
  CHECK_EXPORT_STATUS_FAILURE,
  CHECK_EXPORT_STATUS_REQUEST,
  CHECK_EXPORT_STATUS_SUCCESS,
  CLEAR_SHIP_NODE,
  CLEAR_VENDOR_SEARCH,
  CLOSE_LEFT_DRAWER,
  FETCH_SHIP_NODES_FAILURE,
  FETCH_SHIP_NODES_REQUEST,
  FETCH_SHIP_NODES_SUCCESS,
  FETCH_VENDOR_ROLES_FAILURE,
  FETCH_VENDOR_ROLES_REQUEST,
  FETCH_VENDOR_ROLES_SUCCESS,
  GET_EXPORT_FILE_FAILURE,
  GET_EXPORT_FILE_REQUEST,
  GET_EXPORT_FILE_SUCCESS,
  HIDE_BULK_UPDATE_POPUP,
  HIDE_EXPORT_POPUP,
  HIDE_FULFILLMENT_TABS,
  HIDE_TOAST_MESSAGE,
  INITIATE_EXPORT_FAILURE,
  INITIATE_EXPORT_REQUEST,
  INITIATE_EXPORT_SUCCESS,
  OPEN_LEFT_DRAWER,
  RETRY_EXPORT_FILE_FAILURE,
  RETRY_EXPORT_FILE_REQUEST,
  RETRY_EXPORT_FILE_SUCCESS,
  SEARCH_VENDOR_FAILURE,
  SEARCH_VENDOR_REQUEST,
  SEARCH_VENDOR_SUCCESS,
  SELECT_SHIP_NODE,
  SELECT_VENDOR,
  SET_MODULE_TYPE,
  SET_PARTNER_LIST,
  SHOW_BULK_UPDATE_POPUP,
  SHOW_EXPORT_POPUP,
  SHOW_TOAST_MESSAGE,
  SKIP_SUPPLIER_SELECTION,
  TOGGLE_ATMT_FEEDBACK,
  TOGGLE_SOURCE_TYPE,
  GET_SOURCE_TYPE,
  SET_SOURCE_TYPE
} from './AppConfig.actionTypes'
import { resetTable } from './SSHPaginatedTable'
import {
  aiBulkExportSingleLineFailed,
  aiBulkExportSingleLineRetry,
  aiBulkUpdateRequest,
  aiChangePage,
  aiSelectFulfillmentCenter,
  aiSelectVendor,
  aiTrackException
} from './TelemetryActions'
import { aiDownloadReports, aiDownloadReportRetry } from './TelemetryActionsNew'
import {
  ADD_DOWNLOAD_REPORT,
  DISABLE_DOWNLOAD,
  ENABLE_DOWNLOAD,
  UPDATE_DOWNLOAD_REPORT_STATUS
} from '../../templates/NotificationDrawer/NotificationDrawer.actionTypes'
import {
  API_MODULE_TYPES,
  CHECK_STATUS_INTERVAL,
  FILTER_KEYS,
  MODULE_TYPES,
  ROUTE_MODULES,
  VISIBLE_MODULE_TYPES,
  CLUB_MODULE_TYPES,
  dateTimeFormat
} from '../../config/genericConstants'
import { SMART_INVENTORY_TABS } from '../../pages/SmartInventory/SmartInventoryTabSwitcher'
import { ERROR_OCCURRED } from '../../templates/Error/ErrorHandler.actionTypes'
import { fetchDashboard } from '../../pages/Dashboard/Dashboard.Action'
import { fetchReturnOrderItemsFromPath } from '../../pages/ReturnOrder/ReturnOrderAction'
import { PENALTY, ACCOUNTS_RECEIVABLE } from '../../pages/Approvals/commonConstants'
import { TOGGLE_SOURCE_ACCESS } from '../../templates/UserInfo/UserInfo.actionTypes'
import { fetchInTransitItemsFromPath } from '../../pages/InTransitInventory/InTransitInventoryAction'
import { fetchForecastItems, fetchForecastClubs } from '../../pages/Club/SaleForecast/ForecastAction'
import { fetchOrderDemandItems, fetchOrderDemandClubs } from '../../pages/Club/OrderDemand/OrderDemandAction'
import { fetchOutofStockItems } from '../../pages/Club/OutofStock/ClubOutofStockAction'
import { getPenaltyHistory } from '../../pages/PenaltyProgram/PenaltyProgramActions'
import { getShipNodes } from '../../pages/Capacity/CapacityActions'
import { getARMonthwiseParam } from '../../pages/AccountReceivable/AccountReceivableActions'

export const openLeftDrawer = () => {
  return {
    type: OPEN_LEFT_DRAWER
  }
}

export const closeLeftDrawer = () => {
  return {
    type: CLOSE_LEFT_DRAWER
  }
}

/**
 * @param {String} pathName
 * @param {String} selectedVendorName
 */
export const updatePageTitle = () => (dispatch, getState) => {
  const PATH_NAME = getState().router.location.pathname
  const VENDOR_NAME = getState().AppConfig.vendor.selectedVendor.displayName

  const newPageTitle = getRouteNameFromURL({ pathName: PATH_NAME })
  const titleArray = []
  if (newPageTitle) titleArray.push(newPageTitle)
  if (VENDOR_NAME) titleArray.push(VENDOR_NAME)

  const title = `${titleArray.join(' | ')} | Sam's Supplier Hub`
  document.title = title
}

/**
 * if URL has Functional Test flag
 *  skipLogin()
 *  return
 *
 * if cookie.exists?
 *    Hydrate userInfo from cookie into redux store
 * else if URL.contains(code)?
 *    get userInfo from backend
 * else
 *    redirectTo RetailLink
 *
 * Once Redux store has userInfo, it will automatically redirect to Landing page
 *
 * @returns {function(...[*]=)}
 */

export const checkIfLoggedIn = () => (dispatch, getState) => {
  const isFunctionalTest = get(getState(), 'router.location.query.isTest', false)
  const isFunctionalVendorTest = get(getState(), 'router.location.query.isVendorTest', false)

  if ((isFunctionalTest || isFunctionalVendorTest) && !isProdEnvironment()) {
    const customerToken = get(getState(), 'router.location.query.token', null)
    const iamToken = get(getState(), 'router.location.query.iamtoken', null)
    const permission = get(getState(), 'router.location.query.permission', null)
    return dispatch(skipRetailLink({ customerToken, iamToken, permission, isVendor: isFunctionalVendorTest }))
  }

  const COOKIE = getCookie(APP_CONFIG.COOKIE_NAME)
  const AUTHORIZATION_CODE = getAuthorizationCode()
  if (COOKIE) return dispatch(getUserInfoFromCookie())
  if (AUTHORIZATION_CODE) return dispatch(getUserInformation(AUTHORIZATION_CODE))
  return redirectToLogin()
}

export const changePage = url => (dispatch, getState) => {
  /* do something before redirection */
  const OLD_PAGE = getState().router.location.pathname
  const { isVendor } = getState().UserInfo

  if (OLD_PAGE === url) return Promise.resolve()

  dispatch(closeAlert())

  dispatch({
    type: RESET_FEED_ITEM_SEARCH
  })

  if (url.search(/fulfillment/i) === -1) {
    // check search order details page
    dispatch(resetSearch())
  }

  if (url.search(/download-history/i) === -1) {
    dispatch(resetDownloadSearch())
  }

  if (url.search(/smart-inventory/i) === -1) {
    dispatch(resetSmartInventory(isVendor))
  }

  dispatch(aiChangePage({ oldPage: OLD_PAGE, newPage: url }))
  return Promise.resolve(dispatch(push(url)))
    .then(dispatch(resetTable()))
    .then(dispatch(fetchDataBasedOnPage()))
    .then(dispatch(setVisibleModule()))
}

export const setPartnerList = () => (dispatch, getState) => {
  const PARTNERS = [...getState().UserInfo.partnerList].map(partner => ({
    ...partner,
    name: partner.displayName,
    value: partner.vendorId
  }))

  const PREFERRED_PARTNER = find(PARTNERS, { preferred: true }) || {}
  dispatch({
    type: SET_PARTNER_LIST,
    payload: { partnerList: PARTNERS }
  })

  if (!isEmpty(PREFERRED_PARTNER)) return dispatch(selectVendor(PREFERRED_PARTNER))
  return dispatch(selectVendor(PARTNERS[0]))
}

export const searchVendor = query => dispatch => {
  if (!query || query.length <= 2) return dispatch({ type: SEARCH_VENDOR_FAILURE })

  return Promise.resolve(
    dispatch({
      [CALL_API]: {
        types: [SEARCH_VENDOR_REQUEST, SEARCH_VENDOR_SUCCESS, SEARCH_VENDOR_FAILURE],
        payload: { query },
        endpoint: `${config.get('searchVendor')}${query}`,
        method: 'GET'
      }
    })
  )
}

export const clearSearchList = () => dispatch => {
  return dispatch({
    type: CLEAR_VENDOR_SEARCH
  })
}

export const selectVendor = vendor => (dispatch, getState) => {
  if (!vendor || !vendor.vendorId) return Promise.resolve()

  dispatch({
    type: SELECT_VENDOR,
    payload: { vendor, clearList: getState().UserInfo.isAdmin }
  })

  dispatch(updatePageTitle())
  dispatch(clearShipNode())
  dispatch(clearMantisShipNode())
  dispatch(resetFulfillmentData())
  return dispatch(getVendorRoles())
}

/**
 * @description Show or Hide the past due and due today tabs in Fulfillment screen based on the role API response
 * @param {Array} appUserRoleInfo
 * @return {function(*): *}
 */
export const showHideVendorRoles = ({ vendorRoleInfo }) => dispatch => {
  return dispatch({
    type: HIDE_FULFILLMENT_TABS,
    payload: { vendorRoleInfo }
  })
}

/**
* @description get the source types - DSV and/or CLUB from API
* @param {Array} sourceType
* @return {function(*): *}
*/
export const getSourceType = ({ sourceType }) => dispatch => {
  return dispatch({
    type: GET_SOURCE_TYPE,
    payload: { sourceType }
  })
}

export const getVendorRoles = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)

  return dispatch({
    [CALL_API]: {
      types: [FETCH_VENDOR_ROLES_REQUEST, FETCH_VENDOR_ROLES_SUCCESS, FETCH_VENDOR_ROLES_FAILURE],
      endpoint: `${config.get('userFeatures')}/${VENDOR_ID}/roles`,
      method: 'GET'
    }
  })
    .then(response => {
      if (!!response && response.type === FETCH_VENDOR_ROLES_SUCCESS) {
        const responsePayload = get(response, 'response.payload', [])
        dispatch(aiSelectVendor())
        dispatch(showHideVendorRoles({ vendorRoleInfo: responsePayload }))
        dispatch(getSourceType({ sourceType: responsePayload }))
        dispatch(setRolesOnTypeChange({ vendorRoleInfo: responsePayload }))
        return dispatch(fetchDataBasedOnPage())
      }

      return Promise.resolve()
    })
    .catch(() => { })
}

/**
 * @description Use this action to enable or disable the user modules for supplier based on sourcetype DSV/CLUB selected
 * @param {Array} vendorRoleInfo
 * @returns {function(...[*]=)}
 */
export const setRolesOnTypeChange = ({ vendorRoleInfo }) => (dispatch, getState) => {
  const selected = getState().AppConfig.sourceType.selected
  const type = getState().AppConfig.sourceType.type
  dispatch({
    type: TOGGLE_SOURCE_ACCESS,
    payload: { vendorRoleInfo, selected, type }
  })
}

/**
 * Use this action to get data from API for any page
 * @returns {function(...[*]=)}
 */
export const fetchDataBasedOnPage = () => (dispatch, getState) => {
  const CURRENT_PAGE = getState().router.location.pathname
  const ACCESS_LEVELS = getState().UserInfo.access
  const { type } = getState().router.location.query

  if (ACCESS_LEVELS[CURRENT_PAGE.slice(1)] === false) return Promise.resolve()

  switch (CURRENT_PAGE) {
    case ROUTE_MODULES?.performance?.home?.path:
      return dispatch(fetchDashboard())

    case ROUTE_MODULES?.fulfilment?.orderfulfilment?.path:
      return dispatch(fetchFulfillmentData())

    case ROUTE_MODULES?.toolsreporting?.uploadrequest?.path:
    case ROUTE_MODULES?.toolsreporting?.adminuploadrequest?.path:
      return dispatch(fetchFeedItems())

    case ROUTE_MODULES?.performance?.weekmetrics?.path:
      return dispatch(fetchPerformanceSummary())

    case ROUTE_MODULES?.toolsreporting?.approvals?.path: {
      isEmpty(type) && dispatch(getPenaltyHistory())
      return
    }

    case ROUTE_MODULES?.toolsreporting?.leadtimemanagement?.path:
      return dispatch(fetchNetworkLevelFlag())

    case ROUTE_MODULES?.prepurchase?.inventory?.path:
    case ROUTE_MODULES?.postpurchase?.returnprocessing?.path:
    case ROUTE_MODULES?.prepurchase?.intransitinventory?.path:
      return dispatch(checkShipnodesLoaded())

    case CURRENT_PAGE.match(/^\/fulfillment\/vendor\//) ? CURRENT_PAGE : undefined:
      return dispatch(fetchOrderDetails())

    case ROUTE_MODULES?.forecasting?.clubsaleforecast?.path:
      return dispatch(fetchForecastItems())

    case ROUTE_MODULES?.forecasting?.cluborderdemand?.path:
      return dispatch(fetchOrderDemandItems())

    case ROUTE_MODULES?.inventorypricing?.outofstock?.path:
      return dispatch(fetchOutofStockItems())

    case CURRENT_PAGE.match(/^\/club\/sales-forecast\/vendor\/\d*\/item\/\d*/) ? CURRENT_PAGE : undefined:
      return dispatch(fetchForecastClubs())

    case CURRENT_PAGE.match(/^\/club\/order-demand\/vendor\/\d*\/item\/\d*/) ? CURRENT_PAGE : undefined:
      return dispatch(fetchOrderDemandClubs())

    default:
      return {
        type: 'NULL'
      }
  }
}

export const checkShipnodesLoaded = () => (dispatch, getState) => {
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId
  if (!SHIP_NODE_ID) {
    return dispatch(fetchShipNodes())
  }
  return SHIP_NODE_ID
}

export const fetchShipNodes = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)

  if (!VENDOR_ID) return Promise.resolve()

  return dispatch({
    [CALL_API]: {
      types: [FETCH_SHIP_NODES_REQUEST, FETCH_SHIP_NODES_SUCCESS, FETCH_SHIP_NODES_FAILURE],
      endpoint: `${String(process.env.REACT_APP_SHIPNODE_API_VERSION) === 'v2' ? config.get('shipNodes') : config.get('shipNodes').replace('v2', 'v1')}/${VENDOR_ID}/shipnodes`,
      method: 'GET'
    }
  })
}

export const skipSupplierSelection = () => (dispatch, getState) => {
  return dispatch({
    type: SKIP_SUPPLIER_SELECTION,
    payload: true
  })
}

/**
 *
 * @param {String} value
 * @param {String} name
 * @returns {function(*, *): *}
 */
export const selectShipNode = ({ value, name }) => dispatch => {
  dispatch({
    type: SELECT_SHIP_NODE,
    payload: {
      shipNode: {
        shipNodeId: value,
        shipNodeName: name
      }
    }
  })

  dispatch(aiSelectFulfillmentCenter())
  return dispatch(fetchDataBasedOnPage())
}

export const clearShipNode = () => dispatch => {
  return Promise.resolve(
    dispatch({
      type: CLEAR_SHIP_NODE
    })
  )
}

/**
 *
 * @param {String} message
 * @returns {function(*): *}
 */
export const showToastMessage = ({ message }) => dispatch => {
  return dispatch({
    type: SHOW_TOAST_MESSAGE,
    payload: { message }
  })
}

export const hideToastMessage = () => dispatch => {
  return dispatch({
    type: HIDE_TOAST_MESSAGE
  })
}


/**
 * @description bulk upload files excel
 * @param {*} param0 
 * @returns 
 */
export const bulkUploadFiles = ({ files, isFulfillment, isSmartInventory, isPenalty, isPenaltyApproval, isArApproval, isInTransitInventory, ...restProps }) => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState) || '0000'; //send vendorId as 0000 in api for requests made by admin if supplier not selection
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId;
  const { isVendor } = getState().UserInfo;

  const formData = new FormData();
  const URL_PATH = determineUrlPath({ isVendor, isSmartInventory, isPenalty, isPenaltyApproval, isArApproval, isInTransitInventory, restProps, VENDOR_ID, SHIP_NODE_ID, getState, isFulfillment });

  files.forEach(file => {
    formData.append('file', file, file.name);
  });

  aiBulkUpdateRequest({ fileCount: files.length });
  return dispatch({
    [CALL_API]: {
      types: [BULK_UPLOAD_FILES_REQUEST, BULK_UPLOAD_FILES_SUCCESS, BULK_UPLOAD_FILES_FAILURE],
      endpoint: `${config.get('uploadFiles')}${URL_PATH}`,
      method: 'POST',
      data: formData,
      isUpload: true
    }
  });
};


/**
 * @description determine url path return path for upload excel
 * @param {*} param0 
 * @returns 
 */
const determineUrlPath = ({
  isVendor,
  isSmartInventory,
  isPenalty,
  isPenaltyApproval,
  isArApproval,
  isInTransitInventory,
  restProps,
  VENDOR_ID,
  SHIP_NODE_ID,
  getState,
  isFulfillment
}) => {
  const { AppConfig } = getState();
  const inventoryType = (AppConfig.sourceType?.selected === 1) ? 'INVENTORY' : CLUB_MODULE_TYPES.inventory;

  if (isSmartInventory) {
    return isVendor ? `/${VENDOR_ID}/shipnode/${VENDOR_ID}/feed/ALERTS/upload` : `/0/shipnode/0/feed/ALERTS/upload`;
  } else if (isPenalty) {
    return `/${VENDOR_ID}/shipnode/0/feed/PENALTY/upload`;
  } else if (isPenaltyApproval) {
    return `/0/shipnode/0/feed/PENALTY_APPROVAL/upload`;
  } else if (isArApproval) {
    return `/0/shipnode/0/feed/AR/upload`;
  } else if (isInTransitInventory) {
    return `/${VENDOR_ID}/shipnode/${SHIP_NODE_ID}/feed/IN_TRANSIT_INVENTORY/upload`;
  } else if (restProps?.isClubSaleForecast) {
    return isVendor ? `/${VENDOR_ID}/shipnode/0/feed/CLUB_FORECAST/upload` : `/0/shipnode/0/feed/CLUB_FORECAST/upload`;
  } else if (restProps?.isDailyCapacity) {
    return `/${VENDOR_ID}/shipnode/0/feed/FCAP/upload`
  } else {
    return `/${VENDOR_ID}/shipnode/${SHIP_NODE_ID}/feed/${isFulfillment ? 'ORDER' : inventoryType}/upload`;
  }
};

export const resetBulkUploadState = () => dispatch => {
  return dispatch({
    type: BULK_UPLOAD_FILES_FAILURE
  })
}

/**
 *
 * @param {Boolean} isFulfillment
 * @param {String} shipNodeId
 * @returns {function(*): *}
 */
export const showBulkUpdatePopup = ({ isFulfillment, shipNodeId, isSmartInventory, isPenalty, isPenaltyApproval, isInTransitInventory, isClubSaleForecast, ...restProps }) => dispatch => {
  return Promise.resolve(
    dispatch({
      type: SHOW_BULK_UPDATE_POPUP,
      payload: {
        isFulfillment: !!isFulfillment,
        isSmartInventory,
        isPenalty,
        isPenaltyApproval,
        isArApproval: restProps.isArApproval,
        isInTransitInventory,
        isClubSaleForecast,
        isDailyCapacity: restProps.isDailyCapacity
      }
    })
  ).then(() => {
    if (shipNodeId !== undefined) return dispatch(selectShipNode({ value: shipNodeId, name: shipNodeId }))
    return Promise.resolve()
  })
}

export const hideBulkUpdatePopup = () => (dispatch, getState) => {
  if (getState().router.location.pathname === '/feed') dispatch(clearShipNode())

  dispatch(resetBulkUploadState())

  return Promise.resolve(
    dispatch({
      type: HIDE_BULK_UPDATE_POPUP
    })
  )
}

export const showExportPopup = () => (dispatch, getState) => {
  const moduleType = getRouteNameFromURL({ pathName: getState().router.location.pathname })
  return Promise.resolve(
    dispatch({
      type: SHOW_EXPORT_POPUP,
      payload: { moduleType }
    })
  )
}

export const hideExportPopup = () => dispatch => {
  return Promise.resolve(
    dispatch({
      type: HIDE_EXPORT_POPUP
    })
  )
}

const exportRequestParamsFulfillment = (requestParam, getState) => {
  const ORDER_TYPE = getState().FulfillmentNew.selectedOrderType
  const TOTAL_ROWS = parseInt(getState().FulfillmentNew.orderCount[getOrderTypeFromName(ORDER_TYPE).key], 10)

  const ORDER_SEARCH_REQUEST = getOrderSearchParameter(getState)
  requestParam.reportType = API_MODULE_TYPES.fulfilment
  requestParam.jobParams.totalCount = TOTAL_ROWS
  requestParam.jobParams.statusType = ORDER_TYPE
  requestParam.jobParams.isSearchFilter = true
  requestParam.jobParams.orderSearchRequest = ORDER_SEARCH_REQUEST
  return requestParam
}

const exportRequestParamsInventory = (requestParam, getState) => {
  const TOTAL_ROWS = parseInt(getState().InventoryNew.totalInventory, 10)

  requestParam.reportType = (getState().AppConfig.sourceType?.selected === 1) ? API_MODULE_TYPES.inventory : CLUB_MODULE_TYPES.inventory
  requestParam.jobParams.totalCount = TOTAL_ROWS
  return requestParam
}

const exportRequestParamsInTransitInventory = (requestParam, getState) => {
  const { itemCount, searchParams } = getState().InTransitInventory
  const TOTAL_ROWS = parseInt(itemCount, 10)
  const itemIdSearchParam = searchParams?.itemIds
  const dateRangeSearchParam = searchParams?.inTransitDate
  requestParam.reportType = API_MODULE_TYPES.inTransitInventory
  requestParam.jobParams.totalCount = TOTAL_ROWS
  if (itemIdSearchParam || dateRangeSearchParam?.from || dateRangeSearchParam?.to) {
    requestParam.jobParams.itemIds = itemIdSearchParam
    requestParam.jobParams.fromDate = dateRangeSearchParam?.from
    requestParam.jobParams.toDate = dateRangeSearchParam?.to
  }
  return requestParam
}

/**
 * request params for Club sales/Order forecast
 * @param {*} requestParam 
 * @param {*} getState 
 * @returns 
 */
const exportRequestParamsClubForecastDemand = (requestParam, getState) => {
  const { forecastFilterApplied } = getState().ClubForecastChart
  const { filtersApplied } = getState().ClubOrderDemandChart
  const { isVendor } = getState().UserInfo
  const vendorId = getVendorIdFromState(getState)
  const { pathname } = getState().router.location
  const isSalesForecast = (pathname === ROUTE_MODULES?.forecasting?.clubsaleforecast?.path)

  requestParam.reportType = CLUB_MODULE_TYPES.salesForecast
  requestParam.jobParams = {
    vendorId: isVendor ? vendorId : '0',
    type: isSalesForecast ? "sales" : "order",
    filters: isSalesForecast ? forecastFilterApplied : filtersApplied
  }
  return requestParam
}

/**
 * request params for Club OOS
 * @param {*} requestParam 
 * @param {*} getState 
 * @returns 
 */
const exportRequestParamsClubOOS = (requestParam, getState) => {
  const { oosVisibilityFilters, total } = getState().ClubOutofStock
  const { isVendor } = getState().UserInfo
  const vendorId = getVendorIdFromState(getState)

  requestParam.reportType = CLUB_MODULE_TYPES.oos
  requestParam.jobParams = {
    vendorId: isVendor ? vendorId : '0',
    totalCount: total,
    filters: oosVisibilityFilters
  }
  return requestParam
}

/**
 * request params for Daily Capacity
 * @param {*} requestParam 
 * @param {*} getState 
 * @returns 
 */
const exportRequestParamsDailyCapacity = async (requestParam, getState, dispatch) => {
  const { SHIP_NODE_ID } = await dispatch(getShipNodes())
  const { selectedDate, isDownloadAll } = getState().FCapacity
  const vendorId = getVendorIdFromState(getState)
  const dateFormat = 'MM-DD-YYYY';

  requestParam = {
    ...requestParam,
    format: 'excel',
    refreshTask: true,
    vendorId,
    reportType: API_MODULE_TYPES.dailyCapacity,
    jobParams: {
      vendorId,
      startDate: isDownloadAll ? moment().format(dateFormat) : selectedDate?.fromDate,
      endDate: isDownloadAll ? moment().add(180, 'days').format(dateFormat) : selectedDate?.toDate,
      totalCount: 1,
      shipNodeId: SHIP_NODE_ID
    }
  }
  return requestParam
}

const exportRequestParamsPerformance = (requestParam, getState) => {
  requestParam.reportType = API_MODULE_TYPES.performance
  requestParam.jobParams.week = getState().Performance.selected.weekDetailedView.value
  requestParam.jobParams.statusType = 'All'
  delete requestParam.jobParams.shipNodeId
  return requestParam
}

export const exportRequestParamsLeadTime = (requestParam, getState) => {
  const { selectedDate } = getState().PredefinedReports
  requestParam.reportType = API_MODULE_TYPES.leadTime
  requestParam.fromDate = selectedDate.fromDate
  requestParam.toDate = selectedDate.toDate
  requestParam.vendorId = '0000'
  requestParam.jobParams = {
    vendorId: '0000'
  }
  return requestParam
}

const exportRequestParamsPredefinedReports = (requestParam, getState) => {
  const { selectedDate, scopes } = getState().PredefinedReports
  const { isVendor } = getState().UserInfo
  const vendorId = getVendorIdFromState(getState) || '0000'
  const VENDOR_ID = !isVendor && find(scopes, { selected: true }).value === 'all' ? '0000' : vendorId

  requestParam.vendorId = VENDOR_ID
  requestParam.jobParams = {
    vendorId: VENDOR_ID,
    shipNodeId: '',
    fromDate: selectedDate.fromDate,
    toDate: selectedDate.toDate
  }

  if (requestParam?.reportType.includes(API_MODULE_TYPES.openOrders)) {
    requestParam.jobParams = {
      ...requestParam.jobParams,
      statusType: 'Active'
    }
  }
  return requestParam
}

const exportRequestParamsSmartInventory = (requestParam, getState) => {
  const { smartInventoryTab, apiRequestFilters, alertType } = getState().SmartInventory
  const { isVendor } = getState().UserInfo
  const vendorId = getVendorIdFromState(getState)

  requestParam.reportType = API_MODULE_TYPES.smartInventory
  requestParam.jobParams = {
    tab: smartInventoryTab,
    filters: {}
  }
  getSmartInventoryFilters(FILTER_KEYS.DIVISION, apiRequestFilters, requestParam)
  getSmartInventoryFilters(FILTER_KEYS.CATEGORY_ID, apiRequestFilters, requestParam)
  getSmartInventoryFilters(FILTER_KEYS.CATEGORY, apiRequestFilters, requestParam)
  getSmartInventoryFilters(FILTER_KEYS.SUBCATEGORY_NUM, apiRequestFilters, requestParam)
  getSmartInventoryFilters(FILTER_KEYS.SUBCATEGORY, apiRequestFilters, requestParam)
  requestParam.jobParams.filters.status = alertType.value === 'All' ? [] : [alertType.value]
  if (isVendor) {
    requestParam.jobParams.filters.suppliers = [vendorId]
  } else {
    const foundFilter = apiRequestFilters.find(filter => filter.filterName === FILTER_KEYS.SUPPLIER_ID)
    if (foundFilter) {
      requestParam.jobParams.filters.suppliers = foundFilter.filterValues
    }
  }
  return requestParam
}

export const exportRequestParamsBulkApprovals = (requestParam, getState) => {
  const { isCompletedView, selectedAdminTab, selectedSuppliers, selectedARMonth } = getState().Approvals
  const { toDate, fromDate } = getARMonthwiseParam({ selectedARMonth })
  requestParam.vendorId = '0000'
  requestParam.jobParams = {
    vendorId: '0000',
    status: isCompletedView ? 'resolved' : 'pending'
  }
  switch (selectedAdminTab) {
    case PENALTY:
      requestParam.reportType = API_MODULE_TYPES.penaltyApprovals
      requestParam.jobParams = {
        ...requestParam.jobParams,
        totalCount: parseInt(getState().PenaltyProgram.totalRows, 10)
      }
      break
    case ACCOUNTS_RECEIVABLE:
      requestParam.reportType = API_MODULE_TYPES.accountsReceivable
      requestParam.fromDate = fromDate
      requestParam.toDate = toDate
      requestParam.jobParams = {
        ...requestParam.jobParams,
        filters: [
          {
            filterName: "supplierId",
            filterValues: selectedSuppliers
          }
        ],
        totalCount: parseInt(getState().AccountReceivable.totalRows, 10)
      }
      break
    default:
      break
  }
  return requestParam
}

const getSmartInventoryFilters = (filterName, apiRequestFilters, requestParam) => {
  const foundFilter = apiRequestFilters.find(filter => filter.filterName === filterName)
  if (foundFilter) {
    requestParam.jobParams.filters[filterName] = foundFilter.filterValues
  }
}

export const exportData = () => async (dispatch, getState) => {
  const { isVendor } = getState().UserInfo
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId
  const MODULE_TYPE = getRouteNameFromURL({ pathName: getState().router.location.pathname })
  const REPORT_TYPE = find(getState().PredefinedReports.reportType, { selected: true }).reportType === API_MODULE_TYPES.leadTime
  const IS_PREDEFINED_REPORTS = MODULE_TYPE === capitalize(MODULE_TYPES.predefinedReports)
  const IS_SMART_INVENTORY = MODULE_TYPE === capitalize(MODULE_TYPES.smartInventory)
  const { isCompletedView, selectedAdminTab } = getState()?.Approvals

  const { reportType } = getState().PredefinedReports
  const selectedReport = find(reportType, { selected: true })
  let isImmediateDownload = false //No notification - immediate download

  dispatch({
    type: DISABLE_DOWNLOAD,
    payload: {
      downloadBtnCtrl: getVisibleModuleName(visibleModuleName({ IS_PREDEFINED_REPORTS, selectedReport, MODULE_TYPE }))
    }
  })
  dispatch({
    type: SET_MODULE_TYPE,
    payload: {
      moduleType: MODULE_TYPE
    }
  })


  let requestParams = requestJobParams(IS_SMART_INVENTORY, VENDOR_ID, SHIP_NODE_ID, isVendor)

  const endPoint = getAPIEndPoint(MODULE_TYPE, selectedReport)

  switch (MODULE_TYPE) {
    case capitalize(MODULE_TYPES.fulfilment):
      requestParams = exportRequestParamsFulfillment(requestParams, getState)
      break
    case capitalize(MODULE_TYPES.inventory):
      requestParams = exportRequestParamsInventory(requestParams, getState)
      break
    case capitalize(MODULE_TYPES.weekmetrics):
      requestParams = exportRequestParamsPerformance(requestParams, getState)
      break
    case capitalize(MODULE_TYPES.predefinedReports):
      if (REPORT_TYPE) {   //Leadtime case
        requestParams = exportRequestParamsLeadTime(requestParams, getState)
      }
      else {
        requestParams.reportType = selectedReport.reportType
        requestParams = exportRequestParamsPredefinedReports(requestParams, getState)
      }
      break
    case capitalize(MODULE_TYPES.smartInventory):
      requestParams = exportRequestParamsSmartInventory(requestParams, getState)
      break
    case capitalize(MODULE_TYPES.leadTimeApproval): //Approvals
      requestParams = exportRequestParamsBulkApprovals(requestParams, getState)
      isImmediateDownload = !(selectedAdminTab === PENALTY && isCompletedView)
      break
    case capitalize(MODULE_TYPES.inTransitInventory):
      requestParams = exportRequestParamsInTransitInventory(requestParams, getState)
      break
    case capitalize(MODULE_TYPES.clubSaleForecast):
    case capitalize(MODULE_TYPES.clubOrderDemand):
      requestParams = exportRequestParamsClubForecastDemand(requestParams, getState)
      break
    case MODULE_TYPES.clubOOS:
      requestParams = exportRequestParamsClubOOS(requestParams, getState)
      break
    case MODULE_TYPES.dailyCapacity:
      requestParams = await exportRequestParamsDailyCapacity(requestParams, getState, dispatch)
      break
    default:
      break
  }

  return dispatch({
    [CALL_API]: {
      types: [INITIATE_EXPORT_REQUEST, INITIATE_EXPORT_SUCCESS, INITIATE_EXPORT_FAILURE],
      endpoint: endPoint,
      method: 'POST',
      data: requestParams,
      payload: requestParams
    }
  }).then(response => {
    if (response?.type === INITIATE_EXPORT_SUCCESS) {
      setTimeout(() => dispatch(checkExportStatus(isImmediateDownload)), 5000)
      dispatch(
        showToastMessage({
          message: DOWNLOAD_REPORT_INITIATED
        })
      )
    } else {
      dispatch({
        type: ENABLE_DOWNLOAD,
        payload: {
          downloadBtnCtrl: getVisibleModuleName(visibleModuleName({ IS_PREDEFINED_REPORTS, selectedReport, MODULE_TYPE }))
        }
      })
    }
    return Promise.resolve()
  })
}
/**
 * @description visible module name return module name
 * @param {*} IS_PREDEFINED_REPORTS
 * @param {*} selectedReport
 * @param {*} MODULE_TYPE
 * @returns
 */
const visibleModuleName = ({ IS_PREDEFINED_REPORTS, selectedReport, MODULE_TYPE }) => {
  return IS_PREDEFINED_REPORTS ? selectedReport.reportType : MODULE_TYPE;
}


/**
 * @description get request param object
 * @param {*} IS_SMART_INVENTORY
 * @param {*} VENDOR_ID
 * @param {*} SHIP_NODE_ID
 * @param {*} isVendor
 * @returns
 */
const requestJobParams = (IS_SMART_INVENTORY, VENDOR_ID, SHIP_NODE_ID, isVendor) => {
  const jobParams = IS_SMART_INVENTORY ? {} : { vendorId: String(VENDOR_ID), shipNodeId: SHIP_NODE_ID }

  return {
    format: 'excel',
    refreshTask: false,
    vendorId: VENDOR_ID === null || (!isVendor && IS_SMART_INVENTORY) ? '' : String(VENDOR_ID),
    jobParams
  }
}

export const checkExportStatus = (isImmediateDownload) => (dispatch, getState) => {
  const ACK_IDS = getState().AppConfig.exportOrders.ackIds
  const MODULE_TYPE = getRouteNameFromURL({ pathName: getState().router.location.pathname })
  const IS_PREDEFINED_REPORTS = MODULE_TYPE === capitalize(MODULE_TYPES.predefinedReports)
  const { scopes } = getState().PredefinedReports
  const vendorId = getVendorIdFromState(getState) || '0000'
  const VENDOR_ID = IS_PREDEFINED_REPORTS && find(scopes, { selected: true }).value === 'all' ? '0000' : vendorId

  ACK_IDS.filter(ackId => includes(['INITIATED', 'INPROGRESS'], ackId.status)).forEach(ackId => {
    return dispatch({
      [CALL_API]: {
        types: [CHECK_EXPORT_STATUS_REQUEST, CHECK_EXPORT_STATUS_SUCCESS, CHECK_EXPORT_STATUS_FAILURE],
        endpoint: `${config.get('checkTaskStatus')}/${ackId.ackId}?vendorId=${VENDOR_ID === null ? '' : VENDOR_ID}`,
        method: 'GET',
        payload: { ackId }
      }
    }).then(response => {
      const updatedAck = getState().AppConfig.exportOrders?.ackIds.find(_ackId => ackId.ackId === _ackId.ackId)
      if (!!response && response.type === CHECK_EXPORT_STATUS_SUCCESS) {
        dispatch(checkExportStatusSuccess(isImmediateDownload, updatedAck, MODULE_TYPE))
      } else {
        dispatch(updateNotification(updatedAck))
        return Promise.resolve()
      }
      return Promise.resolve()
    })
  })
}

export const checkExportStatusSuccess = (isImmediateDownload, updatedAck, MODULE_TYPE) => (dispatch, getState) => {
  const debouncedExportStatus = debounce(() => dispatch(checkExportStatus(isImmediateDownload)), CHECK_STATUS_INTERVAL)
  const FILE_STATUSES = getState().AppConfig.exportOrders.ackIds.map(ackId => ackId.status)

  if (differenceWith(FILE_STATUSES, ['COMPLETED', 'FAILED'], isEqual).length > 0) {
    debouncedExportStatus()
  } else if (isImmediateDownload) {
    dispatch(getExportFile({ ackId: updatedAck }))
    dispatch({
      type: ENABLE_DOWNLOAD,
      payload: {
        downloadBtnCtrl: getVisibleModuleName(MODULE_TYPE)
      }
    })
  }

  if (!isImmediateDownload) {
    if (!getState().NotificationDrawer?.downloadReports?.find(report => report?.ackId === updatedAck?.ackId)) {
      dispatch(
        showToastMessage({
          // eslint-disable-next-line max-len
          message: `Request #${updatedAck?.ackId} for download has started. You will get notification once this request is processed.`
        })
      )
    }
    dispatch(updateNotification(updatedAck))
  }
  if (get(updatedAck, 'status', null) === 'FAILED') {
    dispatch(aiBulkExportSingleLineFailed({ ackId: updatedAck?.ackId }))
  }
}

export const getExportFile = ({ ackId, actionTrigger = '' }) => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState) || '0000'

  dispatch(downloadReportRequest(ackId.ackId, true))
  return dispatch({
    [CALL_API]: {
      types: [GET_EXPORT_FILE_REQUEST, GET_EXPORT_FILE_SUCCESS, GET_EXPORT_FILE_FAILURE],
      endpoint: `${config.get('downloadPickList')}/${ackId.ackId}?vendorId=${VENDOR_ID}`,
      method: 'GET',
      isExcel: true
    }
  }).then(response => {
    dispatch(hideToastMessage())
    dispatch(aiDownloadReports({ type: response.type, response, reportNumber: ackId.ackId, actionTrigger }))
    if (Boolean(response) && response.type === GET_EXPORT_FILE_SUCCESS) {
      downloadFile({ data: response.response }, ackId.fileName)
      dispatch(downloadReportRequest(ackId.ackId, false))
    } else {
      dispatch(showToastMessage({ message: FULFILLMENT_DOWNLOAD_FILE_FAILED }))
    }
  })
}

export const retryFailedExportFile = ({ ackId, taskType = undefined, actionTrigger = '' }) => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId
  const IS_FULFILLMENT = [taskType].includes(VISIBLE_MODULE_TYPES.fulfilment)
  const IS_INVENTORY = [taskType].includes(VISIBLE_MODULE_TYPES.inventory)
  const IS_PERFORMANCE = [taskType].includes(VISIBLE_MODULE_TYPES.performance)
  const IS_PREDEFINED_REPORTS =
    [taskType].includes(VISIBLE_MODULE_TYPES.leadTime) ||
    [taskType].includes(VISIBLE_MODULE_TYPES.openOrders) ||
    [taskType].includes(VISIBLE_MODULE_TYPES.salesOrders)

  const IS_SMART_INVENTORY =
    [taskType].includes(VISIBLE_MODULE_TYPES.alert) || [taskType].includes(VISIBLE_MODULE_TYPES.smartInventory)

  const IN_TRANSIT_INVENTORY = [taskType].includes(VISIBLE_MODULE_TYPES.inTransitInventory)
  const CLUB_SALE_FORECAST = [taskType].includes(CLUB_MODULE_TYPES.salesForecast)
  const CLUB_OOS = [taskType].includes(CLUB_MODULE_TYPES.oos)

  const debouncedExportStatus = debounce(() => dispatch(checkExportStatus()), 5000)
  let requestParams = {
    format: 'excel',
    refreshTask: true,
    vendorId: VENDOR_ID === null ? '' : String(VENDOR_ID),
    jobParams: {
      vendorId: VENDOR_ID === null ? '' : String(VENDOR_ID),
      shipNodeId: SHIP_NODE_ID
    }
  }

  if (IS_FULFILLMENT) requestParams = exportRequestParamsFulfillment(requestParams, getState)
  else if (IS_INVENTORY) requestParams = exportRequestParamsInventory(requestParams, getState)
  else if (IS_PERFORMANCE) requestParams = exportRequestParamsPerformance(requestParams, getState)
  else if (IS_PREDEFINED_REPORTS) {
    if (taskType.includes(VISIBLE_MODULE_TYPES.leadTime)) {
      requestParams = exportRequestParamsLeadTime(requestParams, getState)
    } else {
      requestParams.reportType =
        taskType === VISIBLE_MODULE_TYPES.openOrders ? API_MODULE_TYPES.openOrders : API_MODULE_TYPES.salesOrders
      requestParams = exportRequestParamsPredefinedReports(requestParams, getState)
    }
  } else if (IS_SMART_INVENTORY) {
    requestParams = exportRequestParamsSmartInventory(requestParams, getState)
    if (ackId?.fileName?.includes('SI_Items')) {
      requestParams.jobParams.tab = SMART_INVENTORY_TABS.ITEMS
    } else if (ackId?.fileName?.includes('SI_Categories')) {
      requestParams.jobParams.tab = SMART_INVENTORY_TABS.CATEGORIES
    } else {
      requestParams.jobParams.tab = SMART_INVENTORY_TABS.SUPPLIERS
    }
  } else if (IN_TRANSIT_INVENTORY) {
    requestParams = exportRequestParamsInTransitInventory(requestParams, getState)
  } else if (CLUB_SALE_FORECAST) {
    requestParams = exportRequestParamsClubForecastDemand(requestParams, getState)
  } else if (CLUB_OOS) {
    requestParams = exportRequestParamsClubOOS(requestParams, getState)
  }

  requestParams.ackId = ackId.ackId

  dispatch(aiBulkExportSingleLineRetry({ ackId: ackId.ackId }))
  return dispatch({
    [CALL_API]: {
      types: [RETRY_EXPORT_FILE_REQUEST, RETRY_EXPORT_FILE_SUCCESS, RETRY_EXPORT_FILE_FAILURE],
      endpoint: `${config.get('initiateTask')}`,
      method: 'POST',
      data: requestParams,
      payload: requestParams
    }
  }).then(response => {
    dispatch(aiDownloadReportRetry({ type: response.type, response, reportNumber: ackId.ackId, actionTrigger }))
    if (!!response && response.type === RETRY_EXPORT_FILE_SUCCESS) {
      const FILE_STATUSES = getState().AppConfig.exportOrders.ackIds.map(ackId => ackId.status)
      dispatch(
        showToastMessage({
          message: `Request #${response?.response?.payload.ackId} for download has started. 
          You will get notification once this request is processed.`
        })
      )
      dispatch({
        type: UPDATE_DOWNLOAD_REPORT_STATUS,
        payload: {
          report: {
            ackId: response?.response?.payload.ackId,
            status: response?.response?.payload.status,
            fileName: JSON.parse(JSON.stringify(response?.response?.payload?.statusDetails))?.fileName,
            supplierName: getVendorIdFromState(getState),
            module: getState().AppConfig.exportOrders.moduleType,
            shipNode: getShipNodeIdFromState(getState).shipNodeId,
            time: moment.utc().format(dateTimeFormat),
            isVendor: getState().UserInfo.isVendor
          }
        }
      })
      if (differenceWith(FILE_STATUSES, ['COMPLETED', 'FAILED'], isEqual).length > 0) debouncedExportStatus()
    }
  })
}

const getAPIEndPoint = (moduleType, reportType) => {
  switch (String(moduleType).toLowerCase()) {
    case MODULE_TYPES.fulfilment:
    case MODULE_TYPES.inventory:
    case MODULE_TYPES.performance: {
      return `${config.get('initiatingDownload')}`
    }
    case MODULE_TYPES.smartInventory: {
      return `${config.get('initiateTask')}`
    }
    case MODULE_TYPES.predefinedReports: {
      if (!isEmpty(reportType) && reportType.name.includes('Lead time')) {
        return `${config.get('initiatingDownload')}`
      }
      return `${config.get('initiateTask')}`
    }
    default: {
      return `${config.get('initiatingDownload')}`
    }
  }
}

/**
 * @description
 * @return {function(*, *)}
 */
export const autoSelectVendor = () => (dispatch, getState) => {
  const VENDOR_ID = getDataFromURL('vendor')
  const SHIP_NODE_ID = getDataFromURL('shipNode')
  const CURRENT_PAGE = getState().router.location.pathname

  if (VENDOR_ID && getVendorIdFromState(getState) === null) {
    return dispatch(searchVendor(VENDOR_ID))
      .then(() => {
        const vendor = getState().AppConfig.vendor.vendorList[0]

        if (!vendor) throw new Error(`Unable to find vendor ${VENDOR_ID}`)

        dispatch(selectVendor(vendor)).then(() => {
          if (CURRENT_PAGE.match(/^\/club\/sales-forecast\/vendor\//) && CURRENT_PAGE.match(/item\/\d*/)) {
            const itemId = CURRENT_PAGE.substr(CURRENT_PAGE.lastIndexOf('/') + 1);
            return dispatch(fetchForecastClubs(itemId))
          }
          else if (CURRENT_PAGE.match(/^\/club\/order-demand\/vendor\//) && CURRENT_PAGE.match(/item\/\d*/)) {
            const itemId = CURRENT_PAGE.substr(CURRENT_PAGE.lastIndexOf('/') + 1);
            return dispatch(fetchOrderDemandClubs(itemId))
          }
          SHIP_NODE_ID && dispatch(fetchShipNodes())
            .then(() => {
              const shipNode = getState().AppConfig.shipNodes.list.find(sn => sn.shipNodeId === SHIP_NODE_ID)

              if (!shipNode) throw new Error(`Unable to find ship node ${SHIP_NODE_ID}`)

              return dispatch(selectShipNode({ value: shipNode.shipNodeId, name: shipNode.shipNodeName }))
            })
            .then(() => {
              dispatch(fetchCarrierMethods())
              dispatch(fetchCarrierAccount())
              if (CURRENT_PAGE.match(/^\/returns\/vendor\//) && CURRENT_PAGE.match(/order\/\d*/)) {
                const orderNo = CURRENT_PAGE.substr(CURRENT_PAGE.lastIndexOf('/') + 1);
                return dispatch(fetchReturnOrderItemsFromPath(orderNo))
              }

              if (CURRENT_PAGE.match(/^\/in-transit-inventory\/vendor\//) && CURRENT_PAGE.match(/item\/\d*/)) {
                const itemId = CURRENT_PAGE.substr(CURRENT_PAGE.lastIndexOf('/') + 1);
                return dispatch(fetchInTransitItemsFromPath(itemId))
              }

              const poNumber = getDataFromURL('poNumber')
              return dispatch(setOrderList([{ poNumber }])).then(dispatch(fetchDataBasedOnPage()))
            })
        })
      })
      .catch(error => {
        dispatch(
          aiTrackException({
            error,
            name: 'ERROR_REHYDRATING_ORDER_INFO'
          })
        )
        return dispatch(changePage('/fulfillment'))
      })
  }

  return Promise.resolve()
}

/**
 * Show error on the top
 * @param {*} errMessage Message to be shown
 * @returns dispatch
 */
export const showError = errMessage => dispatch => {
  return dispatch({
    type: ERROR_OCCURRED,
    payload: {
      isAlertAction: true,
      isFailure: true,
      message: errMessage
    }
  })
}

/**
 * Sets the module on the basis of url path
 * @returns dispatch
 */
export const setVisibleModule = () => (dispatch, getState) => {
  const MODULE_TYPE = getRouteNameFromURL({ pathName: getState().router.location.pathname })
  return dispatch({
    type: SET_MODULE_TYPE,
    payload: {
      moduleType: MODULE_TYPE
    }
  })
}

/**
 * Update notification once the status is changed
 */
export const updateNotification = updatedAck => (dispatch, getState) => {
  dispatch({
    type: ADD_DOWNLOAD_REPORT,
    payload: {
      report: {
        ...updatedAck,
        supplierName: updatedAck?.vendorId,
        module: updatedAck?.taskType,
        shipNode: updatedAck?.shipnodeId,
        time: moment.utc().format(dateTimeFormat),
        isVendor: getState().UserInfo.isVendor
      },
      downloadBtnCtrl: updatedAck?.taskType
    }
  })
}

/**
 * @description toggle feedback
 */
export const toggleFeedback = () => (dispatch, getState) => {
  dispatch({
    type: TOGGLE_ATMT_FEEDBACK
  })
}

/**
 * @description toggle source type
 */
export const toggleSourceType = (selected) => (dispatch, getState) => {
  dispatch({
    type: TOGGLE_SOURCE_TYPE,
    payload: { selected }
  })
}


/**
 * @description set source type
 */
export const setSourceType = () => (dispatch, getState) => {
  const { pathname } = getState().router?.location
  dispatch({
    type: SET_SOURCE_TYPE,
    payload: { pathname }
  })
}


/**
 * @description Redirect to homepage(Dashboard or Inventory) based on DSV or CLUB selected
 */
export const redirectToHomePage = () => (dispatch, getState) => {
  if (getState().AppConfig.sourceType?.selected === 1) {
    dispatch(changePage(ROUTE_MODULES.performance?.home?.path)) //sourceType = "DSV"
  } else {
    dispatch(changePage(ROUTE_MODULES.inventorypricing?.inventory?.path)) //sourceType = "CLUB"
  }
}
