import { cloneDeep, findIndex } from 'lodash'
import moment from 'moment'
import {
  SET_INTRANSIT_ITEM_COUNT,
  SET_ITEM_INVENTORY_PAGE,
  SET_EDIT_TABLE,
  SET_FILTER_OPEN,
  SET_REQUEST_PAYLOAD,
  CLEAR_REQUEST_PAYLOAD,
  SET_FILTER_PARAMS,
  CLEAR_FILTER_PARAMS,
  INTRANSIT_UPDATE_REQUEST,
  INTRANSIT_UPDATE_SUCCESS,
  INTRANSIT_UPDATE_FAILURE,
  SET_EDIT_CALENDAR,
  RESET_ITEM_DETAILS,
  SEARCH_INTRANSIT_ITEM_FAILURE,
  SEARCH_INTRANSIT_ITEM_SUCCESS,
  SEARCH_INTRANSIT_ITEM_REQUEST
} from './InTransitInventory.types'
import { yearFirstFormat } from '../../config/genericConstants'

export const INITIAL_STATE = {
  itemCount: 0,
  editTable: false,
  isFilterOpen: false,
  selectedItem: {},
  requestPayload: [],
  isFetching: false,
  isEditScheduler: false,
  searchParams: {
    itemIds: null,
    inTransitDate: {
      from: null,
      to: null
    }
  }
}

export default (state = cloneDeep(INITIAL_STATE), action) => {
  switch (action.type) {
    case SET_INTRANSIT_ITEM_COUNT:
      return {
        ...state,
        itemCount: action.payload.count
      }

    case SET_ITEM_INVENTORY_PAGE:
      return {
        ...state,
        selectedItem: action?.payload?.itemDetails
      }

    case SET_EDIT_TABLE:
      return {
        ...state,
        editTable: action?.payload?.editable
      }

    case SET_FILTER_OPEN:
      return {
        ...state,
        isFilterOpen: action.payload?.filterOpen
      }

    case SET_EDIT_CALENDAR:
      const { enable } = action.payload
      return {
        ...state,
        isEditScheduler: enable
      }

    case INTRANSIT_UPDATE_REQUEST:
    case SEARCH_INTRANSIT_ITEM_REQUEST:
      return {
        ...state,
        isFetching: true
      }

    case INTRANSIT_UPDATE_SUCCESS:
    case INTRANSIT_UPDATE_FAILURE:
    case SEARCH_INTRANSIT_ITEM_FAILURE:
    case SEARCH_INTRANSIT_ITEM_SUCCESS:
      return {
        ...state,
        isFetching: false
      }

    case SET_REQUEST_PAYLOAD:
      const { itemDetails } = action.payload
      let updatedReqPayload = state.requestPayload
      const matchedIndex = findIndex(updatedReqPayload, val => val.itemId === itemDetails?.itemId)
      if (matchedIndex === -1) {
        updatedReqPayload.push(itemDetails)
      } else {
        updatedReqPayload = state.requestPayload?.map(value => {
          if (value?.itemId === itemDetails?.itemId) {
            return { ...itemDetails }
          } else {
            return value
          }
        })
      }
      return {
        ...state,
        requestPayload: updatedReqPayload
      }

    case CLEAR_REQUEST_PAYLOAD:
      return {
        ...state,
        requestPayload: []
      }

    case SET_FILTER_PARAMS: {
      const { searchKey, searchValue } = action.param
      const newSearchParams = cloneDeep(state.searchParams)

      if (searchKey && searchKey.includes('Date')) {
        // contains dates and add dates to respective field
        const dates = searchKey.split('-')
        newSearchParams[dates[0]][dates[1]] = moment(searchValue?._d).format(yearFirstFormat)
      } else {
        newSearchParams[searchKey] = searchValue?.replace(/\s+/g, '')
      }
      return {
        ...state,
        searchParams: { ...cloneDeep(newSearchParams) }
      }
    }

    case CLEAR_FILTER_PARAMS:
      return {
        ...state,
        searchParams: {
          itemIds: null,
          inTransitDate: {
            from: null,
            to: null
          }
        }
      }

    case RESET_ITEM_DETAILS:
      return {
        ...INITIAL_STATE
      }

    default:
      return { ...state }
  }
}