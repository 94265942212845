import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@mui/styles'
import SSHSelectBox from '../../components/SSHSelectBox'

const useStyle = makeStyles(theme => ({
  form: {
    '& .MuiOutlinedInput-root': {
      height: theme.spacing(5),
      borderRadius: theme.spacing(0.5),
      width: theme.spacing(42.5),
      backgroundColor: theme.palette.primary.lighter
    }
  },
  inputText: {
    '& .MuiOutlinedInput-input': {
      padding: `${theme.spacing(0.125)}px ${theme.spacing(1.75)}px`,
      borderRadius: theme.spacing(3.125),
      backgroundColor: theme.palette.primary.lighter
    },
    '& .MuiSelect-root': {
      width: theme.spacing(42.5),
      color: theme.palette.primary.persianBlue,
      fontWeight: 600,
      padding: `${theme.spacing(1.2)}px ${theme.spacing(1.75)}px`
    },
    '& .MuiInputLabel-outlined': {
      color: theme.palette.primary.persianBlue,
      fontWeight: 600
    },
    '& .Mui-focused': {
      height: theme.spacing(5)
    }
  }
}))

const PartnerSelect = ({ vendorList, selectedVendor, size, selectVendor }) => {
  const options = vendorList.map(vendor => ({ name: vendor.displayName, value: vendor.vendorId }))
  const onSelect = ({ value, name }) => selectVendor({ displayName: name, vendorId: value, name, value })

  const classes = useStyle()
  return (
    <SSHSelectBox
      options={options}
      onSelect={onSelect}
      size={size}
      defaultVal={selectedVendor}
      style={classes.inputText}
      selectStyle={classes.form}
    />
  )
}

PartnerSelect.propTypes = {
  size: PropTypes.string,
  vendorList: PropTypes.arrayOf(PropTypes.shape({ vendorId: PropTypes.string, displayName: PropTypes.string })),
  selectedVendor: PropTypes.object,
  selectVendor: PropTypes.func.isRequired
}

PartnerSelect.defaultProps = {
  size: 'medium',
  vendorList: [],
  selectedVendor: { displayName: null, vendorId: null }
}

export default PartnerSelect
