import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Grid, TextField, Typography } from '@mui/material'
import {withStyles} from '@mui/styles'
import PropTypes from 'prop-types';
import { storeEditedCapacity, toggleEditCapacityFlag } from '../../pages/Capacity/CapacityActions'
import { debounce, findIndex, isEmpty, filter } from 'lodash'
import moment from 'moment'
import SSHTooltip from '../../components/SSHTooltip'


const styles = (theme => ({
    error: {
        backgroundColor: theme.rgba(theme.palette.primary.red, '0.1')
    }
}))


const EditableCell = ({ error, storeEditedCapacity, row, column, ...props }) => {
    const inputField = useRef()
    let errorText = null
    let hasError = !!error
    const debouncedStoreEditedCapacity = debounce((payload) => storeEditedCapacity({ payload }), 1000);

    const onChange = (e) => {
        const { name } = column
        const digit = name.replace(/\D/g, '')
        const { [`date${digit}`]: date, shipNodeId, shipNodeName } = row
        const { value } = inputField.current

        const payload = {
            date,
            shipNodeId,
            shipNodeName,
            capacity: value
        }
        return debouncedStoreEditedCapacity(payload)
    }

    /**
     * @descriptionnis fail capacity then disbled the success and edit with error failed capacity
     * @returns {boolean}
     */
    const isFailCapacity = () => {
        const { failCapacity, editedCapacity } = props
        if (!failCapacity)
            return false

        const { name } = column
        const digit = name.replace(/\D/g, '')
        const { [`date${digit}`]: date, shipNodeId } = row

        if (failCapacity && findIndex(editedCapacity, { shipNodeId, date }) >= 0) {
            props.setError(true)
            return false
        }
        props.setError(false)
        return true
    }

    /**
     * @description set fail capacity 
     * @returns {number}
     */
    const setCapacityOnFail = () => {
        const { failCapacity, editedCapacity, value } = props
        if (!failCapacity)
            return isEmpty(inputField) || value

        const { name } = column
        const digit = name.replace(/\D/g, '')
        const { [`date${digit}`]: date, shipNodeId } = row
        return filter(editedCapacity, { shipNodeId, date })[0]?.capacity || value

    }

    return (
        <Table.Cell {...props}>
            <Grid container justifyContent="flex-start" alignItems="flex-start">
                <Grid item>
                    <TextField
                        error={!!hasError}
                        fullWidth
                        className={hasError ? props?.classes.error : ''}
                        helperText={hasError ? errorText : null}
                        type="number"
                        required
                        size="small"
                        disabled={isFailCapacity()}
                        onKeyDown={event => ['e', 'E', '+', '-', '.'].includes(event.key) && event.preventDefault()}
                        onChange={(e) => onChange(e)}
                        defaultValue={setCapacityOnFail()}
                        inputRef={inputField}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        </Table.Cell>
    )
}

const NonEditableCell = ({ isEditCapacity, isBeforeDate, ...props }) => {
    return (
        <Table.Cell {...props}>
            <Grid container alignItems="center">
                <Grid item>
                    {
                        isEditCapacity && !isBeforeDate ? <SSHTooltip title='Past date capacity are not editable'>
                            <Typography variant="body2">{props?.value}</Typography>
                        </SSHTooltip>
                            :
                            <Typography variant="body2">{props?.value}</Typography>
                    }

                </Grid>
            </Grid>
        </Table.Cell>
    )
}


const EditCapacity = ({ classes, isEditCapacity, toggleEditCapacityFlag, ...props }) => {
    const [error, setError] = useState(false)

    const { column: { name } } = props
    const digit = name.replace(/\D/g, '')
    const { [`date${digit}`]: date } = props?.row
    const isBeforeDate = (!moment(date).isBefore(moment()) || moment(date).isSame(moment(), 'day'))

    if (isEditCapacity && isBeforeDate) {
        return (
            <EditableCell
                error={error}
                setError={setError}
                classes={classes}
                toggleEditMode={toggleEditCapacityFlag}
                {...props}
            />
        )
    }
    return <NonEditableCell
        toggleEditMode={toggleEditCapacityFlag}
        isEditCapacity={isEditCapacity}
        isBeforeDate={isBeforeDate}
        {...props} />
};

EditCapacity.propTypes = {
    isEditCapacity: PropTypes.bool,
    capacity: PropTypes.array
};

const mapStateToProps = state => ({
    isEditCapacity: state.FCapacity.isEditCapacity,
    selectedDate: state.FCapacity.selectedDate,
    failCapacity: state.FCapacity.failCapacity,
    editedCapacity: state.FCapacity.editedCapacity
})

const mapDispatchToProps = dispatch => bindActionCreators({ toggleEditCapacityFlag, storeEditedCapacity }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditCapacity))
