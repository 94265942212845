import { cloneDeep, each, filter, find, findIndex, get, isArray, isEmpty, values } from 'lodash'
import config from '../../config'
import * as gConstants from '../../config/genericConstants'
import { changePage } from '../../reduxSetup/commonActions/AppConfig'
import {
  aiFetchOrderDetails,
  aiFulfillmentUpdateFailure,
  aiTrackException,
  aiUpdateItemDetailsChanges
} from '../../reduxSetup/commonActions/TelemetryActions'
import {
  aiCanceledUnits,
  aiGetShippingLabel,
  aiShipItems,
  aiUpdateItemShipmentQuantity,
  aiSetShipmentFlag,
  aiUpdateItemDetailsChangesFailed
} from '../../reduxSetup/commonActions/TelemetryActionsNew'
import { CALL_API } from '../../reduxSetup/middleware/API'
import { ERROR_OCCURRED } from '../../templates/Error/ErrorHandler.actionTypes'
import {
  downloadPDF,
  getItemPayloadForApi,
  getReleaseItemChanges,
  getShipNodeIdFromState,
  getVendorIdFromState,
  parseReleaseUpdateError
} from '../../utils/common'
import CustomError from '../../utils/CustomError'
import NetworkError from '../../utils/NetworkError'
import {
  UPDATE_FULFILLMENT_RELEASE_FAILURE,
  UPDATE_FULFILLMENT_RELEASE_REQUEST,
  UPDATE_FULFILLMENT_RELEASE_SUCCESS
} from '../FulfillmentNew/Fulfillment.types'
import {
  FETCH_ORDER_DETAILS_FAILURE,
  FETCH_ORDER_DETAILS_REQUEST,
  FETCH_ORDER_DETAILS_SUCCESS,
  GET_SHIP_LABEL_FAILURE,
  GET_SHIP_LABEL_REQUEST,
  GET_SHIP_LABEL_SUCCESS,
  RESET_ORDER_DETAILS,
  SELECT_CARRIER_METHOD,
  SET_CURRENT_ORDER_DETAILS_INDEX,
  SET_ITEM_SHIP_QUANTITY,
  SET_ORDERS_LIST_FOR_DETAILS,
  SET_SHIPMENT_FLAGS,
  SET_TRACKING_NUMBER,
  SHIP_ITEMS_DISPATCHER_FAILURE,
  SHIP_ITEMS_DISPATCHER_REQUEST,
  SHIP_ITEMS_DISPATCHER_SUCCESS,
  SHOW_HIDE_LABEL_GEN_ERROR_POPUP,
  SHOW_HIDE_SHIPMENT_POPUP,
  TOGGLE_EDIT_POPUP,
  FORCE_UPDATE
} from './OrderDetails.actionTypes'
import { SHIPPING_ERROR, RELEASE_STATUS_FAILED, RELEASE_STATUS_SUCCESS, NO_ITEMS } from '../../config/errorConstants'

/**
 * @description Set the order list which can be navigated with prev/next buttons
 * @param {array} rows - optional
 * @return {function(any, any): Promise<unknown>}
 */
export const setOrderList = (rows = []) => (dispatch, getState) => {
  const visibleOrderList = rows.length > 0 ? rows : getState().SSHPaginatedTableReducer.rows
  return Promise.resolve(
    dispatch({
      type: SET_ORDERS_LIST_FOR_DETAILS,
      payload: {
        orderList: visibleOrderList.map(order => ({
          poNumber: order.poNumber,
          salesOrderNumber: order.salesOrderNumber,
          shipmentDate: order.shipmentDate
        }))
      }
    })
  )
}

/**
 * @description Navigate to order details page for any given order
 * @param {object} order
 * @param {number} orderIndex
 * @return {function(any, any=): any}
 */
export const goToOrder = ({ order, orderIndex }) => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId
  const { poNumber, salesOrderNumber } = order
  let currentOrderIndex = orderIndex

  if (!currentOrderIndex) {
    const { selectedOrderList } = getState().OrderDetailsReducer
    currentOrderIndex = findIndex(selectedOrderList, { poNumber, salesOrderNumber })
  }

  dispatch({
    type: SET_CURRENT_ORDER_DETAILS_INDEX,
    payload: {
      currentOrderIndex
    }
  })
  return dispatch(changePage(`/fulfillment/vendor/${VENDOR_ID}/shipNode/${SHIP_NODE_ID}/order/${poNumber}`))
}

/**
 * @description Navigate to next order in the queue
 * @param {object} currentOrder
 */
export const goToPreviousOrder = () => (dispatch, getState) => {
  const { currentOrderIndex, selectedOrderList } = getState().OrderDetailsReducer
  if (currentOrderIndex > 0) {
    const newOrderIndex = currentOrderIndex - 1
    dispatch(goToOrder({ order: selectedOrderList[newOrderIndex], orderIndex: newOrderIndex }))
  }
}

/**
 * @description Navigate to next order in the queue
 * @param {object} currentOrder
 */
export const goToNextOrder = () => (dispatch, getState) => {
  const { currentOrderIndex, selectedOrderList } = getState().OrderDetailsReducer
  if (currentOrderIndex + 1 < selectedOrderList.length) {
    const newOrderIndex = currentOrderIndex + 1
    dispatch(goToOrder({ order: selectedOrderList[newOrderIndex], orderIndex: newOrderIndex }))
  }
}

/**
 * @description Reset order details reducer to its original state
 * @return {function(*): Promise<unknown>}
 */
export const resetOrderDetails = () => dispatch => {
  return Promise.resolve(
    dispatch({
      type: RESET_ORDER_DETAILS
    })
  )
}

/**
 * @description Fetch order details for the given PO number
 * @return {function(any, any): (any)}
 */
export const fetchOrderDetails = () => (dispatch, getState) => {
  const { selectedOrderList, currentOrderIndex } = getState().OrderDetailsReducer
  let poNumber = null
  const salesOrderNumber = null
  try {
    poNumber = selectedOrderList[currentOrderIndex].poNumber
  } catch (error) {
    return dispatch({
      type: ERROR_OCCURRED,
      error: 'Error occurred while fetching the order details'
    })
  }

  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId

  const reqObj = {
    vendorId: String(VENDOR_ID),
    shipNodeId: SHIP_NODE_ID,
    poStatusList: gConstants.ORDER_DETAILS_API_STATUS_LIST,
    poNumberList: [{ poNumber, salesOrderNumber }]
  }

  return dispatch({
    [CALL_API]: {
      types: [FETCH_ORDER_DETAILS_REQUEST, FETCH_ORDER_DETAILS_SUCCESS, FETCH_ORDER_DETAILS_FAILURE],
      endpoint: `${String(process.env.REACT_APP_ORDER_DETAILS) === 'v2' ? config.get('fulfillmentOrderDetails') : config.get('fulfillmentOrderDetails').replace('v2', 'v1')}`,
      method: 'POST',
      data: reqObj
    }
  }).then(({ type }) => {
    if (type === FETCH_ORDER_DETAILS_SUCCESS) {
      dispatch(aiFetchOrderDetails({ reqObj }))
    }
  })
}

/**
 * @description Add method description
 * @param {String} poNumber
 * @param {String} releaseNumber
 * @returns {function(*, *): *}
 */
export const updateItemDetailChanges = ({ poNumber, status }) => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const release = getState().OrderDetailsReducer.currentOrder.poReleaseList
  const { isSoftLaunch } = getState().OrderDetailsReducer?.currentOrder

  release.map(updatedRelease => {
    let itemDetails = []
    if (status === gConstants.ORDER_STATUSES[1].value) {
      itemDetails = filter(updatedRelease.itemDetails, { status: gConstants.ORDER_STATUSES[0].value })
    } else if (status === gConstants.ORDER_STATUSES[3].value) {
      itemDetails = filter(updatedRelease.itemDetails, release => {
        return (
          release.status === gConstants.ORDER_STATUSES[0].value || release.status === gConstants.ORDER_STATUSES[1].value
        )
      })
    }

    const endPointsSelections = isSoftLaunch ? config.get('newOrderStatusUpdate') : config.get('orderStatusUpdate')//toggle softlaunch flag

    const requestObject = {
      items: getReleaseItemChanges({ itemDetails, status }),
      releaseNo: updatedRelease.basicDetails.releaseNumber,
      documentType: updatedRelease.basicDetails.documentType,
      enterpriseCode: updatedRelease.basicDetails.enterpriseCode,
      poNumber,
      fulfillmentOrderNo: updatedRelease.basicDetails?.fulfillmentOrderNo,
      vendorId: VENDOR_ID,
      containsAlcohol: false,
      containsHazardousMaterial: false,
      containsSignature: false
    }

    return dispatch({
      [CALL_API]: {
        types: [
          UPDATE_FULFILLMENT_RELEASE_REQUEST,
          UPDATE_FULFILLMENT_RELEASE_SUCCESS,
          UPDATE_FULFILLMENT_RELEASE_FAILURE
        ],
        endpoint: `${endPointsSelections}/${VENDOR_ID}`,
        method: 'POST',
        data: requestObject,
        payload: { status }
      }
    })
      .then(result => {
        if (!result) {
          return null
        }
        if (!isEmpty(result.apiResponse)) {
          dispatch(
            aiUpdateItemDetailsChangesFailed({
              items: requestObject.items,
              releaseNo: requestObject.releaseNo,
              poNumber
            })
          )
          return dispatch(errorUpdatingRelease({ updatedRelease, response: result.apiResponse }))
        }
        return dispatch(releaseUpdateSuccess({ status })).then(() => {
          // success refresh item details page with change status
          dispatch(
            aiUpdateItemDetailsChanges({ items: requestObject.items, releaseNo: requestObject.releaseNo, poNumber })
          )
          return dispatch(fetchOrderDetails())
        })
      })
      .catch(err => {
        return dispatch(errorUpdatingRelease({ updatedRelease, response: err.response }))
      })
  })
}

/**
 * @description Add method description
 * @param {object} updatedRelease
 * @param {object} response
 * @returns {function(*, *): *}
 */
export const errorUpdatingRelease = ({ updatedRelease, response }) => (dispatch, getState) => {
  const { error } = parseReleaseUpdateError({ response, updateType: 'items' })

  dispatch(aiFulfillmentUpdateFailure({ release: updatedRelease, errorMessage: error }))
  return Promise.resolve(
    dispatch({
      type: UPDATE_FULFILLMENT_RELEASE_FAILURE,
      payload: {
        updateFailed: true,
        message: error
      }
    })
  )
}

/**
 * @description Add method description
 * @param {String} status
 * @returns {function(*, *): *}
 */
export const releaseUpdateSuccess = ({ status }) => dispatch => {
  return Promise.resolve(
    dispatch({
      type: UPDATE_FULFILLMENT_RELEASE_SUCCESS,
      payload: {
        updateSuccess: true,
        message: status === gConstants.ORDER_STATUSES[1].value ? RELEASE_STATUS_SUCCESS : RELEASE_STATUS_FAILED
      }
    })
  )
}

export const cancelledUnits = ({ cancelItems }) => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const { isSoftLaunch } = getState().OrderDetailsReducer?.currentOrder

  let { itemDetails } = cancelItems.releaseDetails
  itemDetails = filter(itemDetails, itemStatus => {
    return (
      itemStatus.itemNumber === cancelItems.itemNumber && gConstants.ACTIVE_ITEM_STATUSES.includes(itemStatus.status)
    )
  })
  if (isEmpty(itemDetails)) {
    // if nothing to cancel
    return dispatch(
      errorUpdatingRelease({
        updatedRelease: cancelItems.releaseDetails,
        response: { error: { errorCode: 'Error', errorMessage: NO_ITEMS } }
      })
    )
  }

  const requestObject = {
    items: getReleaseItemChanges({ itemDetails, status: gConstants.ORDER_STATUSES[3].value }),
    releaseNo: cancelItems.releaseNumber,
    documentType: cancelItems.releaseDetails.basicDetails.documentType,
    enterpriseCode: cancelItems.releaseDetails.basicDetails.enterpriseCode,
    poNumber: cancelItems.releaseDetails.basicDetails.poNumber,
    fulfillmentOrderNo: cancelItems.releaseDetails.basicDetails?.fulfillmentOrderNo,
    vendorId: VENDOR_ID,
    containsAlcohol: false,
    containsHazardousMaterial: false,
    containsSignature: false
  }

  requestObject.items[0].updateQuantity = cancelItems.cancelledUnits // update cancel as per  cancel units

  const endPointsSelections = isSoftLaunch ? config.get('newOrderStatusUpdate') : config.get('orderStatusUpdate')//toggle softlaunch flag

  return dispatch({
    [CALL_API]: {
      types: [
        UPDATE_FULFILLMENT_RELEASE_REQUEST,
        UPDATE_FULFILLMENT_RELEASE_SUCCESS,
        UPDATE_FULFILLMENT_RELEASE_FAILURE
      ],
      endpoint: `${endPointsSelections}/${VENDOR_ID}`,
      method: 'POST',
      data: requestObject,
      payload: { status: gConstants.ORDER_STATUSES[3].value }
    }
  })
    .then(result => {
      if (!result) {
        return null
      }
      if (!isEmpty(result.apiResponse)) {
        return dispatch(
          errorUpdatingRelease({ updatedRelease: cancelItems.releaseDetails, response: result.apiResponse })
        ).then(() => {
          // error on changing status show message and refresh page
          // return dispatch(fetchOrderDetails())
        })
      }
      return dispatch(releaseUpdateSuccess({ status: gConstants.ORDER_STATUSES[3].value })).then(() => {
        // success refresh item details page with change status
        dispatch(aiCanceledUnits({ requestObject }))
        return dispatch(fetchOrderDetails())
      })
    })
    .catch(err => {
      return dispatch(errorUpdatingRelease({ updatedRelease: cancelItems.releaseDetails, response: err.response }))
    })
}

/**
 * @description Show/hide the shipment popup
 * @param {string} releaseNumber
 * @return {function(any): Promise<unknown>}
 */
export const showHideShipmentPopup = ({ releaseNumber }) => dispatch => {
  return Promise.resolve(
    dispatch({
      type: SHOW_HIDE_SHIPMENT_POPUP,
      payload: { releaseNumber }
    })
  )
}

/**
 * @description set carrier method in redux
 * @param {string} name
 * @param {string} value
 * @return {function(any): Promise<unknown>}
 */
export const selectCarrierMethod = ({ name, value }) => dispatch => {
  return Promise.resolve(
    dispatch({
      type: SELECT_CARRIER_METHOD,
      payload: { name, value }
    })
  )
}

/**
 * @description set the tracking number or item quantity
 * @param {string} name
 * @param {string} value
 * @return {function(any): Promise<unknown>}
 */
export const setTrackingNumber = ({ value }) => dispatch => {
  return Promise.resolve(
    dispatch({
      type: SET_TRACKING_NUMBER,
      payload: { value }
    })
  )
}

/**
 * @description Set the quantity to ship entered in shipment popup. we maintain it in a different key/val called
 *   `updatedfulfilmentQty`
 * @param {object} item
 * @param {number|string} fulfillmentQty
 * @param {string} releaseNumber
 * @return {function(function, function): Promise<unknown>}
 */
export const updateItemShipmentQty = ({ item, fulfillmentQty, releaseNumber }) => (dispatch, getState) => {
  let error = null
  const intFulfillmentQty = isEmpty(fulfillmentQty) ? '' : parseInt(fulfillmentQty, 0)
  if (isEmpty(fulfillmentQty) || intFulfillmentQty <= 0) {
    error = `Invalid quantity `
  } else if (intFulfillmentQty > parseInt(item.currentfulfilmentQty, 10)) {
    error = `Quantity cannot be more than ${item.currentfulfilmentQty}`
  }
  const release = getState().OrderDetailsReducer.currentOrder.poReleaseList.find(
    release => release.basicDetails.releaseNumber === releaseNumber
  )
  release.itemDetails = release.itemDetails.map(releaseItem => {
    if (releaseItem.primeLineNo === item.primeLineNo && gConstants.ACTIVE_ITEM_STATUSES.includes(item.status)) {
      return {
        ...releaseItem,
        error,
        updatedFulfillmentQty: intFulfillmentQty
      }
    }
    return { ...releaseItem }
  })

  dispatch(aiUpdateItemShipmentQuantity({ release }))
  return Promise.resolve(
    dispatch({
      type: SET_ITEM_SHIP_QUANTITY,
      payload: { release }
    })
  )
}

/**
 * @description Use this action to select if the shipment contains Alcohol, Hazardous material or if it requires
 *   signature
 * @param {string} shipmentType
 * @return {function(any, any): (Promise<void>)}
 */
export const setShipmentFlags = ({ shipmentType, fieldName }) => (dispatch, getState) => {
  if (!['prepaid', 'collect'].includes(shipmentType)) {
    return Promise.resolve()
  }

  const flags = cloneDeep(getState().OrderDetailsReducer.shipment.flags)
  each(flags[shipmentType], (value, key) => {
    if (key === fieldName) {
      flags[shipmentType][key] = !value
    }
  })

  return Promise.resolve(
    dispatch({
      type: SET_SHIPMENT_FLAGS,
      payload: { flags }
    })
  ).then(() => {
    dispatch(aiSetShipmentFlag({ shipmentType, fieldName, flags }))
  })
}

/**
 * @description Action use to mark items as shipped - manual or automatic
 * @param {boolean} manual - Whether it's manual or auto shipping
 * @return {function(any, any=): Promise<void>}
 */
export const shipItems = ({
  manual,
  items,
  trackingNumber,
  selectedCarrier,
  releaseNumber,
  basicDetails,
  prepaidActive
}) => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const { shipment } = getState().OrderDetailsReducer
  const { isSoftLaunch } = getState().OrderDetailsReducer?.currentOrder
  const { flags, isForceUpdate } = shipment
  const CARRIER_METHODS = getState().FulfillmentNew?.carrierMethods?.list
  const shipmentTypes = {
    manual: { itemList: [] },
    prepaid: { itemList: [] },
    collect: { itemList: [] }
  }

  items.forEach(item => {
    if (manual) {
      const scac = find(CARRIER_METHODS, { carrierMethodId: selectedCarrier.value })?.carrierId
      shipmentTypes.manual.itemList.push(getItemPayloadForApi(item, trackingNumber, selectedCarrier.value, scac))
    } else if (prepaidActive) {
      // if both collect and prepaid selected
      if (
        String(item?.vendorPaymentTerm).toLowerCase() === String(gConstants.PREPAID_SHIPPING_TYPE_FLAG).toLowerCase()
      ) {
        return shipmentTypes.prepaid.itemList.push(getItemPayloadForApi(item))
      }
    } else if (
      String(item?.vendorPaymentTerm).toLowerCase() === String(gConstants.COLLECT_SHIPPING_TYPE_FLAG).toLowerCase()
    ) {
      return shipmentTypes.collect.itemList.push(getItemPayloadForApi(item))
    }
  })
  const dispatches = []
  each(shipmentTypes, (value, shipmentType) => {
    if (isEmpty(value.itemList)) {
      return
    }

    const request = {
      items: value.itemList,
      releaseNo: releaseNumber,
      documentType: basicDetails.documentType,
      enterpriseCode: basicDetails.enterpriseCode,
      poNumber: basicDetails.poNumber,
      fulfillmentOrderNo: basicDetails?.fulfillmentOrderNo,
      vendorId: VENDOR_ID,
      containsAlcohol: shipmentType === 'manual' ? false : flags[shipmentType].alcohol,
      containsHazardousMaterial: shipmentType === 'manual' ? false : flags[shipmentType].hazard,
      containsSignature: shipmentType === 'manual' ? false : flags[shipmentType].signature
    }

    const endPointsSelections = isSoftLaunch ? config.get('newOrderStatusUpdate') : config.get('orderStatusUpdate')//toggle softlaunch flag

    dispatches.push({
      shipmentType,
      request,
      endpoint: `${endPointsSelections}/${VENDOR_ID}?generateTrackingNo=${manual ? 'false' : 'true'}&isForceUpdate=${isForceUpdate}`
    })
  })

  const handleRequest = ({ request, endpoint, shipmentType }) =>
    dispatch({
      [CALL_API]: {
        types: [SHIP_ITEMS_DISPATCHER_REQUEST, SHIP_ITEMS_DISPATCHER_SUCCESS, SHIP_ITEMS_DISPATCHER_FAILURE],
        endpoint,
        method: 'POST',
        data: request,
        payload: { shipmentType }
      }
    })

  const responseErrors = {
    manual: false,
    prepaid: false,
    collect: false
  }

  return Promise.all(dispatches.map(handleRequest))
    .then(results => {
      dispatch(aiShipItems({ results })) // appinsight
      let trackingNo = null

      if (isArray(results)) {
        results.forEach(result => {
          let finalResponse = result.response
          if (!finalResponse || result instanceof CustomError) {
            finalResponse = result.apiResponse
          }

          if (!finalResponse || result instanceof NetworkError) {
            finalResponse = result.apiResponse
          }

          const error = parseReleaseUpdateError({ response: finalResponse, updateType: 'ship' })
          if (error.errorCode || error.error) {
            responseErrors[get(result, 'payload.shipmentType', '')] = error.error
            if (String(error.errorCode) === '409') {
              dispatch(forceUpdate(true, error.error))
            }
          } else if (get(result, 'payload.shipmentType', '') !== 'manual') {
            trackingNo = finalResponse?.trackingNo
          }
        })
      } else {
        each(responseErrors, (value, key) => {
          responseErrors[key] = SHIPPING_ERROR
        })
      }

      if (!isEmpty(trackingNo)) {
        dispatch(
          getShippingLabel({
            poNumber: basicDetails.poNumber,
            trackingNumber: trackingNo,
            releaseNumber
          })
        )
      }

      if (values(responseErrors).filter(val => val === false).length !== 3) {
        throw new CustomError(SHIPPING_ERROR, responseErrors)
      } else {
        return responseErrors
      }
    })
    .catch(error => {
      if (error instanceof CustomError) {
        throw error
      } else {
        each(responseErrors, (value, key) => {
          responseErrors[key] = error.message
        })
        throw new CustomError(SHIPPING_ERROR, responseErrors)
      }
    })
}

/**
 * @description Action to download the shipping label
 * @param {string} poNumber
 * @param {string} trackingNumber
 * @param {string} release
 * @return {function(any, any): any}
 */
export const getShippingLabel = ({ poNumber, trackingNumber, releaseNumber }) => async (dispatch, getState) => {
  if (isEmpty(poNumber) || isEmpty(String(trackingNumber).trim()) || isEmpty(releaseNumber)) {
    dispatch(showHideLabelGenErrorPopup({ show: true }))
    return Promise.reject(
      dispatch({
        type: GET_SHIP_LABEL_FAILURE
      })
    )
  }
  const VENDOR_ID = getVendorIdFromState(getState)
  try {
    const response = await dispatch({
      [CALL_API]: {
        types: [GET_SHIP_LABEL_REQUEST, GET_SHIP_LABEL_SUCCESS, GET_SHIP_LABEL_FAILURE],
        endpoint: `${config.get(
          'printLabel'
        )}/${poNumber}/trackingNo/${trackingNumber}/releaseNo/${releaseNumber}/label/download?vendorId=${VENDOR_ID}`,
        method: 'GET',
        isPDF: true
      }
    })

    if (response?.response && Object.prototype.toString.call(response.response) === '[object ArrayBuffer]') {
      downloadPDF(response.response, `Shipping_Label_PO_${poNumber}_release_${releaseNumber}_${trackingNumber}.pdf`)
      dispatch(aiGetShippingLabel({ poNumber, trackingNumber, releaseNumber }))
      return Promise.resolve()
    }
    throw new CustomError('Invalid file', response?.response)
  } catch (error) {
    dispatch(
      aiTrackException({
        error,
        severityLevel: 3,
        name: 'ERROR_DOWNLOADING_SHIP_LABEL',
        params: {
          poNumber,
          trackingNumber,
          releaseNumber
        }
      })
    )
    dispatch(showHideLabelGenErrorPopup({ show: true }))
    return Promise.reject(
      dispatch({
        type: GET_SHIP_LABEL_FAILURE
      })
    )
  }
}

/**
 * @description Action to show the error popup if ship label download fails
 * @param {boolean} show
 * @return {function(*): Promise<unknown>}
 */
export const showHideLabelGenErrorPopup = ({ show }) => dispatch => {
  if (typeof show !== 'boolean') {
    return Promise.reject('Invalid parameter')
  }

  return Promise.resolve(
    dispatch({
      type: SHOW_HIDE_LABEL_GEN_ERROR_POPUP,
      payload: { show }
    })
  )
}

export const toggleEditPopup = itemNumber => dispatch => {
  return Promise.resolve(
    dispatch({
      type: TOGGLE_EDIT_POPUP,
      payload: itemNumber
    })
  )
}


/**
 * @description update force update flag true/false
 * @returns boolean
 */
export const forceUpdate = (toggle = false, errMessage = '') => dispatch => {
  return Promise.resolve(
    dispatch({
      type: FORCE_UPDATE,
      payload: { toggle, errMessage }
    })
  )
}
