export const FETCH_NETWORK_MANTIS_LEAD_TIME_REQUEST = 'FETCH_NETWORK_MANTIS_LEAD_TIME_REQUEST'
export const FETCH_NETWORK_MANTIS_LEAD_TIME_SUCCESS = 'FETCH_NETWORK_MANTIS_LEAD_TIME_SUCCESS'
export const FETCH_NETWORK_MANTIS_LEAD_TIME_FAILURE = 'FETCH_NETWORK_MANTIS_LEAD_TIME_FAILURE'

export const SET_NETWORK_MANTIS_LEAD_TIME_REQUEST = 'SET_NETWORK_MANTIS_LEAD_TIME_REQUEST'
export const SET_NETWORK_MANTIS_LEAD_TIME_SUCCESS = 'SET_NETWORK_MANTIS_LEAD_TIME_SUCCESS'
export const SET_NETWORK_MANTIS_LEAD_TIME_FAILURE = 'SET_NETWORK_MANTIS_LEAD_TIME_FAILURE'

export const FETCH_SHIP_NODE_MANTIS_LEAD_TIME_REQUEST = 'FETCH_SHIP_NODE_MANTIS_LEAD_TIME_REQUEST'
export const FETCH_SHIP_NODE_MANTIS_LEAD_TIME_SUCCESS = 'FETCH_SHIP_NODE_MANTIS_LEAD_TIME_SUCCESS'
export const FETCH_SHIP_NODE_MANTIS_LEAD_TIME_FAILURE = 'FETCH_SHIP_NODE_MANTIS_LEAD_TIME_FAILURE'

export const SET_SHIP_NODE_MANTIS_LEAD_TIME_REQUEST = 'SET_SHIP_NODE_MANTIS_LEAD_TIME_REQUEST'
export const SET_SHIP_NODE_MANTIS_LEAD_TIME_SUCCESS = 'SET_SHIP_NODE_MANTIS_LEAD_TIME_SUCCESS'
export const SET_SHIP_NODE_MANTIS_LEAD_TIME_FAILURE = 'SET_SHIP_NODE_MANTIS_LEAD_TIME_FAILURE'

export const CLEAR_SHIP_NODE = 'CLEAR_SHIP_NODE'
