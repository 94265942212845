export const SET_PRICING_SERVICE_COUNT = 'SET_PRICING_SERVICE_COUNT'
export const SET_FILTERS_OPEN = 'SET_FILTERS_OPEN'
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB'
export const SET_FILTERS_OPTIONS = 'SET_FILTERS_OPTIONS'
export const FETCH_FILTER_OPTIONS_REQUEST = 'FETCH_FILTER_OPTIONS_REQUEST'
export const FETCH_FILTER_OPTIONS_SUCCESS = 'FETCH_FILTER_OPTIONS_SUCCESS'
export const FETCH_FILTER_OPTIONS_FAILURE = 'FETCH_FILTER_OPTIONS_FAILURE'
export const DOWNLOAD_PS_REQUEST = 'DOWNLOAD_PS_REQUEST'
export const DOWNLOAD_PS_SUCCESS = 'DOWNLOAD_PS_SUCCESS'
export const DOWNLOAD_PS_FAILURE = 'DOWNLOAD_PS_FAILURE'
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE'
export const RESET_SELECTED_TAB = 'RESET_SELECTED_TAB'
export const RESET_SELECTED_DATE = 'RESET_SELECTED_DATE'
export const UPDATE_FILTERS = 'UPDATE_FILTERS'
export const RESET_FILTERS = 'RESET_FILTERS'
