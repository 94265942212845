import { TableCell, TableContainer, TableHead, TableRow, Table, TableBody } from '@mui/material'
import React from 'react'
import SSHSkeleton from './SSHSkeleton'

const totalRow = [1, 2, 3, 4, 5]
const SSHTableSkeleton = () => {

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            totalRow.map(col => {
                                return <TableCell key={col}>
                                    <SSHSkeleton animation="wave" />
                                </TableCell>
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        totalRow.map(row => {
                            return <TableRow key={row}>
                                {totalRow.map(col => {
                                    return <TableCell key={col}>
                                        <SSHSkeleton animation="wave" />
                                    </TableCell>
                                })}
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SSHTableSkeleton
