import React from 'react'
import { connect } from 'react-redux'
import { SSHTypography } from '../../components/SSHTypography'
import ClubRowTable from "../../pages/Inventory/Club/ClubRowTable"
import QDRowTable from '../../pages/Club/PricingService/QDs/RowTable'
import DealsRowTable from '../../pages/Club/PricingService/Deals/RowTable'
import RIPRowTable from '../../pages/Club/PricingService/RIPs/RowTable'
import { PRICING_SERVICES_TABS } from '../../pages/Club/PricingService/commonConstants'

const message = 'No records'

const internalKeys = ['inbounds', 'samsItemNumber'] //keys constant maitain in this array base on which it will render

const DetailRowTable = ({ row, selectedTab }) => {
    /**
     * @description render pricing services row template as per selected tab
     * @returns render detail row template
     */
    const renderPricingServicesRows = () => {
        switch (selectedTab?.value) {
            case PRICING_SERVICES_TABS[0].value:
                return <QDRowTable row={row} />
            case PRICING_SERVICES_TABS[1].value:
                return <DealsRowTable row={row} />
            case PRICING_SERVICES_TABS[2].value:
                return <RIPRowTable row={row} />
        }
    }
    /**
     * @description render template as per table
     * @param {*} row
     * @returns render detail row
     */
    const renderComponent = () => {
        const keys = Object.keys(row)

        const index = keys.filter(x => internalKeys.some(y => y === x))?.[0] //find which  table row detail renders as per column names

        switch (index) {
            case 'inbounds':
                return <ClubRowTable row={row} />
            case 'samsItemNumber':
                return renderPricingServicesRows()
            default:
                return <SSHTypography align='center'>{message}</SSHTypography>
        }
    }

    return (
        <>
            {renderComponent()}
        </>
    )
}

const mapStateToProps = state => ({
    selectedTab: state.ClubPricingServices.selectedTab
})


export default connect(mapStateToProps, null)(DetailRowTable)
