import { Box, Button } from '@mui/material'
import { withStyles } from '@mui/styles'
import clsx from 'clsx'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

export const SMART_INVENTORY_TABS = {
  DIVISION: 'Division',
  CATEGORIES: 'Categories',
  SUPPLIERS: 'Suppliers',
  ITEMS: 'Items',
  PAST_TRENDS: 'Past tends',
  SEARCH_LABEL_CATEGORY: 'Search category by name or id',
  SEARCH_LABEL_SUPPLIER: 'Search supplier by name or id',
  SEARCH_LABEL_ITEMS: 'Search item by name or id'
}

const styles = theme => ({
  button: {
    marginRight: theme.spacing(2.5),
    color: theme.rgba(theme.palette.primary.downloadLabelText, 0.8),
    fontWeight: 400,
    fontSize: theme.spacing(1.75),
    paddingBottom: theme.spacing(0.2),
    paddingLeft: 0,
    paddingRight: 0,
    display: 'block',
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    }
  },
  active: {
    color: theme.rgba(theme.palette.primary.blue, 0.8),
    borderBottom: `2px solid ${theme.palette.primary.blue}`
  },
  container: {
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.primary.headerBackground,
    marginBottom: '2px'
  },
  chip: { marginLeft: -16, marginRight: 8 }
})

const SmartInventoryTabSwitcher = ({ classes, isFetching, setActiveTab, selectedTab, isVendor }) => {
  const BTN_STYLES = tabView =>
    clsx(classes.button, {
      [classes.active]: tabView === selectedTab
    })
  return (
    <Box display="flex" justifyContent="flex-start" alignItems="flex-start" className={classes.container}>
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Button
            variant="text"
            className={BTN_STYLES(SMART_INVENTORY_TABS.DIVISION)}
            disabled={isFetching}
            onClick={() => setActiveTab({ tabName: SMART_INVENTORY_TABS.DIVISION })}>
            Divisions {selectedTab === SMART_INVENTORY_TABS.DIVISION}
          </Button>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Button
            variant="text"
            className={BTN_STYLES(SMART_INVENTORY_TABS.CATEGORIES)}
            disabled={isFetching}
            onClick={() => setActiveTab({ tabName: SMART_INVENTORY_TABS.CATEGORIES })}>
            Categories {selectedTab === SMART_INVENTORY_TABS.CATEGORIES}
          </Button>
        </Box>
      </Box>
      {!isVendor && (
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Button
              variant="text"
              className={BTN_STYLES(SMART_INVENTORY_TABS.SUPPLIERS)}
              disabled={isFetching}
              onClick={() => setActiveTab({ tabName: SMART_INVENTORY_TABS.SUPPLIERS })}>
              Suppliers {selectedTab === SMART_INVENTORY_TABS.SUPPLIERS}
            </Button>
          </Box>
        </Box>
      )}
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Button
            variant="text"
            className={BTN_STYLES(SMART_INVENTORY_TABS.ITEMS)}
            disabled={isFetching}
            onClick={() => setActiveTab({ tabName: SMART_INVENTORY_TABS.ITEMS })}>
            Items {selectedTab === SMART_INVENTORY_TABS.ITEMS}
          </Button>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Button
            variant="text"
            className={BTN_STYLES(SMART_INVENTORY_TABS.PAST_TRENDS)}
            disabled={isFetching}
            onClick={() => setActiveTab({ tabName: SMART_INVENTORY_TABS.PAST_TRENDS })}>
            Past trends {selectedTab === SMART_INVENTORY_TABS.PAST_TRENDS}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export const SmartInventoryTabSwitcherNaked = SmartInventoryTabSwitcher

SmartInventoryTabSwitcher.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired,
  isVendor: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  isVendor: state.UserInfo.isVendor
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SmartInventoryTabSwitcher))
