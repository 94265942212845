import React, { PureComponent } from 'react'
import { Grid, Slide, Typography, Box, IconButton } from '@mui/material'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { isEmpty } from 'lodash'
import OrderDemandClubTable from './OrderDemandClubTable'
import { ReactComponent as LeftArrowIcon } from '../../../../assets/images/leftArrow.svg'
import { SSHTypography } from '../../../../components/SSHTypography'
import { changePage } from '../../../../reduxSetup/commonActions/AppConfig'
import WeeklyDemandDrawer from '../../../../templates/Club/OrderDemand/WeeklyDemandDrawer'
import { COLORS } from '../../../../templates/Entry/globalMUIStyles'
import { pluralize } from '../../../../utils/common'
import SSHButton from '../../../../components/SSHButton'
import SSHNumberInput from '../../../../components/SSHNumberInput'
import { fetchOrderDemandClubs } from '../OrderDemandAction'
import { resetPagination } from '../../../../reduxSetup/commonActions/SSHPaginatedTable'
import SSHTooltip from '../../../../components/SSHTooltip'
import { ReactComponent as CloseIcon } from '../../../../assets/images/Trash.svg'

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.primary.headerBackground,
        padding: `${theme.spacing(0.5)} ${theme.spacing(3.2)}`,
        position: 'fixed',
        width: '97%',
        top: theme.spacing(5),
        zIndex: theme.zIndex.appBar + 1
    },
    subText: {
        marginLeft: theme.spacing(3),
        fontSize: theme.spacing(1.4),
        color: theme.palette.primary.softBlue
    },
    title: {
        color: theme.palette.primary.baseTextDark,
        fontWeight: '500',
        fontSize: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontSize: theme.spacing(1.75)
        }
    },
    panel: {
        background: theme.palette.primary.headerBackground,
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    input: {
        'background-color': '#fafafa',
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    },
    focus: {
        '& .Mui-focused': {
            'box-shadow': `0px 0px 0px 2px ${theme.palette.primary.blueLight}, 0px 0px 12px ${theme.rgba(
                theme.palette.primary.blueLight,
                0.75
            )}`
        }
    },
    headerTitle: { fontSize: theme.spacing(2.25), fontWeight: '400' },
    totalResults: { fontSize: theme.spacing(1.75), color: COLORS.mettalicBlue },
    clearSearch: {
        position: 'absolute',
        padding: theme.spacing(1.3),
        marginLeft: theme.spacing(-5),
    }
})

class OrderDemandClubDetails extends PureComponent {
    constructor(props) {
        super(props)
        this.state = { clubId: '' }
    }

    /**
     * @description on club id change
     * @param {*} e 
     */
    onClubIdChange = (e) => {
        const { value } = e.target
        this.setState({ clubId: value })
    }

    /**
     * @description club id search reset pagination
     * @param {*} itemId 
     * @param {*} clubId 
     */
    onClubIdSearch = async (itemId, clubId) => {
        const { fetchOrderDemandClubs, resetPagination } = this.props
        await resetPagination()
        await fetchOrderDemandClubs(itemId, clubId)
    }

    /**
     * @description club id reset search
     */
    onClearClick = () => {
        const { selectedItem } = this.props
        this.setState({ clubId: '' })
        this.onClubIdSearch(selectedItem.itemId, '')
    }

    render() {
        const { classes, selectedItem, changePage, totalRows } = this.props
        const { clubId } = this.state
        return (
            <Slide direction="up" in mountOnEnter unmountOnExit>
                <Grid container>
                    <Box className={classes.root}>
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item xs={8}>
                                <Typography variant="h6" className={classes.title}>
                                    <>
                                        <LeftArrowIcon onClick={() => changePage('/club/order-demand')} cursor={'pointer'} /> &nbsp;&nbsp;
                                        {selectedItem?.itemId}&nbsp;&nbsp;
                                    </>
                                </Typography>
                                <SSHTypography className={classes.subText}>{selectedItem?.itemDesc}</SSHTypography>
                            </Grid>
                            <Grid item xs={3}>
                                <SSHNumberInput
                                    size="small"
                                    variant="outlined"
                                    type="text"
                                    placeholder={'Search by Club Ids (Max 100 comma separated)'}
                                    onChange={this.onClubIdChange}
                                    className={clsx(classes.focus, classes.input)}
                                    value={clubId}
                                />
                                {!isEmpty(clubId) && (
                                    <SSHTooltip title="Click to clear search">
                                        <IconButton
                                            className={classes.clearSearch}
                                            aria-label="ClearSearch"
                                            onClick={this.onClearClick}
                                            edge="end">
                                            <CloseIcon />
                                        </IconButton>
                                    </SSHTooltip>
                                )}
                            </Grid>
                            <Grid item xs={1}>
                                <SSHButton variant="contained" disabled={!clubId} color="primary" size="medium" text="Apply" onClick={() => this.onClubIdSearch(selectedItem.itemId, clubId)} />
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid className={classes.panel} container spacing={2}>
                        <Grid item xs={6}>
                            <Typography className={classes.headerTitle}>Clubs</Typography>
                            <Typography className={classes.totalResults}>{`${totalRows || 0} ${pluralize('result', 'results', totalRows)}`}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <OrderDemandClubTable />
                    </Grid>
                    <WeeklyDemandDrawer />
                </Grid>
            </Slide>
        )
    }
}

export const OrderDemandClubDetailsNaked = OrderDemandClubDetails

OrderDemandClubDetails.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    selectedItem: state.ClubOrderDemand.selectedItem,
    totalRows: state.ClubOrderDemand.totalOrderDemandItems
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { changePage, fetchOrderDemandClubs, resetPagination },
        dispatch
    )
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrderDemandClubDetails))
