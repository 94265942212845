export const FETCH_RETURN_ORDER_REQUEST = 'FETCH_RETURN_ORDER_REQUEST'
export const FETCH_RETURN_ORDER_SUCCESS = 'FETCH_RETURN_ORDER_SUCCESS'
export const FETCH_RETURN_ORDER_FAILURE = 'FETCH_RETURN_ORDER_FAILURE'
export const SET_PENDING_ITEM_COUNT = 'SET_PENDING_ITEM_COUNT'
export const SET_ACKNOWLEDGE_ITEM_COUNT = 'SET_ACKNOWLEDGE_ITEM_COUNT'
export const SET_RETURN_TAB = 'SET_RETURN_TAB'
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'
export const SET_PENDING_ORDERS = 'SET_PENDING_ORDERS'
export const SET_ACKNOWLEDGE_ORDERS = 'SET_ACKNOWLEDGE_ORDERS'
export const ORDER_CARRIER_TRACKING_REQUEST = 'ORDER_CARRIER_TRACKING_REQUEST'
export const ORDER_CARRIER_TRACKING_SUCCESS = 'ORDER_CARRIER_TRACKING_SUCCESS'
export const ORDER_CARRIER_TRACKING_FAILURE = 'ORDER_CARRIER_TRACKING_FAILURE'
export const CLEAR_SEARCH_PARAMS = 'CLEAR_SEARCH_PARAMS'
export const SET_FILTER_OPEN = 'SET_FILTER_OPEN'
