import { cloneDeep } from 'lodash'
import { CLUB_MODULE_TYPES, VISIBLE_MODULE_TYPES } from '../../config/genericConstants'
import {
  OPEN_NOTIFICATION_DRAWER,
  CLOSE_NOTIFICATION_DRAWER,
  ADD_DOWNLOAD_REPORT,
  CLEAR_NOTIFICATION_COUNT,
  UPDATE_DOWNLOAD_REPORT_STATUS,
  DISABLE_DOWNLOAD,
  ENABLE_DOWNLOAD,
  DISABLE_PLAY
} from './NotificationDrawer.actionTypes'

export const INITIAL_STATE = {
  downloadReports: [],
  count: 0,
  play: false,
  donwloads: [],
  notificationDrawer: {
    open: false
  },
  disablePerfDownload: false,
  disableInventoryDownload: false,
  disableOrderDownload: false,
  disableSIDownload: false,
  disableLeadTimeDownload: false,
  disableSalesReportDownload: false,
  disableOpenReportDownload: false,
  disablePenaltyDownload: false,
  disableApprovalsDownload: false,
  disableInTransitInventoryDownload: false,
  disableSalesForecastDownload: false,
  disableOrderDemandDownload: false,
  disableClubOOS: false
}

export default (state = cloneDeep(INITIAL_STATE), action) => {
  const getDownloadState = (downloadModule, disableState) => {
    switch (downloadModule) {
      case VISIBLE_MODULE_TYPES.performance:
        return { disablePerfDownload: disableState }
      case VISIBLE_MODULE_TYPES.inventory:
      case CLUB_MODULE_TYPES.inventory:
        return { disableInventoryDownload: disableState }
      case VISIBLE_MODULE_TYPES.fulfilment:
        return { disableOrderDownload: disableState }
      case VISIBLE_MODULE_TYPES.leadTime:
        return { disableLeadTimeDownload: disableState }
      case VISIBLE_MODULE_TYPES.smartInventory:
        return { disableSIDownload: disableState }
      case VISIBLE_MODULE_TYPES.salesOrders:
        return { disableSalesReportDownload: disableState }
      case VISIBLE_MODULE_TYPES.openOrders:
        return { disableOpenReportDownload: disableState }
      case VISIBLE_MODULE_TYPES.penalty:
        return { disablePenaltyDownload: disableState }
      case VISIBLE_MODULE_TYPES.approvals:
        return { disableApprovalsDownload: disableState }
      case VISIBLE_MODULE_TYPES.inTransitInventory:
        return { disableInTransitInventoryDownload: disableState }
      case VISIBLE_MODULE_TYPES.clubSaleForecast:
        return { disableSalesForecastDownload: disableState }
      case VISIBLE_MODULE_TYPES.clubOrderDemand:
        return { disableOrderDemandDownload: disableState }
      case CLUB_MODULE_TYPES.salesForecast:
        return { disableSalesForecastDownload: disableState, disableOrderDemandDownload: disableState }
      case CLUB_MODULE_TYPES.oos:
        return { disableClubOOS: disableState }
      default:
        return {}
    }
  }

  switch (action.type) {
    case OPEN_NOTIFICATION_DRAWER: {
      return {
        ...state,
        notificationDrawer: { open: true }
      }
    }

    case CLOSE_NOTIFICATION_DRAWER: {
      return {
        ...state,
        notificationDrawer: { open: false }
      }
    }

    case ADD_DOWNLOAD_REPORT: {
      const index = state.downloadReports.findIndex(report => report.ackId === action.payload.report.ackId)
      if (index !== -1) {
        return {
          ...state,
          downloadReports: state.downloadReports.map(report => {
            if (report.ackId === action.payload.report.ackId) {
              const updatedReport = action.payload.report
              updatedReport.time = report.time
              return updatedReport
            }
            return report
          }),
          count:
            !['COMPLETED', 'FAILED'].includes(state.downloadReports[index].status) &&
              ['COMPLETED', 'FAILED'].includes(action.payload.report.status)
              ? state.count + 1
              : state.count,
          downloadBtnCtrl: action.payload.downloadBtnCtrl,
          ...getDownloadState(
            action.payload.downloadBtnCtrl,
            !['COMPLETED', 'FAILED'].includes(action.payload.report.status)
          ),
          play: ['COMPLETED', 'FAILED'].includes(action.payload.report.status)
        }
      }
      return {
        ...state,
        count: ['COMPLETED', 'FAILED'].includes(action.payload.report.status) ? state.count + 1 : state.count,
        downloadReports:
          state.downloadReports.length >= 10
            ? [action.payload.report, ...state.downloadReports].slice(0, 10)
            : [action.payload.report, ...state.downloadReports],
        downloadBtnCtrl: action.payload.downloadBtnCtrl,
        ...getDownloadState(
          action.payload.downloadBtnCtrl,
          !['COMPLETED', 'FAILED'].includes(action.payload.report.status)
        ),
        play: ['COMPLETED', 'FAILED'].includes(action.payload.report.status)
      }
    }

    case UPDATE_DOWNLOAD_REPORT_STATUS: {
      return {
        ...state,
        downloadReports: state.downloadReports.map(report =>
          report.ackId === action.payload.report.ackId ? action.payload.report : report
        )
      }
    }

    case CLEAR_NOTIFICATION_COUNT: {
      return {
        ...state,
        count: 0,
        play: false
      }
    }

    case DISABLE_DOWNLOAD: {
      const downloadModule = action.payload.downloadBtnCtrl
      return {
        ...state,
        ...getDownloadState(downloadModule, true)
      }
    }

    case ENABLE_DOWNLOAD: {
      const downloadModule = action.payload.downloadBtnCtrl
      return {
        ...state,
        ...getDownloadState(downloadModule, false)
      }
    }

    case DISABLE_PLAY: {
      return {
        ...state,
        play: false
      }
    }

    default:
      return { ...state }
  }
}
