/* eslint-disable react/require-default-props */
import { Box, Grid, TextField, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { SSHDivider } from '../../../../components/SSHDivider'
import SSHModal from '../../../../components/SSHModal'
import { SSHTypography } from '../../../../components/SSHTypography'
import PopupActions from '../../../../templates/PopupActions'

class EditItemQuantity extends Component {
  constructor(props) {
    super(props)
    this.input = React.createRef()
    this.state = {
      error: false,
      message: ''
    }
  }

  /**
   * @description validate cancel units
   */
  validateCancelUnits = () => {
    const { itemDetails } = this.props
    const cancelUnits = parseInt(this.input.current.value, 0)

    if (parseInt(itemDetails.currentfulfilmentQty, 0) < cancelUnits || cancelUnits === 0) {
      this.setState({
        error: true,
        message:
          cancelUnits === 0
            ? `Unit(s) should not 0`
            : `Unit(s) should not exceed ${itemDetails.currentfulfilmentQty}`
      })
    } else {
      this.setState({
        error: false,
        message: ''
      })
    }
  }

  /**
   * @description calculate remeaning to ship items base on editing calue
   */
  calculateRemeaningToShip = () => {
    const { itemDetails } = this.props

    const cancelUnits = !this.input.current || this.input.current.value === '' ? 0 : this.input.current.value

    return parseInt(itemDetails.currentfulfilmentQty, 0) - parseInt(cancelUnits, 0)
  }

  render = () => {
    const { open, onCancel, onConfirm, itemDetails } = this.props
    const { error, message } = this.state
    if (!open) return null

    return (
      <SSHModal
        title="Cancel item unit(s)"
        onClose={onCancel}
        size="sm"
        showDivider={false}
        actions={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <PopupActions
            cancelText="Cancel"
            disabled={!(this.input.current && this.input.current.value) || error}
            confirmText="Next"
            alignRight={true}
            onCancel={onCancel}
            onConfirm={() => onConfirm(this.input.current.value)}
          />
        }>
        <Grid container spacing={4} justifyContent="flex-start" alignItems='center'>
          <Grid item xs={5}>
            <SSHTypography variant="body2">
              Item name
            </SSHTypography>
          </Grid>
          <Grid item xs={7}>
            <SSHTypography variant="body2">
              {itemDetails.name}
            </SSHTypography>
          </Grid>
        </Grid>
        <Grid container spacing={4} justifyContent="flex-start" alignItems='center'>
          <Grid item xs={5}>
            <SSHTypography variant="body2">
              Ordered Units
            </SSHTypography>
          </Grid>
          <Grid item xs={7}>
            <SSHTypography variant="body2">
              {itemDetails.orignalfulfilmentQty}
            </SSHTypography>
          </Grid>
        </Grid>
        <Grid container spacing={4} justifyContent="flex-start" alignItems='center'>
          <Grid item xs={5}>
            <SSHTypography variant="body2">
              Shipped Units
            </SSHTypography>
          </Grid>
          <Grid item xs={7}>
            <SSHTypography variant="body2">
              {parseInt(itemDetails.shipped, 10)}
            </SSHTypography>
          </Grid>
        </Grid>
        <Grid container spacing={4} justifyContent="flex-start" alignItems='center'>
          <Grid item xs={5}>
            <SSHTypography variant="body2">
              Cancelled Units
            </SSHTypography>
          </Grid>
          <Grid item xs={7}>
            <SSHTypography variant="body2">
              {itemDetails.cancelled}
            </SSHTypography>
          </Grid>
        </Grid>
        <Grid container spacing={4} justifyContent="flex-start" alignItems='center'>
          <Grid item xs={5}>
            Units to Cancel
            <SSHTypography variant="caption" color="error">
              *
            </SSHTypography>
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="outlined-password-input"
              size="small"
              fullWidth
              type="number"
              variant="outlined"
              error={error}
              inputRef={this.input}
              inputProps={{ min: 1, max: 99 }}
              onChange={this.validateCancelUnits}
            />
            <Typography variant="body2" color="error">
              {' '}
              {error && Error}
              {message}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>max.{itemDetails.currentfulfilmentQty}</Typography>
          </Grid>
        </Grid>
        <Box py={2}>
          <SSHDivider />
        </Box>
        <Grid container spacing={4} justifyContent="flex-start" alignItems='center'>
          <Grid item xs={5}>
            <SSHTypography variant="body2">
              Remaining units to ship
            </SSHTypography>
          </Grid>
          <Grid item xs={7}>
            <SSHTypography variant="body2">
              {this.calculateRemeaningToShip()}
            </SSHTypography>
          </Grid>
        </Grid>
      </SSHModal>
    )
  }
}

EditItemQuantity.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  itemDetails: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    remainingToShip: PropTypes.number.isRequired,
    orderedUnits: PropTypes.number.isRequired
  })
}

export default EditItemQuantity
