export default class CustomError extends Error {
  /**
   * @param {string} message
   * @param {object} apiResponse
   * @param {number} [code = 0]
   */
  constructor(message, apiResponse) {
    super()

    /**
     * @type {string}
     * @readonly
     */
    this.message = message

    /**
     * @type {object}
     * @readonly
     */
    this.apiResponse = apiResponse

    /**
     * @type {string}
     * @readonly
     */
    this.name = this.constructor.name

    /**
     * @type {string}
     * @readonly
     */
    this.stack = CustomError.createStack(this)
  }

  /**
   * @param {CustomError} error
   * @return {string}
   * @private
   */
  static createStack(error) {
    return typeof Error.captureStackTrace === 'function'
      ? Error.captureStackTrace(error, error.constructor)
      : new Error().stack
  }

  /**
   * @return {string}
   */
  toString() {
    return this.getPrettyMessage()
  }

  /**
   * @return {string}
   */
  getPrettyMessage() {
    return `${this.message} Code: ${JSON.stringify(this.apiResponse)}.`
  }
}
