import { isEmpty } from 'lodash'
import moment from 'moment'
import config from '../../config'
import {
    FETCH_PAGINATED_TABLE_DATA_FAILURE,
    FETCH_PAGINATED_TABLE_DATA_REQUEST,
    FETCH_PAGINATED_TABLE_DATA_SUCCESS
} from '../../reduxSetup/commonActions/SSHPaginatedTable.actionTypes'
import {
    aiPenaltyOrdersFetch,
} from '../../reduxSetup/commonActions/TelemetryActionsNew'
import { CALL_API } from '../../reduxSetup/middleware/API'
import {
    SET_AR_ROW_COUNT,
    SET_SELECTED_ROWS_ID,
    UPDATE_AR_REQUEST,
    UPDATE_AR_SUCCESS,
    UPDATE_AR_FAILURE,
    SET_EDIT_MODE,
    SET_EDIT_VALUE,
    SET_UPDATING
} from './AccountReceivable.actionTypes'
import { RESOLVED, EDITED, WAIVER } from '../Approvals/commonConstants'
import { SET_MODULE_TYPE } from '../../reduxSetup/commonActions/AppConfig.actionTypes'
import { DISABLE_DOWNLOAD, ENABLE_DOWNLOAD } from '../../templates/NotificationDrawer/NotificationDrawer.actionTypes'
import { hideToastMessage, showToastMessage, changePage, searchVendor, selectVendor } from '../../reduxSetup/commonActions/AppConfig'
import {
    DOWNLOAD_PENALTY_REQUEST,
    DOWNLOAD_PENALTY_SUCCESS,
    DOWNLOAD_PENALTY_FAILURE
} from '../../pages/PenaltyProgram/PenaltyProgram.actionTypes'
import {
    downloadFile,
    getRouteNameFromURL,
    getVisibleModuleName
} from '../../utils/common'
import { ERROR_PENALTY_FILE_DOWNLOAD } from '../../config/errorConstants'
import { ROUTE_MODULES } from '../../config/genericConstants'

/**
 * @description Fetch list of cancelled/late items
 * @returns List of items
 */
export const fetchARLastMonthOrders = ({ sortColumn = '', sortType = '' } = {}) => (dispatch, getState) => {
    const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
    const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
    const { selectedARMonth, selectedSuppliers } = getState().Approvals
    const { iamToken } = getState().UserInfo.userDetails
    const { toDate, fromDate } = getARMonthwiseParam({ selectedARMonth })

    const PATH_URL = `?fromDate=${fromDate}&toDate=${toDate}&pageNo=${PAGE_NO + 1}&pageSize=${PAGE_SIZE}`

    if (isEmpty(sortColumn) && isEmpty(sortType)) {
        sortColumn = "executedPenalty"
        sortType = "asc"
    }

    return dispatch({
        [CALL_API]: {
            types: [
                FETCH_PAGINATED_TABLE_DATA_REQUEST,
                FETCH_PAGINATED_TABLE_DATA_SUCCESS,
                FETCH_PAGINATED_TABLE_DATA_FAILURE
            ],
            endpoint: `${config.get('accountReceivable')}/details${PATH_URL}`,
            method: 'POST',
            iamToken,
            payload: {
                pathName: getState().router.location.pathname,
                selectedApprovalsTab: getState().Approvals.selectedAdminTab,
                selectedARMonth
            },
            data: {
                "filters": [
                    {
                        "filterName": "supplierId",
                        "filterValues": selectedSuppliers
                    }
                ],
                "orderBy": sortType,
                "sortBy": sortColumn
            }

        }
    }).then(({ type, response }) => {
        dispatch(aiPenaltyOrdersFetch({ type, response }))
        if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS) {
            dispatch({
                type: SET_AR_ROW_COUNT,
                payload: parseInt(response?.payload?.totalCount, 10) || 0
            })
        }
        return Promise.resolve()
    })
}

/**
 * @description Fetch list of cancelled/late items
 * @returns List of items
 */
export const fetchAROlderMonthsOrders = ({ sortColumn = '', sortType = '' } = {}) => (dispatch, getState) => {
    const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
    const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
    const { selectedARMonth, selectedSuppliers, selectedStatusTab, selectedAdminTab } = getState().Approvals
    const { iamToken } = getState().UserInfo.userDetails
    const { toDate, fromDate } = getARMonthwiseParam({ selectedARMonth })

    // eslint-disable-next-line max-len
    const PATH_URL = `?fromDate=${fromDate}&toDate=${toDate}&isExecuted=${selectedStatusTab === RESOLVED}&pageNo=${PAGE_NO + 1}&pageSize=${PAGE_SIZE}`

    if (isEmpty(sortColumn) && isEmpty(sortType)) {
        sortColumn = "executedPenalty"
        sortType = "asc"
    }

    return dispatch({
        [CALL_API]: {
            types: [
                FETCH_PAGINATED_TABLE_DATA_REQUEST,
                FETCH_PAGINATED_TABLE_DATA_SUCCESS,
                FETCH_PAGINATED_TABLE_DATA_FAILURE
            ],
            endpoint: `${config.get('accountReceivable')}/tasks${PATH_URL}`,
            method: 'POST',
            iamToken,
            payload: {
                pathName: getState().router.location.pathname,
                selectedApprovalsTab: selectedAdminTab,
                selectedARMonth
            },
            data: {
                filters: [
                    {
                        filterName: "supplierId",
                        filterValues: selectedSuppliers
                    },
                    {
                        filterName: "status",
                        filterValues: []
                    }
                ],
                orderBy: sortType,
                sortBy: sortColumn
            }

        }
    }).then(({ type, response }) => {
        dispatch(aiPenaltyOrdersFetch({ type, response }))
        if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS) {
            dispatch({
                type: SET_AR_ROW_COUNT,
                payload: parseInt(response?.payload?.totalCount, 10) || 0
            })
        }
        return Promise.resolve()
    })
}

/**
 * @description Fetch list of cancelled/late items
 * @returns List of items
 */
export const updateAROlderMonthsOrders = ({ status, row, isMultiple }) => (dispatch, getState) => {
    const { selectedRows, editedValue } = getState().AccountReceivable
    const { iamToken, emailId, loginId } = getState().UserInfo.userDetails
    const reqObj = {
        status,
        updatedBy: emailId || loginId,
        taskId: isMultiple ? selectedRows.map(val => val.taskId) : [row.taskId]
    }
    if (!isMultiple) {
        reqObj.originalPenalty = row.calculatedPenalty;
        if (status === EDITED) {
            reqObj.updatePenalty = editedValue;
        }
    }
    dispatch({ type: SET_UPDATING, payload: true })
    return dispatch({
        [CALL_API]: {
            types: [UPDATE_AR_REQUEST, UPDATE_AR_SUCCESS, UPDATE_AR_FAILURE],
            endpoint: `${config.get('accountReceivable')}/tasks/action`,
            method: 'POST',
            iamToken,
            payload: {
                pathName: getState().router.location.pathname
            },
            data: reqObj
        }
    }).then(({ type, response }) => {
        dispatch({ type: SET_UPDATING, payload: false })
        if (type === UPDATE_AR_SUCCESS && response.payload?.isSuccess) {
            dispatch(setEditMode({ isEdit: false, editRow: {} }))
            const keyWord = (status === WAIVER) ? 'waived off' : 'updated'
            const successMessage = isMultiple ? `Selected penalties have been ${keyWord} successfully.` : `Penalty has been ${keyWord} successfully.`
            dispatch(showToastMessage({ message: successMessage }))
            setTimeout(() => {
                dispatch(hideToastMessage())
            }, 2000)
            dispatch(setSelectedRowId([], []))
            dispatch(fetchAROlderMonthsOrders())
        } else {
            dispatch(showToastMessage({ message: 'Selected Penalty update has failed. Please try again.' }))
        }
        return Promise.resolve()
    })
}

/**
 * @description account receivable filter
 * @param {*}  
 * @returns {fromDate, toDate, penaltyType}
 */
export const getARMonthwiseParam = ({ selectedARMonth }) => {
    const toDate = selectedARMonth?.value?.split(',')[1]
    const fromDate = selectedARMonth?.value?.split(',')[0]
    return {
        fromDate,
        toDate
    }
}

/**
 * @description set selected row Ids
 * @param {*}  
 * @returns {selected row Ids}
 */
export const setSelectedRowId = (selectedRowId, selectedRow) => (dispatch) => {
    dispatch({
        type: SET_SELECTED_ROWS_ID,
        payload: {
            selectedRowId, selectedRow
        }
    })
}

/**
 * @description penalty filter
 * @param {*} columnName, code
 * @returns navigates to the required page
 */
export const goToPenaltyDetails = ({ code, row }) => (dispatch, getState) => {
    let url = ROUTE_MODULES?.performance?.monthpenalty?.path + decodeURIComponent(code)
    return dispatch(searchVendor(row?.supplierId)).then(() => {
        dispatch(selectVendor(getState().AppConfig.vendor.vendorList[0]))
        dispatch(changePage(url))
    })
}

/**
 * @description download Penalty for AR
 * @param {*}  
 * @returns {selected row Ids}
 */
export const downloadPenalty = () => (dispatch, getState) => {
    const MODULE_TYPE = getRouteNameFromURL({ pathName: getState().router.location.pathname })
    const { selectedARMonth } = getState().Approvals
    const { toDate, fromDate } = getARMonthwiseParam({ selectedARMonth })
    const PATH_URL = `0?penaltyType=late,cancelled&fromDate=${fromDate}&toDate=${toDate}&isIncluded=true`

    dispatch({
        type: DISABLE_DOWNLOAD,
        payload: {
            downloadBtnCtrl: getVisibleModuleName(MODULE_TYPE)
        }
    })

    dispatch({
        type: SET_MODULE_TYPE,
        payload: {
            moduleType: MODULE_TYPE
        }
    })

    return dispatch({
        [CALL_API]: {
            types: [DOWNLOAD_PENALTY_REQUEST, DOWNLOAD_PENALTY_SUCCESS, DOWNLOAD_PENALTY_FAILURE],
            endpoint: `${config.get('penalty')}/orders/download/${PATH_URL}`,
            method: 'GET',
            isExcel: true
        }
    }).then(response => {
        if (response.type === DOWNLOAD_PENALTY_SUCCESS) {
            downloadFile({ data: response.response }, `AccountReceivable_${selectedARMonth?.name}${moment().unix()}`)
            dispatch(showToastMessage({ message: 'Penalty file downloaded successfully.' }))
            setTimeout(() => {
                dispatch(hideToastMessage())
            }, 2000)
        } else {
            dispatch(showToastMessage({ message: ERROR_PENALTY_FILE_DOWNLOAD }))
        }
        dispatch({
            type: ENABLE_DOWNLOAD,
            payload: {
                downloadBtnCtrl: getVisibleModuleName(MODULE_TYPE)
            }
        })
        return Promise.resolve()
    })
}

/**
 * @description Toggle edit mode for pending requests
 * @param {*} isEdit: Boolean
 */
export const setEditMode = ({ isEdit, editRow }) => dispatch => {
    dispatch({
        type: SET_EDIT_MODE,
        payload: { isEdit, editRow }
    })
}

/**
 * @description Set Edited Value on change 
 * @param {*} editedValue: string
 */
export const setEditedValue = (editedValue) => dispatch => {
    dispatch({
        type: SET_EDIT_VALUE,
        payload: editedValue
    })
}
