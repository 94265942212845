/* eslint-disable no-param-reassign */
import { cloneDeep, compact, each, filter, find, get, isArray, isEmpty, isEqual, without } from 'lodash'
import moment from 'moment'
import { APP_CONFIG } from '../config/appConfigs'
import {
  FILTER_KEYS,
  FULFILLMENT_TYPES,
  STATE_FILTER_KEYS,
  PATH_INFO,
  VISIBLE_MODULE_TYPES,
  API_MODULE_TYPES,
  COLUMNS_VISIBILITY_NAMES,
  yearFirstFormat,
  US_STATE_LIST,
  ROUTE_MODULES,
  dateFirstFormat
} from '../config/genericConstants'
import { SMART_INVENTORY_TABS } from '../pages/SmartInventory/SmartInventoryTabSwitcher'
import { MENU_ROUTES } from '../templates/LeftNav/AppRoutes'
import CustomError from './CustomError'
import generateUUID from './GenerateUUID'
import { getUpdateApiFilters, setApiFilterForIds, setApiFilterForSubcategoryNumbers } from './parsers'
import { HISTORY_TAB_TITLE, SUPPORTED_SOURCE } from '../config/genericConstantsNew'
import { PENALTY } from '../pages/Approvals/commonConstants'

const JAN_MONTH = 'Jan'
const FEB_MONTH = 'Feb'
const DEC_MONTH = 'Dec'
const NOV_MONTH = 'Nov'
const DATE_FORMAT = 'YYYY-MM-DD'

export const getAuthorizationCode = () => {
  const AUTHORIZATION_CODE = getQueryParams(window.location.search)
  if (!isEmpty(AUTHORIZATION_CODE)) return AUTHORIZATION_CODE.code
  return false
}

export const redirectToLogin = () => {
  window.location = getLoginURL()
  return Promise.resolve()
}

/**
 * This function is used to extract the GET parameters from a URL.
 * E.g.
 * getQueryParams("https://retaillink.login.stg.wal-mart.com/authorize?
 * responseType=code&clientId=cd55a546-d75e-4c31-abf4-e2ce11a59efb&scope=openId&redirectUri
 * =https%3A%2F%2Fvb.dev.cld.samsclub.com
 * &nonce=TQAGWOV2UV&state=AN67EX86ED&isInternal=true')
 * Will return
 * {
 *    responseType: "code"
 *    clientId: "cd55a546-d75e-4c31-abf4-e2ce11a59efb"
 *    scope: "openId"
 *    redirectUri: "https://vb.dev.cld.samsclub.com"
 *    nonce: "TQAGWOV2UV"
 *    state: "AN67EX86ED"
 *    isInternal: "true"
 * }
 * @param {String} url
 * @returns {Object} params
 */
export const getQueryParams = url => {
  const urlTemp = url.split('+').join(' ')

  const params = {}
  let tokens
  const re = /[?&]?([^=]+)=([^&]*)/g

  // eslint-disable-next-line no-cond-assign
  while ((tokens = re.exec(urlTemp))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2])
  }

  return params
}

/**
 * Generate a random string (nonce) of given length
 * @param {Number} length
 * @returns {string}
 */
export const generateRandomString = length => {
  let text = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  for (let i = 0; i < length; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

/**
 * Function used to generate the request headers that are sent to the REST API with every request
 *
 * @param {Object} store
 * @returns {{
 *  Authorization: string,
 *  'X-Session-Id': string,
 *  'X-Correlation-Id': string,
 *  userId: string
 * }}
 */
export const getRequestHeaders = (store) => {
  let headers = {}
  try {
    const userInfo = store.getState().UserInfo.userDetails
    const { type, selected } = store.getState().AppConfig.sourceType
    headers = {
      Authorization: userInfo.customerToken,
      userId: userInfo.loginId,
      'X-Session-Id': userInfo.sessionId,
      'sourceType': find(type, { 'id': selected })?.source
    }
    headers = {
      ...headers,
      'wm_consumer.id': process.env.REACT_APP_CONSUMER_ID,
      'wm_qos.correlation_id': generateUUID(),
      'wm_sec.auth_token': userInfo.iamToken,
      'Content-Type': 'application/json'
    }

  } catch (err) {
    headers = {
      Authorization: null,
      userId: null,
      'X-Session-Id': null,
      'wm_consumer.id': null,
      'wm_qos.correlation_id': null,
      'wm_sec.auth_token': null
    }
  }

  return headers
}

export const isProdEnvironment = () => {
  return process.env.REACT_APP_ENV === 'PROD'
}

export const isLocalEnvironment = () => {
  return process.env.REACT_APP_ENV === 'LOCAL'
}

export const getLoginURL = () => {
  const NONCE = generateRandomString(10)
  const STATE = generateRandomString(10)
  const RESPONSE_TYPE = 'code'
  const SCOPE = 'openId'

  return `${APP_CONFIG.ENV().retailLink
    }authorize?responseType=${RESPONSE_TYPE}&clientId=${APP_CONFIG.ENV().clientId}&scope=${SCOPE}&redirectUri=${encodeURIComponent(
      APP_CONFIG.ENV().host
    )}&nonce=${NONCE}&state=${STATE}${!isProdEnvironment() ? '&isInternal=true' : ''}`
}

/**
 * @desc Get Module access based on the sourceType(DSV/CLUB) selected
 * @param {Object} modules
 * @param {Boolean} isAdmin
 * @param {Array} userRoles
 * @param {Number} sourceSelected
 * @returns {Object} modules
 */
export const getUserModuleAccess = (modules, isAdmin, userRoles, sourceSelected, type) => {
  const pages = { ...modules }
  pages.search = true
  pages.dashboard = true

  const roles = userRoles.filter(x => String(x.type).toLowerCase() === String(type.find(z => z.id === sourceSelected)?.source).toLowerCase())?.[0]

  if (!isEmpty(roles)) { // if no roles selected then redirect to search page
    pages.feed = roles.appUserRoleInfo.features.indexOf('FM') > -1
    pages.fulfillment = roles.appUserRoleInfo.features.indexOf('FF') > -1
    pages.inventory = roles.appUserRoleInfo.features.indexOf('IM') > -1
    pages.performance = roles.appUserRoleInfo.features.indexOf('PM') > -1
    pages['lead-time-capacity'] = roles.appUserRoleInfo.features.indexOf('LT') > -1
    pages['download-history'] = true // Download section should be always enabled.
    pages['smart-inventory'] = isAdmin ? true : roles.appUserRoleInfo.features.indexOf('SI') > -1
    pages.reports = isAdmin ? true : roles.appUserRoleInfo.features.indexOf('PDR') > -1
    pages.penalty = isAdmin ? true : roles.appUserRoleInfo.features.indexOf('PPA') > -1
    pages['returns'] = roles.appUserRoleInfo.features.indexOf('RF') > -1
    pages['club/sales-forecast'] = isAdmin ? true : roles.appUserRoleInfo.features.indexOf('SFM') > -1
    pages['club/order-demand'] = isAdmin ? true : roles.appUserRoleInfo.features.indexOf('ODM') > -1
    pages['club/stockout'] = isAdmin ? true : roles.appUserRoleInfo.features.indexOf('OOS') > -1
    pages['in-transit-inventory'] = roles.appUserRoleInfo.features.indexOf('ITI') > -1
  }

  return pages
}

/**
 * @desc get Module configuration based on the sourceType(DSV/CLUB) selected
 * @param {Object} payload
 * @param {Number} sourceSelected (1: DSV, 2: CLUB)
 * @returns {Object} configuration
 */
export const getModuleConfiguration = (payload, sourceSelected) => {
  const sourceIndex = (sourceSelected === 2) ? 1 : 0
  return payload.find(val => val.type === SUPPORTED_SOURCE[sourceIndex])?.configuration
}

export const downloadFile = (response, fileName) => {
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName.endsWith('xlsx') || fileName.endsWith('.csv') ? fileName : `${fileName}.xlsx`)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const downloadTxtFile = (response, fileName) => {
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName.endsWith('txt') ? fileName : `${fileName}.txt`)
  document.body.appendChild(link)
  link.click()
}

export const downloadPDF = (response, fileName) => {
  const url = window.URL.createObjectURL(
    new Blob([response], {
      type: 'application/pdf'
    })
  )
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}`)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const getVendorIdFromState = getState => {
  let vendorId = null
  try {
    vendorId = getState().AppConfig.vendor.selectedVendor.vendorId
  } catch (e) {
    throw new CustomError('Problem with Vendor ID')
  }
  return vendorId
}

export const getShipNodeIdFromState = getState => {
  let shipNodeId = {}
  try {
    shipNodeId = getState().AppConfig.shipNodes.selected
  } catch (e) {
    throw new CustomError('Problem with shipNode ID')
  }
  return shipNodeId
}

export const getOrderTypeFromName = name => {
  return FULFILLMENT_TYPES.find(type => type.statusType === name)
}

/**
 *
 * @param {String} singular
 * @param {String} plural
 * @param {Number} count
 */
export const pluralize = (singular, plural, count) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(count)) return plural

  if (parseInt(count, 10) === 1) return singular
  return plural
}

/**
 *
 * @param {String} pathName
 * @returns {boolean|String}
 */
export const getRouteNameFromURL = ({ pathName }) => {
  let activeMenuItem = MENU_ROUTES.find(route => route.url === pathName)

  if (isEmpty(activeMenuItem)) {
    activeMenuItem = MENU_ROUTES.flatMap(item => item.nav).find(route => route.url === pathName)
  }


  if (isEmpty(activeMenuItem)) {
    switch (pathName) {
      case '/unauthorized':
        return 'Access Denied'
      case pathName.match(/^\/fulfillment\/vendor\//) ? pathName : undefined:
        return 'Order Details'
      default:
        return ''
    }
  }

  return activeMenuItem?.name
}

/**
 *
 * @param {Object} updatedRelease
 * @param {Object} originalRelease
 * @returns {[]}
 */
export const getReleaseItemChanges = ({ itemDetails, status }) => {
  const items = []

  // eslint-disable-next-line array-callback-return
  itemDetails.map(item => {
    const itemChanges = {
      primeLineNo: item.primeLineNo,
      subLineNo: item.subLineNo,
      fulfillmentLineNo: item?.fulfillmentLineNo,
      scac: item.scac,
      originalStatus: item.status,
      statusChange: status,
      originalQuantity: item.orignalfulfilmentQty,
      updateQuantity: item.currentfulfilmentQty,
      carrierServiceCode: item.carrierServiceCode,
      trackingNumber: '',
      upc: item.upcNumber
    }
    items.push(itemChanges)
  })

  return items
}

export const getItemPayloadForApi = (item, trackingNumber = null, carrierMethodId = null, scac = null) => {
  return {
    primeLineNo: item.primeLineNo,
    subLineNo: item.subLineNo,
    fulfillmentLineNo: item?.fulfillmentLineNo,
    scac: scac || item.scac,
    originalStatus: item.status,
    originalQuantity: item.currentfulfilmentQty,
    updateQuantity: item.updatedFulfillmentQty || item.currentfulfilmentQty,
    upc: item.upcNumber,
    carrierServiceCode: carrierMethodId || item.carrierServiceCode,
    statusChange: 'Shipped',
    trackingNumber: trackingNumber || ''
  }
}

/**
 *
 * @param {Array} itemList
 * @param {Object} errorObject
 * @returns {{}}
 */
export const getRelevantErrorTypesFromStatus = ({ itemList, errorObject }) => {
  if (isEmpty(errorObject)) return {}

  const newErrorObject = {}

  const applicableFields = {
    Pending: ['status'],
    Shipped: ['status', 'currentfulfilmentQty', 'carrierServiceCode', 'trackingNumber'],
    Acknowledged: ['status'],
    Cancelled: ['status', 'currentfulfilmentQty']
  }

  if (isEmpty(applicableFields) || !itemList) return newErrorObject

  itemList.forEach((item, index) => {
    if (applicableFields[item.status]) {
      applicableFields[item.status].forEach(field => {
        newErrorObject[field + index] = errorObject[field + index] === true
      })
    }
  })

  return newErrorObject
}

/**
 *
 * @param {Object} statusCode
 */
export const resetValueInPerformanceData = statusCode => {
  return each(statusCode, (data, key) => {
    statusCode[key].value = ''
    statusCode[key].change = '0.0'
    statusCode[key].changeFlag = 'NEUTRAL'
  })
}

/**
 *
 * @param {Object} statusCode
 * @returns {[]}
 */
export const convertStatusCodeObjectToArray = statusCode => {
  const statusCodeArr = []

  if (typeof statusCode !== 'object') return statusCodeArr

  each(statusCode, (data, key) => {
    statusCodeArr.push({ title: data, code: key, active: true })
  })
  return statusCodeArr
}

export const screenResolution = `${window.screen.availWidth * window.devicePixelRatio} x ${window.screen.availHeight *
  window.devicePixelRatio}`
export const getTelemetryProperties = getState => {
  return {
    userId: getState().UserInfo.userDetails.loginId,
    vendorId: getVendorIdFromState(getState),
    sessionId: getState().UserInfo.userDetails.sessionId,
    isVendor: getState().UserInfo.isVendor,
    isAdmin: getState().UserInfo.isAdmin,
    isSuperAdmin: getState().UserInfo.isSuperAdmin,
    screenResolution
  }
}

/**
 * @desc Compare updated release details with original details to decide whether there are unsaved changes
 * @param {Object} originalRelease
 * @param {Object} newRelease
 * @param {String} fieldName
 * @param {String} newValue
 * @param {Number} itemIndex
 */
export const unsavedChanges = ({ originalRelease, newRelease, fieldName, newValue, itemIndex }) => {
  const originalStatuses = originalRelease.itemDetails.map(item => item.status)
  const newStatuses = newRelease.itemDetails.map(item => item.status)

  if (fieldName === 'status') newStatuses[itemIndex] = newValue

  return !isEqual(newStatuses, originalStatuses)
}

/**
 * @description Convert a given string / decimal / integer to decimal with 2 places
 * @param {Number|String} value
 */
export const getDecimalValue = ({ value }) => {
  if (isEmpty(value)) return value

  if (typeof value === 'string') return parseFloat(value).toFixed(2)
  return value.toFixed(2)
}

/**
 * @desc Given a ship node name, return the city and state for a ship node.
 * @example 29507-ZINUS INC-San Francisco-CA will return San Francisco, CA
 * @param {String} shipNodeName
 * @returns {string}
 */
export const getShipNodeName = shipNodeName => {
  if (isEmpty(shipNodeName)) return ''
  return shipNodeName
    .split('-')
    .slice(2)
    .join(', ')
}

/**
 * @desc Given a task object, return the string to be displayed in admin table for ship node name
 * @param {String} shipNodeId
 * @param {String} shipNodeName
 */
export const getShipNodeNameWithId = ({ shipNodeId, shipNodeName }) => {
  if (isEmpty(shipNodeName)) return shipNodeId || ''

  let shipNodeNum = null
  try {
    // eslint-disable-next-line prefer-destructuring
    shipNodeNum = shipNodeId.split('_')[1]
  } catch (e) {
    shipNodeNum = shipNodeId
  }
  return `${getShipNodeName(shipNodeName)} (${shipNodeNum})`
}

/**
 * @desc Validate if a given string is a valid email address
 * @param {String} email
 * @returns {boolean}
 */
export const validateEmail = email => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

/**
 * @desc Given a task object, return the string to be displayed in admin table
 * @param {String} supplierId
 * @param {String} supplierName
 */
export const getSupplierName = ({ supplierId, supplierName }) => {
  if (isEmpty(supplierName)) return supplierId || ''

  return `${supplierName} (${supplierId})`
}

/**
 * @desc Given a task object, return the string to be displayed in admin table
 * @param {String} fromDate
 * @param {String} fromTo
 */
export const validateDateInMonths = (fromDate, toDate) => {
  const from = moment(fromDate)
  const to = moment(toDate)
  const months = Math.floor(to.diff(from, 'months', true)) + 1
  return Math.ceil(months) <= 6
}

/**
 * @desc Given a task object, return the string to be displayed in admin table
 * @param {String} fromDate
 * @param {String} fromTo
 */
export const validateSearch = ({ newOrderSearch }) => {
  const orderInfoLength = newOrderSearch.searchOrderType.filter(item => {
    return newOrderSearch[item.value] !== ''
  }).length

  const statusChecked = filter(newOrderSearch.orderStatus, { checked: true }, false)


  return !(
    (newOrderSearch.orderDateRange.from != null && newOrderSearch.orderDateRange.to != null) ||
    (newOrderSearch.shipDateRange.from != null && newOrderSearch.shipDateRange.to != null) ||
    orderInfoLength > 0 ||
    statusChecked.length > 0
  )

}

/**
 * @desc Get order search post object
 * @param {object} getState
 */
export const getOrderSearchParameter = getState => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId

  const ORDER_SEARCH = getState().OrderSearchReducer.orderSearch
  let shipDateRange = null
  let orderDateRange = null

  if (ORDER_SEARCH.shipDateRange.from && ORDER_SEARCH.shipDateRange.to) {
    shipDateRange = {
      fromDate: moment(ORDER_SEARCH.shipDateRange.from).format(DATE_FORMAT),
      toDate: moment(ORDER_SEARCH.shipDateRange.to).format(DATE_FORMAT)
    }
  }

  if (ORDER_SEARCH.orderDateRange.from && ORDER_SEARCH.orderDateRange.to) {
    orderDateRange = {
      fromDate: moment(ORDER_SEARCH.orderDateRange.from).format(DATE_FORMAT),
      toDate: moment(ORDER_SEARCH.orderDateRange.to).format(DATE_FORMAT)
    }
  }

  const orderType = find(cloneDeep(FULFILLMENT_TYPES), { statusType: 'Active' })

  const status = filter(cloneDeep(ORDER_SEARCH.orderStatus), { checked: true })

  orderType.statusType = []

  status.forEach(item => {
    return orderType.statusType.push(`${item.name}`)
  })

  if (status.length === 0) {
    const ORDER_TYPE = getState().FulfillmentNew.selectedOrderType
    orderType.statusType.push(ORDER_TYPE)
  }

  return {
    vendorId: String(VENDOR_ID),
    shipNodeId: SHIP_NODE_ID,
    status: orderType.statusType || [],
    itemNumber: ORDER_SEARCH.itemNumber,
    vendorStockId: ORDER_SEARCH.vendorStockId,
    legacyPoNo: ORDER_SEARCH.legacyPoNo,
    poNumber: ORDER_SEARCH.poNumber,
    salesOrderNo: ORDER_SEARCH.salesOrderNo,
    orderDateRange,
    shipDateRange
  }
}

/**
 * @desc Get order type object
 * @param {object} orderStatus
 * return {object}
 */
export const getOrderType = (orderStatus, selectedOrderType) => {
  const orderType = find(cloneDeep(FULFILLMENT_TYPES), { statusType: selectedOrderType })

  const status = filter(cloneDeep(orderStatus), { checked: true })
  orderType.statusType = []

  status.forEach(item => {
    return orderType.statusType.push(`${item.name}`)
  })

  return orderType
}

/**
 * @desc clear order search parameter and reset to default
 * @param {object} orderSearch
 * return {object}
 */
export const clearOrderSearchValue = orderSearch => {
  const clearOrderSearch = orderSearch
  orderSearch.searchOrderType.map(item => {
    clearOrderSearch[item.value] = ''
    return clearOrderSearch
  })

  orderSearch.orderStatus.map(() => {
    clearOrderSearch.orderStatus.checked = false
    return clearOrderSearch
  })

  orderSearch.orderStatus.map(name => {
    name.checked = false
    return name
  })

  clearOrderSearch.orderDateRange.from = null
  clearOrderSearch.orderDateRange.to = null
  clearOrderSearch.shipDateRange.from = null
  clearOrderSearch.shipDateRange.to = null
  clearOrderSearch.searchParamCount = 0
  clearOrderSearch.orderDate = false
  clearOrderSearch.shipDate = false
  clearOrderSearch.inValidOrderDate = false
  clearOrderSearch.inValidShipDate = false
  clearOrderSearch.sortByParameter = 'orderDate'
  clearOrderSearch.sortType = 'asc'
  clearOrderSearch.search = true

  return clearOrderSearch
}

/**
 * @description allow access check for permission for feature is allowed or not
 * @param {string} action
 */
export const allowAccess = action => {
  // eslint-disable-next-line global-require
  const store = require('../reduxSetup/store') // dynamic import of store
  let PATH_NAME = store.default.getState().router.location.pathname
  const PERMISSION = store.default.getState().UserInfo.permissions
  const IS_VENDOR = store.default.getState().UserInfo.isVendor

  if (PATH_NAME === '/') {
    // fake call for testing
    return true
  }

  if (IS_VENDOR) {
    return true
  }

  if (PATH_NAME.search(/fulfillment\/vendor/i) === 1) {
    PATH_NAME = PATH_NAME.substr(0, 12)
  }
  if (PATH_NAME.search(/returns\/vendor/i) === 1) {
    PATH_NAME = PATH_NAME.substr(0, 8)
  }
  if (PATH_NAME.search(/in-transit-inventory\/vendor/i) === 1) {
    PATH_NAME = PATH_NAME.substr(0, 21)
  }
  const PATH = PERMISSION[PATH_NAME]

  if (isEmpty(PATH)) {
    return false
  }

  return PATH.includes(action.toLocaleLowerCase())
}

/**
 * @description Capitalize the first alphabet of every word in a sentence
 * @param {string} sentence
 * @return {string}
 */
export const capitalizeSentence = sentence => {
  if (typeof sentence !== 'string') return sentence

  return sentence
    .split(' ')
    .map(word => {
      if (word.length > 0) {
        return word[0].toUpperCase() + word.substring(1).toLowerCase()
      }
      return ''
    })
    .join(' ')
}

/**
 * @description
 * @param {string} dataType - vendor|shipNode|poNumber
 */
export const getDataFromURL = dataType => {
  const url = window.location.href
  let regex
  switch (dataType) {
    case 'vendor':
      regex = /.+\/vendor\/(\d+)\/.*/
      break
    case 'shipNode':
      // eslint-disable-next-line no-useless-escape
      regex = /.*\/shipNode\/([\d\_]+)\/.*/
      break
    case 'poNumber':
    case 'order':
      regex = /.*\/order\/(\d+)$/
      break
    default:
      return false
  }
  const matchedValue = url.replace(regex, '$1')
  if (matchedValue && matchedValue.length > 0 && matchedValue !== url) return matchedValue

  return false
}

/**
 * @description
 * @param order
 * @return {null|*}
 */
export const parseItemsInOrder = order => {
  if (isEmpty(order)) return null

  order.poReleaseList.forEach(release => {
    const itemOrderedQty = {}
    release.itemDetails.forEach(item => {
      if (itemOrderedQty[item.primeLineNo]) itemOrderedQty[item.primeLineNo] += parseInt(item.orignalfulfilmentQty, 10)
      else itemOrderedQty[item.primeLineNo] = parseInt(item.orignalfulfilmentQty, 10)
    })
    release.itemDetails.map(item => ({
      ...item,
      updatedFulfillmentQty: item.currentfulfilmentQty,
      totalOrdered: itemOrderedQty[item.primeLineNo]
    }))
  })
  return order
}

/**
 * @description Check when a given object is of type Error
 * @param {object} object
 * @return {boolean}
 */
export const isError = object => {
  return object instanceof Error
}

/**
 * @description Use this function to parse the error response received from API while updating a release
 * @param {object} response
 * @param {string} updateType - either "ship" / "cancel"
 * @return {{errorCode: string|null, error: string|null}}
 */
export const parseReleaseUpdateError = ({ response, updateType }) => {
  let genericError = 'Error occurred while trying to '
  genericError += updateType === 'ship' ? 'ship items' : 'modify quantity'

  let errorCode = get(response, 'payload[0].errorCode', null)
  let errorMessage = get(response, 'payload[0].errorMessage', null)
  const globalErrorCode = get(response, 'error.errorCode', null)
  const globalErrorMessage = get(response, 'error.errorMessage', null)

  if (
    (get(response, 'apiResponse', null) && get(response, 'message', '').includes('NetworkError')) ||
    get(response, 'message', '').includes('Network Error')
  ) {
    errorCode = get(response, 'apiResponse.response.status', 0)
    errorMessage = get(response, 'apiResponse.response.statusText', 'An error has occurred')
  }

  const hasError =
    [errorCode, globalErrorCode, errorMessage, globalErrorMessage].filter(val => !['', null, undefined].includes(val))
      .length > 0
  if (!hasError) return { error: null, errorCode: null }

  if (errorCode || errorMessage) genericError = `ERROR: ${compact([errorCode, errorMessage]).join(' - ')}`
  else if (globalErrorCode || globalErrorMessage) {
    genericError = `ERROR: ${compact([globalErrorCode, globalErrorMessage]).join(' - ')}`
  }

  return { error: genericError, errorCode: errorCode || globalErrorCode }
}

export const readBinaryStringFromArrayBuffer = (arrayBuffer, onSuccess, onFail) => {
  const reader = new FileReader()
  reader.onload = event => {
    onSuccess(event.target.result)
  }
  reader.onerror = event => {
    onFail(event.target.error)
  }
  reader.readAsBinaryString(new Blob([arrayBuffer], { type: 'application/octet-stream' }))
}

/**
 * @description
 * @param order
 * @return {null|*}
 */

export const parseMantisShipNode = shipNodes => {
  if (isEmpty(shipNodes)) {
    return []
  }

  const newShipNode = []
  if (isEmpty(shipNodes)) {
    return newShipNode
  }

  shipNodes.map(shipNode => {
    return newShipNode.push({
      shipNodeName: shipNode?.shipNodeName,
      shipNodeId: shipNode?.shipNodeId,
      enabled: shipNode.enabled
    })
  })
  return newShipNode
}

/**
 * @description legacy po number array will loop and shown on order details page
 * @param {*} object
 * @param {string}
 * @returns array po number
 */
export const getLegacyPOList = ({ order, prefixName = '' }) => {
  let poList = order.poReleaseList.map(item => {
    if (item.basicDetails.legacyPONumber) {
      return `${prefixName} ${item.basicDetails.legacyPONumber}`
    }
  })
  poList = without(poList, undefined)
  return poList.length > 0 ? poList : 'PO#'
}

/**
 * @param {string} stringPhrase String to display with {index} format
 * @param  {Array} args All the values which needs to be inserted in the formatted string
 * @returns New string with joined params. Eg: "(Some string with {0}, passedParam)" this will be converted to
 * "Some string with passedParam"
 */
export const formatStringWithParams = (stringPhrase, ...args) => {
  return stringPhrase.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] !== 'undefined' ? args[number] : match
  })
}

/**
 * @param  {Object} allCategoriesData All categories data alongwith corresponding subcategories data
 * @returns New Array which conatins data of all subcategories
 */
export const getAllSubcategoriesFromAllCategories = allCategoriesData => {
  const subcategoriesData = []
  if (isArray(allCategoriesData)) {
    allCategoriesData.forEach(category =>
      category?.subcategories?.forEach(subcategory => subcategoriesData.push(subcategory))
    )
  }
  return subcategoriesData
}

export const shouldApplyResetFilter = (apiRequestFilters, alertType) => {
  return alertType.value !== ''
}

export const prepareSmartInventoryApiRequest = (filters, apiRequestFilters, isVendor, vendorId, selectedTab) => {
  const allColumns = filters.allColumns
    ? filters.allColumns.filter(column => column.active === true).map(s => s.visibility)
    : []

  if (
    selectedTab === SMART_INVENTORY_TABS.DIVISION ||
    allColumns.includes(STATE_FILTER_KEYS.DIVISION) ||
    selectedTab === SMART_INVENTORY_TABS.PAST_TRENDS
  ) {
    getUpdateApiFilters(STATE_FILTER_KEYS.DIVISION, FILTER_KEYS.DIVISION, filters, apiRequestFilters)
  }
  if (
    selectedTab === SMART_INVENTORY_TABS.CATEGORIES ||
    allColumns.includes(STATE_FILTER_KEYS.CATEGORY) ||
    selectedTab === SMART_INVENTORY_TABS.PAST_TRENDS
  ) {
    getUpdateApiFilters(STATE_FILTER_KEYS.CATEGORY, FILTER_KEYS.CATEGORY, filters, apiRequestFilters)
    setApiFilterForIds(STATE_FILTER_KEYS.CATEGORY, FILTER_KEYS.CATEGORY_ID, filters, apiRequestFilters)
  }

  if (allColumns.includes(STATE_FILTER_KEYS.SUBCATEGORY) || selectedTab === SMART_INVENTORY_TABS.PAST_TRENDS) {
    getUpdateApiFilters(STATE_FILTER_KEYS.SUBCATEGORY, FILTER_KEYS.SUBCATEGORY, filters, apiRequestFilters)
    setApiFilterForSubcategoryNumbers(filters, apiRequestFilters)
  }

  if (
    selectedTab === SMART_INVENTORY_TABS.SUPPLIERS ||
    allColumns.includes(STATE_FILTER_KEYS.SUPPLIER) ||
    selectedTab === SMART_INVENTORY_TABS.PAST_TRENDS
  ) {
    getUpdateApiFilters(
      STATE_FILTER_KEYS.SUPPLIER,
      FILTER_KEYS.SUPPLIER_ID,
      filters,
      apiRequestFilters,
      isVendor,
      vendorId
    )
  }

  if (selectedTab === SMART_INVENTORY_TABS.PAST_TRENDS && isVendor) {
    apiRequestFilters = apiRequestFilters?.map(filter => {
      if (filter.filterName === 'supplierId') {
        return {
          filterName: filter.filterName,
          filterValues: [vendorId]
        }
      }
      return filter
    })
  }
  return apiRequestFilters
}
/**
 * @description get height
 * @param {string} pathName
 */
export const getHeight = () => {
  const store = require('../reduxSetup/store') // dynamic import of store
  const PATH_NAME = store.default.getState().router.location.pathname
  const FULFILMENT_TAB = store.default.getState().FulfillmentNew.selectedTabView
  const PENALTY_TAB = store.default.getState().PenaltyProgram.selectedTab
  const ADMIN_TAB = store.default.getState().Approvals.selectedAdminTab

  let HEIGHT = 240

  switch (PATH_NAME) {
    case '/inventory':
    case ROUTE_MODULES?.inventorypricing?.pricingservice?.path: {
      HEIGHT = 272
      break
    }
    case '/fulfillment': {
      if (FULFILMENT_TAB === 'Closed') {
        HEIGHT = 279
        break
      }
      HEIGHT = 400
      break
    }
    case '/returns': {
      HEIGHT = 400
      break
    }
    case '/smart-inventory': {
      HEIGHT = 354
      break
    }
    case ROUTE_MODULES?.toolsreporting?.approvals?.path:
    case ROUTE_MODULES?.toolsreporting?.leadtimemanagement?.path: {
      if (ADMIN_TAB === PENALTY) {
        HEIGHT = 276
        break
      }
      HEIGHT = 230
      break
    }
    case '/penalty': {
      if (PENALTY_TAB?.title === HISTORY_TAB_TITLE) {
        HEIGHT = 318
        break
      }
      HEIGHT = 520
      break
    }
    case '/in-transit-inventory':{
      HEIGHT = 239
      break
    }
    case '/lead-time-capacity':
      HEIGHT = 222
      break
    default: {
      return HEIGHT
    }
  }
  return HEIGHT
}

/**
 * @description get path info
 * @param {string} pathName
 */
export const getPathInfo = pathName => {
  switch (pathName) {
    case '/inventory':
      return PATH_INFO.inventory
    case '/feed':
      return PATH_INFO.feed
    case '/admin/feed':
      return PATH_INFO.adminFeed
    case '/smart-inventory':
      return PATH_INFO.si
    case '/download-history':
      return PATH_INFO.download
    case '/admin/approvals':
      return PATH_INFO.approvals
    case '/admin/mantis-lead-time':
      return PATH_INFO.leadTimeMgt
    case '/supplier-mgt':
      return PATH_INFO.supplier
    case '/penalty':
      return PATH_INFO.penalty
    case '/returns':
      return PATH_INFO.returns
    case '/in-transit-inventory':
      return PATH_INFO.inTransitInventory
    default:
      return ''
  }
}

export const getVisibleModuleName = moduleName => {
  switch (moduleName) {
    case 'Performance':
      return VISIBLE_MODULE_TYPES.performance
    case 'Inventory':
      return VISIBLE_MODULE_TYPES.inventory
    case 'Fulfillment':
      return VISIBLE_MODULE_TYPES.fulfilment
    case 'Smart inventory':
      return VISIBLE_MODULE_TYPES.smartInventory
    case 'Reports':
      return 'Reports'
    case API_MODULE_TYPES.leadTime:
      return VISIBLE_MODULE_TYPES.leadTime
    case API_MODULE_TYPES.salesOrders:
      return VISIBLE_MODULE_TYPES.salesOrders
    case API_MODULE_TYPES.openOrders:
      return VISIBLE_MODULE_TYPES.openOrders
    case 'Monthly penalties': // check this
      return VISIBLE_MODULE_TYPES.penalty
    case ROUTE_MODULES.toolsreporting?.approvals?.name:
      return VISIBLE_MODULE_TYPES.approvals
    case ROUTE_MODULES.forecasting?.clubsaleforecast?.name:
      return VISIBLE_MODULE_TYPES.clubSaleForecast
    case ROUTE_MODULES.forecasting?.cluborderdemand?.name:
      return VISIBLE_MODULE_TYPES.clubOrderDemand
    default:
      return ''
  }
}

/**
 *
 * @param {Map} map of any
 * @returns array of all the values in the map
 */
export const getValuesFromMap = map => {
  const valueArray = []
  const itr = map.values()
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < map.size; i++) {
    valueArray.push(...itr.next().value)
  }
  return valueArray
}

/**
 * @description sett columns name in column visiblity dropdown
 * @returns array of column names
 */
export const setColumnsNames = (selectedTab, isSupplier = false) => {
  const visibilityColumns = cloneDeep(COLUMNS_VISIBILITY_NAMES[selectedTab])
  if (isSupplier && selectedTab === SMART_INVENTORY_TABS.ITEMS) {
    visibilityColumns.splice(-1)
  }
  return visibilityColumns
}

/**
 *
 * @param {string} selectedTab currect smart inventory tab
 * @returns boolean
 */
export const isAggregatedCall = selectedTab => {
  return [SMART_INVENTORY_TABS.DIVISION, SMART_INVENTORY_TABS.CATEGORIES, SMART_INVENTORY_TABS.SUPPLIERS].includes(
    selectedTab
  )
}

/**
 * Previous two months penalty should be available
 * @returns Array of tab titles
 */
export const getPenaltyTabs = () => {
  const tabs = []
  const monthMinusOne = moment()
    .subtract(1, 'months')
    .format('MMM')
  const monthMinusTwo = moment()
    .subtract(2, 'months')
    .format('MMM')
  const tabOneYear =
    (monthMinusOne === DEC_MONTH || monthMinusOne === JAN_MONTH)
      ? moment().format('YY')
      : moment()
        .add(1, 'years')
        .format('YY')

  const tabTwoYear =
    (monthMinusTwo === NOV_MONTH || monthMinusTwo === DEC_MONTH || monthMinusTwo === JAN_MONTH)
      ? moment().format('YY')
      : moment()
        .add(1, 'years')
        .format('YY')

  tabs.push({
    title: `${monthMinusOne}-FY'${tabOneYear}`, value: `${moment()
      .subtract(1, 'months')
      .startOf('month')
      .format(yearFirstFormat)},${moment()
        .subtract(1, 'months')
        .endOf('month')
        .format(yearFirstFormat)}`
  })
  tabs.push({
    title: `${monthMinusTwo}-FY'${tabTwoYear}`, value: `${moment()
      .subtract(2, 'months')
      .startOf('month')
      .format(yearFirstFormat)},${moment()
        .subtract(2, 'months')
        .endOf('month')
        .format(yearFirstFormat)}`
  })

  const currentFY =
    moment().format('MMM') === JAN_MONTH
      ? moment().format('YY')
      : moment()
        .add(1, 'years')
        .format('YY')

  const startcurrentFy = (moment().format('MMM') === JAN_MONTH || moment().format('MMM') === FEB_MONTH)
    ? moment().format('YY')
    : moment()
      .add(1, 'years')
      .format('YY')
  const currentMonth = moment().format('MMM')

  tabs.push({
    title: `Feb-FY'${startcurrentFy} to ${currentMonth}-FY'${currentFY}`, value: `${moment()
      .subtract(1, 'months')
      .startOf('month')
      .format(yearFirstFormat)},${moment()
        .subtract(1, 'months')
        .endOf('month')
        .format(yearFirstFormat)}`
  })
  return tabs
}

/**
 * Previous two months penalty should be available
 * @returns Array of tab titles
 */
export const getARMonthValues = () => {
  const tabs = []
  const monthMinusOne = moment()
    .subtract(1, 'months')
    .format('MMM')
  const monthMinusTwo = moment()
    .subtract(2, 'months')
    .format('MMM')
  const tabOneYear =
    (monthMinusOne === DEC_MONTH || monthMinusOne === JAN_MONTH)
      ? moment().format('YY')
      : moment()
        .add(1, 'years')
        .format('YY')

  const tabTwoYear =
    (monthMinusTwo === NOV_MONTH || monthMinusTwo === DEC_MONTH || monthMinusTwo === JAN_MONTH)
      ? moment().format('YY')
      : moment()
        .add(1, 'years')
        .format('YY')

  tabs.push({
    name: `${monthMinusOne} FY'${tabOneYear}`, value: `${moment()
      .subtract(1, 'months')
      .startOf('month')
      .format(yearFirstFormat)},${moment()
        .subtract(1, 'months')
        .endOf('month')
        .format(yearFirstFormat)}`, id: 1
  })

  tabs.push({
    name: `${monthMinusTwo} FY'${tabTwoYear}`, value: `${moment()
      .subtract(2, 'months')
      .startOf('month')
      .format(yearFirstFormat)},${moment()
        .subtract(2, 'months')
        .endOf('month')
        .format(yearFirstFormat)}`, id: 2
  })

  tabs.push({
    name: 'Older penalties', value: `${moment()
      .subtract(7, 'months')
      .startOf('month')
      .format(yearFirstFormat)},${moment()
        .subtract(2, 'months')
        .endOf('month')
        .format(yearFirstFormat)}`, id: 3
  })
  return tabs
}

export const getPreviousTwoMonthsDate = () => {
  const dateBeforeTwoMonths = moment().subtract(2, 'months')
  const fromDate1 = dateBeforeTwoMonths.startOf('month').format(yearFirstFormat)
  const toDate1 = dateBeforeTwoMonths.endOf('month').format(yearFirstFormat)

  const dateBeforeOneMonths = moment().subtract(1, 'months')
  const fromDate2 = dateBeforeOneMonths.startOf('month').format(yearFirstFormat)
  const toDate2 = dateBeforeOneMonths.endOf('month').format(yearFirstFormat)

  const fromDate3 = (moment().format('MMM') === JAN_MONTH || moment().format('MMM') === FEB_MONTH)
    ? moment()
      .subtract(1, 'years')
      .startOf('year')
      .add(1, 'months')
      .startOf('month')
      .format(DATE_FORMAT)
    : moment()
      .startOf('year')
      .add(1, 'months')
      .startOf('month')
      .format(DATE_FORMAT)
  const toDate3 = moment().format(yearFirstFormat)

  return [
    { fromDate: fromDate2, toDate: toDate2 },
    { fromDate: fromDate1, toDate: toDate1 },
    { fromDate: fromDate3, toDate: toDate3 }
  ]
}

export const formatPhoneNumber = (value) => {
  value = value?.split('-').join('')
  const len = value.length
  if (!isNaN(value) && len <= 10) {
    if (len < 7) {
      value = value.replace(/(\d{3})(\d{1,3})/, "$1-$2");
    } else {
      value = value.replace(/(\d{3})(\d{3})(\d{1,4})/, "$1-$2-$3");
    }
    return value
  } else if (isNaN(value) && len === 1) {
    return ""
  }
  return false
}

/**
* To show the state name or - in case of null
* @param {string} value(state code)
* @returns value as it is or - in case of null
*/
export const displayStateNameFromCode = (value) => {
  const stateObj = US_STATE_LIST.find(obj => value === obj.value)
  return stateObj?.name || '-'
}

/**
 * @description get date format into string
 * @param {*} dateValue 
 * @returns string MM/DD/YYYY
 */
export const getDateFromString = (dateValue) => {
  const stringDate = dateValue.split('-')
  if (isArray(stringDate) && stringDate.length >= 3) {
    return `${stringDate?.[1]}/${String(stringDate?.[2]).substring(0, 2)}/${stringDate?.[0]}`
  }
  return '-'
}

/**
 * @description display date from today
 * @param {days} {dateFormat}
 * @returns date in {dateFormat}
 */
export const displayDateFromToday = (days, dateFormat) => {
  const currentDate = new Date()
  return moment(currentDate.setDate(currentDate.getDate() + days)).format(dateFormat)
}

/**
 * @description get saturday for upcoming week 
 * @param {*} dateFormat 
 * @returns  {date}
 */
export const getNextSatuday = (dateFormat) => {
  const currentDate = new Date()
  const currentDay = currentDate.getDay()
  let saturday = null
  if (currentDay != 6) {
    saturday = currentDate.getDate() + (6 - currentDay)
  }
  else {
    saturday = currentDay
  }
  return `${moment(currentDate.setDate(saturday)).format(dateFormat)} 
           to ${moment(currentDate.setDate(saturday + 112 - 1)).format(dateFormat)}`
}

/**
 * @description display past weekdays from yesterday
 * @param {days} {number}
 * @returns date in {dateFormat}
 */
export const displayPastWeekDays = (days) => {
  const daysArray = []
  const Sunday = 0;
  const Saturday = 6;
  let daysRemaining = days;
  const newDate = moment(new Date())
  while (daysRemaining > 0) {
    newDate.subtract(1, 'days');
    if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
      daysArray.push({
        name: newDate.format(dateFirstFormat),
        value: newDate.format(yearFirstFormat)
      })
      daysRemaining--;
    }
  }
  return daysArray
}

/**
* @description check if the date is within the interval days range
* @param {*} props 
* @returns {boolean}
*/
export const isDateInbetweenRange = (props, interval) => {
  const dateTo = new Date(props.to)
  const dateFrom = new Date(props.from)
  if (dateTo < dateFrom) {
    return false
  }
  const diff = Math.abs(dateTo - dateFrom)
  const diffDays = diff / (1000 * 60 * 60 * 24)
  return diffDays <= interval
}
