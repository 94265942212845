import { Grid } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import SSHButton from '../components/SSHButton'

const PopupActions = ({ cancelText, confirmText, onCancel, onConfirm, disabled, isLoading, alignRight }) => (
  <Grid container spacing={2}>
    <Grid item xs={12} container justifyContent={alignRight ? 'flex-end' : 'space-between'}>
      {!!cancelText && <SSHButton fullWidth={!alignRight} variant="outlined" size="medium" text={cancelText} onClick={onCancel} />}
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <SSHButton
        fullWidth={!alignRight}
        variant="contained"
        size="medium"
        disabled={disabled}
        loading={isLoading}
        text={confirmText}
        color="primary"
        onClick={onConfirm}
      />
    </Grid>
  </Grid>
)

PopupActions.propTypes = {
  cancelText: PropTypes.string,
  confirmText: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  alignRight: PropTypes.bool
}
PopupActions.defaultProps = {
  isLoading: false,
  alignRight: false,
  disabled: false,
  cancelText: null,
  onCancel: () => null
}

export default PopupActions
