import { CssBaseline } from '@mui/material'
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Provider } from 'react-redux'
import store from '../../reduxSetup/store'
import ErrorBoundary from '../ErrorBoundary'
import globalMUIStyles from './globalMUIStyles'
import Layout from './Layout'
import '@bluesteel/desktop/lib/styles.css'
import { theme } from '@bluesteel/desktop'

let newTheme = { theme, ...globalMUIStyles }

const Entry = () => (
  <>
    <CssBaseline />
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(newTheme)}>
        <Provider store={store}>
          <ErrorBoundary>
            <Layout />
          </ErrorBoundary>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  </>
)

export default Entry
