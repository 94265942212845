import { cloneDeep, get } from 'lodash'
import config from '../../config'
import {
  AI_PERF_MONTH,
  AI_PERF_TYPE,
  AI_PERF_WEEK,
  AI_PERF_YEAR
} from '../../reduxSetup/commonActions/Telemetry.actionTypes'
import { aiChangePerformanceFilters, aiPerformanceCompareTo } from '../../reduxSetup/commonActions/TelemetryActions'
import { CALL_API } from '../../reduxSetup/middleware/API'
import { getVendorIdFromState } from '../../utils/common'
import {
  CHANGE_DATE_DETAILED_VIEW,
  CHANGE_PERFORMANCE_COMPARISON_TYPES,
  CHANGE_PERFORMANCE_TYPE_DISPLAY,
  CHANGE_SELECTED_WEEK_SUMMARY,
  FETCH_PERFORMANCE_DETAILS_FAILURE,
  FETCH_PERFORMANCE_DETAILS_REQUEST,
  FETCH_PERFORMANCE_DETAILS_SUCCESS,
  FETCH_PERFORMANCE_SUMMARY_FAILURE,
  FETCH_PERFORMANCE_SUMMARY_REQUEST,
  FETCH_PERFORMANCE_SUMMARY_SUCCESS,
  RESET_PERFORMANCE_DATA,
  TOGGLE_DETAILED_VIEW
} from './Performance.actionTypes'
import { DEFAULT_SELECTION } from './PerformanceReducer'

export const fetchPerformanceSummary = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)

  if (!VENDOR_ID) return Promise.resolve()

  return dispatch({
    [CALL_API]: {
      types: [FETCH_PERFORMANCE_SUMMARY_REQUEST, FETCH_PERFORMANCE_SUMMARY_SUCCESS, FETCH_PERFORMANCE_SUMMARY_FAILURE],
      endpoint: `${config.get(
        'performanceAnalytics'
      )}/${VENDOR_ID}/performance?vendorId=${VENDOR_ID}`,
      method: 'GET'
    }
  }).then(response => {
    if (response && response.type === FETCH_PERFORMANCE_SUMMARY_SUCCESS) {
      const selectedWeek = getState().Performance.selected.week
      return dispatch(changeSelectedWeekSummary({ name: selectedWeek.title, value: selectedWeek.value }))
    }
    return Promise.resolve()
  })
}

export const changeSelectedWeekSummary = ({ name, value }) => dispatch => {
  return dispatch({
    type: CHANGE_SELECTED_WEEK_SUMMARY,
    payload: { week: { title: name, value } }
  })
}

export const toggleDetailedView = () => (dispatch, getState) => {
  return Promise.resolve(
    dispatch({
      type: TOGGLE_DETAILED_VIEW
    })
  ).then(() => {
    const detailedViewEnabled = getState().Performance.detailedView.enabled
    if (detailedViewEnabled) return dispatch(fetchPerformanceDetails())
    return Promise.resolve()
  })
}

/**
 *
 * @param {String} performanceType
 * @returns {function(*, *=): *}
 */
export const fetchPerformanceDetails = (performanceType = null) => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const { year, month, weekDetailedView } = getState().Performance.selected

  if (performanceType !== null) dispatch(showOnlyOneColumn({ columnCode: performanceType }))

  return dispatch({
    [CALL_API]: {
      types: [FETCH_PERFORMANCE_DETAILS_REQUEST, FETCH_PERFORMANCE_DETAILS_SUCCESS, FETCH_PERFORMANCE_DETAILS_FAILURE],
      endpoint: `${config.get('performanceAnalytics')}/${VENDOR_ID}/performance/details?year=${year.value}&month=${
        month.value
      }&week=${weekDetailedView.value}&statusCode=All&vendorId=${VENDOR_ID}`,
      method: 'GET'
    }
  })
}

export const changeYearMonthWeek = ({ fieldName, value, name }) => (dispatch, getState) => {
  let selectedValues = { ...getState().Performance.selected }
  let telemetryChangeType = null
  selectedValues = {
    year: fieldName === 'year' ? { title: name, value } : selectedValues.year,
    month: fieldName === 'month' ? { title: name, value } : selectedValues.month,
    weekDetailedView: fieldName === 'weekDetailedView' ? { title: name, value } : selectedValues.weekDetailedView
  }

  if (fieldName === 'year') {
    selectedValues.month = { ...DEFAULT_SELECTION }
    selectedValues.weekDetailedView = { ...DEFAULT_SELECTION }
    telemetryChangeType = AI_PERF_YEAR
  } else if (fieldName === 'month') {
    selectedValues.weekDetailedView = { ...DEFAULT_SELECTION }
    telemetryChangeType = AI_PERF_MONTH
  } else {
    telemetryChangeType = AI_PERF_WEEK
  }

  dispatch(aiChangePerformanceFilters({ type: telemetryChangeType, value }))

  return Promise.resolve(
    dispatch({
      type: CHANGE_DATE_DETAILED_VIEW,
      payload: { selectedValues }
    })
  ).then(dispatch(fetchPerformanceDetails()))
}

/**
 *
 * @param {Object} event
 * @param {Object} item
 * @returns {function(...[*]=)}
 */
export const changePerformanceTypes = (event, item) => (dispatch, getState) => {
  const columns = [get(item, 'props.value', null)]

  let enabledColumns = cloneDeep(getState().Performance.detailedView.enabledColumns)
  enabledColumns = enabledColumns.map(column => {
    if (columns.indexOf(column.code) > -1) return { ...column, active: !column.active }

    return { ...column }
  })

  if (enabledColumns.filter(col => col.active).length === 0) return Promise.resolve()

  setTimeout(() => dispatch(aiChangePerformanceFilters({ type: AI_PERF_TYPE, value: null })), 5000)

  return Promise.resolve(
    dispatch({
      type: CHANGE_PERFORMANCE_TYPE_DISPLAY,
      payload: { enabledColumns }
    })
  )
}

/**
 *
 * @param {String} columnCode
 * @returns {function(*, *): Promise<unknown>}
 */
export const showOnlyOneColumn = ({ columnCode }) => (dispatch, getState) => {
  let enabledColumns = cloneDeep(getState().Performance.detailedView.enabledColumns)
  enabledColumns = enabledColumns.map(column => {
    if (columnCode === column.code) return { ...column, active: true }

    return { ...column, active: false }
  })

  return Promise.resolve(
    dispatch({
      type: CHANGE_PERFORMANCE_TYPE_DISPLAY,
      payload: { enabledColumns }
    })
  )
}
/**
 *
 * @param {String} comparisonType
 * @param {Boolean} enabled
 * @returns {function(*, *): Promise<unknown>}
 */
export const togglePerformanceComparisons = ({ comparisonType, enabled }) => (dispatch, getState) => {
  let currentEnabledRowTypes = getState().Performance.detailedView.enabledRowTypes
  if (!!enabled && currentEnabledRowTypes.indexOf(comparisonType) === -1) {
    currentEnabledRowTypes.push(comparisonType)
    dispatch(aiPerformanceCompareTo({ compareType: comparisonType }))
  } else {
    currentEnabledRowTypes = currentEnabledRowTypes.filter(rowType => rowType !== comparisonType)
  }

  return Promise.resolve(
    dispatch({
      type: CHANGE_PERFORMANCE_COMPARISON_TYPES,
      payload: { enabledRowTypes: currentEnabledRowTypes }
    })
  )
}

export const resetPerformanceData = () => dispatch => {
  return Promise.resolve(
    dispatch({
      type: RESET_PERFORMANCE_DATA
    })
  )
}
