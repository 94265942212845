/* eslint-disable react/destructuring-assignment */
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SSHTooltip from '../../components/SSHTooltip'
import { goToOrder, setOrderList } from '../../pages/OrderPage/OrderDetailsActions'
import { ArrayFormatter } from './CustomFieldTypeProvider'

const styles = theme => ({
  link: {
    color: theme.palette.primary.blue,
    cursor: 'pointer'
  }
})

const PoNumberCell = ({ classes, setOrderList, goToOrder, closedOrder, ...props }) => {
  const onClick = () => {
    return setOrderList().then(() => goToOrder({ order: props.row }))
  }

  let tooltip = closedOrder ? 'Click to view closed order' : 'Click to update this order'

  return (
    <Table.Cell {...props}>
      <SSHTooltip title={tooltip} placement="top-start">
        <Typography variant="body2" className={classes.link} onClick={onClick}>
          <ArrayFormatter value={props.value} noTooltip />
        </Typography>
      </SSHTooltip>
    </Table.Cell>
  )
}

PoNumberCell.propTypes = {
  classes: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
  goToOrder: PropTypes.func.isRequired,
  closedOrder: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  closedOrder: state.FulfillmentNew.selectedTabView === 'Closed'
})
export const PoNumberCellNaked = PoNumberCell

const mapDispatchToProps = dispatch => bindActionCreators({ goToOrder, setOrderList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PoNumberCell))
