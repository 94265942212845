import {
  OPEN_NOTIFICATION_DRAWER,
  CLOSE_NOTIFICATION_DRAWER,
  CLEAR_NOTIFICATION_COUNT,
  DISABLE_PLAY
} from './NotificationDrawer.actionTypes'

/**
 * This function dispatches action
 * to open the notification drawer
 * @returns {function(*, *): *}
 */
export const openNotificationDrawer = () => {
  return {
    type: OPEN_NOTIFICATION_DRAWER
  }
}

/**
 * This function dispatches action
 * to close the notification drawer
 * @returns {function(*, *): *}
 */
export const closeNotificationDrawer = () => {
  return {
    type: CLOSE_NOTIFICATION_DRAWER
  }
}

/**
 * This function clears the notification count
 * @returns {function(*, *): *}
 */
export const clearNotificationCount = () => {
  return {
    type: CLEAR_NOTIFICATION_COUNT
  }
}


/**
 * Disable play sound after download  completed
 * @returns {function(*, *): *}
 */
export const disablePlay = () => {
  return {
    type: DISABLE_PLAY
  }
}
