import { isEmpty } from 'lodash'

export const columns = [
    { name: 'clubId', title: "Club ID", isLink: true },
    {
        name: 'clubName', title: 'Club name',
        getCellValue: row => {
            return isEmpty(row?.clubName) ? '-' : row?.clubName
        }
    },
    {
        name: 'samsForecastedQty', title: "Sam's demand qty",
        getCellValue: row => {
            if (row?.samsForecastedQty !== null) {
                return row?.samsForecastedQty?.toLocaleString()
            }
            return '-'
        }
    }
]
export const columnExtensions = [
    { columnName: 'samsForecastedQty', align: 'right' }
]
export const formattedColumns = [
    {
        type: 'tooltip',
        list: ['clubName']
    }
]
