import { cloneDeep } from 'lodash'
import { SET_DOWNLOAD_ROW_COUNT, REPORT_DOWNLOAD } from './DownloadHistory.types'

export const INITIAL_STATE = {
  totalRows: null,
  downloadInProgress: {}
}

export default (state = cloneDeep(INITIAL_STATE), action) => {
  switch (action.type) {
    case SET_DOWNLOAD_ROW_COUNT:
      return {
        ...state,
        totalRows: action.payload.count
      }

    case REPORT_DOWNLOAD: {
      return {
        ...state,
        downloadInProgress: { ...state.downloadInProgress, ...action.payload }
      }
    }

    default:
      return { ...state }
  }
}
