import { IconButton, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logout } from './UserInfoAction'
import CompareOutlinedIcon from '@mui/icons-material/CompareOutlined'
import { COLORS } from '../Entry/globalMUIStyles'

const styles = theme => ({
    logout: {
        color: theme.palette.primary.white,
        marginLeft: - theme.replacePX(theme.spacing(0.9))
    }
})

const Logout = ({ classes, logout }) => {
    return (
        <IconButton className={classes.logout} onClick={logout}>
            <CompareOutlinedIcon /> &nbsp;&nbsp;
            <Typography style={{ color: COLORS.white }}>Sign out</Typography>
        </IconButton>
    )
}

export const LogoutNaked = connect(null, null)(withStyles(styles)(Logout))

Logout.propTypes = {
    classes: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => bindActionCreators({ logout }, dispatch)
export default connect(null, mapDispatchToProps)(withStyles(styles)(Logout))
