import _, { pick, map, filter, isEmpty, groupBy, get, reduce } from 'lodash'
import config from '../../config'
import moment from 'moment'
import { changePage, showToastMessage, hideToastMessage } from '../../reduxSetup/commonActions/AppConfig'
import {
  FETCH_PAGINATED_TABLE_DATA_FAILURE,
  FETCH_PAGINATED_TABLE_DATA_REQUEST,
  FETCH_PAGINATED_TABLE_DATA_SUCCESS
} from '../../reduxSetup/commonActions/SSHPaginatedTable.actionTypes'
import { CALL_API } from '../../reduxSetup/middleware/API'
import { getVendorIdFromState } from '../../utils/common'
import {
  SET_DATE_RANGE,
  RESET_DATE_RANGE,
  TOGGLE_EDIT_CAPACITY,
  SET_EDITED_CAPACITY,
  RESET_EDITED_CAPACITY,
  SET_DOWNLOAD_ALL,
  STORE_CAPACITY_NODES,
  SET_SHIPNODE_SELECTED,
  UPDATE_FCAPACITY_REQUEST,
  UPDATE_FCAPACITY_SUCCESS,
  UPDATE_FCAPACITY_FAILURE,
  SET_ACTIVE_TAB,
  SET_CHANGELOG_COUNT,
  SET_FAILED_CAPACITY
} from './Capacity.actionTypes'
import { ROUTE_MODULES } from '../../config/genericConstants'
import { clearShipnodes, getShipNodeDetails } from '../SupplierManagement/SupplierOnBoardAction'
import { resetFcapacityTable, resetPagination } from '../../reduxSetup/commonActions/SSHPaginatedTable'

/**
 * @description fetch capacity on capacity tab load
 * @returns  capacity list  
 */
export const fetchCapacity = () => async (dispatch, getState) => {
  const { SUPPLIER_ID, SHIP_NODE_ID, pathname } = await dispatch(getShipNodes())

  if (isEmpty(SHIP_NODE_ID)) {
    await dispatch(resetFcapacityTable())
    await dispatch(resetPagination())
    return Promise.resolve()
  }

  const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
  let params = ''
  const formatDate = 'MM-DD-YYYY'
  const { selectedDate } = getState().FCapacity

  params += `&startDate=${!selectedDate.fromDate ? moment().format(formatDate) : moment(selectedDate.fromDate).format(formatDate)}&endDate=${!selectedDate.toDate ? moment().add(14, 'days').format(formatDate) : moment(selectedDate.toDate).format(formatDate)}`
  params += `&shipNodeId=${SHIP_NODE_ID}`

  // Dispatch the API call with the required parameters
  return dispatch({
    [CALL_API]: {
      types: [FETCH_PAGINATED_TABLE_DATA_REQUEST, FETCH_PAGINATED_TABLE_DATA_SUCCESS, FETCH_PAGINATED_TABLE_DATA_FAILURE],
      endpoint: `${config.get('shipnodeDetails')}/${SUPPLIER_ID}/fulfillment-capacity?pageNo=${PAGE_NO + 1}&pageSize=${10}${params}`,
      method: 'GET',
      payload: {
        pathName: pathname
      }
    }
  }).then(() => {
    return Promise.resolve()
  })
}

/**
 * @description fetch change log
 * @returns change log tasks
 */
export const fetchChangelogTasks = (isCountAPI) => async (dispatch, getState) => {
  const SUPPLIER_ID = getVendorIdFromState(getState)
  const { selectedShipnode } = getState().FCapacity
  const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
  const PAGE_SIZE = isCountAPI ? 1 : 10
  if (!SUPPLIER_ID) {
    return
  }
  return dispatch({
    [CALL_API]: {
      types: [FETCH_PAGINATED_TABLE_DATA_REQUEST, FETCH_PAGINATED_TABLE_DATA_SUCCESS, FETCH_PAGINATED_TABLE_DATA_FAILURE],
      endpoint: `${config.get('shipnodeDetails')}/${SUPPLIER_ID}/shipnode/${selectedShipnode?.id}/fulfillment-capacity/updates?pageNo=${PAGE_NO + 1}&pageSize=${PAGE_SIZE}`,
      method: 'GET',
      payload: {
        pathName: getState().router.location.pathname
      }
    }
  }).then(({ type, response }) => {
    if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS && isCountAPI) {
      dispatch({
        type: SET_CHANGELOG_COUNT,
        payload: parseInt(response?.payload?.totalPages, 10) || 0
      })
    }
    return Promise.resolve()
  })
}

/**
 * @description get ship nodes only for capacity action  do not use for other actions
 * @returns {object}
 */
export const getShipNodes = () => async (dispatch, getState) => {
  const SUPPLIER_ID = getVendorIdFromState(getState)
  const { pathname } = getState().router.location
  let { shipnodesDetails } = await getState().SupplierOnBoard

  if (isEmpty(shipnodesDetails) || shipnodesDetails[0]?.shipNodeId?.split('_')[0] != SUPPLIER_ID) {
    await dispatch(clearShipnodes())
    await dispatch(getShipNodeDetails())
    shipnodesDetails = await getState().SupplierOnBoard.shipnodesDetails
  }

  const CAPACITY_NODES = map(shipnodesDetails, (item) => pick(item, ['capacity', 'shipNodeId']))

  // Check if SHIP_NODE_ID is an array, and if so, join the values with commas
  if (!isEmpty(CAPACITY_NODES) && Array.isArray(CAPACITY_NODES)) {
    const ONLY_CAPACITY_NODES = filter(CAPACITY_NODES, z => z.capacity?.isEnabled).length
    const SHIP_NODE_ID = filter(CAPACITY_NODES, z => z.capacity?.isEnabled).map(x => x.shipNodeId).join(',')

    await dispatch(storeCapacityNodes({ capacityNodes: CAPACITY_NODES, totalCount: ONLY_CAPACITY_NODES }))

    return {
      SUPPLIER_ID,
      SHIP_NODE_ID,
      pathname
    }
  }
}

/**
 * @description set date change
 * @param {*} dateRange 
 * @returns {object}
 */
export const setDateChange = (dateRange) => (dispatch) => {
  const { from, to } = dateRange
  return Promise.resolve(
    dispatch({
      type: SET_DATE_RANGE,
      payload: {
        from,
        to
      }
    }))
}

/**
 * @desc reset date range for fcapacity 
 * @returns 
 */
export const resetDateRange = () => dispatch => {
  return Promise.resolve(dispatch({
    type: RESET_DATE_RANGE
  }))
}

/**
 * @description go to Change log
 * @returns 
 */
export const goToChangeLog = ({ shipnode }) => async dispatch => {
  const { id } = shipnode
  const path = !isEmpty(id) ? `/changelog/${id}` : ''
  await dispatch(setSelectedShipnode({ shipnode }))
  dispatch(changePage(`${ROUTE_MODULES?.fulfilment?.leadtime?.path}${path}`))
}

/**
 * @description toggle Edit capacity
 * @returns  {object}
 */
export const toggleEditCapacityFlag = (flag) => (dispatch) => {
  return Promise.resolve(
    dispatch({
      type: TOGGLE_EDIT_CAPACITY,
      payload: flag
    })
  )
}

/**
 * @description store edir capacity
 * @param {*} param0 
 * @returns {object}
 */
export const storeEditedCapacity = ({ payload }) => (dispatch) => {
  return Promise.resolve(
    dispatch({
      type: SET_EDITED_CAPACITY,
      payload
    })
  )
}

export const resetEditedCapacity = () => (dispatch) => {
  return Promise.resolve(
    dispatch({
      type: RESET_EDITED_CAPACITY
    })
  )
}

/**
 * @description set option from download menu
 * @param {*} boolean 
 * @returns {object}
 */
export const setIsDownloadAll = (payload) => (dispatch) => {
  return Promise.resolve(
    dispatch({
      type: SET_DOWNLOAD_ALL,
      payload
    })
  )
}

/**
 * @description set shipnode to display changelogs
 * @param {*} shipnode object {id, name}
 * @returns {object}
 */
export const setSelectedShipnode = ({ shipnode }) => (dispatch) => {
  return Promise.resolve(
    dispatch({
      type: SET_SHIPNODE_SELECTED,
      payload: shipnode
    })
  )
}

/**
 * @description set active tab leadtime or capacity
 * @param {*} 0: leadtime /1: capacity
 * @returns {object}
 */
export const setActiveTab = (selectedTab) => (dispatch) => {
  return Promise.resolve(
    dispatch({
      type: SET_ACTIVE_TAB,
      payload: selectedTab
    })
  )
}

/**
 * @description store capacity for reference 
 * @param {*} param0 
 * @returns {*} object
 */
export const storeCapacityNodes = ({ capacityNodes, totalCount }) => (dispatch) => {
  return Promise.resolve(
    dispatch({
      type: STORE_CAPACITY_NODES,
      payload: {
        capacityNodes,
        totalCount
      }
    })
  )
}

/**
 * @description udpate capacity
 * @param {*} reasonCode 
 * @returns {object}
 */
export const updateCapacity = (reasonCode) => (dispatch, getState) => {
  const SUPPLIER_ID = getVendorIdFromState(getState)
  const { editedCapacity, failCapacity } = getState().FCapacity
  const grpShipNodeId = groupBy(editedCapacity, 'shipNodeId')
  const payload = {
    shipNodes: map(grpShipNodeId, (val, key) => {
      return {
        shipNodeId: key,
        shipNodeName: val[0]?.shipNodeName,
        capacity: val.map((item) => {
          return {
            startDate: item.date,
            endDate: item.date,
            newCapacity: item.capacity,
            reasonCode,
            recurrence: []
          }
        })
      }
    })
  }

  // Dispatch the API call with the required parameters
  return dispatch({
    [CALL_API]: {
      types: [UPDATE_FCAPACITY_REQUEST, UPDATE_FCAPACITY_SUCCESS, UPDATE_FCAPACITY_FAILURE],
      endpoint: `${config.get('shipnodeDetails')}/${SUPPLIER_ID}/fulfillment-capacity`,
      method: 'PUT',
      data: payload
    }
  }).then(async (response) => {
    if (response?.type === UPDATE_FCAPACITY_SUCCESS) {

      const shipNodes = get(response, 'response.payload.shipNodes')

      const failedCapacities = getFailedCapacities(shipNodes)

      if (hasFailed(failedCapacities, failCapacity)) {
        await handleFailedCapacityUpdate(dispatch, failedCapacities)
      } else {
        handleSuccessfulCapacityUpdate(dispatch)
      }
    } else {
      handleCapacityUpdateFailure(dispatch)
    }
  }).catch((err => {
    dispatch(
      showToastMessage({
        message: 'Some of the values are beyond the acceptable range.'
      })
    )
  }))
}

/**
 * @description enable flag tp store failed capacity
 * @param {*} param0 
 * @returns 
 */
export const storeFailCapacity = ({ payload }) => (dispatch) => {
  return Promise.resolve(
    dispatch({
      type: SET_FAILED_CAPACITY,
      payload
    })
  )
}

/**
 * @description get failed capacities
 * @param {*} shipNodes 
 * @returns {[*]}
 */
const getFailedCapacities = (shipNodes) => {
  return reduce(shipNodes, (result, obj) => {
    const capacity = filter(obj.capacity, { 'updateStatus': false })
    if (!isEmpty(capacity)) {
      capacity.forEach(cap => {
        const { startDate, ...rest } = pick(cap, ['startDate', 'updateStatus', 'reasonCode', 'newCapacity'])
        result.push({
          ...rest,
          date: startDate,
          shipNodeId: obj.shipNodeId,
        })
      })
    }
    return result
  }, [])
}

/**
 * @description check fail capacity any from server resposne
 * @param {*} failedCapacity 
 * @param {*} failCapacity 
 * @returns {boolean}
 */
const hasFailed = (failedCapacity, failCapacity) => {
  return !isEmpty(failedCapacity) && !failCapacity
}

/**
 * @description handle failed capacity udpate
 * @param {*} dispatch 
 * @param {*} failedCapacities 
 */
const handleFailedCapacityUpdate = async (dispatch, failedCapacities) => {
  await dispatch(showToastMessage({ message: 'Some of the values highlighted in red failed to update.' }))
  await dispatch(storeFailCapacity({ payload: failedCapacities }))
  await dispatch(fetchCapacity())
}

/**
 * @description handle successful capacity
 * @param {*} dispatch 
 */
const handleSuccessfulCapacityUpdate = (dispatch) => {
  dispatch(showToastMessage({ message: 'Daily capacity updated successfully.' }))
  dispatch(toggleEditCapacityFlag(false))
  dispatch(resetEditedCapacity())
  dispatch(fetchCapacity())

  setTimeout(() => {
    dispatch(hideToastMessage())
  }, 2000)
}

/**
 * @description handle capacity update failure
 * @param {*} dispatch 
 */
const handleCapacityUpdateFailure = (dispatch) => {
  dispatch(
    showToastMessage({
      message: 'Daily capacity update request failed. Please try again.'
    })
  )
}