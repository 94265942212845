import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { withStyles } from '@mui/styles'
import { find } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import SSHLoadingAnimation from './SSHLoadingAnimation'
import { ReactComponent as Arrow } from '../assets/images/ArrowDownBlue.svg'

const styles = theme => ({
  bgWhite: {
    backgroundColor: theme.palette.primary.white,
    color:theme.palette.primary.persianBlue,
    fontWeight:400,
    '& .MuiSelect-icon': {
      marginTop: theme.spacing(0)
    }
  },
  boldMenuItem: {
    color: theme.palette.text.dropDownItem,
    fontWeight: '400',
    fontSize: '14px',
    backgroundColor: theme.palette.primary.white,
    '& .MuiSelect-icon': {
      marginTop: theme.spacing(0.625)
    }
  }
})

const SSHSelectBox = ({
  classes,
  disabled,
  onSelect,
  placeholder,
  name,
  error,
  label,
  loading,
  options,
  size,
  defaultVal,
  menuTextStyle,
  style,
  ...restProps
}) => {
  const onChange = event => {
    const { value } = event.target
    const option = find(options, { value })
    return onSelect({ ...option }, event)
  }

  return (
    <FormControl
      variant="outlined"
      placeholder={placeholder}
      size={size}
      fullWidth
      className={clsx(restProps.selectStyle)}>
      {!!label && (
        <InputLabel
          id={`sshSelect-${label.replace(/\W\D/, '')}`}
          className={menuTextStyle === 'bold' ? classes.boldMenuItem : classes.bgWhite}>
          {label}
        </InputLabel>
      )}

      <Select
        IconComponent={Arrow}
        primary
        native={restProps?.native || false}
        data-testid='select-ship-node'
        id="select-ship-node"
        placeholder="ssh-select"
        disabled={disabled}
        className={menuTextStyle === 'bold' ? clsx(classes.boldMenuItem, style) : clsx(classes.bgWhite, style)}
        onChange={onChange}
        onClick={()=>{}}
        name={name}
        error={error}
        value={defaultVal.value}
        inputProps={{ 'data-testid': 'ssh-select' }}
        endAdornment={
          loading && (
            <>
              <SSHLoadingAnimation size={20} thickness={5} />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </>
          )
        }
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}>
        {!!defaultVal.value && !find(options, { value: defaultVal.value }) && (
          <MenuItem
            className={menuTextStyle === 'bold' ? classes.boldMenuItem : classes.bgWhite}
            value={defaultVal.value}
            key={defaultVal.value}>
            {defaultVal.name}
          </MenuItem>
        )}
        {options.map(option => (
          <MenuItem
            className={menuTextStyle === 'bold' ? classes.boldMenuItem : classes.bgWhite}
            key={option.value}
            value={option.value}
            name={option.name}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SSHSelectBox.defaultProps = {
  loading: false,
  disabled: false,
  error: false,
  placeholder: 'Click to select',
  options: [],
  size: 'medium',
  name: '',
  defaultVal: { value: '', name: null },
  menuTextStyle: undefined,
  style: {}
}

SSHSelectBox.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  // eslint-disable-next-line react/require-default-props
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string
    })
  ),
  defaultVal: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string
  }),
  menuTextStyle: PropTypes.string,
  style: PropTypes.object
}

export default withStyles(styles)(SSHSelectBox)
