import CustomError from './CustomError'

export default class NetworkError extends CustomError {
  /**
   * @param {string} message
   * @param {object} response
   * @param {number} [code = 0]
   */
  constructor(message, response) {
    super(message, response)

    /**
     * @type {string}
     * @readonly
     */
    this.message = message

    /**
     * @type {object}
     * @readonly
     */
    this.response = response

    /**
     * @type {string}
     * @readonly
     */
    this.name = this.constructor.name

    /**
     * @type {string}
     * @readonly
     */
    this.stack = NetworkError.createStack(this)
  }

  /**
   * @param {CustomError} error
   * @return {string}
   * @private
   */
  static createStack(error) {
    return typeof Error.captureStackTrace === 'function'
      ? Error.captureStackTrace(error, error.constructor)
      : new Error().stack
  }

  /**
   * @return {string}
   */
  toString() {
    return this.getPrettyMessage()
  }

  /**
   * @return {string}
   */
  getPrettyMessage() {
    return `${this.message} Code: ${JSON.stringify(this.response)}.`
  }
}
