import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { withStyles } from '@mui/styles'
import clsx from 'clsx'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { SSHDivider } from './SSHDivider'
import { SSHTypography } from './SSHTypography'
import { ReactComponent as CloseIcon } from '../assets/images/CloseModal.svg'

const styles = theme => ({
  root: {
    '& .MuiDialog-paperFullWidth': {
      borderRadius: theme.spacing(1.2)
    }
  },
  dialogActions: {
    padding: theme.spacing(3)
  },
  noPaddingBottom: {
    paddingBottom: 0
  },
  noMarginBottom: {
    marginBottom: 0
  }
})

const SSHModal = ({ classes, title, onClose, size, children, nonPaddedChildren, actions, showDivider = true }) => {
  const DIVIDER_STYLES = clsx({ [classes.noMarginBottom]: nonPaddedChildren !== null })
  const DIALOG_TITLE_STYLES = clsx({ [classes.noPaddingBottom]: nonPaddedChildren !== null })

  return (
    <Dialog
      fullWidth
      maxWidth={size}
      open
      onClose={onClose}
      aria-labelledby={title.replace(/\W\D/, '-')}
      className={classes.root}>
      <DialogTitle className={DIALOG_TITLE_STYLES}>
        <Grid container spacing={1} justifyContent="space-between" alignItems="center">
          <Grid item xs={10}>
            <SSHTypography variant="h6" data-testid="title">
              {title}
            </SSHTypography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={onClose}
              data-testid="closeIcon"
              className="closeIcon"
              size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        {showDivider && <SSHDivider className={DIVIDER_STYLES} />}
      </DialogTitle>
      <Box>{nonPaddedChildren}</Box>
      <DialogContent data-testid="content">{children}</DialogContent>
      {!isEmpty(actions) && (
        <DialogActions className={classes.dialogActions} data-testid="actions">
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
}

SSHModal.defaultProps = {
  size: 'md',
  nonPaddedChildren: null,
  actions: {}
}

SSHModal.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  onClose: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  actions: PropTypes.object,
  nonPaddedChildren: PropTypes.object
}

export default withStyles(styles)(SSHModal)
