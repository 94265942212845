/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-wrap-multilines */
import { Grid, Typography, Box } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { ReactComponent as CalendarIcon } from '../../../assets/images/Calendar1.svg'
import { ReactComponent as MapPinIcon } from '../../../assets/images/MapPin.svg'
import { ReactComponent as ReleaseIcon } from '../../../assets/images/Release.svg'
import { ReactComponent as SoNumberIcon } from '../../../assets/images/SoNumber.svg'
import SSHSkeleton from '../../../components/SSHSkeleton'

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.headerBackground,
    borderBottom: `1px solid ${theme.palette.primary.columbiaBlue}`,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`
  },
  icon: {
    width: theme.spacing(1.8),
    height: theme.spacing(1.8),
    marginBottom: -theme.spacing(0.2),
    marginRight: theme.spacing(1)
  },
  lineHeight: {
    lineHeight: '1.6em',
    fontWeight: '400',
    fontSize: theme.spacing(2.25),
    '& span , & div': {
      color: theme.palette.primary.tropaz,
      marginLeft: theme.spacing(3),
      fontSize: theme.spacing(1.75)
    }
  },
  lastGrid: {
    justifySelf: 'flex-end',
    marginLeft: 'auto'
  }
})

const ReturnOrderDetails = ({ classes, ordersList }) => {
  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={3} md={2}>
          <Typography variant="body2" className={classes.lineHeight}>
            <SoNumberIcon className={classes.icon} /> SO number
            <br />
            {isEmpty(ordersList)
              ? [
                <SSHSkeleton variant="text" animation="wave" width="50%" />,
                <SSHSkeleton variant="text" animation="wave" width="50%" />
              ]
              : [
                <span>{ordersList?.salesOrderNo}</span>
              ]}
          </Typography>
        </Grid>
        <Grid item xs={3} md={2}>
          <Typography variant="body2" className={classes.lineHeight}>
            <ReleaseIcon className={classes.icon} /> Number of items
            <br />
            {isEmpty(ordersList)
              ? [
                <SSHSkeleton variant="text" animation="wave" width="50%" />,
                <SSHSkeleton variant="text" animation="wave" width="50%" />
              ]
              : [

                <span>
                  {ordersList?.orderLines?.length}
                </span>

              ]}
          </Typography>
        </Grid>
        <Grid item xs={3} md={2}>
          <Typography variant="body2" className={classes.lineHeight}>
            <CalendarIcon className={classes.icon} />  Dates
            <br />
            {isEmpty(ordersList) ? (
              <SSHSkeleton variant="text" animation="wave" width="50%" />
            ) : (
              <span>
                Order return date :  {moment(ordersList?.orderDate)
                  .format('ll')}
              </span>
            )}
          </Typography>
        </Grid>
        <Grid item xs={3} md={2}>
          <Typography variant="body2" className={classes.lineHeight}>
            <MapPinIcon className={classes.icon} /> Pick up address
            <br />
            {isEmpty(ordersList) ? (
              <SSHSkeleton variant="text" animation="wave" width="50%" />
            ) : (
              <div>
                {ordersList?.billToAddress?.addressLine1} {ordersList?.billToAddress?.addressLine2} {ordersList?.billToAddress?.addressLine3}
                {ordersList?.billToAddress?.addressLine4} {ordersList?.billToAddress?.addressLine5} {ordersList?.billToAddress?.addressLine6}
                {ordersList?.billToAddress?.city} {ordersList?.billToAddress?.state} {ordersList?.billToAddress?.country} {ordersList?.billToAddress?.zipCode}
              </div>

            )}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
export const ReturnOrderDetailsNaked = ReturnOrderDetails

ReturnOrderDetails.propTypes = {
  order: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ReturnOrderDetails)
