export const SET_SMART_INVENTORY_ROW_COUNT = 'SET_SMART_INVENTORY_ROW_COUNT'
export const FETCH_SMART_INVENTORY = 'FETCH_SMART_INVENTORY'
export const SEARCH_SMART_INVENTORY_ITEM = 'SEARCH_SMART_INVENTORY_ITEM'
export const SET_SMART_INVENTORY_TAB = 'SET_SMART_INVENTORY_TAB'
export const SET_FILTERS = 'SET_SMART_INVENTORY_FILTERS'
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS'
export const REMOVE_FILTER = 'REMOVE_FILTER'
export const FETCH_SUPPLIERS_REQUEST = 'FETCH_SUPPLIERS_REQUEST'
export const FETCH_SUPPLIERS_SUCCESS = 'FETCH_SUPPLIERS_SUCCESS'
export const FETCH_SUPPLIERS_FAILURE = 'FETCH_SUPPLIERS_FAILURE'
export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST'
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS'
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE'
export const RESET_FILTER = 'RESET_FILTER'
export const RESET_TAB = 'RESET_TAB'
export const SHOW_FILTERS_POPUP = 'SHOW_FILTERS_POPUP'
export const HIDE_FILTERS_POPUP = 'HIDE_FILTERS_POPUP'
export const SET_SMART_INVENTORY_AGGREGATED_ROWS = 'SET_SMART_INVENTORY_AGGREGATED_ROWS'
export const UPDATE_ALERT_TYPE = 'UPDATE_ALERT_TYPE'
export const UPDATE_ALERT_TYPE_CHIP = 'UPDATE_ALERT_TYPE_CHIP'
export const RESET_SMART_INVENTORY_ITEM_SEARCH = 'RESET_SMART_INVENTORY_ITEM_SEARCH'
export const SHOW_ACTION_CONFIRMATION_POPUP = 'SHOW_ACTION_CONFIRMATION_POPUP'
export const HIDE_ACTION_CONFIRMATION_POPUP = 'HIDE_ACTION_CONFIRMATION_POPUP'
export const SHOW_SUSPEND_ALERT_POPUP = 'SHOW_SUSPEND_ALERT_POPUP'
export const HIDE_SUSPEND_ALERT_POPUP = 'HIDE_SUSPEND_ALERT_POPUP'
export const SHOW_TERMINATE_ALERT_POPUP = 'SHOW_TERMINATE_ALERT_POPUP'
export const HIDE_TERMINATE_ALERT_POPUP = 'HIDE_TERMINATE_ALERT_POPUP'
export const SET_ITEM_DETAILS = 'SET_ITEM_DETAILS'
export const UPDATE_API_FILTER = 'UPDATE_API_FILTER'
export const SET_ALERT_ACTION_REQUEST = 'SET_ALERT_ACTION_REQUEST'
export const SET_ALERT_ACTION_SUCCESS = 'SET_ALERT_ACTION_SUCCESS'
export const SET_ALERT_ACTION_FAILURE = 'SET_ALERT_ACTION_FAILURE'
export const SET_OPEN_FILTER = 'SET_OPEN_FILTER'
export const FETCH_DIVISIONS_FAILURE = 'FETCH_DIVISIONS_FAILURE'
export const FETCH_DIVISIONS_SUCCESS = 'FETCH_DIVISIONS_SUCCESS'
export const FETCH_DIVISIONS_REQUEST = 'FETCH_DIVISIONS_REQUEST'
export const UPDATE_COLUMN_VISIBILITY = 'UPDATE_COLUMN_VISIBILITY'
export const FETCH_PAST_TRENDS_OOS_DATA_REQUEST = 'FETCH_PAST_TRENDS_OOS_DATA_REQUEST'
export const FETCH_PAST_TRENDS_OOS_DATA_SUCCESS = 'FETCH_PAST_TRENDS_OOS_DATA_SUCCESS'
export const FETCH_PAST_TRENDS_OOS_DATA_FAILURE = 'FETCH_PAST_TRENDS_OOS_DATA_FAILURE'
export const FETCH_PAST_TRENDS_RL_DATA_REQUEST = 'FETCH_PAST_TRENDS_RL_DATA_REQUEST'
export const FETCH_PAST_TRENDS_RL_DATA_SUCCESS = 'FETCH_PAST_TRENDS_RL_DATA_SUCCESS'
export const FETCH_PAST_TRENDS_RL_DATA_FAILURE = 'FETCH_PAST_TRENDS_RL_DATA_FAILURE'
export const UPDATE_CHART_VIEW = 'UPDATE_CHART_VIEW'
export const UPDATE_CHART_DURATION = 'UPDATE_CHART_DURATION'
