import { isEmpty, uniqBy } from 'lodash'
import {
    SMART_INVENTORY_DROPDOWN_MODEL,
    CHART_DURATION,
    yearFirstFormat
} from '../config/genericConstants'
import moment from 'moment'


/**  Cognitive code split here for smart inventory module  start **/



/**
 * @description parse code for supplier and category if login is not supplier
 * @param {*} divisions
 * @param {*} object
 */
export const nonSupplierParseSupCat = (divisions, { formattedDivisions,
    formattedCategories,
    formattedSubCategories,
    categoryToSubCategoryMap,
    divisionToCategoryMap,
    categoryToDivisionMap,
    divisionToSubCategoryMap }) => {

    if (!isEmpty(divisions)) {
        divisions.forEach(division => {
            formattedDivisions.push({
                id: division.divisionId,
                title: division.divisionName,
                value: division.divisionName,
                active: true
            })
            const { categories } = division
            if (categories[0]?.categoryId !== undefined) {
                categories.forEach(category => {
                    categoryToSubCategoryMap.set(category.categoryId, [{ ...SMART_INVENTORY_DROPDOWN_MODEL }])
                    const cat = {
                        id: category.categoryId,
                        title: category.categoryName,
                        value: category.categoryName,
                        active: true
                    }

                    mapExisitingCatNonSup(division, cat, category.categoryId, { formattedCategories, divisionToCategoryMap, categoryToDivisionMap })
                    category.subcategories.forEach(subCategory => {
                        const sub = {
                            id: subCategory.subcategoryId,
                            title: subCategory.subcategoryName,
                            value: subCategory.subcategoryName,
                            number: subCategory.subcategoryNmbr,
                            active: true
                        }
                        mapExisitingSubCatNonSup(division, sub, category.categoryId, { formattedSubCategories, categoryToSubCategoryMap, divisionToSubCategoryMap })
                    })
                })
            }
        })
    }
}

/**
 * @description map existing cat for non supplier
 * @param {*} division
 * @param {*} cat
 * @param {*} categoryId
 * @param {*} object
 */
const mapExisitingCatNonSup = (division, cat, categoryId, { formattedCategories, divisionToCategoryMap, categoryToDivisionMap }) => {
    const existingCategpriesForDiv = divisionToCategoryMap.get(division.divisionId)

    existingCategpriesForDiv ? existingCategpriesForDiv.push(cat) : divisionToCategoryMap.set(division.divisionId, [cat])
    categoryToDivisionMap.set(categoryId, division)
    formattedCategories.push(cat)
}

/**
 * @description map existing sub cat for non supplier
 * @param {*} division
 * @param {*} sub
 * @param {*} categoryId
 * @param {*} param3
 */
const mapExisitingSubCatNonSup = (division, sub, categoryId, { formattedSubCategories, categoryToSubCategoryMap, divisionToSubCategoryMap }) => {
    const existingSubcategories = []
    categoryToSubCategoryMap.get(categoryId).push(sub)
    const existingSubCatForDiv = divisionToSubCategoryMap.get(division.divisionId)
    existingSubCatForDiv ? divisionToSubCategoryMap.get(division.divisionId).push(sub) : divisionToSubCategoryMap.set(division.divisionId, [sub])

    if (!existingSubcategories.includes(sub.id)) {
        formattedSubCategories.push(sub)
        existingSubcategories.push(sub.id)
    }
}

/**
 * @description supplier parser category
 * @param {*} divisions
 * @param {*} suppliers
 * @param {*} object
 */
export const supplierParseSupCat = (divisions, suppliers, { formattedCategories,
    formattedSubCategories,
    formattedDivisions,
    formattedSuppliers,
    divisionToSupplierMap,
    categoryToSubCategoryMap,
    categoryToSupplierMap,
    divisionToCategoryMap,
    categoryToDivisionMap,
    subCategoryToSupplierMap,
    divisionToSubCategoryMap }) => {



    if (!isEmpty(divisions)) {
        suppliers.forEach(supplier => {
            const sup = {
                id: supplier.supplierId,
                title: supplier.supplierName,
                value: supplier.supplierId,
                active: true
            }
            formattedSuppliers.push(sup)
            if (divisions !== undefined) {
                divisions.forEach(division => {
                    formattedDivisions.push({
                        id: division.divisionId,
                        title: division.divisionName,
                        value: division.divisionName,
                        active: true
                    })

                    mapDivisionSupplier(division, sup, { divisionToSupplierMap })

                    if (division.categories !== undefined) {
                        division.categories.forEach(category => {
                            mapCategories(category, sup, { categoryToSubCategoryMap, categoryToSupplierMap })

                            mapExisitingCategory(division, category, { formattedCategories, divisionToCategoryMap, categoryToDivisionMap })

                            category.subcategories.forEach(subCategory => {
                                subCategoryToSupplierMap.set(subCategory.subcategoryId, [
                                    ...(subCategoryToSupplierMap.get(subCategory.subcategoryId) || [
                                        { ...SMART_INVENTORY_DROPDOWN_MODEL }
                                    ]),
                                    sup
                                ])
                                const sub = {
                                    id: subCategory.subcategoryId,
                                    title: subCategory.subcategoryName,
                                    value: subCategory.subcategoryName,
                                    number: subCategory.subcategoryNmbr,
                                    active: true
                                }

                                mapExistingSubCategory(division, category, sub, { formattedSubCategories, divisionToSubCategoryMap, categoryToSubCategoryMap })

                            })
                        })
                    }
                })
            }
        })
    }
}


/**
 * @description map division to supplier module
 * @param {*} division
 * @param {*} sup
 * @param {*} object
 */
const mapDivisionSupplier = (division, sup, { divisionToSupplierMap }) => {
    if (isEmpty(divisionToSupplierMap.get(division.divisionId))) {
        divisionToSupplierMap.set(division.divisionId, [{ ...SMART_INVENTORY_DROPDOWN_MODEL }])
    }
    divisionToSupplierMap.get(division.divisionId).push(sup)
}

/**
 * @description map categoryies for supplier module
 * @param {*} category
 * @param {*} sup
 * @param {*} param2
 */
const mapCategories = (category, sup, { categoryToSubCategoryMap, categoryToSupplierMap }) => {
    if (isEmpty(categoryToSubCategoryMap.get(category.categoryId))) {
        categoryToSubCategoryMap.set(category.categoryId, [{ ...SMART_INVENTORY_DROPDOWN_MODEL }])
    }
    categoryToSupplierMap.set(category.categoryId, [
        ...(categoryToSupplierMap.get(category.categoryId) || [{ ...SMART_INVENTORY_DROPDOWN_MODEL }]),
        sup
    ])
}

/**
 * @description map exisiting category supplier module
 * @param {*} division
 * @param {*} category
 * @param {*} param2
 */
const mapExisitingCategory = (division, category, { formattedCategories, divisionToCategoryMap, categoryToDivisionMap }) => {
    const existingCategories = []
    if (!existingCategories.includes(category.categoryId)) {
        existingCategories.push(category.categoryId)
        const cat = {
            id: category.categoryId,
            title: category.categoryName,
            value: category.categoryName,
            active: true
        }
        formattedCategories.push(cat)
        isEmpty(divisionToCategoryMap.get(division.divisionId)) ? divisionToCategoryMap.set(division.divisionId, [cat]) : divisionToCategoryMap.get(division.divisionId).push(cat)
        categoryToDivisionMap.set(category.categoryId, division)
    }
}

/**
 * @description map exisiting sub category for supplier module
 * @param {*} division
 * @param {*} category
 * @param {*} sub
 * @param {*} param3
 */
const mapExistingSubCategory = (division, category, sub, { formattedSubCategories, divisionToSubCategoryMap, categoryToSubCategoryMap }) => {

    const existingSubcategories = []

    if (!existingSubcategories.includes(sub.id)) {
        formattedSubCategories.push(sub)
        existingSubcategories.push(sub.id)
    }

    isEmpty(divisionToSubCategoryMap.get(division.divisionId))
    ? divisionToSubCategoryMap.set(division.divisionId, [sub])
    : divisionToSubCategoryMap.get(division.divisionId).push(sub)

    const temp1 = [...divisionToSubCategoryMap.get(division.divisionId), sub]
    const temp2 = [...categoryToSubCategoryMap.get(category.categoryId), sub]
    divisionToSubCategoryMap.set(division.divisionId, uniqBy(temp1, 'id'))
    categoryToSubCategoryMap.set(category.categoryId, uniqBy(temp2, 'id'))
}


/**  Cognitive code split close here for smart inventory module close  **/

/*********** Cognitive code starts here for chart duration module **********/

/**
 * @description get dates view duraion
 * @param {`*`} param0
 * @returns {object} to and from dates
 */
export const getDatesViewDurationQtr = ({ duration }) => {
    let endDate = moment().day(moment().day() >= 5 ? 5 : -2)
    switch (duration.id) {
        case CHART_DURATION.quarter[0].id: {
            const startDate = moment(endDate)
            let index
            if ([0, 3, 6, 9].includes(endDate.month())) {
                index =
                    startDate
                        .startOf('month')
                        .subtract(3, 'month')
                        .endOf('month')
                        .day() >= 5
                        ? 5
                        : -2
            } else {
                index =
                    startDate
                        .startOf('quarter')
                        .endOf('month')
                        .day() >= 5
                        ? 5
                        : -2
            }
            return {
                toDate: endDate.format(yearFirstFormat),
                fromDate: startDate.day(index).format(yearFirstFormat)
            }
        }
        case CHART_DURATION.quarter[1].id: {
            let endDateIndex
            if ([0, 3, 6, 9].includes(endDate.month())) {
                endDateIndex =
                    endDate
                        .startOf('month')
                        .subtract(3, 'month')
                        .endOf('month')
                        .day() >= 5
                        ? 5
                        : -2
            } else {
                endDateIndex =
                    endDate
                        .startOf('quarter')
                        .endOf('month')
                        .day() >= 5
                        ? 5
                        : -2
            }
            const startDate = moment(endDate)
            const startDateIndex = startDate.subtract(3, 'months').day() >= 5 ? 5 : -2
            return {
                toDate: endDate.day(endDateIndex).format(yearFirstFormat),
                fromDate: startDate.day(startDateIndex).format(yearFirstFormat)
            }
        }
        case CHART_DURATION.quarter[3].id: {
            endDate = moment()
                .startOf('month')
                .subtract(1, 'days')
                .day(
                    moment()
                        .startOf('month')
                        .subtract(1, 'days')
                        .day() >= 5
                        ? 5
                        : -2
                )
            const startDate = moment(endDate)
            const index =
                startDate
                    .subtract(1, 'years')
                    .endOf('month')
                    .day() >= 5
                    ? 5
                    : -2
            return {
                toDate: endDate.format(yearFirstFormat),
                fromDate: startDate.day(index).format(yearFirstFormat)
            }
        }
        default: {
            const startDate = moment()
            const index =
                startDate
                    .startOf('year')
                    .endOf('month')
                    .day() >= 5
                    ? 5
                    : -2
            return {
                toDate: endDate.format(yearFirstFormat),
                fromDate: startDate.day(index).format(yearFirstFormat)
            }
        }
    }
}

/**
 * @description check all selected status
 * @param {*} param0
 * @returns  {boolean}
 */
export const checkAllSelected = ({ input, updated }) => {
    return input?.length > 0
        ? (input[0]?.id === 'All' || input[input?.length - 1]?.id === 'All') && updated[0]?.active === false
        : false
}

/**
 * @description check all unselected status
 * @param {*} param0
 * @returns {boolean}
 */
export const checkAllUnSelected = ({ input, updated }) => {
    return input?.length > 0
        ? input[0]?.id !== 'All' && input[input.length - 1]?.id !== 'All' && updated[0]?.active === true
        : false
}


/**
 * @description handle check and uncheck status
 * @param {*} param0
 */
export const handleCheckUnCheckStatus = ({ input, updated }) => {
    const found = []
    for (let i = 0; i < input?.length; i++) {
        found.push(input[i]?.id)
        for (let j = 0; j < updated?.length; j++) {
            if (updated[j]?.id === input[i]?.id) {
                updated[j].active = true
            } else if (!found.includes(updated[j]?.id)) {
                updated[j].active = false
            }
        }
    }
}
