import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Grid, TextField, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { omit } from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateAROlderMonthsOrders, setEditedValue } from '../../pages/AccountReceivable/AccountReceivableActions'

const styles = theme => ({
    root: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'end'
        }
    }
})
const EditableCell = ({ restProps }) => {
    const onUpdate = (event) => {
        restProps.setEditedValue(event.target.value)
    }

    return (
        <Table.Cell {...restProps}>
            <Grid item>
                <TextField
                    required
                    type="number"
                    size="small"
                    variant="outlined"
                    fullWidth
                    onKeyDown={event => ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault()}
                    defaultValue={restProps.value.replace(/\$/g, '')}
                    onChange={onUpdate}
                    className={restProps.classes?.root}
                    InputLabelProps={{
                        shrink: false
                    }}
                />
            </Grid>
        </Table.Cell>
    )
}

const NonEditableCell = ({ restProps }) => {
    return (
        <Table.Cell {...restProps}>
            <Grid item>
                <Typography variant="body2">{restProps.value}</Typography>
            </Grid>
        </Table.Cell>
    )
}

class TotalAREditableCell extends PureComponent {
    render = () => {
        const { editMode, row, editRow } = this.props
        const restProps = omit(this.props, ['updateAROlderMonthsOrders'])

        if (editMode && row.taskId === editRow?.taskId) {
            return (<EditableCell restProps={restProps} />)
        }
        return <NonEditableCell restProps={restProps} />
    }
}

TotalAREditableCell.propTypes = {
    updateAROlderMonthsOrders: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    editMode: state.AccountReceivable.isEditMode,
    editRow: state.AccountReceivable.editRow
})

export const TotalAREditableCellNaked = TotalAREditableCell

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { updateAROlderMonthsOrders, setEditedValue },
        dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TotalAREditableCell))
