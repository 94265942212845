import { Box, darken, lighten, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

const styles = theme => ({
  icon: {
    fontSize: '1.2em',
    marginRight: theme.spacing(0.5)
  },
  label: {
    display: 'inline-block',
    padding: '4px 10px',
    borderRadius: 3,
    backgroundColor: theme.palette.grey[300]
  },
  labelText: {
    textTransform: 'capitalize',
    fontSize: '0.8750em',
    fontWeight: '400'
  },
  bgGreenLight: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.greenLight
  },
  bgGreenDark: {
    color: theme.palette.common.white,
    backgroundColor: darken(theme.palette.primary.greenLight, 0.15)
  },
  bgBlue: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light
  },
  bgAmber: {
    color: theme.palette.text.pending,
    backgroundColor: theme.palette.primary.tumbleWeed
  },
  bgRed: {
    color: theme.palette.common.white,
    backgroundColor: lighten(theme.palette.primary.red, 0.3)
  },
  bgGray: {
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.grey[300]
  },
  bgGreen: {
    color: theme.palette.primary.persianBlue,
    backgroundColor: theme.palette.primary.greenParrot
  },
  bgDownloadGreen: {
    color: theme.palette.text.inStock,
    backgroundColor: theme.palette.primary.greenParrot
  },
  bgDownloadBlue: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light
  },
  bgDownloadRed: {
    color: theme.palette.text.lowStock,
    backgroundColor: theme.palette.primary.tumbleWeed
  },
  bgOOS: {
    color: theme.palette.primary.notificationBadge,
    backgroundColor: theme.palette.primary.errorRed
  },
  bgPartiallyShipped: {
    color: theme.palette.text.lowStock,
    backgroundColor: theme.palette.primary.warning
  },
  bgCreateShipnode: {
    color: theme.palette.primary.brandDarken,
    backgroundColor: theme.palette.primary.successGreen
  },
  bgActivateShipnode: {
    color: theme.palette.primary.brandDarken,
    backgroundColor: theme.palette.primary.icyLilac
  }
})

export const COLOR_MAPPING = {
  PENDING: 'bgAmber',
  ACKNOWLEDGED: 'bgBlue',
  SHIPPED: 'bgGreenDark',
  PARTIALLY_SHIPPED: 'bgPartiallyShipped',
  DELIVERED: 'bgGreenDark',
  PARTIALLY_DELIVERED: 'bgGreenLight',
  CANCELLED: 'bgGray',
  CANCELED: 'bgGray',
  PARTIALLY_CANCELLED: 'bgGray',
  PROCESSING: 'bgAmber',
  PROCESSED: 'bgGreenLight',
  PARTIALLY_PROCESSED: 'bgGreenDark',
  ERROR: 'bgRed',
  REJECTED: 'bgRed',
  APPROVED: 'bgGreen',
  GIFT_MESSAGE: 'bgGreen',
  INPROGRESS: 'bgDownloadBlue',
  COMPLETED: 'bgDownloadGreen',
  FAILED: 'bgDownloadRed',
  LOW_STOCK: 'bgDownloadRed',
  IN_STOCK: 'bgDownloadGreen',
  OUT_OF_STOCK: 'bgOOS',
  CANCELLED_PENALTY: 'bgPartiallyShipped',
  LATE: 'bgOOS',
  NEW_SHIPNODE: 'bgCreateShipnode',
  EDIT_SHIPNODE: 'bgPartiallyShipped',
  DEACTIVATION: 'bgOOS',
  ACTIVATION: 'bgActivateShipnode',
  WAIVED: 'bgRed',
  EDITEDAPPROVED: 'bgGreen'
}

const SSHLabel = ({ classes, value, isPenalty }) => {
  let LABEL_KEY = String(value)
    .replace(/\s+/g, '_')
    .toUpperCase()

  if (isPenalty && value === 'cancelled') {
    LABEL_KEY = 'CANCELLED_PENALTY'
  }
  const CLASS_NAME = COLOR_MAPPING[LABEL_KEY] || COLOR_MAPPING.CANCELLED

  const LABEL_STYLES = clsx(classes.label, classes[CLASS_NAME])

  return (
    <Box className={LABEL_STYLES} data-testid="box">
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <Typography variant="body2" className={classes.labelText}>
          {value}
        </Typography>
      </Box>
    </Box>
  )
}

SSHLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired
}

export default withStyles(styles)(SSHLabel)
