import { cloneDeep, get, orderBy } from 'lodash'
import { capitalizeSentence } from '../../utils/common'
import { RESET_FEED_ITEM_SEARCH, SEARCH_FEED_ITEM_REQUEST } from '../../pages/Feed/FeedStatus.types'
import {
  SEARCH_INVENTORY_ITEM_FAILURE,
  SEARCH_INVENTORY_ITEM_REQUEST,
  SEARCH_INVENTORY_ITEM_SUCCESS,
  UPDATE_INVENTORY_QUANTITY_REDUX
} from '../../pages/Inventory/Inventory.types'
import { MARK_TASK_AS_REJECTED_STORE } from '../../pages/LeadTime/LeadTime.actionTypes'
import { downloadListParser, feedListParser, parseFulfillmentOrders } from '../../utils/parsers'
import {
  CHANGE_PAGE_SIZE,
  CLEAR_ACCESS_LEVEL_ROLES,
  FETCH_PAGINATED_TABLE_DATA_FAILURE,
  FETCH_PAGINATED_TABLE_DATA_REQUEST,
  FETCH_PAGINATED_TABLE_DATA_SUCCESS,
  RESET_PAGINATED_TABLE,
  SET_CURRENT_PAGE,
  CLIENT_PAGINATION,
  CLIENT_SORTING,
  UPDATE_SORT_ARROW,
  RESET_SEARCH,
  RESET_FCAPACITY_TABLE
} from '../commonActions/SSHPaginatedTable.actionTypes'
import {
  SEARCH_ORDER_REQUEST,
  SEARCH_ORDER_SUCCESS,
  SEARCH_ORDER_FAILURE
} from '../../pages/FulfillmentNew/Fulfillment.types'
import { SEARCH_DOWNLOAD_ITEM_REQUEST } from '../../pages/DownloadHistory/DownloadHistory.types'
import {
  SEARCH_SMART_INVENTORY_ITEM,
  RESET_SMART_INVENTORY_ITEM_SEARCH
} from '../../pages/SmartInventory/SmartInventory.actionTypes'
import { SMART_INVENTORY_TABS } from '../../pages/SmartInventory/SmartInventoryTabSwitcher'
import { setSmartInventoryRowCount } from '../../pages/SmartInventory/SmartInventoryAction'
import { ROUTE_MODULES } from '../../config/genericConstants'
import { SEARCH_INTRANSIT_ITEM_FAILURE, SEARCH_INTRANSIT_ITEM_REQUEST, SEARCH_INTRANSIT_ITEM_SUCCESS } from '../../pages/InTransitInventory/InTransitInventory.types'
import { ACCOUNTS_RECEIVABLE } from '../../pages/Approvals/commonConstants'

export const INITIAL_STATE = {
  isFetching: false,
  accessRoles: [],
  searchQuery: null,
  pageNo: 0,
  pageSize: 25,
  pathName: '',
  rows: [],
  tmpRows: [],
  orderSearchEnabled: false,
  allowChip: false,
  lastItemSortColumn: {
    columnName: 'smartInventoryStatus',
    direction: 'desc'
  },
  sortColumn: [
    {
      columnName: 'oos',
      direction: 'desc'
    },
    {
      columnName: 'lossOfRevenue',
      direction: 'desc'
    },
    {
      columnName: 'smartInventoryCategory',
      direction: 'asc'
    },
    {
      columnName: 'smartInventorySubcategory',
      direction: 'asc'
    },
    {
      columnName: 'smartInventorySupplier',
      direction: 'asc'
    },
    {
      columnName: 'smartInventoryStatus',
      direction: 'asc'
    },
    {
      columnName: 'noOfAlerts',
      direction: 'asc'
    }
  ],
  sortItemColumn: [
    {
      columnName: 'smartInventoryItem',
      direction: 'asc'
    },
    {
      columnName: 'smartInventoryCategory',
      direction: 'asc'
    },
    {
      columnName: 'smartInventorySubcategory',
      direction: 'asc'
    },
    {
      columnName: 'smartInventorySupplier',
      direction: 'asc'
    },
    {
      columnName: 'smartInventoryStatus',
      direction: 'desc'
    }
  ]
}

/**
 * @description LTM pagination set page no and page size to grid show respective records
 * @param {object} state
 */
const clientPagination = state => {
  const newRows = state?.tmpRows?.slice(state.pageNo * state.pageSize, state.pageNo * state.pageSize + state.pageSize)
  return {
    ...state,
    isFetching: false,
    rows: newRows?.length > 0 ? newRows : state?.tmpRows,
    pageNo: newRows?.length > 0 ? state?.pageNo : 0,
    pageSize: state?.pageSize,
    tmpRows: state?.tmpRows
  }
}

/**
 * @description Client side sorting in data table
 * @param {object} state
 */
const clientSorting = (state, action) => {
  let { colName, colSortType } = action.payload
  const updatedSortColumn = state.sortColumn

  let rows = []
  switch (colName) {
    case 'oos':
      updatedSortColumn[0].direction = colSortType
      break
    case 'smartInventoryCategory':
      updatedSortColumn[2].direction = colSortType
      colName = 'category'
      break
    case 'smartInventorySubcategory':
      updatedSortColumn[3].direction = colSortType
      colName = 'subcategory'
      break
    case 'smartInventorySupplier':
      updatedSortColumn[4].direction = colSortType
      colName = 'supplierName'
      break
    case 'smartInventoryStatus':
      updatedSortColumn[5].direction = colSortType
      colName = 'status'
      break
    case 'noOfAlerts':
      updatedSortColumn[6].direction = colSortType
      break
    default:
      updatedSortColumn[1].direction = colSortType
  }

  const sortColumnText = col => {
    if (['subcategory', 'category', 'supplier'].includes(colName)) {
      return capitalizeSentence(col[colName])
    }
    return col[colName]
  }
  state.tmpRows = orderBy(state.tmpRows, [sortColumnText], [colSortType])
  rows = clientPagination(state).rows
  return {
    ...state,
    isFetching: false,
    rows,
    tmpRows: state.tmpRows,
    sortColumn: updatedSortColumn
  }
}

/**
 * @description Handling of row data for SmartInventory module
 * @param {object} state
 * @param {object} action
 */
const setSmartInventoryRowsData = (state, action) => {
  let rows = []
  if (
    [SMART_INVENTORY_TABS.CATEGORIES, SMART_INVENTORY_TABS.SUPPLIERS, SMART_INVENTORY_TABS.DIVISION].includes(
      action?.payload?.selectedTab
    )
  ) {
    if (action?.payload?.type === 'search') {
      rows = get(action, 'payload.updatedAggregatedRows', [])
      setSmartInventoryRowCount({ count: rows.length }) // set total count
    } else {
      rows = get(action, 'response.payload.aggregatedAlerts', [])
    }
    // eslint-disable-next-line no-param-reassign
    const updatedRows = rows.map(row => {
      const updatedRow = row
      updatedRow.oos = parseFloat(parseFloat(row.oos).toFixed(2))
      updatedRow.lossOfRevenue = parseFloat(parseFloat(row.lossOfRevenue).toFixed(2))
      updatedRow.noOfAlerts = parseInt(row.noOfAlerts, 10)
      return updatedRow
    })
    state.tmpRows = orderBy(updatedRows, [state.sortColumn[1].columnName], [state.sortColumn[1].direction])
    rows = clientPagination(state).rows
  } else if (action?.payload?.selectedTab === SMART_INVENTORY_TABS.ITEMS) {
    rows = get(action, 'response.payload.itemAlertsList', [])
  }

  return rows
}

export default function (state = cloneDeep(INITIAL_STATE), action = {}) {
  switch (action.type) {
    case RESET_PAGINATED_TABLE:
      return { ...INITIAL_STATE, isFetching: true, pathName: state.pathName }

    case CHANGE_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload.pageSize
      }

    case SET_CURRENT_PAGE:
      return {
        ...state,
        pageNo: action.payload.pageNo
      }

    case FETCH_PAGINATED_TABLE_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
        isReset: true,
        pathName: get(action, 'payload.pathName', null)
      }

    case FETCH_PAGINATED_TABLE_DATA_SUCCESS: {
      const { pathName } = action.payload
      if (pathName !== state.pathName) return { ...state }
      let rows = null

      switch (pathName) {
        case ROUTE_MODULES?.fulfilment?.orderfulfilment?.path:
          rows = parseFulfillmentOrders(get(action, 'response.payload', []))
          break;
        case ROUTE_MODULES?.prepurchase?.inventory?.path:
          rows = get(action, 'response.payload.itemList', [])
          break;
        case ROUTE_MODULES?.postpurchase?.returnprocessing?.path:
          rows = get(action, 'response.payload.ordersList', [])
          break;
        case ROUTE_MODULES?.toolsreporting?.uploadrequest?.path:
        case ROUTE_MODULES?.toolsreporting?.adminuploadrequest?.path:
          rows = feedListParser(get(action, 'response.feedLists', []))
          break;
        case ROUTE_MODULES?.toolsreporting?.downloads?.path:
          rows = downloadListParser(get(action, 'response.payload.downloadDetails', [action.response.payload]))
          break;
        case ROUTE_MODULES?.prepurchase?.smartinventory?.path:
          rows = setSmartInventoryRowsData(state, action)
          break;
        case ROUTE_MODULES.toolsreporting?.approvals?.path:
        case ROUTE_MODULES.fulfilment?.supppliermanagement?.path:
          const { selectedApprovalsTab, selectedARMonth } = action.payload
          if (selectedApprovalsTab === ACCOUNTS_RECEIVABLE && selectedARMonth?.id === 1) {
            rows = get(action, 'response.payload.details', [])
            break;
          } else {
            rows = get(action, 'response.payload.tasks', [])
            break;
          }
        case ROUTE_MODULES?.performance?.monthpenalty?.path:
          rows = get(action, 'response.payload.details', [])
          break;
        case ROUTE_MODULES?.prepurchase?.intransitinventory?.path:
        case ROUTE_MODULES?.inventorypricing?.pricingservice?.path:
        case ROUTE_MODULES?.inventorypricing?.outofstock?.path:
          rows = get(action, 'response.payload.items', [])
          break;
        case ROUTE_MODULES?.forecasting?.clubsaleforecast?.path:
        case ROUTE_MODULES?.forecasting?.cluborderdemand?.path:
          rows = get(action, 'response.items', [])
          break;
        case pathName.match(/^\/club\/sales-forecast\/vendor\/\d*\/item\/\d*/) ? pathName : undefined:
        case pathName.match(/^\/club\/order-demand\/vendor\/\d*\/item\/\d*/) ? pathName : undefined:
          rows = get(action, 'response.clubs', [])
          break;
        case ROUTE_MODULES?.fulfilment?.leadtime?.path:
          rows = get(action, 'response.payload', [])
          break;
        case pathName.match(/^\/lead-time-capacity\/changelog\/\d*/) ? pathName : undefined:
          rows = get(action, 'response.payload.capacity', [])
          break;
        default:
          rows = []
      }
      return {
        ...state,
        isFetching: false,
        rows
      }
    }

    case SEARCH_INVENTORY_ITEM_FAILURE:
    case SEARCH_INTRANSIT_ITEM_FAILURE:
    case FETCH_PAGINATED_TABLE_DATA_FAILURE: {
      return {
        ...state,
        isFetching: false
      }
    }

    case UPDATE_INVENTORY_QUANTITY_REDUX: {
      const { itemId, availableInventory } = action.payload
      return {
        ...state,
        rows: [
          ...state.rows.map(row => {
            if (String(row.itemId) === String(itemId)) return { ...row, availableInventory }
            return { ...row }
          })
        ]
      }
    }

    case SEARCH_INVENTORY_ITEM_REQUEST:
      return {
        ...state,
        isFetching: true,
        searchQuery: action.payload.itemNumber,
        pathName: action.payload.pathName
      }

    case SEARCH_INVENTORY_ITEM_SUCCESS: {
      const { pathName } = action.payload

      if (pathName !== state.pathName) return { ...state }
      return {
        ...state,
        isFetching: false,
        rows: action.response.payload.itemList
      }
    }

    case SEARCH_FEED_ITEM_REQUEST:
    case SEARCH_DOWNLOAD_ITEM_REQUEST:
    case SEARCH_SMART_INVENTORY_ITEM:
    case SEARCH_INTRANSIT_ITEM_REQUEST:
      return {
        ...state,
        isFetching: true,
        searchQuery: action.payload.searchQuery
      }

    case RESET_FEED_ITEM_SEARCH:
    case RESET_SMART_INVENTORY_ITEM_SEARCH:
      return {
        ...state,
        isFetching: false,
        searchQuery: null
      }
    case RESET_SEARCH:
      return {
        ...state,
        searchQuery: null
      }
    case CLEAR_ACCESS_LEVEL_ROLES:
      if (action.isClearRow) {
        return {
          ...state,
          isReset: true,
          accessRoles: [],
          rows: []
        }
      }
      return { ...state }

    case MARK_TASK_AS_REJECTED_STORE:
      return {
        ...state,
        rows: action.payload.tasks
      }

    case CLIENT_PAGINATION:
      return clientPagination(state)

    case SEARCH_ORDER_REQUEST:
      return {
        ...state,
        isFetching: true,
        orderSearchEnabled: true,
        allowChip: true,
        pathName: action.payload.pathName
      }

    case SEARCH_ORDER_SUCCESS: {
      const { pathName } = action.payload

      if (pathName !== state.pathName) return { ...state }
      return {
        ...state,
        isFetching: false,
        allowChip: false,
        rows: get(action, 'response.payload', [])
      }
    }
    case SEARCH_ORDER_FAILURE:
      return {
        ...state,
        orderSearchEnabled: false,
        allowChip: false,
        isFetching: false
      }

    case SEARCH_INTRANSIT_ITEM_SUCCESS: {
      const { pathName } = action.payload

      if (pathName !== state.pathName) {
        return { ...state }
      }
      return {
        ...state,
        isFetching: false,
        rows: action.response.payload.items
      }
    }

    case CLIENT_SORTING:
      return clientSorting(state, action)

    case UPDATE_SORT_ARROW: {
      // eslint-disable-next-line no-case-declarations
      const updatedSortColumn = cloneDeep(state.sortItemColumn)

      switch (action.payload?.colName) {
        case 'smartInventoryCategory':
          updatedSortColumn[1].direction = action.payload?.sortType
          break
        case 'smartInventorySubcategory':
          updatedSortColumn[2].direction = action.payload?.sortType
          break
        case 'smartInventorySupplier':
          updatedSortColumn[3].direction = action.payload?.sortType
          break
        case 'smartInventoryStatus':
          updatedSortColumn[4].direction = action.payload?.sortType
          break
        default:
          updatedSortColumn[0].direction = action.payload?.sortType
      }
      return {
        ...state,
        sortItemColumn: updatedSortColumn,
        lastItemSortColumn: { columnName: action.payload?.colName, direction: action.payload?.sortType }
      }
    }

    case RESET_FCAPACITY_TABLE:
      return {
        ...state,
        isFetching:false,
        rows:[]
      }
    default:
      return { ...state }
  }
}
