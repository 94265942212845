export const COLUMNS = [{
    name: 'Cases per pallet', value: 'casesPerPallet'
}, {
    name: 'Deal min qty', value: 'dealMinimumQuantity'
}, {
    name: 'Item min qty', value: 'itemMinimumQuantity'
}, {
    name: 'Code', value: 'code'
}, {
    name: 'Start date', value: 'startDate'
}, {
    name: 'End date', value: 'endDate'
}, {
    name: 'Price', value: 'price'
}, {
    name: 'Opportunity type', value: 'opportunityType'
}]
