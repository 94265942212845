import React, { PureComponent } from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SSHTooltip from '../../components/SSHTooltip'
import { Box } from '@mui/material'
import { withStyles } from "@mui/styles"
import { ReactComponent as EditIcon } from '../../assets/images/Edit.svg'
import EditDrawer from './EditDrawer'
import { allowAccess } from '../../utils/common'
import { ROLE_BACK_ACTIONS } from '../../config/genericConstants'
import { enableEditScheduler, updateInTransitInventory } from '../../pages/InTransitInventory/InTransitInventoryAction'

const styles = theme => ({
    edit: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(3),
        cursor: 'pointer',
    }
})

/**
 * @description flexscape component to customize toolbar for scheduler component
 */
class ToggleEdit extends PureComponent {

    render() {
        const { editTooltip, classes, isEditScheduler, enableEditScheduler, updateInTransitInventory } = this.props
        return (
            <>
                {allowAccess(ROLE_BACK_ACTIONS.EDIT) &&
                    <Box className={classes.edit}>
                        <SSHTooltip title={editTooltip}>
                            <EditIcon onClick={() => enableEditScheduler(true)} />
                        </SSHTooltip>
                    </Box>
                }
                {isEditScheduler && <EditDrawer isOpen={isEditScheduler} onCancel={() => enableEditScheduler(false)} applySearch={updateInTransitInventory} />}
            </>
        )
    }
}


ToggleEdit.propTypes = {
    editTooltip: PropTypes.string,
    enableEditScheduler: PropTypes.func
}

ToggleEdit.defaultProps = {
    editTooltip: 'Edit scheduler'
}

const mapStateToProps = state => ({
    isEditScheduler: state.InTransitInventory.isEditScheduler
})
export const ToggleEditNaked = ToggleEdit

const mapDispatchToProps = dispatch => bindActionCreators({ enableEditScheduler, updateInTransitInventory }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ToggleEdit))
