/* eslint-disable react/jsx-no-bind */
import {
    FormGroup,
    FormControl,
    FormControlLabel,
    Grid,
    RadioGroup,
    Typography,
    TextField
} from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { isEmpty, get } from 'lodash'
import { Edit } from '@mui/icons-material'
import clsx from 'clsx'
import { SSHRadio } from '../../../../components/SSHRadio'
import SSHButton from '../../../../components/SSHButton'
import SSHCheckbox from '../../../../components/SSHCheckbox'
import SSHSelectBox from '../../../../components/SSHSelectBox'
import { setShipmentFlags } from '../../OrderDetailsActions'
import { toggleCarrierRegistrationPopup } from '../../../FulfillmentNew/FulfillmentAction'
import ShipmentItemsTable from './ShipmentItemsTable'

const styles = theme => ({
    billing: {
        width: '80%',
        textAlign: 'center',
        lineHeight: `${theme.spacing(0.25)}px`
    },
    disabled: {
        color: theme.palette.text.disabled
    }
})

class ShipmentForm extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            shipmentType: 'prepaid',
            hasCollect: false,
            hasPrepaid: false
        }
    }

    static getDerivedStateFromProps = ({ hasPrepaid, hasCollect, manual }) => {
        if (!manual) {
            if (hasPrepaid) {
                return { shipmentType: 'prepaid' }
            }
            if (hasCollect) {
                return { shipmentType: 'collect' }
            }
        }
        return {}
    }

    render = () => {
        const {
            items,
            manual,
            selectedCarrier,
            setShipmentFlags,
            selectCarrierMethod,
            carrierMethods,
            carrierAccount,
            setTrackingNumber,
            flags,
            trackingNumber,
            isSaving,
            toggleShipmentType,
            disableAutoShip,
            updateQtyToShip,
            toggleCarrierRegistrationPopup,
            ...restProps
        } = this.props

        const { shipmentType } = this.state

        return (
            <>
                <Grid container spacing={2} justifyContent='flex' direction='column'>
                    <Grid item container alignItems='center'>
                        <Grid item xs={2}>
                            <Typography variant='body2'>
                                Shipping type <Typography variant="caption" color='error'>*</Typography> :
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <FormControl component="shipment-type-selection">
                                <RadioGroup
                                    aria-label="shipment-type-selection"
                                    name="shipType"
                                    value={manual ? 1 : 0}
                                    onChange={toggleShipmentType} row>
                                    <FormControlLabel
                                        value={1}
                                        disabled={isSaving}
                                        control={<SSHRadio color="primary" />}
                                        label={<Typography variant="body2">Manual shipment</Typography>}
                                    />
                                    <FormControlLabel
                                        value={0}
                                        disabled={disableAutoShip || isSaving}
                                        control={<SSHRadio color="primary" />}
                                        label={[
                                            <Typography variant="body2">
                                                Generate shipping label and capture tracking details
                                            </Typography>
                                        ]}
                                    />
                                </RadioGroup>
                                <Typography variant="body2" className={clsx(restProps.classes.billing, { [restProps.classes.disabled]: disableAutoShip || isSaving })}>
                                    Billed through Sam’s Club Carrier account (Collect) Or Supplier’s account (Prepaid)
                                </Typography><br />
                            </FormControl>
                        </Grid>
                    </Grid>
                    {
                        !manual &&
                        <Grid item container alignItems='center'>
                            <Grid item xs={2}>
                                <Typography variant='body2' >
                                    Shipping account :
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography>
                                    {restProps.prepaidActive ? 'Supplier (Prepaid)' : `Sam's club (Collect)`}

                                </Typography>
                                <Typography variant='body2'>
                                    {
                                        restProps.prepaidActive && <>
                                            {
                                                isEmpty(carrierAccount) ?
                                                    <>
                                                        Your preferred carrier does not exist ?
                                                        <SSHButton text={'Register it'} color="primary" onClick={toggleCarrierRegistrationPopup} />
                                                    </> :
                                                    <>
                                                        Billing through your supplier carrier account
                                                        <SSHButton text={`${get(carrierAccount, '[0].carrierAccountId', 'Unknown')}`}
                                                            iconRight
                                                            Icon={Edit}
                                                            color="primary"
                                                            onClick={toggleCarrierRegistrationPopup} />
                                                    </>
                                            }
                                        </>
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    {
                        manual && <><Grid item container alignItems='center'>
                            <Grid item xs={2}>
                                <Typography variant='body2' >
                                    Carrier name <Typography variant="caption" color='error'>*</Typography> :
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <SSHSelectBox
                                    size="small"
                                    label="Select a carrier"
                                    onSelect={selectCarrierMethod}
                                    defaultVal={selectedCarrier}
                                    options={carrierMethods}
                                />
                                {isEmpty(selectedCarrier.value) && (
                                    <Typography variant="caption" color="error">
                                        Carrier selection is mandatory
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                            <Grid item container alignItems='center'>
                                <Grid item xs={2}>
                                    <Typography variant='body2' >
                                        Tracking number <Typography variant="caption" color='error'>*</Typography> :
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        defaultValue={trackingNumber}
                                        error={isEmpty(trackingNumber.trim())}
                                        fullWidth
                                        onChange={setTrackingNumber}
                                        placeholder='Enter tracking no'
                                    />
                                    {isEmpty(trackingNumber.trim()) && (
                                        <Typography variant="caption" color="error">
                                            Tracking number is mandatory
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid></>
                    }
                    {
                        !manual && <Grid item container alignItems='center'>
                            <Grid item xs={2}>
                                Special attributes :
                            </Grid>
                            <Grid item xs={10}>
                                <FormControl component="special-attributes">
                                    <FormGroup row spacing={4}>
                                        <SSHCheckbox
                                            handleChange={setShipmentFlags.bind(this, {
                                                shipmentType,
                                                fieldName: 'alcohol'
                                            })}
                                            checked={flags[shipmentType].alcohol === true}
                                            label="Contains alcohol"
                                        />
                                        <SSHCheckbox
                                            handleChange={setShipmentFlags.bind(this, {
                                                shipmentType,
                                                fieldName: 'signature'
                                            })}
                                            checked={flags[shipmentType].signature === true}
                                            label="Signature required"
                                        />
                                        <SSHCheckbox
                                            handleChange={setShipmentFlags.bind(this, {
                                                shipmentType,
                                                fieldName: 'hazard'
                                            })}
                                            checked={flags[shipmentType].hazard === true}
                                            label="Contains hazardous materials"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }
                    <Grid item container alignItems='center'>
                        <Grid item xs={2}>
                            <Typography variant='body2'>
                                Selected items ({items.length}) :
                            </Typography>
                        </Grid>
                        <Grid xs={10}>
                            <ShipmentItemsTable
                                items={items}
                                activeTab={false}
                                showTab={false}
                                updateQtyToShip={updateQtyToShip}
                                removeSelecetdItem={restProps.removeSelecetdItem}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }
}

ShipmentForm.propTypes = {
    classes: PropTypes.object.isRequired,
    selectedRows: PropTypes.array.isRequired,
    updateQtyToShip: PropTypes.func.isRequired,
    selectedCarrier: PropTypes.object,
    flags: PropTypes.object,
    carrierAccount: PropTypes.array,
    carrierMethods: PropTypes.array,
    manual: PropTypes.bool,
    hasPrepaid: PropTypes.bool,
    hasCollect: PropTypes.bool,
    // eslint-disable-next-line react/no-unused-prop-types
    hasBoth: PropTypes.bool,
    trackingNumber: PropTypes.string,
    toggleCarrierRegistrationPopup: PropTypes.func.isRequired,
    setTrackingNumber: PropTypes.func.isRequired
}

ShipmentForm.defaultProps = {
    selectedCarrier: {},
    carrierMethods: [],
    flags: { prepaid: {}, collect: {} },
    carrierAccount: [],
    manual: true,
    hasPrepaid: false,
    hasCollect: false,
    hasBoth: false,
    trackingNumber: ''
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setShipmentFlags,
            toggleCarrierRegistrationPopup
        },
        dispatch
    )
export default connect(null, mapDispatchToProps)(withStyles(styles)(ShipmentForm))
