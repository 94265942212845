/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-wrap-multilines */
import { Grid, Typography, Box } from '@mui/material'
import { withStyles } from '@mui/styles'
import { ReactComponent as CalendarIcon } from '../../assets/images/Calendar1.svg'
import { ReactComponent as MapPinIcon } from '../../assets/images/MapPin.svg'
import { ReactComponent as ReleaseIcon } from '../../assets/images/Release.svg'
import { filter, get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import moment from 'moment'
import SSHSkeleton from '../../components/SSHSkeleton'
import { capitalizeSentence } from '../../utils/common'
import { ORDER_STATUSES } from '../../config/genericConstants'
import CancelPopupGeneric from './Popups/ItemQuantity/CancelPopupGeneric'

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.headerBackground,
    borderBottom: `1px solid ${theme.palette.primary.columbiaBlue}`,
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`
  },
  icon: {
    width: theme.spacing(1.8),
    height: theme.spacing(1.8),
    marginBottom: -theme.spacing(0.2),
    marginRight: theme.spacing(1)
  },
  lineHeight: {
    lineHeight: '1.6em',
    fontWeight: '400',
    fontSize: theme.spacing(2.25),
    '& span , & div': {
      color: theme.palette.primary.tropaz,
      marginLeft: theme.spacing(3),
      fontSize: theme.spacing(1.75)
    }
  },
  lastGrid: {
    justifySelf: 'flex-end',
    marginLeft: 'auto'
  }
})

const OrderDetails = ({ classes, order, isFetching, updateItemDetails, orderResult, showAlert }) => {
  const { basicDetails, shippingDetails } = isEmpty(order)
    ? {}
    : get(order, 'poReleaseList[0]', { basicDetails: {}, shippingDetails: {} })

  const { updateSuccess, message } = orderResult
  const [isCanceled, setConfirmCancel] = useState(false)

  const onConfirmCancel = () => {
    setConfirmCancel(false)
    updateItemDetails({ poNumber: basicDetails.poNumber, status: ORDER_STATUSES[3].value })
  }


  const getReleaseCount = () => {
    const totalRelases = filter(order?.poReleaseList, (release) => {
      return parseInt(release.basicDetails.releaseNumber) !== 0
    })
    return isEmpty(totalRelases) ? 0 : totalRelases.length
  }

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          {updateSuccess && showAlert(message)}
        </Grid>
        <Grid item xs={3} md={2}>
          <Typography variant="body2" className={classes.lineHeight}>
            <CalendarIcon className={classes.icon} /> Dates
            <br />
            {isFetching
              ? [
                <SSHSkeleton variant="text" animation="wave" width="50%" />,
                <SSHSkeleton variant="text" animation="wave" width="50%" />
              ]
              : [
                <span>Ordered: {basicDetails.orderDate}</span>,
                <br />,
                <span>
                  Ship by:{' '}
                  {
                    !isEmpty(basicDetails.shipmentDate) ?
                      moment(basicDetails.shipmentDate, 'MM/DD/YYYY')
                        .zone(-600)
                        .format('MMM DD, YYYY') : '-'
                  }
                </span>
              ]}
          </Typography>
        </Grid>
        <Grid item xs={3} md={2}>
          <Typography variant="body2" className={classes.lineHeight}>
            <MapPinIcon className={classes.icon} /> Shipping address
            <br />
            {isFetching
              ? [
                <SSHSkeleton variant="text" animation="wave" width="50%" />,
                <SSHSkeleton variant="text" animation="wave" width="50%" />
              ]
              : [
                <div>
                  {`${capitalizeSentence(shippingDetails.customerName)} `}
                  {shippingDetails.shippingAddress}
                </div>
              ]}
          </Typography>
        </Grid>
        <Grid item xs={3} md={2}>
          <Typography variant="body2" className={classes.lineHeight}>
            <ReleaseIcon className={classes.icon} /> Number of releases
            <br />
            {isFetching ? (
              <SSHSkeleton variant="text" animation="wave" width="50%" />
            ) : (
              <span>
                {getReleaseCount()}
              </span>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CancelPopupGeneric
            onCancel={() => {
              setConfirmCancel(!isCanceled)
            }}
            onConfirm={onConfirmCancel}
            open={isCanceled}
            popupType="order"
          />
        </Grid>
      </Grid>
    </Box>
  )
}
export const OrderDetailsNaked = OrderDetails
OrderDetails.propTypes = {
  order: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(OrderDetails)
