import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

const styles = () => ({
  noPad: {
    padding: 0
  },
  noMargin: {
    marginLeft: 0,
    marginRight: 0
  }
})

const SSHCheckbox = ({ classes, dense, noMargin, size, checked, value, handleChange, label, name, color }) => {
  return (
    <FormControlLabel
      classes={{ root: clsx({ [classes.noMargin]: !!noMargin }) }}
      control={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Checkbox
          classes={{ root: clsx({ [classes.noPad]: !!dense }) }}
          checked={checked}
          onChange={handleChange}
          value={value}
          color={color || 'primary'}
          size={size}
          name={name}
        />
      }
      label={<Typography variant="body2">{label}</Typography>}
    />
  )
}

SSHCheckbox.defaultProps = {
  label: '',
  checked: false,
  handleChange: () => null,
  value: 'checkbox',
  color: '',
  name: 'checkbox',
  dense: false,
  noMargin: false,
  size: 'medium'
}

SSHCheckbox.propTypes = {
  label: PropTypes.string,
  classes: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  dense: PropTypes.bool,
  noMargin: PropTypes.bool,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium'])
}

export default withStyles(styles)(SSHCheckbox)
