export const ERROR = 'ERROR'
export const CLOSE_ALERT = 'CLOSE_ALERT'

/* === New action types === */
export const FETCH_CARRIER_METHODS_REQUEST = 'FETCH_CARRIER_METHODS_REQUEST'
export const FETCH_CARRIER_METHODS_SUCCESS = 'FETCH_CARRIER_METHODS_SUCCESS'
export const FETCH_CARRIER_METHODS_FAILURE = 'FETCH_CARRIER_METHODS_FAILURE'

export const FETCH_CARRIER_ACCOUNT_REQUEST = 'FETCH_CARRIER_ACCOUNT_REQUEST'
export const FETCH_CARRIER_ACCOUNT_SUCCESS = 'FETCH_CARRIER_ACCOUNT_SUCCESS'
export const FETCH_CARRIER_ACCOUNT_FAILURE = 'FETCH_CARRIER_ACCOUNT_FAILURE'

export const UPDATE_CARRIER_ACCOUNT_REQUEST = 'UPDATE_CARRIER_ACCOUNT_REQUEST'
export const UPDATE_CARRIER_ACCOUNT_SUCCESS = 'UPDATE_CARRIER_ACCOUNT_SUCCESS'
export const UPDATE_CARRIER_ACCOUNT_FAILURE = 'UPDATE_CARRIER_ACCOUNT_FAILURE'

export const TOGGLE_CARRIER_REGISTRATION_POPUP = 'TOGGLE_CARRIER_REGISTRATION_POPUP'

export const FETCH_ORDER_COUNT_REQUEST = 'FETCH_ORDER_COUNT_REQUEST'
export const FETCH_ORDER_COUNT_SUCCESS = 'FETCH_ORDER_COUNT_SUCCESS'
export const FETCH_ORDER_COUNT_FAILURE = 'FETCH_ORDER_COUNT_FAILURE'

export const CHANGE_SELECTED_ORDER_TYPE = 'CHANGE_SELECTED_ORDER_TYPE'
export const CHANGE_TAB_FULFILLMENT = 'CHANGE_TAB_FULFILLMENT'

export const FETCH_ORDER_DETAILS_REQUEST = 'FETCH_ORDER_DETAILS_REQUEST'
export const FETCH_ORDER_DETAILS_FAILURE = 'FETCH_ORDER_DETAILS_FAILURE'
export const FETCH_ORDER_DETAILS_SUCCESS = 'FETCH_ORDER_DETAILS_SUCCESS'

export const INITIATE_PICK_LIST_DL_REQUEST = 'INITIATE_PICK_LIST_DL_REQUEST'
export const INITIATE_PICK_LIST_DL_FAILURE = 'INITIATE_PICK_LIST_DL_FAILURE'
export const INITIATE_PICK_LIST_DL_SUCCESS = 'INITIATE_PICK_LIST_DL_SUCCESS'

export const CHECK_PICK_LIST_DL_STATUS_REQUEST = 'CHECK_PICK_LIST_DL_STATUS_REQUEST'
export const CHECK_PICK_LIST_DL_STATUS_FAILURE = 'CHECK_PICK_LIST_DL_STATUS_FAILURE'
export const CHECK_PICK_LIST_DL_STATUS_SUCCESS = 'CHECK_PICK_LIST_DL_STATUS_SUCCESS'

export const GET_PICK_LIST_FILE_REQUEST = 'GET_PICK_LIST_FILE_REQUEST'
export const GET_PICK_LIST_FILE_FAILURE = 'GET_PICK_LIST_FILE_FAILURE'
export const GET_PICK_LIST_FILE_SUCCESS = 'GET_PICK_LIST_FILE_SUCCESS'

export const UPDATE_FULFILLMENT_RELEASE_REQUEST = 'UPDATE_FULFILLMENT_RELEASE_REQUEST'
export const UPDATE_FULFILLMENT_RELEASE_SUCCESS = 'UPDATE_FULFILLMENT_RELEASE_SUCCESS'
export const UPDATE_FULFILLMENT_RELEASE_FAILURE = 'UPDATE_FULFILLMENT_RELEASE_FAILURE'

export const SHOW_FULFILLMENT_UPDATE_POPUP = 'SHOW_FULFILLMENT_UPDATE_POPUP'
export const HIDE_FULFILLMENT_UPDATE_POPUP = 'HIDE_FULFILLMENT_UPDATE_POPUP'
export const UNSAVED_CHANGES_CONFIRMATION_POPUP = 'UNSAVED_CHANGES_CONFIRMATION_POPUP'
export const HIDE_UNSAVED_CHANGES_CONFIRMATION_POPUP = 'HIDE_UNSAVED_CHANGES_CONFIRMATION_POPUP'
export const CHANGE_ACTIVE_PURCHASE_ORDER = 'CHANGE_ACTIVE_PURCHASE_ORDER'
export const CHANGE_FULFILLMENT_ITEM_DETAILS = 'CHANGE_FULFILLMENT_ITEM_DETAILS'
export const RESET_RELEASE_CHANGES = 'RESET_RELEASE_CHANGES'
export const RESET_FULFILLMENT_DATA = 'RESET_FULFILLMENT_DATA'

export const SEARCH_ORDER_REQUEST = 'SEARCH_ORDER_REQUEST'
export const SEARCH_ORDER_SUCCESS = 'SEARCH_ORDER_SUCCESS'
export const SEARCH_ORDER_FAILURE = 'SEARCH_ORDER_FAILURE'

export const SET_SEARCH_KEY_VALUE = 'SET_SEARCH_KEY_VALUE'
export const CLEAR_SEARCH_KEY_VALUE = 'CLEAR_SEARCH_KEY_VALUE'
export const CLEAR_ORDER_SEARCH = 'CLEAR_ORDER_SEARCH'
export const REMOVE_DATE_FILTER = 'REMOVE_DATE_FILTER'
export const SET_ORDER_STATUS = 'SET_ORDER_STATUS'
export const REMOVE_STATUS_FILTER = 'REMOVE_STATUS_FILTER'
export const SEARCH_PARAMETER_COUNT = 'SEARCH_PARAMETER_COUNT'
export const SEARCH_ORDER_SORTING = 'SEARCH_ORDER_SORTING'

export const MAINTAIN_STATUS_TAB = 'MAINTAIN_STATUS_TAB'

export const SET_ORDER_SORT_TYPE = 'SET_ORDER_SORT_TYPE'
