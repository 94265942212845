import React from 'react'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SSHTooltip from '../../components/SSHTooltip'
import { ArrayFormatter } from './CustomFieldTypeProvider'
import { goToChangeLog } from '../../pages/Capacity/CapacityActions'

const styles = theme => ({
    link: {
        color: theme.palette.primary.blue,
        cursor: 'pointer'
    }
})

const FcapShipNodeEditCell = ({ classes, goToChangeLog, ...props }) => {
    const tooltip = ''
    const onClick = async () => {
        const { row } = props
        return goToChangeLog({
            shipnode: {
                id: row?.shipNodeId,
                name: row?.shipNodeName
            }
        })
    }

    return (
        <Table.Cell {...props}>
            <SSHTooltip title={tooltip} placement="top-start">
                <Typography variant="body2" className={classes.link} onClick={onClick}>
                    <ArrayFormatter value={props.value} noTooltip />
                </Typography>
            </SSHTooltip>
        </Table.Cell>
    )
}

FcapShipNodeEditCell.propTypes = {
    classes: PropTypes.object.isRequired,
    props: PropTypes.object.isRequired,
    gotoChangeLog: PropTypes.func
}

const mapDispatchToProps = dispatch => bindActionCreators({ goToChangeLog }, dispatch)
export default connect(null, mapDispatchToProps)(withStyles(styles)(FcapShipNodeEditCell))
export const FcapShipNodeEditNaked = FcapShipNodeEditCell
