import { Table } from '@devexpress/dx-react-grid-material-ui'
import React from 'react'
import { connect } from 'react-redux'
import SSHLabel from '../../components/SSHLabel'

/**
 * @description Component to render custom table cell for Supplier mngt request type
 * @returns React element with the shipnode request information
 */
const ShipnodeRequestTypeCell = ({ row, ...props }) => {
  const STATUS = {
    CREATE: 'New Shipnode',
    UPDATE: 'Edit Shipnode',
    DEACTIVATE: 'Deactivation',
    ACTIVATE: 'Activation'
  }
  return (
    <Table.Cell {...props} value={row.requestType} style={{ padding: 0, paddingLeft: 8 }}>
      <SSHLabel value={STATUS[row.requestType]} />
    </Table.Cell>
  )
}

export default connect(null, null)(ShipnodeRequestTypeCell)
