/* eslint-disable react/no-unused-prop-types */
import { Grid, Slide } from '@mui/material'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { find, findIndex, isEmpty } from 'lodash'
import { changePage } from '../../../reduxSetup/commonActions/AppConfig'
import ReturnOrderAck from './ReturnOrderAck'
import ReturnOrderDetails from './ReturnOrderDetails'
import ReturnDetailsTable from './ReturnDetailsTable'
import { updateOrderCarrierTracking } from '../ReturnOrderAction'
import { getDataFromURL } from '../../../utils/common'
import * as gConstants from '../../../config/genericConstants'

class ReturnsDetails extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      ordersList: {}
    }
  }

  static getDerivedStateFromProps = ({ pendingOrders, acknowledgeOrders, selectedReturnTab }, { ordersList }) => {
    const orders = (selectedReturnTab === gConstants.ORDER_STATUSES[0].value) ? pendingOrders : acknowledgeOrders
    if (!isEmpty(orders) && isEmpty(ordersList)) {
      const orderNo = getDataFromURL('order')
      const ordersList = find(orders, { orderNo })
      return { ordersList }
    }
    return {}
  }

  /**
   * @description go to returns page
   * @returns {object} return page
   */
  goToReturnsPage = async () => {
    const { changePage } = this.props
    return changePage('/returns')
  }

  /**
   * @description select carreir
   * @param {object} {name,value}
   * @param {object} item
   */
  selectCarrier = ({ name, value }, { item }) => {
    this.changeCarrierTrackingValue({ name, value }, { item, type: 'carrier' })
  }

  /**
   * @description change return order quantity for orderline
   * @param {*} item
   */
  onChangeOrderRecievedQty = ({ e, item }) => {
    const reg = /\d*/;
    const { value } = e.target
    if (reg.test(value)) {
      this.changeCarrierTrackingValue({ name: value }, { item, type: 'returnedQty' })
    }
  }

  /** @description on change carrier to others
  * @param {object} {name,value}
  * @param {object} item
  */
  onSelectOtherCarrier = ({ e, item }) => {
    const { value } = e.target
    this.changeCarrierTrackingValue({ name: value }, { item, type: 'otherCarrier' })
  }

  /**
   * @description change tracking number for orderline
   * @param {*} item
   */
  onChangeTrackingNumber = ({ e, item }) => {
    const { value } = e.target
    this.changeCarrierTrackingValue({ name: value }, { item, type: 'tracking' })
  }

  /**
   * @description reset crarreir tracking
   * @param {*} item
   */
  resetCarrierTracking = ({ item }) => {
    this.changeCarrierTrackingValue({ name: '', isReset: true }, { item, type: 'carrier' })
    this.changeCarrierTrackingValue({ name: '', isReset: true }, { item, type: 'otherCarrier' })
    this.changeCarrierTrackingValue({ name: '', isReset: true }, { item, type: 'tracking' })
    this.changeCarrierTrackingValue({ name: '', isReset: true }, { item, type: 'returnedQty' })
  }

  /**
   * @description change carreir tracking number
   * @param {*} name
   * @param {*} value
   * @param {*} item
   * @param {*} type
   */
  changeCarrierTrackingValue = ({ name, value, isReset = false }, { item, type }) => {
    const { ordersList } = this.state
    const index = findIndex(ordersList?.orderLines, { itemNo: item.itemNo })
    const newRows = { ...ordersList }
    if (index >= 0) {
      if (String(type).toLowerCase() === String('otherCarrier').toLowerCase()) {
        newRows.orderLines[index].carrierNameOthers = name
      } else if (String(type).toLowerCase() === String('carrier').toLowerCase()) {
        this.selectCarrierMethod({ name, value })
        newRows.orderLines[index].carrierNameOthers = ''
        newRows.orderLines[index].carrierName = name
      } else if (String(type).toLowerCase() === String('returnedQty').toLowerCase()) {
        newRows.orderLines[index].returnedQty = name
      } else {
        newRows.orderLines[index].trackingNo = name
      }

      if (isReset) {
        // if canceled clicked reset
        newRows.orderLines[index].isModified = false
      }
    }
    this.setState({
      ordersList: newRows
    })
  }

  /**
   * @description set selected Carrier
   * @param {object} {name,value}
   */
  selectCarrierMethod = ({ name, value }) => {
    this.setState({
      selectedCarrier: {
        name,
        value
      }
    })
  }

  /**
   * confirm changes
   * @param {*} item
   */
  onConfirm = (item, isAll = false) => {
    const { ordersList } = this.state
    const index = findIndex(ordersList?.orderLines, { itemNo: item.itemNo })
    let newRows = { ...ordersList }
    if (index >= 0) {
      newRows.orderLines[index].isModified = true
      newRows = newRows?.orderLines.forEach((order) => {
        if (newRows.orderLines[index].carrierNameOthers) {
          newRows.orderLines[index].carrierName = newRows.orderLines[index].carrierNameOthers
        }
        return order
      })
    }
    if (index !== -1 && isAll) {
      newRows = newRows?.orderLines.forEach((order, cnt) => {
        if (cnt !== index) {
          if (newRows.orderLines[index].carrierNameOthers) {
            newRows.orderLines[index].carrierName = newRows.orderLines[index].carrierNameOthers
          }
          order.carrierName = newRows.orderLines[index].carrierName
          order.trackingNo = newRows.orderLines[index].trackingNo
          order.isModified = true
          return order
        }
        return order
      })
    }
    this.setState({
      ordersList: newRows
    })
  }

  render = () => {
    const { carrierMethods, updateOrderCarrierTracking, isUpdating } = this.props
    const { ordersList, selectedCarrier } = this.state

    return (
      // eslint-disable-next-line react/jsx-boolean-value
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <Grid container>
          <Grid item xs={12}>
            <ReturnOrderAck
              goToReturnsPage={this.goToReturnsPage}
              updateOrderCarrierTracking={updateOrderCarrierTracking}
              ordersList={ordersList}
              isUpdating={isUpdating}
              changePage={changePage}
            />
            <ReturnOrderDetails ordersList={ordersList}
            />
            <ReturnDetailsTable
              carrierMethods={carrierMethods}
              ordersList={ordersList}
              selectedCarrier={selectedCarrier}
              selectCarrier={this.selectCarrier}
              onSelectOtherCarrier={this.onSelectOtherCarrier}
              onChangeTrackingNumber={this.onChangeTrackingNumber}
              onChangeOrderRecievedQty={this.onChangeOrderRecievedQty}
              resetCarrierTracking={this.resetCarrierTracking}
              onConfirm={this.onConfirm}
            />
          </Grid>
        </Grid>
      </Slide>
    )
  }
}

export const ReturnsDetailsNaked = ReturnsDetails

ReturnsDetails.propTypes = {
  changePage: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  updateOrderCarrierTracking: PropTypes.func.isRequired,
  carrierMethods: PropTypes.array.isRequired,
  selectedReturnTab: PropTypes.string.isRequired,
  pendingOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
  acknowledgeOrders: PropTypes.arrayOf(PropTypes.object).isRequired
}

const mapStateToProps = state => ({
  isUpdating: state.ReturnOrder.isUpdating,
  carrierMethods: state.FulfillmentNew.carrierMethods.list,
  selectedReturnTab: state.ReturnOrder.selectedReturnTab,
  pendingOrders: state.ReturnOrder.pendingOrders,
  acknowledgeOrders: state.ReturnOrder.acknowledgeOrders
})

const mapDispatchToProps = dispatch => bindActionCreators({ changePage, updateOrderCarrierTracking }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(ReturnsDetails)
