import { Button, Box } from '@mui/material'
import { withStyles } from '@mui/styles'
import clsx from 'clsx'
import { isEmpty } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.primary.headerBackground,
        height: theme.spacing(5),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        display: 'flex',
        justifyContent: 'flex-start',
        borderBottom: `2px solid ${theme.palette.primary.bottiCelli}`
    },
    button: {
        marginRight: theme.spacing(2.4),
        color: theme.palette.primary.haliteBlue,
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '10px',
        padding: `${theme.spacing(2.4)}px 0px`,
        '&:hover': {
            backgroundColor: theme.palette.grey[200]
        }
    },
    active: {
        color: theme.palette.primary.blue,
        borderBottom: `2px solid ${theme.palette.primary.blue}`,
    }
})



const ReleaseTab = ({
    classes,
    poReleaseList,
    releaseActiveTab,
    onTabChange
}) => {

    const setActive = ({ releaseNumber }) => {
        onTabChange({ releaseNumber })
    }

    const BTN_STYLES = tabView =>
        clsx(classes.button, {
            [classes.active]: tabView === releaseActiveTab
        })

    if (isEmpty(poReleaseList)) {
        return null
    }
    return (
        <>
            <Box className={classes.root}>
                {
                    poReleaseList.map(release => {
                        return (
                            <Button variant="text" size="small" disabled={false}
                                className={BTN_STYLES(release.basicDetails.releaseNumber)}
                                onClick={() => setActive({ releaseNumber: release.basicDetails.releaseNumber })}>{`PO number : ${release.basicDetails.legacyPONumber || ' -'}`}
                            </Button>)
                    })
                }
            </Box>
        </>
    )
}

export const ReleaseTabNaked = ReleaseTab

ReleaseTab.propTypes = {
    classes: PropTypes.object.isRequired,
    poReleaseList: PropTypes.array.isRequired,
    releaseActiveTab: PropTypes.string.isRequired,
    onTabChange: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReleaseTab))
