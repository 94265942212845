/** *************************** Common messages ******************************************** */

export const DOWNLOAD_REPORT_INITIATED = 'Started processing...'

export const FAILED_TO_GET_DATA = 'Error occurred while fetching data'

export const INITIATE_EXPORT_FAILURE_ERROR =
  'Failed to initiate download. Please try again shortly, or reach out to Supplier Hub team'

export const NO_PERFORMANCE_DATE =
  'No performance data available for the week of {title}. You can check for other weeks using the dropdown above.'

/** *************************** Fulfilment ******************************************** */

export const SELECT_ONLY_100_ROWS = 'Please limit the selection to a maximum of 100 rows at a time, in order to proceed'

export const PICK_LIST_DOWNLOAD_IN_PROGRESS =
  'Please wait, while the pick list for the selected PO numbers is generated'

export const PICK_LIST_DOWNLOAD_FAILED =
  'Failed to download pick list. Please try again shortly, or reach out to Supplier Hub team'

export const FULFILLMENT_DOWNLOAD_FILE_FAILED = 'Error occurred while generating the Excel file'

export const RELEASE_STATUS_SUCCESS = 'Release updated successfully!'

export const RELEASE_STATUS_FAILED = 'Release canceled successfully'

export const SUPPLIER_MESSAGE = 'Following matching suppliers already exist in our system'

export const SEARCH_FOR_MESSAGE = 'Following matching supplier exists in partner catalog but not in our system'

export const SHIP_NODE_MESSAGE = 'No ship node found'

export const SHIPPED_ITEMS_ERROR =
  'Units to ship exceeds allowed limit. It should be less than or equal to quantity in pending column.'

export const NO_ITEMS = 'Item(s) not found'

export const GIFT_MESSAGE = 'Gift message is copied.'

/** ***************************Lead time ******************************************** */

export const LEAD_TIME_MESSAGE = 'No lead time requests made in the past week'

export const LEAD_TIME_CHANGE = 'New lead time value expected'

export const SAME_LEAD_TIME_ERROR_MESSAGE = 'Current and new lead time should not be same'

export const NO_LEADTIME_APPROVALS_DATA = 'There are no pending lead time update requests currently'

export const GENERAL_ERROR = 'Something went wrong on loading this page. Please refresh or contact  dsvsup1@samsclub.com for additional support'

export const SHIPPING_ERROR = 'An error occurred while shipping'

export const SHIPPING_SUCCESS = 'Item(s) shipped successfully.'

export const LABEL_MESSAGE = 'Shipping label will be downloaded automatically'

/** *********************************** Mantis Lead Time ****************************** */

export const MANTIS_LEAD_TIME_FLAG = 'Turn on automatic lead time & cut off time at supplier network'

export const MANTIS_LEAD_TIME_NETWORK =
  'Please turn on automatic lead time & cut off time at supplier network to configure settings for supplier and associated ship nodes.'

export const MANTIS_SHIPNODE_SELECTION = 'Please search and select a supplier'


/**********************Return flow *******************************/

export const RETURN_FLOW_NO_DATA = 'No returns order found'
export const EDIT_CARRIER_TRACKING = 'Edit carrier & tracking number'
export const UPDATE_ORDER_MESSAGE = 'Return order status acknowledged successfully'

/**********************Penalty *******************************/

export const EXCLUDE_PENALTY_SUCCESS_MESSAGE = 'Your request to exclude items has been submitted successfully'

export const NO_PENALTY_APPROVALS_DATA = 'There are no pending penalty exclude requests currently'

export const NO_PENALTY_RESOLVED_DATA = 'There are no resolved penalty exclude requests currently'

export const EXCLUDE_PENALTY_APPROVE = 'A request to exclude an item from penalty calculation is approved successfully.'

export const EXCLUDE_PENALTY_REJECT = 'A request to exclude an item from penalty calculation is rejected successfully.'

export const ERROR_PENALTY_FILE_DOWNLOAD = 'Error occured while downloading the file. Please try later'

/********************** Supplier Management *******************************/

export const NO_PENDING_SHIPNODE_REQUESTS = 'There are no pending shipnode requests currently'

export const NO_RESOLVED_SHIPNODE_REQUESTS = 'There are no resolved shipnode requests currently'

export const ERROR_UPDATING_SHIPNODE = 'There is a system error while updating below shipnode. Please try again later.'

export const NO_CHANGE_LOG = 'There are no change logs currently'

/********************** Inventory *******************************/

export const INVENTORY_UPDATE_ERROR = 'Error occurred while updating inventory'

export const INCORRECT_ITEM_NUMBER = 'Please enter the correct item number to search'


/********************* Generic message *****************************/
export const GENERIC_FILE_LARGE_MESSAGE = 'File size exceeds the allowed limit. Please apply some filter and then download.'

export const OPEN_ORDERS_EXCEED_PL_MESSAGE = 'Showing the oldest 10k records. Use filters to get the records of your preference.'

export const CLOSED_ORDERS_EXCEED_PL_MESSAGE = 'Showing the latest 10k records. Use filters to get the records of your preference.'

