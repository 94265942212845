import React, { PureComponent } from "react"
import { Box } from '@mui/material'
import { withStyles } from "@mui/styles"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import CollapsiblePanel from '../../components/CollapsiblePanel'
import SSHNonPaginatedTable from '../../components/SSHNonPaginatedTable'
import { CANCELLED_ITEM_STATUSES } from '../../config/genericConstants'


const styles = theme => ({
    panelAction: {
        position: 'absolute',
        right: theme.spacing(2),
        marginTop: theme.spacing(2)
    }
})

class CancelledItems extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            hasCancelledItems: false,
            items: []
        }
    }

    static columns = [
        { name: 'itemDesc', title: 'Item name', sorting: false },
        { name: 'itemNumber', title: 'Item ID', sorting: false },
        { name: 'skuId', title: 'SKU ID', sorting: false },
        { name: 'upcNumber', title: 'UPC', sorting: false },
        { name: 'orignalfulfilmentQty', title: 'Ordered', sorting: false },
        { name: 'currentfulfilmentQty', title: 'Cancelled', sorting: false },
        { name: 'giftInstructionText', title: 'Gift messages', sorting: false },
        // { name: 'Action', title: 'Action', sorting: false }
    ]

    static columnExtensions = [
        { columnName: 'itemDesc' },
        { columnName: 'itemNumber', width: 120, align: 'right' },
        { columnName: 'skuId', width: 120, align: 'right' },
        { columnName: 'upcNumber', width: 120, align: 'right' },
        { columnName: 'orignalfulfilmentQty', width: 120, align: 'right' },
        { columnName: 'currentfulfilmentQty', width: 120, align: 'right' },
        { columnName: 'giftInstructionText' }
    ]

    static getDerivedStateFromProps = ({ releaseDetails }) => {
        if (!isEmpty(releaseDetails)) {
            const { itemDetails } = releaseDetails
            return {
                items: itemDetails.filter(item => CANCELLED_ITEM_STATUSES.includes(item.status)),
            }
        }

        return {
            items: []
        }
    }

    render = () => {
        const { classes } = this.props
        const { items } = this.state
        if (isEmpty(items)) {
            return null
        }

        return (
            <>
                <Box className={classes.box}>
                    <CollapsiblePanel
                        title={`Canceled items`}
                        padding={4}
                        defaultOpen={false}
                        caption={`${items.length} items `}>
                        <SSHNonPaginatedTable
                            columns={CancelledItems.columns}
                            rows={items}
                            tableColumnExtensions={CancelledItems.columnExtensions}
                        />
                    </CollapsiblePanel>
                </Box>
            </>
        )
    }
}

CancelledItems.propTypes = {
    title: PropTypes.string.isRequired,
    shipment: PropTypes.object.isRequired,
    itemDetails: PropTypes.array.isRequired,
    releaseNumber: PropTypes.string.isRequired,
    poNumber: PropTypes.string.isRequired,
    carrierMethods: PropTypes.array.isRequired
}

export default withStyles(styles)(CancelledItems)
