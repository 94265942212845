import { InputAdornment, TextField } from '@mui/material'
import React from 'react'
import { pluralize } from '../utils/common'

// eslint-disable-next-line react/prop-types
const SSHNumberInput = ({ unit, onChange, value, error, className, type, placeholder, name, ...restProps }) => {
  return (
    <TextField
      data-testid="ssh-number-input"
      square
      fullWidth
      type={type || 'number'}
      min="1"
      name={name || ''}
      size="small"
      placeholder={placeholder}
      className={className}
      error={error}
      inputProps={{ min: 1, max: 999 }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={
        unit && {
          endAdornment: <InputAdornment position="end"> | {pluralize('day', unit, value)}</InputAdornment>
        }
      }
      onChange={onChange}
      value={value}
      variant="outlined"
      {...restProps}
    />
  )
}
SSHNumberInput.defaultProps = {
  error: false
}

export default SSHNumberInput
