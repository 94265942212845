/* eslint-disable react/no-unused-prop-types */
import { Grid, Slide } from '@mui/material'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changePage } from '../../reduxSetup/commonActions/AppConfig'
import OrderDetails from './OrderDetails'
import { resetOrderDetails, updateItemDetailChanges, showHideShipmentPopup, toggleEditPopup, cancelledUnits } from './OrderDetailsActions'
import CarrierRegistration from './Popups/CarrierRegistration'
import DownloadLabelError from './Popups/Shipment/DownloadLabelError'
import ReleaseTab from './ReleaseTab'
import Summary from './Summary'
import ShippedItems from './ShippedItems'
import CancelledItems from './CancelledItems'
import PendingItems from './PendingItems'
import OrderAckCan from './OrderAckCan'
import { showAlert } from '../../templates/Error/ErrorHandlerActions'
import { maintainStatusTab } from '../FulfillmentNew/FulfillmentAction'
import { isEmpty } from 'lodash'

class OrderPage extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      releaseActiveTab: '',
      releaseDetails: {},
      isTabChanged: false
    }
  }

  static getDerivedStateFromProps = ({ order, releaseNumber }, { releaseActiveTab }) => {
    const number = isEmpty(releaseActiveTab) ? releaseNumber : releaseActiveTab
    const releaseDetails = order?.poReleaseList.find(release => release.basicDetails.releaseNumber === number)
    return { releaseDetails, releaseActiveTab: number }
  }

  componentWillUnmount = () => {
    const { resetOrderDetails } = this.props
    return resetOrderDetails()
  }

  goToFulfillment = async () => {
    const { changePage, maintainStatusTab } = this.props
    await maintainStatusTab(true)
    return changePage('/fulfillment')
  }

  updateItemDetails = ({ poNumber, status }) => {
    const { updateItemDetailChanges } = this.props
    updateItemDetailChanges({ poNumber, status })
  }

  setActiveReleaseTab = ({ releaseNumber }) => {
    this.setState({
      releaseActiveTab: releaseNumber,
      isTabChanged: true
    })
  }

  resetTab = () => {
    this.setState({
      isTabChanged: false
    })
  }


  render = () => {
    const {
      isFetching,
      order,
      orderResult,
      showShipmentPopup,
      showHideShipmentPopup,
      showLabelDownloadErrorPopup,
      showCarrierRegistrationPopup,
      carrierMethods,
      showEditPopupItem,
      toggleEditPopup,
      cancelledUnits,
      isForceUpdate,
      errorMessage,
      legacyPOExists
    } = this.props
    const { releaseDetails, releaseActiveTab, isTabChanged } = this.state

    if (!isFetching && !order) return null

    return (
      // eslint-disable-next-line react/jsx-boolean-value
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <Grid container >
          <Grid item xs={12}>
            <OrderAckCan releaseDetails={releaseDetails}
              goToFulfillment={this.goToFulfillment}
              orderResult={orderResult}
              releaseActiveTab={releaseActiveTab}
              updateItemDetails={this.updateItemDetails}
              legacyPOExists={legacyPOExists}
            />
            <OrderDetails
              order={order}
              isFetching={isFetching}
              orderResult={orderResult}
              showAlert={showAlert} />
            <ReleaseTab
              poReleaseList={order?.poReleaseList}
              onTabChange={this.setActiveReleaseTab}
              releaseActiveTab={releaseActiveTab}
              isFetching={isFetching} />
            <PendingItems
              releaseDetails={releaseDetails}
              isFetching={isFetching}
              resetTab={this.resetTab}
              isTabChanged={isTabChanged}
              releaseActiveTab={releaseActiveTab}
              showHideShipmentPopup={showHideShipmentPopup}
              showShipmentPopup={showShipmentPopup}
              showEditPopupItem={showEditPopupItem}
              toggleEditPopup={toggleEditPopup}
              cancelledUnits={cancelledUnits}
              isForceUpdate={isForceUpdate}
              errorMessage={errorMessage}
              legacyPOExists={legacyPOExists}
            />
            <ShippedItems
              releaseDetails={releaseDetails}
              releaseNumber={releaseActiveTab}
              carrierMethods={carrierMethods}
              isFetching={isFetching}
            />
            <CancelledItems
              releaseDetails={releaseDetails}
              releaseNumber={releaseActiveTab} />
            <Summary
              release={releaseDetails}
              isFetching={isFetching} />
          </Grid>
          {showCarrierRegistrationPopup && <CarrierRegistration />}
          {showLabelDownloadErrorPopup && <DownloadLabelError />}
        </Grid>
      </Slide>
    )
  }
}

export const OrderPageNaked = OrderPage

OrderPage.propTypes = {
  changePage: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  showEditPopupItem: PropTypes.object.isRequired,
  toggleEditPopup: PropTypes.func.isRequired,
  cancelledUnits: PropTypes.func.isRequired,
  isForceUpdate: PropTypes.bool.isRequired,
  errorMessage: PropTypes.bool.isRequired,
  orderResult: PropTypes.object.isRequired,
  showHideShipmentPopup: PropTypes.func.isRequired,
  showShipmentPopup: PropTypes.bool.isRequired,
  showLabelDownloadErrorPopup: PropTypes.bool.isRequired,
  showCarrierRegistrationPopup: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  order: PropTypes.object,
  carrierMethods: PropTypes.array.isRequired,
  legacyPOExists: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  isFetching: state.OrderDetailsReducer.isFetchingOrder,
  order: state.OrderDetailsReducer.currentOrder,
  orderResult: state.OrderDetailsReducer.orderResult,
  currentOrderIndex: state.OrderDetailsReducer.currentOrderIndex,
  showShipmentPopup: state.OrderDetailsReducer.shipment.showPopup,
  showLabelDownloadErrorPopup: state.OrderDetailsReducer.showLabelDownloadErrorPopup,
  showCarrierRegistrationPopup: state.FulfillmentNew.carrierMethods.shipLabelAccount.showPopup,
  carrierMethods: state.FulfillmentNew.carrierMethods.list,
  showEditPopupItem: state.OrderDetailsReducer.showEditPopupItem,
  releaseNumber: state.OrderDetailsReducer.releaseNumber,
  isForceUpdate: state.OrderDetailsReducer.shipment.isForceUpdate,
  errorMessage: state.OrderDetailsReducer.shipment.errMessage,
  legacyPOExists: state.OrderDetailsReducer.legacyPOExists
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { changePage, resetOrderDetails, updateItemDetailChanges, showHideShipmentPopup, toggleEditPopup, cancelledUnits, showAlert, maintainStatusTab },
    dispatch
  )
export default connect(mapStateToProps, mapDispatchToProps)(OrderPage)
