/* eslint-disable react/jsx-no-bind */
import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Box } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import clsx from 'clsx'
import SSHNumberInput from '../../../../components/SSHNumberInput'
import { SSHTypography } from '../../../../components/SSHTypography'
import { selectCarrierMethod, setShipmentFlags, setTrackingNumber } from '../../OrderDetailsActions'
import { toggleCarrierRegistrationPopup } from '../../../FulfillmentNew/FulfillmentAction'
// import ErrorDisplay from '../../../../templates/Error'
import { showAlert, closeAlert } from '../../../../templates/Error/ErrorHandlerActions'
import { SHIPPED_ITEMS_ERROR } from '../../../../config/errorConstants'
import { ERROR } from '../../../../templates/Error/ErrorHandler.actionTypes'
import SSHTooltip from '../../../../components/SSHTooltip'

const styles = theme => ({
  removeIcon: {
    color: theme.palette.primary.softBlue,
    cursor: 'pointer'
  },
  table: {
    '& tr': {
      '& td:not(:nth-child(8)):not(:nth-child(9))': {
        backgroundColor: theme.palette.primary.smoke
      }
    }
  },
  focus: {
    '& .Mui-focused': {
      'box-shadow': `0px 0px 0px 2px ${theme.palette.primary.blueLight}, 0px 0px 12px ${theme.rgba(
        theme.palette.primary.blueLight,
        0.75
      )}`
    }
  },
  root: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  }
})

class ShipmentItemsTable extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      shipmentType: 'prepaid'
    }
  }

  static getDerivedStateFromProps = ({ hasPrepaid, hasCollect, manual }) => {
    if (!manual) {
      if (hasPrepaid) return { shipmentType: 'prepaid' }
      if (hasCollect) return { shipmentType: 'collect' }
    }
    return {}
  }

  /**
   * @description check enter qty is greater then required
   * @param {boolean} error
   * @returns
   */
  showAlertOnQuantityInValid = ({ error }) => {
    const { showAlert, closeAlert } = this.props
    if (error) {
      this.setState(
        {
          isError: error
        },
        () => showAlert(ERROR, SHIPPED_ITEMS_ERROR)
      )

      return error
    }
    closeAlert()
    return error
  }

  render = () => {
    const { items, updateQtyToShip, classes, removeSelecetdItem } = this.props

    if (isEmpty(items)) {
      return null
    }

    return (
      <Box>
        {/* <ErrorDisplay /> */}
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell borderRight borderBottom borderTop borderLeft>
                  Item name
                </TableCell>
                <TableCell borderRight borderBottom borderTop borderLeft>
                  Item ID
                </TableCell>
                <TableCell borderRight borderBottom borderTop>
                  SKU ID
                </TableCell>
                <TableCell borderRight borderBottom borderTop>
                  UPC
                </TableCell>
                <TableCell borderRight borderBottom borderTop borderLeft>
                  Ordered
                </TableCell>
                <TableCell borderRight borderBottom borderTop borderLeft>
                  Pending
                </TableCell>
                <TableCell borderRight borderBottom borderTop>
                  Cancelled
                </TableCell>
                <TableCell borderRight borderBottom borderTop>
                  Shipping
                </TableCell>
                <TableCell borderRight borderBottom borderTop>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map(item => (
                <TableRow>
                  <TableCell>
                    <SSHTypography variant="body2">{item.itemDesc}</SSHTypography>
                  </TableCell>
                  <TableCell>{item.itemNumber}</TableCell>
                  <TableCell>{item.skuId}</TableCell>
                  <TableCell>{item.upcNumber}</TableCell>
                  <TableCell>{item.orignalfulfilmentQty}</TableCell>
                  <TableCell>{item.currentfulfilmentQty}</TableCell>
                  <TableCell>{item.cancelled}</TableCell>
                  <TableCell valign>
                    <SSHNumberInput
                      size="small"
                      variant="outlined"
                      type="number"
                      error={this.showAlertOnQuantityInValid({ error: item.error })}
                      onChange={updateQtyToShip.bind(this, item.itemNumber)}
                      className={clsx(classes.focus, classes.root)}
                      value={item.updatedFulfillmentQty === 0 ? item.currentfulfilmentQty : item.updatedFulfillmentQty}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <SSHTooltip title="Remove item from selection">
                      <RemoveCircleIcon
                        className={classes.removeIcon}
                        onClick={() => removeSelecetdItem({ itemNumber: item.itemNumber })}
                      />
                    </SSHTooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }
}
export const ShipmentItemsTableNaked = ShipmentItemsTable

ShipmentItemsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  updateQtyToShip: PropTypes.func.isRequired,
  manual: PropTypes.bool,
  hasPrepaid: PropTypes.bool,
  hasCollect: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  hasBoth: PropTypes.bool
}

ShipmentItemsTable.defaultProps = {
  manual: true,
  hasPrepaid: false,
  hasCollect: false,
  hasBoth: false
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { setShipmentFlags, selectCarrierMethod, setTrackingNumber, toggleCarrierRegistrationPopup, showAlert, closeAlert },
    dispatch
  )
export default connect(null, mapDispatchToProps)(withStyles(styles)(ShipmentItemsTable))
