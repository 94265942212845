import { withStyles } from '@mui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FeedbackLanguage } from '@atmt/feedback-component-web'
import ATMTAnnouncement, { FeedbackEnvironment, AuthType } from '@atmt/feedback-trigger-library'
import ChatBot from 'sams-supplier-bot/dist/components/ChatBot'
import {
  checkIfLoggedIn,
  fetchDataBasedOnPage,
  setVisibleModule,
  updatePageTitle,
  toggleFeedback,
  setSourceType
} from '../../reduxSetup/commonActions/AppConfig'
import Footer from '../Footer'
import HeaderNew from '../Header/indexNew'
import LeftNav from '../LeftNav'
import NotificationDrawer from '../NotificationDrawer'
import ToastMessage from '../ToastMessage'
import Router from './router'
import ErrorDisplay from '../Error'
import ATMTFeedback from '../Atmt/index'
import '@atmt/feedback-trigger-library/build/index.css'
import { APP_CONFIG } from '../../config/appConfigs'
import packageJson from '../../../package.json'
import SSHBreadcrumb from '../Breadcrumb'

const styles = theme => ({
  root: { display: 'flex', height: '100vh', background: theme.palette.background.default },
  content: {
    flexGrow: 1,
    marginTop: 65,
    marginLeft: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.default,
    width: '97%'
  },
  chatBotStyle: {
    width: 0,
    height: 0,
    position: 'fixed',
    bottom: '7%',
    right: '4%'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
})
const PROD = 'PROD'
class Layout extends PureComponent {
  onBackButtonEvent = () => {
    setVisibleModule()
    const { fetchDataBasedOnPage } = this.props
    fetchDataBasedOnPage()
  }

  componentDidMount = () => {
    const { checkIfLoggedIn, fetchDataBasedOnPage, setSourceType } = this.props
    checkIfLoggedIn()
    setVisibleModule()
    setSourceType()//default ecomm
    fetchDataBasedOnPage()
    window.addEventListener('popstate', this.onBackButtonEvent)
  }

  componentDidUpdate = prevProps => {
    const { pathName, updatePageTitle, loginId, iamToken } = this.props
    const { version } = packageJson
    if (prevProps.pathName !== pathName) updatePageTitle()
    if (prevProps.iamToken !== iamToken) {
      new ATMTAnnouncement({
        environment: String(process.env.REACT_APP_ENV).toString() === PROD ? FeedbackEnvironment.PRD_TORBIT : FeedbackEnvironment.STG_TORBIT,
        version,
        languageCode: FeedbackLanguage.ENGLISH,
        countryCode: 'US',
        userId: loginId,
        productId: APP_CONFIG.ENV().atmtAppId,
        disableCaching: true,
        authentication: {
          type: AuthType.iam,
          config: {
            consumerId: process.env.REACT_APP_CONSUMER_ID,
            token: iamToken
          }
        }
      })
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('popstate', this.onBackButtonEvent)
  }

  render = () => {
    const {
      classes,
      loggingIn,
      loginId,
      sessionId,
      drawerOpen,
      toggleFeedback,
      isToggleAtmtFeedback,
      vendorId,
      authorization,
      firstName,
      ...restProps
    } = this.props

    return (
      <div className={classes.root}>
        <SSHBreadcrumb pathName={restProps.pathName} drawerOpen={drawerOpen} />
        <HeaderNew />
        <LeftNav />
        <NotificationDrawer />
        <ToastMessage />
        <main className={clsx(classes.content, { [classes.contentShift]: drawerOpen })}>
          <ErrorDisplay />
          <Router loginId={loginId} sessionId={sessionId} loggingIn={loggingIn} />
          <ATMTFeedback
            toggleFeedback={toggleFeedback}
            isToggleAtmtFeedback={isToggleAtmtFeedback}
            loginId={loginId}
            iamToken={restProps.iamToken}
          />
          <div className={classes.chatBotStyle}>
            <ChatBot
              authorization={authorization}
              supplierId={vendorId}
              environment={String(process.env.REACT_APP_API_PROXY_URL)}
              userName={firstName}
              iamToken={restProps.iamToken}
              consumerId={String(process.env.REACT_APP_CONSUMER_ID)}
            />
          </div>
          <Footer />
        </main>
      </div>
    )
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  loginId: PropTypes.string.isRequired,
  sessionId: PropTypes.string.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  pathName: PropTypes.string.isRequired,
  checkIfLoggedIn: PropTypes.func.isRequired,
  fetchDataBasedOnPage: PropTypes.func.isRequired,
  updatePageTitle: PropTypes.func.isRequired,
  toggleFeedback: PropTypes.func.isRequired,
  isToggleAtmtFeedback: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  loggingIn: state.UserInfo.loggingIn,
  loginId: state.UserInfo.userDetails.loginId,
  sessionId: state.UserInfo.userDetails.sessionId,
  drawerOpen: state.AppConfig.leftDrawer.open,
  pathName: state.router.location.pathname,
  isToggleAtmtFeedback: state.AppConfig.isToggleAtmtFeedback,
  customerToken: state.UserInfo.userDetails.customerToken,
  iamToken: state.UserInfo.userDetails.iamToken,
  vendorId: state.AppConfig.vendor.selectedVendor.vendorId,
  authorization: state.UserInfo.userDetails.customerToken,
  firstName: state.UserInfo.userDetails.firstName
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ checkIfLoggedIn, fetchDataBasedOnPage, updatePageTitle, toggleFeedback, setSourceType }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Layout))
