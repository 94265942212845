import React from "react"
import { Box } from '@mui/material'
import TitleInformation from './TileInformation'


/**
 * @description flexscape component to customize toolbar for scheduler component
 */
const FlexiSpace = (props) => {
    return (
        <Box>
            <TitleInformation {...props} />
        </Box>
    )
}

export default FlexiSpace