/* eslint-disable no-param-reassign */
import { cloneDeep, find, get, isNull } from 'lodash'
import moment from 'moment'
import { validateDateInMonths, validateSearch, clearOrderSearchValue } from '../../utils/common'
import { ORDER_SEARCH_TYPES, ORDER_STATUS, ORDER_SEARCH_SORT } from '../../config/genericConstants'
import {
  SET_SEARCH_KEY_VALUE,
  CLEAR_SEARCH_KEY_VALUE,
  CLEAR_ORDER_SEARCH,
  REMOVE_DATE_FILTER,
  SET_ORDER_STATUS,
  REMOVE_STATUS_FILTER,
  SEARCH_PARAMETER_COUNT,
  SEARCH_ORDER_SORTING,
  SET_ORDER_SORT_TYPE
} from './Fulfillment.types'

export const INITIAL_STATE = {
  orderSearch: {
    isSearching: false,
    search: true,
    searchOrderType: [...ORDER_SEARCH_TYPES],
    orderStatus: [...ORDER_STATUS],
    status: [],
    itemNumber: '',
    vendorStockId: '',
    legacyPoNo: '',
    poNumber: '',
    salesOrderNo: '',
    orderDateRange: {
      from: null,
      to: null
    },
    shipDateRange: {
      from: null,
      to: null
    },
    inValidOrderDate: false,
    inValidShipDate: false,
    searchParamCount: 0,
    orderDate: false,
    shipDate: false,
    sortByParameter: 'orderDate',
    sortType: 'asc'
  }
}

/**
 * @description get order date selection
 * @param {string} newOrderSearch */
const getOrderDate = newOrderSearch => {
  newOrderSearch.orderDate = false
  if (
    (isNull(newOrderSearch.orderDateRange.from) && !isNull(newOrderSearch.orderDateRange.to)) ||
    (!isNull(newOrderSearch.orderDateRange.from) && isNull(newOrderSearch.orderDateRange.to))
  ) {
    newOrderSearch.orderDate = true
  }

  if (!isNull(newOrderSearch.orderDateRange.from) && !isNull(newOrderSearch.orderDateRange.to)) {
    newOrderSearch.inValidOrderDate = !validateDateInMonths(
      newOrderSearch.orderDateRange.from,
      newOrderSearch.orderDateRange.to
    )
  }
}

/**
 * @description get ship date selection
 * @param {string} newOrderSearch */
const getShipDate = newOrderSearch => {
  newOrderSearch.shipDate = false
  if (
    (isNull(newOrderSearch.shipDateRange.from) && !isNull(newOrderSearch.shipDateRange.to)) ||
    (!isNull(newOrderSearch.shipDateRange.from) && isNull(newOrderSearch.shipDateRange.to))
  ) {
    newOrderSearch.shipDate = true
  }

  if (!isNull(newOrderSearch.shipDateRange.from) && !isNull(newOrderSearch.shipDateRange.to)) {
    newOrderSearch.inValidShipDate = !validateDateInMonths(
      newOrderSearch.shipDateRange.from,
      newOrderSearch.shipDateRange.to
    )
  }
}

const setSearchKeyValue = (action, state) => {
  const { searchKey, searchValue } = action.param
  const newOrderSearch = cloneDeep(state.orderSearch)

  if (searchKey && searchKey.includes('Date')) {
    // contains dates and add dates to respective field
    const dates = searchKey.split('-')
    /* eslint-disable */
    newOrderSearch[dates[0]][dates[1]] = moment(searchValue._d).format('MM/DD/YYYY')
    /* eslint-enable */
  }

  if (get(state, `orderSearch.${searchKey}`) !== undefined) {
    const ORDER_TYPE = find(cloneDeep(ORDER_SEARCH_TYPES), { value: searchKey })
    if (ORDER_TYPE.regEx.test(searchValue) || ORDER_TYPE.closedRegEx.test(searchValue)) {
      newOrderSearch[searchKey] = String(searchValue)
    }
  }

  getOrderDate(newOrderSearch)

  getShipDate(newOrderSearch)

  const isValid = validateSearch({ newOrderSearch })
  /* eslint-disable */
  newOrderSearch.search = isValid || newOrderSearch.inValidOrderDate || newOrderSearch.inValidShipDate // allow search click
  /* eslint-enable */
  return newOrderSearch
}

export default (state = cloneDeep(INITIAL_STATE), action) => {
  switch (action.type) {
    case SET_SEARCH_KEY_VALUE: {
      const newOrderSearch = setSearchKeyValue(action, state)
      return {
        ...state,
        orderSearch: { ...newOrderSearch }
      }
    }
    case CLEAR_SEARCH_KEY_VALUE: {
      const clearOrderSearch = state.orderSearch
      state.orderSearch.searchOrderType.forEach(item => {
        clearOrderSearch[item.value] = ''
        return clearOrderSearch
      })
      clearOrderSearch.searchParamCount = 0
      return {
        ...state,
        orderSearch: { ...clearOrderSearch }
      }
    }

    case CLEAR_ORDER_SEARCH: {
      const clearOrder = clearOrderSearchValue(state.orderSearch)
      return {
        ...state,
        orderSearch: { ...cloneDeep(clearOrder) }
      }
    }

    case REMOVE_DATE_FILTER: {
      const removeSearchFilter = state.orderSearch
      if (action.dateType === 'ORDER') {
        removeSearchFilter.orderDateRange.from = null
        removeSearchFilter.orderDateRange.to = null
      } else {
        removeSearchFilter.shipDateRange.from = null
        removeSearchFilter.shipDateRange.to = null
      }
      removeSearchFilter.searchParamCount = 0
      return {
        ...state,
        orderSearch: { ...cloneDeep(removeSearchFilter) }
      }
    }

    case SET_ORDER_STATUS: {
      const status = state.orderSearch
      const { name, isChecked } = action.payload
      const filter = find(status.orderStatus, { name }, false)
      if (filter) {
        filter.checked = isChecked
      }
      return {
        ...state,
        orderSearch: { ...cloneDeep(status) }
      }
    }

    case REMOVE_STATUS_FILTER: {
      const status = state.orderSearch
      const { name } = action.payload
      const filter = find(status.orderStatus, { name })

      if (filter) {
        filter.checked = false
      }

      status.searchParamCount = 0
      return {
        ...state,
        orderSearch: { ...cloneDeep(status) }
      }
    }

    case SEARCH_PARAMETER_COUNT: {
      const ORDER_COUNT = state.orderSearch
      ORDER_COUNT.searchParamCount += 1
      return {
        ...state,
        orderSearch: { ...ORDER_COUNT }
      }
    }

    case SEARCH_ORDER_SORTING: {
      const ORDER_SORT = state.orderSearch
      const { sortByParameter } = action.payload

      const colSort = find(cloneDeep(ORDER_SEARCH_SORT), { name: sortByParameter })

      ORDER_SORT.sortByParameter = (colSort && colSort.sortName) || null
      ORDER_SORT.sortType = ORDER_SORT.sortType === 'desc' ? 'asc' : 'desc'

      return {
        ...state,
        orderSearch: { ...ORDER_SORT }
      }
    }

    case SET_ORDER_SORT_TYPE: {
      const ORDER_SORT = state.orderSearch
      const { tabView } = action.payload
      ORDER_SORT.sortType = tabView === 'Active' ? 'asc' : 'desc'

      return {
        ...state,
        orderSearch: { ...ORDER_SORT }
      }
    }

    default:
      return { ...state }
  }
}
