import { Grid, lighten, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logout } from './UserInfoAction'
import { COLORS } from '../Entry/globalMUIStyles'
import { AccountCircleIcon } from '../LeftNav/ColoredSvg'

const styles = theme => ({
  avatar: {
    height: 25,
    width: 25,
    marginRight: theme.spacing(2),
    backgroundColor: lighten(theme.palette.primary.blue, 0.5)
  },
  logout: {
    height: 25,
    marginRight: theme.spacing(2.5)
  },
  colorGrey: { color: theme.palette.grey[600] },
  justifyLeft: { justifyContent: 'flex-start' }
})

const UserInfo = ({ firstName, lastName }) => {
  const DISPLAY_NAME = firstName ? [firstName, lastName].join(' ') : ''

  return (
    <Grid
      container
      style={{ marginTop: 8, marginBottom: 8 }}
      alignItems="center"
      wrap="nowrap"
      justifyContent="space-between">
      <Grid container alignItems="center" wrap="nowrap">
        <AccountCircleIcon /> &nbsp;&nbsp;&nbsp;&nbsp;
        <Typography style={{ color: COLORS.white }}>{DISPLAY_NAME}</Typography>
      </Grid>
    </Grid>
  )
}

export const UserInfoNaked = connect(null, null)(withStyles(styles)(UserInfo))

UserInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired
}
const mapStateToProps = state => ({
  firstName: state.UserInfo.userDetails.firstName,
  lastName: state.UserInfo.userDetails.lastName
})

const mapDispatchToProps = dispatch => bindActionCreators({ logout }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserInfo))
