import { cloneDeep, isEmpty } from 'lodash'
import { SET_FILTERS_OPEN, OOS_TOTAL_COUNT, OOS_FILTERS } from './ClubOutofStock.types'
import { FILTER_KEYS } from '../../../config/genericConstants'

const initialStateFilters = [{
    filterName: FILTER_KEYS.CATEGORY,
    filterValues: []
},
{
    filterName: FILTER_KEYS.CATEGORY_ID,
    filterValues: []
},
{
    filterName: FILTER_KEYS.SUBCATEGORY,
    filterValues: []
},
{
    filterName: FILTER_KEYS.SUBCATEGORY_NUM,
    filterValues: []
},
{
    filterName: FILTER_KEYS.SUPPLIER_ID,
    filterValues: []
},
{
    filterName: FILTER_KEYS.ITEM_ID,
    filterValues: []
}]

export const INITIAL_STATE = {
    total: 0,
    isFilterOpen: false,
    selectedItem: {},
    selectedClub: {},
    oosVisibilityFilters: initialStateFilters
}

/**
 * @description create applied filters array
 * @param {categories, subCategories, suppliers, itemId, state} 
 * @returns [{}] applied filters array
 */
const createFilters = ({ categories, subCategories, suppliers, itemId, state }) => {

    const filters = cloneDeep(state.oosVisibilityFilters)
    const allCategorySelected = categories.find(val => val.id === 'All')
    const allSubCategorySelected = subCategories.find(val => val.id === 'All')
    const allSupplierSelected = suppliers.find(val => val.id === 'All')

    let catFilteredValues = filters?.find(key => key.filterName === FILTER_KEYS.CATEGORY).filterValues
    let catIdFilteredValues = filters?.find(key => key.filterName === FILTER_KEYS.CATEGORY_ID).filterValues

    let subcatFilteredValues = filters?.find(key => key.filterName === FILTER_KEYS.SUBCATEGORY).filterValues
    let subcatIdFilteredValues = filters?.find(key => key.filterName === FILTER_KEYS.SUBCATEGORY_NUM).filterValues

    let supplierFilteredValues = filters?.find(key => key.filterName === FILTER_KEYS.SUPPLIER_ID).filterValues
    let itemIdFilteredValues = filters?.find(key => key.filterName === FILTER_KEYS.ITEM_ID).filterValues

    /** always initiaize with empty array before adding new filters */
    catFilteredValues.length = 0
    catIdFilteredValues.length = 0
    subcatFilteredValues.length = 0
    subcatIdFilteredValues.length = 0
    supplierFilteredValues.length = 0
    itemIdFilteredValues.length = 0

    if (!isEmpty(itemId)) {
        if (itemId.includes(',')) {
            const itemIdArr = itemId.replace(/\s+/g, '')?.split(',')
            itemIdFilteredValues.push(...itemIdArr)
        } else {
            itemIdFilteredValues.push(itemId)
        }
    }

    if (!allCategorySelected) {
        categories.forEach(element => {
            catFilteredValues.push(element?.value)
            catIdFilteredValues.push(element?.id)
        })
    }

    if (!allSubCategorySelected) {
        subCategories.forEach(element => {
            subcatFilteredValues.push(element?.value)
            subcatIdFilteredValues.push(element?.number)
        })
    }

    if (!allSupplierSelected) {
        suppliers.forEach(element => {
            supplierFilteredValues.push(element?.id)
        })
    }

    return filters
}

export default (state = cloneDeep(INITIAL_STATE), action) => {
    switch (action.type) {
        case OOS_TOTAL_COUNT:
            const { count } = action.payload
            return {
                ...state,
                total: count
            }

        case SET_FILTERS_OPEN:
            const { isFilterOpen } = action.payload
            return {
                ...state,
                isFilterOpen: isFilterOpen
            }

        case OOS_FILTERS:
            const { categories, subCategories, suppliers, itemId } = action.payload
            return {
                ...state,
                oosVisibilityFilters: createFilters({ categories, subCategories, suppliers, itemId, state })
            }

        default:
            return { ...state }
    }
}
