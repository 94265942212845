export const LEADTIME = 'LeadTime'
export const CAPACITY = 'Capacity'
export const PENALTY = 'Penalty'
export const SUPPLIER_MNGT = 'SupplierMngt'
export const ACCOUNTS_RECEIVABLE = 'AccountsReceivable'

export const APPROVAL_MODULES = [
  { name: 'Penalty', value: PENALTY },
  { name: 'Accounts Receivable', value: ACCOUNTS_RECEIVABLE },
  { name: 'Shipnode management', value: SUPPLIER_MNGT },
  { name: 'Lead time', value: LEADTIME }
]

export const PENDING = 'Pending'
export const RESOLVED = 'Resolved'
export const APPROVE = 'approved'
export const WAIVER = 'waived'
export const EDITED = 'edited'
