/* eslint-disable prefer-object-spread */
import cloneDeep from 'lodash/cloneDeep'
import CustomError from '../../utils/CustomError'
import NetworkError from '../../utils/NetworkError'
import { CLOSE_ERROR_ALERT, DELETE_ERROR, ERROR_OCCURRED, ERROR, CLOSE_ALERT, SUCCESS } from './ErrorHandler.actionTypes'
import { NETWORK_ERROR_MESSAGE } from '../../config/genericConstantsNew'

export const INITIAL_STATE = {
  errMsg: null,
  showAlert: false,
  errCode: null,
  caller: null,
  errorType: null
}

const API_INFO_CODE = 422

/**
 * @description error handler reducer
 * @param {*} param0
 * @returns {string}
 */
const checkErrorType = ({ message }) => {
  if (String(message).toLocaleLowerCase().includes('network error')) {
    return NETWORK_ERROR_MESSAGE
  }
  return message
}

export default function ErrorHandler(state = cloneDeep(INITIAL_STATE), action = {}) {
  switch (action.type) {
    case ERROR:
      return Object.assign({}, state, {
        errMsg: action?.errMsg || action?.payload.message,
        showAlert: true,
        errCode: action?.errCode,
        caller: action?.caller,
        errorType: action?.errorType
      })
    case SUCCESS:
      return Object.assign({}, state, {
        errMsg: action?.errMsg || action?.payload.message,
        showAlert: true,
        isSuccess: true
      })
    case DELETE_ERROR:
      return Object.assign({}, state, {
        errMsg: null,
        showAlert: false
      })

    case CLOSE_ALERT:
      return Object.assign({}, state, {
        showAlert: false
      })

    case ERROR_OCCURRED:
      // eslint-disable-next-line no-case-declarations
      let newState = {}
      if (action.error instanceof NetworkError) {
        newState = {
          errMsg: checkErrorType({ message: action.error.response.message }),
          errCode: action.error.toString()
        }
      } else if (action.error instanceof CustomError) {
        newState = {
          errMsg: action.error.apiResponse.error.errorMessage,
          errCode: action.error.apiResponse.error.errorCode,
          isInfo: action.error.apiResponse.error.errorCode === API_INFO_CODE
        }
      } else if (action.payload?.isAlertAction && action.payload?.isSuccess) {
        newState = {
          errMsg: action.payload.message,
          isSuccess: action.payload.isSuccess
        }
      } else if (action.payload?.isAlertAction && action.payload?.isFailure) {
        newState = {
          errMsg: action.payload.message
        }
      }
      return {
        ...newState,
        showAlert: true,
        caller: null,
        errorType: null
      }

    case CLOSE_ERROR_ALERT:
      return {
        ...INITIAL_STATE,
        showAlert: false
      }

    default:
      return state
  }
}
