export const columns = [
  { name: 'line', title: 'Line #', sorting: false, align: 'center' },
  { name: 'itemId', title: 'Item ID', sorting: false },
  { name: 'itemDesc', title: 'Item name', sorting: false },
  { name: 'UPC', title: 'UPC', sorting: false },
  { name: 'vendorStockId', title: 'Vendor stock ID', sorting: false },
  { name: 'reasonOfReturn', title: 'Reason for return', sorting: false },
  { name: 'orderedQty', title: 'Ordered Quantity', sorting: false },
  { name: 'pendingQty', title: 'Pending Quantity', sorting: false },
  { name: 'returnedQty', title: 'Order Recieved', sorting: false },
  { name: 'carrier', title: 'Carrier', sorting: false },
  { name: 'tracking', title: 'Tracking number', sorting: false },
  { name: 'returnActions', title: ' ', sorting: false },

]

export const columnExtensions = [
  { columnName: 'line', align: 'right', width: 100 },
  { columnName: 'itemId', align: 'right' },
  { columnName: 'VendorstockID', align: 'right' },
  { columnName: 'returnActions', align: 'center', width: 100 },
]
