import { Scheduler, MonthView, DateNavigator, Toolbar, TimeTableCell, Appointments } from '@devexpress/dx-react-scheduler-material-ui'
import { ViewState } from '@devexpress/dx-react-scheduler'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import clsx from 'clsx'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { find, findIndex, filter } from 'lodash'
import FlexiSpaceScheduler from '../templates/FutureInventoryScheduler/FlexiSpaceScheduler'
import SSHTooltip from './SSHTooltip'
import SSHPopover from './SSHPopover'
import { SSHTypography } from './SSHTypography'
import SSHNumberInput from './SSHNumberInput'
import SSHButton from './SSHButton'
import { updateInTransitInventory, setRequestPayload } from '../pages/InTransitInventory/InTransitInventoryAction'
import { yearFirstFormat, ROLE_BACK_ACTIONS } from '../config/genericConstants'
import { allowAccess } from '../utils/common'
import { COLORS } from '../templates/Entry/globalMUIStyles'

const useStyles = makeStyles(theme => ({
    weekDay: {
        '& div': {
            fontWeight: 'normal',
            textAlign: 'center',
            fontSize: theme.spacing(1.9),
            color: '#727272'
        }
    },
    toolbar: {
        '& > div:first-of-type': {
            '& > div:first-of-type': {
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                marginRight: theme.spacing(3)
            }
        }
    },
    cell: {
        '& > div': {
            textAlign: 'left',
            margin: 0,
            padding: '1px 8px'
        },
        '& :focus': {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.primary.light,
            cursor: 'pointer',
            height: 'inherit',
            padding: '0 !important'
        }
    },
    disabledCell: {
        backgroundColor: COLORS.grey10
    },
    appointment: {
        fontSize: theme.spacing(3),
        textAlign: 'center',
        fontWeight: 'normal',
        background: 'none',
        '& > div > div': {
            color: '#323A3E'
        }
    },
    hoverStyle: {
        padding: theme.spacing(2)
    },
    paddingBottom: {
        paddingBottom: theme.spacing(2)
    },
    qtyTitle: {
        fontSize: theme.spacing(2.25),
        color: '#323A3E',
        fontWeight: '400',
        marginBottom: theme.spacing(2)
    },
    qty: {
        color: '#31557C',
        fontSize: theme.spacing(1.5)
    },
    newQty: {
        color: '#323A3E',
        fontSize: theme.spacing(1.75)
    }
}))

const currentDate = moment(new Date()).format(yearFirstFormat)

const formatDayScaleDate = (date, options) => {
    const momentDate = moment(date);
    const { weekday } = options;
    return momentDate.format(weekday ? 'dddd' : 'D');
}

const DayScaleCell = ({ formatDate, ...restProps }) => {
    const classes = useStyles()
    return <MonthView.DayScaleCell {...restProps} formatDate={formatDayScaleDate} className={classes.weekDay} />
}

/**
 * @description appointment container
 * @param {*} props 
 * @returns {object}
 */
const appointmentContainer = (props) => {
    const classes = useStyles()
    return <Appointments.Container className={classes.appointment} {...props} />
}

/**
 * @description check if the date is within the next 30 days range
 * @param {*} props 
 * @returns {boolean}
 */
const isDateInbetweenRange = (props) => {
    const currentDate = new Date()
    return !!(props?.startDate > currentDate && props?.startDate <= moment(currentDate.setDate(currentDate.getDate() + 30)));
}

const SSHScheduler = ({ data, updateInTransitInventory, setRequestPayload, selectedItem, ...restProps }) => {
    const classes = useStyles()

    const Cell = (props) => {
        const classes = useStyles()
        const [anchorEl, setAnchorEl] = useState(null)
        const [editedQty, setEditedQty] = useState(null)
        const open = Boolean(anchorEl)
        const matched = find(data, val => moment(val.startDate).format(yearFirstFormat) === moment(props.startDate).format(yearFirstFormat))

        /** 
         * @description handle hover open
         * @param {*} e
         * @param {*} name
         */
        const handlePopoverOpen = (e) => {
            setAnchorEl(e.currentTarget)
        }

        /**
         * @description hanlde hover close
         */
        const handlePopoverClose = () => {
            setAnchorEl(null)
            setEditedQty(null)
        }

        const handleSave = async (e, props) => {
            const editedDate = moment(props.startDate).format(yearFirstFormat)
            const reqObj = { date: editedDate, quantity: editedQty }
            const matchedIndex = findIndex(data, val => moment(val.startDate).format(yearFirstFormat) === moment(props.startDate).format(yearFirstFormat))
            let newData = []
            if (matchedIndex === -1) {
                newData.push(reqObj)
            } else {
                newData = filter(data, val => {
                    if (moment(val.startDate).format(yearFirstFormat) === moment(props.startDate).format(yearFirstFormat)) {
                        val.date = editedDate
                        val.quantity = editedQty
                        return val
                    }
                })
            }
            await setRequestPayload({ itemId: selectedItem.itemId, inTransitInventory: newData })
            await updateInTransitInventory()
            setAnchorEl(null)
        }

        return (
            <>
                {isDateInbetweenRange(props)
                    ? (<SSHTooltip title={'Edit quantity'} >
                        <MonthView.TimeTableCell
                            className={classes.cell}
                            onClick={handlePopoverOpen}
                            {...props}
                        >
                        </MonthView.TimeTableCell>
                    </SSHTooltip>)
                    : (<MonthView.TimeTableCell
                        className={clsx(classes.cell, classes.disabledCell)}
                        {...props}
                    >
                    </MonthView.TimeTableCell>)}
                <SSHPopover
                    id="ssh-popover"
                    anchorEl={anchorEl}
                    handleClose={handlePopoverClose}
                    disableRestoreFocus
                    isOpen={open}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}>
                    {
                        <Grid container xs={12} className={classes.hoverStyle}>
                            <Grid item xs={12} className={classes.paddingBottom}>
                                <SSHTypography className={classes.qtyTitle}>
                                    Edit quantity for {moment(props.startDate).format('ll')}
                                    <SSHTypography className={classes.qty}>
                                        Current quantity : {matched?.title || '0'}
                                    </SSHTypography>
                                </SSHTypography>
                                <SSHTypography>
                                    New quantity
                                </SSHTypography>
                                <SSHNumberInput type='number' value={editedQty} onChange={(e) => { setEditedQty(e.target.value) }}></SSHNumberInput>
                            </Grid>
                            <Grid xs={12} container justifyContent='flex-end'>
                                <SSHButton text='Cancel' size='md' variant='outlined' onClick={handlePopoverClose} />&nbsp;&nbsp;
                                {allowAccess(ROLE_BACK_ACTIONS.EDIT) &&
                                    <SSHButton color='primary' size='md' variant='contained' text='Save' onClick={(e) => handleSave(e, props)} />
                                }
                            </Grid>
                        </Grid>
                    }
                </SSHPopover>
            </>
        )
    }

    return (
        <Grid className={classes.toolbar}>
            <Scheduler
                data={data}
                height={'auto'}
                firstDayOfWeek={6}>
                <ViewState defaultCurrentDate={currentDate} />
                <Toolbar
                    flexibleSpaceComponent={FlexiSpaceScheduler}
                />
                <DateNavigator />
                <MonthView
                    dayScaleCellComponent={DayScaleCell}
                    timeTableCellComponent={Cell}
                />
                <Appointments appointmentComponent={appointmentContainer} />
            </Scheduler>
        </Grid>
    )
}


SSHScheduler.defaultProps = {
    data: []
}

SSHScheduler.propTypes = {
    data: PropTypes.object
}

const mapStateToProps = state => ({
    selectedItem: state.InTransitInventory.selectedItem
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            updateInTransitInventory,
            setRequestPayload
        },
        dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(SSHScheduler)
