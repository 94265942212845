import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Typography } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import SSHTooltip from '../../components/SSHTooltip'

/**
 * @description Component to render custom table cell for Downloaded date/time
 * @param {Object} row Data object with list item details
 * @param {Object} ackIds State Data object with list of reports
 * @returns React element with the value of downloaded date/time
 */
const DownloadDateTimeCell = ({ row, ...props }) => {
  return (
    <Table.Cell {...props}>
      <SSHTooltip title={row.createdAt} placement="left">
        <Typography variant="body2">{row.createdAt}</Typography>
      </SSHTooltip>
    </Table.Cell>
  )
}

export default connect(null, null)(DownloadDateTimeCell)
