import { cloneDeep } from 'lodash'
import {
    SET_FORECAST_ITEM_COUNT,
    SET_SELECTED_ITEM,
    SET_CLUB_WEEKLY_FORECAST,
    SET_FILTERS_OPEN
} from './Forecast.types'

export const INITIAL_STATE = {
    totalForecastItems: 0,
    selectedItem: {},
    selectedClub: {},
    isFilterOpen: false,
    displayWeeklyClubForecast: false
}

export default (state = cloneDeep(INITIAL_STATE), action) => {
    switch (action.type) {
        case SET_FORECAST_ITEM_COUNT:
            const { count } = action.payload
            return {
                ...state,
                totalForecastItems: count
            }

        case SET_SELECTED_ITEM:
            const { itemDetails } = action.payload
            return {
                ...state,
                selectedItem: itemDetails
            }

        case SET_CLUB_WEEKLY_FORECAST:
            const { clubDetails, displayForecastDrawer } = action.payload
            return {
                ...state,
                selectedClub: clubDetails,
                displayWeeklyClubForecast: displayForecastDrawer
            }

        case SET_FILTERS_OPEN:
            const { isOpen } = action.payload
            return {
                ...state,
                isFilterOpen: isOpen
            }

        default:
            return { ...state }
    }
}
