import { Table } from '@devexpress/dx-react-grid-material-ui'
import React from 'react'
import PropTypes from 'prop-types'
import SSHTooltip from '../../components/SSHTooltip'
import { SSHTypography } from '../../components/SSHTypography'
import { capitalizeSentence } from '../../utils/common'

/**
 * @description Component to render custom table cell for Smart inventory
 * @param {Object} row Data object with list cell details
 * @returns React element with the value of Smart inventory cell
 */
const SmartInventoryTableCell = ({ text, id, ...props }) => {
  return (
    <Table.Cell {...props}>
      <SSHTooltip title={`${capitalizeSentence(text)} (${id})`} placement="top-start">
        <SSHTypography variant="body2">{`${capitalizeSentence(text)} (${id})`}</SSHTypography>
      </SSHTooltip>
    </Table.Cell>
  )
}

SmartInventoryTableCell.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
}

export default SmartInventoryTableCell
