import { cloneDeep, isEmpty } from 'lodash'
import {
  BOARD_SUPPLIER_REQUEST,
  BOARD_SUPPLIER_SUCCESS,
  BOARD_SUPPLIER_FAILURE,
  RESET_ONBOARD,
  OPEN_SHIPNODE_FORM,
  OPEN_DEACTIVATE_FORM,
  GET_SHIP_NODES_DETAILS_SUCCESS,
  CLEAR_SHIPNODES_DETAILS,
  SET_REQUEST_DESCRIPTION,
  SET_HISTORY_ROW_COUNT,
  SET_SHIPNODE_FORMDATA,
  OPEN_VIEW_CHANGES_POPUP,
  SET_IS_FETCHING,
  SET_SUPPLIER_ROLES,
  SET_SUPPLIER_ROLES_PAYLOAD
} from './SupplierOnBoardAction.types'
import { COUNTRY_LIST } from '../../config/genericConstantsNew'
import { validateEmail } from '../../utils/common'
import { OPERATING_CALENDAR } from './commonConstants'

export const INITIAL_STATE = {
  success: false,
  failure: false,
  isBoarding: false,
  vendor: {
    vendorId: null,
    vendorName: null
  },
  shipnodesDetails: [],
  shipnodeForm: {
    leadTime: 2,
    capacity: {
      isEnabled: false,
      defaultValue: null
    },
    timeZone: '',
    address: {
      country: COUNTRY_LIST[0].name
    },
    operatingCalendar: OPERATING_CALENDAR,
    contactInfo: {}
  },
  openShipnodeForm: false,
  openDeactivateForm: false,
  openViewChangesPopup: false,
  requestDescription: '',
  createMode: false,
  totalRows: 0,
  selectedViewDetails: {},
  isFormInvalid: false,
  isFetching: false,
  supplierRoles: [],
  supplierRolesPayload: []
}

export const checkIsEmpty = (object) => {
  for (const key in object) {
    if (isEmpty(object[key]) && key !== 'addressLine2' && key !== 'ext' && key !== 'mobileNumber') {
      return true
    }
  }
  return false
}

export default (state = cloneDeep(INITIAL_STATE), action) => {
  switch (action.type) {
    case BOARD_SUPPLIER_REQUEST:
      return {
        ...state,
        isBoarding: true
      }
    case BOARD_SUPPLIER_SUCCESS:
      return {
        ...state,
        isBoarding: false,
        success: true
      }
    case BOARD_SUPPLIER_FAILURE:
      return {
        ...state,
        isBoarding: false,
        failure: true
      }
    case RESET_ONBOARD:
      return {
        ...INITIAL_STATE
      }
    case OPEN_SHIPNODE_FORM: {
      const { isOpen, isCreate } = action?.payload
      return {
        ...state,
        openShipnodeForm: isOpen,
        createMode: isCreate
      }
    }
    case OPEN_DEACTIVATE_FORM: {
      const { isOpen } = action?.payload
      return {
        ...state,
        openDeactivateForm: isOpen
      }
    }
    case OPEN_VIEW_CHANGES_POPUP: {
      const { isOpen, details } = action?.payload
      try {
        return {
          ...state,
          openViewChangesPopup: isOpen,
          selectedViewDetails: JSON.parse(details)
        }
      } catch (e) {
        return {
          ...state,
          openViewChangesPopup: isOpen,
          selectedViewDetails: {}
        }
      }
    }
    case GET_SHIP_NODES_DETAILS_SUCCESS:
      return {
        ...state,
        shipnodesDetails: [...state.shipnodesDetails, ...action.response?.payload]
      }
    case CLEAR_SHIPNODES_DETAILS:
      return {
        ...state,
        shipnodesDetails: []
      }
    case SET_REQUEST_DESCRIPTION: {
      const { selectedDescription } = action?.payload
      return {
        ...state,
        requestDescription: selectedDescription
      }
    }
    case SET_HISTORY_ROW_COUNT:
      return {
        ...state,
        totalRows: action.payload.count
      }
    case SET_SHIPNODE_FORMDATA:
      const updatedShipnodeForm = { ...state.shipnodeForm, ...action.payload }
      const { address, contactInfo, operatingCalendar, timeZone, capacity } = updatedShipnodeForm
      const isAddressInvalid = checkIsEmpty(address)
      const isContactInvalid = checkIsEmpty(contactInfo) || !validateEmail(contactInfo?.email) ||
        contactInfo?.officeNumber?.length < 12 || contactInfo?.mobileNumber && contactInfo?.mobileNumber?.length < 12
      const isCalenderInvalid = operatingCalendar.filter(val => val.workingDay && val.cutOffTime).length ? false : true
      const isTimeZoneInvalid = state.createMode ? false : !timeZone
      const isCapacityInvalid = capacity?.isEnabled && (capacity?.defaultValue === null || capacity?.defaultValue === '' || parseInt(capacity?.defaultValue) <= 0)
      return {
        ...state,
        shipnodeForm: updatedShipnodeForm,
        isFormInvalid: (isAddressInvalid || isContactInvalid || isCalenderInvalid || isTimeZoneInvalid || isCapacityInvalid)
      }
    case SET_IS_FETCHING:
      const { loading } = action?.payload
      return {
        ...state,
        isFetching: loading
      }

    case SET_SUPPLIER_ROLES:
      const { payload } = action?.payload
      return {
        ...state,
        supplierRoles: payload,
        supplierRolesPayload: payload
      }
    case SET_SUPPLIER_ROLES_PAYLOAD:
      return {
        ...state,
        supplierRolesPayload: action?.payload
      }
    default:
      return { ...state }
  }
}
