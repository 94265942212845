import { cloneDeep, isEmpty } from 'lodash'
import moment from 'moment'
import { ROUTE_MODULES } from '../../config/genericConstants'
import {
  FETCH_WEEKLY_PERFORMANCE_REQUEST,
  FETCH_WEEKLY_PERFORMANCE_SUCCESS,
  FETCH_WEEKLY_PERFORMANCE_FAILURE,
  FETCH_DAILY_PERFORMANCE_REQUEST,
  FETCH_DAILY_PERFORMANCE_SUCCESS,
  FETCH_DAILY_PERFORMANCE_FAILURE,
  FETCH_OOS_ITEMS_REQUEST,
  FETCH_OOS_ITEMS_SUCCESS,
  FETCH_OOS_ITEMS_FAILURE,
  FETCH_LT_COUNT_REQUEST,
  FETCH_LT_COUNT_SUCCESS,
  FETCH_LT_COUNT_FAILURE,
  FETCH_PENALTY_COUNT_REQUEST,
  FETCH_PENALTY_COUNT_SUCCESS,
  FETCH_PENALTY_COUNT_FAILURE,
  FETCH_SHIPNODE_COUNT_REQUEST,
  FETCH_SHIPNODE_COUNT_SUCCESS,
  FETCH_SHIPNODE_COUNT_FAILURE,
  FETCH_AR_COUNT_REQUEST,
  FETCH_AR_COUNT_SUCCESS,
  FETCH_AR_COUNT_FAILURE,
  CHECK_MTERICS_AVAILABLE
} from './Dashboard.types'

export const INITIAL_STATE = {
  daily: {
    tag: `Alerts as on`,
    dailyFetching: false,
    date: '',
    metrics: []
  },
  weekly: {
    weeklyFetching: false,
    weekNo: null,
    tag: 'Key Performance Indicators',
    date: '',
    metrics: []
  },
  leadTime: {
    leadTimeFetching: false,
    tag: 'Alerts as on',
    date: moment().format('MM-DD-YYYY HH:MM'),
    name: 'Pending LT requests',
    title: 'Pending LT requests',
    totalCount: 0
  },
  penaltyCount: {
    name: 'Pending Penalty dispute requests',
    title: 'Pending Penalty dispute requests',
    totalCount: 0
  },
  shipnodeCount: {
    name: 'Pending Shipnode requests',
    title: 'Pending Shipnode requests',
    totalCount: 0
  },
  arCount: {
    name: 'Pending Accounts payable penalties',
    title: 'Pending Accounts payable penalties',
    totalCount: 0
  },
  isMetrics: false
}

export default (state = cloneDeep(INITIAL_STATE), action) => {
  switch (action.type) {
    case FETCH_WEEKLY_PERFORMANCE_REQUEST:
      return {
        ...state,
        weekly: {
          ...state.weekly,
          weeklyFetching: true
        }
      }
    case FETCH_WEEKLY_PERFORMANCE_SUCCESS: {
      const { response } = action

      if (isEmpty(response)) {
        return {
          ...state,
          weekly: {
            ...state.weekly,
            weeklyFetching: false,
            metrics: []
          }
        }
      }
      const { date, metrics, weekNo } = action.response.payload

      return {
        ...state,
        weekly: {
          ...state.weekly,
          weeklyFetching: false,
          weekNo,
          date,
          metrics
        }
      }
    }
    case FETCH_WEEKLY_PERFORMANCE_FAILURE:
      return {
        ...state,
        weekly: {
          ...state.weekly,
          weeklyFetching: false
        }
      }
    case FETCH_DAILY_PERFORMANCE_REQUEST:
    case FETCH_OOS_ITEMS_REQUEST:
      return {
        ...state,
        daily: {
          ...state.daily,
          dailyFetching: true,
          metrics: []
        }
      }
    case FETCH_DAILY_PERFORMANCE_SUCCESS: {
      const { response } = action

      if (isEmpty(response)) {
        return {
          ...state,
          daily: {
            ...state.daily,
            dailyFetching: false,
            metrics: []
          }
        }
      }
      const { date, metrics } = action.response.payload
      return {
        ...state,
        daily: {
          ...state.daily,
          date,
          metrics,
          dailyFetching: false
        }
      }
    }
    case FETCH_DAILY_PERFORMANCE_FAILURE:
    case FETCH_OOS_ITEMS_FAILURE:
      return {
        ...state,
        daily: {
          ...state.daily,
          dailyFetching: false
        }
      }
    case FETCH_OOS_ITEMS_SUCCESS: {
      const { totalRecords, itemAlertsList } = action.response.payload
      const metric = [...state.daily.metrics]

      if (!isEmpty(totalRecords) && totalRecords !== '0') {
        const OOSItems = {
          name: 'OOS items',
          title: 'OOS items',
          value: totalRecords,
          link: ROUTE_MODULES.prepurchase?.smartinventory?.path
        }
        metric.push(OOSItems)
      }

      if (!isEmpty(itemAlertsList) && !isEmpty(itemAlertsList[0].networkOosPercentage)) {
        const OOSPercentage = {
          name: 'Network OOS',
          title: 'Network OOS',
          value: `${itemAlertsList[0].networkOosPercentage}%`
        }
        metric.push(OOSPercentage)
      }

      return {
        ...state,
        daily: {
          ...state.daily,
          date: !isEmpty(state?.daily?.date) ? state?.daily?.date : moment(),
          metrics: metric,
          dailyFetching: false
        }
      }
    }
    case FETCH_LT_COUNT_REQUEST:
      return {
        ...state,
        leadTime: {
          ...state.leadTime,
          leadTimeFetching: true
        }
      }
    case FETCH_LT_COUNT_SUCCESS: {
      const { totalCount } = action?.response?.payload
      return {
        ...state,
        leadTime: {
          ...state.leadTime,
          leadTimeFetching: false,
          totalCount
        }
      }
    }
    case FETCH_LT_COUNT_FAILURE:
      return {
        ...state,
        leadTime: {
          ...state.leadTime,
          leadTimeFetching: false
        }
      }
    case FETCH_PENALTY_COUNT_REQUEST:
    case FETCH_PENALTY_COUNT_FAILURE:
      return {
        ...state,
        penaltyCount: {
          ...state.penaltyCount
        }
      }
    case FETCH_PENALTY_COUNT_SUCCESS: {
      const { totalCount } = action?.response?.payload
      return {
        ...state,
        penaltyCount: {
          ...state.penaltyCount,
          totalCount
        }
      }
    }
    case FETCH_SHIPNODE_COUNT_REQUEST:
    case FETCH_SHIPNODE_COUNT_FAILURE:
      return {
        ...state,
        shipnodeCount: {
          ...state.shipnodeCount
        }
      }
    case FETCH_SHIPNODE_COUNT_SUCCESS: {
      const { totalCount } = action?.response?.payload
      return {
        ...state,
        shipnodeCount: {
          ...state.shipnodeCount,
          totalCount
        }
      }
    }
    case FETCH_AR_COUNT_REQUEST:
    case FETCH_AR_COUNT_FAILURE:
      return {
        ...state,
        arCount: {
          ...state.arCount
        }
      }
    case FETCH_AR_COUNT_SUCCESS: {
      const { totalCount } = action?.response?.payload
      return {
        ...state,
        arCount: {
          ...state.arCount,
          totalCount
        }
      }
    }
    case CHECK_MTERICS_AVAILABLE:
      return {
        ...state,
        isMetrics: true
      }
    default:
      return { ...state }
  }
}
