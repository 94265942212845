export const OPEN_LEFT_DRAWER = 'OPEN_LEFT_DRAWER'
export const CLOSE_LEFT_DRAWER = 'CLOSE_LEFT_DRAWER'
export const ERROR_MESSAGE = 'ERROR'

export const SEARCH_VENDOR_REQUEST = 'SEARCH_VENDOR_REQUEST'
export const SEARCH_VENDOR_SUCCESS = 'SEARCH_VENDOR_SUCCESS'
export const SEARCH_VENDOR_FAILURE = 'SEARCH_VENDOR_FAILURE'
export const CLEAR_VENDOR_SEARCH = 'CLEAR_VENDOR_SEARCH'

export const FETCH_VENDOR_ROLES_REQUEST = 'FETCH_VENDOR_ROLES_REQUEST'
export const FETCH_VENDOR_ROLES_SUCCESS = 'FETCH_VENDOR_ROLES_SUCCESS'
export const FETCH_VENDOR_ROLES_FAILURE = 'FETCH_VENDOR_ROLES_FAILURE'

export const SELECT_VENDOR = 'SELECT_VENDOR'
export const HIDE_FULFILLMENT_TABS = 'HIDE_FULFILLMENT_TABS'
export const SET_PARTNER_LIST = 'SET_PARTNER_LIST'

/* === New action types === */
export const FETCH_SHIP_NODES_REQUEST = 'FETCH_SHIP_NODES_REQUEST'
export const FETCH_SHIP_NODES_SUCCESS = 'FETCH_SHIP_NODES_SUCCESS'
export const FETCH_SHIP_NODES_FAILURE = 'FETCH_SHIP_NODES_FAILURE'
export const SELECT_SHIP_NODE = 'SELECT_SHIP_NODE'
export const CLEAR_SHIP_NODE = 'CLEAR_SHIP_NODE'
export const SHOW_TOAST_MESSAGE = 'SHOW_TOAST_MESSAGE'
export const HIDE_TOAST_MESSAGE = 'HIDE_TOAST_MESSAGE'

export const BULK_UPLOAD_FILES_REQUEST = 'BULK_UPLOAD_FILES_REQUEST'
export const BULK_UPLOAD_FILES_FAILURE = 'BULK_UPLOAD_FILES_FAILURE'
export const BULK_UPLOAD_FILES_SUCCESS = 'BULK_UPLOAD_FILES_SUCCESS'

export const GET_EXPORT_FILE_REQUEST = 'GET_EXPORT_FILE_REQUEST'
export const GET_EXPORT_FILE_FAILURE = 'GET_EXPORT_FILE_FAILURE'
export const GET_EXPORT_FILE_SUCCESS = 'GET_EXPORT_FILE_SUCCESS'

export const RETRY_EXPORT_FILE_REQUEST = 'RETRY_EXPORT_FILE_REQUEST'
export const RETRY_EXPORT_FILE_FAILURE = 'RETRY_EXPORT_FILE_FAILURE'
export const RETRY_EXPORT_FILE_SUCCESS = 'RETRY_EXPORT_FILE_SUCCESS'

export const INITIATE_EXPORT_REQUEST = 'INITIATE_EXPORT_REQUEST'
export const INITIATE_EXPORT_FAILURE = 'INITIATE_EXPORT_FAILURE'
export const INITIATE_EXPORT_SUCCESS = 'INITIATE_EXPORT_SUCCESS'

export const CHECK_EXPORT_STATUS_REQUEST = 'CHECK_EXPORT_STATUS_REQUEST'
export const CHECK_EXPORT_STATUS_FAILURE = 'CHECK_EXPORT_STATUS_FAILURE'
export const CHECK_EXPORT_STATUS_SUCCESS = 'CHECK_EXPORT_STATUS_SUCCESS'

export const SHOW_EXPORT_POPUP = 'SHOW_EXPORT_POPUP'
export const HIDE_EXPORT_POPUP = 'HIDE_EXPORT_POPUP'
export const SHOW_BULK_UPDATE_POPUP = 'SHOW_BULK_UPDATE_POPUP'
export const HIDE_BULK_UPDATE_POPUP = 'HIDE_BULK_UPDATE_POPUP'
export const SET_MODULE_TYPE = 'SET_MODULE_TYPE'

export const SKIP_SUPPLIER_SELECTION = 'SKIP_SUPPLIER_SELECTION'

export const TOGGLE_ATMT_FEEDBACK = 'TOGGLE_ATMT_FEEDBACK'

export const TOGGLE_SOURCE_TYPE='TOGGLE_SOURCE_TYPE'
export const GET_SOURCE_TYPE='GET_SOURCE_TYPE'
export const SET_SOURCE_TYPE='SET_SOURCE_TYPE'
