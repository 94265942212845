import { isEmpty } from 'lodash'
export const column = [
    { name: 'approvedOn', title: 'Approved on', sorting: true, align: 'left' },
    { name: 'approvedBy', title: 'Approved by', sorting: false, align: 'left' },
    { name: 'requestBy', title: 'Requested by', sorting: false, align: 'left' },
    { name: 'newCapacity', title: 'New capacity', sorting: false, align: 'right' },
    { name: 'startDate', title: 'Start date', sorting: true, align: 'left' },
    { name: 'endDate', title: 'End date', sorting: true, align: 'left' },
    { name: 'reasonCode', title: 'Reason for change', sorting: false, align: 'left' },
    {
        name: 'recurrence', title: 'Days of week to update', sorting: false, align: 'left',
        getCellValue: row => {
            if (isEmpty(row['recurrence'])) {
                return 'All Days'
            }
            return row['recurrence'].join(', ')
        }
    }
]

export const columnExtensions = [
    { columnName: 'approvedOn', align: 'left' },
    { columnName: 'approvedBy', align: 'left' },
    { columnName: 'requestBy', align: 'left' },
    { columnName: 'newCapacity', align: 'right', width: '8%' },
    { columnName: 'startDate', align: 'left' },
    { columnName: 'endDate', align: 'left' },
    { columnName: 'reasonCode', align: 'left', width: '20%' },
    { columnName: 'recurrence', align: 'left', width: '20%' }
]

export const formattedColumns = []
