export const BOARD_SUPPLIER_REQUEST = 'ONBOARD_SUPPLIER_REQUEST'
export const BOARD_SUPPLIER_SUCCESS = 'ONBOARD_SUPPLIER_SUCCESS'
export const BOARD_SUPPLIER_FAILURE = 'ONBOARD_SUPPLIER_FAILURE'
export const GET_SHIP_NODES_DETAILS_REQUEST = 'GET_SHIP_NODES_DETAILS_REQUEST'
export const GET_SHIP_NODES_DETAILS_SUCCESS = 'GET_SHIP_NODES_DETAILS_SUCCESS'
export const GET_SHIP_NODES_DETAILS_FAILURE = 'GET_SHIP_NODES_DETAILS_FAILURE'
export const CREATE_SHIPNODE_REQUEST = 'CREATE_SHIPNODE_REQUEST'
export const CREATE_SHIPNODE_SUCCESS = 'CREATE_SHIPNODE_SUCCESS'
export const CREATE_SHIPNODE_FAILURE = 'CREATE_SHIPNODE_FAILURE'
export const DEACTIVE_SHIPNODE_REQUEST = 'DEACTIVE_SHIPNODE_REQUEST'
export const DEACTIVE_SHIPNODE_SUCCESS = 'DEACTIVE_SHIPNODE_SUCCESS'
export const DEACTIVE_SHIPNODE_FAILURE = 'DEACTIVE_SHIPNODE_FAILURE'
export const RESET_ONBOARD = 'RESET_ONBOARD'
export const OPEN_SHIPNODE_FORM = 'OPEN_SHIPNODE_FORM'
export const OPEN_DEACTIVATE_FORM = 'OPEN_DEACTIVATE_FORM'
export const SET_REQUEST_DESCRIPTION = 'SET_REQUEST_DESCRIPTION'
export const CLEAR_SHIPNODES_DETAILS = 'CLEAR_SHIPNODES_DETAILS'
export const SET_HISTORY_ROW_COUNT = 'SET_HISTORY_ROW_COUNT'
export const SET_SHIPNODE_FORMDATA = 'SET_SHIPNODE_FORMDATA'
export const OPEN_VIEW_CHANGES_POPUP = 'OPEN_VIEW_CHANGES_POPUP'
export const APPROVE_REJECT_REQUEST = 'APPROVE_REJECT_REQUEST'
export const APPROVE_REJECT_FAILURE = 'APPROVE_REJECT_FAILURE'
export const APPROVE_REJECT_SUCCESS = 'APPROVE_REJECT_SUCCESS'
export const SET_IS_FETCHING = 'SET_IS_FETCHING'
export const SET_SUPPLIER_ROLES = 'SET_SUPPLIER_ROLES'
export const SUPPLIER_FEATURES_REQUEST = 'SUPPLIER_FEATURES_REQUEST'
export const SUPPLIER_FEATURES_SUCCESS = 'SUPPLIER_FEATURES_SUCCESS'
export const SUPPLIER_FEATURES_FAILURE = 'SUPPLIER_FEATURES_FAILURE'
export const SUPPLIER_FEATURES_UPDATE_REQUEST = 'SUPPLIER_FEATURES_UPDATE_REQUEST'
export const SUPPLIER_FEATURES_UPDATE_SUCCESS = 'SUPPLIER_FEATURES_UPDATE_SUCCESS'
export const SUPPLIER_FEATURES_UPDATE_FAILURE = 'SUPPLIER_FEATURES_UPDATE_FAILURE'
export const SET_SUPPLIER_ROLES_PAYLOAD = 'SET_SUPPLIER_ROLES_PAYLOAD'
