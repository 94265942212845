import moment from 'moment'
/**
 * Format month when Rip is present
 * @param {Array} suppliers Selected values of supplier dropdown
 */
const formatMonthLabel = (value, label) => {
    return `${moment().add(value, 'months').format('MMMM')} ${label}`
}

export const COLUMNS = [{
    name: 'Brand', value: 'brand'
}, {
    name: 'Manufacturer', value: 'manufacturer'
}, {
    name: 'Bottle size', value: 'bottleSize'
}, {
    name: 'UOM', value: 'uom'
}, {
    name: formatMonthLabel(0, 'RIP'), value: 'month0Rip'
}, {
    name: formatMonthLabel(0, 'dollars'), value: 'month0Dollars'
}, {
    name: formatMonthLabel(1, 'RIP'), value: 'month1Rip'
}, {
    name: formatMonthLabel(1, 'dollars'), value: 'month1Dollars'
}, {
    name: formatMonthLabel(2, 'RIP'), value: 'month2Rip'
}, {
    name: formatMonthLabel(2, 'dollars'), value: 'month2Dollars'
}, {
    name: formatMonthLabel(3, 'RIP'), value: 'month3Rip'
}, {
    name: formatMonthLabel(3, 'dollars'), value: 'month3Dollars'
}]
