import { CircularProgress } from '@mui/material'
import { withStyles } from '@mui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

const styles = theme => ({
  colorPrimary: {
    color: theme.palette.primary.blue
  },
  colorWhite: {
    color: theme.palette.primary.white
  }
})

const SSHLoadingAnimation = ({ classes, size, thickness, isWhite }) => {
  const STYLES = clsx({
    [classes.colorPrimary]: !isWhite,
    [classes.colorWhite]: !!isWhite
  })

  return <CircularProgress size={size} thickness={thickness} classes={{ root: STYLES }} />
}

SSHLoadingAnimation.defaultProps = {
  size: 80,
  thickness: 3,
  isWhite: false
}

SSHLoadingAnimation.propTypes = {
  size: PropTypes.number,
  thickness: PropTypes.number,
  classes: PropTypes.object.isRequired,
  isWhite: PropTypes.bool
}

export default withStyles(styles)(SSHLoadingAnimation)
