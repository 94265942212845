import React, { useEffect, useState, useRef } from 'react'
import { Box, ListItem, ListItemText } from '@mui/material'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { SSHTypography } from '../../components/SSHTypography'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import SSHPopover from '../../components/SSHPopover'
import { redirectToHomePage, toggleSourceType, setRolesOnTypeChange } from '../../reduxSetup/commonActions/AppConfig'
import clsx from 'clsx'
import { isEmpty, find } from 'lodash'

const styles = (theme => ({
    root: {
        zIndex: 999,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        cursor: 'pointer',
        color: theme.palette.primary.white,
        '& div': {
            color: theme.palette.primary.white,
            zIndex: 999
        },
        '& hr': {

        }
    },
    line: {
        width: `${theme.replacePX(theme.spacing(6.25))}%`,
        margin: '0 auto',
        left: `${theme.spacing(9)}`,
        right: 0,
        borderBottom: `2px solid ${theme.palette.primary.cobalt}`,
        position: 'absolute',
        top: `${theme.spacing(3.5)}`
    },
    shorLine: {
        width: `${theme.replacePX(theme.spacing(8.125))}% !important`,
        left: `${theme.spacing(2.5)} !important`
    },
    hideLine: {
        padding: `${theme.spacing(0.25)} ${theme.spacing(0.75)}`,
        backgroundColor: theme.palette.primary.cobalt,
        borderRadius: `${theme.spacing(2)}`,
        marginBottom: `${theme.spacing(1)}`,
        '& div': {
            'overflow': 'visible'
        }
    },
    arrow: {
        position: 'relative',
        top: `${theme.spacing(0.4)}`,
        right: `-${theme.spacing(2.5)}`,
        cursor: 'pointer',
        '& svg': {
            color: theme.palette.primary.cobalt
        }
    },
    hoverStyle: {
        cursor: 'pointer'
    },
    active: {
        color: theme.palette.primary.cobalt
    }
}))

function usePreviousValue(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const SupplierTypeSelection = ({ classes, drawerOpen, sourceType, toggleSourceType, redirectToHomePage, ...restProps }) => {

    const [anchorEl, setAnchorEl] = useState(null)

    const open = Boolean(anchorEl)

    const prevSelected = usePreviousValue(sourceType?.selected)

    /**
     * @description get lables name
     * @param {*} type
     * @returns  string name
     */
    const getLabels = ({ source }) => {
        if (isEmpty(source.alias)) {
            return source.name
        }
        return `${source.name} (${source.alias})`
    }

    /**
     * @description toggle source type
     * @param {*} event
     */
    const toggleSource = (event) => {
        event.preventDefault()
        restProps.closeMainMenu(null)
        setAnchorEl(event.currentTarget)
    }

    /**
     * @description set source type
     */
    const setSourceType = (source) => {
        toggleSourceType(source?.id)
        restProps.setRolesOnTypeChange({ vendorRoleInfo: restProps.rolesList })
        setAnchorEl(null)
    }

    useEffect(() => {
        if (!isEmpty(restProps.mainMenuStatus) && !isEmpty(anchorEl)) {
            setAnchorEl(null)
        }
    })

    /**
     * @description redirect page based on source type change (DSV/CLUB) only if current pathname is not '/'
     * @returns redirects to Dashboard(DSV) or Inventory page(CLUB)
     */
    useEffect(() => {
        if ((prevSelected && prevSelected !== sourceType?.selected)) {
            redirectToHomePage()
        }
    }, [sourceType?.selected])

    /**
     * @description set type in label on selected option
     * @returns string type name
     */
    const setType = () => {
        const selectedType = find(sourceType?.type, { id: sourceType?.selected })
        return !drawerOpen ? String(selectedType?.name.substring(0, 4)) : selectedType?.name
    }


    return (
        <>
            <div className={clsx(classes.root, !drawerOpen ? classes.hideLine : '')}
                onClick={toggleSource} onMouseEnter={toggleSource}>
                <SSHTypography component='div' color='#fff'>{setType()}</SSHTypography>
                <div className={clsx(classes.line, sourceType?.selected === 2 ? classes.shorLine : '')}></div>
                <div className={classes.arrow} onClick={toggleSource}>
                    <ArrowDropDownIcon fontSize='large' />
                </div>
            </div>
            {!restProps.isFetching && !restProps.disableDownload && <SSHPopover
                id="ssh-popover"
                anchorEl={anchorEl}
                handleClose={() => { setAnchorEl(null) }}
                disableRestoreFocus
                isOpen={open}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}>
                {
                    sourceType?.type.map(source => {
                        return (
                            <Box key={source?.id} className={clsx(classes.hoverStyle, source.id === sourceType.selected ? classes.active : '')}>
                                <ListItem onClick={() => setSourceType(source)}>
                                    <ListItemText
                                        primary={getLabels({ source })}
                                        data-testid="itemText"
                                    />
                                </ListItem>
                            </Box>
                        )
                    })
                }
            </SSHPopover>}
        </>
    )
}

export const SupplierTypeSelectionNaked = SupplierTypeSelection

SupplierTypeSelection.defaultProps = {
    text: 'Ecommerce'
}

SupplierTypeSelection.propTypes = {
    text: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    drawerOpen: PropTypes.bool.isRequired,
    sourceType: PropTypes.array.isRequired,
    redirectToHomePage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    drawerOpen: state.AppConfig.leftDrawer.open,
    sourceType: state.AppConfig.sourceType,
    isFetching: state.AppConfig.vendor.isFetching,
    currentPath: state.router.location.pathname,
    disableDownload: state.NotificationDrawer.disableInventoryDownload,
    rolesList: state.AppConfig.vendor.rolesList
})

const mapDispatchToProps = dispatch =>
    bindActionCreators({ toggleSourceType, redirectToHomePage, setRolesOnTypeChange }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SupplierTypeSelection))
