const fulfillment = '/fulfillment'
const inventory = '/inventory'
const feed = '/feed'
const performance = '/performance'
const leadTime = '/lead-time'
const adminLeadTime = '/admin/approvals'
const mantisLeadTime = '/admin/mantis-lead-time'
const smartInventory = '/smart-inventory'
const supplierManagement = '/supplier-mgt'
const reports = '/admin/reports'
const penalty = '/penalty'
const returns = '/returns'
const adminFeed = '/admin/feed'

export const mockResponse = {
  header: {},
  status: 'OK',
  payload: {
    responseType: 'IAM',
    userInfo: {
      responseType: null,
      rlId: null,
      firstName: 'Pradeep',
      lastName: 'kadnor',
      emailId: 'Pradeep.Kadnor@walmart.com',
      phone: null,
      contactCountryCode: null,
      contactPhoneCountry: null,
      uuid: null,
      partners: null,
      loginId: 'p0k0133',
      partnerType: 'internal-supplier',
      userRole: null,
      siteAdmin: null,
      external: false,
      permissions: [
        {
          resourceName: reports,
          action: 'DOWNLOAD'
        },
        {
          resourceName: reports,
          action: 'VIEW'
        },
        {
          resourceName: penalty,
          action: 'EDIT'
        },
        {
          resourceName: penalty,
          action: 'DOWNLOAD'
        },
        {
          resourceName: supplierManagement,
          action: 'EDIT'
        },
        {
          resourceName: supplierManagement,
          action: 'UPDATE'
        },
        {
          resourceName: returns,
          action: 'EDIT'
        },
        {
          resourceName: fulfillment,
          action: 'PICKLIST'
        },
        {
          resourceName: feed,
          action: 'UPLOAD'
        },
        {
          resourceName: performance,
          action: 'DOWNLOAD'
        },
        {
          resourceName: fulfillment,
          action: 'DOWNLOAD'
        },
        {
          resourceName: fulfillment,
          action: 'LABEL_DOWNLOAD'
        },
        {
          resourceName: inventory,
          action: 'DOWNLOAD'
        },
        {
          resourceName: adminLeadTime,
          action: 'UPDATE'
        },
        {
          resourceName: adminLeadTime,
          action: 'DOWNLOAD'
        },
        {
          resourceName: mantisLeadTime,
          action: 'EDIT'
        },
        {
          resourceName: inventory,
          action: 'EDIT'
        },
        {
          resourceName: fulfillment,
          action: 'EDIT'
        },
        {
          resourceName: leadTime,
          action: 'UPDATE'
        },
        {
          resourceName: feed,
          action: 'SEARCH'
        },
        {
          resourceName: inventory,
          action: 'UPLOAD'
        },
        {
          resourceName: fulfillment,
          action: 'UPLOAD'
        },
        {
          resourceName: feed,
          action: 'DOWNLOAD'
        },
        {
          resourceName: smartInventory,
          action: 'DOWNLOAD'
        },
        {
          resourceName: smartInventory,
          action: 'UPLOAD'
        },
        {
          resourceName: smartInventory,
          action: 'EDIT'
        },
        {
          resourceName: smartInventory,
          action: 'UPDATE'
        },
        {
          resourceName: adminFeed,
          action: "UPLOAD"
        },
        {
          resourceName: adminLeadTime,
          action: "UPLOAD"
        },
        {
          resourceName: adminFeed,
          action: "DOWNLOAD"
        },
        {
          resourceName: adminFeed,
          action: 'SEARCH'
        }
      ]
    },
    appUserRole: 'admin',
    customerToken: null,
    appUserRoleInfo: null,
    sessionId: null,
    iamToken: null,
    superAdmin: false
  }
}

export const merchant = {
  permissions: [
    {
      resourceName: reports,
      action: 'DOWNLOAD'
    },
    {
      resourceName: reports,
      action: 'VIEW'
    },
    {
      resourceName: penalty,
      action: 'DOWNLOAD'
    },
    {
      resourceName: fulfillment,
      action: 'PICKLIST'
    },
    {
      resourceName: performance,
      action: 'DOWNLOAD'
    },
    {
      resourceName: fulfillment,
      action: 'DOWNLOAD'
    },
    {
      resourceName: fulfillment,
      action: 'LABEL_DOWNLOAD'
    },
    {
      resourceName: inventory,
      action: 'DOWNLOAD'
    },
    {
      resourceName: adminLeadTime,
      action: 'DOWNLOAD'
    },
    {
      resourceName: leadTime,
      action: 'UPDATE'
    },
    {
      resourceName: feed,
      action: 'SEARCH'
    },
    {
      resourceName: feed,
      action: 'DOWNLOAD'
    },
    {
      resourceName: smartInventory,
      action: 'DOWNLOAD'
    },
    {
      resourceName: adminFeed,
      action: 'DOWNLOAD'
    },
    {
      resourceName: adminFeed,
      action: 'SEARCH'
    }
  ]
}
