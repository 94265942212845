import { Table } from '@devexpress/dx-react-grid-material-ui'
import React from 'react'
import { connect } from 'react-redux'
import SSHLabel from '../../components/SSHLabel'

/**
 * @description Component to render custom table cell for smart inventory download status
 * @returns React element with the inventory status information
 */
const SmartInventoryStatusCell = ({ row, ...props }) => {
  const STATUS = {
    IN_STOCK: 'In stock',
    LOW_STOCK: 'Low stock',
    OUT_OF_STOCK: 'Out of stock'
  }
  return (
    <Table.Cell {...props} value={row.status} style={{ padding: 0, paddingLeft: 8 }}>
      <SSHLabel value={STATUS[row.status]} />
    </Table.Cell>
  )
}

export default connect(null, null)(SmartInventoryStatusCell)
