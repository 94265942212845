import config from '../../config'
import { CALL_API } from '../../reduxSetup/middleware/API'
import { getVendorIdFromState } from '../../utils/common'
import {
  FETCH_NETWORK_MANTIS_LEAD_TIME_REQUEST,
  FETCH_NETWORK_MANTIS_LEAD_TIME_SUCCESS,
  FETCH_NETWORK_MANTIS_LEAD_TIME_FAILURE,
  SET_NETWORK_MANTIS_LEAD_TIME_REQUEST,
  SET_NETWORK_MANTIS_LEAD_TIME_SUCCESS,
  SET_NETWORK_MANTIS_LEAD_TIME_FAILURE,
  FETCH_SHIP_NODE_MANTIS_LEAD_TIME_REQUEST,
  FETCH_SHIP_NODE_MANTIS_LEAD_TIME_SUCCESS,
  FETCH_SHIP_NODE_MANTIS_LEAD_TIME_FAILURE,
  SET_SHIP_NODE_MANTIS_LEAD_TIME_REQUEST,
  SET_SHIP_NODE_MANTIS_LEAD_TIME_SUCCESS,
  SET_SHIP_NODE_MANTIS_LEAD_TIME_FAILURE,
  CLEAR_SHIP_NODE
} from './MantisLeadTime.actionTypes'
import {
  aiFetchNetworkLevelFlag,
  aiSetNetworkLevelFlag,
  aiGetShipNodeLevelFlag,
  aiSetShipNodeLevelFlag
} from '../../reduxSetup/commonActions/TelemetryActionsNew'

/**
 *  get network level flag
 * @returns {object} network flag
 */
export const fetchNetworkLevelFlag = () => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_NETWORK_MANTIS_LEAD_TIME_REQUEST,
        FETCH_NETWORK_MANTIS_LEAD_TIME_SUCCESS,
        FETCH_NETWORK_MANTIS_LEAD_TIME_FAILURE
      ],
      endpoint: `${config.get('mantisEnableFlag')}/tasktype/mantis/status`,
      method: 'GET'
    }
  }).then(({ type }) => {
    dispatch(aiFetchNetworkLevelFlag({ type }))
  })
}

/**
 *  set network level flag
 * @returns {object} any
 */
export const setNetworkLevelFlag = () => (dispatch, getState) => {
  const IS_NETWORK_FLAG = getState().MantisLeadTime.isNetworkFlag
  return dispatch({
    [CALL_API]: {
      types: [
        SET_NETWORK_MANTIS_LEAD_TIME_REQUEST,
        SET_NETWORK_MANTIS_LEAD_TIME_SUCCESS,
        SET_NETWORK_MANTIS_LEAD_TIME_FAILURE
      ],
      endpoint: `${config.get('mantisEnableFlag')}/tasktype/mantis/status/${!IS_NETWORK_FLAG}`,
      method: 'POST',
      data: {}
    }
  }).then(({ type }) => {
    dispatch(aiSetNetworkLevelFlag({ type, IS_NETWORK_FLAG }))
  })
}

/**
 *  get network level flag
 * @returns {object} network flag
 */
export const getShipNodeLevelFlag = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)

  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_SHIP_NODE_MANTIS_LEAD_TIME_REQUEST,
        FETCH_SHIP_NODE_MANTIS_LEAD_TIME_SUCCESS,
        FETCH_SHIP_NODE_MANTIS_LEAD_TIME_FAILURE
      ],
      endpoint: `${config.get('mantisEnableFlag')}/tasktype/mantis/supplier/${VENDOR_ID}/status`,
      method: 'GET',
      data: {}
    }
  }).then(({ type }) => {
    dispatch(aiGetShipNodeLevelFlag({ type, VENDOR_ID }))
  })
}

/**
 *  set network level flag
 * @returns {object} any
 */
export const setShipNodeLevelFlag = ({ shipNodeId }) => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODES = getState().MantisLeadTime.shipNodes

  if (SHIP_NODES.length === 0) {
    return null
  }

  const SHIP_NODE = SHIP_NODES.filter(node => {
    return node.shipNodeId === shipNodeId
  })

  return dispatch({
    [CALL_API]: {
      types: [
        SET_SHIP_NODE_MANTIS_LEAD_TIME_REQUEST,
        SET_SHIP_NODE_MANTIS_LEAD_TIME_SUCCESS,
        SET_SHIP_NODE_MANTIS_LEAD_TIME_FAILURE
      ],
      endpoint: `${config.get('mantisEnableFlag')}/supplier/${VENDOR_ID}`,
      method: 'POST',
      data: {
        shipNodeId: SHIP_NODE.length > 0 ? SHIP_NODE[0].shipNodeId : 0,
        taskType: 'MANTIS',
        flag: SHIP_NODE.length > 0 && SHIP_NODE[0].enabled ? 'false' : 'true'
      },
      payload: {
        shipNodeId: SHIP_NODE.length > 0 ? SHIP_NODE[0].shipNodeId : 0
      }
    }
  }).then(({ type }) => {
    dispatch(
      aiSetShipNodeLevelFlag({ type, VENDOR_ID, SHIP_NODE_ID: SHIP_NODE.length > 0 ? SHIP_NODE[0].shipNodeId : 0 })
    )
  })
}

/**
 *  clear mantis ship node on vendor change
 * @returns {object} any
 */
export const clearMantisShipNode = () => dispatch => {
  return Promise.resolve(() => {
    dispatch({
      type: CLEAR_SHIP_NODE
    })
  })
}
