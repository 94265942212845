import { setFeedRowCount } from '../../pages/Feed/FeedStatusAction'
import {
  CHANGE_PAGE_SIZE,
  RESET_PAGINATED_TABLE,
  RESET_SEARCH,
  SET_CURRENT_PAGE,
  RESET_FCAPACITY_TABLE
} from './SSHPaginatedTable.actionTypes'
import { aiTableChangePageNumber, aiTableChangePageSize } from './TelemetryActions'

export const setCurrentPage = pageNo => (dispatch, getState) => {
  const oldPage = getState().SSHPaginatedTableReducer.pageNo
  return Promise.resolve(
    dispatch({
      type: SET_CURRENT_PAGE,
      payload: { pageNo }
    })
  ).then(dispatch(aiTableChangePageNumber({ oldPage, newPage: pageNo })))
}

export const changePageSize = (pageSize, totalRows) => (dispatch, getState) => {
  const newTotalPages = !totalRows ? 9999999 : Math.floor(totalRows / pageSize)
  const currentPage = getState().SSHPaginatedTableReducer.pageNo
  const oldPageSize = getState().SSHPaginatedTableReducer.pageSize

  const promises = []
  promises.push(
    dispatch({
      type: CHANGE_PAGE_SIZE,
      payload: { pageSize }
    })
  )
  if (newTotalPages < currentPage) promises.push(dispatch(setCurrentPage(newTotalPages)))

  return Promise.resolve(promises).then(dispatch(aiTableChangePageSize({ oldPageSize, newPageSize: pageSize })))
}

export const resetTable = (action = undefined) => dispatch => {
  return Promise.resolve(
    dispatch({
      type: RESET_PAGINATED_TABLE
    })
  ).then(dispatch(action ? action() : setFeedRowCount({ count: null })))
}

export const resetTableSearch = () => dispatch => {
  return Promise.resolve(
    dispatch({
      type: RESET_SEARCH
    })
  )
}

export const resetPagination = () => dispatch => {
  const pageNo = 0
  return dispatch({
    type: SET_CURRENT_PAGE,
    payload: { pageNo }
  })
}

/**
 * @description reset capacity
 * @returns {*} object
 */
export const resetFcapacityTable=()=>dispatch=>{
  return dispatch({
    type:RESET_FCAPACITY_TABLE
  })
}
