import { Table } from '@devexpress/dx-react-grid-material-ui'
import React from 'react'
import { connect } from 'react-redux'
import SSHLabel from '../../components/SSHLabel'

/**
 * @description Component to render custom table cell for download status
 * @returns React element with the download status information
 */
const DownloadedStatusCell = ({ value, style, ackIds, row, ...props }) => {
  const ackStateObj = ackIds.find(ackIdObj => ackIdObj.ackId === row.ackId)

  /**
   * The state object updates on checkStatus api, to render updated status of file download.
   * This check confirms that if checkStatus api is not called but the status is updated in
   * downloadDetails api then the state object should also update itself, but only in the case of Terminal states.
   */

  // if (ackStateObj && ['COMPLETED', 'FAILED'].includes(row.status)) {
  //   ackStateObj.status = row.status
  // }

  return (
    <Table.Cell
      {...props}
      value={ackStateObj ? ackStateObj.status : value}
      style={{ ...style, padding: 0, paddingLeft: 8 }}>
      <SSHLabel value={ackStateObj ? ackStateObj.status : value} />
    </Table.Cell>
  )
}

const mapStateToProps = state => ({
  ackIds: state.AppConfig.exportOrders.ackIds
})

export default connect(mapStateToProps, null)(DownloadedStatusCell)
