import { MODULE_TYPES } from '../../config/genericConstants'
import {
  AI_UPDATE_ITEM_DETAILS_CHANGES_FAILED,
  AI_UPDATE_ITEM_SHIPMENT_QUANTITY,
  AI_SET_SHIPMENT_FLAG,
  AI_SHIPMENT_ITEMS,
  AI_GET_SHIPPING_LABEL,
  AI_CANCELED_ORDER_UNITS,
  AI_FETCH_NETWORK_LEVEL_FLAGS,
  AI_SET_NETWORK_LEVEL_FLAG,
  AI_GET_SHIP_NODE_LEVEL_FLAG,
  AI_SET_SHIP_NODE_LEVEL_FLAG,
  AI_FETCH_DOWNLOAD_REPORT,
  AI_DOWNLOAD_REPORT_RETRY,
  AI_DOWNLOAD_REPORT_NOTIFICATION,
  AI_DOWNLOAD_REPORT_TABLE,
  AI_DOWNLOAD_REPORT_RETRY_NOTIFICATION,
  AI_SEARCH_DOWNLOAD_REPORT,
  AI_ONBOARD_SUPPLIER_REQUEST,
  AI_FETCH_SMART_INVENTORY_AGGREGATED,
  AI_FETCH_SMART_INVENTORY_ITEM,
  AI_SMART_INVENTORY_ITEM_SEARCH,
  AI_SMART_INVENTORY_AGGREGATED_SEARCH,
  AI_SMART_INVENTORY_DOWNLOAD,
  AI_SMART_INVENTORY_UPLOAD,
  AI_SMART_INVENTORY_RESET,
  AI_SMART_INVENTORY_REMOVE_FILTER,
  AI_SMART_INVENTORY_FETCH_CATEGORIES,
  AI_SMART_INVENTORY_FETCH_SUPPLIERS,
  AI_SMART_INVENTORY_ACTION,
  AI_SMART_INVENTORY_FETCH_DIVISIONS,
  AI_SMART_INVENTORY_PAST_TRENDS,
  AI_PENALTY_SEARCH,
  AI_PENALTY_FETCH,
  AI_PENALTY_METRICS,
  AI_PENALTY_ORDERS,
  AI_RETURNS_ORDERS,
  AI_RETURNS_ORDERS_FAILURE,
  AI_RETURNS_ORDERS_ACK_SUCCESS,
  AI_RETURNS_ORDERS_ACK_FAILURE,
  AI_PENALTY_EXCLUDE,
  AI_PENALTY_CHARGEBACK,
  AI_PENALTY_APPROVE_REJECT,
  AI_PENALTY_HISTORY,
  AI_SHIPNODE_DETAILS,
  AI_SHIPNODE_CREATE_UPDATE,
  AI_SHIPNODE_DEACTIVATE,
  AI_SHIPNODE_HISTORY,
  AI_SHIPNODE_APPROVE_REJECT,
  AI_PENALTY_BULK_UPDATE,
  AI_SUPPLIER_ROLE_REQUEST
} from './Telemetry.actionTypes'
import { getAppInsights } from '../../utils/TelemetryService'
import { getTelemetryProperties } from '../../utils/common'

/**
 * @description track update item changes will change order status Acknowledge or
 * Cancel on status changing failed or error
 * @param {object} {items, releaseNo, poNumber }
 * @returns {function(...[*]=)}
 */
export const aiUpdateItemDetailsChangesFailed = ({ items, releaseNo, poNumber }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_UPDATE_ITEM_DETAILS_CHANGES_FAILED,
    properties: {
      items,
      releaseNo,
      poNumber,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description track Cancel order units or complete order
 * @param {object}
 * @returns {function(...[*]=)}
 */
export const aiCanceledUnits = ({ requestObject }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_CANCELED_ORDER_UNITS,
    properties: {
      items: requestObject.items,
      releaseNo: requestObject.releaseNo,
      documentType: requestObject.requestObject,
      enterpriseCode: requestObject.enterpriseCode,
      poNumber: requestObject.poNumber,
      vendorId: requestObject.VENDOR_ID,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description track update shipment quantity
 * @param {object}
 * @returns {function(...[*]=)}
 */
export const aiUpdateItemShipmentQuantity = ({ release }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_UPDATE_ITEM_SHIPMENT_QUANTITY,
    properties: {
      release,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description track set shipment flag is prepaid / collect
 * @param {object}
 * @returns {function(...[*]=)}
 */
export const aiSetShipmentFlag = ({ shipmentType, fieldName, flags }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SET_SHIPMENT_FLAG,
    properties: {
      shipmentType,
      fieldName,
      flags,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description track ship items
 * @parm {object}
 * @returns {function(...[*]=)}
 */
export const aiShipItems = ({ results }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SHIPMENT_ITEMS,
    properties: {
      results,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track when user clicks on merchant LTM page to view history
 * @param {object}
 * @returns {function(...[*]=)}
 */
export const aiGetShippingLabel = ({ poNumber, trackingNumber, releaseNumber }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_GET_SHIPPING_LABEL,
    properties: {
      poNumber,
      trackingNumber,
      releaseNumber,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track network level flag
 * @param {boolean}
 * @returns {function(...[*]=)}
 */
export const aiFetchNetworkLevelFlag = ({ type }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_FETCH_NETWORK_LEVEL_FLAGS,
    properties: {
      type,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track Set network level flag
 * @param {boolean}
 * @returns {function(...[*]=)}
 */
export const aiSetNetworkLevelFlag = ({ type, IS_NETWORK_FLAG }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SET_NETWORK_LEVEL_FLAG,
    properties: {
      type,
      IS_NETWORK_FLAG: !IS_NETWORK_FLAG,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track ship node level flag
 * @param {boolean}
 * @returns {function(...[*]=)}
 */
export const aiGetShipNodeLevelFlag = ({ type, VENDOR_ID }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_GET_SHIP_NODE_LEVEL_FLAG,
    properties: {
      type,
      vendorId: VENDOR_ID,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track set ship  node level flag
 * @param {boolean}
 * @returns {function(...[*]=)}
 */
export const aiSetShipNodeLevelFlag = ({ type, VENDOR_ID, SHIP_NODE_ID, STATUS }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SET_SHIP_NODE_LEVEL_FLAG,
    properties: {
      type,
      vendorId: VENDOR_ID,
      shipNodeId: SHIP_NODE_ID,
      status: STATUS,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track landing on download reports page
 * @returns {function(...[*]=)}
 */
export const aiFetchDownloadReports = ({ type, response }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_FETCH_DOWNLOAD_REPORT,
    properties: {
      type,
      response,
      ...getTelemetryProperties(getState)
    }
  })
}

/*
 * @description Track set ship  node level flag
 * @param {boolean}
 * @returns {function(...[*]=)}
 */
export const aiOnBoardSupplier = ({ VENDOR_ID, STATUS }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_ONBOARD_SUPPLIER_REQUEST,
    status: STATUS,
    properties: {
      vendorId: VENDOR_ID,
      ...getTelemetryProperties(getState)
    }
  })
}

/*
 * @description add/remove supplier role features
 * @param {object}
 * @returns {function(...[*]=)}
 */
export const aiSupplierFeatures = ({VENDOR_ID, type, featureList}) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SUPPLIER_ROLE_REQUEST,
    properties: {
      vendorId: VENDOR_ID,
      roleType: type,
      featureList,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track search on download reports page
 * @returns {function(...[*]=)}
 */
export const aiSearchDownloadReports = ({ type, response }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SEARCH_DOWNLOAD_REPORT,
    properties: {
      type,
      response,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track download reports download action
 * @param {string} reportNumber AckId for download report
 * @param {string} actionTrigger Identifier to know the action was triggered via notification or table
 * @returns {function(...[*]=)}
 */
export const aiDownloadReports = ({ type, response, reportNumber, actionTrigger }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: actionTrigger === MODULE_TYPES.notification ? AI_DOWNLOAD_REPORT_NOTIFICATION : AI_DOWNLOAD_REPORT_TABLE,
    properties: {
      type,
      response,
      reportNumber,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track download reports retry action
 * @param {string} reportNumber AckId for download report
 * @param {string} actionTrigger Identifier to know the action was triggered via notification or table
 * @returns {function(...[*]=)}
 */
export const aiDownloadReportRetry = ({ type, response, reportNumber, actionTrigger }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name:
      actionTrigger === MODULE_TYPES.notification ? AI_DOWNLOAD_REPORT_RETRY_NOTIFICATION : AI_DOWNLOAD_REPORT_RETRY,
    properties: {
      type,
      response,
      reportNumber,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track smart inventory fetch with All
 * @returns {function(...[*]=)}
 */
export const aiSmartInventoryFetchAggregated = ({ type, response, filters, alertType }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_FETCH_SMART_INVENTORY_AGGREGATED,
    properties: {
      type,
      filters,
      response,
      alertType,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track smart inventory fetch with All
 * @returns {function(...[*]=)}
 */
export const aiSmartInventoryFetchItems = ({ type, response, filters, alertType }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_FETCH_SMART_INVENTORY_ITEM,
    properties: {
      type,
      filters,
      response,
      alertType,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track smart inventory search action for item
 * @returns {function(...[*]=)}
 */
export const aiSmartInventoryItemSearch = ({ type, response, searchQuery }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SMART_INVENTORY_ITEM_SEARCH,
    properties: {
      type,
      response,
      searchQuery,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track smart inventory search action for category
 * @returns {function(...[*]=)}
 */
export const aiSmartInventoryAggregatedSearch = ({ searchQuery }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SMART_INVENTORY_AGGREGATED_SEARCH,
    properties: {
      type: AI_SMART_INVENTORY_AGGREGATED_SEARCH,
      searchQuery,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track download smart inventory action
 * @returns {function(...[*]=)}
 */
export const aiSmartInventoryDownload = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SMART_INVENTORY_DOWNLOAD,
    properties: {
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track upload smart inventory action
 * @returns {function(...[*]=)}
 */
export const aiSmartInventoryUpload = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SMART_INVENTORY_UPLOAD,
    properties: {
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track upload smart inventory reset action
 * @returns {function(...[*]=)}
 */
export const aiSmartInventoryReset = ({ type, response }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SMART_INVENTORY_RESET,
    properties: {
      type,
      response,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track upload smart inventory reset action
 * @returns {function(...[*]=)}
 */
export const aiSmartInventoryRemoveFilter = ({ type, selectedTab }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SMART_INVENTORY_REMOVE_FILTER,
    properties: {
      type,
      selectedTab,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track upload smart inventory reset action
 * @returns {function(...[*]=)}
 */
export const aiSmartInventoryFetchSuppliers = ({ type, response }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SMART_INVENTORY_FETCH_SUPPLIERS,
    properties: {
      type,
      response,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track upload smart inventory reset action
 * @returns {function(...[*]=)}
 */
export const aiSmartInventoryFetchCategories = ({ type, response }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SMART_INVENTORY_FETCH_CATEGORIES,
    properties: {
      type,
      response,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track SI fetch division call
 * @returns {function(...[*]=)}
 */
export const aiSmartInventoryFetchDivisions = ({ type, response }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SMART_INVENTORY_FETCH_DIVISIONS,
    properties: {
      type,
      response,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track upload smart inventory reset action
 * @returns {function(...[*]=)}
 */
export const aiSmartInventoryTableAction = ({ type, action, response }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SMART_INVENTORY_ACTION,
    properties: {
      type,
      action,
      response,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track upload smart inventory past trends action
 * @returns {function(...[*]=)}
 */
export const aiSmartInventoryPastTrendsAction = ({ type, trendType, filters, response }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SMART_INVENTORY_PAST_TRENDS,
    properties: {
      type,
      trendType,
      filters,
      response,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track Penalty program search
 * @returns {function(...[*]=)}
 */
export const aiPenaltySearch = ({ searchQuery }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_PENALTY_SEARCH,
    properties: {
      type: AI_PENALTY_SEARCH,
      searchQuery,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track Penalty program fetch
 * @returns {function(...[*]=)}
 */
export const aiPenaltyFetch = ({ response }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_PENALTY_FETCH,
    properties: {
      type: AI_PENALTY_FETCH,
      ...getTelemetryProperties(getState),
      response
    }
  })
}

/**
 * @description Track Penalty metrics fetch
 * @returns {function(...[*]=)}
 */
export const aiPenaltyMetrics = ({ response }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_PENALTY_METRICS,
    properties: {
      type: AI_PENALTY_METRICS,
      ...getTelemetryProperties(getState),
      response
    }
  })
}

/**
 * @description Track Penalty table data fetch
 * @returns {function(...[*]=)}
 */
export const aiPenaltyOrdersFetch = ({ type, response, searchQuery = '' }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_PENALTY_ORDERS,
    properties: {
      type: AI_PENALTY_ORDERS,
      ...getTelemetryProperties(getState),
      response,
      searchQuery,
      penaltyType: type
    }
  })
}

/**
 * @description Track Returns table data fetch
 * @returns {function(...[*]=)}
 */
export const aiReturnOrdersFetch = ({ type, response }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_RETURNS_ORDERS,
    properties: {
      type,
      ...getTelemetryProperties(getState),
      response
    }
  })
}

/**
 * @description Track Returns table data fetch Failure
 * @returns {function(...[*]=)}
 */
export const aiReturnOrdersFailure = ({ errorMessage }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_RETURNS_ORDERS_FAILURE,
    properties: {
      type: AI_RETURNS_ORDERS_FAILURE,
      ...getTelemetryProperties(getState),
      errorMessage
    }
  })
}

/**
 * @description Track Pending Returns Acknowledge Success
 * @returns {function(...[*]=)}
 */
export const aiReturnOrdersAckSuccess = ({ type, response }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_RETURNS_ORDERS_ACK_SUCCESS,
    properties: {
      type,
      ...getTelemetryProperties(getState),
      response
    }
  })
}

/**
 * @description Track Pending Returns Acknowledge Failure
 * @returns {function(...[*]=)}
 */
export const aiReturnOrdersAckFailure = ({ errorMessage }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_RETURNS_ORDERS_ACK_FAILURE,
    properties: {
      type: AI_RETURNS_ORDERS_ACK_FAILURE,
      ...getTelemetryProperties(getState),
      errorMessage
    }
  })
}

/**
 * @description Track Penalty program exclude
 * @returns {function(...[*]=)}
 */
export const aiPenaltyExclude = ({ response }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_PENALTY_EXCLUDE,
    properties: {
      type: AI_PENALTY_EXCLUDE,
      ...getTelemetryProperties(getState),
      response
    }
  })
}

/**
 * @description Track Penalty charge back
 * @returns {function(...[*]=)}
 */
export const aiPenaltyChargeback = ({ response }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_PENALTY_CHARGEBACK,
    properties: {
      type: AI_PENALTY_CHARGEBACK,
      ...getTelemetryProperties(getState),
      response
    }
  })
}

/**
 * @description Track Penalty approve/reject
 * @returns {function(...[*]=)}
 */
export const aiPenaltyApproveReject = ({ response }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_PENALTY_APPROVE_REJECT,
    properties: {
      type: AI_PENALTY_APPROVE_REJECT,
      ...getTelemetryProperties(getState),
      response
    }
  })
}

/**
 * @description Track Penalty program fetch history
 * @returns {function(...[*]=)}
 */
export const aiPenaltyHistory = ({ isCompleted, response }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_PENALTY_HISTORY,
    properties: {
      type: AI_PENALTY_HISTORY,
      isCompleted,
      response,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track Shipnode details api
 * @returns {function(...[*]=)}
 */
export const aiShipnodeDetails = ({ vendorId, shipnode, response, type }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SHIPNODE_DETAILS,
    properties: {
      type,
      vendorId,
      shipnode,
      response,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track Shipnode create/update api
 * @returns {function(...[*]=)}
 */
export const aiCreateUpdateShipnode = ({ vendorId, shipnode, response, type }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SHIPNODE_CREATE_UPDATE,
    properties: {
      type,
      vendorId,
      shipnode,
      response,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track Shipnode deactivate api
 * @returns {function(...[*]=)}
 */
export const aiDeactivateShipnode = ({ vendorId, shipnode, response, type }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SHIPNODE_DEACTIVATE,
    properties: {
      type,
      vendorId,
      shipnode,
      response,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track Shipnode pending/completed api
 * @returns {function(...[*]=)}
 */
export const aiShipnodeHistory = ({ vendorId, shipnode, response, type }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SHIPNODE_HISTORY,
    properties: {
      type,
      vendorId,
      shipnode,
      response,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track Shipnode approve/reject api
 * @returns {function(...[*]=)}
 */
 export const aiShipnodeApproveReject = ({ vendorId, task, response, type }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SHIPNODE_APPROVE_REJECT,
    properties: {
      type,
      vendorId,
      task,
      response,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @desc When user clicks on Bulk Update in Penalty page
 * @returns {function(...[*]=)}
 */
 export const aiPenaltyBulkUpdate = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_PENALTY_BULK_UPDATE,
    properties: {
      ...getTelemetryProperties(getState),
      totalRows: getState().PenaltyProgram.totalRows
    }
  })
}
