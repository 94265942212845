import { cloneDeep, isEmpty } from 'lodash'
import {
  CLEAR_ERROR_DOWNLOAD_FILES,
  DOWNLOAD_ERROR_FILE_FAILURE,
  DOWNLOAD_ERROR_FILE_REQUEST,
  DOWNLOAD_ERROR_FILE_SUCCESS,
  INITIATE_ERROR_FILE_DOWNLOAD_FAILURE,
  INITIATE_ERROR_FILE_DOWNLOAD_REQUEST,
  INITIATE_ERROR_FILE_DOWNLOAD_SUCCESS,
  REMOVE_ERROR_DOWNLOAD_FILE,
  SET_FEED_ROW_COUNT
} from './FeedStatus.types'

export const INITIAL_STATE = {
  totalRows: null,
  downloads: []
}

export default (state = cloneDeep(INITIAL_STATE), action) => {
  switch (action.type) {
    case SET_FEED_ROW_COUNT:
      return {
        ...state,
        totalRows: action.payload.count
      }

    case INITIATE_ERROR_FILE_DOWNLOAD_REQUEST:
      // eslint-disable-next-line no-case-declarations
      const existingRow = state.downloads.find(feed => feed.ackId === action.payload.ackId)
      if (!isEmpty(existingRow)) {
        existingRow.downloadFileExist = 'INITIATED'
      }

      return {
        ...state,
        downloads: existingRow
          ? [...state.downloads]
          : [
              ...state.downloads,
              {
                ackId: action.payload.ackId,
                feedType: action.payload.feedType,
                shipNodeId: action.payload.shipNodeId,
                downloadFileExist: 'INITIATED',
                downloaded: false
              }
            ]
      }

    case INITIATE_ERROR_FILE_DOWNLOAD_SUCCESS:
      return {
        ...state,
        downloads: [
          ...state.downloads.map(feed => {
            if (feed.ackId === action.payload.ackId) {
              return { ...feed, ...action.response }
            }

            return { ...feed }
          })
        ]
      }

    case INITIATE_ERROR_FILE_DOWNLOAD_FAILURE:
      return {
        ...state,
        downloads: [
          ...state.downloads.map(feed => {
            if (feed.ackId === action.payload.ackId) {
              return { ...feed, downloadFileExist: 'FAILED' }
            }

            return { ...feed }
          })
        ]
      }

    case DOWNLOAD_ERROR_FILE_REQUEST:
      return { ...state }

    case DOWNLOAD_ERROR_FILE_SUCCESS:
      return {
        ...state,
        downloads: [
          ...state.downloads.map(feed => {
            if (feed.ackId === action.payload.ackId) {
              return { ...feed, downloaded: true }
            }

            return { ...feed }
          })
        ]
      }

    case DOWNLOAD_ERROR_FILE_FAILURE:
      return {
        ...state,
        downloads: [
          ...state.downloads.map(feed => {
            if (feed.ackId === action.payload.ackId) {
              return { ...feed, downloaded: false, downloadFileExist: 'FAILED' }
            }

            return { ...feed }
          })
        ]
      }

    case REMOVE_ERROR_DOWNLOAD_FILE:
      return {
        ...state,
        downloads: [...state.downloads.filter(feed => feed.ackId !== action.payload.ackId)]
      }

    case CLEAR_ERROR_DOWNLOAD_FILES:
      return {
        ...state,
        downloads: [...INITIAL_STATE.downloads]
      }

    default:
      return { ...state }
  }
}
