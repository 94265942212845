export const INVENTORY_ITEM_LISTS_REQUEST = 'SC.INVENTORY_ITEM_LISTS_REQUEST'
export const INVENTORY_ITEM_LISTS_SUCCESS = 'SC.INVENTORY_ITEM_LISTS_SUCCESS'
export const INVENTORY_ITEM_LISTS_FAILURE = 'SC.INVENTORY_ITEM_LISTS_FAILURE'
export const INVENTORY_ITEM_LISTS_DOWNLOAD_REQUEST = 'SC.INVENTORY_ITEM_LISTS_DOWNLOAD_REQUEST'
export const INVENTORY_ITEM_LISTS_DOWNLOAD_SUCCESS = 'SC.INVENTORY_ITEM_LISTS_DOWNLOAD_SUCCESS'
export const INVENTORY_ITEM_LISTS_DOWNLOAD_FAILURE = 'SC.INVENTORY_ITEM_LISTS_DOWNLOAD_FAILURE'
export const INVENTORY_ITEM_LISTS_UPLOAD_REQUEST = 'SC.INVENTORY_ITEM_LISTS_UPLOAD_REQUEST'
export const INVENTORY_ITEM_LISTS_UPLOAD_SUCCESS = 'SC.INVENTORY_ITEM_LISTS_UPLOAD_SUCCESS'
export const INVENTORY_ITEM_LISTS_UPLOAD_FAILURE = 'SC.INVENTORY_ITEM_LISTS_UPLOAD_FAILURE'
export const INVENTORY_ITEM_SEARCH_REQUEST = 'SC.INVENTORY_ITEM_SEARCH_REQUEST'
export const INVENTORY_ITEM_SEARCH_SUCCESS = 'SC.INVENTORY_ITEM_SEARCH_SUCCESS'
export const INVENTORY_ITEM_SEARCH_FAILURE = 'SC.INVENTORY_ITEM_SEARCH_FAILURE'
export const INVENTORY_ITEM_UPDATE_REQUEST = 'SC.INVENTORY_ITEM_UPDATE_REQUEST'
export const INVENTORY_ITEM_UPDATE_SUCCESS = 'SC.INVENTORY_ITEM_UPDATE_SUCCESS'
export const INVENTORY_ITEM_UPDATE_FAILURE = 'SC.INVENTORY_ITEM_UPDATE_FAILURE'
export const INVENTORY_CLEAR_ERROR = 'SC.INVENTORY_CLEAR_ERROR'
export const INVENTORY_ITEM_LISTS_FILE_DOWNLOAD_SUCCESS = 'SC.INVENTORY_ITEM_LISTS_FILE_DOWNLOAD_SUCCESS'
export const INVENTORY_ITEM_LISTS_FILE_DOWNLOAD_FAILURE = 'SC.INVENTORY_ITEM_LISTS_FILE_DOWNLOAD_FAILURE'
export const INVENTORY_RESET_DATA = 'SC.INVENTORY_RESET_DATA'
export const INVENTORY_ITEM_LISTS_ON_PAGE_SUCCESS = 'SC.INVENTORY_ITEM_LISTS_ON_PAGE_SUCCESS'
export const INVENTORY_ITEM_LISTS_ON_PAGE_FAILURE = 'SC.INVENTORY_ITEM_LISTS_ON_PAGE_FAILURE'
export const DOWNLOAD_FILE_RETRY_REQUEST = 'SC.INVENTORY_DOWNLOAD_FILE_RETRY_REQUEST'

// ===== NEW ACTIONS
export const UPDATE_INVENTORY_QUANTITY_REQUEST = 'UPDATE_INVENTORY_QUANTITY_REQUEST'
export const UPDATE_INVENTORY_QUANTITY_SUCCESS = 'UPDATE_INVENTORY_QUANTITY_SUCCESS'
export const UPDATE_INVENTORY_QUANTITY_FAILURE = 'UPDATE_INVENTORY_QUANTITY_FAILURE'
export const UPDATE_INVENTORY_QUANTITY_REDUX = 'UPDATE_INVENTORY_QUANTITY_REDUX'

export const SET_INVENTORY_ITEM_COUNT = 'SET_INVENTORY_ITEM_COUNT'
export const RESET_UPDATED_ITEM_LIST = 'RESET_UPDATED_ITEM_LIST'

export const SEARCH_INVENTORY_ITEM_REQUEST = 'SEARCH_INVENTORY_ITEM_REQUEST'
export const SEARCH_INVENTORY_ITEM_SUCCESS = 'SEARCH_INVENTORY_ITEM_SUCCESS'
export const SEARCH_INVENTORY_ITEM_FAILURE = 'SEARCH_INVENTORY_ITEM_FAILURE'
export const SET_INVENTORY_TAB = 'SET_INVENTORY_TAB'


/****************INBOUND ACTIONS ******************/
export const UPDATE_INVENTORY_INBOUND_REQUEST = 'UPDATE_INVENTORY_INBOUND_REQUEST'
export const UPDATE_INVENTORY_INBOUND_SUCCESS = 'UPDATE_INVENTORY_INBOUND_SUCCESS'
export const UPDATE_INVENTORY_INBOUND_FAILURE = 'UPDATE_INVENTORY_INBOUND_FAILURE'


export const SET_WEEK_INBOUND_QTY = 'SET_WEEK_INBOUND_QTY'
export const RESET_WEEK_INBOUND_QTY = 'RESET_WEEK_INBOUND_QTY'
