import { cloneDeep, filter, map, find, pick, get, isEmpty } from 'lodash'
import config from '../../config'
// eslint-disable-next-line import/no-cycle
import { showToastMessage } from '../../reduxSetup/commonActions/AppConfig'
import {
  FETCH_PAGINATED_TABLE_DATA_FAILURE,
  FETCH_PAGINATED_TABLE_DATA_REQUEST,
  FETCH_PAGINATED_TABLE_DATA_SUCCESS
} from '../../reduxSetup/commonActions/SSHPaginatedTable.actionTypes'
import { CALL_API } from '../../reduxSetup/middleware/API'
import { getVendorIdFromState } from '../../utils/common'
import {
  APPROVE_REJECT_LEAD_TIME_REQUEST,
  APPROVE_REJECT_LEAD_TIME_SUCCESS,
  CANCELLED_LEAD_TIME_FAILURE,
  CANCELLED_LEAD_TIME_REQUEST,
  CANCELLED_LEAD_TIME_SUCCESS,
  CLEAR_CURRENT_LEAD_TIME,
  FETCH_CURRENT_LEAD_TIME_FAILURE,
  FETCH_CURRENT_LEAD_TIME_REQUEST,
  FETCH_CURRENT_LEAD_TIME_SUCCESS,
  FETCH_REQUEST_LEAD_TIME_FAILURE,
  FETCH_REQUEST_LEAD_TIME_REQUEST,
  FETCH_REQUEST_LEAD_TIME_SUCCESS,
  MARK_TASK_AS_CANCELED_STORE,
  MARK_TASK_AS_REJECTED_STORE,
  RESET_LEAD_TIME_HISTORY,
  SAVE_LEAD_TIME_FAILURE,
  SAVE_LEAD_TIME_REQUEST,
  SAVE_LEAD_TIME_SUCCESS,
  TOGGLE_PENDING_BOX,
  TOGGLE_REQUEST_BOX,
  SET_LEAD_TIME_COUNT,
  SET_REPORT_DATE,
  SET_LOADING_FLAG
} from './LeadTime.actionTypes'
import { clearShipnodes, getShipNodeDetails } from '../SupplierManagement/SupplierOnBoardAction'
import {
  aiGetRequestHistory,
  aiSaveLeadTime,
  aiShowPendingBox,
  aiCancelPendingRequest,
  aiMerchantApprovalReject,
  aiViewPending,
  aiViewHistory,
  aiNewRequestPopup
} from '../../reduxSetup/commonActions/TelemetryActions'

const leadTimeEndPoint = config.get('newLeadTime')

/**
 * @description toggle request box on click show / hide modal bix
 * @param {string} shipNodeId
 * @param {string} shipNodeName
 * @return {function(any, any): (any)} action
 */
export const toggleRequestBox = ({ shipNodeId, shipNodeName }) => (dispatch, getState) => {
  const pending = filter(getState().LeadTime.requestHistory, { shipNodeId, status: 'pending' }).length > 0

  if (pending) {
    dispatch(aiShowPendingBox({ shipNodeId, shipNodeName }))
    return dispatch({
      type: TOGGLE_PENDING_BOX,
      payload: {
        shipNodeId,
        shipNodeName
      }
    })
  }

  dispatch(aiNewRequestPopup({ shipNodeId, shipNodeName }))
  return dispatch({
    type: TOGGLE_REQUEST_BOX,
    payload: {
      shipNodeId,
      shipNodeName
    }
  })
}

/**
 * @description toggle request box on click show / hide modal bix
 * @params {}
 * @return redux action
 */
export const togglePendingBox = () => dispatch => {
  return dispatch({
    type: TOGGLE_PENDING_BOX,
    payload: {}
  })
}

/**
 * @description fetch current & request history lead time
 * @params {userid}
 * @returns {function(*, *=): (*)} current lead time
 */
export const fetchLeadTime = () => async (dispatch, getState) => {

  const { currentLeadTime } = getState().LeadTime

  if (isEmpty(currentLeadTime)) {
    const SUPPLIER_ID = getVendorIdFromState(getState)
    let { shipnodesDetails } = await getState().SupplierOnBoard

    if (isEmpty(shipnodesDetails) || shipnodesDetails[0]?.shipNodeId?.split('_')[0] != SUPPLIER_ID) {
      await dispatch(clearShipnodes())
      await dispatch(getShipNodeDetails())
      shipnodesDetails = await getState().SupplierOnBoard.shipnodesDetails
    }

    const CAPACITY_NODES = map(shipnodesDetails, (item) => pick(item, ['capacity', 'shipNodeId', 'shipNodeName']))

    // Check if SHIP_NODE_ID is an array, and if so, join the values with commas
    if (!isEmpty(CAPACITY_NODES) && Array.isArray(CAPACITY_NODES)) {
      const ONLY_LEAD_TIME_NODES = filter(CAPACITY_NODES, z => !z.capacity?.isEnabled)

      let promises = ONLY_LEAD_TIME_NODES.map(node => {
        return Promise.all([
          dispatch(fetchCurrentLeadTime(SUPPLIER_ID, node.shipNodeId, node.shipNodeName)),
          dispatch(getRequestHistory(SUPPLIER_ID, node.shipNodeId))
        ])
      })

      // Return a promise that resolves when all the promises in the array have resolved
      return Promise.all(promises)
    }
  }
  return Promise.resolve()
}

/**
 * @description fetch current lead time
 * @param {String} VENDOR_ID
 * @param {Array} SHIP_NODE
 */
export const fetchCurrentLeadTime = (VENDOR_ID, SHIP_NODE_ID, SHIP_NODE_NAME) => dispatch => {
  return dispatch({
    [CALL_API]: {
      types: [FETCH_CURRENT_LEAD_TIME_REQUEST, FETCH_CURRENT_LEAD_TIME_SUCCESS, FETCH_CURRENT_LEAD_TIME_FAILURE],
      endpoint: `${config.get('currentLeadTime')}/${VENDOR_ID}/shipnode/${SHIP_NODE_ID}/leadtime`,
      method: 'GET',
      payload: { shipNodeName: SHIP_NODE_NAME }
    }
  })
}

/**
 * @description get request history
 * @param VENDOR_ID
 * @param SHIP_NODE_ID
 * @return {function(any): any}
 */
export const getRequestHistory = (VENDOR_ID, SHIP_NODE_ID) => dispatch => {
  return dispatch({
    [CALL_API]: {
      types: [FETCH_REQUEST_LEAD_TIME_REQUEST, FETCH_REQUEST_LEAD_TIME_SUCCESS, FETCH_REQUEST_LEAD_TIME_FAILURE],
      endpoint: `${leadTimeEndPoint}/leadTime/supplier/${VENDOR_ID}/shipnode/${SHIP_NODE_ID}`,
      method: 'GET',
      payload: { shipNodeId: SHIP_NODE_ID }
    }
  }).then(({ type }) => {
    if (type === FETCH_REQUEST_LEAD_TIME_SUCCESS) {
      dispatch(aiGetRequestHistory({ SHIP_NODE_ID }))
    }
  })
}

/**
 * @description save new lead time on ship node basis
 * @params {new lead time}
 * @params {reason}
 * @returns {object} redux action
 */
export const saveNewLeadTime = ({ newLeadTime, reason }) => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const EMAIL = getState().UserInfo.userDetails.emailId
  const LOGIN_ID = getState().UserInfo.userDetails.loginId
  const SHIP_NODE_ID = getState().LeadTime.selectedShipNode
  const SHIP_NODE_NAME = getState().LeadTime.selectedShipNodeName
  const LATEST_LEAD_TIME = getState().LeadTime.currentLeadTime
  const CURRENT_LEAD_TIME = find(LATEST_LEAD_TIME, { shipNodeId: SHIP_NODE_ID }).currentLeadTime
  const VENDOR_NAME = getState().AppConfig.vendor.selectedVendor.displayName

  const reqObj = {
    supplierId: String(VENDOR_ID),
    shipNodeId: SHIP_NODE_ID,
    newLeadTime,
    currentLeadTime: CURRENT_LEAD_TIME,
    requestedBy: EMAIL || LOGIN_ID,
    supplierReason: reason,
    supplierName: VENDOR_NAME,
    shipNodeName: SHIP_NODE_NAME,
    status: 'pending'
  }

  return dispatch({
    [CALL_API]: {
      types: [SAVE_LEAD_TIME_REQUEST, SAVE_LEAD_TIME_SUCCESS, SAVE_LEAD_TIME_FAILURE],
      endpoint: `${leadTimeEndPoint}/leadTime/${VENDOR_ID}`,
      method: 'POST',
      data: reqObj,
      payload: reqObj
    }
  }).then(response => {
    if (get(response, 'response.payload.message') === 'success') {
      dispatch(resetLeadTimeHistory({ SHIP_NODE_ID })).then(() => {
        dispatch(getRequestHistory(VENDOR_ID, SHIP_NODE_ID))
        dispatch(fetchCurrentLeadTime(VENDOR_ID, SHIP_NODE_ID))
      })
      dispatch(aiSaveLeadTime({ oldLeadTime: CURRENT_LEAD_TIME, newLeadTime, reason })) // telemetry
      return dispatch(showToastMessage({ message: 'Request for lead time change submitted successfully!' }))
    }
    return dispatch(
      showToastMessage({ message: 'Unable to place request for lead time change. Please try again after some time.' })
    )
  })
}

export const cancelledTask = () => (dispatch, getState) => {
  const EMAIL = getState().UserInfo.userDetails.emailId
  const LOGIN_ID = getState().UserInfo.userDetails.loginId
  const SHIP_NODE_ID = getState().LeadTime.selectedShipNode
  const REQUEST_HISTORY = getState().LeadTime.requestHistory
  const VENDOR_ID = getVendorIdFromState(getState)

  const task = filter(REQUEST_HISTORY, { shipNodeId: SHIP_NODE_ID, status: 'pending' })[0]

  const reqObj = {
    taskId: task.taskId,
    shipNodeId: task.shipNodeId,
    supplierId: task.supplierId,
    merchantReason: null,
    merchantEmail: EMAIL || LOGIN_ID,
    status: 'canceled'
  }

  return dispatch({
    [CALL_API]: {
      types: [CANCELLED_LEAD_TIME_REQUEST, CANCELLED_LEAD_TIME_SUCCESS, CANCELLED_LEAD_TIME_FAILURE],
      endpoint: `${leadTimeEndPoint}/leadTime/supplier/${VENDOR_ID}`,
      method: 'POST',
      data: reqObj
    }
  }).then(response => {
    if (response.type === CANCELLED_LEAD_TIME_SUCCESS && get(response, 'response.payload.message') === 'success') {
      dispatch(
        aiCancelPendingRequest({ taskId: task.taskId, shipNodeId: task.shipNodeId, supplierId: task.supplierId })
      )
      return dispatch(markTaskAsCancelled(SHIP_NODE_ID))
    }
    dispatch(resetLeadTimeHistory({ SHIP_NODE_ID })).then(() => {
      dispatch(getRequestHistory(VENDOR_ID, SHIP_NODE_ID))
      dispatch(fetchCurrentLeadTime(VENDOR_ID, SHIP_NODE_ID))
    })
    return dispatch(showToastMessage({ message: 'Error occurred while trying to canceled lead time request' }))
  })
}

export const markTaskAsCancelled = SHIP_NODE_ID => dispatch => {
  return Promise.resolve(
    dispatch({
      type: MARK_TASK_AS_CANCELED_STORE,
      shipNodeId: SHIP_NODE_ID
    })
  )
}

export const resetCurrentLeadTime = () => dispatch => {
  return Promise.resolve(
    dispatch({
      type: CLEAR_CURRENT_LEAD_TIME
    })
  )
}

export const resetLeadTimeHistory = ({ SHIP_NODE_ID }) => dispatch => {
  return Promise.resolve(
    dispatch({
      type: RESET_LEAD_TIME_HISTORY,
      shipNode: SHIP_NODE_ID
    })
  )
}

/*
 * ======== Lead time admin screen actions =======
 */
export const fetchLeadTimeRequestsAdmin = () => (dispatch, getState) => {
  const { isCompletedView } = getState().Approvals
  const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
  const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo

  const leadTimeState = isCompletedView ? '?completed=true&' : '?'

  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_PAGINATED_TABLE_DATA_REQUEST,
        FETCH_PAGINATED_TABLE_DATA_SUCCESS,
        FETCH_PAGINATED_TABLE_DATA_FAILURE
      ],
      endpoint: `${leadTimeEndPoint}/leadtime${leadTimeState}pageNumber=${PAGE_NO + 1}&pageSize=${PAGE_SIZE}`,
      method: 'GET',
      payload: { pathName: getState().router.location.pathname }
    }
  }).then(({ type, response }) => {
    if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS) {
      const { payload } = response
      dispatch(setLeadTimeCount({ count: payload?.totalCount || 0 }))
      if (!isCompletedView) {
        dispatch(aiViewPending())
      } else {
        dispatch(aiViewHistory())
      }
    }
  })
}

/**
 * @desc Action to approve or reject Lead Time request
 * @param {Object} task
 * @param {boolean} isApproved
 * @param {String} reason
 * @returns {function(any, any): any}
 */
export const approveRejectLeadTimeRequest = ({ task, isApproved, reason }) => (dispatch, getState) => {
  const requestParams = {
    taskId: task.taskId,
    shipNodeId: task.shipNodeId,
    supplierId: task.supplierId,
    merchantReason: reason,
    merchantEmail: getState().UserInfo.userDetails.emailId,
    status: isApproved ? 'approved' : 'rejected'
  }

  return dispatch({
    [CALL_API]: {
      types: [APPROVE_REJECT_LEAD_TIME_REQUEST, APPROVE_REJECT_LEAD_TIME_SUCCESS, APPROVE_REJECT_LEAD_TIME_SUCCESS],
      endpoint: `${leadTimeEndPoint}/leadTime/action/complete`,
      method: 'POST',
      data: requestParams,
      payload: { task, isApproved, reason }
    }
  }).then(({ type, response }) => {
    const API_SUCCESS = type === APPROVE_REJECT_LEAD_TIME_SUCCESS
    const RESPONSE_SUCCESS = get(response, 'status', null) === 'OK'
    const MESSAGE_SUCCESS = get(response, 'payload.message', null) === 'success'
    if (API_SUCCESS && RESPONSE_SUCCESS && MESSAGE_SUCCESS) {
      if (!isApproved) dispatch(markTaskAsRejected({ updatedTask: task }))
      // make success AppInsights call here
      dispatch(aiMerchantApprovalReject({ task, isApproved: isApproved ? 'approved' : 'rejected', reason }))
      return Promise.resolve()
    }
    const error =
      get(response, 'payload.error.errorMessage', null) || get(response, 'data.payload.error.errorMessage', null)
    // make failed AppInsights call here
    throw new Error(error || 'Error occurred while trying to update lead time request')
  })
}

export const markTaskAsRejected = ({ updatedTask }) => (dispatch, getState) => {
  let tasks = cloneDeep(getState().SSHPaginatedTableReducer.rows)
  tasks = tasks.map(task => {
    if (task.taskId === updatedTask.taskId) return { ...updatedTask, status: 'rejected' }
    return task
  })
  return Promise.resolve(
    dispatch({
      type: MARK_TASK_AS_REJECTED_STORE,
      payload: { tasks }
    })
  )
}

export const setLeadTimeCount = ({ count }) => dispatch => {
  return Promise.resolve(
    dispatch({
      type: SET_LEAD_TIME_COUNT,
      payload: {
        count
      }
    })
  )
}

export const setLeadTimeReportDate = ({ dates }) => dispatch => {
  return Promise.resolve(
    dispatch({
      type: SET_REPORT_DATE,
      payload: { dates }
    })
  )
}

export const leadTimeOrCapacityLoading = (flag = false) => (dispatch) => {
  return Promise.resolve(dispatch({
    type: SET_LOADING_FLAG,
    payload: flag
  }))
}
