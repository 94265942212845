import { cloneDeep, get } from 'lodash'
import {
  FETCH_NETWORK_MANTIS_LEAD_TIME_REQUEST,
  FETCH_NETWORK_MANTIS_LEAD_TIME_SUCCESS,
  FETCH_NETWORK_MANTIS_LEAD_TIME_FAILURE,
  SET_NETWORK_MANTIS_LEAD_TIME_REQUEST,
  SET_NETWORK_MANTIS_LEAD_TIME_SUCCESS,
  SET_NETWORK_MANTIS_LEAD_TIME_FAILURE,
  FETCH_SHIP_NODE_MANTIS_LEAD_TIME_REQUEST,
  FETCH_SHIP_NODE_MANTIS_LEAD_TIME_SUCCESS,
  FETCH_SHIP_NODE_MANTIS_LEAD_TIME_FAILURE,
  SET_SHIP_NODE_MANTIS_LEAD_TIME_REQUEST,
  SET_SHIP_NODE_MANTIS_LEAD_TIME_SUCCESS,
  SET_SHIP_NODE_MANTIS_LEAD_TIME_FAILURE,
  CLEAR_SHIP_NODE
} from './MantisLeadTime.actionTypes'
import { parseMantisShipNode } from '../../utils/common'

export const INITIAL_STATE = {
  isFetching: false,
  isNetworkFlag: false,
  isSuplierFlag: false,
  emptyShipNode: false,
  shipNodes: []
}

export default (state = cloneDeep(INITIAL_STATE), action) => {
  switch (action.type) {
    case FETCH_NETWORK_MANTIS_LEAD_TIME_SUCCESS: {
      const { response } = action
      return {
        ...state,
        isNetworkFlag: response.payload?.enabled,
        isFetching: false
      }
    }
    case FETCH_NETWORK_MANTIS_LEAD_TIME_FAILURE:
    case SET_NETWORK_MANTIS_LEAD_TIME_FAILURE:
    case SET_SHIP_NODE_MANTIS_LEAD_TIME_FAILURE: {
      return {
        ...state,
        isFetching: false
      }
    }
    case FETCH_NETWORK_MANTIS_LEAD_TIME_REQUEST:
    case SET_NETWORK_MANTIS_LEAD_TIME_REQUEST:
    case SET_SHIP_NODE_MANTIS_LEAD_TIME_REQUEST: {
      return {
        ...state,
        isFetching: true
      }
    }
    case SET_NETWORK_MANTIS_LEAD_TIME_SUCCESS: {
      const { response } = action
      const message = get(response, 'payload.message', null)
      if (message && message.toLowerCase() === 'success') {
        return {
          ...state,
          isFetching: false,
          isNetworkFlag: !state.isNetworkFlag
        }
      }
      return {
        ...state,
        isFetching: false
      }
    }
    case FETCH_SHIP_NODE_MANTIS_LEAD_TIME_REQUEST: {
      return {
        ...state,
        isFetching: true,
        emptyShipNode: false
      }
    }
    case FETCH_SHIP_NODE_MANTIS_LEAD_TIME_SUCCESS: {
      const { response } = action
      // eslint-disable-next-line camelcase
      const shipNodes = parseMantisShipNode(response.payload?.shipNode_feature)
      return {
        ...state,
        isFetching: false,
        shipNodes: [...shipNodes],
        emptyShipNode: shipNodes.length === 0
      }
    }
    case FETCH_SHIP_NODE_MANTIS_LEAD_TIME_FAILURE:
    case CLEAR_SHIP_NODE: {
      return {
        ...state,
        isFetching: false,
        emptyShipNode: false,
        shipNodes: []
      }
    }
    case SET_SHIP_NODE_MANTIS_LEAD_TIME_SUCCESS: {
      const { shipNodeId } = action.payload
      const { shipNodes } = state
      const node = shipNodes.find(node => node.shipNodeId === shipNodeId)
      node.enabled = !node.enabled
      return {
        ...state,
        isFetching: false,
        shipNodes: [...shipNodes]
      }
    }
    default:
      return { ...state }
  }
}
