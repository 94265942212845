import moment from 'moment'

const formatMonthLabel = (value) => {
    return moment().add(value, 'months').format('MMMM')
}

export const COLUMNS = [{
    name: 'Cases per pallet', value: 'casesPerPallet'
}, {
    name: 'Case minimum order', value: 'caseMinimumOrder'
}, {
    name: 'Frontline, Accumulative or Per club invoice', value: 'frontlineAccumulativeOrPerClubInvoice'
}, {
    name: 'Family number', value: 'familyNo'
}, {
    name: moment().format('MMMM'), value: 'month0'
}, {
    name: formatMonthLabel(1), value: 'month1'
}, {
    name: formatMonthLabel(2), value: 'month2'
}, {
    name: formatMonthLabel(3), value: 'month3'
}]
