import React, { useEffect, useState } from 'react'
import { ListItem, ListItemIcon, ListItemText, Box } from '@mui/material'
import { withStyles } from '@mui/styles'
import { isEmpty, find } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import NavItem from './NavItem'
import clsx from 'clsx'
import { getSourceOptions } from './commonMethods'

const styles = (theme) => ({
  root: {
    background: theme.palette.primary.downloadLabelText, color: theme.palette.primary.white,
    padding: `${theme.spacing(1)} ${theme.spacing(1.6)}`
  },
  active: {
    background: theme.palette.primary.cobalt,
    borderLeft: `4px solid ${theme.palette.primary.mediumCyan}`,
    color: theme.palette.primary.white
  },
  hoverActive: {
    background: theme.palette.primary.cobalt,
    borderRight: `4px solid ${theme.palette.primary.mediumCyan}`,
    color: theme.palette.primary.white
  },
  listItemIcon:{
    'min-width':theme.spacing(4.6)
  }
})

export const NavListItem = ({
  access,
  fetchingRoles,
  item,
  changePage,
  isVendorSelected,
  isAdmin,
  classes,
  pathName,
  ...restProps
}) => {

  const { nav, name, sourceType } = item

  const sourceOptions = getSourceOptions(restProps.selectedSourceType)

  const [expanded, setExpand] = useState(false)

  useEffect(() => {//colapse all when tab is closed
    if (!restProps.isDrawerOpen) {
      setExpand(false)
      if (item.url === pathName) {
        restProps.changeHoverList(name)
      }
    }
    if (restProps.isDrawerOpen && !isEmpty(nav)) {
      setExpand(!isEmpty(find(nav, { url: pathName })))
    }
  }, [restProps.isDrawerOpen, pathName])

  /**
   * @description handle route on click of modules main items
   */
  function handleClick() {
    restProps.changeHoverList(name)
    return changePage(item.url)
  }

  /**
   * @description handle route on click on submenu items
   * @param {*} link
   * @returns
   */
  const handleSubMenu = (link) => {
    if (restProps.isDrawerOpen) {
      restProps.changeHoverList(name)
      return changePage(link)
    }
  }

  /**
   * @description toggle menu vertically
   * @param {*} e
   * @returns boolean
   */
  const toggleMenu = async (e) => {
    e.preventDefault()
    if (restProps.isDrawerOpen) {
      return setExpand(expanded => !expanded)
    }
  }

  /**
   * @description show hover menu in closed state
   * @param {*} e
   * @param {*} name
   * @returns  event
   */
  const showHoverMenu = (e, name) => {
    if (!restProps.isDrawerOpen) {
      return restProps.handlePopoverOpen(e, name)
    }
    return null
  }

  /**
   * @description hover active
   * @returns objects
   */
  const hoverActive = () => {
    if (restProps.isDrawerOpen) {
      const nonExpanded = item.url === pathName ? classes.active : ''
      return clsx(classes.root, expanded ? classes.active : nonExpanded)
    }
    if (String(restProps?.hoverList?.name).toLowerCase() === String(name).toLowerCase()) {
      return clsx(classes.root, classes.hoverActive)
    }
    return classes.root
  }

  return (
    <>
      {
        (sourceType == 'both' || sourceType === sourceOptions) &&
        <Box>
          <ListItem
            aria-owns={restProps.open ? 'ssh-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={(e) => showHoverMenu(e, name)}
            className={hoverActive()}
            button
            disableGutters
            onClick={!isEmpty(nav) ? toggleMenu : handleClick}
            data-testid="listItem">
            <ListItemIcon  className={classes.listItemIcon}>{<item.selectedIcon />}</ListItemIcon>
            <ListItemText
              primary={name}
              data-testid="itemText"
            />
            {!isEmpty(nav) && <span>{expanded ? <item.lessIcon /> : <item.moreIcon />}</span>}
          </ListItem>
        </Box>
      }
      {expanded ? <NavItem
        nav={nav}
        pathName={pathName}
        handleSubMenu={handleSubMenu}
        isAdmin={isAdmin}
        allowPath={restProps.allowPath}
        access={access}
        fetchingRoles={fetchingRoles}
        isVendorSelected={isVendorSelected} /> : null}
    </>
  )
}

NavListItem.propTypes = {
  item: PropTypes.object.isRequired,
  access: PropTypes.object.isRequired,
  fetchingRoles: PropTypes.bool.isRequired,
  changePage: PropTypes.func.isRequired,
  isVendorSelected: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  pathName: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  pathName: state.router.location.pathname,
  selectedSourceType: state.AppConfig.sourceType
})
export default connect(mapStateToProps, null)(withStyles(styles)(React.memo(NavListItem)))

export const NavListItemNaked = NavListItem
