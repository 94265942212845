import { isEmpty, find } from 'lodash'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import clsx from 'clsx'
import moment from 'moment'
import { Grid, IconButton } from '@mui/material'
import { withStyles } from '@mui/styles'
import SSHNumberInput from '../../../components/SSHNumberInput'
import { SSHTypography } from '../../../components/SSHTypography'
import SSHTooltip from '../../../components/SSHTooltip'
import {
    updateInboundWeek,
    resetInboundWeek,
    updateInboundInventory
} from '../InventoryAction'
import { ReactComponent as Refuse } from '../../../assets/images/Refuse.svg'
import { ReactComponent as Accept } from '../../../assets/images/Accept.svg'
import SSHLoadingAnimation from '../../../components/SSHLoadingAnimation'
import { COLORS } from '../../../templates/Entry/globalMUIStyles'
import SSHAlertDialogWithActions from '../../../components/SSHAlertDialogWithActions'
import {
    CLEAR_EDIT,
    CLEAR_EDIT_MSG,
    SAVE_BEFORE_EDIT,
    SAVE_BEFORE_EDIT_MSG
} from '../../../config/genericConstantsNew'
import { dateFirstFormat } from '../../../config/genericConstants'

const styles = theme => ({
    rowDetailBg: { background: COLORS.brandLightenBg, marginTop: -4, marginBottom: -8 },
    renderRowMargin: { marginLeft: theme.spacing(6.25), marginBottom: theme.spacing(2) },
    whiteBg: { background: COLORS.white },
    focus: {
        width: theme.spacing(10),
        '& .Mui-focused': {
            'box-shadow': `0px 0px 0px 2px ${theme.palette.primary.blueLight}, 0px 0px 12px ${theme.rgba(
                theme.palette.primary.blueLight,
                0.75
            )}`
        }
    },
    root: {
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    }
})

const ClubRowTable = ({ row, updateInboundWeek, inboundList, resetInboundWeek, updateInboundInventory, classes }) => {

    const [editedItemWeek, setEditedWeek] = useState({})
    const [isSaving, setSaving] = useState(false)
    const [isCancel, setIsCancel] = useState(false)
    const [saveBeforeEdit, setSaveBeforeEdit] = useState(false)

    const onEdit = ({ week, win }) => {
        setEditedWeek({ week, win })
    }

    const onChange = (e, row) => {
        let { name, value } = e.target
        const { win, inbounds } = row
        const inboundValue = inbounds.find(val => val.walmartWeek === parseInt(name))

        const inboundlistExists = inboundList.find(val => val.win !== win)
        if (!isEmpty(inboundlistExists)) {
            setSaveBeforeEdit(true)
        }

        if (parseInt(value) < 0) {
            value = 0
        }

        const inboundWeek = {
            walmartWeek: parseInt(name),
            quantity: parseInt(value),
            weekStartDate: inboundValue?.weekStartDate,
            weekEndDate: inboundValue?.weekEndDate,
            win: win
        }

        updateInboundWeek({ inboundWeek })
    }

    const setWeekValue = ({ inboundList, itemWeek, win }) => {
        const weekQty = find(inboundList, { win: win, walmartWeek: itemWeek.walmartWeek })?.quantity
        return weekQty === undefined ? itemWeek?.quantity : weekQty
    }

    const resetWeekInboundList = async (isConfirmed) => {
        if (isConfirmed) {
            await resetInboundWeek({ win: row.win })
            setEditedWeek(null)
        }
        setIsCancel(false)
    }

    /**
     * @description display alert pop up if any row is edited when another inbound row data has a change
     * @returns reset the changed row data based on user choice and close pop up
     */
    const proceedEditingInboundList = async (isConfirmed) => {
        const { win } = row
        if (isConfirmed) {
            const inboundlistExists = inboundList.find(val => val.win !== win)
            await resetInboundWeek({ win: inboundlistExists?.win })
        } else {
            await resetInboundWeek({ win })
            setEditedWeek(null)
        }
        setSaveBeforeEdit(false)
    }

    const updateInboundWeekInventory = ({ row }) => {
        setSaving(true)
        updateInboundInventory({ row }).then(() => {
            setEditedWeek(null)
            setSaving(false)
        })
    }

    /**
     * @description display read only input fields
     * @returns setWeekValue method
     */
    const setReadableWeek = ({ inboundList, itemWeek, win }) => {
        return isNaN(setWeekValue({ inboundList, itemWeek, win })) ? 0 : setWeekValue({ inboundList, itemWeek, win })
    }

    const renderRow = ({ row, inboundList }) => {
        const { inbounds } = row
        if (isEmpty(inbounds)) {
            return null
        }

        return <Grid container justifyContent='space-evenly' xs={12} spacing={2} className={classes.rowDetailBg}>
            {inbounds.map(itemWeek => {
                return <Grid item xs={3} spacing={2}>
                    <SSHTooltip
                        placement="left">
                        <SSHTypography fontWeight='fontWeightMedium'>
                            {`Week ${itemWeek?.walmartWeek} 
                            (${moment(itemWeek?.weekStartDate).format(dateFirstFormat)} - ${moment(itemWeek?.weekEndDate).format(dateFirstFormat)})`}
                        </SSHTypography>
                    </SSHTooltip>
                    {
                        editedItemWeek?.week === itemWeek?.walmartWeek ?
                            <SSHNumberInput min={0} value={setWeekValue({ inboundList, itemWeek, win: row.win })} name={itemWeek?.walmartWeek}
                                className={clsx(classes.focus, classes.root, classes.whiteBg)}
                                onChange={(e) => onChange(e, row)}
                            />
                            : <SSHTypography onClick={() => onEdit({ week: itemWeek?.walmartWeek, win: row.win })}>
                                {setReadableWeek({ inboundList, itemWeek, win: row.win })}
                            </SSHTypography>
                    }
                </Grid>
            })}
        </Grid>
    }

    const renderActions = ({ row }) => {
        const { inbounds } = row
        if (isEmpty(inbounds)) {
            return null
        }

        if (isSaving) {
            return <SSHLoadingAnimation size={32} thickness={4} />
        }

        return (editedItemWeek?.win === row?.win) && <Grid item>
            <SSHTooltip title="Save inbound week">
                <IconButton name={row} onClick={() => { updateInboundWeekInventory({ row }) }}>
                    <Accept fontSize="inherit" />
                </IconButton>
            </SSHTooltip>
            <SSHTooltip title="Reset inbound week">
                <IconButton onClick={() => setIsCancel(true)} >
                    <Refuse />
                </IconButton>
            </SSHTooltip>
        </Grid>
    }


    return (
        <>
            {
                <Grid container justifyContent='space-between' className={classes.rowDetailBg}>
                    <Grid item xs={10} justifyContent='space-between' className={classes.renderRowMargin}>
                        {renderRow({ row, inboundList })}
                    </Grid>
                    <Grid item xs={1} justifyContent='center' alignContent='center'>
                        {renderActions({ row, inboundList })}
                    </Grid>
                    {isCancel && (
                        <SSHAlertDialogWithActions title={CLEAR_EDIT} description={CLEAR_EDIT_MSG} callAction={resetWeekInboundList} />
                    )}
                    {saveBeforeEdit && (
                        <SSHAlertDialogWithActions title={SAVE_BEFORE_EDIT} description={SAVE_BEFORE_EDIT_MSG} callAction={proceedEditingInboundList} />
                    )}
                </Grid>
            }
        </>
    )
}

export const ClubRowTableNaked = ClubRowTable

const mapStateToProps = state => ({
    isFetching: state.SSHPaginatedTableReducer.isFetching,
    inboundList: state.InventoryNew.inboundList
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            updateInboundWeek,
            resetInboundWeek,
            updateInboundInventory
        },
        dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClubRowTable))
