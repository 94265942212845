import { Snackbar, IconButton, SnackbarContent } from '@mui/material'
import { withStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { SSHTypography } from '../../components/SSHTypography'
import { hideToastMessage } from '../../reduxSetup/commonActions/AppConfig'


const styles = (theme => ({
  root: {
    marginBottom: theme.spacing(14),
    marginLeft: theme.spacing(6)
  },
  content: {
    borderRadius: theme.spacing(0.625),
    height: theme.spacing(7)
  }
}))

const ToastMessage = ({ message, isOpen, hideToastMessage, classes }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={isOpen}
      onClose={hideToastMessage}
      className={classes.root}>
      <SnackbarContent
        className={classes.content}
        message={<SSHTypography>{message}</SSHTypography>}
        action={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={hideToastMessage}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </Snackbar>
  )
}

ToastMessage.propTypes = {
  message: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  hideToastMessage: PropTypes.func
}

ToastMessage.defaultProps = {
  message: '',
  hideToastMessage: null
}

const mapStateToProps = state => ({
  isOpen: state.AppConfig.toastMessage.open,
  message: state.AppConfig.toastMessage.message
})

const mapDispatchToProps = dispatch => bindActionCreators({ hideToastMessage }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ToastMessage))
