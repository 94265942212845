import config from './index'
import { APP_CONFIG } from './appConfigs'

export const FULFILLMENT_TYPES = [
  {
    name: 'All open orders',
    statusType: 'Active',
    key: 'activeOrdersCount',
    tabView: 'Active',
    style: '',
    className: 'active',
    endpointCount: `${String(process.env.REACT_APP_OPEN_ORDER_COUNT) === 'v2' ? config.get('orderCount') : config.get('orderCount').replace('v2', 'v1')}`,
    endpointDetails: `${String(process.env.REACT_APP_OPEN_ORDER_SEARCH) === 'v2' ? config.get('orderSearch') : config.get('orderSearch').replace('v2', 'v1')}`
  },
  {
    name: 'Past due',
    statusType: 'Past',
    key: 'pastDue',
    tabView: 'Active',
    style: 'pastDueOrders',
    className: 'past',
    endpointCount: `${String(process.env.REACT_APP_OPEN_ORDER_COUNT) === 'v2' ? config.get('orderCount') : config.get('orderCount').replace('v2', 'v1')}`,
    endpointDetails: `${String(process.env.REACT_APP_OPEN_ORDER_SEARCH) === 'v2' ? config.get('orderSearch') : config.get('orderSearch').replace('v2', 'v1')}`
  },
  {
    name: 'Due today',
    statusType: 'Current',
    key: 'dueToday',
    tabView: 'Active',
    className: 'orders',
    endpointCount: `${String(process.env.REACT_APP_OPEN_ORDER_COUNT) === 'v2' ? config.get('orderCount') : config.get('orderCount').replace('v2', 'v1')}`,
    endpointDetails: `${String(process.env.REACT_APP_OPEN_ORDER_SEARCH) === 'v2' ? config.get('orderSearch') : config.get('orderSearch').replace('v2', 'v1')}`
  },
  {
    name: 'Pending acknowledgement',
    statusType: 'Pending',
    key: 'pending',
    tabView: 'Active',
    style: 'ordersPending',
    className: 'pending',
    endpointCount: `${String(process.env.REACT_APP_OPEN_ORDER_COUNT) === 'v2' ? config.get('orderCount') : config.get('orderCount').replace('v2', 'v1')}`,
    endpointDetails: `${String(process.env.REACT_APP_OPEN_ORDER_SEARCH) === 'v2' ? config.get('orderSearch') : config.get('orderSearch').replace('v2', 'v1')}`
  },
  {
    name: 'Shipped',
    statusType: 'Shipped',
    key: 'shipped',
    tabView: 'Closed',
    className: 'shipped',
    endpointCount: `${String(process.env.REACT_APP_CLOSED_ORDER_COUNT) === 'v2' ? config.get('orderCount') : config.get('fulfillmentCount').replace('v2', 'v1')}`,
    endpointDetails: `${String(process.env.REACT_APP_CLOSED_ORDER_SEARCH) === 'v2' ? config.get('orderSearch') : config.get('fulfillmentDetails')}`
  },
  {
    name: 'Delivered',
    statusType: 'Delivered',
    key: 'delivered',
    tabView: 'Closed',
    className: 'delivered',
    endpointCount: `${String(process.env.REACT_APP_CLOSED_ORDER_COUNT) === 'v2' ? config.get('orderCount') : config.get('fulfillmentCount').replace('v2', 'v1')}`,
    endpointDetails: `${String(process.env.REACT_APP_CLOSED_ORDER_SEARCH) === 'v2' ? config.get('orderSearch') : config.get('fulfillmentDetails')}`
  },
  {
    name: 'Cancelled',
    statusType: 'Cancelled',
    key: 'cancelled',
    tabView: 'Closed',
    style: 'pastDueOrders',
    className: 'cancelled',
    endpointCount: `${String(process.env.REACT_APP_CLOSED_ORDER_COUNT) === 'v2' ? config.get('orderCount') : config.get('fulfillmentCount').replace('v2', 'v1')}`,
    endpointDetails: `${String(process.env.REACT_APP_CLOSED_ORDER_SEARCH) === 'v2' ? config.get('orderSearch') : config.get('fulfillmentDetails')}`
  }
]

export const ORDER_STATUSES = [
  // reference used in multiple places beware
  { name: 'Pending', value: 'Pending' },
  { name: 'Acknowledge', value: 'Acknowledged' },
  { name: 'Ship', value: 'Shipped' },
  { name: 'Cancel', value: 'Cancelled' },
  { name: 'Partial Return', value: 'Partial Return' },
]

export const PERFORMANCE_STATUS_CODES = [
  { title: 'Return Rate', code: 'RR', active: true },
  { title: 'Net Promoter Score', code: 'NPS', active: true },
  { title: 'Fill Rate', code: 'FR', active: true },
  { title: 'On-time Delivery', code: 'OTD', active: true },
  { title: 'On-time Shipment', code: 'OTS', active: true },
  { title: 'Cancellation Rate', code: 'CR', active: true }
]

export const PERFORMANCE_COMPARISON_TYPES = [
  {
    name: 'LAST_WEEK',
    label: 'Compare to previous week',
    showWhen: 'Week'
  },
  {
    name: 'LAST_YEAR_WEEK',
    label: 'Compare to same week previous year',
    showWhen: 'Week'
  },
  {
    name: 'LAST_MONTH',
    label: 'Compare to previous month',
    showWhen: 'Month'
  },
  {
    name: 'LAST_YEAR_MONTH',
    label: 'Compare to same month previous year',
    showWhen: 'Month'
  },
  {
    name: 'LAST_YEAR',
    label: 'Compare to previous year',
    showWhen: 'Year'
  },
  {
    name: 'NEXT_YEAR',
    label: 'Compare to following year',
    showWhen: 'Year'
  }
]

export const ACCESS_CONTROL_TYPES = [
  { value: '1', name: 'No Access' },
  { value: '2', name: 'Read Only' },
  { value: '3', name: 'Write' }
]

export const ORDER_SEARCH_TYPES = [
  { value: 'legacyPoNo', name: 'PO number', placeHolder: 'Enter purchase order', regEx: /[^\d-]+/g, closedPlaceHolder: 'Enter purchase order(s) separated by commas', closedRegEx: /^(\d+(,\d*)*)?$/ },
  { value: 'salesOrderNo', name: 'SO number', placeHolder: 'Enter sales order', regEx: /[^\d-]+/g, closedPlaceHolder: 'Enter sales order(s) separated by commas', closedRegEx: /^(\d+(,\d*)*)?$/ },
  { value: 'itemNumber', name: 'Item id', placeHolder: 'Enter item Id', regEx: /[^\d-]+/g, closedPlaceHolder: 'Enter item id(s) separated by commas', closedRegEx: /^(\d+(,\d*)*)?$/ },
  { value: 'vendorStockId', name: 'Vendor stock id', placeHolder: 'Enter vendor stock Id', regEx: /[^\w\d-_]+/g, closedPlaceHolder: 'Enter vendor stock Id(s) separated by commas', closedRegEx: /^[a-zA-Z0-9]*(,[a-zA-Z0-9]+)*$/ }
]

export const RETURN_ORDER_SEARCH_TYPES = [
  { value: 'orderNo', name: 'Return number', placeHolder: 'Enter a return number', regEx: /[^\d-]+/g },
  { value: 'legacyPONo', name: 'PO number', placeHolder: 'Enter purchase order', regEx: /[^\d-]+/g },
  { value: 'salesOrderNo', name: 'SO number', placeHolder: 'Enter sales order', regEx: /[^\d-]+/g }]

export const ORDER_STATUS = [
  { label: 'Acknowledged', name: 'Acknowledged', checked: false },
  { label: 'Pending acknowledgement', name: 'Pending', checked: false }
]

export const ORDER_SEARCH_SORT = [
  { name: 'orderDate', sortName: 'orderDate' },
  { name: 'shipmentDate', sortName: 'expectedShipDate' }
]

export const ROLE_BACK_ACTIONS = {
  EDIT: 'EDIT',
  UPLOAD: 'UPLOAD',
  PICKLIST: 'PICKLIST',
  DOWNLOAD: 'DOWNLOAD',
  SEARCH: 'SEARCH',
  UPDATE: 'UPDATE',
  VIEW: 'VIEW',
  LABEL_DOWNLOAD: 'LABEL_DOWNLOAD'
}

export const YEAR_TO_DATE = 'Year to date'
export const LAST_YEAR_DURATION = 'Last year (Previous 12 months)'

export const ORDER_DETAILS_API_STATUS_LIST = ['Active', 'Cancelled', 'Shipped', 'Delivered']

export const ACTIVE_ITEM_STATUSES = ['Pending', 'Acknowledged']

export const CANCELLED_ITEM_STATUSES = ['Cancelled']

export const SHIPPED_ITEM_STATUSES = ['Shipped']

export const US_STATE_LIST = [
  {
    name: 'Alabama',
    value: 'AL'
  },
  {
    name: 'Alaska',
    value: 'AK'
  },
  {
    name: 'American Samoa',
    value: 'AS'
  },
  {
    name: 'Arizona',
    value: 'AZ'
  },
  {
    name: 'Arkansas',
    value: 'AR'
  },
  {
    name: 'California',
    value: 'CA'
  },
  {
    name: 'Colorado',
    value: 'CO'
  },
  {
    name: 'Connecticut',
    value: 'CT'
  },
  {
    name: 'Delaware',
    value: 'DE'
  },
  {
    name: 'District Of Columbia',
    value: 'DC'
  },
  {
    name: 'Federated States Of Micronesia',
    value: 'FM'
  },
  {
    name: 'Florida',
    value: 'FL'
  },
  {
    name: 'Georgia',
    value: 'GA'
  },
  {
    name: 'Guam',
    value: 'GU'
  },
  {
    name: 'Hawaii',
    value: 'HI'
  },
  {
    name: 'Idaho',
    value: 'ID'
  },
  {
    name: 'Illinois',
    value: 'IL'
  },
  {
    name: 'Indiana',
    value: 'IN'
  },
  {
    name: 'Iowa',
    value: 'IA'
  },
  {
    name: 'Kansas',
    value: 'KS'
  },
  {
    name: 'Kentucky',
    value: 'KY'
  },
  {
    name: 'Louisiana',
    value: 'LA'
  },
  {
    name: 'Maine',
    value: 'ME'
  },
  {
    name: 'Marshall Islands',
    value: 'MH'
  },
  {
    name: 'Maryland',
    value: 'MD'
  },
  {
    name: 'Massachusetts',
    value: 'MA'
  },
  {
    name: 'Michigan',
    value: 'MI'
  },
  {
    name: 'Minnesota',
    value: 'MN'
  },
  {
    name: 'Mississippi',
    value: 'MS'
  },
  {
    name: 'Missouri',
    value: 'MO'
  },
  {
    name: 'Montana',
    value: 'MT'
  },
  {
    name: 'Nebraska',
    value: 'NE'
  },
  {
    name: 'Nevada',
    value: 'NV'
  },
  {
    name: 'New Hampshire',
    value: 'NH'
  },
  {
    name: 'New Jersey',
    value: 'NJ'
  },
  {
    name: 'New Mexico',
    value: 'NM'
  },
  {
    name: 'New York',
    value: 'NY'
  },
  {
    name: 'North Carolina',
    value: 'NC'
  },
  {
    name: 'North Dakota',
    value: 'ND'
  },
  {
    name: 'Northern Mariana Islands',
    value: 'MP'
  },
  {
    name: 'Ohio',
    value: 'OH'
  },
  {
    name: 'Oklahoma',
    value: 'OK'
  },
  {
    name: 'Oregon',
    value: 'OR'
  },
  {
    name: 'Palau',
    value: 'PW'
  },
  {
    name: 'Pennsylvania',
    value: 'PA'
  },
  {
    name: 'Puerto Rico',
    value: 'PR'
  },
  {
    name: 'Rhode Island',
    value: 'RI'
  },
  {
    name: 'South Carolina',
    value: 'SC'
  },
  {
    name: 'South Dakota',
    value: 'SD'
  },
  {
    name: 'Tennessee',
    value: 'TN'
  },
  {
    name: 'Texas',
    value: 'TX'
  },
  {
    name: 'Utah',
    value: 'UT'
  },
  {
    name: 'Vermont',
    value: 'VT'
  },
  {
    name: 'Virgin Islands',
    value: 'VI'
  },
  {
    name: 'Virginia',
    value: 'VA'
  },
  {
    name: 'Washington',
    value: 'WA'
  },
  {
    name: 'West Virginia',
    value: 'WV'
  },
  {
    name: 'Wisconsin',
    value: 'WI'
  },
  {
    name: 'Wyoming',
    value: 'WY'
  }
]
export const AUTO_LABEL_CARRIER_ACCOUNT = APP_CONFIG.ENV()?.carriers?.split(',')[0] || 3000
export const PREPAID_SHIPPING_TYPE_FLAG = 'Prepaid'
export const COLLECT_SHIPPING_TYPE_FLAG = 'Collect'
export const CARRIER_ACCOUNT_REGISTRATION_FIELDS = [
  {
    name: 'accountNumber',
    label: 'Account Number',
    type: 'text',
    mandatory: true
  },
  {
    name: 'shippingStreets',
    label: 'Shipping Street',
    type: 'text',
    mandatory: true
  },
  {
    name: 'shippingCity',
    label: 'City',
    type: 'text',
    mandatory: true
  },
  {
    name: 'shippingState',
    label: 'State',
    type: 'select',
    options: US_STATE_LIST,
    mandatory: true
  },
  {
    name: 'shippingPostalCode',
    label: 'Zip Code',
    type: 'text',
    mandatory: true
  },
  {
    name: 'shippingCountryCode',
    label: 'Shipping Country Code',
    type: 'text',
    hidden: true,
    defaultValue: 'US'
  },
  {
    name: 'corporateFirstName',
    label: 'First Name',
    type: 'text',
    mandatory: true
  },
  {
    name: 'corporateLastName',
    label: 'Last Name',
    type: 'text',
    mandatory: true
  },
  {
    name: 'corporateJobTitle',
    label: 'Job title',
    type: 'text',
    mandatory: true
  },
  {
    name: 'corporateCompanyName',
    label: 'Company Name',
    type: 'text',
    mandatory: true
  },
  {
    name: 'corporatePhoneNumber',
    label: 'Phone',
    type: 'text',
    mandatory: true
  },
  {
    name: 'corporateEmailAddress',
    label: 'Email',
    type: 'text',
    mandatory: true
  },
  {
    name: 'corporateStreets',
    label: 'Corporate Street',
    type: 'text',
    mapFrom: 'shippingStreets',
    hidden: true
  },
  {
    name: 'corporateCity',
    label: 'Corporate City',
    type: 'text',
    mapFrom: 'shippingCity',
    hidden: true
  },
  {
    name: 'corporateState',
    label: 'Corporate State',
    type: 'text',
    mapFrom: 'shippingState',
    hidden: true
  },
  {
    name: 'corporatePostalCode',
    label: 'Corporate Postal Code',
    type: 'text',
    mapFrom: 'shippingPostalCode',
    hidden: true
  },
  {
    name: 'corporateCountryCode',
    label: 'Corporate Country Code',
    type: 'text',
    hidden: true,
    defaultValue: 'US'
  }
]

export const HISTORY_REPORTS = [
  {
    value: '0',
    name: 'Select duration'
  },
  {
    value: '3',
    name: 'Last 3 days'
  },
  {
    value: '7',
    name: 'Last 7 days'
  },
  {
    value: '14',
    name: 'Last 14 days'
  },
  {
    value: '30',
    name: 'Last 30 days'
  },
  {
    value: 'Custom',
    name: 'Custom'
  }
]


export const CHECK_STATUS_INTERVAL = 2000

export const SMART_INVENTORY_DROPDOWN_MODEL = { id: 'All', title: 'All', number: '-1', value: 'All', active: true }

export const FILTER_KEYS = {
  COLUMNS: 'columnVisibility',
  DIVISION_ID: 'divisionId',
  DIVISION: 'division',
  SUPPLIER_ID: 'supplierId',
  CATEGORY: 'category',
  CATEGORY_ID: 'categoryId',
  SUBCATEGORY: 'subcategory',
  SUBCATEGORY_NUM: 'subcategoryNmbr',
  ALERT_TYPE: 'alertType',
  CLUB_ID: 'clubId',
  ITEM_ID: 'itemId'
}

export const FILTER_LABELS = {
  COLUMN_VISIBILITY: 'Columns visibility',
  DIVISION: 'Divisions',
  CATEGORY: 'Categories',
  SUBCATEGORY: 'Subcategories',
  SUPPLIER: 'Suppliers',
  STATUS: 'Status'
}

export const CHIP_LABELS = {
  DIVISION: 'Division',
  CATEGORY: 'Category',
  SUBCATEGORY: 'Subcategory',
  SUPPLIER: 'Supplier',
  STATUS: 'Status'
}

export const OOS_STATUS = [
  {
    id: 'All',
    name: 'All',
    value: 'All'
  },
  {
    id: 'lowStock',
    name: 'Low stock',
    value: 'lowStock'
  },
  {
    id: 'inStock',
    name: 'In stock',
    value: 'inStock'
  },
  {
    id: 'outOfStock',
    name: 'Out of stock',
    value: 'outOfStock'
  }
]

export const CHART_VIEWS = [
  {
    id: 'weekly',
    name: 'Weekly',
    value: 'week'
  },
  {
    id: 'monthly',
    name: 'Monthly',
    value: 'month'
  },
  {
    id: 'quarterly',
    name: 'Quarterly',
    value: 'quarter'
  },
  {
    id: 'yearly',
    name: 'Yearly',
    value: 'year'
  }
]

export const CHART_DURATION = {
  week: [
    {
      id: '1',
      name: 'Previous week',
      value: 'Previous week'
    },
    {
      id: '2',
      name: 'Last 3 weeks',
      value: 'Last 3 weeks'
    },
    {
      id: '3',
      name: YEAR_TO_DATE,
      value: YEAR_TO_DATE
    },
    {
      id: '4',
      name: LAST_YEAR_DURATION,
      value: LAST_YEAR_DURATION
    }
  ],
  month: [
    {
      id: '1',
      name: 'Previous month',
      value: 'Previous month'
    },
    {
      id: '2',
      name: 'Last 3 months',
      value: 'Last 3 months'
    },
    {
      id: '3',
      name: YEAR_TO_DATE,
      value: YEAR_TO_DATE
    },
    {
      id: '4',
      name: LAST_YEAR_DURATION,
      value: LAST_YEAR_DURATION
    }
  ],
  quarter: [
    {
      id: '1',
      name: 'This quarter',
      value: 'This quarter'
    },
    {
      id: '2',
      name: 'Previous quarter',
      value: 'Previous quarter'
    },
    {
      id: '3',
      name: YEAR_TO_DATE,
      value: YEAR_TO_DATE
    },
    {
      id: '4',
      name: LAST_YEAR_DURATION,
      value: LAST_YEAR_DURATION
    }
  ],
  year: [
    {
      id: '1',
      name: 'This year',
      value: 'This year'
    },
    {
      id: '2',
      name: 'Previous year',
      value: 'Previous year'
    },
    {
      id: '3',
      name: 'Last 2 years',
      value: 'Last 2 years'
    }
  ]
}

export const COLUMNS_VISIBILITY_NAMES = {
  Division: [
    {
      id: 'All',
      title: 'All',
      value: 'All',
      active: false
    },
    {
      id: -1,
      visibility: 'formattedCategories',
      title: 'Category',
      value: 'Categories',
      active: false
    },
    {
      id: -2,
      visibility: 'formattedSubCategories',
      title: 'Subcategory',
      value: 'Subcategories',
      active: false
    }
  ],
  Categories: [
    {
      id: 'All',
      title: 'All',
      value: 'All',
      active: false
    },
    {
      id: -1,
      visibility: 'formattedSubCategories',
      title: 'Subcategory',
      value: 'Subcategories',
      active: false
    }
  ],
  Suppliers: [
    {
      id: 'All',
      title: 'All',
      value: 'All',
      active: false
    },
    {
      id: -1,
      visibility: 'formattedCategories',
      title: 'Category',
      value: 'Categories',
      active: false
    },
    {
      id: -2,
      visibility: 'formattedSubCategories',
      title: 'Subcategory',
      value: 'Subcategories',
      active: false
    }
  ],
  Items: [
    {
      id: 'All',
      title: 'All',
      value: 'All',
      active: false
    },
    {
      id: -1,
      visibility: 'formattedDivisions',
      title: 'Division',
      value: 'Division',
      active: false
    },
    {
      id: -2,
      visibility: 'formattedCategories',
      title: 'Category',
      value: 'Categories',
      active: false
    },
    {
      id: -3,
      visibility: 'formattedSubCategories',
      title: 'Subcategory',
      value: 'Subcategories',
      active: false
    },
    {
      id: -4,
      visibility: 'formattedSuppliers',
      title: 'Supplier',
      value: 'Suppliers',
      active: false
    }
  ]
}

export const HIDDEN_COLUMNS_NAME = [
  {
    colName: 'division',
    tabName: 'Division'
  },
  {
    colName: 'smartInventoryCategory',
    tabName: 'Categories'
  },
  { colName: 'smartInventorySubcategory', tabName: 'Subcategories' },
  { colName: 'smartInventorySupplier', tabName: 'Suppliers' },
  { colName: 'smartInventoryItem', tabName: 'Items' }
]

export const STATE_FILTER_KEYS = {
  DIVISION: 'formattedDivisions',
  CATEGORY: 'formattedCategories',
  SUBCATEGORY: 'formattedSubCategories',
  SUPPLIER: 'formattedSuppliers',
  VISIBLE_COLUMNS: 'allColumns'
}

export const ITEM_ACTION_TYPES = {
  SUSPEND: 'suspend',
  TERMINATE: 'terminate'
}

export const SEARCH_BOX_HINT_FEED = 'Select by upload id or file name'
export const SEARCH_BOX_PLACEHOLDER_FEED = 'Search by Feed ID, File name or Status (case sensitive)'

export const SUPPLIER_DROPDOWN_TITLE = 'Welcome to Sam’s Supplier Hub'
export const SUPPLIER_DROPDOWN_SUB_TITLE = `Please set your preference on default Supplier 
and Ship node for various features on this platform.`
export const NO_SHIPNODE_SELECTED_MESSAGE = 'To get started, please select a supplier'
export const INVENTORY_SEARCH_FILTER_HINT = 'Enter item ids separated by commas (max 25)'
export const INVENTORY_SEARCH_HINT = 'Search by an Item Id'
export const SEARCH_BOX_LIMIT_ERR =
  'We don’t support search items by multiple ids in search bar. Please use fitler instead.'
export const MAX_SEARCH_25 = 'Max 25 item ids are allowed.'
export const UPLOAD_MSG = 'Drag & drop excel file here'
export const UPLOAD_MSG_ALTERNATE = 'or upload one via the file browser'
export const DOWNLOAD_MSG_SI = 'Download all inventory data in an excel sheet'
export const UPLOAD_MSG_SI = 'Update item inventory using an excel sheet'
export const RESET = 'Reset'
export const RESET_MSG = `This will discard current search results and reset 
to default list of items. Do you want to continue?`
export const UPLOADING = 'Uploading file'
export const DOWNLOAD_MSG_PENALTY = 'Download data in an excel sheet'
export const RETURN_SEARCH_HINT = 'Enter a return number'

export const FILTER_NAMES = {
  ORDER_STATUS: 'Order status',
  ORDER_INFO: 'Order info',
  ORDER_DURATION: 'Order duration',
  SHIPPING_DURATION: 'Shipping duration'
}

export const PATH_INFO = {
  inventory: 'Showing all inventory items for the selected supplier and fulfillment center',
  feed: `This page contains the status of every bulk update file, used in Sam's Supplier Hub. You can download the error report for each of those bulk updates,
      and retry uploading as well.`,
  adminFeed: `This page contains the status of every bulk update file, used for Approvals by admin.
    You can download the error report for each of those bulk updates, and retry uploading as well.`,
  si: `This page contains alerts info across different Divisions, Categories, Suppliers and Items.
   You can download associated report.`,
  download: `This page contains report request initiated by you in different modules.
  You can download report anytime for up to 48 hours for successful
  requests and retry for requests having error status.`,
  approvals: `This page lists the pending requests for lead time, penalty exclude change, shipnode management and account receivables, 
  placed by all the suppliers via Supplier Hub only. 
  Merchant team members can review these requests, and either approve or reject them. 
  To view requests which have already been closed, click on resolved requests tab.`,
  leadTimeMgt: `You can turn on and turn off automatic lead time recommendations at different levels i.e.
   supplier network, suppliers and ship nodes.
   It’s going to have cascading reflection on underlying levels.`,
  supplier: 'Here you can onboard a new supplier and offboard an existing one. Also, create a new shipnode and update, activate or deactivate an existing shipnode.',
  penalty: `Penalty calculation - Penalty for cancelled orders: 
   \${0} or {1}% of the item cost, whichever is greater. 
   Penalty for late shipped orders:
   1 day late = \${2} or {3}% of item cost, whichever is greater. 
   More than 1 day late  = \${4} or {5}% of item cost, whichever is greater.`,
  returns: `This page contains list of return orders which are pending or acknowledged.`
}

export const ROUTE_MODULES = {
  search: {
    key: 'Search',
    path: '/search',
    name: 'Supplier selection',
  },
  performance: {
    key: 'Performance',
    weekmetrics: {
      path: '/performance',
      name: 'Weekly metrics',
    },
    home: {
      path: '/dashboard',
      name: 'Dashboard',
    },
    monthpenalty: {
      path: '/penalty',
      name: 'Monthly penalties'
    }
  },
  prepurchase: {
    key: 'Pre-purchase',
    inventory: {
      path: '/inventory',
      name: 'Inventory'
    },
    smartinventory: {
      name: 'Smart inventory',
      path: '/smart-inventory'
    },
    intransitinventory: {
      path: '/in-transit-inventory',
      name: 'In-transit inventory'
    }
  },
  forecasting: {
    key: 'Forecasting',
    clubsaleforecast: {
      name: 'Sales forecast',
      path: '/club/sales-forecast'
    },
    cluborderdemand: {
      name: 'Order forecast',
      path: '/club/order-demand'
    }
  },
  inventorypricing: {
    key: 'Inventory & Pricing',
    inventory: {
      path: '/inventory',
      name: 'Inventory'
    },
    pricingservice: {
      name: 'Pricing service',
      path: '/club/pricing-service'
    },
    outofstock: {
      name: 'Out of Stock',
      path: '/club/stockout'
    }
  },
  fulfilment: {
    key: 'Fulfillment',
    orderfulfilment: {
      name: 'Order fulfillment',
      path: '/fulfillment'
    },
    leadtime: {
      path: '/lead-time-capacity',
      name: 'Lead time/Daily capacity'
    },
    supppliermanagement: {
      name: 'Supplier management',
      path: '/supplier-mgt'
    }
  },
  postpurchase: {
    key: 'Post purchase',
    returnprocessing: {
      name: 'Returns processing',
      path: '/returns'
    }
  },
  toolsreporting: {
    key: 'Tools and reporting',
    reports: {
      name: 'Reports',
      path: '/reports'
    },
    uploadrequest: {
      name: 'Upload requests',
      path: '/feed'
    },
    adminuploadrequest: {
      name: 'Uploads - Approvals',
      path: '/admin/feed'
    },
    downloads: {
      name: 'Downloads',
      path: '/download-history'
    },
    approvals: {
      name: 'Approvals',
      path: '/admin/approvals'
    },
    leadtimemanagement: {
      name: 'Lead time management',
      path: '/admin/mantis-lead-time'
    }
  }
}

export const MODULE_TYPES = {
  fulfilment: 'order fulfillment',
  inventory: 'inventory',
  performance: 'performance',
  leadTimeApproval: 'approvals',
  notification: 'notification',
  smartInventory: 'smart inventory',
  leadTimeAdmin: 'lead-time', // same as leadtimeapproval
  predefinedReports: 'reports',
  penaltyProgram: 'Monthly penalties',
  returnOrder: 'returns processing',
  weekmetrics: 'Weekly metrics',
  inTransitInventory: 'in-transit inventory',
  clubSaleForecast: 'Sales forecast',
  clubOrderDemand: 'Order forecast',
  clubOOS: 'Out of Stock',
  dailyCapacity: 'Lead time/Daily capacity'
}

export const INFO_PATHS = [
  ROUTE_MODULES?.prepurchase?.inventory?.path, // '/inventory'
  ROUTE_MODULES?.toolsreporting?.uploadrequest?.path, // '/feed'
  ROUTE_MODULES?.toolsreporting?.adminuploadrequest?.path, // '/admin/feed'
  ROUTE_MODULES?.prepurchase?.smartinventory?.path, // '/smart-inventory'
  ROUTE_MODULES?.toolsreporting?.downloads?.path, // '/download-history'
  ROUTE_MODULES?.toolsreporting?.approvals?.path,// '/admin/approvals'
  ROUTE_MODULES?.toolsreporting?.leadtimemanagement?.path,// '/admin/mantis-lead-time'
  ROUTE_MODULES?.fulfilment?.supppliermanagement?.path,// '/supplier-mgt'
  ROUTE_MODULES?.performance?.monthpenalty?.path, // '/penalty'
  ROUTE_MODULES?.postpurchase?.returnprocessing?.path, // '/returns'
]

export const yearFirstFormat = 'YYYY-MM-DD'
export const dateFirstFormat = 'MM/DD/YYYY'
export const monthFirstFormat = 'MM/DD/YY'
export const dateTimeFormat = 'MM/DD/YYYY HH:mm:ss'

export const API_MODULE_TYPES = {
  performance: 'perf_order_download',
  inventory: 'inventory',
  fulfilment: 'order',
  leadTime: 'recommendedShipNodeConfig',
  smartInventory: 'alert',
  openOrders: 'openOrders',
  salesOrders: 'salesOrders',
  penalty: 'penalty',
  penaltyApprovals: 'penalty_approval',
  inTransitInventory: 'IN_TRANSIT_INVENTORY',
  dailyCapacity: 'FCAP',
  accountsReceivable: 'AR'
}

export const CLUB_MODULE_TYPES = {
  inventory: 'CLUB_INVENTORY',
  salesForecast: 'CLUB_FORECAST',
  oos: 'CLUB_OOS'
}

export const VISIBLE_MODULE_TYPES = {
  performance: 'Performance',
  inventory: 'Inventory',
  fulfilment: 'Fulfillment',
  leadTime: 'Lead time',
  smartInventory: 'Smart inventory',
  alert: 'Alert',
  openOrders: 'Open order report',
  salesOrders: 'Sales report',
  penalty: 'Penalty',
  approvals: 'Approvals',
  inTransitInventory: 'In-transit inventory',
  clubSaleForecast: 'Sales forecast',
  clubOrderDemand: 'Order forecast'
}

export const PAST_TRENDS_TYPES = {
  OOS: 'OOS',
  RL: 'RL'
}

export const TOOLTIP_PENALTY_FR = `Fill rate includes cancellations that 
were processed by the supplier. 
Orders above 99% FR do not 
incur penalty amount for cancellations.`

export const TOOLTIP_PENALTY_OTS = `On-time shipping includes orders those were shipped by the supplier by MSBD. 
Orders above 99% OTS do not incur penalty amount for late shipped orders.`

export const OTHERS = `Others`
