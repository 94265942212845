import { Skeleton } from '@mui/lab'
import { withStyles } from '@mui/styles'
import React from 'react'

const styles = () => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    '&:after': {
      backgroundImage: 'linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.09), transparent)'
    }
  }
})

// eslint-disable-next-line react/prop-types
const SSHSkeleton = ({ classes, ...props }) => <Skeleton classes={{ root: classes.root }} {...props} />
export default withStyles(styles)(SSHSkeleton)
