import { resetTable } from '../../reduxSetup/commonActions/SSHPaginatedTable'
import {
  TOGGLE_HISTORY_VIEW,
  SET_MODULE_STATUS_TAB,
  SET_ADMIN_TAB,
  TOGGLE_REVIEW_BOX,
  SUPPLIER_DATA_REQUEST,
  SUPPLIER_DATA_SUCCESS,
  SUPPLIER_DATA_FAILURE,
  SET_AR_MONTH_VALUE,
  SET_SELECTED_SUPPLIER,
  RESET_SUPPLIER_SELECTION
} from './Approvals.actionTypes'
import { isEmpty } from 'lodash'
import { fetchLeadTimeRequestsAdmin } from '../LeadTime/LeadTimeAction'
import { getPenaltyHistory } from '../PenaltyProgram/PenaltyProgramActions'
import { getShipnodeHistory } from '../SupplierManagement/SupplierOnBoardAction'
import { fetchARLastMonthOrders, fetchAROlderMonthsOrders } from '../AccountReceivable/AccountReceivableActions'
import { LEADTIME, PENALTY, SUPPLIER_MNGT, ACCOUNTS_RECEIVABLE } from './commonConstants'
import { CALL_API } from '../../reduxSetup/middleware/API'
import config from '../../config'

/**
 * @desc set selected module
 * @returns {function(any, any): any}
 */
export const setSelectedTabActive = ({ tabName }) => dispatch => {
  return Promise.resolve(
    dispatch({
      type: SET_MODULE_STATUS_TAB,
      payload: { tabName }
    })
  )
}

/**
 * @desc Toggle the view between completed and pending requests
 * @returns {function(any, any): any}
 */
export const toggleHistoryView = isCompletedView => (dispatch, getState) => {
  const { selectedAdminTab } = getState().Approvals
  return Promise.resolve(
    dispatch({
      type: TOGGLE_HISTORY_VIEW,
      payload: { isCompletedView }
    })
  )
    .then(dispatch(resetTable()))
    .then(dispatch(fetchSelectedView(selectedAdminTab)))
}

/**
 * @desc set admin module tab
 * @returns {function(any, any): any}
 */
export const setAdminTabView = ({ selectedAdminTab }) => dispatch => {
  return Promise.resolve(
    dispatch({
      type: SET_ADMIN_TAB,
      payload: { selectedAdminTab }
    })
  )
    .then(dispatch(resetTable()))
    .then(dispatch(resetSuppliersList()))
    .then(dispatch(fetchSelectedView(selectedAdminTab)))
}

/**
 * @desc fetch table data based on the selected tab
 * @returns {function(any, any): any}
 */
export const fetchSelectedView = selectedAdminTab => (dispatch, getState) => {
  switch (selectedAdminTab) {
    case LEADTIME:
      dispatch(fetchLeadTimeRequestsAdmin())
      break
    case PENALTY:
      dispatch(getPenaltyHistory())
      break
    case SUPPLIER_MNGT:
      dispatch(getShipnodeHistory())
      break
    case ACCOUNTS_RECEIVABLE:
      const { selectedARMonth, selectedSuppliers } = getState().Approvals
      dispatch((selectedARMonth?.id === 1) ? fetchARLastMonthOrders() : fetchAROlderMonthsOrders())
        .then(() => {
          if (isEmpty(selectedSuppliers)) {
            dispatch(fetchSupplierDetails())
          }
        })
      break
    default:
      break
  }
}

/**
 * @desc Toggle the review popup
 * @param {Object} task
 * @returns {function(any): any}
 */
export const toggleReviewBox = ({ task }) => dispatch => {
  return dispatch({
    type: TOGGLE_REVIEW_BOX,
    payload: { task }
  })
}

/**
 * @desc reset tab on menu change
 * @param {Object} selectedAdminTab
 * @returns {function(any): any}
 */
export const resetApprovalsTab = ({ selectedAdminTab }) => dispatch => {
  dispatch({
    type: SET_ADMIN_TAB,
    payload: { selectedAdminTab }
  })
  Promise.resolve(
    dispatch({
      type: TOGGLE_HISTORY_VIEW,
      payload: { isCompletedView: false }
    })
  ).then(dispatch(resetTable()))
}

/**
 * @description fetch supplier details for multi search dropdown
 * @returns
 */
export const fetchSupplierDetails = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        SUPPLIER_DATA_REQUEST,
        SUPPLIER_DATA_SUCCESS,
        SUPPLIER_DATA_FAILURE
      ],
      endpoint: `${config.get('suppliersDetails')}`,
      method: 'GET'
    }
  })
}

/**
 * @desc set selected AR month
 * @returns {function(any, any): any}
 */
export const setSelectedARMonth = (selectedMonth) => dispatch => {
  return Promise.resolve(
    dispatch({
      type: SET_AR_MONTH_VALUE,
      payload: selectedMonth
    })
  )
}

/**
 * @desc set selected supplier
 * @returns {function(any, any): any}
 */
export const setSelectedSupplier = ({ updated, selected }) => dispatch => {
  return Promise.resolve(
    dispatch({
      type: SET_SELECTED_SUPPLIER,
      payload: { updated, selected }
    })
  )
}

/**
 * @desc reset suppliers list
 * @returns {function(any, any): any}
 */
export const resetSuppliersList = () => dispatch => {
  return Promise.resolve(
    dispatch({
      type: RESET_SUPPLIER_SELECTION
    })
  )
}
