import { Table } from '@devexpress/dx-react-grid-material-ui'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Refresh } from '@mui/icons-material'
import { get, includes } from 'lodash'
import SSHButton from '../../components/SSHButton'
import { getExportFile, retryFailedExportFile } from '../../reduxSetup/commonActions/AppConfig'
import SSHLoadingAnimation from '../../components/SSHLoadingAnimation'
import { ReactComponent as DownloadIcon } from '../../assets/images/Download.svg'

const useStyles = makeStyles(theme => ({
  actionIcon: {
    margin: `0px ${theme.spacing(7.5)}px`
  }
}))

/**
 * @description Component to render custom table cell for download actions
 * @returns React element with the functionalities of download/retry
 */
const DownloadActionCell = ({ ...props }) => {
  const { tableRow, getExportFile, retryFailedExportFile, ackIds, downloadInProgress } = props
  const { ackId, status, fileName, taskType } = tableRow.row
  const ackStateObj = ackIds.find(ackIdObj => ackIdObj.ackId === ackId)

  const handleClick = () => {
    if (downloadInProgress[ackId]) {
      return
    }
    const currentFileName = get(ackStateObj, 'fileName', '')
    const exportFileName =
      currentFileName.length > 0
        ? currentFileName.substr(currentFileName.lastIndexOf('/') + 1, currentFileName.length)
        : fileName
    getExportFile({ ackId: { ackId, status, fileName: exportFileName } })
  }

  const handleRetry = () => retryFailedExportFile({ ackId: { ackId, status, fileName }, taskType })

  const classes = useStyles()
  return (
    <Table.Cell {...props} align="center">
      {(ackStateObj ? ackStateObj.status === 'COMPLETED' : status === 'COMPLETED') && (
        <SSHButton
          loading={downloadInProgress[ackId]}
          className={classes.actionIcon}
          Icon={DownloadIcon}
          onClick={handleClick}
        />
      )}
      {(ackStateObj ? ackStateObj.status === 'FAILED' : status === 'FAILED') && (
        <SSHButton Icon={Refresh} onClick={handleRetry} className={classes.actionIcon} />
      )}
      {(ackStateObj ? includes(['INITIATED', 'INPROGRESS'], ackStateObj.status) : false) && (
        <SSHLoadingAnimation size={15} thickness={5} />
      )}
    </Table.Cell>
  )
}

export const DownloadActionCellNaked = DownloadActionCell

DownloadActionCell.propTypes = {
  props: PropTypes.object.isRequired,
  getExportFile: PropTypes.func.isRequired,
  retryFailedExportFile: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  ackIds: state.AppConfig.exportOrders.ackIds,
  downloadInProgress: state.ReportsRequests.downloadInProgress
})

const mapDispatchToProps = dispatch => bindActionCreators({ getExportFile, retryFailedExportFile }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(DownloadActionCell)
