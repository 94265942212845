/* eslint-disable no-lonely-if */
import {  Grid } from '@mui/material'
import React, { PureComponent } from 'react'
import SSHModal from '../../../../components/SSHModal'
import PopupActions from '../../../../templates/PopupActions'

class ForceUpdatePopup extends PureComponent {

  render = () => {
    const { errorMessage, handleForceUpdate, cancelShipping } = this.props
    return (
      <SSHModal
        title="Confirm force update"
        onClose={() => cancelShipping(false)}
        size="sm"
        showDivider={false}
        actions={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <PopupActions
            disabled={false}
            isLoading={false}
            alignRight
            confirmText={'Yes'}
            onConfirm={handleForceUpdate}
            cancelText={'No'}
            onCancel={() => cancelShipping(false)}
          />
        }>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {errorMessage}
          </Grid>
        </Grid>
      </SSHModal>
    )
  }
}
export const ForceUpdatePopupNaked = ForceUpdatePopup
export default ForceUpdatePopup
