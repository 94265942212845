import {
  CustomPaging,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
  RowDetailState
} from '@devexpress/dx-react-grid'
import {
  Grid,
  PagingPanel,
  Table,
  TableHeaderRow,
  TableSelection,
  TableColumnVisibility,
  VirtualTable,
  TableRowDetail,
  TableFixedColumns,
  TableBandHeader
} from '@devexpress/dx-react-grid-material-ui'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { CustomFieldTypeProvider } from '../templates/ReactGridDataTables/CustomFieldTypeProvider'
import CustomTableCell from '../templates/ReactGridDataTables/CustomTableCell'
import TableHeaderCell from '../templates/SmartInventory/TableHeaderCell'
import BandCell from '../pages/Capacity/BandCell'
import DetailRowTable from '../templates/DetailRowTable'
import { formattedColumnsDefaultProps } from '../utils/commonDefaultProps'
import { formattedColumnsPropType } from '../utils/commonPropTypes'
import SSHTableSkeleton from './SSHTableSkeleton'
import { getHeight } from '../utils/common'
import { isEmpty } from 'lodash'

const Root = props => <Grid.Root {...props} style={{ height: `${window.innerHeight - getHeight()}px` }} />

class SSHPaginatedTable extends Component {
  render = () => {
    const {
      rows,
      columns,
      tableColumnExtensions,
      pageNo,
      pageSize,
      totalRows,
      selectedRows,
      isFetching,
      serverSide,
      noDataMessage,
      setCurrentPage,
      changePageSize,
      formattedColumns,
      enableSelection,
      hideColumns,
      onSelectionChange,
      hiddenColumnNames,
      sorting,
      onSortingChange,
      selectableRow,
      ...restProps
    } = this.props

    if (isFetching) return <SSHTableSkeleton />

    /**
     * @description display the columns as locked
     */
    const displayLockedColumns = () => {
      const { isLockableColumns, leftColumns, rightColumns } = restProps
      return (isLockableColumns && <TableFixedColumns
        leftColumns={leftColumns}
        rightColumns={rightColumns}
      />)
    }

    return (
      <Grid rows={rows} columns={columns} rootComponent={Root}>
        {formattedColumns.map(column => (
          <CustomFieldTypeProvider key={column.type} colType={column.type} for={column.list} />
        ))}
        {enableSelection && <SelectionState selection={selectedRows} onSelectionChange={onSelectionChange} />}
        {serverSide && ([
          <SortingState
            key={columns.title}
            sorting={sorting}
            onSortingChange={onSortingChange}
            columnExtensions={columns.map(col => ({
              columnName: col.name,
              sortingEnabled: !!col.sorting
            }))}
          />,
          <PagingState
            key={columns.title}
            currentPage={pageNo}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={changePageSize}
          />
        ])}
        {!serverSide && ([
          <SortingState key={columns.title} />,
          <PagingState key={columns.title} defaultCurrentPage={0} pageSize={25} />,
          <IntegratedSorting key={columns.title} />,
          <IntegratedPaging key={columns.title} />
        ])}

        <CustomPaging totalCount={parseInt(totalRows, 10)} />
        <VirtualTable height="auto" />
        <Table
          columnExtensions={tableColumnExtensions}
          cellComponent={CustomTableCell}
          messages={{ noData: isFetching ? 'Loading data' : noDataMessage }}
        />
        {restProps.enableRowDetailsTable && ([
          <RowDetailState
            key={columns.title}
            defaultExpandedRowIds={[0]}
          />,
          <TableRowDetail key={columns.title} contentComponent={DetailRowTable} />
        ])
        }
        {enableSelection && <IntegratedSelection />}
        {enableSelection && selectableRow &&
          <TableSelection showSelectAll highlightRow cellComponent={selectableRow} {...restProps} />}
        {enableSelection && !selectableRow &&
          <TableSelection showSelectAll highlightRow />}
        <TableHeaderRow cellComponent={TableHeaderCell} showSortingControls />
        {!hideColumns && <TableColumnVisibility hiddenColumnNames={hiddenColumnNames} />}
        {!isEmpty(restProps?.columnBands) && <TableBandHeader columnBands={restProps.columnBands} cellComponent={BandCell} />}
        <TableFixedColumns leftColumns={restProps.leftColumns || []} />

        {displayLockedColumns()}
        <PagingPanel pageSizes={[10, 25, 50, 100]} />
      </Grid>
    )
  }
}

SSHPaginatedTable.defaultProps = {
  pageNo: 1,
  pageSize: 25,
  rows: [{ col1: 'Row1' }],
  columns: [{ name: 'col1', title: 'Column 1' }],
  formattedColumns: formattedColumnsDefaultProps,
  tableColumnExtensions: [],
  totalRows: 10,
  isFetching: false,
  enableSelection: false,
  hideColumns: false,
  selectedRows: [],
  noDataMessage: 'No data',
  serverSide: true,
  hiddenColumnNames: [],
  enableRowDetailsTable: false,
  isLockableColumns: false,
  leftColumns: [],
  rightColumns: []
}

SSHPaginatedTable.propTypes = {
  pageNo: PropTypes.number,
  pageSize: PropTypes.number,
  rows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  formattedColumns: formattedColumnsPropType,
  tableColumnExtensions: PropTypes.array,
  totalRows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noDataMessage: PropTypes.string,
  isFetching: PropTypes.bool,
  enableSelection: PropTypes.bool,
  hideColumns: PropTypes.bool,
  selectedRows: PropTypes.arrayOf(PropTypes.number),
  setCurrentPage: PropTypes.func.isRequired,
  changePageSize: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  serverSide: PropTypes.bool,
  hiddenColumnNames: PropTypes.array,
  // eslint-disable-next-line react/require-default-props
  sorting: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  onSortingChange: PropTypes.func,
  selectableRow: PropTypes.func,
  isLockableColumns: PropTypes.bool,
  leftColumns: PropTypes.array,
  rightColumns: PropTypes.array
}

export default SSHPaginatedTable
