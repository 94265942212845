import { CLOSE_ERROR_ALERT } from './ErrorHandler.actionTypes'
import { GENERAL_ERROR } from '../../config/errorConstants'

// eslint-disable-next-line import/prefer-default-export
export const closeAlert = () => dispatch => {
  return dispatch({
    type: CLOSE_ERROR_ALERT
  })
}

// eslint-disable-next-line import/prefer-default-export
export const showAlert = (type, errMsg = GENERAL_ERROR) => dispatch => {
  return Promise.resolve(
    dispatch({
      type,
      errMsg
    })
  )
}

