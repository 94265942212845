import React from 'react'
import { Box, Button } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import clsx from 'clsx'
import { setReturnTab } from './ReturnOrderAction'

export const RETURNS_TABS = {
  PENDING: 'Pending',
  ACKNOWLEDGED: 'Acknowledged'
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.headerBackground,
    height: theme.spacing(5),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: 'flex',
    justifyContent: 'start',
    borderBottom: `2px solid ${theme.palette.primary.bottiCelli}`
  },
  btn:{
    marginRight: theme.spacing(0.1),
    color: theme.palette.primary.haliteBlue,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '10px',
    cursor:'pointer'
  },
  active: {
    color: theme.palette.primary.blue,
    borderBottom: `2px solid ${theme.palette.primary.blue}`
  },
  cntActive: {
    backgroundColor: theme.palette.primary.errorRed,
    color: theme.palette.primary.notificationBadge
  },
  activeHighlight: {
    backgroundColor: theme.palette.primary.notificationBadge,
    color: theme.palette.primary.errorRed
  },
  cntClosed: {
    backgroundColor: theme.palette.primary.successGreen,
    color: theme.palette.primary.springGreen
  },
  closedHighlight: {
    backgroundColor: theme.palette.primary.springGreen,
    color: theme.palette.primary.successGreen
  },
  allCount: {
    borderRadius: theme.spacing(4),
    margin: theme.spacing(1),
    padding: theme.spacing(0.25, 0.5),
    fontSize: theme.spacing(1.8),
    lineHeight: '10px'
  }
})

const ReturnOrderFilter = ({ classes, selectedReturnTab, setReturnTab, pendingReturnsCount, acknowledgeReturnsCount, isFetching }) => {
  return (
    <Box className={classes.root}>
      <Box>
        <Button
          variant="text"
          size="small"
          className={selectedReturnTab === RETURNS_TABS.PENDING ? classes.active : classes.btn}
          disabled={isFetching}
          onClick={() => setReturnTab({ tabName: RETURNS_TABS.PENDING })}>
          {RETURNS_TABS.PENDING}
          <span
            className={clsx(
              classes.allCount,
              selectedReturnTab === RETURNS_TABS.PENDING ? classes.activeHighlight : classes.cntActive
            )}>
            {pendingReturnsCount}
          </span>
        </Button>
      </Box>
      <Box>
        &nbsp;&nbsp;
        <Button
          variant="text"
          size="small"
          className={selectedReturnTab === RETURNS_TABS.ACKNOWLEDGED ? classes.active : classes.btn}
          disabled={isFetching}
          onClick={() => setReturnTab({ tabName: RETURNS_TABS.ACKNOWLEDGED })}>
          {RETURNS_TABS.ACKNOWLEDGED}
          <span
            className={clsx(
              classes.allCount,
              selectedReturnTab === RETURNS_TABS.ACKNOWLEDGED ? classes.closedHighlight : classes.cntClosed
            )}>
            {acknowledgeReturnsCount}
          </span>
        </Button>
      </Box>
    </Box>
  )
}

ReturnOrderFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedReturnTab: PropTypes.string.isRequired,
  setReturnTab: PropTypes.func.isRequired,
  pendingReturnsCount: PropTypes.number.isRequired,
  acknowledgeReturnsCount: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  selectedReturnTab: state.ReturnOrder?.selectedReturnTab,
  pendingReturnsCount: state.ReturnOrder?.pendingReturnsCount,
  acknowledgeReturnsCount: state.ReturnOrder?.acknowledgeReturnsCount,
  isFetching: state.SSHPaginatedTableReducer.isFetching
})

const mapDispatchToProps = dispatch => bindActionCreators({ setReturnTab }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReturnOrderFilter))
export const ReturnOrderFilterNaked = ReturnOrderFilter
