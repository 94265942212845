import { Typography, Grid } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SSHModal from '../../../../components/SSHModal'
import PopupActions from '../../../../templates/PopupActions'
import { showHideLabelGenErrorPopup } from '../../OrderDetailsActions'

const DownloadLabelError = ({ showHideLabelGenErrorPopup }) => {
  const onClose = showHideLabelGenErrorPopup.bind(this, { show: false })
  return (
    <SSHModal
      size="sm"
      title="Download shipping label"
      showDivider={false}
      // eslint-disable-next-line react/jsx-no-bind
      onClose={onClose}
      // eslint-disable-next-line react/jsx-no-bind
      actions={<PopupActions alignRight confirmText="Ok" onConfirm={onClose} />}>
      <Grid container justifyContent="center" spacing={0} alignItems="center" alignContent="center" direction="row">
        <Grid item container justifyContent="center" xs={1}>
          <InfoOutlinedIcon fontSize="default" color="primary" />
        </Grid>
        <Grid item xs={11}>
          <Typography variant="body2">
            System is unable to download label as it's no longer available or label was not generated using Supplier Hub
          </Typography>
        </Grid>
      </Grid>
    </SSHModal>
  )
}

export const DownloadLabelErrorNaked = DownloadLabelError

DownloadLabelError.propTypes = {
  showHideLabelGenErrorPopup: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => bindActionCreators({ showHideLabelGenErrorPopup }, dispatch)
export default connect(null, mapDispatchToProps)(DownloadLabelError)
