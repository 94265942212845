import { Box } from '@mui/material'
import { withStyles } from '@mui/styles'
import { isArray, isEmpty, filter } from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import CollapsiblePanel from '../../components/CollapsiblePanel'
import SSHNonPaginatedTable from '../../components/SSHNonPaginatedTable'
import {
  ACTIVE_ITEM_STATUSES,
  SHIPPED_ITEM_STATUSES,
  CANCELLED_ITEM_STATUSES,
  ORDER_DETAILS_API_STATUS_LIST,
  ROLE_BACK_ACTIONS
} from '../../config/genericConstants'
import ShipmentPopupContainer from './Popups/Shipment/ShipmentPopupContainer'
import CancelPopupGeneric from './Popups/ItemQuantity/CancelPopupGeneric'
import SSHButton from '../../components/SSHButton'
import GiftMessage from './GiftMessage'
import EditItemQuantity from './Popups/ItemQuantity/EditItemQuantity'
import { allowAccess } from '../../utils/common'
import { PO_WAITING_MESSAGE } from '../../config/genericConstantsNew'

const styles = theme => ({
  panelAction: {
    position: 'absolute',
    right: theme.spacing(4),
    marginTop: theme.spacing(2)
  },
  shipNow: {
    fontWeight: '500',
    borderRadius: theme.spacing(0.25)
  }
})

class PendingItems extends PureComponent {
  static columns = [
    { name: 'itemDesc', title: 'Item name', sorting: false },
    { name: 'itemNumber', title: 'Item ID', sorting: false },
    { name: 'skuId', title: 'SKU ID', sorting: false },
    { name: 'upcNumber', title: 'UPC', sorting: false },
    { name: 'orignalfulfilmentQty', title: 'Ordered', sorting: false },
    { name: 'currentfulfilmentQty', title: 'Pending', sorting: false },
    {
      name: 'giftInstructionText',
      title: 'Gift message',
      sorting: false,
      getCellValue: row => {
        return row.giftInstructionText && <GiftMessage text={row.giftInstructionText} />
      }
    },
    {
      name: 'cancelAction',
      title: 'Action'
    }
  ]

  static columnExtensions = [
    { columnName: 'itemDesc', width: 380 },
    { columnName: 'itemNumber', width: 120, align: 'right' },
    { columnName: 'skuId', width: 120, align: 'right' },
    { columnName: 'upcNumber', width: 120, align: 'right' },
    { columnName: 'orignalfulfilmentQty', width: 120, align: 'right' },
    { columnName: 'currentfulfilmentQty', width: 120, align: 'right' },
    { columnName: 'giftInstructionText', width: 400 },
    { columnName: 'Action', width: 60 }
  ]

  constructor(props) {
    super(props)
    this.state = {
      items: [],
      selectedRowIds: [],
      selectedRows: [],
      popupType: 'item',
      cancelPopup: false,
      selectedCancelItem: null
    }
  }

  static getDerivedStateFromProps = ({ releaseDetails }) => {
    return {
      items: releaseDetails?.itemDetails.filter(item => ACTIVE_ITEM_STATUSES.includes(item.status))
    }
  }

  resetSelectedRow = () => {
    this.setState({ selectedRowIds: [], selectedRows: [] })
  }

  onSelectionChange = selectedRowIds => {
    this.setState({ selectedRowIds })
  }

  componentDidUpdate = async () => {
    const { resetTab, isTabChanged } = this.props
    const { selectedRowIds } = this.state
    if (isTabChanged && !isEmpty(selectedRowIds)) {
      await resetTab()
      this.resetSelectedRow()
    }
  }


  getSelectedRowsItem = async () => {
    const { items, selectedRowIds } = this.state
    const { showHideShipmentPopup, releaseActiveTab, releaseDetails } = this.props

    const newItem = []
    if (!isEmpty(selectedRowIds) && isArray(selectedRowIds)) {
      selectedRowIds.forEach(row => {
        newItem.push(items[row])
      })
    }

    const { itemDetails } = releaseDetails
    const selectedNewRow = []
    newItem.forEach(item => {
      const cancelled = filter(itemDetails, { itemNumber: item.itemNumber, status: CANCELLED_ITEM_STATUSES[0] })
      selectedNewRow.push({
        ...item,
        cancelled: cancelled.length > 0 ? cancelled[0].currentfulfilmentQty : 0
      })
    })

    this.setState(
      {
        selectedRows: selectedNewRow
      },
      () => showHideShipmentPopup({ releaseNumber: releaseActiveTab })
    )
  }

  /**
   * @description confirm cancel units dialog and cancel the required items
   * @param {string} cancelUnits
   */
  confirmCancel = async cancelUnits => {
    const { toggleEditPopup } = this.props
    const canceltems = this.renderCancelToItems()

    let popupType = 'item'
    if (canceltems.currentfulfilmentQty === cancelUnits) {
      popupType = 'order'
    }

    this.setState(
      {
        popupType,
        selectedCancelItem: {
          ...canceltems,
          cancelUnits
        }
      },
      () => {
        toggleEditPopup(canceltems.itemNumber)
        this.toggleCancelPopup(true)
      }
    )
  }

  renderCancelToItems = () => {
    const { releaseDetails, showEditPopupItem } = this.props

    let items = {}

    if (showEditPopupItem.isEdit) {
      const { itemDetails } = releaseDetails

      const shipped = filter(itemDetails, itemStatus => {
        return (
          itemStatus.itemNumber === showEditPopupItem.itemNumber &&
          (itemStatus.status === SHIPPED_ITEM_STATUSES[0] || itemStatus.status === ORDER_DETAILS_API_STATUS_LIST[3])
        )
      })
      const cancelled = filter(itemDetails, {
        itemNumber: showEditPopupItem.itemNumber,
        status: CANCELLED_ITEM_STATUSES[0]
      })
      const pending = filter(itemDetails, itemStatus => {
        return (
          itemStatus.itemNumber === showEditPopupItem.itemNumber && ACTIVE_ITEM_STATUSES.includes(itemStatus.status)
        )
      })

      items = {
        name: pending.length > 0 ? pending[0].itemDesc : '',
        orignalfulfilmentQty: this.getItems(pending, 'orignalfulfilmentQty'),
        currentfulfilmentQty: this.getItems(pending, 'currentfulfilmentQty'),
        cancelled: cancelled.length,
        shipped: shipped.length > 0 ? shipped[0].currentfulfilmentQty : 0,
        pending: pending.length,
        itemNumber: this.getItems(pending, 'itemNumber'),
        poNumber: this.getItems(pending, 'poNumber'),
      }
    }
    return items
  }

  /**
  * @description Get Items
  */
  getItems = (pending, prop) => {
    return pending.length > 0 ? pending[0][prop] : 0
  }

  toggleCancelPopup = isCancel => {
    this.setState({
      cancelPopup: isCancel
    })
  }

  /**
   * @description cancel item units in edit form
   */
  onCanceledUnits = () => {
    const { selectedCancelItem } = this.state
    const { releaseDetails, cancelledUnits, releaseActiveTab } = this.props

    const cancelItems = {
      name: selectedCancelItem.name,
      itemNumber: selectedCancelItem.itemNumber,
      orderedUnits: selectedCancelItem.orignalfulfilmentQty,
      currentfulfilmentQty: selectedCancelItem.currentfulfilmentQty,
      cancelledUnits: selectedCancelItem.cancelUnits,
      releaseNumber: releaseActiveTab,
      releaseDetails
    }
    this.setState({
      cancelPopup: false
    })
    cancelledUnits({ cancelItems })
  }

  render = () => {
    const {
      classes,
      isFetching,
      showShipmentPopup,
      releaseActiveTab,
      releaseDetails,
      showEditPopupItem,
      toggleEditPopup,
      isForceUpdate,
      errorMessage,
      legacyPOExists
    } = this.props
    const { items, selectedRowIds, selectedRows, cancelPopup, popupType, selectedCancelItem } = this.state

    if (isEmpty(releaseDetails) || isEmpty(items)) {
      return null
    }
    return (
      <>
        <Box>
          <Box className={classes.panelAction}>
            {allowAccess(ROLE_BACK_ACTIONS.LABEL_DOWNLOAD) && (
              <SSHButton
                loading={false}
                tooltip={legacyPOExists ? 'Ship selected item(s)' : PO_WAITING_MESSAGE}
                disabled={isEmpty(selectedRowIds) || !legacyPOExists}
                text="Ship now"
                size="medium"
                variant="outlined"
                onClick={this.getSelectedRowsItem}
              />
            )}
          </Box>
          <CollapsiblePanel title="Pending items" padding={4} defaultOpen caption={`${items.length || 0} items`}>
            <SSHNonPaginatedTable
              columns={PendingItems.columns}
              rows={items}
              isFetching={isFetching}
              tableColumnExtensions={PendingItems.columnExtensions}
              enableSelection
              onSelectionChange={this.onSelectionChange}
              selectedRows={selectedRowIds}
            />
          </CollapsiblePanel>
        </Box>
        <CancelPopupGeneric
          onCancel={() => {
            this.toggleCancelPopup(false)
            toggleEditPopup(showEditPopupItem.itemNumber)
          }}
          onConfirm={this.onCanceledUnits}
          open={cancelPopup}
          popupType={popupType}
          itemDetails={
            cancelPopup && {
              name: selectedCancelItem.name,
              id: selectedCancelItem.itemNumber,
              orderedUnits: selectedCancelItem.orignalfulfilmentQty,
              currentfulfilmentQty: selectedCancelItem.currentfulfilmentQty,
              cancelledUnits: selectedCancelItem.cancelUnits
            }
          }
        />
        <EditItemQuantity
          onCancel={() => toggleEditPopup(null)}
          // eslint-disable-next-line react/jsx-boolean-value
          disabled={true}
          onConfirm={this.confirmCancel}
          open={showEditPopupItem.isEdit}
          itemDetails={this.renderCancelToItems()}
        />
        {showShipmentPopup && (
          <ShipmentPopupContainer
            releaseNumber={releaseActiveTab}
            release={releaseDetails}
            selectedRows={selectedRows}
            resetSelectedRow={this.resetSelectedRow}
            isForceUpdate={isForceUpdate}
            errorMessage={errorMessage}
          />
        )}
      </>
    )
  }
}

export const PendingItemsNaked = PendingItems

PendingItems.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  order: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  showShipmentPopup: PropTypes.bool.isRequired,
  releaseActiveTab: PropTypes.string.isRequired,
  releaseDetails: PropTypes.object.isRequired,
  showEditPopupItem: PropTypes.object.isRequired,
  toggleEditPopup: PropTypes.func.isRequired
}

export default withStyles(styles)(PendingItems)
