/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-wrap-multilines */
import { Grid, Typography, Box } from '@mui/material'
import { withStyles } from '@mui/styles'
import { ReactComponent as LeftArrowIcon } from '../../assets/images/leftArrow.svg'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import SSHButton from '../../components/SSHButton'
import SSHLabel from '../../components/SSHLabel'
import SSHSkeleton from '../../components/SSHSkeleton'
import { allowAccess } from '../../utils/common'
import { ORDER_STATUSES, ROLE_BACK_ACTIONS } from '../../config/genericConstants'
import CancelPopupGeneric from './Popups/ItemQuantity/CancelPopupGeneric'
import { isEmpty } from 'lodash'
import { PO_WAITING_MESSAGE } from '../../config/genericConstantsNew'

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.headerBackground,
    // borderBottom: `1px solid ${theme.palette.primary.columbiaBlue}`,
    padding: `${theme.spacing(1.50)} ${theme.spacing(3.2)}`,
    position: 'fixed',
    width: '100%',
    top: theme.spacing(5),
    zIndex: theme.zIndex.appBar + 1
  },
  title: {
    color: theme.palette.primary.baseTextDark,
    fontWeight: '400',
    fontSize: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    '& p': {
      fontSize: theme.spacing(1.75)
    }
  }
})

const OrderAckCan = ({ classes, releaseDetails, isFetching, goToFulfillment, orderResult, updateItemDetails, legacyPOExists }) => {
  const [isCanceled, setConfirmCancel] = useState(false)

  if (isEmpty(releaseDetails)) {
    return null
  }
  const { basicDetails } = releaseDetails
  const { loadingStatus } = orderResult

  const onConfirmCancel = () => {
    setConfirmCancel(false)
    updateItemDetails({ poNumber: basicDetails?.poNumber, status: ORDER_STATUSES[3].value })
  }

  return (
    <Box className={classes.root}>
      <Grid container spacing={0} alignItems='center'>
        <Grid item xs={10}>
          <Typography variant="h6" className={classes.title}>
            {isFetching ? [
              <SSHSkeleton variant="text" animation="wave" width="50%" />
            ] : [
              <>
                <LeftArrowIcon onClick={goToFulfillment} cursor={'pointer'} /> &nbsp;&nbsp;
                SO number: {basicDetails?.salesOrderNumber}&nbsp;&nbsp;
                <SSHLabel value={basicDetails?.status} />
              </>
            ]}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {!isFetching && allowAccess(ROLE_BACK_ACTIONS.EDIT) && (
            <>
              {(basicDetails?.status === ORDER_STATUSES[0].value || basicDetails?.status === ORDER_STATUSES[1].value) && (
                <SSHButton
                  text="Cancel order"
                  tooltip={legacyPOExists ? 'Cancel all items from this order' : PO_WAITING_MESSAGE}
                  variant="outlined"
                  color="primary"
                  disabled={loadingStatus === ORDER_STATUSES[3].value || !legacyPOExists}
                  loading={loadingStatus === ORDER_STATUSES[3].value}
                  onClick={() => setConfirmCancel(!isCanceled)}
                  size="medium"
                />
              )}
              &nbsp;&nbsp;&nbsp;&nbsp;
              {basicDetails.status === ORDER_STATUSES[0].value && (
                <SSHButton
                  text="Acknowledge"
                  tooltip={legacyPOExists ? 'Acknowledge all items from this order' : PO_WAITING_MESSAGE}
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={loadingStatus === ORDER_STATUSES[1].value || !legacyPOExists}
                  loading={loadingStatus === ORDER_STATUSES[1].value}
                  onClick={() =>
                    updateItemDetails({ poNumber: basicDetails.poNumber, status: ORDER_STATUSES[1].value })
                  }
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
      <CancelPopupGeneric
        onCancel={() => {
          setConfirmCancel(!isCanceled)
        }}
        onConfirm={onConfirmCancel}
        open={isCanceled}
        popupType="order"
      />
    </Box>
  )
}
export const OrderAckCanNaked = OrderAckCan
OrderAckCan.propTypes = {
  order: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(OrderAckCan)
