import React from "react"
import PropTypes from 'prop-types'
import { Breadcrumbs } from "@bluesteel/desktop"
import { MENU_ROUTES as ROUTES } from "../LeftNav/AppRoutes"
import { withStyles } from "@mui/styles"
import { isEmpty } from "lodash"
import { changePage, fetchDataBasedOnPage } from "../../reduxSetup/commonActions/AppConfig"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import clsx from "clsx"
import { DRAWER_WIDTH } from "../LeftNav"
import { SSHAppBar } from "../../components/SSHAppBar"

const styles = (theme => ({
    root: {
        position: 'fixed',
        width: '100%',
        'zIndex': 9,
        '& div:first-child': {
            padding: `0  ${theme.spacing(5)}`
        }
    },
    appBarShift: {
        width: `calc(100% - ${DRAWER_WIDTH - parseInt(theme.spacing(8).replace('px', ''))}px)`,
        marginLeft: 0,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    }
}))

const SSHBreadcrumb = ({ classes, pathName, changePage, fetchDataBasedOnPage, drawerOpen }) => {
    const links = {
        name: "Sam's Supplier Hub",
        path: '/search',
        onClick: (e) => { return changePage('/search') }
    }

    const getPath = (pathName) => {
        let newLink = ROUTES?.find(item => item.url === pathName)
        if (isEmpty(newLink)) {
            newLink = ROUTES?.flatMap(item => item.nav).find(nav => nav.url === pathName)
        }
        let link = null
        if (!isEmpty(newLink)) {
            let keys = []
            if (!isEmpty(newLink?.key)) {
                keys.push({
                    name: newLink?.key,
                    disabled: true
                })
            }
            link = [...keys, {
                name: newLink.name,
                path: newLink.url,
                onClick: (e) => {
                    return changePage(newLink.url)
                }
            }]
            return [links, ...link]
        }
        return [links]
    }


    return (
        <SSHAppBar elevation={0} position="fixed" className={clsx({ [classes.appBarShift]: drawerOpen }, classes.root)}>
            <Breadcrumbs links={getPath(pathName) || []} onRefresh={(e) => { fetchDataBasedOnPage() }} />
        </SSHAppBar>
    )
}

SSHBreadcrumb.propTypes = {
    classes: PropTypes.object,
    pathName: PropTypes.string,
    changePage: PropTypes.func,
    fetchDataBasedOnPage: PropTypes.func
}

const mapDispatchToProps = dispatch =>
    bindActionCreators({ changePage, fetchDataBasedOnPage }, dispatch)

export default connect(null, mapDispatchToProps)(withStyles(styles)(SSHBreadcrumb))
