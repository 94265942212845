import React from 'react'
import { Grid } from '@mui/material'
import { withStyles } from '@mui/styles'
import { SSHTypography } from '../../../../components/SSHTypography'
import SSHTooltip from '../../../../components/SSHTooltip'
import { COLORS } from '../../../../templates/Entry/globalMUIStyles'
import { COLUMNS } from './rowColumns'

const styles = theme => ({
    rowDetailBg: { background: COLORS.brandLightenBg },
    renderRowMargin: { marginLeft: theme.spacing(6.25) },
})

const DealsRowTable = ({ row, classes }) => {

    /**
     * Format values when price is present in the string
     * @param {row, value} row value
     */
    const formatValues = (row, value) => {
        if (value.includes('price')) {
            let rowvalue = (row[value] !== '' && row[value] !== null) ? row[value] : '-';
            return `${rowvalue}`;
        }
        return `${(row[value] !== '' && row[value] !== null) ? row[value] : '-'}`
    }

    const renderRow = ({ row }) => {
        return (
            <Grid container justifyContent='space-evenly' xs={12} spacing={2} className={classes.rowDetailBg}>
                {COLUMNS.map(col => <Grid key={col?.value} item xs={3} spacing={2}>
                    <SSHTooltip placement="left">
                        <SSHTypography fontWeight='fontWeightMedium'>{col.name}</SSHTypography>
                    </SSHTooltip>
                    <SSHTypography>{formatValues(row, col.value)}</SSHTypography>
                </Grid>
                )}
            </Grid>)
    }

    return (
        <Grid container justifyContent='space-between' className={classes.rowDetailBg}>
            <Grid xs={12} container justifyContent='space-between' className={classes.renderRowMargin}>
                {renderRow({ row })}
            </Grid>
        </Grid>
    )
}

export const DealsRowTableNaked = DealsRowTable

export default withStyles(styles)(DealsRowTable)
