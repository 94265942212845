/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
import { Box, Typography } from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import PropTypes from 'prop-types'
import SSHModal from './SSHModal'
import SSHButton from './SSHButton'

const useStyles = makeStyles(theme => ({
  button: {
    width: '100px',
    marginLeft: theme.spacing(1.5)
  }
}))

const SSHAlertDialogWithActions = ({ title, description, callAction }) => {
  const classes = useStyles()

  return (
    <SSHModal size="sm" title={title} showDivider={false} onClose={() => callAction(false)}>
      <Typography>{description}</Typography>
      <Box display="flex" justifyContent="flex-end" alignItems="center" margin="20px 0px">
        <SSHButton
          className={classes.button}
          text="Yes"
          variant="outlined"
          color="primary"
          onClick={() => callAction(true)}
        />
        <SSHButton
          className={classes.button}
          text="No"
          variant="contained"
          color="primary"
          onClick={() => callAction(false)}
        />
      </Box>
    </SSHModal>
  )
}

SSHAlertDialogWithActions.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  callAction: PropTypes.func.isRequired
}

export const SSHAlertDialogWithActionsNaked = SSHAlertDialogWithActions

export default SSHAlertDialogWithActions
