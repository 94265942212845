/* eslint-disable react/destructuring-assignment */
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Box } from '@mui/material'
import {makeStyles} from '@mui/styles'
import { OpenInNewOutlined, MoreVertOutlined } from '@mui/icons-material'
import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SSHButton from '../../components/SSHButton'
import SSHPopover from '../../components/SSHPopover'
import { allowAccess } from '../../utils/common'
import { ROLE_BACK_ACTIONS } from '../../config/genericConstants'
import {
  openSuspendAlertPopup,
  openTerminateAlertPopup,
  setSelectedItemDetails
} from '../../pages/SmartInventory/SmartInventoryAction'

const useStyles = makeStyles(theme => ({
  menuItem: {
    fontSize: '14px',
    color: theme.palette.primary.baseGrey,
    fontWeight: 500,
    width: '80px',
    paddingLeft: theme.spacing(1.25),
    display: 'flex',
    justifyContent: 'flex-start'
  }
}))

const SmartInventoryActionCell = ({
  openSuspendAlertPopup,
  openTerminateAlertPopup,
  setSelectedItemDetails,
  isVendor,
  ...props
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const onMenuOpen = event => {
    setAnchorEl(event.currentTarget)
    setIsPopoverOpen(true)
  }

  const onMenuClose = () => {
    setIsPopoverOpen(false)
  }

  const openSuspendItemAlertPopup = () => {
    setSelectedItemDetails(props?.row?.alertId, props?.row?.itemId, props?.row?.itemDesc)
    openSuspendAlertPopup()
    setIsPopoverOpen(false)
  }

  const openTerminateItemAlertPopup = () => {
    setSelectedItemDetails(props?.row?.alertId, props?.row?.itemId, props?.row?.itemDesc)
    openTerminateAlertPopup()
    setIsPopoverOpen(false)
  }

  const openDashboardLink = () => {
    window.open(props?.row?.dashboardLink, '_blank')
  }

  const classes = useStyles()

  return (
    <Table.Cell {...props}>
      <>
        {props?.row?.dashboardLink && !isVendor && allowAccess(ROLE_BACK_ACTIONS.EDIT) && <SSHButton Icon={OpenInNewOutlined} onClick={openDashboardLink} />}
        {allowAccess(ROLE_BACK_ACTIONS.UPDATE) && <SSHButton Icon={MoreVertOutlined} onClick={onMenuOpen} />}
        <SSHPopover
          anchorEl={anchorEl}
          handleClose={onMenuClose}
          isOpen={isPopoverOpen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}>
          <Box display="flex" flexDirection="column" alignItems="start">
            <SSHButton size="small" text="Pause" onClick={openSuspendItemAlertPopup} className={classes.menuItem} />
            <SSHButton size="small" text="End" onClick={openTerminateItemAlertPopup} className={classes.menuItem} />
          </Box>
        </SSHPopover>
      </>
    </Table.Cell>
  )
}

SmartInventoryActionCell.propTypes = {
  openSuspendAlertPopup: PropTypes.func.isRequired,
  openTerminateAlertPopup: PropTypes.func.isRequired,
  setSelectedItemDetails: PropTypes.func.isRequired,
  isVendor: PropTypes.bool.isRequired
}

export const SmartInventoryActionCellNaked = SmartInventoryActionCell

const mapStateToProps = state => ({
  isVendor: state.UserInfo.isVendor
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ openSuspendAlertPopup, openTerminateAlertPopup, setSelectedItemDetails }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SmartInventoryActionCell)
