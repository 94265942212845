import { Paper } from '@mui/material'
import { withStyles } from '@mui/styles'
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const styles = theme => ({
  root: {
    color: theme.palette.primary.persianBlue,
    fontWeight: 400
  }
})

const AsyncSearchBoxHeader = ({ childProps, classes }) => {
  return (
    <Paper className={clsx(childProps.className, classes.root)} elevation={2}>
      {childProps.children}
    </Paper>
  )
}

AsyncSearchBoxHeader.propTypes = {
  childProps: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AsyncSearchBoxHeader)
