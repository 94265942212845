/* eslint-disable react/no-unused-prop-types */
import { TextField } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import SSHSelectBox from '../../../../components/SSHSelectBox'

const FieldComponent = ({ name, label, hidden, errors, options, type, defaultValues }) => {
  const [selectedOption, setSelectedOption] = useState({
    name: null,
    value: null
  })

  if (hidden) return null

  switch (type) {
    case 'text':
      return (
        <TextField
          fullWidth
          name={name}
          error={!!errors[name]}
          size="small"
          variant="outlined"
          type="text"
          defaultValue={defaultValues[name]}
          inputProps={{
            'data-label': label
          }}
        />
      )

    case 'phone':
      return (
        <TextField
          fullWidth
          name={name}
          error={!!errors[name]}
          size="small"
          variant="outlined"
          type="phone"
          inputProps={{
            'data-label': label
          }}
        />
      )

    case 'select':
      return (
        <SSHSelectBox
          name={name}
          error={!!errors[name]}
          defaultVal={selectedOption}
          onSelect={setSelectedOption}
          options={options}
          size="small"
        />
      )

    case 'email':
      return (
        <TextField
          fullWidth
          error={!!errors[name]}
          name={name}
          size="small"
          variant="outlined"
          type="email"
          inputProps={{
            'data-label': label
          }}
        />
      )

    default:
      return null
  }
}

export const FieldComponentNaked = FieldComponent

FieldComponent.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  inputRef: PropTypes.object.isRequired,
  defaultValues: PropTypes.object.isRequired,
  errors: PropTypes.object,
  options: PropTypes.array,
  hidden: PropTypes.bool,
  mandatory: PropTypes.bool
}

FieldComponent.defaultProps = {
  errors: {},
  options: [],
  hidden: false,
  mandatory: false
}

export default FieldComponent
