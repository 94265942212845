import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Avatar,
  Tooltip
} from '@mui/material'
import { withStyles } from '@mui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changePage, closeLeftDrawer, openLeftDrawer, toggleFeedback, redirectToHomePage } from '../../reduxSetup/commonActions/AppConfig'
import { MENU_ROUTES } from './AppRoutes'
import NavListItem from './NavListItem'
import SSHNotificationIcon from '../../components/SSHNotificationIcon'
import UserInfo from '../UserInfo'
import { openNotificationDrawer } from '../NotificationDrawer/NotificationDrawerActions'
import { Help, DoubleArrowIcon } from './ColoredSvg'
import Logout from '../UserInfo/logout'
import SubMenu from './SubMenu'
import SupplierTypeSelection from './SupplierTypeSelection'

export const DRAWER_WIDTH = 240

const styles = theme => ({
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  noPadRight: {
    paddingRight: 0
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.downloadLabelText,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.downloadLabelText,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      width: theme.replacePX(theme.spacing(6))  + 2
    },
    // position: 'relative',
    zIndex: 1400
  },
  menuButton: {
    marginLeft: 2
  },
  background: {
    background: theme.palette.background.transparentBlack
  },
  notificationListItem: {
    height: theme.spacing(6)
  },
  flip: {
    minWidth:theme.spacing(4),
    '& svg': {
      'transform': 'ScaleX(-1)'
    }
  },
  listItem: {
    color: theme.palette.primary.white,
    fontSize: 16
  },
  appLogo: {
    marginLeft: -3,
    '& .MuiAvatar-root': {
      background: theme.palette.primary.cobalt,
      color: theme.palette.primary.white
    }

  },
  drawerIcon: {
    marginLeft: theme.spacing(0.7),
    marginRight: theme.spacing(0.4)
  },
  tagLine: {
    color: theme.palette.primary.white,
    fontSize: theme.spacing(2.4),
    fontWeight: 500,
    marginLeft: theme.spacing(1),
    lineHeight: ` ${theme.replacePX(theme.spacing(0.05))}`,
    textAlign: 'left',
    '& span': {
      color: theme.palette.primary.light,
      fontSize: theme.spacing(1.5)
    },
    '& h6': {
      lineHeight: `${theme.replacePX(theme.spacing(0.175))}`,
    }
  },
  listItemIcon:{
    minWidth:theme.spacing(4)
  }
})



const LeftNav = ({
  classes,
  closeLeftDrawer,
  access,
  drawerOpen,
  changePage,
  isVendorSelected,
  fetchingRoles,
  isAdmin,
  openLeftDrawer,
  openNotificationDrawer,
  toggleFeedback,
  redirectToHomePage,
  ...restProps
}) => {

  const [anchorEl, setAnchorEl] = useState(null)
  const [hoverList, setHoverList] = useState(undefined)

  /**
   * @description handle hover open
   * @param {*} e
   * @param {*} name
   */
  const handlePopoverOpen = (e, name) => {
    setHoverList(MENU_ROUTES.find(item => item.name === name))
    setAnchorEl(e.currentTarget)
  }

  /**
   * @description hanlde hover close
   */
  const handlePopoverClose = (name) => {
    setHoverList(MENU_ROUTES.find(item => item.name === name))
    setAnchorEl(null)
  }


  /**
   * @description handle click on redirect to page
   * @param {*} link
   */
  const handleClick = (link) => {
    setAnchorEl(null)
    changePage(link)
  }


  /**
   * @description change hover list on submenu click
   * @param {*} name
   */
  const changeHoverList = (name) => {
    setHoverList(MENU_ROUTES.find(item => item.name === name))
  }

  useEffect(() => {
    if (drawerOpen) {
      setAnchorEl(null)
    }
  }, [drawerOpen])

  const open = Boolean(anchorEl)

  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen
          })
        }}>
        <List>
          <div className={classes.drawerHeader}>
            <IconButton color="inherit" edge="start" className={classes.appLogo} onClick={redirectToHomePage}>
              <Avatar variant="rounded">
                SSH
              </Avatar>
              <Typography component="div" className={classes.tagLine}>
                <Typography variant='h6' > Sam’s Supplier Hub™ </Typography>
                <Typography variant='span' >Next gen supplier experience</Typography>
              </Typography>
            </IconButton>
          </div>
          <div>
            <SupplierTypeSelection
              closeMainMenu={setAnchorEl}
              mainMenuStatus={anchorEl} />
          </div>
          {MENU_ROUTES.map(item => {
            return (
              <NavListItem
                key={item.url}
                isAdmin={isAdmin}
                access={access}
                fetchingRoles={fetchingRoles}
                item={item}
                changePage={changePage}
                isDrawerOpen={drawerOpen}
                handlePopoverOpen={handlePopoverOpen}
                handlePopoverClose={handlePopoverClose}
                open={open}
                hoverList={hoverList}
                changeHoverList={changeHoverList}
              />
            )
          })}
        </List>
        <List>
          <Tooltip title={drawerOpen ? 'Collapse menu' : 'Expand menu'} placement='right'>
            <ListItem
              className={classes.background}
              onClick={() => (drawerOpen ? closeLeftDrawer() : openLeftDrawer())}
              data-testid="listItem">
              <ListItemIcon   className={drawerOpen ? classes.flip : ''}>
                <DoubleArrowIcon />
              </ListItemIcon>
              <ListItemText primary="Collapse menu" data-testid="itemText" className={classes.listItem} />
            </ListItem>
          </Tooltip>
          <Tooltip title='User info' placement='right'>
            <ListItem
              className={classes.background}
              data-testid="listItem">
              <ListItemIcon className={classes.listItemIcon}>
                <UserInfo />
              </ListItemIcon>
            </ListItem>
          </Tooltip>
          <Tooltip title='Help' placement='right'>
            <ListItem
              className={clsx(classes.background, classes.ListItem)}
              onClick={toggleFeedback}
              data-testid="help-icon">
              <ListItemIcon className={classes.listItemIcon}>
                <Help />
              </ListItemIcon>
              <ListItemText primary="Help" data-testid="itemText" className={classes.listItem} />
            </ListItem>
          </Tooltip>
          <Tooltip title='Notification' placement='right'>
            <ListItem
              className={clsx(classes.background, classes.notificationListItem)}
              onClick={openNotificationDrawer}
              data-testid="notification-icon">
              <ListItemIcon className={classes.listItemIcon}>
                <SSHNotificationIcon />
              </ListItemIcon>
              <ListItemText primary="Notifications" data-testid="itemText" className={classes.listItem} />
            </ListItem>
          </Tooltip>
          <Tooltip title='Sign out' placement='right'>
            <ListItem
              className={clsx(classes.background)}
              data-testid="signout">
              <ListItemIcon className={classes.listItemIcon}>
                <Logout />
              </ListItemIcon>
            </ListItem>
          </Tooltip>
        </List>
      </Drawer>
      {
        <SubMenu
          open={open}
          hoverList={hoverList}
          handlePopoverClose={handlePopoverClose}
          handleClick={handleClick}
          anchorEl={anchorEl}
          isAdmin={isAdmin}
          fetchingRoles={fetchingRoles}
          access={access}
          pathName={restProps.pathName}
        />
      }
    </>
  )
}

export const LeftNavNaked = LeftNav

LeftNav.propTypes = {
  classes: PropTypes.object.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  access: PropTypes.object.isRequired,
  fetchingRoles: PropTypes.bool.isRequired,
  isVendorSelected: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  closeLeftDrawer: PropTypes.func.isRequired,
  openLeftDrawer: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  openNotificationDrawer: PropTypes.func.isRequired,
  toggleFeedback: PropTypes.func.isRequired,
  redirectToHomePage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  drawerOpen: state.AppConfig.leftDrawer.open,
  access: { ...state.UserInfo.access },
  fetchingRoles: state.UserInfo.fetchingRoles,
  isVendorSelected: state.AppConfig.vendor.selectedVendor.vendorId !== null,
  isAdmin: state.UserInfo.isAdmin,
  pathName: state.router.location.pathname,
  selectedSourceType: state.AppConfig.sourceType
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ closeLeftDrawer, changePage, openLeftDrawer, openNotificationDrawer, toggleFeedback, redirectToHomePage }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(React.memo(LeftNav)))
