import { lighten } from '@mui/material'

const shadows = new Array(22)

export const COLORS = {
  baseBlue: '#0071E9',
  baseBlueLight: '#A6CDF7',
  baseBlueLighter: '#E0EEFE',
  baseBlueDark: '#0060C6',
  kpiOrange: '#F27C00',
  mettalicBlue: '#33577d',
  kpiPurple: '#7551B3',
  kpiPink: '#DE1A73',
  warning: '#FFF8E9',
  notificationBadge: '#B44714',
  successGreen: '#E8F6EE',
  errorRed: '#FAEDE7',
  chipBlue: '#E3F4F8',
  progressGreen: '#29CB9A',
  secondaryProgressBlue: '#CCE2FB',
  greyBase: '#38475D',
  white: '#ffffff',
  softBlue: '#597796',
  border: '#cae0f9',
  widgetBorder: '#CCD5DF',
  brandLighten: '#009BEF',
  // text
  baseText: '#002D5D',
  baseTextLight: '#1A426D',
  baseTextDark: '#00172F',
  disableTextGrey: '#88919E',
  hintTextGrey: '#99ABBE',
  drawerItemText: '#6E94D3',
  brandDarken: '#002751',
  // background
  titleBarBackground: '#F1F5FB',
  defaultBackground: '#EBEEF1',
  selectionHoverBackground: '#F6F9FA',
  tooltipBackground: '#f5f6f7',
  buttonBackground: '#FAFCFF',
  popOverBackground: '#D5DEE4',
  drawerBackground: '#173362',
  pantone: '#C3C8CE',
  saffron: '#FFB72C',
  disabledGray: '#D7DADF',
  haliteBlue: '#0B2744',
  columbiaBlue: '#B7D8EB',
  bottiCelli: '#CDDDE6',
  tumbleWeed: '#F0E2CA',
  springGreen: '#16914E',
  inputLabel: '#1A4B66',
  darkRed: '#D35318',
  smoke: '#F2F5F7',
  greyLighten: '#EBEDEF',
  brandLightenBg: '#E5F1FD',
  brandLightenBg2: '#D9EAFC',
  brandLightenBg3: '#D0E8FF',
  icyLilac: '#E5EFF9',
  tropaz: '#33577D',
  transparentBlack: '#00000000',
  red: '#FA4616',
  statusLowStock: '#A6771D',
  statusInStock: '#0E5E32',
  pending: '#8C6418',
  cobalt: '#024B71',
  mediumCyan: '#0092C1',
  prusianBlue: '#013150',
  merianBlue: '#D9E0E7',
  blue90: '#0067A0',
  cyanDark: '#040A10',
  cyanMediumShade: '#31557C',
  grey10: '#F1F4F6',
  grey80: '#516069',
  grey100: '#323A3E',
  brand80: '#007CB0',
  information60: '#0B71ED',
  lightOrange: '#FFBC77'
}

const styles = {
  typography: {
    fontFamily: ['canada-type-gibson', 'Roboto'].join(','),
    fontSize: 12,
    htmlFontSize: 14,
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      light: COLORS.baseBlueLight,
      lighter: COLORS.baseBlueLighter,
      main: COLORS.baseBlue,
      dark: COLORS.baseBlueDark,
      contrastText: '#A4DBE8',
      red: COLORS.red,
      greenDark: '#006F44',
      greenLight: '#47D7AC',
      blue: COLORS.baseBlue,
      blueLight: '#00AFD7',
      vividBlue: COLORS.baseBlue,
      lightGrayishBlue: '#8492A8',
      blueLight2: '#e2f1fe',
      yellow: '#FFB500',
      orange: '#FFBF1C',
      solitude: '#F5F6F8',
      strongGreen: '#66BC15',
      vividRed: '#FF1010',
      black: '#000000',
      gray: '#808080',
      limegreen: '#32CD32',
      blueDark: COLORS.brandDarken,
      greenParrot: '#A2DDBD',
      brightBlue: '#0061c8',
      persianBlue: COLORS.baseText,
      smoke: COLORS.smoke,
      darkGrey: '#606c7d',
      skyBlue: '#D8EAFC',
      lightCyanBlue: '#cce2fb',
      slateGrey: '#88919e',
      paleBlue: '#DBECFF',
      blackPanther: '#424242',
      downloadLabelText: COLORS.baseTextDark,
      downloadLabelRed: '#f1c5bb',
      blackPearl: COLORS.baseTextDark,
      alertColor: '#742e0d',
      baseGrey: '#38475D',
      drawerBackground: COLORS.drawerBackground,
      headerBackground: COLORS.titleBarBackground,
      defaultBackground: COLORS.defaultBackground,
      mettalicBlue: COLORS.mettalicBlue,
      pantone: COLORS.pantone,
      warning: COLORS.warning,
      saffron: COLORS.saffron,
      white: COLORS.white,
      // regalBlue: COLORS.regalBlue,
      baseTextLight: COLORS.baseTextLight,
      baseText: COLORS.baseText,
      haliteBlue: COLORS.haliteBlue,
      baseBlue: COLORS.baseBlue,
      columbiaBlue: COLORS.columbiaBlue,
      bottiCelli: COLORS.bottiCelli,
      tumbleWeed: COLORS.tumbleWeed,
      errorRed: COLORS.errorRed,
      notificationBadge: COLORS.notificationBadge,
      springGreen: COLORS.springGreen,
      successGreen: COLORS.successGreen,
      icyLilac: COLORS.icyLilac,
      tropaz: COLORS.tropaz,
      disableTextGrey: COLORS.disableTextGrey,
      progressGreen: COLORS.progressGreen,
      softBlue: COLORS.softBlue,
      cobalt: COLORS.cobalt,
      mediumCyan: COLORS.mediumCyan,
      prusianBlue: COLORS.prusianBlue,
      merianBlue: COLORS.merianBlue,
      blue90: COLORS.blue90,
      cyanDark: COLORS.cyanDark,
      cyanMediumShade: COLORS.cyanMediumShade,
      information60: COLORS.information60,
      grey100: COLORS.grey100
    },
    text: {
      primary: COLORS.baseText,
      secondary: COLORS.baseTextLight,
      dropDownItem: '#38475d',
      drawerItem: COLORS.drawerItemText,
      softBlue: COLORS.softBlue,
      lowStock: COLORS.statusLowStock,
      inStock: COLORS.statusInStock,
      pending: COLORS.pending
    },
    background: {
      default: '#EBEEF1',
      lightGray: '#F7F8F9',
      magnolia: '#E7EDF6',
      disabledGray: COLORS.disabledGray,
      brandLighten: COLORS.brandLightenBg3,
      brandLighten2: COLORS.brandLightenBg2,
      chipBlue: COLORS.chipBlue,
      transparentBlack: COLORS.transparentBlack,
      brandLightBlue: COLORS.brandLightenBg
    }
  },
  components: {
    // Name of the component
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '16px',
          fontWeight: 400,
          backgroundColor: COLORS.tooltipBackground,
          color: COLORS.greyBase,
          margin: '26px 33px 36px 118px',
          padding: '8px',
          borderRadius: '4px',
          boxShadow: '0 4px 8px 0 rgba(0, 39, 81, 0.15)',
          border: `solid 1px ${COLORS.widgetBorder}`
        },
        arrow: {
          color: COLORS.tooltipBackground,
          "&::before": {
            border: `solid 1px ${COLORS.widgetBorder}`,
            boxSizing: "border-box"
          }
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          position: 'sticky',
          top: 0,
          zIndex: 999
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.white,
          '& .MuiTableCell-root:first-child': {
            borderLeft: `1px solid ${COLORS.border}`
          },
          '& .MuiTableCell-root': {
            color: COLORS.baseTextDark,
            borderRight: `1px solid ${COLORS.border}`,
            borderBottom: `1px solid ${COLORS.border}`,
            fontSize: '14px',
            padding: '8px'
          }
        },
        head: {
          backgroundColor: COLORS.titleBarBackground,
          color: COLORS.baseTextDark,
          '& .MuiTableCell-head': {
            color: COLORS.baseTextDark,
            fontSize: '14px',
            fontWeight: '400'
          }
        }
      }
    },
    TableNoDataCell: {
      styleOverrides: {
        textContainer: {
          color: COLORS.baseTextLight
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        '&root': {
          padding: 0
        },
        head: {
          border: `1px solid ${COLORS.border}`
        }
      }
    },
    Pagination: {
      styleOverrides: {
        MuiButton: {
          fontWeight: 'normal',
          backgroundColor: COLORS.titleBarBackground,
          color: COLORS.softBlue,
          border: `1px solid ${COLORS.widgetBorder}`,
          width: '31px',
          Height: '30px',
          fontSize: '14px'
        },
        arrowButton: {
          fontWeight: 'normal',
          backgroundColor: COLORS.titleBarBackground,
          color: COLORS.softBlue,
          border: `1px solid ${COLORS.widgetBorder}`,
          width: '31px',
          height: '38px',
          borderRadius: 0
        },
        prev: {
          borderTopLeftRadius: '3px',
          borderBottomLeftRadius: '3px',
          '& .MuiIconButton-label': {
            marginTop: '-4px'
          }
        },
        next: {
          borderTopRightRadius: '3px',
          borderBottomRightRadius: '3px',
          '& .MuiIconButton-label': {
            marginTop: '-4px'
          }
        },
        activeButton: {
          color: COLORS.baseBlue,
          backgroundColor: COLORS.buttonBackground,
          fontWeight: 'bold'
        },
        rowsLabel: {
          color: COLORS.mettalicBlue,
          fontSize: '14px'
        }
      }
    },
    PageSizeSelector: {
      styleOverrides: {
        inputRoot: {
          border: `1px solid ${COLORS.popOverBackground}`,
          '& .MuiSelect-selectMenu': {
            paddingLeft: '6px',
            backgroundColor: COLORS.selectionHoverBackground,
            color: COLORS.mettalicBlue
          }
        },
        selectIcon: {
          color: COLORS.baseBlueDark,
          width: '16px',
          height: '25px',
          right: '4px'
        },
        label: {
          color: COLORS.baseBlue,
          fontSize: '14px'
        }
      }
    },
    Pager: {
      styleOverrides: {
        pager: {
          backgroundColor: COLORS.titleBarBackground,
          justifyContent: 'space-between',
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          border: `1px solid ${COLORS.popOverBackground}`,
          borderRadius: '3px'
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: COLORS.selectionHoverBackground
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.selectionHoverBackground,
          '&.Mui-selected': {
            color: COLORS.baseBlueDark,
            backgroundColor: '#E5F1FD'
          }
        },
        button: {
          color: COLORS.softBlue
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
        },
      },
    }
  },
  shadows: [
    'none',
    '0 1px 0 rgba(0, 0, 0, 0.2)',
    '0 1px 1px rgba(0, 0, 0, 0.2)',
    ...shadows.fill('0 1px 1px rgba(0, 0, 0, 0.4)')
  ],
  shape: { borderRadius: 0 },
  // styleOverrides: {
  //   MuiCssBaseline: {
  //     '@global': {
  //       '@font-face': 'canada-type-gibson'
  //     }
  //   },
  //   MuiListItemIcon: {
  //     root: {
  //       minWidth: 'auto',
  //       paddingRight: 16
  //     }
  //   },
  //   MuiCheckbox: {
  //     root: {
  //       color: COLORS.baseBlue
  //     },
  //     colorPrimary: {
  //       color: COLORS.baseBlue
  //     },
  //     colorSecondary: {
  //       '&.Mui-checked': {
  //         color: COLORS.baseBlue
  //       }
  //     }
  //   },
  // Pagination: {
  //   button: {
  //     fontWeight: 'normal',
  //     backgroundColor: COLORS.titleBarBackground,
  //     color: COLORS.softBlue,
  //     border: `1px solid ${COLORS.widgetBorder}`,
  //     width: '31px',
  //     Height: '30px',
  //     fontSize: '14px'
  //   },
  //   arrowButton: {
  //     fontWeight: 'normal',
  //     backgroundColor: COLORS.titleBarBackground,
  //     color: COLORS.softBlue,
  //     border: `1px solid ${COLORS.widgetBorder}`,
  //     width: '31px',
  //     height: '38px',
  //     borderRadius: 0
  //   },
  //   prev: {
  //     borderTopLeftRadius: '3px',
  //     borderBottomLeftRadius: '3px',
  //     '& .MuiIconButton-label': {
  //       marginTop: '-4px'
  //     }
  //   },
  //   next: {
  //     borderTopRightRadius: '3px',
  //     borderBottomRightRadius: '3px',
  //     '& .MuiIconButton-label': {
  //       marginTop: '-4px'
  //     }
  //   },
  //   activeButton: {
  //     color: COLORS.baseBlue,
  //     backgroundColor: COLORS.buttonBackground,
  //     fontWeight: 'normal'
  //   },
  //   rowsLabel: {
  //     color: COLORS.mettalicBlue,
  //     fontSize: '14px'
  //   }
  // },
  // PageSizeSelector: {
  //   inputRoot: {
  //     border: `1px solid ${COLORS.popOverBackground}`,
  //     '& .MuiSelect-selectMenu': {
  //       paddingLeft: '6px',
  //       backgroundColor: COLORS.selectionHoverBackground,
  //       color: COLORS.mettalicBlue
  //     }
  //   },
  //   selectIcon: {
  //     color: COLORS.baseBlueDark,
  //     width: '16px',
  //     height: '25px',
  //     right: '4px'
  //   },
  //   label: {
  //     color: COLORS.baseBlue,
  //     fontSize: '14px'
  //   }
  // },
  // Pager: {
  //   pager: {
  //     backgroundColor: COLORS.titleBarBackground,
  //     justifyContent: 'space-between'
  //   }
  // },
  //   MuiPickersBasePicker: {
  //     pickerView: {
  //       backgroundColor: COLORS.brandLightenBg2
  //     }
  //   },
  //   MuiPickersModal: {
  //     dialogAction: {
  //       backgroundColor: COLORS.brandLightenBg2
  //     }
  //   },
  //   MuiPickersCalendar: {
  //     weekDayLabel: {
  //       color: COLORS.brandDarken
  //     }
  //   },
  //   MuiPickersCalendarHeader: {
  //     dayLabel: {
  //       color: COLORS.baseText,
  //       fontWeight: 'bold',
  //       fontSize: 14
  //     },
  //     switchHeader: {
  //       borderBottom: `2px solid ${COLORS.brandLighten}`,
  //       paddingBottom: 10
  //     }
  //   },
  //   MuiPickersArrowSwitcher: {
  //     iconButton: {
  //       color: COLORS.baseBlue
  //     }
  //   },
  //   MuiPickersDay: {
  //     day: {
  //       borderRadius: '4px',
  //       color: COLORS.baseBlue
  //     },
  //     daySelected: {
  //       border: `1px solid ${COLORS.baseBlue}`,
  //       backgroundColor: COLORS.greyLighten,
  //       color: COLORS.baseTextDark
  //     },
  //     dayDisabled: {
  //       backgroundColor: COLORS.smoke
  //     }
  //   },
  // MuiTooltip: {
  //   tooltip: {
  //     fontSize: '16px',
  //     fontWeight: 400,
  //     backgroundColor: COLORS.tooltipBackground,
  //     color: COLORS.greyBase,
  //     margin: '26px 33px 36px 118px',
  //     padding: '8px',
  //     borderRadius: '4px',
  //     boxShadow: '0 4px 8px 0 rgba(0, 39, 81, 0.15)',
  //     border: `solid 1px ${COLORS.widgetBorder}`
  //   },
  //   arrow: {
  //     color: COLORS.tooltipBackground,
  //     "&::before": {
  //       border: `solid 1px ${COLORS.widgetBorder}`,
  //       boxSizing: "border-box"
  //     }
  //   }
  // },
  // MuiTableHead: {
  //   root: {
  //     position: 'sticky',
  //     top: 0,
  //     zIndex: 999
  //   }
  // },
  // MuiTableRow: {
  //   root: {
  //     backgroundColor: COLORS.white,
  //     '& .MuiTableCell-root:first-child': {
  //       borderLeft: `1px solid ${COLORS.border}`
  //     },
  //     '& .MuiTableCell-root': {
  //       color: COLORS.baseTextDark,
  //       borderRight: `1px solid ${COLORS.border}`,
  //       borderBottom: `1px solid ${COLORS.border}`,
  //       fontSize: '14px',
  //       padding: '8px'
  //     }
  //   },
  //   head: {
  //     backgroundColor: COLORS.titleBarBackground,
  //     color: COLORS.baseTextDark,
  //     '& .MuiTableCell-head': {
  //       color: COLORS.baseTextDark,
  //       fontSize: '14px',
  //       fontWeight: '400'
  //     }
  //   }
  // },
  // TableNoDataCell: {
  //   textContainer: {
  //     color: COLORS.baseTextLight
  //   }
  // },
  // MuiTableCell: {
  //   '&root': {
  //     padding: 0
  //   },
  //   head: {
  //     border: `1px solid ${COLORS.border}`
  //   }
  // },
  // MuiPopover: {
  //   paper: {
  //     border: `1px solid ${COLORS.popOverBackground}`,
  //     borderRadius: '3px'
  //   }
  // },
  // MuiMenu: {
  //   list: {
  //     backgroundColor: COLORS.selectionHoverBackground
  //   }
  // },
  // MuiListItem: {
  //   root: {
  //     backgroundColor: COLORS.selectionHoverBackground,
  //     '&.Mui-selected': {
  //       color: COLORS.baseBlueDark,
  //       backgroundColor: '#E5F1FD'
  //     }
  //   },
  //   button: {
  //     color: COLORS.softBlue
  //   }
  // }
  // },
  '@global': {
    '.textRed': { color: COLORS.errorRed }
  },
  coreFn: { lighten },
  rgba: (hex, alpha) => {
    let r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    }
    return "rgb(" + r + ", " + g + ", " + b + ")";
  },
  replacePX: (px) => {
    const regEx = /\d*\.?\d+(?:px|%)?/i
    if (String(px).match(regEx)) {
      return typeof px === 'number' ? parseInt(String(px).replace('px', ''), 10) : parseFloat(px.replace('px', ''))
    }
    return px
  }
}

export default styles
