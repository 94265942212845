import { cloneDeep } from 'lodash'
import {
    SET_PRICING_SERVICE_COUNT,
    SET_FILTERS_OPEN,
    SET_SELECTED_TAB,
    SET_FILTERS_OPTIONS,
    DOWNLOAD_PS_REQUEST,
    DOWNLOAD_PS_SUCCESS,
    DOWNLOAD_PS_FAILURE,
    SET_SELECTED_DATE,
    RESET_SELECTED_DATE,
    RESET_SELECTED_TAB,
    RESET_FILTERS,
    UPDATE_FILTERS
} from './PricingService.types'
import { PRICING_SERVICES_TABS, FILTER_IDs } from './commonConstants'
import { displayPastWeekDays } from '../../../utils/common'

const defaultFilters = [
    {
        "filterName": "date",
        "filterValues": []
    },
    {
        "filterName": FILTER_IDs[0],
        "filterValues": []
    },
    {
        "filterName": FILTER_IDs[1],
        "filterValues": []
    },
    {
        "filterName": FILTER_IDs[2],
        "filterValues": []
    }
]

export const INITIAL_STATE = {
    totalPricingServiceItems: 0,
    isFilterOpen: false,
    selectedTab: PRICING_SERVICES_TABS[0],
    selectedDate: displayPastWeekDays(7)[0],
    downloadInProgress: false,
    filtersApplied: defaultFilters,
    filterOptionsPayload: [
        {
            "filterName": "date",
            "filterValues": []
        },
        {
            "filterName": "tab",
            "filterValues": []
        },
        {
            "filterName": "properties",
            "filterValues": []
        }
    ],
    filterOptions: {}
}

/**
 * @description create applied filters array
 * @param {categories, subCategories, suppliers, itemId, state} 
 * @returns [{}] applied filters array
 */
const createFilters = ({ suppliers, states, categories }, state) => {

    const filters = cloneDeep(state.filtersApplied)
    const allSupplierSelected = suppliers?.find(val => val.id === 'All')
    const allStateSelected = states?.find(val => val.id === 'All')
    const allCategorySelected = categories?.find(val => val.id === 'All')

    let supplierFilteredValues = filters?.find(key => key.filterName === FILTER_IDs[0]).filterValues

    let stateFilteredValues = filters?.find(key => key.filterName === FILTER_IDs[1]).filterValues

    let categoryFilteredValues = filters?.find(key => key.filterName === FILTER_IDs[2]).filterValues

    /** always initiaize with empty array before adding new filters */
    supplierFilteredValues.length = 0
    stateFilteredValues.length = 0
    categoryFilteredValues.length = 0

    if (!allSupplierSelected) {
        suppliers?.forEach(element => {
            supplierFilteredValues?.push(element?.value)
        })
    }

    if (!allStateSelected) {
        states?.forEach(element => {
            stateFilteredValues?.push(element?.value)
        })
    }

    if (!allCategorySelected) {
        categories?.forEach(element => {
            categoryFilteredValues?.push(element?.value)
        })
    }

    return filters
}

export default (state = cloneDeep(INITIAL_STATE), action) => {
    switch (action.type) {
        case SET_PRICING_SERVICE_COUNT:
            const { count } = action.payload
            return {
                ...state,
                totalPricingServiceItems: count
            }

        case SET_SELECTED_TAB:
            const { tabName } = action.payload
            return {
                ...state,
                selectedTab: tabName
            }

        case RESET_SELECTED_TAB:
            return {
                ...state,
                selectedTab: INITIAL_STATE.selectedTab
            }

        case SET_FILTERS_OPEN:
            const { isOpen } = action.payload
            return {
                ...state,
                isFilterOpen: isOpen
            }

        case SET_FILTERS_OPTIONS:
            const { options } = action.payload
            return {
                ...state,
                filterOptions: options
            }

        case UPDATE_FILTERS:
            return {
                ...state,
                filtersApplied: createFilters(action.payload, state)
            }

        case SET_SELECTED_DATE:
            const { selected } = action.payload
            return {
                ...state,
                selectedDate: selected
            }

        case RESET_SELECTED_DATE:
            return {
                ...state,
                selectedDate: INITIAL_STATE.selectedDate
            }

        case RESET_FILTERS:
            return {
                ...state,
                filtersApplied: defaultFilters
            }

        case DOWNLOAD_PS_REQUEST:
            return {
                ...state,
                downloadInProgress: true
            }

        case DOWNLOAD_PS_SUCCESS:
        case DOWNLOAD_PS_FAILURE:
            return {
                ...state,
                downloadInProgress: false
            }


        default:
            return { ...state }
    }
}
