import axios from 'axios'
import { get } from 'lodash'
import { APP_CONFIG } from '../../config/appConfigs'
import { CLOSE_ERROR_ALERT, ERROR_OCCURRED } from '../../templates/Error/ErrorHandler.actionTypes'
import { getRequestHeaders } from '../../utils/common'
import { invalidateCookie } from '../../utils/cookies'
import CustomError from '../../utils/CustomError'
import NetworkError from '../../utils/NetworkError'
import { aiStartAPITracking, aiStopAPITracking, aiTrackException } from '../commonActions/TelemetryActions'
import { FAILED_TO_GET_DATA} from '../../config/errorConstants'


/*
 * This is a custom middleware from reduxSetup official example docs
 * https://github.com/reduxjs/redux/blob/master/examples/real-world/src/middleware/api.js
 *
 * Useful to reduce boilerplate code around thunk action creators
 *
 * */


const API_PROXY = APP_CONFIG.ENV().proxyURL
const AUTH_API = APP_CONFIG.ENV().auth
const AUTH_NAME = '/auth/'

/**
 * @description get endpoint from substring
 * @param {*} param0
 * @returns module name
 */
const getAPIEndpoint = ({ endpoint }) => {
  const startStr = endpoint.slice(endpoint.indexOf('v1') + 2)
  const endIndex = startStr.indexOf('/', 1)
  return startStr.substr(0, endIndex + 1).trim()
}

/**
 * @description check base proxy or apim
 * @param {*} param0
 * @returns
 */
const getEndPoint = ({ endpoint }) => {
  let url = API_PROXY
  if (getAPIEndpoint({ endpoint }) === AUTH_NAME) {//auth api
    url = AUTH_API
  }
  return url + endpoint
}


const callApi = async (
  endpoint,
  method,
  data,
  headers,
  getState,
  dispatch,
  requestType,
  isPDF = false,
  isExcel = false,
  isUpload = false,
  isTxt = false,
  options = {}
) => {

  let customHeaders = { ...headers }
  let customOptions = { ...options }


  if (isPDF) {
    customHeaders = {
      ...customHeaders,
      contentType: 'application/pdf, application/json',
      accept: 'application/pdf, application/json'
    }
    customOptions = {
      ...customOptions,
      responseType: 'arraybuffer'
    }
  } else if (isExcel) {
    customHeaders = {
      ...customHeaders,
      contentType: '*/*',
      accept: '*/*'
    }
    customOptions = {
      ...customOptions,
      responseType: 'blob'
    }
  } else if (isUpload) {//only in case upload file as per new axios version
    customHeaders = {
      ...customHeaders,
      'Content-Type': 'multipart/form-data',
    }
  } else if (isTxt) {
    customHeaders = {
      ...customHeaders,
      'Content-Type': 'text/plain',
    }
  }

  // Telemetry tracking start
  aiStartAPITracking({ APIName: requestType })(null, getState)
  const startTimer = performance.now()
  const responseStatus = 'response.status'

  return axios({
    url: getEndPoint({ endpoint }),
    method,
    data,
    headers: { ...customHeaders },
    ...customOptions
  })
    .then(response => {
      // Telemetry tracking end
      const endTimer = performance.now()
      const timeInMS = endTimer - startTimer
      aiStopAPITracking({
        APIName: requestType,
        endpoint,
        method,
        requestParams: data,
        responseCode: response.status,
        responseTime: timeInMS === 0 ? 0 : (timeInMS / 1000).toFixed(2),
        errorText: ''
      })(null, getState)
      if (response.status === 204) {
        return response.data
      }
      if (response.data.status === 'FAIL' || response.status !== 200) {
        if (get(response, 'data.error.errorCode', null) === 401 ||
          (get(response, 'status', null) === 401 || get(response, 'status', null) === '401 Unauthorized')) {
          invalidateCookie()
          window.location = `${APP_CONFIG.ENV().retailLink}ssologout?postLogoutRedirect=${APP_CONFIG.ENV().host
            }&clientId=${APP_CONFIG.ENV().clientId}`
        }
        throw new CustomError(FAILED_TO_GET_DATA, { ...response.data })
      }

      if (!isPDF && !isExcel && !isTxt && typeof response.data !== 'object') {
        throw new CustomError(FAILED_TO_GET_DATA, {
          error: FAILED_TO_GET_DATA
        })
      }

      if ((isPDF || isExcel || isTxt) && response.headers['content-type'] === 'application/json') {
        throw new CustomError(FAILED_TO_GET_DATA, { ...response.data })
      }
      return response.data
    })
    .catch(err => {
      // Telemetry tracking end
      const endTimer = performance.now()
      const timeInMS = endTimer - startTimer
      aiStopAPITracking({
        APIName: requestType,
        endpoint,
        method,
        requestParams: data,
        responseCode: get(err, responseStatus, null) || get(err, 'apiResponse.error.errorCode', null),
        errorText: get(err, 'response.data', null) || get(err, 'apiResponse.error.errorMessage', null),
        responseTime: timeInMS === 0 ? 0 : (timeInMS / 1000).toFixed(2)
      })(null, getState)

      /*
       * https://github.com/axios/axios#handling-errors
       * Accessible keys:
       *  error.response
       *  error.request
       *  error.message
       * */
      if ((get(err, responseStatus, null) === 401 || get(err, responseStatus, null) === '401 Unauthorized')
      ) {
        invalidateCookie()
        window.location = `${APP_CONFIG.ENV().retailLink}ssologout?postLogoutRedirect=${APP_CONFIG.ENV().host
          }&clientId=${APP_CONFIG.ENV().clientId}`
        throw new NetworkError(err.message, err)
      }
      if (err.apiResponse) throw new CustomError(err.message, err.apiResponse)
      else throw new NetworkError(err.message, err)
    })
}

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'Call API'

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
  const callAPI = action[CALL_API]
  if (typeof callAPI === 'undefined') {
    return next(action)
  }

  const { endpoint } = callAPI
  const { types, method, data, payload, isPDF, isExcel, isUpload, isTxt } = callAPI

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.')
  }

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.')
  }
  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.')
  }

  const actionWith = reqData => {
    const finalAction = { ...action, ...reqData }
    delete finalAction[CALL_API]
    return finalAction
  }

  const [requestType, successType, failureType] = types
  if (store.getState().ErrorHandler.showAlert) next(actionWith({ type: CLOSE_ERROR_ALERT }))

  next(actionWith({ type: requestType, payload }))

  // start timer
  return callApi(
    endpoint,
    method,
    data,
    getRequestHeaders(store),
    store.getState,
    store.dispatch,
    requestType,
    isPDF,
    isExcel,
    isUpload,
    isTxt
  )
    .then(response =>
      next(
        actionWith({
          response,
          type: successType,
          payload
        })
      )
    )
    .catch(error => {
      next(
        actionWith({
          payload,
          type: failureType,
          error: error || 'Something bad happened'
        })
      )
      aiTrackException({ error })(null, store.getState)
      next(
        actionWith({
          type: ERROR_OCCURRED,
          error
        })
      )
      // eslint-disable-next-line no-param-reassign
      error.payload = payload
      return error
    })
}
