import { isEmpty } from 'lodash'
import config from '../../config'
import {
  FETCH_PAGINATED_TABLE_DATA_FAILURE,
  FETCH_PAGINATED_TABLE_DATA_REQUEST,
  FETCH_PAGINATED_TABLE_DATA_SUCCESS
} from '../../reduxSetup/commonActions/SSHPaginatedTable.actionTypes'
import { aiFetchDownloadReports, aiSearchDownloadReports } from '../../reduxSetup/commonActions/TelemetryActionsNew'

import { CALL_API } from '../../reduxSetup/middleware/API'
import { getVendorIdFromState } from '../../utils/common'
import {
  SET_DOWNLOAD_ROW_COUNT,
  SEARCH_DOWNLOAD_ITEM_REQUEST,
  CLEAR_DOWNLOAD_SEARCH,
  REPORT_DOWNLOAD
} from './DownloadHistory.types'
import { resetTable } from '../../reduxSetup/commonActions/SSHPaginatedTable'

/**
 * @description Set the table's row count to the passed param value
 * @param {Number} count
 * @returns {function(...[*]=)}
 */
export const setReportsRowCount = ({ count }) => dispatch => {
  return dispatch({
    type: SET_DOWNLOAD_ROW_COUNT,
    payload: { count }
  })
}

/**
 * @description Fetch list of all download requests
 * @returns List of download reports
 */
export const fetchReportRequests = () => (dispatch, getState) => {
  const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
  const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
  const VENDOR_ID = getVendorIdFromState(getState)
  const IS_VENDOR = getState().UserInfo.isVendor

  const FILTER_URL_PATH = IS_VENDOR ? `&vendorId=${VENDOR_ID}` : ''

  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_PAGINATED_TABLE_DATA_REQUEST,
        FETCH_PAGINATED_TABLE_DATA_SUCCESS,
        FETCH_PAGINATED_TABLE_DATA_FAILURE
      ],
      endpoint: `${config.get('downloadDetails')}details?pageNo=${PAGE_NO + 1}&pageSize=${PAGE_SIZE}${FILTER_URL_PATH}`,
      method: 'GET',
      payload: {
        vendorId: VENDOR_ID,
        PAGE_SIZE,
        PAGE_NO,
        pathName: getState().router.location.pathname
      }
    }
  }).then(({ type, response }) => {
    if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS) {
      return dispatch(setReportsRowCount({ count: response.payload.totalCount }))
    }
    dispatch(aiFetchDownloadReports({ type, response }))
    return Promise.resolve()
  })
}

/**
 * @description Search download report with the search query entered.
 * @returns Search download reports
 */
export const searchReportItems = () => (dispatch, getState) => {
  const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
  const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
  const VENDOR_ID = getVendorIdFromState(getState)
  const SEARCH_QUERY = getState().SSHPaginatedTableReducer.searchQuery

  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_PAGINATED_TABLE_DATA_REQUEST,
        FETCH_PAGINATED_TABLE_DATA_SUCCESS,
        FETCH_PAGINATED_TABLE_DATA_FAILURE
      ],
      endpoint: `${config.get('downloadDetails')}/${VENDOR_ID}/${SEARCH_QUERY}`,
      method: 'GET',
      payload: {
        vendorId: VENDOR_ID,
        PAGE_SIZE,
        PAGE_NO,
        pathName: getState().router.location.pathname
      }
    }
  }).then(({ type, response }) => {
    if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS) {
      return dispatch(setReportsRowCount({ count: response.totalCount }))
    }
    dispatch(aiSearchDownloadReports({ type, response }))
    return Promise.resolve()
  })
}

/**
 * @param {String} searchQuery
 * @returns {function(...[*]=)}
 */
export const searchDownlodReport = ({ searchQuery }) => dispatch => {
  if (isEmpty(searchQuery)) {
    return Promise.resolve()
  }

  return Promise.resolve(
    dispatch({
      type: SEARCH_DOWNLOAD_ITEM_REQUEST,
      payload: { searchQuery }
    })
  ).then(dispatch(searchReportItems()))
}

/**
 * @description Reset all search params
 */
export const resetDownloadSearch = () => dispatch => {
  return Promise.resolve(
    dispatch({
      type: CLEAR_DOWNLOAD_SEARCH
    })
  ).then(dispatch(resetTable()))
}

/**
 * @description Download the report to system
 */
export const downloadReportRequest = (ackId, isDownloading) => dispatch => {
  return dispatch({
    type: REPORT_DOWNLOAD,
    payload: { [ackId]: isDownloading }
  })
}
