/* eslint-disable react/jsx-no-bind */
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Box,
    IconButton,
    Grid
} from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import clsx from 'clsx'
import { isEmpty, isArray, filter } from 'lodash'
import SSHNumberInput from '../../../components/SSHNumberInput'
import SSHTooltip from '../../../components/SSHTooltip'
import { columns } from './column'
import { EditIcon } from '../../../templates/LeftNav/ColoredSvg'
import { ReactComponent as Refuse } from '../../../assets/images/Refuse.svg'
import { ReactComponent as Accept } from '../../../assets/images/Accept.svg'
import SSHSelectBox from '../../../components/SSHSelectBox'
import { RETURN_FLOW_NO_DATA, EDIT_CARRIER_TRACKING } from '../../../config/errorConstants'
import { allowAccess } from '../../../utils/common'
import * as gConstants from '../../../config/genericConstants'
import ReflectCarrierTracking from './ReflectCarrierTracking'
import SSHLoadingAnimation from '../../../components/SSHLoadingAnimation'

const styles = theme => ({
    removeIcon: {
        color: theme.palette.primary.softBlue,
        cursor: 'pointer'
    },
    table: {
        '& tr': {
            '& td:not(:nth-child(9)):not(:nth-child(10)):not(:nth-child(11)):not(:nth-child(12))': {
                backgroundColor: theme.palette.primary.smoke
            }
        }
    },
    focus: {
        "& .Mui-focused": {
            'box-shadow': `0px 0px 0px 2px ${theme.palette.primary.blueLight}, 0px 0px 12px ${theme.rgba(theme.palette.primary.blueLight, 0.75)}`
        },
    },
    root: {
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    },
    iconBtn: {
        padding: 3,
        fontSize: '1.4em',
        lineHeight: '1em',
        marginLeft: theme.spacing(1),
        color: theme.palette.primary.baseBlue
    },
    paddingLeft: {
        paddingLeft: `${theme.spacing(2)}px !important`
    },
    loadingIconPadding: {
        padding: `${theme.spacing(1)}px !important`
    }
})

const StyledTableCell = withStyles(() => ({
    body: {
        display: 'inline-flex',
        width: '100%'
    },
}))(TableCell);

const msgReturnQtyZero = "Returned qty cannot be 0"
const msgReturnQtyGreater = "Returned qty is greater then pending qty"
const msgReturnQtyNegative = "Returned qty cannot be negative"
const msgReturnQtyDecimal = "Returned qty should be an integer"

class ReturnItemsTable extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            editMode: false,
            editedRow: 0,
            carrierMethods: [],
            isCarrierError: false,
            isTrackingError: false,
            isReturnOrderQtyError: false,
            isReflectAll: false,
            reflectAks: 0,
            currentItem: null
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { carrierMethods } = this.props

        if (!isEmpty(carrierMethods) && isArray(carrierMethods) && prevState.carrierMethods.length !== carrierMethods.length + 1) {
            const newCarrierMethods = carrierMethods.map(cm => ({
                name: cm.carrierMethodName,
                value: cm.carrierMethodId
            }))
            this.setState({
                carrierMethods: newCarrierMethods.concat({ name: gConstants.OTHERS, value: "-1" })
            })
        }
    }


    /**
      * @description toggle edit mode
      * @param {Object} item
      */
    toggleEditMode = (item) => {
        const { editMode } = this.state
        this.setState({
            editMode: !editMode,
            editedRow: item.itemNo
        })
    }

    /**
     * @description reset edit
     * @param {*} item
     */
    resetEdit = async (item) => {
        const { resetCarrierTracking } = this.props
        this.setState({
            isCarrierError: false,
            isTrackingError: false,
            isReturnOrderQtyError: false
        }, () => {
            resetCarrierTracking({ item })
            this.toggleEditMode(item)
        })
    }

    /**
     * @description update carrier tracking conirmation
     * @param {*} item
     */
    updateCarrierTracking = async (item) => {
        const { onConfirm } = this.props
        if (!this.validateCarrierTracking(item)) {

            if (await this.showReflectAll()) {
                return this.setState({ isReflectAll: true, currentItem: item })
            }

            await onConfirm(item)
            this.toggleEditMode(item.itemNo)
        }
    }


    /**
     * @description validiate carrier tracking
     * @param {*} item
     * @returns boolean
     */
    validateCarrierTracking = (item) => {
        this.setState({
            isCarrierError: this.checkIsOtherCarrier() ? isEmpty(item?.carrierNameOthers) : isEmpty(item?.carrierName),
            isTrackingError: isEmpty(item?.trackingNo),
            isReturnOrderQtyError: isEmpty(String(item?.returnedQty).trim()) || parseInt(item?.orderedQty - item?.returnedQtyRunningTotal, 10) < parseInt(String(item?.returnedQty).trim())
        })
        return (this.checkIsOtherCarrier() ? isEmpty(item?.carrierNameOthers) : isEmpty(item?.carrierName)) || isEmpty(item.trackingNo) || isEmpty(item?.returnedQty) || this.validateReturnQty(item).isError
    }

    /**
     * @description render edit mode
     * @param {*} item
     * @returns object
     */
    renderEditMode = (item) => {
        const { classes } = this.props
        const { editMode, editedRow } = this.state

        if (editMode && editedRow === item.itemNo) {
            return <TableCell align='center'>
                <Grid container justifyContent="center" alignItems="flex-start">
                    <Grid item className={classes.actions}>
                        <SSHTooltip title="Save">
                            <IconButton className={clsx(classes.iconBtn, 'save')} size="medium" onClick={() => this.updateCarrierTracking(item)} disabled={false}>
                                <Accept fontSize="inherit" />
                            </IconButton>
                        </SSHTooltip>
                        <SSHTooltip title="Cancel">
                            <IconButton className={clsx(classes.iconBtn, 'cancel')} size="medium" onClick={() => this.resetEdit(item)} disabled={false}>
                                <Refuse fontSize="inherit" />
                            </IconButton>
                        </SSHTooltip>
                    </Grid>
                </Grid>
            </TableCell>
        }
        return (
            <TableCell align='center'>
                <SSHTooltip title={EDIT_CARRIER_TRACKING}>
                    <IconButton className={classes.iconBtn} onClick={() => this.toggleEditMode(item)} disabled={editMode}>
                        <EditIcon />
                    </IconButton>
                </SSHTooltip>
            </TableCell>
        )
    }


    /**
     * @description render edit tracking
     * @param {*} item
     * @returns object
     */
    renderEditTracking = (item) => {
        const { classes, onChangeTrackingNumber } = this.props
        const { editMode, editedRow, isTrackingError } = this.state
        if (editMode && editedRow === item.itemNo) {
            return (
                <SSHNumberInput
                    size="small"
                    variant="outlined"
                    type="text"
                    error={isTrackingError}
                    onChange={(e) => onChangeTrackingNumber({ e, item })}
                    className={clsx(classes.focus, classes.root)}
                    value={
                        item.trackingNo
                    }
                />)
        }

        return item.trackingNo
    }

    /**
     * @description validate return qty
     * @param {*} rQty 
     * @returns boolean
     */
    validateReturnQty = (rQty) => {
        if (parseInt(rQty.returnedQty, 10) == 0) {
            return { message: msgReturnQtyZero, isError: true }
        }
        else if (parseInt(rQty.returnedQty, 10) > (parseInt(rQty.orderedQty) - parseInt(rQty.returnedQtyRunningTotal))) {
            return { message: msgReturnQtyGreater, isError: true }
        }
        else if (parseInt(rQty.returnedQty, 10) < 0) {
            return { message: msgReturnQtyNegative, isError: true }
        }
        else if (!(rQty.returnedQty % 1 === 0)) {
            return { message: msgReturnQtyDecimal, isError: true }
        }
        return { message: '', isError: false }
    }

    /**
 * @description render edit return order qty
 * @param {*} item
 * @returns object
 */
    renderEditOrderRecievedQty = (item) => {
        const { classes, onChangeOrderRecievedQty } = this.props
        const { editMode, editedRow, isReturnOrderQtyError } = this.state
        if (editMode && editedRow === item.itemNo) {
            return (
                <SSHNumberInput
                    size="small"
                    variant="outlined"
                    type="number"
                    error={isReturnOrderQtyError || this.validateReturnQty(item).isError}
                    helperText={this.validateReturnQty(item).message}
                    onChange={(e) => onChangeOrderRecievedQty({ e, item })}
                    className={clsx(classes.focus, classes.root)}
                    value={
                        item.returnedQty
                    }
                />)
        }

        return item.returnedQty
    }

    renderEditCarrierOthers = (item) => {
        const { classes, onSelectOtherCarrier } = this.props
        const { editMode, editedRow, isCarrierError } = this.state
        if (editMode && editedRow === item.itemNo) {
            return (
                <StyledTableCell>
                    {this.renderEditCarrier(item)}
                    <SSHNumberInput
                        size="small"
                        variant="outlined"
                        error={this.checkIsOtherCarrier() ? isCarrierError : false}
                        onChange={(e) => onSelectOtherCarrier({ e, item })}
                        placeholder="Other carrier"
                        type="text"
                        className={clsx(classes.focus, classes.root, classes.paddingLeft)}
                        value={
                            item.carrierNameOthers
                        }
                    />
                </StyledTableCell>
            )
        }
        return (<TableCell>{item.carrierNameOthers}</TableCell>)
    }

    /**
     * @description render edit carrier
     * @param {*} item
     * @returns object
     */
    renderEditCarrier = (item) => {
        const { selectCarrier, selectedCarrier } = this.props
        const { editMode, editedRow, carrierMethods, isCarrierError } = this.state
        if (editMode && editedRow === item.itemNo) {
            return (
                <>
                    <SSHSelectBox
                        size="small"
                        error={!this.checkIsOtherCarrier() ? isCarrierError : false}
                        onSelect={({ name, value }) => selectCarrier({ name, value }, { item })}
                        defaultVal={item?.carrierName !== null ? selectedCarrier : { name: '', value: undefined }}
                        options={carrierMethods}
                    />
                </>
            )
        }
        return !this.checkIsOtherCarrier() ? item.carrierName : ''
    }


    /**
     * @description slice columns actions for rback accesss
     * @returns  table cell
     */
    sliceColumn = () => {
        const { ordersList } = this.props
        let cols = columns
        if (!allowAccess(gConstants.ROLE_BACK_ACTIONS.EDIT)) {
            cols = cols.slice(0, columns.length - 1)
        } else if (String(ordersList?.status).toLowerCase() !== String(gConstants.ORDER_STATUSES[0].value).toLowerCase() && String(ordersList?.status).toLowerCase() !== String(gConstants.ORDER_STATUSES[4].value).toLowerCase()) {
            cols = cols.slice(0, columns.length - 3)
        }
        return cols.map(col => {
            return <TableCell>
                {col.title}
            </TableCell>
        })
    }

    /**
     * @description show reflect all message box
     * @returns length
     */
    showReflectAll = async () => {
        const { ordersList } = this.props
        const { reflectAks } = this.state
        let len = 0
        if (ordersList?.orderLines?.length > 1) {
            len = filter(ordersList.orderLines, (order) => {
                return isEmpty(order.carrierName) || isEmpty(order.trackingNo) || isEmpty(order.returnedQty)
            }).length
        }
        return len > 0 && reflectAks === 0
    }

    /**
     * @description close message box
     * @param {*} isAll
     */
    closeReflectAll = (isAll) => {
        this.setState({
            isReflectAll: isAll,
            reflectAks: 1
        })
    }


    /**
     * @description save reflect all carrier and tracking for order line
     * @param {*} isAll
     */
    saveReflectAll = async (isAll) => {
        const { onConfirm } = this.props
        const { currentItem } = this.state
        await onConfirm(currentItem, isAll)
         this.toggleEditMode(currentItem.itemNo)
         this.setState({ isReflectAll: false, reflectAks: 1 })
    }

    checkStatus = ({ status }) => {
        return (String(status).toLowerCase() === String(gConstants.ORDER_STATUSES[0].value).toLowerCase() || String(status).toLowerCase() === String(gConstants.ORDER_STATUSES[4].value).toLowerCase())
    }

    checkIsOtherCarrier = () => {
        const { selectedCarrier } = this.props
        return String(selectedCarrier?.name).toLowerCase() === String(gConstants.OTHERS).toLowerCase()
    }


    render = () => {

        const { classes, ordersList } = this.props
        const { isReflectAll } = this.state

        return (
            <Box>
                <TableContainer>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {this.sliceColumn()}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                ordersList?.orderLines?.length === 0 ? RETURN_FLOW_NO_DATA :
                                    ordersList?.orderLines?.map((item, index) => (
                                        <TableRow>
                                            <TableCell align='right'>
                                                {index + 1}
                                            </TableCell>
                                            <TableCell align='right'>{item?.itemNo}</TableCell>
                                            <TableCell >{item?.itemDesc}</TableCell>
                                            <TableCell align='right'>{item?.upcNo}</TableCell>
                                            <TableCell >{item?.vendorStockId}</TableCell>
                                            <TableCell >{item?.returnReason}</TableCell>
                                            <TableCell >{parseInt(item?.orderedQty, 10)}</TableCell>
                                            <TableCell >{parseInt(item?.orderedQty, 10) - parseInt(item?.returnedQtyRunningTotal, 10)}</TableCell>
                                            {
                                                this.checkStatus({ status: item.status }) &&
                                                <TableCell valign >{
                                                    this.renderEditOrderRecievedQty(item)
                                                }
                                                </TableCell>
                                            }
                                            {
                                                this.checkStatus({ status: item.status }) && !this.checkIsOtherCarrier() &&
                                                <TableCell >{this.renderEditCarrier(item)}</TableCell>
                                            }
                                            {
                                                this.checkStatus({ status: item.status }) && this.checkIsOtherCarrier() &&
                                                this.renderEditCarrierOthers(item)
                                            }
                                            {
                                                this.checkStatus({ status: item.status }) &&
                                                <TableCell valign >{
                                                    this.renderEditTracking(item)
                                                }
                                                </TableCell>
                                            }
                                            {
                                                allowAccess(gConstants.ROLE_BACK_ACTIONS.EDIT) &&
                                                this.checkStatus({ status: ordersList?.status })
                                                && this.checkStatus({ status: item.status }) &&
                                                parseInt(item?.orderedQty, 10) - parseInt(item?.returnedQtyRunningTotal, 10) > 0 &&
                                                this.renderEditMode(item)
                                            }
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {isEmpty(ordersList) && <Box component="div" align='center' className={classes.loadingIconPadding}>
                    <SSHLoadingAnimation size={80} />
                </Box>}
                {isReflectAll && <ReflectCarrierTracking closeReflectAll={this.closeReflectAll} saveReflectAll={this.saveReflectAll} />}
            </Box>
        )
    }
}


ReturnItemsTable.propTypes = {
    classes: PropTypes.object.isRequired,
    selectedCarrier: PropTypes.object,
    carrierMethods: PropTypes.array,
    // eslint-disable-next-line react/no-unused-prop-types
    trackingNumber: PropTypes.string,
}

ReturnItemsTable.defaultProps = {
    carrierMethods: [],
    trackingNumber: ''
}

export default withStyles(styles)(ReturnItemsTable)
export const ReturnItemsTableNaked = ReturnItemsTable
