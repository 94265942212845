
/**
 * @description enabled/disabled access link
 * @param {*} item
 * @param {*} isAdmin
 * @param {*} access
 * @returns
 */
export const disabledLink = (item, isAdmin, access) => {
    let DISABLED
    if (item.access === 'all' || (item.access === 'admin' && isAdmin)) {
        DISABLED = false
    } else if (!DISABLED) {
        DISABLED = !access[item.url.slice(1)]
    }
    return DISABLED
}

/**
 * @description get source types options 
 * @param {string} sourceType 
 * @returns {string}
 */
export const getSourceOptions = (sourceType) => {
    return String(sourceType?.type.find(x => x.id === sourceType?.selected)?.source).toLowerCase()
}