import React from 'react'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SSHTooltip from '../../components/SSHTooltip'
import { goToClubDetails } from '../../pages/Club/SaleForecast/ForecastAction'
import { ArrayFormatter } from './CustomFieldTypeProvider'

const styles = theme => ({
    link: {
        color: theme.palette.primary.blue,
        cursor: 'pointer'
    }
})

const SaleForecastItemCell = ({ classes, goToClubDetails, ...props }) => {

    const tooltip = ''
    const onClick = () => {
        return goToClubDetails({ itemDetails: props.row })
    }

    return (
        <Table.Cell {...props}>
            <SSHTooltip title={tooltip} placement="top-start">
                <Typography variant="body2" className={classes.link} onClick={onClick}>
                    <ArrayFormatter value={props.value} noTooltip />
                </Typography>
            </SSHTooltip>
        </Table.Cell>
    )
}

SaleForecastItemCell.propTypes = {
    classes: PropTypes.object.isRequired,
    props: PropTypes.object.isRequired
}


const mapDispatchToProps = dispatch => bindActionCreators({ goToClubDetails }, dispatch)

export default connect(null, mapDispatchToProps)(withStyles(styles)(SaleForecastItemCell))

export const SaleForecastItemCellNaked = SaleForecastItemCell