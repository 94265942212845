import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Grid, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { omit, findIndex, find } from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SSHNumberInput from '../../components/SSHNumberInput'
import { setRequestPayload } from '../../pages/InTransitInventory/InTransitInventoryAction'

const styles = theme => ({
  root: {
    '& .MuiOutlinedInput-input': {
      textAlign: 'end'
    }
  }
})

const EditableCell = ({ classes, error, restProps }) => {
  const inputField = useRef()
  const hasError = !!error

  const onChange = () => {
    const { value } = inputField.current
    const { row, date, setRequestPayload } = restProps
    const reqObj = { date, quantity: value }
    const matchedIndex = findIndex(row.inTransitInventory, val => val.date === date)
    if (matchedIndex === -1) {
      row.inTransitInventory.push(reqObj)
    } else {
      row.inTransitInventory.map(val => {
        if (val.date === date) {
          val.quantity = value
        }
      })
    }
    return setRequestPayload({ itemId: row.itemId, inTransitInventory: row.inTransitInventory })
  }

  return (
    <Table.Cell {...restProps} style={{ padding: 0, border: 'none' }}>
      <SSHNumberInput
        size="small"
        error={!!hasError}
        inputRef={inputField}
        className={classes.root}
        onChange={onChange}
        onKeyDown={event => ['e', 'E', '+', '-', '.'].includes(event.key) && event.preventDefault()}
        defaultValue={displayQuantity(restProps)}
      />
    </Table.Cell>
  )
}

const NonEditableCell = ({ restProps }) => {
  return (
    <Table.Cell {...restProps}>
      <Grid container justifyContent='flex-end'>
        <Grid item>
          <Typography variant="body2">{displayQuantity(restProps)}</Typography>
        </Grid>
      </Grid>
    </Table.Cell>
  )
}

const displayQuantity = (restProps) => {
  const { row, date } = restProps
  return find(row.inTransitInventory, val => val.date === date)?.quantity || 0
}

class CustomEditableCell extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { error: false }
  }

  render = () => {
    const { classes, editMode } = this.props
    const { error } = this.state
    const restProps = omit(this.props, ['classes'])

    if (editMode) {
      return (
        <>
          <EditableCell
            classes={classes}
            error={error}
            restProps={restProps}
          />
        </>
      )
    }
    return <NonEditableCell classes={classes} restProps={restProps} />
  }
}

CustomEditableCell.propTypes = {
  date: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  editMode: state.InTransitInventory.editTable
})

const mapDispatchToProps = dispatch => bindActionCreators({ setRequestPayload }, dispatch)

export const CustomEditableCellNaked = CustomEditableCell

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomEditableCell))
