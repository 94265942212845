import moment from 'moment'
import CustomError from './CustomError'



/**
 * @description get cookie from browser storage
 * @param {*} cname 
 * @returns {string} value of cookie 
 */
export const getCookie = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';').map(cookie => cookie.trim());

  for (let cookie of cookieArray) {
    if (cookie.startsWith(name)) {
      return cookie.substring(name.length);
    }
  }
  return ''
}

/**
 * Function used to invalidate the cookie being used for SSH
 */
export const invalidateCookie = () => {
  document.cookie = `userInfo=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`
  document.cookie = `customerToken=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`
  document.cookie = `appUserRole=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`
  document.cookie = `appUserRoleInfo=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`
  document.cookie = `sessionId=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`
  document.cookie = `isSuperAdmin=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`
  document.cookie = `iamToken=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`
}

/**
 *
 * @param {Object} json
 * @returns {string}
 */
const encryptJSON = json => {
  return btoa(JSON.stringify(json))
}

/**
 *
 * @param {String} cookieName
 * @returns {string|object}
 */
const decryptCookie = cookieName => {
  const cookieData = getCookie(cookieName)
  let response = ''
  if (cookieData.length === 0) return response

  try {
    response = JSON.parse(atob(cookieData))
  } catch (e) {
    throw new CustomError('Problem with response')
  }
  return response
}

export const writeUserInfoCookie = response => {
  const expiry = moment
    .utc()
    .add(12, 'hours')
    .toString()
  document.cookie = `userInfo = ${encryptJSON(response.userInfo)};expires=${expiry}; path=/`
  document.cookie = `appUserRole = ${encryptJSON(response.appUserRole)};expires=${expiry}; path=/`
  document.cookie = `customerToken = ${encryptJSON(response.customerToken)};expires=${expiry}; path=/`
  document.cookie = `appUserRoleInfo = ${encryptJSON(response.appUserRoleInfo)};expires=${expiry}; path=/`
  document.cookie = `sessionId = ${encryptJSON(response.sessionId)};expires=${expiry}; path=/`
  document.cookie = `isSuperAdmin = ${encryptJSON(response.superAdmin)};expires=${expiry}; path=/`
  document.cookie = `iamToken = ${encryptJSON(response.iamToken)};expires=${expiry}; path=/`
}

export const getUserDataFromCookie = () => {
  const userInfo = decryptCookie('userInfo')
  const customerToken = decryptCookie('customerToken')
  const sessionId = decryptCookie('sessionId')
  const appUserRole = decryptCookie('appUserRole')
  const appUserRoleInfo = decryptCookie('appUserRoleInfo')
  const superAdmin = decryptCookie('isSuperAdmin')
  const iamToken = decryptCookie('iamToken')

  return {
    userInfo,
    customerToken,
    sessionId,
    appUserRole,
    appUserRoleInfo,
    superAdmin,
    iamToken
  }
}
