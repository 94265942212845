import React, { useEffect } from 'react'
import { IconButton } from '@mui/material'
import { makeStyles,withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { openNotificationDrawer, disablePlay } from '../templates/NotificationDrawer/NotificationDrawerActions'
import { SSHBadge } from './SSHBadge'
import { NotificationIcon } from '../templates/LeftNav/ColoredSvg'
import Ting from '../assets/audio/ting.mp3'
import { isEmpty } from 'lodash'

const StyledNotificationIcon = withStyles(theme => ({
  root: {
    color: theme.palette.text.drawerItem
  }
}))(NotificationIcon)

const useStyle = makeStyles(theme => ({
  container: {
    marginRight: -11,
    marginLeft: -13
  },
  badge: {
    backgroundColor: theme.palette.primary.notificationBadge
  }
}))


let audio = null

const SSHNotificationIcon = ({ count, openNotificationDrawer, play, disablePlay }) => {

  useEffect(() => {
    if (play) {
      try {
        if (isEmpty(audio?.src)) {
          audio = new Audio(Ting)
        }
        disablePlay()
        audio.play()
      } catch (error) {
        disablePlay()
      }
    }
  }, [play])

  const classes = useStyle()
  return (
    <div className={classes.container}>
      <IconButton
        onClick={openNotificationDrawer}
        data-testid="SSHNotificationIcon"
        size="large">
        <SSHBadge badgeContent={count} classes={{ badge: classes.badge }}>
          <StyledNotificationIcon />
        </SSHBadge>
      </IconButton>
    </div>
  );
}

SSHNotificationIcon.propTypes = {
  count: PropTypes.number.isRequired,
  openNotificationDrawer: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  count: state.NotificationDrawer.count,
  play: state.NotificationDrawer.play
})

export const SSHNotificationIconNaked = SSHNotificationIcon

const mapDispatchToProps = dispatch => bindActionCreators({ openNotificationDrawer, disablePlay }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SSHNotificationIcon)
