import { isArray, isEmpty } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { taskStatusParser } from '../../utils/parsers'
import {
  BULK_UPLOAD_FILES_FAILURE,
  BULK_UPLOAD_FILES_REQUEST,
  BULK_UPLOAD_FILES_SUCCESS,
  CHECK_EXPORT_STATUS_FAILURE,
  CHECK_EXPORT_STATUS_REQUEST,
  CHECK_EXPORT_STATUS_SUCCESS,
  CLEAR_SHIP_NODE,
  CLEAR_VENDOR_SEARCH,
  CLOSE_LEFT_DRAWER,
  FETCH_SHIP_NODES_FAILURE,
  FETCH_SHIP_NODES_REQUEST,
  FETCH_SHIP_NODES_SUCCESS,
  HIDE_BULK_UPDATE_POPUP,
  HIDE_EXPORT_POPUP,
  HIDE_FULFILLMENT_TABS,
  HIDE_TOAST_MESSAGE,
  INITIATE_EXPORT_FAILURE,
  INITIATE_EXPORT_REQUEST,
  INITIATE_EXPORT_SUCCESS,
  OPEN_LEFT_DRAWER,
  RETRY_EXPORT_FILE_FAILURE,
  RETRY_EXPORT_FILE_REQUEST,
  RETRY_EXPORT_FILE_SUCCESS,
  SEARCH_VENDOR_FAILURE,
  SEARCH_VENDOR_REQUEST,
  SEARCH_VENDOR_SUCCESS,
  SELECT_SHIP_NODE,
  SELECT_VENDOR,
  SET_PARTNER_LIST,
  SHOW_BULK_UPDATE_POPUP,
  SHOW_EXPORT_POPUP,
  SHOW_TOAST_MESSAGE,
  SET_MODULE_TYPE,
  SKIP_SUPPLIER_SELECTION,
  TOGGLE_ATMT_FEEDBACK,
  TOGGLE_SOURCE_TYPE,
  GET_SOURCE_TYPE,
  SET_SOURCE_TYPE
} from '../commonActions/AppConfig.actionTypes'
import { SUPPORTED_SOURCE } from '../../config/genericConstantsNew'

export const INITIAL_STATE = {
  leftDrawer: { open: false },
  toastMessage: { open: false, message: null },
  vendor: {
    isFetching: false,
    searchText: null,
    vendorList: [],
    rolesList: [],
    isSupplierPresent: false,
    FFCO: false,
    hidePastDue: false,
    hideDueToday: false,
    FFPL: false,
    selectedVendor: { vendorId: null, displayName: null }
    // selectedVendor: { vendorId: '29579', displayName: 'ESSENDANT CO' },
  },
  shipNodes: {
    isFetching: false,
    list: [],
    selected: {
      shipNodeId: '',
      shipNodeName: ''
    }
  },
  bulkUpdate: {
    showPopup: false,
    isFulfillment: false,
    isUploading: false,
    isPenalty: false,
    isPenaltyApproval: false,
    isArApproval: false,
    isInTransitInventory: false,
    isClubSaleForecast: false,
    isDailyCapacity: false,
    ackIds: []
  },
  exportOrders: {
    moduleType: null,
    showPopup: false,
    isFetching: false,
    ackIds: []
  },
  isToggleAtmtFeedback: false,
  sourceType: {
    selected: 1,
    type: [{
      id: 1,
      name: 'Ecommerce',
      source: SUPPORTED_SOURCE[0],
      alias: '.Com'
    }, {
      id: 2,
      name: 'Club',
      source: SUPPORTED_SOURCE[1],
      alias: ''
    }]
  }
}

/**
 * @description get sourcetype and update the state on change
 * @returns {updated state}
 */
const getSourcetypeState = (sourceType, state) => {
  const type = sourceType.map((val) => {
    if (val.type === SUPPORTED_SOURCE[0]) { //SourceType = "DSV"
      return {
        id: 1,
        name: 'Ecommerce',
        source: val.type,
        alias: '.Com'
      }
    } else if (val.type === SUPPORTED_SOURCE[1]) { //SourceType = "CLUB"
      return {
        id: 2,
        name: 'Club',
        source: val.type,
        alias: ''
      }
    }
  })
  return {
    ...state,
    sourceType: {
      ...state.sourceType,
      selected: type[0]?.id || state.sourceType?.selected,
      type: isEmpty(type) ? state.sourceType?.type : type
    },
    vendor: {
      ...state.vendor,
      rolesList: sourceType
    }
  }
}

/**
 * @description set source params
 * @param {string} pathname 
 *  @param {object} state 
 * @returns  {}
 */
const setSourceByParams = ({ pathname, state }) => {
  const regex = /club/i
  let type = []
  if (!pathname.match(regex)) {
    type.push({
      id: 1,
      name: 'Ecommerce',
      source: SUPPORTED_SOURCE[0],
      alias: '.Com'
    })
  } else {
    type.push({
      id: 2,
      name: 'Club',
      source: SUPPORTED_SOURCE[1],
      alias: ''
    })
  }

  return {
    ...state,
    sourceType: {
      ...state.sourceType,
      selected: !pathname.match(regex) ? state.sourceType.type[0]?.id : state.sourceType.type[1]?.id
    }
  }

}

export default function (state = cloneDeep(INITIAL_STATE), action = {}) {
  switch (action.type) {
    case OPEN_LEFT_DRAWER:
      return {
        ...state,
        leftDrawer: { open: true }
      }

    case CLOSE_LEFT_DRAWER:
      return {
        ...state,
        leftDrawer: { open: false }
      }

    case SEARCH_VENDOR_REQUEST:
      return {
        ...state,
        vendor: {
          ...state.vendor,
          searchText: action.payload.query,
          isFetching: true
        }
      }

    case SEARCH_VENDOR_SUCCESS:
      if (state.vendor.searchText !== action.payload.query) return { ...state }

      return {
        ...state,
        vendor: {
          ...state.vendor,
          isFetching: false,

          vendorList: action.response.payload.vendorList.map(vendor => ({
            ...vendor,
            name: `${vendor.displayName} (${vendor.vendorId})`,
            value: vendor.vendorId
          }))
        }
      }

    case CLEAR_VENDOR_SEARCH:
    case SEARCH_VENDOR_FAILURE:
      return {
        ...state,
        vendor: {
          ...state.vendor,
          isFetching: false,
          vendorList: []
        }
      }

    case SET_PARTNER_LIST:
      return {
        ...state,
        vendor: {
          ...state.vendor,
          vendorList: action.payload.partnerList
        }
      }

    case SELECT_VENDOR:
      return {
        ...state,
        vendor: {
          ...state.vendor,
          isFetching: false,
          vendorList: action.payload.clearList ? [] : state.vendor.vendorList,
          selectedVendor: {
            vendorId: action.payload.vendor.vendorId,
            displayName: action.payload.vendor.displayName,
            name: action.payload.vendor.name,
            value: action.payload.vendor.value
          }
        }
      }

    case HIDE_FULFILLMENT_TABS: {
      const { vendorRoleInfo } = action.payload
      let newState = state
      if ((state.sourceType.selected === 1)) {
        vendorRoleInfo.forEach(val => {
          if (val.type === SUPPORTED_SOURCE[0]) {
            newState = {
              ...state,
              vendor: {
                ...state.vendor,
                FFCO: val.appUserRoleInfo.features.includes('FFCO'),
                hidePastDue: val.appUserRoleInfo.hiddenFeatures.includes('HPD'),
                hideDueToday: val.appUserRoleInfo.hiddenFeatures.includes('HDT'),
                FFPL: val.appUserRoleInfo.features.includes('FFPL')
              }
            }
          }
        })
      }
      return {
        ...newState
      }
    }

    case FETCH_SHIP_NODES_REQUEST:
      return {
        ...state,
        shipNodes: {
          ...INITIAL_STATE.shipNodes,
          isFetching: true
        }
      }

    case FETCH_SHIP_NODES_SUCCESS:
      return {
        ...state,
        vendor: {
          ...state.vendor,
          isSupplierPresent: true
        },
        shipNodes: {
          ...state.shipNodes,
          isFetching: false,
          list: [...action.response.payload.shipNodes]
        }
      }

    case FETCH_SHIP_NODES_FAILURE:
      return {
        ...state,
        vendor: {
          ...state.vendor,
          isSupplierPresent: false
        },
        shipNodes: {
          ...INITIAL_STATE.shipNodes,
          isFetching: false
        }
      }

    case SELECT_SHIP_NODE:
      return {
        ...state,
        shipNodes: {
          ...state.shipNodes,
          selected: { ...action.payload.shipNode }
        }
      }

    case CLEAR_SHIP_NODE:
      return {
        ...state,
        shipNodes: {
          ...state.shipNodes,
          selected: { ...INITIAL_STATE.shipNodes.selected },
          list: []
        }
      }

    case SHOW_TOAST_MESSAGE:
      return {
        ...state,
        toastMessage: {
          open: true,
          message: action.payload.message
        }
      }

    case HIDE_TOAST_MESSAGE:
      return {
        ...state,
        toastMessage: {
          open: false,
          message: null
        }
      }

    case SHOW_BULK_UPDATE_POPUP:
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          showPopup: true,
          isFulfillment: action.payload.isFulfillment,
          isSmartInventory: action.payload.isSmartInventory,
          isPenalty: action.payload.isPenalty,
          isPenaltyApproval: action.payload.isPenaltyApproval,
          isArApproval: action.payload.isArApproval,
          isInTransitInventory: action.payload.isInTransitInventory,
          isClubSaleForecast: action.payload.isClubSaleForecast,
          isDailyCapacity: action.payload.isDailyCapacity
        }
      }

    case HIDE_BULK_UPDATE_POPUP:
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          showPopup: false,
          isFulfillment: false,
          isPenalty: false,
          isInTransitInventory: false,
          isClubSaleForecast: false,
          isDailyCapacity: false
        }
      }

    case BULK_UPLOAD_FILES_REQUEST:
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          isUploading: true,
          ackIds: []
        }
      }

    case BULK_UPLOAD_FILES_SUCCESS:
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          isUploading: false,
          ackIds: action.response.payloads
        }
      }

    case BULK_UPLOAD_FILES_FAILURE:
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          isUploading: false,
          ackIds: []
        }
      }

    case SHOW_EXPORT_POPUP:
      return {
        ...state,
        exportOrders: {
          ...INITIAL_STATE.exportOrders,
          showPopUp: true,
          moduleType: action.payload.moduleType
        }
      }

    case HIDE_EXPORT_POPUP:
      return {
        ...state,
        exportOrders: {
          ...INITIAL_STATE.exportOrders,
          showPopUp: false
        }
      }

    case INITIATE_EXPORT_REQUEST:
      return {
        ...state,
        exportOrders: {
          ...state.exportOrders,
          isFetching: true
        }
      }

    case INITIATE_EXPORT_SUCCESS:
    case RETRY_EXPORT_FILE_SUCCESS:
      return {
        ...state,
        exportOrders: {
          ...state.exportOrders,
          ackIds: isArray(action.response.payload?.ackIds)
            ? [taskStatusParser(action.response.payload?.ackIds[0]), ...state.exportOrders.ackIds]
            : [taskStatusParser(action.response.payload), ...state.exportOrders.ackIds],
          isFetching: false
        }
      }

    case INITIATE_EXPORT_FAILURE:
      return {
        ...state,
        exportOrders: {
          ...state.exportOrders,
          isFetching: false
        }
      }

    case CHECK_EXPORT_STATUS_REQUEST:
      return { ...state }

    case CHECK_EXPORT_STATUS_SUCCESS: {
      const requestedAckId = { ...action.payload.ackId }
      return {
        ...state,
        exportOrders: {
          ...state.exportOrders,
          ackIds: state.exportOrders.ackIds.map(ackId => {
            if (ackId.ackId === requestedAckId.ackId) return { ...ackId, ...taskStatusParser(action.response.payload) }
            return { ...ackId }
          })
        }
      }
    }

    case CHECK_EXPORT_STATUS_FAILURE:
    case RETRY_EXPORT_FILE_REQUEST:
    case RETRY_EXPORT_FILE_FAILURE: {
      const requestedAckId = action.payload.ackId
      const ackStatus = action.type === RETRY_EXPORT_FILE_REQUEST ? 'INITIATED' : 'FAILED'
      return {
        ...state,
        exportOrders: {
          ...state.exportOrders,
          ackIds: state.exportOrders.ackIds.map(ackId => {
            if (ackId.ackId === requestedAckId) {
              return {
                ...ackId,
                status: ackStatus
              }
            }
            return { ...ackId }
          })
        }
      }
    }

    case SET_MODULE_TYPE:
      return {
        ...state,
        exportOrders: {
          ...state.exportOrders,
          moduleType: action.payload.moduleType
        }
      }

    case SKIP_SUPPLIER_SELECTION:
      return {
        ...state,
        skipSupplierSelection: action.payload
      }

    case TOGGLE_ATMT_FEEDBACK:
      return {
        ...state,
        isToggleAtmtFeedback: !state.isToggleAtmtFeedback
      }

    case TOGGLE_SOURCE_TYPE:
      const { selected } = action.payload
      return {
        ...state,
        sourceType: {
          ...state.sourceType,
          selected: selected
        }
      }

    case GET_SOURCE_TYPE:
      const { sourceType } = action.payload
      return getSourcetypeState(sourceType, state)

    case SET_SOURCE_TYPE:
      const { pathname } = action.payload
      return setSourceByParams({ pathname, state })

    default:
      return state
  }
}
