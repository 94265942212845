import React from 'react'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ArrayFormatter } from './CustomFieldTypeProvider'
import { goToPenaltyDetails } from '../../pages/AccountReceivable/AccountReceivableActions'

const styles = theme => ({
    link: {
        color: theme.palette.primary.blue,
        cursor: 'pointer'
    }
})

const AccountReceivablePenaltyCell = ({ classes, goToPenaltyDetails, columnName, ...restProps }) => {
    let code = ''
    const { row, value, selectedARMonth } = restProps
    const vendorParam = `${row?.supplierId}`
    const latePenalty = 'latePenaltiesCount'
    const cancellPenalty = 'cancellationPenaltiesCount'
    if (columnName == latePenalty) {
        code = encodeURIComponent(`?vendor=${vendorParam}&m=${selectedARMonth.id - 1}&pt=Late`)
    }
    if (columnName == cancellPenalty) {
        code = encodeURIComponent(`?vendor=${vendorParam}&m=${selectedARMonth.id - 1}&pt=Cancelled`)
    }

    const onClick = () => {
        if (value !== null && selectedARMonth.id === 4) {
            return goToPenaltyDetails({ code: code, row: row })
        }
    }

    return (
        <Table.Cell {...restProps}>
            <Typography variant="body2" className={{ [classes.link]: selectedARMonth.id === 4 }} onClick={onClick}>
                <ArrayFormatter value={restProps.value} noTooltip />
            </Typography>
        </Table.Cell>
    )
}

AccountReceivablePenaltyCell.PropTypes = {
    classes: PropTypes.object.isRequired,
    restProps: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    selectedARMonth: state.Approvals.selectedARMonth
})

const mapDispatchToProps = dispatch => bindActionCreators({ goToPenaltyDetails }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountReceivablePenaltyCell))
