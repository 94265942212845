import config from '../../config'
import { APP_CONFIG } from '../../config/appConfigs'
// eslint-disable-next-line import/no-cycle
import { autoSelectVendor, setPartnerList } from '../../reduxSetup/commonActions/AppConfig'
import { aiLoginSuccess, aiSignOut } from '../../reduxSetup/commonActions/TelemetryActions'
import { CALL_API } from '../../reduxSetup/middleware/API'
import { getAuthorizationCode, getVendorIdFromState, redirectToLogin } from '../../utils/common'
import { getUserDataFromCookie, invalidateCookie, writeUserInfoCookie } from '../../utils/cookies'
// eslint-disable-next-line import/extensions
import { mockResponse, merchant } from './mockIAMResponse.js'
import mockVendorResponse from './mockVendorResponse'
import {
  GET_USER_ROLES_FAILURE,
  GET_USER_ROLES_REQUEST,
  GET_USER_ROLES_SUCCESS,
  INVALIDATE_SESSION_FAILURE,
  INVALIDATE_SESSION_REQUEST,
  INVALIDATE_SESSION_SUCCESS,
  LOGIN_VIA_PERSISTENT_TOKEN
} from './UserInfo.actionTypes'

export const getUserInformation = authorizationCode => (dispatch, getState) => {
  const URL = `${config.get('userInfo')}?authToken=${authorizationCode}&redirectionURI=${APP_CONFIG.ENV().host}`

  return dispatch({
    [CALL_API]: {
      types: [GET_USER_ROLES_REQUEST, GET_USER_ROLES_SUCCESS, GET_USER_ROLES_FAILURE],
      endpoint: URL,
      method: 'POST',
      payload: { authorizationCode, isCookie: false }
    }
  })
    .then(result => {
      if (result && result.type === GET_USER_ROLES_SUCCESS) {
        writeUserInfoCookie(result.response.payload)
      }
      return result
    })
    .then(result => {
      if (result && result.type === GET_USER_ROLES_SUCCESS) {
        if (getState().UserInfo.isVendor === true) dispatch(setPartnerList())

        return dispatch(aiLoginSuccess())
      }
      return Promise.resolve()
    })
}

export const getUserInfoFromCookie = () => (dispatch, getState) => {
  const userData = getUserDataFromCookie()
  const AUTHORIZATION_CODE = getAuthorizationCode()

  if (userData.userInfo === '' && Boolean(AUTHORIZATION_CODE) === false) return redirectToLogin()

  return Promise.resolve(
    dispatch({
      type: GET_USER_ROLES_SUCCESS,
      payload: { isCookie: true },
      response: {
        payload: {
          userInfo: { ...userData.userInfo },
          superAdmin: userData.superAdmin,
          customerToken: userData.customerToken,
          sessionId: userData.sessionId,
          iamToken: userData.iamToken,
          appUserRole: userData.appUserRole,
          appUserRoleInfo: userData.appUserRoleInfo
        }
      }
    })
  ).then(() => {
    if (getState().UserInfo.isVendor === true) dispatch(setPartnerList())

    return dispatch(aiLoginSuccess())
  })
    .then(dispatch(autoSelectVendor()))
}

export const logout = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  aiSignOut()
  return dispatch({
    [CALL_API]: {
      types: [INVALIDATE_SESSION_REQUEST, INVALIDATE_SESSION_SUCCESS, INVALIDATE_SESSION_FAILURE],
      endpoint: `${config.get('logout')}?vendorId=${VENDOR_ID}`,
      method: 'PUT'
    }
  }).then(() => {
    invalidateCookie()
    window.location = `${APP_CONFIG.ENV().retailLink}ssologout?postLogoutRedirect=${APP_CONFIG.ENV().host}&clientId=${APP_CONFIG.ENV().clientId}`
  })
}

/**
 *
 * @param {String} customerToken
 * @return {function(any, any): any}
 */
export const skipRetailLink = ({ customerToken, iamToken, permission, isVendor }) => dispatch => {
  let response

  if (isVendor) {
    response = mockVendorResponse
    mockVendorResponse.payload.customerToken = customerToken
  } else {
    response = mockResponse
    mockResponse.payload.customerToken = customerToken
    mockResponse.payload.iamToken = iamToken
  }

  // eslint-disable-next-line no-param-reassign
  permission = permission ? permission.toUpperCase() : permission
  return dispatch({
    type: LOGIN_VIA_PERSISTENT_TOKEN,
    payload: { isCookie: true },
    response: setPermission({ response, permission })
  })
}

/**
 *
 * @param {object} response
 * @param {string} permission
 * @return {function(object, string): response}
 */
const setPermission = ({ response, permission }) => {
  switch (permission) {
    case 'MERCHANT':
      response.payload.userInfo.permissions = merchant.permissions
      return response
    case 'SSH':
      response.payload.userInfo.permissions = []
      return response
    default:
      return response
  }
}
