import { cloneDeep, isEmpty } from 'lodash'
import { getUserModuleAccess, getModuleConfiguration } from '../../utils/common'
import { MENU_ROUTES } from '../LeftNav/AppRoutes'
import {
  GET_USER_ROLES_FAILURE,
  GET_USER_ROLES_REQUEST,
  GET_USER_ROLES_SUCCESS,
  LOGIN_VIA_PERSISTENT_TOKEN,
  INVALIDATE_SESSION_REQUEST,
  INVALIDATE_SESSION_SUCCESS,
  TOGGLE_SOURCE_ACCESS
} from './UserInfo.actionTypes'
import {
  FETCH_VENDOR_ROLES_REQUEST,
  FETCH_VENDOR_ROLES_SUCCESS,
  FETCH_VENDOR_ROLES_FAILURE
} from '../../reduxSetup/commonActions/AppConfig.actionTypes'
import { permissionParser } from '../../utils/parsers'

const modules = {}
MENU_ROUTES.forEach(route => {
 modules[route?.url?.slice(1)] = false
})

export const INITIAL_STATE = {
  access: { ...modules },
  fetchingRoles: false,
  loggedIn: false,
  loggingIn: true,
  loggingOut: false,
  isAdmin: false,
  isSuperAdmin: false,
  isVendor: false,
  isSupplierPresent: false,
  partnerList: [],
  isCookie: null,
  userDetails: {
    firstName: null,
    lastName: null,
    emailId: null,
    loginId: null,
    customerToken: null,
    sessionId: null,
    appUserRole: null,
    iamToken: null
  },
  configuration: null
}

export default function UserInfoReducer(state = cloneDeep(INITIAL_STATE), action) {
  switch (action.type) {
    case GET_USER_ROLES_REQUEST:
      return {
        ...state,
        loggedIn: false,
        loggingIn: true,
        userDetails: { ...state.userDetails }
      }

    case LOGIN_VIA_PERSISTENT_TOKEN:
    case GET_USER_ROLES_SUCCESS: {
      return {
        ...state,
        access: {
          ...state.access,
          dashboard: true,
          'smart-inventory': action.response.payload.appUserRole === 'admin',
          reports: true
        },
        loggedIn: true,
        loggingIn: false,
        isAdmin: action.response.payload.appUserRole === 'admin',
        isVendor: action.response.payload.appUserRole !== 'admin',
        isSuperAdmin: action.response.payload.superAdmin,
        permissions: permissionParser(action.response.payload.userInfo.permissions),
        isCookie: !!action.payload.isCookie,
        partnerList: !isEmpty(action.response.payload.userInfo.partners)
          ? [...action.response.payload.userInfo.partners]
          : [],
        userDetails: {
          ...state.userDetails,
          firstName: action.response.payload.userInfo.firstName,
          lastName: action.response.payload.userInfo.lastName,
          emailId: action.response.payload.userInfo.emailId,
          loginId: action.response.payload.userInfo.loginId,
          appUserRole: action.response.payload.appUserRole,
          customerToken: action.response.payload.customerToken,
          sessionId: action.response.payload.sessionId,
          iamToken: action.response.payload.iamToken
        }
      }
    }

    case GET_USER_ROLES_FAILURE:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        access: { ...INITIAL_STATE.access },
        isSuperAdmin: false,
        userDetails: { ...INITIAL_STATE.userDetails }
      }

    case FETCH_VENDOR_ROLES_REQUEST:
      return {
        ...state,
        fetchingRoles: true
      }

    case FETCH_VENDOR_ROLES_SUCCESS:
      return {
        ...state,
        fetchingRoles: false,
        isSupplierPresent: true
      }

    case FETCH_VENDOR_ROLES_FAILURE:
      return {
        ...state,
        fetchingRoles: false,
        isSupplierPresent: false,
        access: { ...INITIAL_STATE.access }
      }

    case INVALIDATE_SESSION_REQUEST:
      return{
        ...state,
        loggingOut: true
      }
      case INVALIDATE_SESSION_SUCCESS:
        return{
          ...state,
          loggingOut: true,
          loggedIn: false,
          userDetails: { ...INITIAL_STATE.userDetails }
        }

      case TOGGLE_SOURCE_ACCESS:
        const { selected, vendorRoleInfo,type } = action.payload
        return {
          ...state,
          access: {
            ...getUserModuleAccess(modules, state.isAdmin, vendorRoleInfo, selected,type)
          },
          configuration: getModuleConfiguration(vendorRoleInfo, selected)
        }

    default:
      return { ...state }
  }
}
