import React from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import SSHTooltip from '../../components/SSHTooltip'

const TableHeaderCell = ({ children, tableRow, tableColumn, column, ...restProps }) => {
  let icon = null

  const useStyles = makeStyles(theme => ({
    infoIcon: {
      color: theme.palette.primary.blue,
      fontSize: `${theme.replacePX(theme.spacing(2))}px`,
      position: 'relative',
      left: `${theme.replacePX(theme.spacing(1))}px`,
      top: `${theme.replacePX(theme.spacing(0.625))}px`
    }
  }))

  const classes = useStyles()

  const renderToolTip = () => {
    switch (column.name) {
      case 'oos':
        return 'OOS% is percentage of items currently out of stock'
      case 'lossOfRevenue':
        return 'Revenue loss (in USD) is the projected revenue that will not be captured in the next 14 days if the OOS items are not addressed'
      default:
        return ''
    }
  }

  if (column.name === 'oos' || column.name === 'lossOfRevenue') {
    icon = (
      <>
        <Box>
          <SSHTooltip title={renderToolTip()} placement="top">
            <InfoOutlinedIcon className={classes.infoIcon} />
          </SSHTooltip>
        </Box>
      </>
    )
  }

  return (
    <TableHeaderRow.Cell {...restProps} column={column}>
      <Box display="flex" alignItems="flex-start">
        {children}
        {icon}
      </Box>
    </TableHeaderRow.Cell>
  )
}

export default TableHeaderCell
