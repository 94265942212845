import config from '../../../config'
import moment from 'moment'
import { mapValues, filter } from 'lodash'
import {
    FETCH_PAGINATED_TABLE_DATA_FAILURE,
    FETCH_PAGINATED_TABLE_DATA_REQUEST,
    FETCH_PAGINATED_TABLE_DATA_SUCCESS
} from '../../../reduxSetup/commonActions/SSHPaginatedTable.actionTypes'
import { CALL_API } from '../../../reduxSetup/middleware/API'
import {
    SET_PRICING_SERVICE_COUNT,
    SET_FILTERS_OPEN,
    SET_SELECTED_TAB,
    SET_FILTERS_OPTIONS,
    FETCH_FILTER_OPTIONS_REQUEST,
    FETCH_FILTER_OPTIONS_SUCCESS,
    FETCH_FILTER_OPTIONS_FAILURE,
    DOWNLOAD_PS_REQUEST,
    DOWNLOAD_PS_SUCCESS,
    DOWNLOAD_PS_FAILURE,
    SET_SELECTED_DATE,
    RESET_SELECTED_DATE,
    RESET_SELECTED_TAB,
    UPDATE_FILTERS,
    RESET_FILTERS
} from './PricingService.types'
import { DOWNLOAD_SUCCESS_MSG, DOWNLOAD_FAILURE_MSG } from './commonConstants'
import { downloadFile } from '../../../utils/common'
import { hideToastMessage, showToastMessage } from '../../../reduxSetup/commonActions/AppConfig'
import { SMART_INVENTORY_DROPDOWN_MODEL } from '../../../config/genericConstants'

/**
* @description fetch pricing services Items
* @returns {function}
*/
export const fetchPricingServices = () => (dispatch, getState) => {
    const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
    const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
    const { isAdmin } = getState().UserInfo
    const filters = getState().ClubPricingServices.filtersApplied
    const selectedTab = getState().ClubPricingServices.selectedTab

    if (!isAdmin) {
        return
    }

    return dispatch({
        [CALL_API]: {
            types: [
                FETCH_PAGINATED_TABLE_DATA_REQUEST,
                FETCH_PAGINATED_TABLE_DATA_SUCCESS,
                FETCH_PAGINATED_TABLE_DATA_FAILURE
            ],
            endpoint: `${config.get('pricingServices')}/${selectedTab?.value}?pageNo=${PAGE_NO + 1}&pageSize=${PAGE_SIZE}`,
            method: 'POST',
            payload: {
                PAGE_SIZE,
                PAGE_NO,
                pathName: getState().router.location.pathname
            },
            data: {
                filters: dispatch(getFiltersPayload(filters))
            }
        }
    }).then(({ type, response }) => {
        if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS) {
            return dispatch(setPricingServiceCount({ count: response?.payload?.totalRecords || 0 }))
        }
        return false
    })
}

/**
* @description get filters payload
* @param {object} {filters}
* @return {filters}
*/
export const getFiltersPayload = (filters) => (dispatch, getState) => {
    const selectedDate = getState().ClubPricingServices.selectedDate
    return filters.map(val => {
        if (val.filterName === "date") {
            val.filterValues = [selectedDate?.value]
        }
        return val
    })
}

/**
* @description set pricing service items count
* @param {object} {count}
* @return {function}
*/
export const setPricingServiceCount = ({ count }) => dispatch => {
    return dispatch({
        type: SET_PRICING_SERVICE_COUNT,
        payload: { count }
    })
}

/**
* @description set selected tab
* @param {string} {tabName}
* @return {function}
*/
export const setSelectedTab = (tabName) => dispatch => {
    dispatch({
        type: SET_SELECTED_TAB,
        payload: { tabName }
    })
}

/**
* @description set filters drawer open
* @param {boolean} {isOpen}
* @return {function}
*/
export const setFilterOpen = (isOpen) => dispatch => {
    return dispatch({
        type: SET_FILTERS_OPEN,
        payload: { isOpen }
    })
}

/**
* @description set filters options
* @param {object} {options}
* @return {function}
*/
export const setFilterOptions = (options) => dispatch => {
    return dispatch({
        type: SET_FILTERS_OPTIONS,
        payload: options
    })
}

/**
* @description set update filters
* @param {object} {options}
* @return {function}
*/
export const updateFilters = (filters) => dispatch => {
    return dispatch({
        type: UPDATE_FILTERS,
        payload: sortFilters(filters)
    })
}

/**
* @description sort active filters
* @param {object} {filters}
* @return {suppliers, states, categories}
*/
const sortFilters = (filters) => {
    const suppliers = filter(filters['vendor_#'], { 'active': true })
    const states = filter(filters['state'], { 'active': true })
    const categories = filter(filters['cat_desc'], { 'active': true })

    return {
        suppliers,
        states,
        categories
    }
}

/**
* @description set date change
* @param {object} {selected}
* @return {function}
*/
export const setDateChange = (selected) => dispatch => {
    return Promise.resolve(
        dispatch({
            type: SET_SELECTED_DATE,
            payload: { selected }
        })
    )
        .then(dispatch(resetSelectedTab()))
        .then(dispatch(resetFilters()))
        .then(dispatch(fetchPricingServices()))
}

/**
* @description reset to current date
* @return {function}
*/
export const resetCurrentDate = () => dispatch => {
    return dispatch({
        type: RESET_SELECTED_DATE
    })
}

/**
* @description reset selected tab
* @return {function}
*/
export const resetSelectedTab = () => dispatch => {
    dispatch({
        type: RESET_SELECTED_TAB
    })
}

/**
* @description reset selected filters
* @return {function}
*/
export const resetFilters = () => dispatch => {
    dispatch({
        type: RESET_FILTERS
    })
}

/**
* @description fetch pricing services filter options
* @returns {function}
*/
export const fetchFilterOptions = () => (dispatch, getState) => {
    const filterOptionsPayload = getState().ClubPricingServices.filterOptionsPayload
    const filtersApplied = getState().ClubPricingServices.filtersApplied

    return dispatch({
        [CALL_API]: {
            types: [
                FETCH_FILTER_OPTIONS_REQUEST,
                FETCH_FILTER_OPTIONS_SUCCESS,
                FETCH_FILTER_OPTIONS_FAILURE
            ],
            endpoint: `${config.get('pricingServices')}/filters`,
            method: 'POST',
            payload: {
                pathName: getState().router.location.pathname
            },
            data: {
                filters: dispatch(getFiltersOptionsPayload(filterOptionsPayload))
            }
        }
    }).then(({ type, response }) => {
        if (type === FETCH_FILTER_OPTIONS_SUCCESS) {
            const options = mapValues(response?.payload, (filter, name) => {
                const selected = filtersApplied?.find(key => key.filterName === name).filterValues
                const newfilterArr = filter.map(val => {
                    return {
                        id: val,
                        title: val,
                        value: val,
                        active: selected.length === 0 || selected.includes(val)
                    }
                })
                newfilterArr.splice(0, 0, { ...SMART_INVENTORY_DROPDOWN_MODEL, active: selected.length === 0 })
                return newfilterArr
            })
            return dispatch(setFilterOptions({ options }))
        }
        return false
    })
}

/**
* @description get filters payload
* @param {object} {filters}
* @return {filters}
*/
export const getFiltersOptionsPayload = (filters) => (dispatch, getState) => {
    const selectedDate = getState().ClubPricingServices.selectedDate
    const selectedTab = getState().ClubPricingServices.selectedTab
    return filters.map(val => {
        switch (val.filterName) {
            case "date":
                val.filterValues = [selectedDate?.value]
                break;
            case "tab":
                val.filterValues = [selectedTab?.value]
                break;
            case "properties":
                val.filterValues = selectedTab?.filters
        }
        return val
    })
}


/**
* @description download pricing services
* @return {function}
*/
export const downloadPricingServices = () => (dispatch, getState) => {
    const selectedTab = getState().ClubPricingServices.selectedTab
    const filters = getState().ClubPricingServices.filtersApplied
    return dispatch({
        [CALL_API]: {
            types: [
                DOWNLOAD_PS_REQUEST,
                DOWNLOAD_PS_SUCCESS,
                DOWNLOAD_PS_FAILURE
            ],
            endpoint: `${config.get('pricingServicesDownload')}/${selectedTab?.value}`,
            method: 'POST',
            payload: {
                pathName: getState().router.location.pathname
            },
            isExcel: true,
            data: {
                filters: dispatch(getFiltersPayload(filters))
            }
        }
    }).then((response) => {
        if (response?.type === DOWNLOAD_PS_SUCCESS) {
            downloadFile({ data: response.response }, `PricingServices_${selectedTab?.value}_${moment().unix()}`)
            dispatch(showToastMessage({ message: DOWNLOAD_SUCCESS_MSG }))
            setTimeout(() => {
                dispatch(hideToastMessage())
            }, 2000)
        } else {
            dispatch(
                showToastMessage({
                    message: DOWNLOAD_FAILURE_MSG
                })
            )
        }
    })
}
