import React, { useEffect } from 'react'
import { Drawer, IconButton, Typography, Grid, Box } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import clsx from 'clsx'
import { Clear } from '@mui/icons-material'
import { changePage } from '../../reduxSetup/commonActions/AppConfig'
import { closeNotificationDrawer, clearNotificationCount } from './NotificationDrawerActions'
import SSHButton from '../../components/SSHButton'
import NotificationItem from './NotificationItem'
import { SSHBadge } from '../../components/SSHBadge'

const styles = theme => ({
  container: {
    width: 368
  },
  headerContainer: {
    backgroundColor: theme.palette.background.lightGray,
    maxHeight: 76
  },
  headerText: {
    fontSize: 16,
    fontWeight: 500
  },
  subText: {
    fontSize: 12,
    color: theme.palette.primary.softBlue
  },
  textContainer: {
    height: 76
  },
  footerContainer: {
    backgroundColor: theme.palette.background.magnolia
  },
  footerButton: {
    fontSize: 16,
    fontWeight: 500,
    height: 34,
    width: 143,
    '& .MuiButton-label': {
      height: 30
    }
  },
  viewAllButton: {
    border: `2px solid ${theme.palette.primary.blue}`,
    borderRadius: 2,

    '&.Mui-disabled': {
      border: `2px solid ${theme.palette.primary.slateGrey}`,
      color: theme.palette.primary.slateGrey
    }
  },
  closeIcon: {
    fontSize: '20px',
    fill: theme.palette.primary.blue
  },
  noNotificationText: {
    textAlign: 'center',
    fontSize: '14px',
    marginTop: theme.spacing(3.75)
  }
})

const StyledSSHBadge = withStyles(() => ({
  badge: {
    top: 13,
    right: -15
  }
}))(SSHBadge)

const NotificationDrawer = ({
  classes,
  notificationDrawerOpen,
  closeNotificationDrawer,
  count,
  changePage,
  downloadReports,
  clearNotificationCount
}) => {
  useEffect(() => {
    return () => {
      if (count !== 0 && notificationDrawerOpen) {
        clearNotificationCount()
      }
    }
  }, [notificationDrawerOpen])

  const viewAllReports = () => {
    closeNotificationDrawer()
    changePage('/download-history')
  }

  return (
    <Drawer anchor="right" open={notificationDrawerOpen} onClose={closeNotificationDrawer}>
      <Grid className={classes.container} container justifyContent="space-between">
        <Grid className={classes.headerContainer} container item alignItems="center" xs={12} direction="row">
          <Grid container item direction="column" xs={10}>
            <Grid item xs={12}>
              <Box paddingTop="15px" marginLeft="25px" height={76}>
                <StyledSSHBadge badgeContent={count}>
                  <Typography variant="h6" className={classes.headerText}>
                    Notifications
                  </Typography>
                </StyledSSHBadge>
                <Typography variant="subtitle1" className={classes.subText}>
                  {count} items awaiting your actions
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={closeNotificationDrawer}>
              <Clear className={classes.closeIcon} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box overflow="scroll" height="calc(100vh - 135px)" marginLeft="16px">
            {downloadReports && downloadReports.length !== 0 ? (
              downloadReports.map(report => (
                <NotificationItem
                  key={report.ackId}
                  fileName={report.fileName}
                  status={report.status}
                  module={report.module}
                  requestNumber={report.ackId}
                  requestedTime={report.time}
                  shipNodeId={report.shipNode}
                  supplierName={report.supplierName}
                  isVendor={report.isVendor}
                />
              ))
            ) : (
              <Typography variant="h5" className={classes.noNotificationText}>
                No download requests
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid className={classes.footerContainer} item contianer justifyContent="space-evenly" alignItems="center" xs={12}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-evenly" alignItems="center" height={60}>
              <SSHButton className={classes.footerButton} size="small" text="Back" onClick={closeNotificationDrawer} />
              <SSHButton
                className={clsx(classes.footerButton, classes.viewAllButton)}
                size="small"
                text="View all"
                onClick={viewAllReports}
                disabled={downloadReports.length === 0}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  )
}

NotificationDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  notificationDrawerOpen: PropTypes.bool.isRequired,
  closeNotificationDrawer: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
  downloadReports: PropTypes.array.isRequired,
  clearNotificationCount: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  notificationDrawerOpen: state.NotificationDrawer.notificationDrawer.open,
  downloadReports: state.NotificationDrawer.downloadReports,
  count: state.NotificationDrawer.count
})

export const NotificationDrawerNaked = NotificationDrawer

const mapDispatchToProps = dispatch =>
  bindActionCreators({ closeNotificationDrawer, changePage, clearNotificationCount }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotificationDrawer))
