import React from 'react'
import {
  BusinessOutlined,
  HomeOutlined,
  LocalShippingOutlined,
  SpeedOutlined,
  SwapVertOutlined,
  ScheduleOutlined,
  DescriptionOutlined,
  StoreOutlined,
  Settings,
  SupervisorAccountOutlined,
  HelpOutline,
  Edit,
  SearchOutlined,
  AssessmentOutlined,
  MonetizationOnOutlined,
  AssignmentReturnOutlined,
  InsertChartOutlined,
  ExpandMoreOutlined,
  ExpandLessOutlined,
  NotificationsActiveOutlined,
  DoubleArrowOutlined,
  AccountCircleOutlined
} from '@mui/icons-material'
import { COLORS } from '../Entry/globalMUIStyles'

const ColoredSvg = (C, selected = false, color = selected ? COLORS.white : COLORS.drawerItemText) => {
  return (
    <div>
      <C style={{ fill: color }} />
    </div>
  )
}

const Business = () => ColoredSvg(BusinessOutlined, false)
const BusinessSelected = () => ColoredSvg(BusinessOutlined, true)

const Home = () => ColoredSvg(HomeOutlined, false)
const HomeSelected = () => ColoredSvg(HomeOutlined, true)

const LocalShipping = () => ColoredSvg(LocalShippingOutlined, false)
const LocalShippingSelected = () => ColoredSvg(LocalShippingOutlined, true)

const Speed = () => ColoredSvg(SpeedOutlined, false)
const SpeedSelected = () => ColoredSvg(SpeedOutlined, true)

const SwapVert = () => ColoredSvg(SwapVertOutlined, false)
const SwapVertSelected = () => ColoredSvg(SwapVertOutlined, true)

const Schedule = () => ColoredSvg(ScheduleOutlined, false)
const ScheduleSelected = () => ColoredSvg(ScheduleOutlined, true)

const Description = () => ColoredSvg(DescriptionOutlined, false)
const DescriptionSelected = () => ColoredSvg(DescriptionOutlined, true)

const Store = () => ColoredSvg(StoreOutlined, false)
const StoreSelected = () => ColoredSvg(StoreOutlined, true)

const Account = () => ColoredSvg(SupervisorAccountOutlined, false)
const AccountSelected = () => ColoredSvg(SupervisorAccountOutlined, true)

const Setting = () => ColoredSvg(Settings, false)
const SettingSelected = () => ColoredSvg(Settings, true)

const Search = () => ColoredSvg(SearchOutlined, false)
const SearchSelected = () => ColoredSvg(SearchOutlined, true)

const Help = () => ColoredSvg(HelpOutline, true)

const EditIcon = () => ColoredSvg(Edit, true, COLORS.baseBlue)

const ReportIcon = () => ColoredSvg(AssessmentOutlined, false)
const ReportSelected = () => ColoredSvg(AssessmentOutlined, true)

const PenaltyIcon = () => ColoredSvg(MonetizationOnOutlined, false)
const PenaltySelected = () => ColoredSvg(MonetizationOnOutlined, true)

const ReturnIcon = () => ColoredSvg(AssignmentReturnOutlined, false)
const ReturnSelected = () => ColoredSvg(AssignmentReturnOutlined, true)

const Performance = () => ColoredSvg(InsertChartOutlined, false)
const PerformanceSelected = () => ColoredSvg(InsertChartOutlined, true)

const ExpandMore = () => ColoredSvg(ExpandMoreOutlined, false)
const ExpandMoreSelected = () => ColoredSvg(ExpandMoreOutlined, true)

const ExpandLess = () => ColoredSvg(ExpandLessOutlined, false)
const ExpandLessSelected = () => ColoredSvg(ExpandLessOutlined, true)

const NotificationIcon = () => ColoredSvg(NotificationsActiveOutlined, true)
const DoubleArrowIcon = () => ColoredSvg(DoubleArrowOutlined, true)
const AccountCircleIcon = () => ColoredSvg(AccountCircleOutlined, true)

export {
  Business,
  BusinessSelected,
  Home,
  HomeSelected,
  LocalShipping,
  LocalShippingSelected,
  Speed,
  SpeedSelected,
  SwapVert,
  SwapVertSelected,
  Schedule,
  ScheduleSelected,
  Description,
  DescriptionSelected,
  Account,
  AccountSelected,
  Store,
  StoreSelected,
  Setting,
  SettingSelected,
  Help,
  EditIcon,
  Search,
  SearchSelected,
  ReportIcon,
  ReportSelected,
  PenaltyIcon,
  PenaltySelected,
  ReturnIcon,
  ReturnSelected,
  Performance,
  PerformanceSelected,
  ExpandMore,
  ExpandMoreSelected,
  ExpandLess,
  ExpandLessSelected,
  NotificationIcon,
  DoubleArrowIcon,
  AccountCircleIcon
}
