/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-wrap-multilines */
import { Grid, Typography, Box } from '@mui/material'
import { withStyles } from '@mui/styles'
import { ReactComponent as LeftArrowIcon } from '../../../assets/images/leftArrow.svg'
import PropTypes from 'prop-types'
import React from 'react'
import SSHButton from '../../../components/SSHButton'
import SSHLabel from '../../../components/SSHLabel'
import SSHSkeleton from '../../../components/SSHSkeleton'
import { filter, isEmpty } from 'lodash'
import { allowAccess } from '../../../utils/common'
import * as gConstants from '../../../config/genericConstants'

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.primary.headerBackground,
        padding: `${theme.replacePX(theme.spacing(1.5))}px ${theme.replacePX(theme.spacing(3.2))}px`,
        position: 'fixed',
        width: '97%',
        top: 0,
        zIndex: theme.zIndex.appBar + 1
    },
    title: {
        color: theme.palette.primary.baseTextDark,
        fontWeight: '400',
        fontSize: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontSize: theme.spacing(1.75)
        }
    }
})

/**
 * @description enable acknowledged on row modified with carrier and tracking number
 * @param {*} rows
 * @returns object
 */
const enableAcknowledged = ({ ordersList }) => {
    return filter(ordersList?.orderLines, { isModified: true }).length === 0
}

const ReturnOrderAck = ({ classes, goToReturnsPage, updateOrderCarrierTracking, ...restProps }) => {
    return (
        <Box className={classes.root}>
            <Grid container spacing={0} alignItems='center'>
                <Grid item xs={11}>
                    <Typography variant="h6" className={classes.title}>
                        <>
                            <LeftArrowIcon onClick={goToReturnsPage} cursor={'pointer'} /> &nbsp;&nbsp;
                            Return number: {restProps?.ordersList?.orderNo} &nbsp;&nbsp;
                            {isEmpty(restProps.ordersList) ? <SSHSkeleton variant="text" animation="wave" width="50%" /> : <SSHLabel value={restProps?.ordersList?.status} />}
                        </>
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    {allowAccess(gConstants.ROLE_BACK_ACTIONS.EDIT) &&
                        (String(restProps?.ordersList?.status).toLowerCase() === String(gConstants.ORDER_STATUSES[0].value).toLowerCase() || String(restProps?.ordersList?.status).toLowerCase() === String(gConstants.ORDER_STATUSES[4].value).toLowerCase()) &&
                        (
                            <>
                                <SSHButton
                                    text="Acknowledge"
                                    tooltip="Acknowledge all items from this order"
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    disabled={restProps.isUpdating || enableAcknowledged({ ordersList: restProps.ordersList })}
                                    loading={restProps.isUpdating}
                                    onClick={() => updateOrderCarrierTracking({ ordersList: restProps.ordersList })}
                                />
                            </>
                        )}
                </Grid>
            </Grid>
        </Box>
    )
}
export const ReturnOrderAckNaked = ReturnOrderAck

ReturnOrderAck.propTypes = {
    order: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ReturnOrderAck)
