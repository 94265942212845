export const SET_ADMIN_TAB = 'SET_ADMIN_TAB'
export const TOGGLE_HISTORY_VIEW = 'TOGGLE_HISTORY_VIEW'
export const SET_MODULE_STATUS_TAB = 'SET_MODULE_STATUS_TAB'
export const TOGGLE_REVIEW_BOX = 'TOGGLE_REVIEW_BOX'
export const SUPPLIER_DATA_REQUEST = 'SUPPLIER_DATA_REQUEST'
export const SUPPLIER_DATA_SUCCESS = 'SUPPLIER_DATA_SUCCESS'
export const SUPPLIER_DATA_FAILURE = 'SUPPLIER_DATA_FAILURE'
export const SET_AR_MONTH_VALUE = 'SET_AR_MONTH_VALUE'
export const SET_SELECTED_SUPPLIER = 'SET_SELECTED_SUPPLIER'
export const RESET_SUPPLIER_SELECTION = 'RESET_SUPPLIER_SELECTION'
