import { CircularProgress, TextField } from '@mui/material'
import { Autocomplete } from '@mui/lab'
import { withStyles } from '@mui/styles';
import clsx from 'clsx'
import { debounce, find } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import AsyncSearchBoxHeader from './AsyncSearchBoxHeader'
import { ReactComponent as Arrow } from '../assets/images/ArrowDownBlue.svg'
import { COLORS } from '../templates/Entry/globalMUIStyles'
import { SSHTypography } from './SSHTypography'

const styles = theme => ({
  input: {
    border: 'none',
    width: '340px',
    margin: '0 auto',
    '& .MuiAutocomplete-option': {
      color: COLORS.grey100
    }
  },
  disabled: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background.disabledGray
    }
  },
  enabled: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.primary.lighter
    }
  },
  inputText: {
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.spacing(0.5),
      width: '340px',
      color: COLORS.grey100,
      fontWeight: 400
    },
    '& .MuiInputBase-input': {
      color: theme.palette.primary.persianBlue
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.persianBlue
    },
    '& .MuiInputLabel-outlined': {
      color: COLORS.main,
      fontWeight: 400
    }
  }
})

class AsyncSearchBox extends Component {
  constructor(props) {
    super(props)
    this.state = { open: false, inputText: '' }
    this.input = React.createRef('')
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.debouncedChange = debounce(this.handleChange, 400)
  }

  static getDerivedStateFromProps = newProps => {
    const options = [...newProps.options]
    if (!!newProps.selectedOption.vendorId && !find(options, { ...newProps.selectedOption })) {
      options.unshift({ ...newProps.selectedOption })
    }
    return { options }
  }

  handleOpen = () => this.setState({ open: true })

  // eslint-disable-next-line react/destructuring-assignment
  handleClose = () => this.setState({ open: false, inputText: '' })

  handleChange = (event, value, reason) => {
    if (reason === 'reset') return false
    const { asyncSearch, clearShipNode } = this.props
    if (reason === 'clear') {
      clearShipNode()
    }

    this.setState({ inputText: value })
    return asyncSearch(value)
  }

  handleSelect = (event, option) => {
    const { selectOption, selectorType } = this.props
    this.setState({ inputText: '' })
    if (selectorType === 'shipnode' && option === null) {
      return selectOption({ name: '', value: '' })
    }
    return selectOption(option)
  }


  render = () => {
    const { open, options, inputText } = this.state
    const { isFetching, classes, selectedOption, size, selectorType, disabled, disableClearable } = this.props
    return (
      <Autocomplete
        disabled={disabled}
        disableClearable={disableClearable}
        open={open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        onChange={this.handleSelect}
        getOptionLabel={option => option.name || ""}
        options={options}
        loading={isFetching}
        data-testid="asyncSearch"
        value={selectedOption}
        popupIcon={<Arrow />}
        placeholder={selectorType === 'shipnode' ? <SSHTypography> by ShipNode name or id </SSHTypography> : <SSHTypography>by Supplier name or id</SSHTypography>}
        noOptionsText={
          String(inputText).length > 3 && !isFetching
            ? <SSHTypography> No vendors found for that query</SSHTypography>
            : <SSHTypography>Type in vendor name or ID to see options</SSHTypography>
        }
        loadingText={<SSHTypography>Searching...</SSHTypography>}
        onInputChange={this.debouncedChange}
        PaperComponent={props => (
          <AsyncSearchBoxHeader childProps={props} loading={isFetching} value={selectedOption} options={options} />
        )}
        className={clsx(classes.input, 'searchVendor')}
        renderInput={params => (
          <TextField
            {...params}
            className={clsx(classes.inputText, disabled ? classes.disabled : classes.enabled)}
            placeholder={selectorType === 'shipnode' ? 'ShipNode name or id' : 'Supplier name or id'}
            fullWidth
            variant="outlined"
            size={size}
            inputRef={ref => {
              this.input = ref
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
    )
  }
}

export const AsyncSearchBoxNaked = AsyncSearchBox

AsyncSearchBox.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  asyncSearch: PropTypes.func.isRequired,
  selectOption: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.object.isRequired,
  size: PropTypes.string,
  selectorType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disableClearable: PropTypes.bool
}

AsyncSearchBox.defaultProps = {
  size: 'medium',
  disabled: false,
  disableClearable: false
}

export default withStyles(styles)(AsyncSearchBox)
