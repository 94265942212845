import React from 'react'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SSHTooltip from '../../components/SSHTooltip'
import { goToReturns } from '../../pages/ReturnOrder/ReturnOrderAction'
import { ArrayFormatter } from './CustomFieldTypeProvider'


const styles = theme => ({
    link: {
        color: theme.palette.primary.blue,
        cursor: 'pointer'
    }
})

const ReturnNumberCell = ({ classes, goToReturns, ...props }) => {

    const tooltip = ''
    const onClick = () => {
        return goToReturns({ returnOrder: props.row })
    }


    return (
        <Table.Cell {...props}>
            <SSHTooltip title={tooltip} placement="top-start">
                <Typography variant="body2" className={classes.link} onClick={onClick}>
                    <ArrayFormatter value={props.value} noTooltip />
                </Typography>
            </SSHTooltip>
        </Table.Cell>
    )
}

ReturnNumberCell.propTypes = {
    classes: PropTypes.object.isRequired,
    props: PropTypes.object.isRequired,
    goToOrder: PropTypes.func.isRequired,
    closedOrder: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => bindActionCreators({ goToReturns }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReturnNumberCell))

export const ReturnNumberNaked = ReturnNumberCell
