import React from 'react'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import SSHPopover from '../../../components/SSHPopover'
import { Box, ListItem, ListItemText } from '@mui/material'
import { withStyles } from '@mui/styles'
import { MENU_ROUTES as ROUTES } from '../../LeftNav/AppRoutes'
import { disabledLink ,getSourceOptions} from '../commonMethods'


const styles = theme => ({
    hoverStyle: {
        '& li': {
            borderBottom: `1px solid ${theme.palette.primary.merianBlue}`
        },
        '& li > div > span': {
            color: theme.palette.primary.persianBlue,
            fontSize: 16,
            fontWeight: 500
        },
        '& div > div > span': {
            color: theme.palette.primary.persianBlue,
            fontSize: 16
        }
    },
    link: {
        cursor: 'pointer'
    }
})


const SubMenu = ({ open, hoverList, handlePopoverClose, handleClick, anchorEl, classes, ...restProps }) => {

    const sourceOptions = getSourceOptions(restProps.selectedSourceType)

    /**
     * @description validate click for main items
     */
    const validateClick = () => {
        if (isEmpty(hoverList?.nav)) {
            handleClick(hoverList.url)
        }
    }

    /**
     * @description get name
     * @returns string
     */
    const getName = () => {
        let name = ROUTES?.find(item => item.url === restProps.pathName)?.name || ''
        if (isEmpty(name)) {
            name = ROUTES?.flatMap(item => item.nav).find(nav => nav.url === restProps.pathName)?.key || ''
        }
        return handlePopoverClose(name)
    }

    /**
     * render submenu
     * @returns  object
     */
    const renderSubmenu = () => {
        return hoverList?.nav.map(item => {
            if (!restProps.isAdmin && item.onlyAdmin !== restProps.isAdmin) {//render admin modules when IAM login
                return null
            }

            if (item.sourceType === 'both' || sourceOptions === item.sourceType) {
                return <ListItem key={item?.id} button disabled={disabledLink(item,
                    restProps.isAdmin,
                    restProps.access)} onClick={() => handleClick(item.url)}>
                    <ListItemText
                        primary={item.name}
                        className={classes.link}
                        data-testid="itemTextHover"
                    />
                </ListItem>
            }
        })
    }

    return (
        <SSHPopover
            id="ssh-popover"
            anchorEl={anchorEl}
            handleClose={getName}
            disableRestoreFocus
            isOpen={open}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}>
            {
                <Box className={classes.hoverStyle}>
                    <ListItem button={isEmpty(hoverList?.nav)} onClick={validateClick}>
                        <ListItemText
                            primary={hoverList?.name}
                            data-testid="itemText"
                        />
                    </ListItem>
                    {renderSubmenu()}
                </Box>
            }
        </SSHPopover>
    )
}
export const SubMenuNaked = SubMenu

const mapStateToProps = state => ({
    selectedSourceType: state.AppConfig.sourceType
})

export default connect(mapStateToProps, null)(withStyles(styles)(SubMenu))
