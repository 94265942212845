const mockVendorResponse = {
  header: {},
  status: 'OK',
  payload: {
    responseType: 'RL',
    userInfo: {
      responseType: null,
      rlId: null,
      firstName: 'Apurv',
      lastName: 'Khare',
      emailId: 'Apurv.Khare@walmart.com',
      phone: null,
      contactCountryCode: null,
      contactPhoneCountry: null,
      uuid: null,
      loginId: 'a0k06nw',
      partnerType: 'external-supplier',
      userRole: null,
      isSiteAdmin: false,
      isExternal: true,
      permissions: [],
      partners: [
        {
          vendorId: '825823',
          displayName: 'Trail Blazer',
          preferred: true
        }
      ]
    },
    appUserRole: 'read',
    customerToken: null,
    appUserRoleInfo: {
      features: ['PM', 'FF', 'FM', 'IM', 'LT', 'FFOD', 'FFCO'],
      configurations: {
        OS_DOWNLOAD_LIMIT: '3000'
      }
    },
    sessionId: null,
    superAdmin: false
  }
}

export default mockVendorResponse
