const config = require('./apiEndPoint.json')

function getConfig(name) {
  const value = config[name]

  if (!value) {
    console.error(`Error - CCM getConfig for ${name}: ${value}`)
  }
  return value
}

module.exports = {
  get: getConfig
}
