import { AppBar, styled } from '@mui/material'

// eslint-disable-next-line import/prefer-default-export
export const SSHAppBar = styled(AppBar)(props => ({
  backgroundColor: props.theme.palette.primary.headerBackground,
  transition: props.theme.transitions.create(['margin', 'width'], {
    easing: props.theme.transitions.easing.sharp,
    duration: props.theme.transitions.duration.leavingScreen
  })
}))
