/* eslint-disable react/require-default-props */
import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import SSHModal from '../../../../components/SSHModal'
import ItemCancellationDetails from './ItemCancellationDetails'
import PopupActions from '../../../../templates/PopupActions'

/**
 * @desc This component renders the popup used to cancel:
 * a. Complete order - order
 * b. Few units of an item - unit
 * c. All units of an item - item
 *
 * @desc Based on popup type, it will show/hide few sections.
 * @desc Popup contains 2 actions on the bottom - Confirm and Cancel
 * @desc Aim to keep this as generic as possible
 */

class CancelPopupGeneric extends PureComponent {
  static titles = {
    order: {
      title: 'Are you sure you want to cancel this order?',
      cancelText: "No, don't cancel this order",
      confirmText: 'Yes, cancel this order'
    },
    unit: {
      title: 'Are you sure you want to cancel these units?',
      cancelText: "No, don't cancel these units",
      confirmText: 'Yes, cancel these units'
    },
    item: {
      title: 'Are you sure you want to cancel this item?',
      cancelText: "No, don't cancel this item",
      confirmText: 'Yes, cancel this item'
    }
  }

  render = () => {
    const { popupType, onCancel, onConfirm, open, classes, itemDetails } = this.props

    if (!open) return null

    const currentPopupType = CancelPopupGeneric.titles[popupType]

    return (
      <SSHModal
        title={currentPopupType.title}
        onClose={onCancel}
        showDivider={false}
        size="sm"
        actions={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <PopupActions
            cancelText={currentPopupType.cancelText}
            confirmText={currentPopupType.confirmText}
            onCancel={onCancel}
            onConfirm={onConfirm}
            alignRight={true}
          />
        }>
        <Typography variant="body2" textColor="secondary" color="textSecondary">
          Once cancelled, it cannot be undone.
        </Typography>
        <br />
        {['item', 'unit'].includes(popupType) && <ItemCancellationDetails {...itemDetails} classes={classes} />}
      </SSHModal>
    )
  }
}

CancelPopupGeneric.propTypes = {
  classes: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  popupType: PropTypes.oneOf(['order', 'unit', 'item']).isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  itemDetails: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    cancelledUnits: PropTypes.number.isRequired,
    orderedUnits: PropTypes.number.isRequired
  })
}

export default CancelPopupGeneric
