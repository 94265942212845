export const TOGGLE_REQUEST_BOX = 'TOGGLE_REQUEST_BOX'

export const FETCH_CURRENT_LEAD_TIME_REQUEST = 'FETCH_CURRENT_LEAD_TIME_REQUEST'
export const FETCH_CURRENT_LEAD_TIME_SUCCESS = 'FETCH_CURRENT_LEAD_TIME_SUCCESS'
export const FETCH_CURRENT_LEAD_TIME_FAILURE = 'FETCH_CURRENT_LEAD_TIME_FAILURE'

export const FETCH_REQUEST_LEAD_TIME_REQUEST = 'FETCH_REQUEST_LEAD_TIME_REQUEST'
export const FETCH_REQUEST_LEAD_TIME_SUCCESS = 'FETCH_REQUEST_LEAD_TIME_SUCCESS'
export const FETCH_REQUEST_LEAD_TIME_FAILURE = 'FETCH_REQUEST_LEAD_TIME_FAILURE'

export const SAVE_LEAD_TIME_REQUEST = 'SAVE_LEAD_TIME_REQUEST'
export const SAVE_LEAD_TIME_SUCCESS = 'SAVE_LEAD_TIME_SUCCESS'
export const SAVE_LEAD_TIME_FAILURE = 'SAVE_LEAD_TIME_FAILURE'

export const APPROVE_REJECT_LEAD_TIME_REQUEST = 'APPROVE_REJECT_LEAD_TIME_REQUEST'
export const APPROVE_REJECT_LEAD_TIME_SUCCESS = 'APPROVE_REJECT_LEAD_TIME_SUCCESS'
export const APPROVE_REJECT_LEAD_TIME_FAILURE = 'APPROVE_REJECT_LEAD_TIME_FAILURE'
export const MARK_TASK_AS_REJECTED_STORE = 'MARK_TASK_AS_REJECTED_STORE'

export const SET_SELECTED_SHIP_NODE_ID = 'SET_SELECTED_SHIP_NODE_ID'
export const CLEAR_SELECTED_SHIP_NODE_ID = 'CLEAR_SELECTED_SHIP_NODE_ID'

export const CLEAR_CURRENT_LEAD_TIME = 'CLEAR_CURRENT_LEAD_TIME'
export const TOGGLE_PENDING_BOX = 'TOGGLE_PENDING_BOX'

export const CANCELLED_LEAD_TIME_REQUEST = 'CANCELLED_LEAD_TIME_REQUEST'
export const CANCELLED_LEAD_TIME_SUCCESS = 'CANCELLED_LEAD_TIME_SUCCESS'
export const CANCELLED_LEAD_TIME_FAILURE = 'CANCELLED_LEAD_LEAD_TIME_FAILURE'

export const MARK_TASK_AS_CANCELED_STORE = 'MARK_TASK_AS_CANCELED_STORE'
export const RESET_LEAD_TIME_HISTORY = 'RESET_LEAD_TIME_HISTORY'

export const SET_LEAD_TIME_COUNT = 'SET_LEAD_TIME_COUNT'
export const SET_REPORT_DATE = 'SET_REPORT_DATE'
export const SET_LOADING_FLAG = 'SET_LOADING_FLAG'
