import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { Typography } from '@mui/material'
import { compact, isArray } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import { SSHLink } from '../../components/SSHLink'
import SSHTooltip from '../../components/SSHTooltip'
import { getDecimalValue, isProdEnvironment, validateEmail } from '../../utils/common'

/**
 * @desc Convert 112.98 to $112.98
 * @param {string} value
 * @returns {string}
 */
const CurrencyFormatter = ({ value }) => `$${getDecimalValue({ value })}`
CurrencyFormatter.propType = {
  value: PropTypes.string.isRequired
}

/**
 * @desc Convert "2020-03-28 02:03:05" to "03/28/2020"
 * @param {string} value
 * @returns {string}
 */
const TimeFormatter = ({ value }) => {
  const date = moment(value, 'YYYY-MM-DD H:mm:ss')
  if (date && date.isValid()) return date.format('MM/DD/YYYY')
  return isProdEnvironment() ? (
    ''
  ) : (
    <Typography variant="caption" color="textSecondary">
      Invalid date
    </Typography>
  )
}
TimeFormatter.propType = {
  value: PropTypes.string.isRequired
}

/**
 * @desc Convert ['a', 'b', 'c'] to "a,b,c" in SSHPaginatedTable
 * @param {Array} value
 * @param {boolean} noTooltip
 * @returns {JSX.Element}
 */
export const ArrayFormatter = ({ value, noTooltip }) => {
  const compactValue = isArray(value) ? compact(value) : compact([value])
  const output = <span>{compactValue.length > 0 ? compactValue.join(', ') : '-'}</span>
  if (noTooltip) return output
  return (
    <SSHTooltip title={compactValue.join(', ')} placement="right">
      {output}
    </SSHTooltip>
  )
}

ArrayFormatter.defaultProps = {
  noTooltip: false
}
ArrayFormatter.propTypes = {
  noTooltip: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired
}

/**
 * @desc Wraps a given string in SSHTooltip component
 * @param {String} value
 * @returns {JSX.Element}
 */
const TooltipFormatter = ({ value }) => (
  <SSHTooltip title={value} placement="right">
    <span>{value}</span>
  </SSHTooltip>
)
TooltipFormatter.propTypes = {
  value: PropTypes.string.isRequired
}

/**
 * @desc Wrap email in a <Link> component with mailto
 * @param {string} value
 * @returns {JSX.Element}
 */
const EmailFormatter = ({ value }) => {
  if (validateEmail(value)) {
    return (
      <SSHTooltip title={value}>
        <SSHLink color="textSecondary" noWrap={true} href={`mailto:${value}`}>
          {value}
        </SSHLink>
      </SSHTooltip>
    )
  }
  return value
}

EmailFormatter.propTypes = {
  value: PropTypes.string.isRequired
}

const availableFilterOperations = [
  'equal',
  'notEqual',
  'greaterThan',
  'greaterThanOrEqual',
  'lessThan',
  'lessThanOrEqual'
]

const FormatterMapping = {
  tooltip: TooltipFormatter,
  currency: CurrencyFormatter,
  dateTime: TimeFormatter,
  array: ArrayFormatter,
  email: EmailFormatter
}

export const CustomFieldTypeProvider = ({ colType, ...props }) => {
  return (
    <DataTypeProvider
      formatterComponent={FormatterMapping[colType]}
      availableFilterOperations={availableFilterOperations}
      {...props}
    />
  )
}
