import {  Badge } from '@mui/material'
import { withStyles } from '@mui/styles'

// eslint-disable-next-line import/prefer-default-export
export const SSHBadge = withStyles(theme => ({
  badge: {
    backgroundColor: theme.palette.primary.blue,
    color: theme.palette.primary.white,
    height: 16,
    width: 21,
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.6),
    paddingRight: theme.spacing(0.6),
    fontSize: 10
  }
}))(Badge)
