export const OPERATING_CALENDAR = [
    {
        day: "sunday",
        workingDay: false,
        cutOffTime: null
    },
    {
        day: "monday",
        workingDay: false,
        cutOffTime: null
    },
    {
        day: "tuesday",
        workingDay: false,
        cutOffTime: null
    },
    {
        day: "wednesday",
        workingDay: false,
        cutOffTime: null
    },
    {
        day: "thursday",
        workingDay: false,
        cutOffTime: null
    },
    {
        day: "friday",
        workingDay: false,
        cutOffTime: null
    },
    {
        day: "saturday",
        workingDay: false,
        cutOffTime: null
    }
]

export const ROLES_UPDATE_ERROR_MESSAGE = 'The roles update for the supplier failed. Please try again later.'
export const GET_FEATURES_ERROR_MESSAGE = 'There was a error while fetching roles for the supplier. Please try again.'
