import React from 'react'
import { Drawer, IconButton, Grid, Box, Accordion, AccordionSummary } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { displayWeeklyForecast } from '../../../pages/Club/SaleForecast/ForecastAction'
import SSHButton from '../../../components/SSHButton'
import { SSHTypography } from '../../../components/SSHTypography'
import { ReactComponent as TrendingIconDisabled } from '../../../assets/images/TrendingDisabled.svg'
import { dateFirstFormat } from '../../../config/genericConstants'


const styles = theme => ({
    container: {
        width: 387
    },
    headerContainer: {
        backgroundColor: theme.palette.background.lightGray,
        maxHeight: 76
    },
    headerText: {
        fontSize: theme.spacing(2.4),
        fontWeight: 500
    },
    subText: {
        fontSize: theme.spacing(1.4),
        color: theme.palette.primary.softBlue
    },
    footerContainer: {
        backgroundColor: theme.palette.background.magnolia
    },
    footerButton: {
        fontSize: theme.spacing(2),
        fontWeight: 500,
        height: 34,
        width: '100%',
        margin: theme.spacing(2.4),
        '& .MuiButton-label': {
            height: 30
        }
    }
})

const WeeklyForecastDrawer = ({
    classes,
    selectedClub,
    selectedItem,
    displayWeeklyClubForecast,
    displayWeeklyForecast
}) => {

    /**
     * Handles on drawer close
     * @return {function} displayWeeklyForecast
     */
    const onDrawerClose = () => {
        return displayWeeklyForecast({ clubDetails: {}, displayForecastDrawer: false })
    }

    return (
        <Drawer anchor="right" open={displayWeeklyClubForecast} onClose={onDrawerClose}>
            <Grid className={classes.container} container justifyContent="space-between">
                <Grid className={classes.headerContainer} container item alignItems="center" xs={12} direction="row">
                    <Grid item xs={1}>
                        <IconButton style={{ cursor: 'auto' }}><TrendingIconDisabled /></IconButton>
                    </Grid>
                    <Grid container item direction="column" xs={8}>
                        <Grid item xs={12}>
                            <Box paddingTop="15px" marginLeft="25px" height={76}>
                                <SSHTypography variant="h6" className={classes.headerText}>
                                    Weekly forecast
                                </SSHTypography>
                                <SSHTypography variant="subtitle1" className={classes.subText}>
                                    Item Id : {selectedItem.itemId} & Club Id : {selectedClub.clubId}
                                </SSHTypography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item xs={12}>
                    <Box overflow="scroll" height="calc(100vh - 135px)" marginLeft="16px">
                        {selectedClub?.weeklyClubInventory?.map(week => (<Accordion expanded={false}>
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                                <Grid container display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <SSHTypography data-testid="weeklyForecast">
                                            Week {week.walmartWeek} ({moment(week.weekStartDate).format(dateFirstFormat)} - {moment(week.weekEndDate).format(dateFirstFormat)})
                                        </SSHTypography>
                                        <SSHTypography className={classes.subText}>Sam's forecasted qty : {week.samsForecastedQty?.toLocaleString() || '-'} & Supplier forecasted qty : {week.supplierForecastedQty?.toLocaleString() || '-'}</SSHTypography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                        </Accordion>))}
                    </Box>
                </Grid>

                <Grid className={classes.footerContainer} item container justifyContent="space-evenly" alignItems="center" xs={12}>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-evenly" alignItems="center" height={60}>
                            <SSHButton className={classes.footerButton} variant="contained" color="primary" size="medium" text="Close" onClick={onDrawerClose} />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Drawer>
    )
}

WeeklyForecastDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    selectedClub: PropTypes.object.isRequired,
    selectedItem: PropTypes.object.isRequired,
    displayWeeklyClubForecast: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
    displayWeeklyClubForecast: state.ClubSaleForecast.displayWeeklyClubForecast,
    selectedClub: state.ClubSaleForecast.selectedClub,
    selectedItem: state.ClubSaleForecast.selectedItem
})

export const WeeklyForecastDrawerNaked = WeeklyForecastDrawer

const mapDispatchToProps = dispatch =>
    bindActionCreators({ displayWeeklyForecast }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(React.memo(WeeklyForecastDrawer)))
