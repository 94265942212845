/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { CHART_DURATION, CHART_VIEWS, FILTER_KEYS, ITEM_ACTION_TYPES, OOS_STATUS } from '../../config/genericConstants'
import { getAllSubcategoriesFromAllCategories, prepareSmartInventoryApiRequest } from '../../utils/common'
import {
  resetFiltersToDefault,
  resetSuppliersAndCategoriesSelection,
  setApiFilterForIds,
  setApiFilterForSubcategoryNumbers
} from '../../utils/parsers'
import {
  SET_SMART_INVENTORY_ROW_COUNT,
  SET_SMART_INVENTORY_TAB,
  SET_FILTERS,
  REMOVE_FILTER,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_SUPPLIERS_SUCCESS,
  SET_SELECTED_FILTERS,
  RESET_FILTER,
  SHOW_FILTERS_POPUP,
  HIDE_FILTERS_POPUP,
  SET_SMART_INVENTORY_AGGREGATED_ROWS,
  RESET_TAB,
  UPDATE_ALERT_TYPE,
  UPDATE_ALERT_TYPE_CHIP,
  FETCH_CATEGORIES_FAILURE,
  FETCH_SUPPLIERS_FAILURE,
  UPDATE_API_FILTER,
  SHOW_ACTION_CONFIRMATION_POPUP,
  HIDE_ACTION_CONFIRMATION_POPUP,
  SHOW_SUSPEND_ALERT_POPUP,
  HIDE_SUSPEND_ALERT_POPUP,
  SHOW_TERMINATE_ALERT_POPUP,
  HIDE_TERMINATE_ALERT_POPUP,
  SET_ITEM_DETAILS,
  SET_OPEN_FILTER,
  FETCH_DIVISIONS_SUCCESS,
  FETCH_DIVISIONS_FAILURE,
  UPDATE_COLUMN_VISIBILITY,
  FETCH_PAST_TRENDS_RL_DATA_FAILURE,
  FETCH_PAST_TRENDS_RL_DATA_SUCCESS,
  FETCH_PAST_TRENDS_OOS_DATA_SUCCESS,
  FETCH_PAST_TRENDS_OOS_DATA_FAILURE,
  UPDATE_CHART_VIEW,
  UPDATE_CHART_DURATION,
  FETCH_PAST_TRENDS_RL_DATA_REQUEST,
  FETCH_PAST_TRENDS_OOS_DATA_REQUEST
} from './SmartInventory.actionTypes'
import { SMART_INVENTORY_TABS } from './SmartInventoryTabSwitcher'

const defaultFilters = [
  { filterName: FILTER_KEYS.COLUMNS, filterValues: [] },
  { filterName: FILTER_KEYS.DIVISION, filterValues: [] },
  { filterName: FILTER_KEYS.CATEGORY, filterValues: [] },
  { filterName: FILTER_KEYS.SUBCATEGORY, filterValues: [] },
  { filterName: FILTER_KEYS.SUPPLIER_ID, filterValues: [] }
]

/** Do not change the sequence of selected and api filters anywhere */
export const INITIAL_STATE = {
  totalRows: 0,
  filters: {},
  selectedFilters: defaultFilters,
  apiRequestFilters: [{ filterName: FILTER_KEYS.DIVISION, filterValues: [] }],
  smartInventoryTab: SMART_INVENTORY_TABS.DIVISION,
  showFiltersPopup: false,
  divisions: [],
  categories: [],
  suppliers: [],
  smartInventoryAggregatedRows: [],
  alertType: OOS_STATUS[0],
  selectedAlertType: OOS_STATUS[0],
  showActionConfirmationPopup: false,
  showSuspendAlertPopup: false,
  showTerminateAlertPopup: false,
  selectedItemDetails: {
    alertId: '',
    itemId: '',
    itemName: ''
  },
  alertActionType: ITEM_ACTION_TYPES.SUSPEND,
  noOfDays: 0,
  selectedReplenishmentDate: moment(),
  selectedReason: '',
  openFilter: false,
  columnVisibility: [],
  pastTrendsOOSData: [],
  pastTrendsRLData: [],
  chartView: CHART_VIEWS[1],
  chartDuration: CHART_DURATION[CHART_VIEWS[1].value][1],
  isPastTrendsOOSFetching: false,
  isPastTrendsRLFetching: false
}

const filterEditor = (updatedFilterObj, existing) => {
  const selectedValues = existing.filter(item => item.active === true)
  if (selectedValues.length > 1) {
    existing.forEach(item => {
      if (item.value === updatedFilterObj.value) {
        item.active = false
      }
    })
  } else {
    existing.forEach(item => {
      item.active = true
    })
  }
}

const updateFormattedFilters = (updatedFilterObj, stateFilterObj) => {
  switch (updatedFilterObj.filterName) {
    case FILTER_KEYS.SUPPLIER_ID:
      filterEditor(updatedFilterObj, stateFilterObj.formattedSuppliers)
      break

    case FILTER_KEYS.CATEGORY:
      filterEditor(updatedFilterObj, stateFilterObj.formattedCategories)
      break

    case FILTER_KEYS.DIVISION:
      filterEditor(updatedFilterObj, stateFilterObj.formattedDivisions)
      break

    default:
      filterEditor(updatedFilterObj, stateFilterObj.formattedSubCategories)
  }
}

const resetToLinkedFilters = (linkedFilter, state) => {
  let linkedSelectedFilter
  let linkedApiFilter
  let newFilters
  if (linkedFilter) {
    newFilters = resetSuppliersAndCategoriesSelection({
      ...state.filters,
      linkedFilter,
      selectedFilters: state.selectedFilters
    })
    if (linkedFilter.filterName === FILTER_KEYS.CATEGORY) {
      linkedSelectedFilter = [
        { filterName: FILTER_KEYS.CATEGORY, filterValues: [linkedFilter.title] },
        { filterName: FILTER_KEYS.SUBCATEGORY, filterValues: [] },
        { filterName: FILTER_KEYS.SUPPLIER_ID, filterValues: [] }
      ]
      linkedApiFilter = [
        ...linkedSelectedFilter,
        { filterName: FILTER_KEYS.CATEGORY_ID, filterValues: [linkedFilter.id] },
        { filterName: FILTER_KEYS.SUBCATEGORY_NUM, filterValues: [] }
      ]
    } else if (linkedFilter.filterName === FILTER_KEYS.SUPPLIER_ID) {
      linkedSelectedFilter = [
        ...state.selectedFilters.filter(item => item.filterName !== FILTER_KEYS.SUPPLIER_ID),
        { filterName: FILTER_KEYS.SUPPLIER_ID, filterValues: [linkedFilter.id] }
      ]
      linkedApiFilter = [...linkedSelectedFilter]
      setApiFilterForIds(newFilters, linkedApiFilter)
      setApiFilterForSubcategoryNumbers(newFilters, linkedApiFilter)
    }
  }
  return [newFilters, linkedSelectedFilter, linkedApiFilter]
}

export default (state = cloneDeep(INITIAL_STATE), action = {}) => {
  switch (action.type) {
    case SET_SMART_INVENTORY_ROW_COUNT:
      return {
        ...state,
        totalRows: action.payload.count
      }

    case SET_SMART_INVENTORY_TAB: {
      const { tabName } = action.payload
      return {
        ...state,
        smartInventoryTab: tabName
      }
    }

    case SET_FILTERS: {
      return {
        ...state,
        filters: { ...action.payload }
      }
    }

    case SET_SELECTED_FILTERS: {
      return {
        ...state,
        selectedFilters: [...action.payload.selectedFilters],
        apiRequestFilters: [...action.payload.apiRequestFilters]
      }
    }

    case REMOVE_FILTER: {
      const existingValues = state.selectedFilters.find(filter => filter.filterName === action.payload.filterName)
        .filterValues
      const removedValue = existingValues.indexOf(action.payload.value)
      existingValues.splice(removedValue, 1)
      const updatedFilters = state.selectedFilters.map(filterItem => {
        if (filterItem.filterName === action.payload.filterName && existingValues.length > 0) {
          filterItem.filterValues = existingValues
        }
        return filterItem
      })

      updateFormattedFilters(action.payload, state.filters)

      return {
        ...state,
        selectedFilters: [...updatedFilters],
        filters: { ...state.filters }
      }
    }

    case UPDATE_API_FILTER: {
      const apiRequestFilters = []
      // TODO Check why Appconfig state is undefined here in case of aggregated api download.
      // If not possible here then retrieve in actions
      const supplierId = state.AppConfig?.vendor?.selectedVendor?.vendorId
      const isSupplier = state.UserInfo?.isVendor
      prepareSmartInventoryApiRequest(state.filters, apiRequestFilters, isSupplier, supplierId, state.smartInventoryTab)
      return {
        ...state,
        apiRequestFilters: [...apiRequestFilters]
      }
    }

    case FETCH_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: action.payload.categories,
        subcategories: getAllSubcategoriesFromAllCategories(action.payload.categories)
      }
    }

    case FETCH_DIVISIONS_SUCCESS: {
      return {
        ...state,
        divisions: action.response.payload.divisions
      }
    }

    case FETCH_SUPPLIERS_SUCCESS: {
      return {
        ...state,
        suppliers: action.payload.suppliers ? [...action.payload.suppliers] : [action.payload]
      }
    }

    case FETCH_CATEGORIES_FAILURE: {
      return {
        ...state,
        categories: []
      }
    }

    case FETCH_DIVISIONS_FAILURE: {
      return {
        ...state,
        divisions: []
      }
    }

    case FETCH_SUPPLIERS_FAILURE: {
      return {
        ...state,
        suppliers: []
      }
    }

    case SET_SMART_INVENTORY_AGGREGATED_ROWS: {
      return {
        ...state,
        smartInventoryAggregatedRows: action.payload.aggregatedRows
      }
    }

    case RESET_FILTER: {
      const { linkedFilter, isVendor, resetDropdown } = action.payload
      const [newFilters, linkedSelectedFilter, linkedApiFilter] = resetToLinkedFilters(linkedFilter, state)

      if (state.filters === undefined || state.filters.formattedCategories === undefined) {
        return {
          ...state,
          selectedFilters: INITIAL_STATE.selectedFilters,
          apiRequestFilters: INITIAL_STATE.apiRequestFilters,
          chartView: INITIAL_STATE.chartView,
          chartDuration: INITIAL_STATE.chartDuration
        }
      }

      return {
        ...state,
        filters:
          newFilters ||
          resetFiltersToDefault(
            state.divisions,
            state.suppliers,
            state.smartInventoryTab,
            isVendor,
            state.filters,
            resetDropdown
          ),
        selectedFilters: linkedSelectedFilter || INITIAL_STATE.selectedFilters,
        apiRequestFilters: linkedApiFilter || INITIAL_STATE.apiRequestFilters,
        chartView: INITIAL_STATE.chartView,
        chartDuration: INITIAL_STATE.chartDuration
      }
    }

    case SHOW_FILTERS_POPUP: {
      return {
        ...state,
        showFiltersPopup: true
      }
    }

    case HIDE_FILTERS_POPUP: {
      return {
        ...state,
        showFiltersPopup: false
      }
    }

    case SHOW_ACTION_CONFIRMATION_POPUP: {
      return {
        ...state,
        showActionConfirmationPopup: true,
        alertActionType: action.payload.alertActionType,
        noOfDays: action.payload.noOfDays,
        selectedReplenishmentDate: action.payload.selectedReplenishmentDate,
        selectedReason: action.payload.reason
      }
    }

    case HIDE_ACTION_CONFIRMATION_POPUP: {
      return {
        ...state,
        showActionConfirmationPopup: false
      }
    }

    case SHOW_SUSPEND_ALERT_POPUP: {
      return {
        ...state,
        showSuspendAlertPopup: true
      }
    }

    case HIDE_SUSPEND_ALERT_POPUP: {
      return {
        ...state,
        showSuspendAlertPopup: false
      }
    }

    case SHOW_TERMINATE_ALERT_POPUP: {
      return {
        ...state,
        showTerminateAlertPopup: true
      }
    }

    case HIDE_TERMINATE_ALERT_POPUP: {
      return {
        ...state,
        showTerminateAlertPopup: false
      }
    }

    case SET_ITEM_DETAILS: {
      return {
        ...state,
        selectedItemDetails: action.payload.itemDetails
      }
    }

    case RESET_TAB: {
      return {
        ...state,
        smartInventoryTab: SMART_INVENTORY_TABS.DIVISION
      }
    }

    case UPDATE_ALERT_TYPE: {
      return {
        ...state,
        alertType: action.payload
      }
    }

    case UPDATE_ALERT_TYPE_CHIP: {
      return {
        ...state,
        selectedAlertType: action.payload
      }
    }

    case SET_OPEN_FILTER: {
      return {
        ...state,
        openFilter: action.payload
      }
    }

    case UPDATE_COLUMN_VISIBILITY: {
      return {
        ...state,
        visibleColumns: action.payload
      }
    }

    case FETCH_PAST_TRENDS_RL_DATA_REQUEST: {
      return {
        ...state,
        isPastTrendsRLFetching: true,
        pastTrendsRLData: []
      }
    }

    case FETCH_PAST_TRENDS_RL_DATA_SUCCESS: {
      return {
        ...state,
        isPastTrendsRLFetching: false,
        pastTrendsRLData: action?.payload?.trendData
      }
    }

    case FETCH_PAST_TRENDS_RL_DATA_FAILURE: {
      return {
        ...state,
        isPastTrendsRLFetching: false,
        pastTrendsRLData: []
      }
    }

    case FETCH_PAST_TRENDS_OOS_DATA_REQUEST: {
      return {
        ...state,
        isPastTrendsOOSFetching: true,
        pastTrendsOOSData: []
      }
    }

    case FETCH_PAST_TRENDS_OOS_DATA_SUCCESS: {
      return {
        ...state,
        isPastTrendsOOSFetching: false,
        pastTrendsOOSData: action?.payload?.trendData
      }
    }

    case FETCH_PAST_TRENDS_OOS_DATA_FAILURE: {
      return {
        ...state,
        isPastTrendsOOSFetching: false,
        pastTrendsOOSData: []
      }
    }

    case UPDATE_CHART_VIEW: {
      return {
        ...state,
        chartView: action.payload,
        chartDuration: CHART_DURATION[action.payload.value][0]
      }
    }

    case UPDATE_CHART_DURATION: {
      return {
        ...state,
        chartDuration: action.payload
      }
    }

    default:
      return { ...state }
  }
}
