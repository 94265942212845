import { Box, Collapse, IconButton, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import SSHTooltip from './SSHTooltip'

const styles = theme => ({
  boxBg: {
    backgroundColor: theme.palette.primary.icyLilac
  },
  closeBoxBg: {
    backgroundColor: theme.palette.primary.titleBarBackground,
    borderBottom: `1px solid ${theme.palette.primary.columbiaBlue}`
  },
  iconBtn: {
    padding: 0,
    fontSize: '1.5em',
    marginRight: theme.spacing(1),
    position: 'relative',
    top: '-12px'
  },
  clickable: {
    cursor: 'pointer'
  },
  title: {
    color: theme.palette.primary.downloadLabelText,
    fontSize: theme.spacing(2.2),
    lineHeight: theme.spacing(2.8),
    '& span': {
      color: theme.palette.primary.tropaz,
      fontSize: theme.spacing(1.9),
      fontWeight: 'normal'
    }
  }
})

const CollapsiblePanel = ({ defaultOpen, classes, title, children, caption, padding }) => {
  const [expanded, setExpanded] = useState(defaultOpen)
  const togglePanel = () => setExpanded(!expanded)

  return (
    <Box p={padding || 2} paddingTop={0} paddingBottom={1} className={expanded ? classes.boxBg : classes.closeBoxBg}>
      <Box
        paddingTop={2}
        paddingBottom={expanded ? 2 : 0}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.clickable}
        onClick={togglePanel}>
        {/* <Box  > */}
        <SSHTooltip title={`Click here to ${expanded ? 'collapse' : 'expand'}`}>
          <IconButton onClick={togglePanel} className={classes.iconBtn} size="large">
            {expanded ? (
              <KeyboardArrowUp color="primary" fontSize="14" />
            ) : (
              <KeyboardArrowDown color="primary" fontSize="14" />
            )}
          </IconButton>
        </SSHTooltip>
        <Typography variant="body2" className={classes.title}>
          <div>{title}</div>
          <span> {caption}</span>
        </Typography>
      </Box>
      <Collapse in={expanded} collapsedSize={0} unmountOnExit mountOnEnter>
        {children}
      </Collapse>
    </Box>
  );
}

CollapsiblePanel.defaultProps = {
  defaultOpen: false,
  caption: ''
}

CollapsiblePanel.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  defaultOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  caption: PropTypes.string
}

export default withStyles(styles)(CollapsiblePanel)
