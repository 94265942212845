import { get, includes } from 'lodash'
import moment from 'moment'
import config from '../../config'
import {
  PICK_LIST_DOWNLOAD_FAILED,
  PICK_LIST_DOWNLOAD_IN_PROGRESS,
  SELECT_ONLY_100_ROWS
} from '../../config/errorConstants'
import { AUTO_LABEL_CARRIER_ACCOUNT, FULFILLMENT_TYPES } from '../../config/genericConstants'
// eslint-disable-next-line import/no-cycle
import { fetchShipNodes, hideToastMessage, showToastMessage } from '../../reduxSetup/commonActions/AppConfig'
import { resetTable } from '../../reduxSetup/commonActions/SSHPaginatedTable'
import {
  FETCH_PAGINATED_TABLE_DATA_FAILURE,
  FETCH_PAGINATED_TABLE_DATA_REQUEST,
  FETCH_PAGINATED_TABLE_DATA_SUCCESS
} from '../../reduxSetup/commonActions/SSHPaginatedTable.actionTypes'
import {
  aiFulfillmentDownloadPickList,
  aiFulfillmentSwitchOrderType,
  aiFulfillmentSwitchTab,
  aiPicklistGenerationFailed
} from '../../reduxSetup/commonActions/TelemetryActions'
import { CALL_API } from '../../reduxSetup/middleware/API'
import {
  downloadPDF,
  getOrderTypeFromName,
  getShipNodeIdFromState,
  getVendorIdFromState,
  getOrderSearchParameter,
  isError
} from '../../utils/common'
import {
  CHANGE_SELECTED_ORDER_TYPE,
  CHANGE_TAB_FULFILLMENT,
  CHECK_PICK_LIST_DL_STATUS_FAILURE,
  CHECK_PICK_LIST_DL_STATUS_REQUEST,
  CHECK_PICK_LIST_DL_STATUS_SUCCESS,
  FETCH_CARRIER_METHODS_FAILURE,
  FETCH_CARRIER_METHODS_REQUEST,
  FETCH_CARRIER_METHODS_SUCCESS,
  FETCH_CARRIER_ACCOUNT_FAILURE,
  FETCH_CARRIER_ACCOUNT_REQUEST,
  FETCH_CARRIER_ACCOUNT_SUCCESS,
  UPDATE_CARRIER_ACCOUNT_FAILURE,
  UPDATE_CARRIER_ACCOUNT_REQUEST,
  UPDATE_CARRIER_ACCOUNT_SUCCESS,
  FETCH_ORDER_COUNT_FAILURE,
  FETCH_ORDER_COUNT_REQUEST,
  FETCH_ORDER_COUNT_SUCCESS,
  GET_PICK_LIST_FILE_FAILURE,
  GET_PICK_LIST_FILE_REQUEST,
  GET_PICK_LIST_FILE_SUCCESS,
  INITIATE_PICK_LIST_DL_FAILURE,
  INITIATE_PICK_LIST_DL_REQUEST,
  INITIATE_PICK_LIST_DL_SUCCESS,
  RESET_FULFILLMENT_DATA,
  SEARCH_ORDER_REQUEST,
  SEARCH_ORDER_SUCCESS,
  SEARCH_ORDER_FAILURE,
  SET_SEARCH_KEY_VALUE,
  CLEAR_SEARCH_KEY_VALUE,
  CLEAR_ORDER_SEARCH,
  REMOVE_DATE_FILTER,
  SET_ORDER_STATUS,
  REMOVE_STATUS_FILTER,
  SEARCH_PARAMETER_COUNT,
  SEARCH_ORDER_SORTING,
  TOGGLE_CARRIER_REGISTRATION_POPUP,
  MAINTAIN_STATUS_TAB,
  SET_ORDER_SORT_TYPE
} from './Fulfillment.types'

export const resetFulfillmentData = () => dispatch => {
  return dispatch({
    type: RESET_FULFILLMENT_DATA
  })
}

/**
 * @description Fetch all available shipping methods for a ship node / vendor
 * @return {function(*, *=): (Promise<void>)}
 */
export const fetchCarrierMethods = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId

  if (!VENDOR_ID || !SHIP_NODE_ID) return Promise.resolve()

  return dispatch({
    [CALL_API]: {
      types: [FETCH_CARRIER_METHODS_REQUEST, FETCH_CARRIER_METHODS_SUCCESS, FETCH_CARRIER_METHODS_FAILURE],
      endpoint: `${config.get('carrierMethods')}/${VENDOR_ID}/shipnode/${SHIP_NODE_ID}/carriers`,
      method: 'GET'
    }
  })
}

/**
 * @description Fetch carrier shipping account for Prepaid orders along with supported shipping methods
 * @see https://confluence.walmart.com/display/SASAMSUCE/API+Design+%3A+Label+Generation
 * @return {function(*, *=): (Promise<void>)}
 */
export const fetchCarrierAccount = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)

  if (!VENDOR_ID) return Promise.resolve()

  return dispatch({
    [CALL_API]: {
      types: [FETCH_CARRIER_ACCOUNT_REQUEST, FETCH_CARRIER_ACCOUNT_SUCCESS, FETCH_CARRIER_ACCOUNT_FAILURE],
      endpoint: `${config.get('newCarrierMethods')}/${VENDOR_ID}/carrier/${AUTO_LABEL_CARRIER_ACCOUNT}/account`,
      method: 'GET'
    }
  })
}

/**
 * @description Add/update prepaid carrier account for a vendor
 * @param {string} carrier
 * @param {string} accountNumber
 * @param {string} shippingStreets
 * @param {string} shippingCity
 * @param {string} shippingState
 * @param {string} shippingPostalCode
 * @param {string} shippingCountryCode
 * @param {string} corporateFirstName
 * @param {string} corporateLastName
 * @param {string} corporateJobTitle
 * @param {string} corporateCompanyName
 * @param {string} corporatePhoneNumber
 * @param {string} corporateEmailAddress
 * @param {string} corporateStreets
 * @param {string} corporateCity
 * @param {string} corporateState
 * @param {string} corporatePostalCode
 * @param {string} corporateCountryCode
 * @return {function(*, *=): (Promise<void>)}
 */
export const updateCarrierAccount = requestParams => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)

  if (!VENDOR_ID) return Promise.resolve()

  return dispatch({
    [CALL_API]: {
      types: [UPDATE_CARRIER_ACCOUNT_REQUEST, UPDATE_CARRIER_ACCOUNT_SUCCESS, UPDATE_CARRIER_ACCOUNT_FAILURE],
      endpoint: `${config.get('carrierMethods')}/${VENDOR_ID}/carrier/account`,
      method: 'POST',
      data: {
        description: 'SAMS-SSH-LOBO',
        reference: null,
        registrationData: requestParams
      }
    }
  })
    .then(response => {
      if (isError(response)) throw response

      if (get(response, 'type', '') === UPDATE_CARRIER_ACCOUNT_SUCCESS) {
        return dispatch({
          type: FETCH_CARRIER_ACCOUNT_SUCCESS,
          response: {
            payload: {
              vendorCarrierAccountDetails: [
                {
                  carrierAccountId: requestParams.accountNumber,
                  vendorId: VENDOR_ID,
                  carrierId: AUTO_LABEL_CARRIER_ACCOUNT
                }
              ]
            }
          }
        })
      }
      return Promise.resolve()
    })
    .catch(error => {
      throw error
    })
}

export const toggleCarrierRegistrationPopup = () => dispatch => {
  return Promise.resolve(
    dispatch({
      type: TOGGLE_CARRIER_REGISTRATION_POPUP
    })
  )
}
/**
 * @description Use this action creator to switch between different order types in Fulfillment page
 * @param {String} orderType
 * @returns {Function} Redux Action
 */

export const changeSelectedOrderType = orderType => (dispatch, getState) => {
  const { tabView } = FULFILLMENT_TYPES.filter(type => type.statusType === orderType)[0]
  const { selectedOrderType } = getState().FulfillmentNew
  return Promise.resolve(
    dispatch({
      type: CHANGE_SELECTED_ORDER_TYPE,
      payload: { orderType }
    })
  )
    .then(dispatch(resetTable()))
    .then(dispatch(setTabView({ tabView })))
    .then(dispatch(fetchOrderRows()))
    .then(dispatch(aiFulfillmentSwitchOrderType()))
    .then(() => {
      if (selectedOrderType !== orderType) {
        dispatch(fetchAllOrderCounts())
      }
    })
}

/**
 * @description Use this action creator to reset order status type to active or default
 * @returns {Function} Redux Action
 */

export const resetSelectedOrderType = orderType => dispatch => {
  return Promise.resolve(
    dispatch({
      type: CHANGE_SELECTED_ORDER_TYPE,
      payload: { orderType }
    })
  )
}

/**
 * @description reset all search params
 *
 *
 */
export const resetSearch = () => dispatch => {
  return Promise.resolve(
    dispatch({
      type: CLEAR_ORDER_SEARCH
    })
  ).then(dispatch(resetTable()))
}

/**
 * @description Use this action creator to switch between `active` & `closed` tabs in Fulfillment page
 * @param {Boolean} closed
 * @returns {Function} Redux Action
 */
export const changeSelectedFulfillmentTab = ({ closed }) => (dispatch, getState) => {
  const tabView = closed ? 'Closed' : 'Active'
  let SELETCED_ORDER_TYPE = getState().FulfillmentNew.selectedOrderType
  const STATUS = getState().FulfillmentNew.maintainStatusTab
  return Promise.resolve(
    dispatch({
      type: CHANGE_TAB_FULFILLMENT,
      payload: { tabView }
    })
  )
    .then(dispatch({
      type: SET_ORDER_SORT_TYPE,
      payload: { tabView }
    }))
    .then(dispatch(resetTable()))
    .then(() => {
      if (tabView === 'Closed') {
        dispatch(resetSearch()) // reset search on switch tab
      }
    })
    .then(() => {
      if (!STATUS) {
        if (closed) {
          SELETCED_ORDER_TYPE = FULFILLMENT_TYPES.filter(orderType => orderType.tabView === tabView)[0].statusType
        } else if (!closed) { SELETCED_ORDER_TYPE = FULFILLMENT_TYPES.filter(orderType => orderType.tabView === tabView)[0].statusType }
      }

      return dispatch(changeSelectedOrderType(SELETCED_ORDER_TYPE))
        .then(dispatch(fetchAllOrderCounts())).then(dispatch(maintainStatusTab(false)))
        .then(dispatch(aiFulfillmentSwitchTab()))
    })
}


/**
 * @description Change tab view on order status filter selected
 * @param {Boolean} closed
 * @returns {Function} Redux Action
 */
export const setTabView = ({ tabView }) => dispatch => {
  // const tabView = closed ? 'Closed' : 'Active'
  return Promise.resolve(
    dispatch({
      type: CHANGE_TAB_FULFILLMENT,
      payload: { tabView }
    })
  ).then(dispatch({
    type: SET_ORDER_SORT_TYPE,
    payload: { tabView }
  }))
}

/** @description  fetch all closed order
 *
 */
export const fetchClosedOrderCount = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId
  const { FFCO } = getState().AppConfig.vendor

  if (!FFCO) return Promise.resolve('')

  return FULFILLMENT_TYPES.filter(orderType => orderType.tabView === 'Closed').forEach(orderType => {
    if (String(process.env.REACT_APP_CLOSED_ORDER_COUNT) === 'v2') {
      return dispatch(fetchOrderCount(orderType))
    }
    return dispatch({
      [CALL_API]: {
        types: [FETCH_ORDER_COUNT_REQUEST, FETCH_ORDER_COUNT_SUCCESS, FETCH_ORDER_COUNT_FAILURE],
        // eslint-disable-next-line max-len
        endpoint: `${orderType.endpointCount}/${VENDOR_ID}?statusType=${orderType.statusType}&shipNodeId=${SHIP_NODE_ID}&vendorId=${VENDOR_ID}`,
        method: 'GET',
        payload: { key: orderType.key }
      }
    })
  })
}

export const fetchFulfillmentData = () => (dispatch, getState) => {
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId
  if (SHIP_NODE_ID) {
    dispatch(fetchCarrierMethods())
    dispatch(changeSelectedFulfillmentTab({ closed: false }))
    dispatch(fetchCarrierAccount())
    return dispatch(fetchClosedOrderCount())
  }
  return dispatch(fetchShipNodes())
}

export const fetchOrderCount = orderType => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId
  const ORDER_SEARCH = getState().OrderSearchReducer.orderSearch
  const HIDE_PAST_DUE = getState().AppConfig.vendor.hidePastDue
  const HIDE_DUE_TODAY = getState().AppConfig.vendor.hideDueToday

  if ((HIDE_PAST_DUE && orderType.statusType === 'Past') || (HIDE_DUE_TODAY && orderType.statusType === 'Current')) {
    return Promise.resolve('')
  }

  let shipDateRange = null
  let orderDateRange = null

  const dateFormat = 'YYYY-MM-DD'

  if (ORDER_SEARCH.shipDateRange.from && ORDER_SEARCH.shipDateRange.to) {
    shipDateRange = {
      fromDate: moment(ORDER_SEARCH.shipDateRange.from).format(dateFormat),
      toDate: moment(ORDER_SEARCH.shipDateRange.to).format(dateFormat)
    }
  }

  if (ORDER_SEARCH.orderDateRange.from && ORDER_SEARCH.orderDateRange.to) {
    orderDateRange = {
      fromDate: moment(ORDER_SEARCH.orderDateRange.from).format(dateFormat),
      toDate: moment(ORDER_SEARCH.orderDateRange.to).format(dateFormat)
    }
  }

  const reqObj = {
    vendorId: String(VENDOR_ID),
    shipNodeId: SHIP_NODE_ID,
    status: [orderType.statusType],
    itemNumber: ORDER_SEARCH.itemNumber,
    vendorStockId: ORDER_SEARCH.vendorStockId,
    legacyPoNo: ORDER_SEARCH.legacyPoNo,
    poNumber: ORDER_SEARCH.poNumber,
    salesOrderNo: ORDER_SEARCH.salesOrderNo,
    orderDateRange,
    shipDateRange
  }

  return dispatch({
    [CALL_API]: {
      types: [FETCH_ORDER_COUNT_REQUEST, FETCH_ORDER_COUNT_SUCCESS, FETCH_ORDER_COUNT_FAILURE],
      endpoint: `${orderType.endpointCount}`,
      method: 'POST',
      data: reqObj,
      payload: { key: orderType.key }
    }
  })
}

export const fetchSearchOrderCount = orderType => (dispatch, getState) => {
  const ORDER_SEARCH_REQUEST = getOrderSearchParameter(getState)

  return dispatch({
    [CALL_API]: {
      types: [FETCH_ORDER_COUNT_REQUEST, FETCH_ORDER_COUNT_SUCCESS, FETCH_ORDER_COUNT_FAILURE],
      endpoint: `${orderType.endpointCount}`,
      method: 'POST',
      data: ORDER_SEARCH_REQUEST,
      payload: { key: orderType.key }
    }
  })
}

/**
 * @description fetch all order count
 */
export const fetchAllOrderCounts = () => (dispatch, getState) => {
  const promises = []
  const { selectedTabView } = getState().FulfillmentNew
  FULFILLMENT_TYPES.filter(orderType => orderType.tabView === selectedTabView).forEach(orderType => {
    if (selectedTabView === 'Active') {
      // active tab
      promises.push(dispatch(fetchOrderCount(orderType)))
    }
  })

  if (selectedTabView === 'Closed') {
    // closed tab
    dispatch(fetchClosedOrderCount())
  }
  return Promise.all(promises)
}

/**
 * @description Use this action creator to fetch the order detail rows to be shown in the Fulfillment page's table
 * @param {Number} pageNo
 * @param {Number} pageSize
 * @returns {Function} Redux Action
 */
export const fetchOrderRowsActive = () => (dispatch, getState) => {
  const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
  const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId
  const ORDER_TYPE = getState().FulfillmentNew.selectedOrderType
  const ORDER_TYPE_OBJECT = getOrderTypeFromName(ORDER_TYPE)
  const { selectedTabView } = getState().FulfillmentNew

  const reqObj = {
    vendorId: String(VENDOR_ID),
    shipNodeId: SHIP_NODE_ID,
    status: [ORDER_TYPE_OBJECT.statusType],
    itemNumber: '',
    vendorStockId: '',
    legacyPoNo: '',
    poNumber: '',
    salesOrderNo: '',
    orderDateRange: null,
    shipDateRange: null,
    sortByParameter: 'orderDate',
    sortType: selectedTabView === 'Active' ? 'asc' : 'desc',
    pageNo: PAGE_NO + 1,
    pageSize: PAGE_SIZE
  }

  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_PAGINATED_TABLE_DATA_REQUEST,
        FETCH_PAGINATED_TABLE_DATA_SUCCESS,
        FETCH_PAGINATED_TABLE_DATA_FAILURE
      ],
      endpoint: `${ORDER_TYPE_OBJECT.endpointDetails}`,
      method: 'POST',
      data: reqObj,
      payload: {
        vendorId: VENDOR_ID,
        shipNodeId: SHIP_NODE_ID,
        PAGE_SIZE,
        PAGE_NO,
        pathName: getState().router.location.pathname
      }
    }
  }).catch(() => null)
}

/**
 * @description Use this action creator to fetch the order detail rows to be shown in the Fulfillment page's table
 * @param {Number} pageNo
 * @param {Number} pageSize
 * @returns {Function} Redux Action
 */
export const fetchOrderRowsClosed = () => (dispatch, getState) => {
  const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
  const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId
  const ORDER_TYPE = getState().FulfillmentNew.selectedOrderType
  const ORDER_TYPE_OBJECT = getOrderTypeFromName(ORDER_TYPE)

  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_PAGINATED_TABLE_DATA_REQUEST,
        FETCH_PAGINATED_TABLE_DATA_SUCCESS,
        FETCH_PAGINATED_TABLE_DATA_FAILURE
      ],
      endpoint: `${ORDER_TYPE_OBJECT.endpointDetails
        }/${VENDOR_ID}?statusType=${ORDER_TYPE}&shipNodeId=${SHIP_NODE_ID}&pageNo=${PAGE_NO +
        1}&pageSize=${PAGE_SIZE}&vendorId=${VENDOR_ID}`,
      method: 'GET',
      payload: {
        vendorId: VENDOR_ID,
        shipNodeId: SHIP_NODE_ID,
        PAGE_SIZE,
        PAGE_NO,
        pathName: getState().router.location.pathname
      }
    }
  }).catch(() => null)
}

/**
 * @description Use this action creator to fetch the order detail rows to be shown in the Fulfillment page's table
 * @param {Number} pageNo
 * @param {Number} pageSize
 * @returns {Function} Redux Action
 */
export const fetchOrderRows = () => (dispatch, getState) => {
  const { selectedOrderType } = getState().FulfillmentNew
  if (['Past', 'Current', 'Pending', 'Active'].includes(selectedOrderType) || String(process.env.REACT_APP_CLOSED_ORDER_SEARCH) === 'v2') {
    return dispatch(fetchOrderRowsActive())
  }
  return dispatch(fetchOrderRowsClosed())
}

/**
 *
 * @param {Array} poNumbers
 * @returns {function(...[*]=)}
 */
export const initiatePickListDownload = ({ poNumbers }) => (dispatch, getState) => {
  if (poNumbers.length > 100) {
    return dispatch(showToastMessage({ message: SELECT_ONLY_100_ROWS }))
  }

  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId

  const requestParams = {
    vendorId: VENDOR_ID,
    reportType: 'pick_list_pdf',
    format: 'pdf',
    jobParams: {
      vendorId: VENDOR_ID,
      poIdList: poNumbers,
      shipNodeId: SHIP_NODE_ID
    }
  }

  dispatch(aiFulfillmentDownloadPickList())
  return dispatch({
    [CALL_API]: {
      types: [INITIATE_PICK_LIST_DL_REQUEST, INITIATE_PICK_LIST_DL_SUCCESS, INITIATE_PICK_LIST_DL_FAILURE],
      endpoint: `${config.get('initiatePickListGeneration')}`,
      method: 'POST',
      data: requestParams,
      payload: requestParams
    }
  }).then(response => {
    if (!!response && response.type === INITIATE_PICK_LIST_DL_SUCCESS) {
      dispatch(showToastMessage({ message: PICK_LIST_DOWNLOAD_IN_PROGRESS }))
      setTimeout(() => dispatch(checkPickListDownloadStatus()), 5000)
    }
  })
}

export const checkPickListDownloadStatus = () => (dispatch, getState) => {
  const ACK_ID = getState().FulfillmentNew.pickList.ackId
  const VENDOR_ID = getVendorIdFromState(getState)

  return dispatch({
    [CALL_API]: {
      types: [CHECK_PICK_LIST_DL_STATUS_REQUEST, CHECK_PICK_LIST_DL_STATUS_SUCCESS, CHECK_PICK_LIST_DL_STATUS_FAILURE],
      endpoint: `${config.get('checkTaskStatus')}/${ACK_ID}?vendorId=${VENDOR_ID}`,
      method: 'GET'
    }
  }).then(response => {
    if (!!response && response.type === CHECK_PICK_LIST_DL_STATUS_SUCCESS) {
      if (includes(['INPROGRESS', 'INITIATED'], getState().FulfillmentNew.pickList.status)) {
        setTimeout(() => dispatch(checkPickListDownloadStatus()), 5000)
      }
      else if (getState().FulfillmentNew.pickList.status === 'COMPLETED') dispatch(getPickListPDF())
      else {
        dispatch(showToastMessage({ message: PICK_LIST_DOWNLOAD_FAILED }))
        dispatch(aiPicklistGenerationFailed({ ackId: ACK_ID }))
      }
    }
  })
}

export const getPickListPDF = () => (dispatch, getState) => {
  const ACK_ID = getState().FulfillmentNew.pickList.ackId
  const VENDOR_ID = getVendorIdFromState(getState)

  return dispatch({
    [CALL_API]: {
      types: [GET_PICK_LIST_FILE_REQUEST, GET_PICK_LIST_FILE_SUCCESS, GET_PICK_LIST_FILE_FAILURE],
      endpoint: `${config.get('downloadPickList')}/${ACK_ID}?vendorId=${VENDOR_ID}`,
      method: 'GET',
      isPDF: true
    }
  }).then(response => {
    dispatch(hideToastMessage())
    if (!!response && response.type === GET_PICK_LIST_FILE_SUCCESS) {
      downloadPDF(response.response, getState().FulfillmentNew.pickList.fileName)
    }
    else dispatch(showToastMessage({ message: PICK_LIST_DOWNLOAD_FAILED }))
  })
}

/**
 * @description reset all search params
 *
 */
export const parameterCount = () => dispatch => {
  return Promise.resolve(
    dispatch({
      type: SEARCH_PARAMETER_COUNT
    })
  )
}

/**
 * @description search order on click search order
 */
export const searchOrder = () => (dispatch, getState) => {
  const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
  const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
  const VENDOR_ID = getVendorIdFromState(getState)
  const SHIP_NODE_ID = getShipNodeIdFromState(getState).shipNodeId
  const ORDER_SEARCH = getState().OrderSearchReducer.orderSearch
  const { selectedOrderType, selectedTabView } = getState().FulfillmentNew
  let shipDateRange = null
  let orderDateRange = null
  const dateFormat = 'YYYY-MM-DD'

  if (ORDER_SEARCH.shipDateRange.from && ORDER_SEARCH.shipDateRange.to) {
    shipDateRange = {
      fromDate: moment(ORDER_SEARCH.shipDateRange.from).format(dateFormat),
      toDate: moment(ORDER_SEARCH.shipDateRange.to).format(dateFormat)
    }
    dispatch(parameterCount())
  }

  if (ORDER_SEARCH.orderDateRange.from && ORDER_SEARCH.orderDateRange.to) {
    orderDateRange = {
      fromDate: moment(ORDER_SEARCH.orderDateRange.from).format(dateFormat),
      toDate: moment(ORDER_SEARCH.orderDateRange.to).format(dateFormat)
    }
    dispatch(parameterCount())
  }

  // eslint-disable-next-line array-callback-return
  ORDER_SEARCH.searchOrderType.map(item => {
    if (ORDER_SEARCH[item.value] !== '') {
      dispatch(parameterCount())
    }
  })

  const status = []
  if (selectedTabView === 'Active') {
    ORDER_SEARCH.orderStatus.map(ack => {
      if (ack.checked) {
        dispatch(parameterCount())
        status.push(ack.name)
      }
      return status
    })
  } else {
    status.push(selectedOrderType)
  }

  if (ORDER_SEARCH.searchParamCount === 0) {
    // reset search if all search parameters are removed
    return dispatch(resetSearch()).then(dispatch(fetchFulfillmentData()))
  }

  const searchType = (selectedTabView === 'Active') ? String(process.env.REACT_APP_OPEN_ORDER_SEARCH) : String(process.env.REACT_APP_CLOSED_ORDER_SEARCH)

  const reqObj = {
    vendorId: String(VENDOR_ID),
    shipNodeId: SHIP_NODE_ID,
    status,
    itemNumber: ORDER_SEARCH.itemNumber,
    vendorStockId: ORDER_SEARCH.vendorStockId,
    legacyPoNo: ORDER_SEARCH.legacyPoNo,
    poNumber: ORDER_SEARCH.poNumber,
    salesOrderNo: ORDER_SEARCH.salesOrderNo,
    orderDateRange,
    shipDateRange,
    sortByParameter: ORDER_SEARCH.sortByParameter,
    sortType: ORDER_SEARCH.sortType,
    pageNo: PAGE_NO + 1,
    pageSize: PAGE_SIZE
  }

  return dispatch({
    [CALL_API]: {
      types: [SEARCH_ORDER_REQUEST, SEARCH_ORDER_SUCCESS, SEARCH_ORDER_FAILURE],
      endpoint: `${searchType === 'v2' ? config.get('orderSearch') : config.get('orderSearch').replace('v2', 'v1')}`,
      method: 'POST',
      data: reqObj,
      payload: {
        vendorId: VENDOR_ID,
        shipNodeId: SHIP_NODE_ID,
        PAGE_SIZE,
        PAGE_NO,
        pathName: getState().router.location.pathname
      }
    }
  }).catch(() => null)
}

/**
 * @description search key and search value in state
 * @param {string} searchKey
 *  * @param {string} searchValue
 */
export const setSearchKey = ({ searchKey, searchValue }) => dispatch => {
  return Promise.resolve(
    dispatch({
      type: SET_SEARCH_KEY_VALUE,
      param: {
        searchKey,
        searchValue
      }
    })
  )
}

/**
 * @description
 * @return {function(any): Promise<unknown>}
 */
export const clearOrderInfo = () => dispatch => {
  return Promise.resolve(
    dispatch({
      type: CLEAR_SEARCH_KEY_VALUE
    })
  )
}

/**
 * @description reset all search params
 *
 *
 */
export const removeDateFilter = dateType => dispatch => {
  return Promise.resolve(
    dispatch({
      type: REMOVE_DATE_FILTER,
      dateType
    })
  )
}

/**
 * @description reset all search params
 * @param {string} name
 *
 */
export const removeStatusFilter = name => dispatch => {
  return Promise.resolve(
    dispatch({
      type: REMOVE_STATUS_FILTER,
      payload: { name }
    })
  )
}

/**
 * @description reset all search params
 * @param {string} name
 * @param {boolean} isChecked
 *
 */
export const checkOrderStatus = ({ name, isChecked }) => dispatch => {
  return Promise.resolve(
    dispatch({
      type: SET_ORDER_STATUS,
      payload: { name, isChecked }
    })
  )
}

/**
 * @description reset all search params
 *
 */
export const orderSearchSorting = sortByParameter => dispatch => {
  return Promise.resolve(
    dispatch({
      type: SEARCH_ORDER_SORTING,
      payload: {
        sortByParameter
      }
    })
  )
}
export const maintainStatusTab = (isMaintain) => dispatch => {
  return Promise.resolve(dispatch({
    type: MAINTAIN_STATUS_TAB,
    isMaintain
  }))
}
