import React from 'react'
import { Drawer, Typography, Grid, Box } from '@mui/material'
import { withStyles } from '@mui/styles'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { find, findIndex } from 'lodash'
import SSHButton from '../../components/SSHButton'
import { SSHTypography } from '../../components/SSHTypography'
import SSHNumberInput from '../../components/SSHNumberInput'
import { displayDateTitle, qtyDateValue } from '../../pages/InTransitInventory/columns'
import { setRequestPayload, updateInTransitInventory } from '../../pages/InTransitInventory/InTransitInventoryAction'

const styles = theme => ({
    container: {
        width: 387
    },
    headerContainer: {
        backgroundColor: theme.palette.background.lightGray,
        maxHeight: 76
    },
    headerText: {
        fontSize: theme.spacing(2.4),
        fontWeight: 500
    },
    subText: {
        fontSize: theme.spacing(1.4),
        color: theme.palette.primary.softBlue
    },
    panelSummary: {
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0
        }
    },
    panelStyle: {
        display: 'block'
    },
    updatedQtyStyle: {
        marginLeft: theme.spacing(2),
        color: theme.palette.primary.information60
    },
    textLabel: {
        fontSize: '14px'
    },
    footerContainer: {
        backgroundColor: theme.palette.background.magnolia
    },
    footerButton: {
        fontSize: theme.spacing(2),
        fontWeight: 500,
        height: 34,
        width: 143,
        '& .MuiButton-label': {
            height: 30
        }
    }
})

const EditDrawer = ({
    classes,
    isOpen,
    onCancel,
    selectedItem,
    setRequestPayload,
    updateInTransitInventory
}) => {
    const [expanded, setExpanded] = React.useState(false);
    const [qtyPayload, setQtyPayload] = React.useState(selectedItem?.inTransitInventory)
    const dateArray = []

    /**
     * @description Unique Ids of the panel names
     */
    const displayDateRange = () => {
        return `${displayDateTitle(1)} to ${displayDateTitle(30)}`
    }

    const calendarDays = () => {
        const { inTransitInventory } = selectedItem
        for (let i = 1; i <= 30; i++) {
            dateArray.push({
                id: i,
                name: displayDateTitle(i),
                date: qtyDateValue(i),
                currentQty: find(inTransitInventory, val => val.date === qtyDateValue(i))?.quantity,
                updatedQty: null
            })
        }
        return dateArray
    }

    /**
     * @description Handle on accordion change
     * @returns {function(...[*]=)}
     */
    const handleAccordionChange = (selectedPanel) => (event, isExpanded) => {
        setExpanded(isExpanded ? selectedPanel : false);
    }

    /**
     * @description on Qty value changed
     * @returns {function(...[*]=)}
     */
    const onQtyUpdated = (event, panel) => {
        const newArray = [...qtyPayload]
        panel['updatedQty'] = event.target.value
        const reqObj = { date: panel.date, quantity: event.target.value }
        const matchedIndex = findIndex(newArray, val => val.date === panel.date)
        if (matchedIndex === -1) {
            setQtyPayload([...newArray, reqObj])
        } else {
            setQtyPayload(newArray.map(val => {
                if (val.date === panel.date) {
                    return { ...val, quantity: event.target.value };
                } else {
                    return val;
                }
            }))
        }
    }

    /**
     * @description display updated qty when updated
     * @returns {string}
     */
    /**
     * @description on click on apply button
     * @returns {function(...[*]=)}
     */
    const onApply = async () => {
        await setRequestPayload({ itemId: selectedItem.itemId, inTransitInventory: qtyPayload })
        await updateInTransitInventory()
        onCancel()
    }

    return (
        <Drawer anchor="right" open={isOpen} onClose={onCancel}>
            <Grid className={classes.container} container justifyContent="space-between">
                <Grid className={classes.headerContainer} container item alignItems="center" xs={12} direction="row">
                    <Grid container item direction="column" xs={10}>
                        <Grid item xs={12}>
                            <Box paddingTop="15px" marginLeft="25px" height={76}>
                                <Typography variant="h6" className={classes.headerText}>
                                    Edit Quantity
                                </Typography>
                                <Typography variant="subtitle1" className={classes.subText}>
                                    {displayDateRange()}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Box overflow="scroll" height="calc(100vh - 135px)" marginLeft="16px">
                        {calendarDays().map(panel => (
                            <Accordion expanded={expanded === panel.id} onChange={handleAccordionChange(panel.id)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    className={classes.panelSummary}
                                    id="panel1a-header">
                                    <Grid container display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
                                        <Grid item>
                                            <SSHTypography data-testid="filterAccordionName">
                                                {panel.name}
                                            </SSHTypography>
                                            <Grid container>
                                                <SSHTypography className={classes.subText}>Current Quantity: {panel.currentQty || 0}</SSHTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails className={classes.panelStyle}>
                                    <SSHTypography className={classes.textLabel}>
                                        New quantity
                                    </SSHTypography>
                                    <SSHNumberInput type='number' value={panel.updatedQty} onChange={(e) => onQtyUpdated(e, panel)}></SSHNumberInput>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box>
                </Grid>

                <Grid className={classes.footerContainer} item container justifyContent="space-evenly" alignItems="center" xs={12}>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-evenly" alignItems="center" height={60}>
                            <SSHButton className={classes.footerButton} size="small" text="Cancel" onClick={onCancel} />
                            <SSHButton className={classes.footerButton} variant="contained" color="primary" size="medium" text="Apply" onClick={onApply} />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Drawer>
    )
}

EditDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    selectedItem: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func
}

const mapStateToProps = state => ({
    selectedItem: state.InTransitInventory.selectedItem
})

export const EditDrawerNaked = EditDrawer

const mapDispatchToProps = dispatch => bindActionCreators({ setRequestPayload, updateInTransitInventory }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditDrawer))
