import React, { PureComponent } from "react"
import PropTypes from 'prop-types'
import moment from 'moment'
import { SSHTypography } from '../../components/SSHTypography'
import { Box } from '@mui/material'
import { withStyles } from "@mui/styles"

const styles = theme => ({
    title: {
        fontSize: theme.spacing(2),
        fontWeight: '500',
        lineHeight: `${theme.replacePX(theme.spacing(2))}px`,
        color: theme.palette.primary.cyanDark
    },
    caption: {
        color: theme.palette.primary.cyanMediumShade,
        fontSize: theme.spacing(1.75),
        fontWeight: 400
    }
})

/**
 * @description display tomorrow date - 30 days from today
 */
const displayDateRange = () => {
    const currentDate = new Date()
    const dateFormat = 'MM/DD/YY'
    const fromDate = moment(currentDate.setDate(currentDate.getDate() + 1)).format(dateFormat)
    const toDate = moment(currentDate.setDate(currentDate.getDate() + 29)).format(dateFormat)
    return `${fromDate} - ${toDate}`
}

/**
 * @description flexscape component to customize toolbar for scheduler component
 */
class TitleInformation extends PureComponent {

    render() {
        const { title, caption, classes } = this.props
        return (
            <Box>
                <SSHTypography className={classes.title}>
                    {title}
                    <SSHTypography variant="h6" caption='span' className={classes.caption}>
                        {caption}
                    </SSHTypography>
                </SSHTypography>
            </Box>
        )
    }
}

TitleInformation.propTypes = {
    title: PropTypes.string,
    caption: PropTypes.string,
    onEdit: PropTypes.func
}

TitleInformation.defaultProps = {
    title: 'In-transit quantity for next 30 days',
    caption: displayDateRange()
}

export default withStyles(styles)(TitleInformation)
