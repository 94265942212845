export const RESET_PAGINATED_TABLE = 'RESET_PAGINATED_TABLE'
export const CHANGE_PAGE_SIZE = 'CHANGE_PAGE_SIZE'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'

export const FETCH_PAGINATED_TABLE_DATA_REQUEST = 'FETCH_PAGINATED_TABLE_DATA_REQUEST'
export const FETCH_PAGINATED_TABLE_DATA_SUCCESS = 'FETCH_PAGINATED_TABLE_DATA_SUCCESS'
export const FETCH_PAGINATED_TABLE_DATA_FAILURE = 'FETCH_PAGINATED_TABLE_DATA_FAILURE'

export const CLEAR_SEARCH = 'CLEAR_SEARCH'

export const CLEAR_ACCESS_LEVEL_ROLES = 'CLEAR_ACCESS_LEVEL_ROLES'
export const CLIENT_PAGINATION = 'CLIENT_PAGINATION'
export const CLIENT_SORTING = 'CLIENT_SORTING'
export const UPDATE_SORT_ARROW = 'UPDATE_SORT_ARROW'
export const RESET_SEARCH = 'RESET_SEARCH'
export const RESET_FCAPACITY_TABLE='RESET_FCAPACITY_TABLE'
