import { Table } from '@devexpress/dx-react-grid-material-ui'
import React from 'react'
import SSHTooltip from '../../components/SSHTooltip'
import { SSHTypography } from '../../components/SSHTypography'

/**
 * @description Component to render custom table cell for showing tooltip
 * @param {Object} props Data object with list cell details
 * @returns React element with the value of tooltip enabled cell
 */
const TooltipCell = ({ ...props }) => {
  return (
    <Table.Cell {...props}>
      <SSHTooltip title={`${props?.value}`} placement={`${props?.column?.placement || 'top-start'}`}>
        <SSHTypography variant="body2">{`${props?.value}`}</SSHTypography>
      </SSHTooltip>
    </Table.Cell>
  )
}

TooltipCell.propTypes = {}

export default TooltipCell
