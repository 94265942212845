import config from '../../../config'
import { filter } from 'lodash'
import {
    FETCH_PAGINATED_TABLE_DATA_FAILURE,
    FETCH_PAGINATED_TABLE_DATA_REQUEST,
    FETCH_PAGINATED_TABLE_DATA_SUCCESS,
} from '../../../reduxSetup/commonActions/SSHPaginatedTable.actionTypes'
import { SET_FILTERS_OPEN, OOS_TOTAL_COUNT, OOS_FILTERS } from './ClubOutofStock.types'
import { CALL_API } from '../../../reduxSetup/middleware/API'
import { getVendorIdFromState } from '../../../utils/common'
 

/**
* @description fetch order demand Items
* @returns {function}
*/
export const fetchOutofStockItems = () => (dispatch, getState) => {
    const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
    const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
    const { isAdmin } = getState().UserInfo
    const VENDOR_ID = isAdmin ? null : getVendorIdFromState(getState)
    const filters = getState().ClubOutofStock.oosVisibilityFilters

    if (!isAdmin && !VENDOR_ID) {
        return
    }

    return dispatch({
        [CALL_API]: {
            types: [
                FETCH_PAGINATED_TABLE_DATA_REQUEST,
                FETCH_PAGINATED_TABLE_DATA_SUCCESS,
                FETCH_PAGINATED_TABLE_DATA_FAILURE
            ],
            endpoint: `${config.get('clubOOSVisibility')}/items?pageNo=${PAGE_NO + 1}&pageSize=${PAGE_SIZE}`,
            method: 'POST',
            payload: {
                vendorId: VENDOR_ID,
                PAGE_SIZE,
                PAGE_NO,
                pathName: getState().router.location.pathname
            },
            data: {
                supplierId: VENDOR_ID,
                filters
            }
        }
    }).then(({ type, response }) => {
        if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS) {
            return dispatch(setOosItemCount({ count: parseInt(response?.payload?.totalRecords) || 0 }))
        }
        return false
    })
}

/**
* @description set oos items count
* @param {object} {count}
* @return {function}
*/
export const setOosItemCount = ({ count }) => dispatch => {
    return dispatch({
        type: OOS_TOTAL_COUNT,
        payload: { count }
    })
}

/**
* @description apply filters
* @param {object} {itemId}
* @return {function}
*/
export const filterOosData = (itemId = '') => (dispatch, getState) => {
    const { formattedCategories,
        formattedSubCategories,
        formattedSuppliers } = getState().SmartInventory?.filters

    return Promise.resolve(
        dispatch({
            type: OOS_FILTERS,
            payload: sortFilters({ formattedCategories, formattedSubCategories, formattedSuppliers, itemId })
        })
    )
}

const sortFilters = ({ formattedCategories, formattedSubCategories, formattedSuppliers, itemId }) => {
    const categories = filter(formattedCategories, { 'active': true })
    const subCategories = filter(formattedSubCategories, { 'active': true })
    const suppliers = filter(formattedSuppliers, { 'active': true })

    return {
        categories,
        subCategories,
        suppliers,
        itemId
    }
}

/**
* @description set filters drawer open
* @param {boolean} {isOpen}
* @return {function}
*/
export const setFilterOpen = (isFilterOpen) => dispatch => {
    return dispatch({
        type: SET_FILTERS_OPEN,
        payload: { isFilterOpen }
    })
}


