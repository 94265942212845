import { cloneDeep, get, isEmpty, find } from 'lodash'
import { parseItemsInOrder } from '../../utils/common'
import {
  FETCH_ORDER_DETAILS_FAILURE,
  FETCH_ORDER_DETAILS_REQUEST,
  FETCH_ORDER_DETAILS_SUCCESS,
  MARK_ITEM_AS_SELECTED,
  RESET_ORDER_DETAILS,
  SET_CURRENT_ORDER_DETAILS_INDEX,
  SET_ORDERS_LIST_FOR_DETAILS,
  SHOW_HIDE_SHIPMENT_POPUP,
  UPDATE_FULFILLMENT_RELEASE_FAILURE,
  UPDATE_FULFILLMENT_RELEASE_REQUEST,
  SELECT_CARRIER_METHOD,
  UPDATE_FULFILLMENT_RELEASE_SUCCESS,
  SET_TRACKING_NUMBER,
  SET_ITEM_SHIP_QUANTITY,
  SET_SHIPMENT_FLAGS,
  SHOW_HIDE_LABEL_GEN_ERROR_POPUP,
  TOGGLE_EDIT_POPUP,
  FORCE_UPDATE
} from './OrderDetails.actionTypes'

export const INITIAL_STATE = {
  selectedOrderList: [],
  currentOrderIndex: 0,
  currentOrder: {
    poReleaseList: [
      {
        basicDetails: { releaseNumber: '1' },
        itemDetails: [{ status: 'Pending' }, { status: 'Pending' }, { status: 'Pending' }, { status: 'Pending' }],
        shippingDetails: {}
      }
    ],
    cancelItems: []
  },
  orderResult: {
    updateSuccess: false,
    updateFailed: false,
    message: '',
    loadingStatus: ''
  },
  shipment: {
    showPopup: false,
    isForceUpdate: false,
    errMessage: '',
    carrierMethodId: null,
    carrierMethodName: null,
    trackingNumber: null,
    releaseNumber: null,
    flags: {
      prepaid: {
        alcohol: false,
        signature: false,
        hazard: false
      },
      collect: {
        alcohol: false,
        signature: false,
        hazard: false
      }
    }
  },
  showLabelDownloadErrorPopup: false,
  isFetchingOrder: true,
  showEditPopupItem: {
    isEdit: false,
    items: []
  },
  releaseNumber: '',
  legacyPOExists: true,
}

export default (state = cloneDeep(INITIAL_STATE), action) => {
  const { payload, response } = action
  switch (action.type) {
    case SET_ORDERS_LIST_FOR_DETAILS:
      return {
        ...state,
        selectedOrderList: payload.orderList
      }

    case SET_CURRENT_ORDER_DETAILS_INDEX:
      return {
        ...state,
        currentOrderIndex: payload.currentOrderIndex
      }

    case FETCH_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        isFetchingOrder: true
      }

    case FETCH_ORDER_DETAILS_SUCCESS: {
      const order = parseItemsInOrder(get(response, 'payload.poDetailsConsolidatedList[0]', null))

      if (!order) {
        return {
          ...state,
          isFetchingOrder: false
        }
      }

      order.poReleaseList[0].basicDetails = {
        ...order.poReleaseList[0].basicDetails,
        shipmentDate: state.selectedOrderList[state.currentOrderIndex].shipmentDate
      }

      return {
        ...state,
        currentOrder: order,
        isFetchingOrder: false,
        isUpdating: false,
        orderResult: {
          loadingStatus: '',
          updateSuccess: false,
          updateFailed: false,
          message: ''
        },
        releaseNumber: order?.poReleaseList[0]?.basicDetails?.releaseNumber,
        legacyPOExists: !Boolean(find(order?.poReleaseList, val => isEmpty(val.basicDetails?.legacyPONumber)))
      }
    }

    case FETCH_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        isFetchingOrder: false,
        orderResult: {
          loadingStatus: '',
          updateSuccess: false,
          updateFailed: false,
          message: ''
        }
      }

    case RESET_ORDER_DETAILS:
      return {
        ...INITIAL_STATE
      }

    case UPDATE_FULFILLMENT_RELEASE_REQUEST:
      // eslint-disable-next-line no-case-declarations
      const { status } = payload
      // eslint-disable-next-line no-case-declarations
      const result = {
        ...state.orderResult,
        loadingStatus: status
      }
      return {
        ...state,
        orderResult: result
      }
    case UPDATE_FULFILLMENT_RELEASE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        orderResult: {
          ...state.orderResult,
          loadingStatus: '',
          updateFailed: payload.updateFailed,
          message: payload.message
        }
      }

    case UPDATE_FULFILLMENT_RELEASE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        orderResult: {
          ...state.orderResult,
          loadingStatus: '',
          updateSuccess: payload.updateSuccess,
          message: payload.message
        }
      }
    case MARK_ITEM_AS_SELECTED:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          poReleaseList: state.currentOrder.poReleaseList.map(release => {
            if (release.basicDetails.releaseNumber === payload.release.basicDetails.releaseNumber) {
              return payload.release
            }
            return release
          })
        }
      }

    case SHOW_HIDE_SHIPMENT_POPUP: {
      const isPopupVisible = state.shipment.showPopup
      let newShipmentValues = {}
      if (isPopupVisible) {
        newShipmentValues = {
          ...INITIAL_STATE.shipment
        }
      } else {
        newShipmentValues = {
          ...state.shipment,
          releaseNumber: payload.releaseNumber,
          showPopup: true
        }
      }

      return {
        ...state,
        shipment: { ...newShipmentValues }
      }
    }
    case SELECT_CARRIER_METHOD:
      return {
        ...state,
        shipment: {
          ...state.shipment,
          carrierMethodId: payload.value,
          carrierMethodName: payload.name
        }
      }

    case SET_TRACKING_NUMBER:
      return {
        ...state,
        shipment: {
          ...state.shipment,
          trackingNumber: payload.value
        }
      }

    case SET_ITEM_SHIP_QUANTITY: {
      const { release } = payload
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          poReleaseList: state.currentOrder.poReleaseList.map(rel => {
            if (rel.basicDetails.releaseNumber === release.basicDetails.releaseNumber) return { ...release }
            return rel
          })
        }
      }
    }

    case SET_SHIPMENT_FLAGS:
      return {
        ...state,
        shipment: {
          ...state.shipment,
          flags: payload.flags
        }
      }

    case SHOW_HIDE_LABEL_GEN_ERROR_POPUP:
      return {
        ...state,
        showLabelDownloadErrorPopup: payload.show
      }

    case TOGGLE_EDIT_POPUP:
      return {
        ...state,
        showEditPopupItem: {
          isEdit: !state.showEditPopupItem.isEdit,
          itemNumber: action.payload
        }
      }

    case FORCE_UPDATE:
      return {
        ...state,
        shipment: {
          ...state.shipment,
          errMessage: payload?.errMessage,
          isForceUpdate: payload?.toggle
        }
      }

    default:
      return state
  }
}
