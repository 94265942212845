import React from 'react'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SSHTooltip from '../../components/SSHTooltip'
import { displayWeeklyForecast } from '../../pages/Club/SaleForecast/ForecastAction'
import { displayWeeklyOrderDemand } from '../../pages/Club/OrderDemand/OrderDemandAction'
import { ArrayFormatter } from './CustomFieldTypeProvider'
import { ROUTE_MODULES } from '../../config/genericConstants'

const styles = theme => ({
    link: {
        color: theme.palette.primary.blue,
        cursor: 'pointer'
    }
})

const ClubForecastCell = ({ classes, displayWeeklyForecast, displayWeeklyOrderDemand, ...props }) => {

    const tooltip = ''
    const onClick = () => {
        if (props.pathName?.includes(ROUTE_MODULES.forecasting.cluborderdemand?.path)) {
            return displayWeeklyOrderDemand({ clubDetails: props.row, displayWeeklyDrawer: true })
        } else {
            return displayWeeklyForecast({ clubDetails: props.row, displayForecastDrawer: true })
        }
    }

    return (
        <Table.Cell {...props}>
            <SSHTooltip title={tooltip} placement="top-start">
                <Typography variant="body2" className={classes.link} onClick={onClick}>
                    <ArrayFormatter value={props.value} noTooltip />
                </Typography>
            </SSHTooltip>
        </Table.Cell>
    )
}

ClubForecastCell.propTypes = {
    classes: PropTypes.object.isRequired,
    props: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    pathName: state.router.location.pathname
})

const mapDispatchToProps = dispatch => bindActionCreators({ displayWeeklyForecast, displayWeeklyOrderDemand }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClubForecastCell))

export const ClubForecastCellNaked = ClubForecastCell