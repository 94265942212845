import moment from 'moment'
/* eslint-disable import/no-cycle */
import { isEmpty, filter, get, find } from 'lodash'
import config from '../../config'
import {
  FETCH_PAGINATED_TABLE_DATA_FAILURE,
  FETCH_PAGINATED_TABLE_DATA_REQUEST,
  FETCH_PAGINATED_TABLE_DATA_SUCCESS,
  UPDATE_SORT_ARROW
} from '../../reduxSetup/commonActions/SSHPaginatedTable.actionTypes'
import {
  aiPenaltySearch,
  aiPenaltyFetch,
  aiPenaltyOrdersFetch,
  aiPenaltyMetrics,
  aiPenaltyExclude,
  aiPenaltyChargeback,
  aiPenaltyApproveReject,
  aiPenaltyHistory
} from '../../reduxSetup/commonActions/TelemetryActionsNew'
import { CALL_API } from '../../reduxSetup/middleware/API'
import {
  downloadFile,
  getPreviousTwoMonthsDate,
  getRouteNameFromURL,
  getVendorIdFromState,
  getVisibleModuleName,
  getPenaltyTabs
} from '../../utils/common'
import {
  SET_PENALTY_PROGRAM_ROW_COUNT,
  FETCH_PENALTY_REQUEST,
  FETCH_PENALTY_SUCCESS,
  FETCH_PENALTY_FAILURE,
  SET_PENALTY_TAB,
  RESET_PENALTY_ITEM_SEARCH,
  FETCH_SUPPLIER_METRICS_SUCCCESS,
  FETCH_SUPPLIER_METRICS_FAILURES,
  FETCH_SUPPLIER_METRICS_REQUEST,
  UPDATE_PENALTY_TABLE,
  UPDATE_PENALTIES,
  UPDATE_SUPPLIER_METRICS,
  SET_PENALTY_SEARCH_KEY_VALUE,
  CLEAR_PENALTY_SEARCH_KEY_VALUE,
  EXCLUDE_PENALTY_REQUEST,
  EXCLUDE_PENALTY_SUCCESS,
  EXCLUDE_PENALTY_FAILURE,
  SET_SELECTED_PENALTY_ROWS,
  GET_CHARGEBACK_REQUEST,
  GET_CHARGEBACK_SUCCESS,
  GET_CHARGEBACK_FAILURE,
  UPDATE_CHARGEBACK_DETAILS,
  APPROVE_REJECT_REQUEST,
  APPROVE_REJECT_FAILURE,
  APPROVE_REJECT_SUCCESS,
  SHOW_HIDE_EXCLUDE_POPUP,
  SET_EXCLUDE_PENALTY_REASON,
  SET_EXCLUDE_PENALTY_DESCRIPTION,
  SET_EXCLUDE_PENALTY_REASON_ERROR,
  SET_EXCLUDE_PENALTY_DESCRIPTION_ERROR,
  SET_PENALTY_HISTORY_TAB,
  DOWNLOAD_PENALTY_REQUEST,
  DOWNLOAD_PENALTY_SUCCESS,
  DOWNLOAD_PENALTY_FAILURE,
  SET_PENALTY_STATUS,
  SET_FILTER_KEY_VALUE,
  CLEAR_PENALTY_FILTER
} from './PenaltyProgram.actionTypes'
import { ROUTE_MODULES } from '../../config/genericConstants'
import * as gConstants from '../../config/genericConstantsNew'
import { EXCLUDE_PENALTY_SUCCESS_MESSAGE, ERROR_PENALTY_FILE_DOWNLOAD, GENERIC_FILE_LARGE_MESSAGE } from '../../config/errorConstants'
import { showAlert } from '../../templates/Error/ErrorHandlerActions'
import { SUCCESS, ERROR } from '../../templates/Error/ErrorHandler.actionTypes'
import { markTaskAsRejected } from '../LeadTime/LeadTimeAction'
import { resetTable } from '../../reduxSetup/commonActions/SSHPaginatedTable'
import { SET_MODULE_TYPE } from '../../reduxSetup/commonActions/AppConfig.actionTypes'
import { DISABLE_DOWNLOAD, ENABLE_DOWNLOAD } from '../../templates/NotificationDrawer/NotificationDrawer.actionTypes'
import { hideToastMessage, showToastMessage } from '../../reduxSetup/commonActions/AppConfig'
import { RESOLVED } from '../Approvals/commonConstants'
import { push } from 'connected-react-router'

/**
 * @description Set the table's row count to the passed param value
 * @param {Number} count
 * @returns {function(...[*]=)}
 */
export const setPenaltyProgramRowCount = ({ count, downloadFileLimit }) => dispatch => {
  return dispatch({
    type: SET_PENALTY_PROGRAM_ROW_COUNT,
    payload: { count, downloadFileLimit }
  })
}

export const resetSearch = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: RESET_PENALTY_ITEM_SEARCH })
    resolve()
  })
}

/**
 * @description Fetch list of cancelled/late items
 * @returns List of items
 */
export const fetchLateOrCancelledOrders = ({ sortColumn = '', sortType = '' } = {}) => (dispatch, getState) => {
  const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
  const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
  const VENDOR_ID = getVendorIdFromState(getState)
  const { selectedTab, penaltySearchFilter } = getState().PenaltyProgram
  const { lastItemSortColumn } = getState().SSHPaginatedTableReducer
  const { iamToken } = getState().UserInfo.userDetails
  const { toDate, fromDate, penaltyType } = penaltyFilterParam({ selectedTab, penaltySearchFilter })

  // eslint-disable-next-line max-len
  const PATH_URL = `/${VENDOR_ID}?penaltyType=${penaltyType}&fromDate=${fromDate}&toDate=${toDate}&pageNo=${PAGE_NO +
    1}&pageSize=${PAGE_SIZE}&isIncluded=${true}`

  if (isEmpty(sortColumn) && isEmpty(sortType)) {
    // eslint-disable-next-line no-param-reassign
    sortColumn = lastItemSortColumn.columnName
    // eslint-disable-next-line no-param-reassign
    sortType = lastItemSortColumn.direction
  }

  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_PAGINATED_TABLE_DATA_REQUEST,
        FETCH_PAGINATED_TABLE_DATA_SUCCESS,
        FETCH_PAGINATED_TABLE_DATA_FAILURE
      ],
      endpoint: `${config.get('penalty')}/orders${PATH_URL}`,
      method: 'POST',
      iamToken,
      payload: {
        pathName: getState().router.location.pathname
      },
      data: { vendorId: '1234' } // Temporary dummy body
    }
  }).then(({ type, response }) => {
    dispatch(aiPenaltyOrdersFetch({ type, response }))
    if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS) {
      dispatch({
        type: UPDATE_SORT_ARROW,
        payload: {
          colName: sortColumn,
          sortType
        }
      })
      dispatch({
        type: UPDATE_PENALTY_TABLE,
        payload: response?.payload
      })
      return Promise.resolve(
        dispatch(setPenaltyProgramRowCount(
          {
            count: parseInt(response?.payload?.totalCount, 10) || 0,
            downloadFileLimit: response?.payload?.downloadFileLimit
          }
        )))
    }
    return Promise.resolve()
  })
}

/**
 * Search penalty with the passed query
 * @param {String} searchQuery
 * @returns {function(...[*]=)}
 */
export const searchPenalty = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const { iamToken } = getState().UserInfo?.userDetails
  const { penaltySearch } = getState().PenaltyProgram
  const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
  const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo

  if (isEmpty(penaltySearch.searchValue.trim())) {
    return Promise.resolve()
  }

  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_PAGINATED_TABLE_DATA_REQUEST,
        FETCH_PAGINATED_TABLE_DATA_SUCCESS,
        FETCH_PAGINATED_TABLE_DATA_FAILURE
      ],
      endpoint: `${config.get('penalty')}/search/${VENDOR_ID}?pageNo=${PAGE_NO + 1}&pageSize=${PAGE_SIZE}`,
      method: 'POST',
      iamToken,
      payload: {
        pathName: getState().router.location.pathname
      },
      data: { id: penaltySearch.searchKey, searchValue: penaltySearch.searchValue.trim() }
    }
  }).then(({ type, response }) => {
    dispatch(aiPenaltySearch({ type, response, penaltySearch }))

    if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS) {
      dispatch({
        type: UPDATE_PENALTY_TABLE,
        payload: response?.payload
      })
      return Promise.resolve(
        dispatch(setPenaltyProgramRowCount({ count: parseInt(response?.payload?.totalCount, 10) || 0 }))
      )
    }
    return Promise.resolve()
  })
}

/**
 * Approve or Reject Penalty exclude request
 * @returns {function(...[*]=)}
 */
export const approveRejectPenalty = ({ task, isApproved }) => (dispatch, getState) => {
  const { iamToken } = getState().UserInfo?.userDetails
  const { excludePenaltyReason, excludePenaltyDescription } = getState().PenaltyProgram
  const EMAIL = getState().UserInfo?.userDetails?.emailId

  const reqObj = {
    merchantEmail: EMAIL,
    supplierId: task.supplierId,
    status: isApproved ? 'approved' : 'rejected',
    merchantReason:
      excludePenaltyReason.name === gConstants.OTHER ? excludePenaltyDescription : excludePenaltyReason.name || null,
    isIncluded: false,
    penaltyId: task.penaltyId,
    taskId: task.taskId
  }

  return dispatch({
    [CALL_API]: {
      types: [APPROVE_REJECT_REQUEST, APPROVE_REJECT_SUCCESS, APPROVE_REJECT_FAILURE],
      endpoint: `${config.get('penalty')}/exclude/approve`,
      method: 'POST',
      iamToken,
      payload: {
        pathName: getState().router.location.pathname
      },
      data: reqObj
    }
  }).then(({ type, response }) => {
    if (type === APPROVE_REJECT_SUCCESS) {
      if (!isApproved) {
        dispatch(setExcludePenaltyReason({ name: '', value: '' }))
        dispatch(setExcludePenaltyDescription(''))
        dispatch(markTaskAsRejected({ updatedTask: task }))
      }
      dispatch(aiPenaltyApproveReject({ type, response }))
      return Promise.resolve()
    }
    const error =
      get(response, 'payload.error.errorMessage', null) || get(response, 'data.payload.error.errorMessage', null)
    throw new Error(error || 'Error occurred while trying to update penalty request')
  })
}

/**
 * @description Sets the tab active according to the passed tabName
 * @param {String} tabName Selected tab name
 */
export const setPenaltyProgramTabActive = ({ tab }) => dispatch => {
  return new Promise((resolve) => {
    dispatch({
      type: SET_PENALTY_TAB,
      payload: tab
    })
    resolve()
  })
}

/**
 * @description Fetch Penalties
 * @returns List of penaties
 */
export const fetchPenalties = (index, fromDate, toDate) => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const PATH_URL = `/${VENDOR_ID}?fromDate=${fromDate}&toDate=${toDate}`
  const { iamToken } = getState().UserInfo.userDetails

  return dispatch({
    [CALL_API]: {
      types: [FETCH_PENALTY_REQUEST, FETCH_PENALTY_SUCCESS, FETCH_PENALTY_FAILURE],
      endpoint: `${config.get('penalty')}/details${PATH_URL}`,
      method: 'GET',
      iamToken
    }
  }).then(({ type, response }) => {
    if (type === FETCH_PENALTY_SUCCESS) {
      dispatch({
        type: UPDATE_PENALTIES,
        payload: { index, value: response.payload?.chargebackDetails || {} }
      })
    }
    dispatch(aiPenaltyFetch({ response }))
  })
}

/**
 * @description Fetch Supplier metrics for penalty
 * @returns
 */
export const fetchSupplierMetrics = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const { selectedTab } = getState().PenaltyProgram
  const date = selectedTab?.value.split('-')
  const year = date[0]
  const month = date[1].charAt(0) === '0' ? date[1].charAt(1) : date[1]
  const PATH_URL = `/${VENDOR_ID}?year=${year}&month=${month}`
  const { iamToken } = getState().UserInfo.userDetails

  return dispatch({
    [CALL_API]: {
      types: [FETCH_SUPPLIER_METRICS_REQUEST, FETCH_SUPPLIER_METRICS_SUCCCESS, FETCH_SUPPLIER_METRICS_FAILURES],
      endpoint: `${config.get('penalty')}/metrics${PATH_URL}`,
      method: 'GET',
      iamToken
    }
  }).then(({ type, response }) => {
    if (type === FETCH_SUPPLIER_METRICS_SUCCCESS) {
      dispatch({
        type: UPDATE_SUPPLIER_METRICS,
        payload: isEmpty(response) ? response : response.payload
      })
    }
    dispatch(aiPenaltyMetrics({ response }))
    return Promise.resolve()
  })
}

/**
 * @description search key and search value in state
 * @param {string} searchKey
 *  * @param {string} searchValue
 */
export const setSearchKey = ({ searchKey, searchValue }) => dispatch => {
  return Promise.resolve(
    dispatch({
      type: SET_PENALTY_SEARCH_KEY_VALUE,
      payload: {
        searchKey,
        searchValue
      }
    })
  )
}

/**
 * @description
 * @return {function(any): Promise<unknown>}
 */
export const clearPenaltyInfo = () => dispatch => {
  return Promise.resolve(
    dispatch({
      type: CLEAR_PENALTY_SEARCH_KEY_VALUE
    })
  )
}

/**
 * @description
 * @return {function(any): Promise<unknown>}
 */
export const setSelectedPenaltyRows = selected => dispatch => {
  return Promise.resolve(
    dispatch({
      type: SET_SELECTED_PENALTY_ROWS,
      payload: selected
    })
  )
}

/**
 * get Chargeback details
 * @param {String} searchQuery
 * @returns {function(...[*]=)}
 */
export const getChargebackDetails = () => (dispatch, getState) => {
  const { iamToken } = getState().UserInfo.userDetails
  const VENDOR_ID = getVendorIdFromState(getState)
  const IS_VENDOR = getState().UserInfo.isVendor

  const URL_PATH = IS_VENDOR ? `/${VENDOR_ID}` : ''

  return dispatch({
    [CALL_API]: {
      types: [GET_CHARGEBACK_REQUEST, GET_CHARGEBACK_SUCCESS, GET_CHARGEBACK_FAILURE],
      endpoint: `${config.get('penalty')}/chargeback${URL_PATH}`,
      method: 'GET',
      iamToken,
      payload: {
        pathName: getState().router.location.pathname
      }
    }
  }).then(({ type, response }) => {
    dispatch(aiPenaltyChargeback({ type, response }))

    if (type === GET_CHARGEBACK_SUCCESS) {
      dispatch({
        type: UPDATE_CHARGEBACK_DETAILS,
        payload: response?.payload?.chargebackDetails
      })
    }
    return Promise.resolve()
  })
}

/**
 * Requesting to exclude penalty
 * @param {String} searchQuery
 * @returns {function(...[*]=)}
 */
export const excludePenalty = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const { iamToken } = getState().UserInfo.userDetails
  const { selectedRows, excludePenaltyReason, excludePenaltyDescription, penaltySearch } = getState().PenaltyProgram
  const EMAIL = getState().UserInfo.userDetails.emailId
  const LOGIN_ID = getState().UserInfo.userDetails.loginId
  const penalityList = []

  selectedRows.forEach(penalty => {
    if (String(penalty.status).toLowerCase() !== 'pending') {
      penalityList.push(penalty.penaltyId)
    }
  })

  const reqObj = {
    supplierId: VENDOR_ID,
    penaltyIds: penalityList,
    isIncluded: false,
    requestedBy: EMAIL || LOGIN_ID,
    reason:
      String(excludePenaltyReason?.name).toLowerCase() === String(gConstants.OTHER).toLowerCase()
        ? excludePenaltyDescription
        : excludePenaltyReason?.name,
    status: 'pending'
  }

  return dispatch({
    [CALL_API]: {
      types: [EXCLUDE_PENALTY_REQUEST, EXCLUDE_PENALTY_SUCCESS, EXCLUDE_PENALTY_FAILURE],
      endpoint: `${config.get('penalty')}/exclude/suppliers/${VENDOR_ID}`,
      method: 'POST',
      iamToken,
      payload: {
        pathName: getState().router.location.pathname
      },
      data: reqObj
    }
  }).then(({ type, response }) => {
    const { payload } = response
    dispatch(aiPenaltyExclude({ type, response }))

    if (type === EXCLUDE_PENALTY_SUCCESS) {
      const dates = getPreviousTwoMonthsDate()
      dispatch(showHidePopup(false))
      dispatch(setExcludePenaltyReason({ name: '', value: '' }))
      dispatch(setExcludePenaltyDescription(''))
      if (payload?.isUpdated) {
        dispatch(showAlert(SUCCESS, EXCLUDE_PENALTY_SUCCESS_MESSAGE))
        dispatch(setSelectedPenaltyRows([]))
        setTimeout(() => {
          dispatch(fetchPenalties(0, dates[0].fromDate, dates[0].toDate))
          dispatch(fetchPenalties(2, dates[2].fromDate, dates[2].toDate))
          // eslint-disable-next-line no-unused-expressions
          isEmpty(penaltySearch.searchValue?.trim())
            ? dispatch(fetchLateOrCancelledOrders())
            : dispatch(searchPenalty())
        }, 2000)
      }
    }
    return Promise.resolve()
  })
}

/**
 * @description Hide/show Penalty Exclude/Reject Pop up
 * @returns
 */
export const showHidePopup = show => dispatch => {
  return dispatch({
    type: SHOW_HIDE_EXCLUDE_POPUP,
    payload: show
  })
}

/**
 * @description set exclude penalty reason
 * @returns
 */
export const setExcludePenaltyReason = selectedReason => dispatch => {
  dispatch(setExcludePenaltyDescription(''))
  return dispatch({
    type: SET_EXCLUDE_PENALTY_REASON,
    payload: selectedReason
  })
}

/**
 * @description set exclude penalty description
 * @returns
 */
export const setExcludePenaltyDescription = selectedDescription => dispatch => {
  return dispatch({
    type: SET_EXCLUDE_PENALTY_DESCRIPTION,
    payload: selectedDescription
  })
}

/**
 * @description set exclude penalty reason error
 * @returns {function(...[*]=)}
 */
export const setExcludePenaltyReasonError = isError => dispatch => {
  return dispatch({
    type: SET_EXCLUDE_PENALTY_REASON_ERROR,
    payload: isError
  })
}

/**
 * @description set exclude penalty description error
 * @returns {function(...[*]=)}
 */
export const setExcludePenaltyDescriptionError = isError => dispatch => {
  return dispatch({
    type: SET_EXCLUDE_PENALTY_DESCRIPTION_ERROR,
    payload: isError
  })
}

/**
 * @description get penalty history
 * @returns
 */
export const getPenaltyHistory = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const { iamToken } = getState().UserInfo?.userDetails
  const PAGE_SIZE = getState().SSHPaginatedTableReducer.pageSize
  const PAGE_NO = getState().SSHPaginatedTableReducer.pageNo
  const { isCompletedView } = getState().Approvals
  let ROUTER_PATH = getState().router.location.pathname
  let isCompleted = isCompletedView
  const PATH_PARAM = (ROUTER_PATH === ROUTE_MODULES.performance?.monthpenalty?.path && VENDOR_ID) ? `/${VENDOR_ID}` : ''

  if (dispatch(isPenaltyHistoryView())) {
    ROUTER_PATH = '/admin/approvals'
    isCompleted = getState().PenaltyProgram.selectedHistoryTab === RESOLVED
  }

  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_PAGINATED_TABLE_DATA_REQUEST,
        FETCH_PAGINATED_TABLE_DATA_SUCCESS,
        FETCH_PAGINATED_TABLE_DATA_FAILURE
      ],
      endpoint: `${config.get('penalty')}/exclude/suppliers${PATH_PARAM}?pageNo=${PAGE_NO + 1}&pageSize=${PAGE_SIZE}&completed=${isCompleted}`,
      method: 'GET',
      iamToken,
      payload: {
        pathName: ROUTER_PATH
      }
    }
  }).then(({ type, response }) => {
    dispatch(aiPenaltyHistory({ type, response, isCompleted }))
    if (type === FETCH_PAGINATED_TABLE_DATA_SUCCESS) {
      return Promise.resolve(
        dispatch(setPenaltyProgramRowCount({ count: parseInt(response?.payload?.totalCount, 10) || 0 }))
      )
    }
    return Promise.resolve()
  })
}

/**
 * @description Sets the history tab active
 * @param {String} tabName Selected tab name (Pending/Resolved)
 * @returns calls history api
 */
export const setHistoryActiveTab = ({ tabName }) => dispatch => {
  return Promise.resolve(dispatch(resetPenaltyHistoryTab({ tabName }))).then(dispatch(getPenaltyHistory()))
}

/**
 * @description Check if it is history view
 */
export const isPenaltyHistoryView = () => (dispatch, getState) => {
  const route = getState().router.location.pathname
  return (route === '/penalty')
}

/**
 * @description Sets the history tab active
 * @param {String} tabName Selected tab name (Pending/Resolved)
 */
export const resetPenaltyHistoryTab = ({ tabName }) => dispatch => {
  return Promise.resolve(
    dispatch({
      type: SET_PENALTY_HISTORY_TAB,
      payload: { tabName }
    })
  ).then(dispatch(resetTable()))
}

export const downloadPenalty = () => (dispatch, getState) => {
  const VENDOR_ID = getVendorIdFromState(getState)
  const MODULE_TYPE = getRouteNameFromURL({ pathName: getState().router.location.pathname })
  const { selectedTab, penaltySearchFilter, downloadFileLimit, totalRows } = getState().PenaltyProgram
  const { toDate, fromDate, penaltyType } = penaltyFilterParam({ selectedTab, penaltySearchFilter })
  const PATH_URL = `${VENDOR_ID}?penaltyType=${penaltyType}&fromDate=${fromDate}&toDate=${toDate}&isIncluded=${true}`

  if (parseInt(totalRows, 10) > parseInt(downloadFileLimit, 10)) {//show error if download file exisit the limit
    dispatch(showAlert(ERROR, GENERIC_FILE_LARGE_MESSAGE))
    return Promise.resolve()
  }

  dispatch({
    type: DISABLE_DOWNLOAD,
    payload: {
      downloadBtnCtrl: getVisibleModuleName(MODULE_TYPE)
    }
  })
  dispatch({
    type: SET_MODULE_TYPE,
    payload: {
      moduleType: MODULE_TYPE
    }
  })

  return dispatch({
    [CALL_API]: {
      types: [DOWNLOAD_PENALTY_REQUEST, DOWNLOAD_PENALTY_SUCCESS, DOWNLOAD_PENALTY_FAILURE],
      endpoint: `${config.get('penalty')}/orders/download/${PATH_URL}`,
      method: 'GET',
      isExcel: true
    }
  }).then(response => {
    if (response.type === DOWNLOAD_PENALTY_SUCCESS) {
      downloadFile({ data: response.response }, `PenaltyOrders_${VENDOR_ID}_${moment().unix()}`)
      dispatch(showToastMessage({ message: 'Penalty file downloaded successfully.' }))
      setTimeout(() => {
        dispatch(hideToastMessage())
      }, 2000)
    } else {
      dispatch(showToastMessage({ message: ERROR_PENALTY_FILE_DOWNLOAD }))
    }
    dispatch({
      type: ENABLE_DOWNLOAD,
      payload: {
        downloadBtnCtrl: getVisibleModuleName(MODULE_TYPE)
      }
    })
    return Promise.resolve()
  })
}



/**
 * @description set penalty status
 * @param {string} name
 * @param {boolean} isChecked
 *
 */
export const checkPenaltyStatus = ({ name, isChecked }) => dispatch => {
  return Promise.resolve(
    dispatch({
      type: SET_PENALTY_STATUS,
      payload: { name, isChecked }
    })
  )
}

/**
 * @description search key and search value in state
 * @param {string} searchKey
 *  * @param {string} searchValue
 */
export const setPenaltyKey = ({ searchKey, searchValue }) => dispatch => {
  return Promise.resolve(
    dispatch({
      type: SET_FILTER_KEY_VALUE,
      param: {
        searchKey,
        searchValue
      }
    })
  )
}

/**
 * @description clear penalty filter
 */
export const clearPenaltyFilter = () => dispatch => {
  return Promise.resolve(
    dispatch({
      type: CLEAR_PENALTY_FILTER
    })
  )
}

/**
 * @description penalty filter
 * @param {*}  
 * @returns {fromDate, toDate,penaltyType}
 */
export const penaltyFilterParam = ({ selectedTab, penaltySearchFilter }) => {
  const toDate = penaltySearchFilter?.penaltyDateRange?.to || selectedTab?.value.split(',')[1]
  const fromDate = penaltySearchFilter?.penaltyDateRange?.from || selectedTab?.value.split(',')[0]

  const penaltyStatus = filter(penaltySearchFilter?.penaltyStatus, { checked: true }, false)
  const penaltyType = String(penaltyStatus.map(status => status.name).join(',')).toLocaleLowerCase() || 'late,cancelled'

  return {
    fromDate,
    toDate,
    penaltyType
  }
}

/**
 * @description penalty search
 * @param {*} month 
 * @param {*} type 
 * @returns object
 */
export const penaltyARSearch = ({ tabIndex, type }) => (dispatch) => {
  dispatch(setPenaltyProgramTabActive({ tab: getPenaltyTabs()[tabIndex] }))
  const typeSelected = find(gConstants.PENALTY_STATUS_TYPE, { name: type }, false)?.name || gConstants.PENALTY_STATUS_TYPE[0]?.name
  dispatch(checkPenaltyStatus({
    name: typeSelected,
    isChecked: true
  })).then(dispatch(fetchLateOrCancelledOrders()))
}

/**
 * @description reset AR search 
 * @param {*} param 
 * @returns object
 */
export const resetARSearch = (param) => (dispatch) => {
  const { m, pt, n } = param
  if (!isEmpty(m) || !isEmpty(pt) || !isEmpty(n)) {
    return Promise.resolve(dispatch(push(ROUTE_MODULES?.performance?.monthpenalty?.path)))
  }
  return Promise.resolve()
}
