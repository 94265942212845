import { Table } from '@devexpress/dx-react-grid-material-ui'
import React from 'react'
import SSHLabel from '../../components/SSHLabel'
import EditableCellContainer from './EditableCellContainer'
import FeedActionsCell from './FeedActionsCell'
import FeedDetailsCell from './FeedDetailsCell'
import PoNumberCell from './PoNumberCell'
import { LTMAdminActionCell } from './LeadTimeTableCells'
import DownloadActionCell from './DownloadActionCell'
import MantisStatusCell from './MantisStatusCell'
import GiftActionsCell from './GiftActionsCell'
import DownloadedFileCell from './DownloadedFileCell'
import DownloadStatusCell from './DownloadStatusCell'
import DownloadDateTimeCell from './DownloadDateTimeCell'
import SmartInventoryStatusCell from './SmartInventoryStatusCell'
import SmartInventoryActionCell from './SmartInventoryActionCell'
import SmartInventoryTableCell from '../../pages/SmartInventory/SmartInventoryTableCell'
import CancelEditCell from './CancelEditCell'
import PenaltyTypeCell from './PenaltyTypeCell'
import ReturnNumberCell from './ReturnNumberCell'
import TooltipCell from './TooltipCell'
import ViewChangesCell from './ViewChangesCell'
import ShipnodeRequestTypeCell from './ShipnodeRequestTypeCell'
import CustomEditableCell from './CustomEditableCell'
import InTransitItemCell from './InTransitItemCell'
import SaleForecastItemCell from './SaleForecastItemCell'
import ClubForecastCell from './ClubForecastCell'
import OrderForecastItemCell from './OrderForecastItemCell'
import FcapActions from './FcapActions'
import EditCapacity from './EditCapacity'
import FcapUtilizationCell from './UtilizationTableCell'
import AccountReceivablePenaltyCell from './AccountReceivablePenaltyCell'
import { ApprovalEditActionCell } from './ApprovalEditActionCell'
import TotalAREditableCell from './TotalAREditableCell'

const LabelCell = ({ value, style, ...props }) => {
  return (
    <Table.Cell {...props} value={value} style={{ ...style, padding: 0, paddingLeft: 8 }}>
      <SSHLabel value={value} />
    </Table.Cell>
  )
}

/**
 * @description handle same column name scenario with alias name 
 * @param {*} col 
 * @returns {object}
 */
const handleSameColumnName = ({ column, props }) => {
  switch (column?.alias) {
    case 'intransit':
      {
        return column?.isLink ? <InTransitItemCell {...props} /> : <Table.Cell {...props} />
      }
    case 'salesorder':
      {
        return column.isLink ? <SaleForecastItemCell {...props} /> : <Table.Cell {...props} />
      }
    case 'orderdemand':
      {
        return column.isLink ? <OrderForecastItemCell {...props} /> : <Table.Cell {...props} />
      }
    default:
      return <Table.Cell {...props} />
  }
}

const capacityPattern = /planned\d*/g

const CustomTableCell = props => {
  const { column } = props
  // console.log(column)
  switch (column.name) {
    case 'status':
    case 'feedStatus':
      return <LabelCell {...props} />

    case 'legacyPoNumber':
      return <PoNumberCell {...props} />

    case 'availableInventory':
      return <EditableCellContainer {...props} />

    case 'feedDetails':
      return <FeedDetailsCell {...props} />

    case 'feedActions':
      return <FeedActionsCell {...props} />

    case 'approvalActions':
      return <LTMAdminActionCell {...props} />

    case 'approvalEditActions':
      return <ApprovalEditActionCell {...props} />

    case 'includeGiftLabel':
      return <GiftActionsCell {...props} />

    case 'mantisAction':
      return <MantisStatusCell {...props} />

    case 'downloadAction':
      return <DownloadActionCell {...props} />

    case 'downloadStatus':
      return <DownloadStatusCell {...props} />

    case 'fileName':
      return <DownloadedFileCell {...props} />

    case 'downloadCreatedAt':
      return <DownloadDateTimeCell {...props} />

    case 'smartInventoryStatus':
      return <SmartInventoryStatusCell {...props} />

    case 'requestType':
      return <ShipnodeRequestTypeCell {...props} />

    case 'smartInventoryAction':
      return <SmartInventoryActionCell {...props} />

    case 'smartInventoryItem':
      return <SmartInventoryTableCell text={props?.row?.itemDesc} id={props?.row?.itemId} {...props} />

    case 'smartInventoryCategory':
      return <SmartInventoryTableCell text={props?.row?.category} id={props?.row?.categoryId} {...props} />

    case 'smartInventorySubcategory':
      return <SmartInventoryTableCell text={props?.row?.subcategory} id={props?.row?.subcategoryNmbr} {...props} />

    case 'smartInventorySupplier':
      return <SmartInventoryTableCell text={props?.row?.supplierName} id={props?.row?.supplierId} {...props} />

    case 'cancelAction':
      return <CancelEditCell {...props} />

    case 'penaltyType':
      return <PenaltyTypeCell {...props} />

    case 'viewChanges':
      return <ViewChangesCell {...props} />

    case 'orderNo':
      return !column?.acknowledge ? <ReturnNumberCell {...props} /> : <Table.Cell {...props} />

    case 'inTransitInventoryDate':
      return <CustomEditableCell {...props} date={column.date} />

    case 'itemId':
      {
        return handleSameColumnName({ column, props })
      }

    case 'clubId':
      return column?.isLink ? <ClubForecastCell {...props} /> : <Table.Cell {...props} />

    case 'shipNodeId': {
      return column?.refer === 'shipnodeidForCapacity' ? <FcapActions {...props} /> : <Table.Cell {...props} />
    }

    case `${column?.name.match(capacityPattern)}`: {
      return <EditCapacity {...props} />
    }

    case `${column?.name.match(/utilization\d*/g)}`: {
      return <FcapUtilizationCell {...props} />
    }

    case 'latePenaltiesCount':
    case 'cancellationPenaltiesCount':
      return column ? <AccountReceivablePenaltyCell columnName={column.name} {...props} /> : <p>No Data Available</p>

    case 'executedPenalty':
      return <TotalAREditableCell {...props} />

    case 'itemId':
    case 'itemDesc':
    case 'legacyPoNo':
    case 'soNumber':
    case 'shipDate':
    case 'mustShipByDate':
    case 'cost':
    case 'requestedBy':
    case 'requestedOn':
    case 'shipNodeName':
    case 'createdBy':
    case 'updatedBy':
    case 'approvedBy':
    case 'upc':
    case 'desc1':
    case 'desc2':
    case 'subcatDesc':
    case 'vendor':
    case 'manufacturer':
    case 'category':
    case 'subcategory':
    case 'supplierName':
    case 'recurrence':
    case 'shipnodeId':
      return <TooltipCell {...props} />

    default:
      return <Table.Cell {...props} />
  }
}

export default CustomTableCell
