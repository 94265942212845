import { Button } from '@mui/material'
import { withStyles } from '@mui/styles'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import React from 'react'
import SSHLoadingAnimation from './SSHLoadingAnimation'
import SSHTooltip from './SSHTooltip'

const styles = theme => ({
  btn: {
    color: theme.palette.primary.blue90,
    borderRadius: 5,
    minWidth: 'auto'
  },
  btnIcon: props => ({
    fontSize: '1.5em',
    marginRight: props.iconRight === true ? 0 : 4,
    marginLeft: props.iconRight === true ? 4 : 0,
    color: props.iconColor,
    width: theme.spacing(2.5),
    height: theme.spacing(3)
  }),
  loadingIcon: {
    height: '20px !important',
    width: '20px !important',
    marginLeft: '10px'
  },
  containedPrimary: {
    backgroundColor: theme.palette.primary.blue90,
    color: theme.palette.common.white,
    '&:hover, &:active': {
      backgroundColor: theme.coreFn.lighten(theme.palette.primary.blue90, 0.3)
    }
  },
  containedSecondary: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.grey[900],
    '&:hover, &:active': {
      backgroundColor: theme.coreFn.lighten(theme.palette.grey[300], 0.3)
    }
  },
  disabled: {
    cursor: 'not-allowed'
  },
  filter: {
    width: 22,
    height: 16,
    '& path': {
      fill: theme.palette.grey[500]
    }
  },
  outlined: {
    border: '1px solid',
    fontWeight: 500,
    '&:hover': {
      border: '1px solid'
    }
  }
})

const SSHButton = ({ classes, Icon, text, tooltip, iconRight, loading, active, size, ...props }) => {
  const BTN = (
    <Button
      disableElevation
      data-testid="SSHButton"
      size={size}
      {...props}
      classes={{
        root: clsx(classes.btn, { active: !!active }),
        containedPrimary: classes.containedPrimary,
        containedSecondary: classes.containedSecondary,
        disabled: classes.disabled,
        outlined: classes.outlined
      }}>
      {!iconRight && !isEmpty(Icon) && !loading && (
        <Icon className={props.disabled ? classes.filter : classes.btnIcon} color="greenLight" iconRight={iconRight} />
      )}
      {loading ? (
        <>
          <SSHLoadingAnimation size={15} thickness={5} />
          &nbsp;&nbsp;
        </>
      ) : null}
      {text}
      {!!iconRight && !isEmpty(Icon) && !loading && (
        <Icon className={classes.btnIcon} color="greenLight" iconRight={iconRight} />
      )}
    </Button>
  )

  if (props.disabled && !isEmpty(tooltip)) {
    return (
      <SSHTooltip title={tooltip}>
        <span>{BTN}</span>
      </SSHTooltip>
    )
  }
  if (!isEmpty(tooltip)) return <SSHTooltip title={tooltip}>{BTN}</SSHTooltip>
  return BTN
}

SSHButton.defaultProps = {
  loading: false,
  active: false,
  iconRight: false,
  variant: 'text',
  size: 'small'
}

SSHButton.propTypes = {
  classes: PropTypes.object.isRequired,
  Icon: PropTypes.object,
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  active: PropTypes.bool,
  iconRight: PropTypes.bool,
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  size: PropTypes.oneOf(['small', 'medium', 'large'])
}

export default withStyles(styles)(SSHButton)
