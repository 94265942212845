import { IntegratedSelection, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Grid, Table, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { CustomFieldTypeProvider } from '../templates/ReactGridDataTables/CustomFieldTypeProvider'
import CustomTableCell from '../templates/ReactGridDataTables/CustomTableCell'
import { formattedColumnsDefaultProps } from '../utils/commonDefaultProps'
import { formattedColumnsPropType } from '../utils/commonPropTypes'
import SSHTableSkeleton from './SSHTableSkeleton'

class SSHNonPaginatedTable extends Component {
  render = () => {
    const {
      rows,
      columns,
      tableColumnExtensions,
      formattedColumns,
      selectedRows,
      isFetching,
      noDataMessage,
      enableSelection,
      onSelectionChange
    } = this.props

    if (isFetching) {
      return <SSHTableSkeleton />
    }

    return (
      <Grid rows={rows} columns={columns}>
        {enableSelection && <SelectionState selection={selectedRows} onSelectionChange={onSelectionChange} />}
        {enableSelection && <IntegratedSelection />}
        <SortingState
          sorting={[{ columnName: 'orderDate', direction: 'desc' }]}
          onSortingChange={() => null}
          columnExtensions={columns.map(col => ({
            columnName: col.name,
            sortingEnabled: false
          }))}
        />
        {formattedColumns.map(column => (
          <CustomFieldTypeProvider key={column.type} colType={column.type} for={column.list} />
        ))}
        <Table
          columnExtensions={tableColumnExtensions}
          cellComponent={CustomTableCell}
          messages={{ noData: isFetching ? 'Loading data' : noDataMessage }}
        />
        {enableSelection && <TableSelection showSelectAll highlightRow />}
        <TableHeaderRow showSortingControls />
      </Grid>
    )
  }
}

SSHNonPaginatedTable.defaultProps = {
  rows: [{ col1: 'Row1' }],
  columns: [{ name: 'col1', title: 'Column 1' }],
  tableColumnExtensions: [],
  selectedRows: [],
  isFetching: false,
  enableSelection: false,
  noDataMessage: 'No data',
  formattedColumns: formattedColumnsDefaultProps
}

SSHNonPaginatedTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  tableColumnExtensions: PropTypes.array,
  selectedRows: PropTypes.array,
  noDataMessage: PropTypes.string,
  isFetching: PropTypes.bool,
  enableSelection: PropTypes.bool,
  formattedColumns: formattedColumnsPropType,
  // eslint-disable-next-line react/require-default-props
  onSelectionChange: PropTypes.func
}
export default SSHNonPaginatedTable
