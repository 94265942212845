import { Grid, Toolbar, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { SSHAppBar } from '../../components/SSHAppBar'
import { SSHTypography } from '../../components/SSHTypography'
import { INFO_PATHS, MODULE_TYPES, ROUTE_MODULES, monthFirstFormat } from '../../config/genericConstants'
import { DRAWER_WIDTH } from '../LeftNav'
import { MENU_ROUTES as ROUTES } from '../LeftNav/AppRoutes'
import ShipNodeSelector from '../ShipNode/index'
import VendorSearch from '../VendorSearch'
import { ReactComponent as Help } from '../../assets/images/Help.svg'
import SSHTooltip from '../../components/SSHTooltip'
import { isEmpty } from 'lodash'
import { formatStringWithParams, getPathInfo, displayDateFromToday, getNextSatuday, displayPastWeekDays } from '../../utils/common'
import PastDaysSelection from '../Club/PricingServices/PastDaysSelection'

const styles = theme => ({
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH - parseInt(theme.spacing(8).replace('px', ''))}px)`,
    marginLeft: 0,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  border: {
    borderBottom: `1px solid ${theme.palette.primary.columbiaBlue}`,
    height: theme.spacing(8.125),
    top: theme.spacing(5)
  },
  header: {
    height: theme.spacing(3),
    '& h6': {
      marginLeft: theme.spacing(8),
      color: theme.palette.text.primary
    }
  },
  selectorDropDown: {
    padding: `0px ${theme.spacing(3)}`
  },
  hide: {
    display: 'none'
  },
  help: {
    marginLeft: theme.spacing(1)
  },
  temp: {
    alignItems: 'center'
  },
  subText: {
    marginLeft: theme.spacing(8),
    fontSize: theme.spacing(1.75),
    color: theme.palette.primary.cyanMediumShade
  }
})

/**
 * @description header name
 * @param {`*`} pathName
 * @returns string
 */
const getHeaderName = pathName => {
  const name = ROUTES?.find(item => item.url === pathName)?.name || ''
  if (isEmpty(name)) {
    return ROUTES?.flatMap(item => item.nav).find(nav => nav.url === pathName)?.name || ''
  }
  return name
}

/**
 * @description sub header name
 * @param {`*`} pathName
 * @returns string
 */
const getSubHeaderName = pathName => {
  switch (pathName) {
    case ROUTE_MODULES.prepurchase.intransitinventory.path:
      return `In Transit duration from ${displayDateFromToday(1, monthFirstFormat)} to ${displayDateFromToday(30, monthFirstFormat)} (30 days from tomorrow)`
    case ROUTE_MODULES.forecasting.clubsaleforecast.path:
    case ROUTE_MODULES.forecasting.cluborderdemand.path:
      return `Duration is from ${getNextSatuday(monthFirstFormat)} (Next 16 weeks)`
    case ROUTE_MODULES.inventorypricing?.pricingservice.path:
      return `Pricing service for Adult beverages from 
      ${displayPastWeekDays(7)[6]?.name} to ${displayPastWeekDays(7)[0]?.name} (last 7 business days)`
    case ROUTE_MODULES.fulfilment?.orderfulfilment?.path:
      return 'All the orders data are for past 6 months'
  }
}

/**
 * @description enabled supplier search
 * @param {*} pathName
 * @returns boolean
 */
const isSearchEnabled = pathName => {
  const isSearched = ROUTES.find(item => item.url === pathName)?.searchEnabled || false
  if (!isSearched) {
    return ROUTES.flatMap(item => item.nav).find(nav => nav.url === pathName)?.searchEnabled || false
  }
  return isSearched
}

/**
 * @description enabled last 7 week days dropdown
 * @param {*} pathName
 * @returns boolean
 */
const isWeekdaysEnabled = pathName => {
  const weekEnabled = ROUTES.find(item => item.url === pathName)?.weekdaysEnabled || false
  if (!weekEnabled) {
    return ROUTES.flatMap(item => item.nav).find(nav => nav.url === pathName)?.weekdaysEnabled || false
  }
  return weekEnabled
}

/**
 * @description hide component woth matching string
 * @param {*} pathName
 * @returns boolean
 */
const HIDE_COMPONENT = pathName => {
  return [MODULE_TYPES.fulfilment, MODULE_TYPES.inventory, MODULE_TYPES.returnOrder, MODULE_TYPES.inTransitInventory].includes(String(getHeaderName(pathName)).toLowerCase())
}

const HeaderNew = ({ classes, drawerOpen, pathName, selectedVendorId, chargebackDetails }) => {
  return (
    <SSHAppBar elevation={0} position="fixed" className={clsx({ [classes.appBarShift]: drawerOpen }, classes.border)}>
      <Toolbar>
        <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" className={classes.header}>
          <Grid item xs={9}>
            <Grid container justifyContent="flex-start" alignItems="center" className={classes.temp}>
              <Typography variant="h6">{getHeaderName(pathName)}</Typography>
              {INFO_PATHS.includes(pathName) && (
                <SSHTooltip title={formatStringWithParams(getPathInfo(pathName), ...chargebackDetails)}>
                  <Help className={classes.help} />
                </SSHTooltip>
              )}
            </Grid>
            <SSHTypography className={classes.subText}>{getSubHeaderName(pathName)}</SSHTypography>
          </Grid>
          <Grid item className={classes.selectorDropDown}>
            {isSearchEnabled(pathName) && <VendorSearch size="small" />}
          </Grid>
          <Grid item className={clsx(classes.selectorDropDown, !HIDE_COMPONENT(pathName) ? classes.hide : '')}>
            {selectedVendorId && <ShipNodeSelector size="small" />}
          </Grid>
          {isWeekdaysEnabled(pathName) && <PastDaysSelection />}
        </Grid>
      </Toolbar>
    </SSHAppBar>
  )
}

HeaderNew.propTypes = {
  classes: PropTypes.object.isRequired,
  chargebackDetails: PropTypes.object.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  selectedShipNode: PropTypes.shape({
    shipNodeId: PropTypes.string,
    shipNodeName: PropTypes.string
  }).isRequired,
  pathName: PropTypes.string.isRequired,
  selectedVendorId: PropTypes.string.isRequired
}

export const HeaderNewNaked = HeaderNew

const mapStateToProps = state => ({
  drawerOpen: state.AppConfig.leftDrawer.open,
  pathName: state.router.location.pathname,
  selectedVendorId: state.AppConfig.vendor?.selectedVendor?.vendorId,
  chargebackDetails: state.PenaltyProgram.chargebackDetails
})

export default connect(mapStateToProps, null)(withStyles(styles)(HeaderNew))
