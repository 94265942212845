import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import FeedNew from '../pages/Feed/FeedReducerNew'
import FulfillmentNew from '../pages/FulfillmentNew/FulfillmentNewReducer'
import OrderSearchReducer from '../pages/FulfillmentNew/OrderSearchReducer'
import InventoryNew from '../pages/Inventory/InventoryReducerNew'
import Performance from '../pages/Performance/PerformanceReducer'
import ErrorHandler from '../templates/Error/ErrorHandlerReducer'
import UserInfo from '../templates/UserInfo/UserInfoReducer'
import AppConfig from './commonReducers/AppConfig'
import SSHPaginatedTableReducer from './commonReducers/SSHPaginatedTableReducer'
import LeadTime from '../pages/LeadTime/LeadTimeReducer'
import OrderDetailsReducer from '../pages/OrderPage/OrderDetailsReducer'
import MantisLeadTime from '../pages/MantisLeadTime/MantisLeadTimeReducer'
import NotificationDrawer from '../templates/NotificationDrawer/NotificationDrawerReducer'
import ReportsRequests from '../pages/DownloadHistory/DownloadHistoryReducer'
import SupplierOnBoard from '../pages/SupplierManagement/SupplierOnBoardReducer'
import SmartInventory from '../pages/SmartInventory/SmartInventoryReducer'
import Dashboard from '../pages/Dashboard/DashboardReducer'
import PredefinedReports from '../pages/PredefinedReports/PredefinedReportsReducer'
import PenaltyProgram from '../pages/PenaltyProgram/PenaltyProgramReducer'
import ReturnOrder from '../pages/ReturnOrder/ReturnOrderReducer'
import Approvals from '../pages/Approvals/ApprovalsReducer'
import InTransitInventory from '../pages/InTransitInventory/InTransitInventoryReducer'
import ClubForecastChart from '../pages/Club/SaleForecast/ChartView/ClubForecastChartReducer'
import ClubSaleForecast from '../pages/Club/SaleForecast/ForecastReducer'
import ClubOrderDemand from '../pages/Club/OrderDemand/OrderDemandReducer'
import ClubOrderDemandChart from '../pages/Club/OrderDemand/ChartView/OrderDemandChartReducer'
import ClubPricingServices from '../pages/Club/PricingService/PricingServiceReducer'
import ClubOutofStock from '../pages/Club/OutofStock/ClubOutofStockReducer'
import AccountReceivable from '../pages/AccountReceivable/AccountReceivableReducer'
import FCapacity from '../pages/Capacity/CapacityReducer'

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    UserInfo,
    ErrorHandler,
    Performance,
    FulfillmentNew,
    OrderSearchReducer,
    InventoryNew,
    FeedNew,
    AppConfig,
    SSHPaginatedTableReducer,
    LeadTime,
    OrderDetailsReducer,
    MantisLeadTime,
    NotificationDrawer,
    ReportsRequests,
    SupplierOnBoard,
    SmartInventory,
    Dashboard,
    PredefinedReports,
    PenaltyProgram,
    ReturnOrder,
    Approvals,
    InTransitInventory,
    ClubForecastChart,
    ClubSaleForecast,
    ClubOrderDemand,
    ClubOrderDemandChart,
    ClubPricingServices,
    ClubOutofStock,
    AccountReceivable,
    FCapacity
  })

export default createRootReducer