import { isEmpty } from 'lodash'
import { getShipNodeIdFromState, getTelemetryProperties } from '../../utils/common'
import CustomError from '../../utils/CustomError'
import { getAppInsights } from '../../utils/TelemetryService'
import {
  AI_BULK_UPDATE_FAILURE,
  AI_BULK_UPDATE_REQUEST,
  AI_CHANGE_FULFILLMENT_CENTER,
  AI_CHANGE_PAGE,
  AI_CHANGE_PERFORMANCE_FILTERS,
  AI_FEED_DOWNLOAD_ERROR,
  AI_FEED_DOWNLOAD_ERROR_RETRY,
  AI_FEED_ERROR_FILE_DOWNLOAD_FAILED,
  AI_FEED_SEARCH,
  AI_FEED_UPLOAD_ERROR,
  AI_FULFILLMENT_BULK_SINGLE_LINE_FAILED,
  AI_FULFILLMENT_BULK_SINGLE_LINE_RETRY,
  AI_FULFILLMENT_BULK_UPDATE,
  AI_FULFILLMENT_CLOSE_UPDATE_POPUP,
  AI_FULFILLMENT_CLOSE_UPDATE_POPUP_MISTAKE,
  AI_FULFILLMENT_DOWNLOAD_PICK_LIST,
  AI_FULFILLMENT_EXPORT_ORDERS,
  AI_FULFILLMENT_OPEN_UPDATE_POPUP,
  AI_FULFILLMENT_SWITCH_ORDER_TYPE,
  AI_FULFILLMENT_SWITCH_TAB,
  AI_FULFILLMENT_UPDATE_FAILURE,
  AI_FULFILLMENT_UPDATE_SUCCESS,
  AI_INVENTORY_BULK_SINGLE_LINE_FAILED,
  AI_INVENTORY_BULK_SINGLE_LINE_RETRY,
  AI_INVENTORY_BULK_UPDATE,
  AI_INVENTORY_EDIT_CANCEL,
  AI_INVENTORY_EDIT_SAVE,
  AI_INVENTORY_EDIT_START,
  AI_INVENTORY_EXPORT,
  AI_INVENTORY_ITEM_SEARCH,
  AI_LOGIN_SUCCESS,
  AI_PERF_TYPE,
  AI_PERFORMANCE_BULK_SINGLE_LINE_FAILED,
  AI_PERFORMANCE_BULK_SINGLE_LINE_RETRY,
  AI_PERFORMANCE_COMPARE_TO,
  AI_PERFORMANCE_EXPORT,
  AI_PICKLIST_GENERATION_FAILED,
  AI_SELECT_FULFILLMENT_CENTER,
  AI_SELECT_VENDOR,
  AI_SIGN_OUT,
  AI_TABLE_CHANGE_PAGE_NUMBER,
  AI_TABLE_CHANGE_PAGE_SIZE,
  AI_UNAUTHORIZED_USER,
  AI_LTM_GET_HISTORY_SUCCESS,
  AI_LTM_SAVE_CHANGE_REQUEST,
  AI_LTM_SHOW_NEW_REQUEST_POPUP,
  AI_LTM_SUBMIT_NEW_REQUEST_POPUP,
  AI_LTM_CLICK_REQUEST_PENDING_ERROR,
  AI_LTM_CLICK_REQUEST_CANCEL_PENDING,
  AI_LTM_MERCHANT_TAKE_ACTION,
  AI_LTM_MERCHANT_VIEW_PENDING,
  AI_LTM_MERCHANT_VIEW_HISTORY,
  AI_FETCH_ORDER_DETAILS,
  AI_UPDATE_ITEM_DETAILS_CHANGES,
  AI_DOWNLOAD_LEAD_TIME_REPORT,
  AI_UPDATE_IN_TRANSIT_INVENTORY
} from './Telemetry.actionTypes'

/**
 * @desc Telemetry action to track if a user has successfully logged in using RetailLink or cookie
 * @desc Called using authorization process
 * @returns {function(...[*]=)}
 */
export const aiLoginSuccess = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_LOGIN_SUCCESS,
    properties: {
      ...getTelemetryProperties(getState),
      viaCookie: getState().UserInfo.isCookie,
      viaRL: !getState().UserInfo.isCookie
    }
  })
  return Promise.resolve()
}

/**
 * @desc Telemetry action to track if a user is unauthorized to access the application
 * @desc Called from Unauthorized page's componentDidMount hook
 * @returns {function(...[*]=)}
 */
export const aiUnauthorizedUser = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_UNAUTHORIZED_USER,
    properties: {
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @desc Telemetry action to track when user signs out from the application
 * @returns {function(...[*]=)}
 */
export const aiSignOut = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SIGN_OUT,
    properties: {
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @desc Telemetry action to track when user signs out from the application
 * @returns {function(...[*]=)}
 */
export const aiSelectVendor = () => (dispatch, getState) => {
  const userAccess = getState().UserInfo.access
  getAppInsights().trackEvent({
    name: AI_SELECT_VENDOR,
    properties: {
      ...getTelemetryProperties(getState),
      accessLevels: { ...userAccess }
    }
  })
}

/**
 * @desc When user selects a fulfillment center (ship node) in either inventory or fulfillment page
 * @returns {function(...[*]=)}
 */
export const aiSelectFulfillmentCenter = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_SELECT_FULFILLMENT_CENTER,
    properties: {
      ...getTelemetryProperties(getState),
      shipNodeId: getShipNodeIdFromState(getState).shipNodeId
    }
  })
}

/**
 * @desc When user clicks on Edit icon against a selected fulfillment center (ship node)
 * in either inventory or fulfillment page
 * @returns {function(...[*]=)}
 */
export const aiChangeFulfillmentCenter = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_CHANGE_FULFILLMENT_CENTER,
    properties: {
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @desc When user changes the page number in any table
 * @param {Number} oldPage
 * @param {Number} newPage
 * @returns {function(...[*]=)}
 */
export const aiTableChangePageNumber = ({ oldPage, newPage }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_TABLE_CHANGE_PAGE_NUMBER,
    properties: {
      ...getTelemetryProperties(getState),
      oldPage: oldPage + 1,
      newPage: newPage + 1
    }
  })
}

/**
 * @desc When user changes the page size in any table
 * @param {Number} oldPageSize
 * @param {Number} newPageSize
 * @returns {function(...[*]=)}
 */
export const aiTableChangePageSize = ({ oldPageSize, newPageSize }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_TABLE_CHANGE_PAGE_SIZE,
    properties: {
      ...getTelemetryProperties(getState),
      oldPageSize,
      newPageSize
    }
  })
}

/**
 * @desc Telemetry action to track pageView when user changes page manually
 * @returns {function(...[*]=)}
 * @param {String} oldPage
 * @param {String} newPage
 */
export const aiChangePage = ({ oldPage, newPage }) => (dispatch, getState) => {
  getAppInsights().trackPageView({
    name: AI_CHANGE_PAGE,
    uri: newPage,
    refUri: oldPage,
    isLoggedIn: true,
    properties: {
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @desc Start tracking API calls
 * @param {String} APIName
 * @returns {function(...[*]=)}
 */
export const aiStartAPITracking = ({ APIName }) => () => {
  getAppInsights().startTrackEvent(`API_CALL___${APIName}`)
}

/**
 * @desc Stop tracking API calls and send metrics to Azure
 * @param {String} APIName
 * @param {String} endpoint
 * @param {String} method
 * @param {Object} requestParams
 * @param {Number} responseCode
 * @param {String} errorText
 * @param {Number} responseTime
 * @returns {function(...[*]=)}
 */
export const aiStopAPITracking = ({
  APIName,
  endpoint,
  method,
  requestParams,
  responseCode,
  errorText,
  responseTime
}) => (dispatch, getState) => {
  getAppInsights().stopTrackEvent(`API_CALL___${APIName}`, {
    ...getTelemetryProperties(getState),
    endpoint,
    method,
    requestParams,
    responseCode,
    errorText,
    responseTime
  })
}

/**
 *
 * @param {Error} error
 * @param {Object} params
 * @param {Number} severityLevel
 * @param {String} name
 * @returns {function(...[*]=)}
 */
export const aiTrackException = ({ error, severityLevel, name, params }) => (dispatch, getState) => {
  if (!isEmpty(error)) {
    getAppInsights().trackException({
      error,
      severityLevel: 3,
      properties: {
        errorMessage: name,
        ...getTelemetryProperties(getState)
      }
    })
  } else {
    try {
      throw new CustomError(name, {})
    } catch (err) {
      getAppInsights().trackException({
        error: err,
        severityLevel,
        properties: {
          errorMessage: name,
          ...getTelemetryProperties(getState),
          ...params
        }
      })
    }
  }
}

/**
 *
 * @param {String} type
 * @param {String} value
 * @returns {function(...[*]=)}
 */
export const aiChangePerformanceFilters = ({ type, value }) => (dispatch, getState) => {
  let options = { changeType: type, changedValue: '' }
  if (type === AI_PERF_TYPE) {
    options = {
      ...options,
      changedValue: getState()
        .Performance.detailedView.enabledColumns.filter(col => !!col.active)
        .map(col => col.code)
        .join(',')
    }
  } else {
    options = {
      ...options,
      changedValue: value
    }
  }

  getAppInsights().trackEvent({
    name: AI_CHANGE_PERFORMANCE_FILTERS,
    properties: {
      ...getTelemetryProperties(getState),
      ...options
    }
  })
}

/**
 *
 * @param {String} compareType
 * @returns {function(...[*]=)}
 */
export const aiPerformanceCompareTo = ({ compareType }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_PERFORMANCE_COMPARE_TO,
    properties: {
      ...getTelemetryProperties(getState),
      compareType
    }
  })
}

/**
 * @desc When user clicks on download button in performance page
 * @returns {function(...[*]=)}
 */
export const aiPerformanceExport = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_PERFORMANCE_EXPORT,
    properties: {
      ...getTelemetryProperties(getState),
      selectedWeek: getState().Performance.selected.weekDetailedView.value
    }
  })
}

/**
 * @desc Whenever user searches for something in the feed page
 * @returns {function(...[*]=)}
 */
export const aiFeedSearch = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_FEED_SEARCH,
    properties: {
      ...getTelemetryProperties(getState),
      query: getState().SSHPaginatedTableReducer.searchQuery
    }
  })
}

/**
 *
 * @param {String} ackId
 * @param {String} feedType
 * @param {Boolean} retry
 * @returns {function(...[*]=)}
 */
export const aiFeedDownloadError = ({ ackId, feedType, retry }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: retry ? AI_FEED_DOWNLOAD_ERROR_RETRY : AI_FEED_DOWNLOAD_ERROR,
    properties: {
      ...getTelemetryProperties(getState),
      query: getState().SSHPaginatedTableReducer.searchQuery,
      ackId,
      feedType
    }
  })
}

/**
 *
 * @param {String} ackId
 * @param {String} feedType
 * @returns {function(...[*]=)}
 */
export const aiFeedUploadError = ({ ackId, feedType }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_FEED_UPLOAD_ERROR,
    properties: {
      ...getTelemetryProperties(getState),
      query: getState().SSHPaginatedTableReducer.searchQuery,
      ackId,
      feedType
    }
  })
}

/**
 * @desc When user switches between Active & Closed tabs in fulfillment page
 * @returns {function(...[*]=)}
 */
export const aiFulfillmentSwitchTab = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_FULFILLMENT_SWITCH_TAB,
    properties: {
      ...getTelemetryProperties(getState),
      activeTab: getState().FulfillmentNew.selectedTabView
    }
  })
}

/**
 * @desc When user switches between Different Order Types in fulfillment page
 * @returns {function(...[*]=)}
 */
export const aiFulfillmentSwitchOrderType = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_FULFILLMENT_SWITCH_ORDER_TYPE,
    properties: {
      ...getTelemetryProperties(getState),
      activeTab: getState().FulfillmentNew.selectedTabView,
      activeOrderType: getState().FulfillmentNew.selectedOrderType
    }
  })
}

/**
 * @desc When user downloads the picklist for selected orders
 * @returns {function(...[*]=)}
 */
export const aiFulfillmentDownloadPickList = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_FULFILLMENT_DOWNLOAD_PICK_LIST,
    properties: {
      ...getTelemetryProperties(getState),
      activeOrderType: getState().FulfillmentNew.selectedOrderType,
      selectedPoNumbers: getState()
        .FulfillmentNew.selectedPoList.map(po => po.poNumber)
        .join(',')
    }
  })
}

/**
 * @desc When user clicks on change order status button after selecting rows
 * @returns {function(...[*]=)}
 */
export const aiFulfillmentOpenUpdatePopup = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_FULFILLMENT_OPEN_UPDATE_POPUP,
    properties: {
      ...getTelemetryProperties(getState),
      activeOrderType: getState().FulfillmentNew.selectedOrderType,
      selectedPoNumbers: getState()
        .FulfillmentNew.selectedPoList.map(po => po.poNumber)
        .join(',')
    }
  })
}

/**
 * @desc When user closes the update order popup
 * @param {Boolean} unsavedChanges - If there were any unsaved changes
 * @returns {function(...[*]=)}
 */
export const aiFulfillmentCloseUpdatePopup = ({ unsavedChanges }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_FULFILLMENT_CLOSE_UPDATE_POPUP,
    properties: {
      ...getTelemetryProperties(getState),
      activeOrderType: getState().FulfillmentNew.selectedOrderType,
      selectedPoNumbers: getState()
        .FulfillmentNew.selectedPoList.map(po => po.poNumber)
        .join(','),
      unsavedChanges
    }
  })
}

/**
 * @desc When user closes the confirmation popup which prevents them from accidentally closing the update order popup
 * @returns {function(...[*]=)}
 */
export const aiFulfillmentCloseUpdatePopupMistake = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_FULFILLMENT_CLOSE_UPDATE_POPUP_MISTAKE,
    properties: {
      ...getTelemetryProperties(getState),
      activeOrderType: getState().FulfillmentNew.selectedOrderType,
      selectedPoNumbers: getState()
        .FulfillmentNew.selectedPoList.map(po => po.poNumber)
        .join(',')
    }
  })
}

/**
 * @desc When user successfully updates a release from UI
 * @param {Object} release
 * @returns {function(...[*]=)}
 */
export const aiFulfillmentUpdateSuccess = ({ release }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_FULFILLMENT_UPDATE_SUCCESS,
    properties: {
      ...getTelemetryProperties(getState),
      poNumber: release.basicDetails.poNumber,
      releaseNumber: release.basicDetails.releaseNumber
    }
  })
}

/**
 * @desc When user fails to update a release from UI due to data entry error
 * @param {Object} release
 * @param {String} errorMessage
 * @returns {function(...[*]=)}
 */
export const aiFulfillmentUpdateFailure = ({ release, errorMessage }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_FULFILLMENT_UPDATE_FAILURE,
    properties: {
      ...getTelemetryProperties(getState),
      poNumber: release.basicDetails.poNumber,
      releaseNumber: release.basicDetails.releaseNumber,
      errorMessage
    }
  })
}

/**
 * @desc When user clicks on Download/Export in Fulfillment page
 * @returns {function(...[*]=)}
 */
export const aiFulfillmentExportOrders = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_FULFILLMENT_EXPORT_ORDERS,
    properties: {
      ...getTelemetryProperties(getState),
      activeOrderType: getState().FulfillmentNew.selectedOrderType
    }
  })
}

/**
 * @desc When user clicks on Bulk Update in Fulfillment page
 * @returns {function(...[*]=)}
 */
export const aiFulfillmentBulkUpdate = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_FULFILLMENT_BULK_UPDATE,
    properties: {
      ...getTelemetryProperties(getState),
      activeOrderType: getState().FulfillmentNew.selectedOrderType
    }
  })
}

/**
 * @desc When user clicks on Bulk Update in Fulfillment page
 * @param {Number} fileCount
 * @returns {function(...[any]=)}
 */
export const aiBulkUpdateRequest = ({ fileCount }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_BULK_UPDATE_REQUEST,
    properties: {
      ...getTelemetryProperties(getState),
      module: getState().AppConfig.bulkUpdate.isFulfillment ? 'fulfillment' : 'inventory',
      fileCount
    }
  })
}

/**
 * @desc When user clicks on Bulk Update in Fulfillment page
 * @param {Number} fileCount
 * @returns {function(...[any]=)}
 */
export const aiBulkUpdateError = ({ fileCount }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_BULK_UPDATE_FAILURE,
    properties: {
      ...getTelemetryProperties(getState),
      module: getState().AppConfig.bulkUpdate.isFulfillment ? 'fulfillment' : 'inventory',
      fileCount
    }
  })
}

/**
 * @desc When user searches for an item number in inventory page
 * @returns {function(...[*]=)}
 */
export const aiInventorySearch = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_INVENTORY_ITEM_SEARCH,
    properties: {
      ...getTelemetryProperties(getState),
      query: getState().SSHPaginatedTableReducer.searchQuery
    }
  })
}

/**
 * @desc When user clicks on Download/Export in inventory page
 * @returns {function(...[*]=)}
 */
export const aiInventoryExport = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_INVENTORY_EXPORT,
    properties: {
      ...getTelemetryProperties(getState),
      totalInventory: getState().InventoryNew.totalInventory
    }
  })
}

/**
 * @desc When user clicks on Bulk Update in inventory page
 * @returns {function(...[*]=)}
 */
export const aiInventoryBulkUpdate = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_INVENTORY_BULK_UPDATE,
    properties: {
      ...getTelemetryProperties(getState),
      totalInventory: getState().InventoryNew.totalInventory
    }
  })
}

/**
 * @desc When user starts editing available inventory from UI in inventory page
 * @param {String} itemId
 * @returns {function(...[*]=)}
 */
export const aiInventoryEditStart = ({ itemId }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_INVENTORY_EDIT_START,
    properties: {
      ...getTelemetryProperties(getState),
      itemId,
      totalInventory: getState().InventoryNew.totalInventory
    }
  })
}

/**
 * @desc When user cancels editing available inventory from UI in inventory page, without saving changes
 * @param {String} itemId
 * @returns {function(...[*]=)}
 */
export const aiInventoryEditCancel = ({ itemId }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_INVENTORY_EDIT_CANCEL,
    properties: {
      ...getTelemetryProperties(getState),
      itemId,
      totalInventory: getState().InventoryNew.totalInventory
    }
  })
}

/**
 * @desc When user saves the updated available inventory from UI in inventory page
 * @param {String} itemId
 * @returns {function(...[*]=)}
 */
export const aiInventoryEditSave = ({ itemId, sourceType }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_INVENTORY_EDIT_SAVE,
    properties: {
      ...getTelemetryProperties(getState),
      itemId,
      sourceType,
      totalInventory: getState().InventoryNew.totalInventory
    }
  })
}

/**
 * @desc When picklist file generation fails
 * @param {String} ackId
 * @returns {function(...[*]=)}
 */
export const aiPicklistGenerationFailed = ({ ackId }) => dispatch => {
  dispatch(
    aiTrackException({
      error: null,
      name: AI_PICKLIST_GENERATION_FAILED,
      severityLevel: 3,
      params: {
        ackId
      }
    })
  )
}

/**
 * @desc Feed error file download failed
 * @param {String} ackId
 * @param {String} feedType
 * @returns {function(...[*]=)}
 */
export const aiFeedErrorDownloadFailed = ({ ackId, feedType }) => dispatch => {
  dispatch(
    aiTrackException({
      error: null,
      name: AI_FEED_ERROR_FILE_DOWNLOAD_FAILED,
      severityLevel: 3,
      params: {
        ackId,
        feedType
      }
    })
  )
}

/**
 * @desc Bulk download line item generation failure
 * @param {String} ackId
 * @returns {function(...[*]=)}
 */
export const aiBulkExportSingleLineFailed = ({ ackId }) => (dispatch, getState) => {
  const exportData = getState().AppConfig.exportOrders
  const exceptionTitle = {
    Performance: AI_PERFORMANCE_BULK_SINGLE_LINE_FAILED,
    Inventory: AI_INVENTORY_BULK_SINGLE_LINE_FAILED,
    Fulfillment: AI_FULFILLMENT_BULK_SINGLE_LINE_FAILED
  }

  dispatch(
    aiTrackException({
      error: null,
      name: exceptionTitle[exportData.moduleType],
      severityLevel: 3,
      params: {
        ackId,
        moduleType: exportData.moduleType
      }
    })
  )
}

/**
 * @description Track when user clicks on retry icon within the bulk export popup against a single line
 * @param {String} ackId
 * @returns {function(...[*]=)}
 */
export const aiBulkExportSingleLineRetry = ({ ackId }) => (dispatch, getState) => {
  const exportData = getState().AppConfig.exportOrders
  const exceptionTitle = {
    Performance: AI_PERFORMANCE_BULK_SINGLE_LINE_RETRY,
    Inventory: AI_INVENTORY_BULK_SINGLE_LINE_RETRY,
    Fulfillment: AI_FULFILLMENT_BULK_SINGLE_LINE_RETRY
  }
  getAppInsights().trackEvent({
    name: exceptionTitle[exportData.moduleType],
    properties: {
      ackId,
      ...getTelemetryProperties(getState)
    }
  })
}

/* ====== LEAD TIME MODULE ====== */

/**
 * @description Track when user clicks on lead time vendor page
 * @param {String} SHIP_NODE_ID
 * @returns {function(...[*]=)}
 */
export const aiGetRequestHistory = ({ SHIP_NODE_ID }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_LTM_GET_HISTORY_SUCCESS,
    properties: {
      shipNodeId: SHIP_NODE_ID,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track when user clicks on to save lead time
 * @param {String} oldLeadTime
 * @param {String} newLeadTime
 * @param {String} reason
 * @returns {function(...[*]=)}
 */
export const aiSaveLeadTime = ({ oldLeadTime, newLeadTime, reason }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_LTM_SAVE_CHANGE_REQUEST,
    properties: {
      oldLeadTime,
      newLeadTime,
      reason,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track when user clicks on to save lead time popup
 * @param {String} shipNodeId
 * @param {String} shipNodeName
 * @returns {function(...[*]=)}
 */
export const aiNewRequestPopup = ({ shipNodeId, shipNodeName }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_LTM_SHOW_NEW_REQUEST_POPUP,
    properties: {
      shipNodeId,
      shipNodeName,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track when user clicks on to submit or save lead time
 * @param {String} newLeadTime
 * @param {String} reason
 * @returns {function(...[*]=)}
 */
export const aiSubmitNewRequest = ({ newLeadTime, reason }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_LTM_SUBMIT_NEW_REQUEST_POPUP,
    properties: {
      newLeadTime,
      reason,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track when user clicks on new request for already pending
 * @param {String} shipNodeId
 * @param {String} shipNodeName
 * @returns {function(...[*]=)}
 */
export const aiShowPendingBox = ({ shipNodeId, shipNodeName }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_LTM_CLICK_REQUEST_PENDING_ERROR,
    properties: {
      shipNodeId,
      shipNodeName,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track when user clicks on cancel for already pending
 * @param {String} taskId
 * @param {String} shipNodeId
 * @param {String} supplierId
 * @returns {function(...[*]=)}
 */
export const aiCancelPendingRequest = ({ taskId, shipNodeId, supplierId }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_LTM_CLICK_REQUEST_CANCEL_PENDING,
    properties: {
      taskId,
      shipNodeId,
      supplierId,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track when user clicks on merchant LTM page to approval or reject request
 * @param {String} task
 * @param {String} isApproved
 * @param {String} reason
 * @returns {function(...[*]=)}
 */
export const aiMerchantApprovalReject = ({ task, isApproved, reason }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_LTM_MERCHANT_TAKE_ACTION,
    properties: {
      task,
      isApproved,
      reason,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track when user clicks on merchant LTM page to view pending request
 * @returns {function(...[*]=)}
 */
export const aiViewPending = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_LTM_MERCHANT_VIEW_PENDING,
    properties: {
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track when user clicks on merchant LTM page to view history
 * @returns {function(...[*]=)}
 */
export const aiViewHistory = () => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_LTM_MERCHANT_VIEW_HISTORY,
    properties: {
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description Track when user clicks on merchant LTM page to view history
 * @returns {function(...[*]=)}
 */
export const aiDownloadLeadTimeReport = ({ selectedRange, reportType }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_DOWNLOAD_LEAD_TIME_REPORT,
    properties: {
      selectedRange,
      ...getTelemetryProperties(getState),
      reportType
    }
  })
}

/**
 * @description track fetch order details
 * @param {object}
 * @returns {function(...[*]=)}
 */
export const aiFetchOrderDetails = ({ reqObj }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_FETCH_ORDER_DETAILS,
    properties: {
      vendorId: reqObj.vendorId,
      shipNodeId: reqObj.shipNodeId,
      poStatusList: reqObj.poStatusList,
      poNumberList: reqObj.poNumberList,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description track update item changes will change order status Acknowledge or Cancel
 * @param {object} {items, releaseNo, poNumber }
 * @returns {function(...[*]=)}
 */
export const aiUpdateItemDetailsChanges = ({ items, releaseNo, poNumber }) => (dispatch, getState) => {
  getAppInsights().trackEvent({
    name: AI_UPDATE_ITEM_DETAILS_CHANGES,
    properties: {
      items,
      releaseNo,
      poNumber,
      ...getTelemetryProperties(getState)
    }
  })
}

/**
 * @description track update item changes for in transit inventory
 * @param {object} {payload}
 * @returns {function(...[*]=)}
 */
export const aiUpdateInTransitInventory = ({ payload }) => () => {
  getAppInsights().trackEvent({
    name: AI_UPDATE_IN_TRANSIT_INVENTORY,
    properties: { payload }
  })
}
