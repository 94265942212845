import { Grid, Typography, Box } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ReactComponent as LeftArrowIcon } from '../../../assets/images/leftArrow.svg'
import { changePage } from '../../../reduxSetup/commonActions/AppConfig'
import { SSHTypography } from '../../../components/SSHTypography'
import ShipNodeSelector from '../../../templates/ShipNode'
import SSHNumberInput from '../../../components/SSHNumberInput'
import SSHButton from '../../../components/SSHButton'
import { fetchInTransitItemsFromPath } from '../InTransitInventoryAction'

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.primary.headerBackground,
        padding: `${theme.replacePX(theme.spacing(0.5))}px ${theme.replacePX(theme.spacing(3.2))}px`,
        position: 'fixed',
        width: '97%',
        top: '40px',
        zIndex: theme.zIndex.appBar + 1
    },
    input: {
        'background-color': '#fafafa',
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    },
    focus: {
        '& .Mui-focused': {
            'box-shadow': `0px 0px 0px 2px ${theme.palette.primary.blueLight}, 0px 0px 12px ${theme.rgba(
                theme.palette.primary.blueLight,
                0.75
            )}`
        }
    },
    subText: {
        marginLeft: theme.spacing(3),
        fontSize: theme.spacing(1.4),
        color: theme.palette.primary.softBlue
    },
    title: {
        color: theme.palette.primary.baseTextDark,
        fontWeight: '400',
        fontSize: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontSize: theme.spacing(1.75)
        }
    }
})

function usePreviousValue(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const EditHeader = ({ classes, changePage, selectedItem, selectedShipNode, selectedVendorId, fetchInTransitItemsFromPath, ...restProps }) => {
    const [selectedItemId, setSelecteditemId] = React.useState()
    const prevShipnodeSelected = usePreviousValue(selectedShipNode?.shipNodeId)
    const { drawerOpen } = restProps

    /**
     * @description action on itemId change
     * @returns set selectedItemId
     */
    const onItemIdChange = (e) => {
        setSelecteditemId(e.target.value)
    }

    /**
     * @description refresh page on search
     * @returns fetch in transit items
     */
    const refreshPage = (itemId) => {
        changePage(`/in-transit-inventory/vendor/${selectedVendorId}/shipNode/${selectedShipNode?.shipNodeId}/item/${itemId}`)
        fetchInTransitItemsFromPath(itemId)
    }

    /**
     * @description refresh page if different shipnode is selected
     * @returns refresh page with new shipnode
     */
    useEffect(() => {
        if ((prevShipnodeSelected && prevShipnodeSelected !== selectedShipNode?.shipNodeId)) {
            refreshPage(selectedItem?.itemId)
        }
    })

    return (
        <Box className={classes.root}>
            <Grid container spacing={2} alignItems='center'>
                <Grid item xs={drawerOpen ? 5 : 6}>
                    <Typography variant="h6" className={classes.title}>
                        <>
                            <LeftArrowIcon onClick={() => changePage('/in-transit-inventory')} cursor={'pointer'} /> &nbsp;&nbsp;
                            {selectedItem?.itemId}&nbsp;&nbsp;
                        </>
                    </Typography>
                    <SSHTypography className={classes.subText}>{selectedItem?.itemName}</SSHTypography>
                </Grid>
                <Grid item xs={3}>
                    <ShipNodeSelector size="small"></ShipNodeSelector>
                </Grid>
                <Grid item xs={2}>
                    <SSHNumberInput
                        size="small"
                        variant="outlined"
                        type="number"
                        name="itemNumber"
                        placeholder="Search by item Id"
                        onChange={onItemIdChange}
                        className={clsx(classes.focus, classes.input)}
                        value={selectedItemId}
                    />
                </Grid>
                <Grid item xs={1}>
                    <SSHButton color='primary' size='md' disabled={!selectedItemId} variant='contained' text='Apply' onClick={() => refreshPage(selectedItemId)} />
                </Grid>
            </Grid>
        </Box>
    )
}
export const EditHeaderNaked = EditHeader

EditHeader.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    selectedItem: state.InTransitInventory.selectedItem,
    selectedVendorId: state.AppConfig.vendor.selectedVendor.vendorId,
    selectedShipNode: { ...state.AppConfig.shipNodes.selected },
    drawerOpen: state.AppConfig.leftDrawer.open
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { changePage, fetchInTransitItemsFromPath },
        dispatch
    )
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditHeader))
