/* ==================== GLOBAL ========================= */
export const AI_LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const AI_UNAUTHORIZED_USER = 'UNAUTHORIZED_USER'
export const AI_SIGN_OUT = 'SIGN_OUT'
export const AI_CHANGE_PAGE = 'CHANGE_PAGE'
export const AI_SELECT_VENDOR = 'SELECT_VENDOR'
export const AI_SELECT_FULFILLMENT_CENTER = 'SELECT_FULFILLMENT_CENTER'
export const AI_CHANGE_FULFILLMENT_CENTER = 'CHANGE_FULFILLMENT_CENTER'
export const AI_TABLE_CHANGE_PAGE_NUMBER = 'TABLE_CHANGE_PAGE_NUMBER'
export const AI_TABLE_CHANGE_PAGE_SIZE = 'TABLE_CHANGE_PAGE_SIZE'
export const AI_BULK_UPDATE_REQUEST = 'BULK_UPDATE_REQUEST'
export const AI_BULK_UPDATE_FAILURE = 'BULK_UPDATE_FAILURE'

/* ==================== PERFORMANCE ========================= */
export const AI_CHANGE_PERFORMANCE_FILTERS = 'CHANGE_PERFORMANCE_FILTERS'
export const AI_PERF_TYPE = 'PERF_TYPE'
export const AI_PERF_YEAR = 'PERF_YEAR'
export const AI_PERF_MONTH = 'PERF_MONTH'
export const AI_PERF_WEEK = 'PERF_WEEK'
export const AI_PERFORMANCE_COMPARE_TO = 'PERFORMANCE_COMPARE_TO'
export const AI_PERFORMANCE_EXPORT = 'PERFORMANCE_EXPORT'

/* ==================== FEED ========================= */
export const AI_FEED_SEARCH = 'FEED_SEARCH'
export const AI_FEED_DOWNLOAD_ERROR = 'FEED_DOWNLOAD_ERROR'
export const AI_FEED_DOWNLOAD_ERROR_RETRY = 'FEED_DOWNLOAD_ERROR_RETRY'
export const AI_FEED_UPLOAD_ERROR = 'FEED_UPLOAD_ERROR'

/* ==================== INVENTORY ========================= */
export const AI_INVENTORY_ITEM_SEARCH = 'INVENTORY_ITEM_SEARCH'
export const AI_INVENTORY_EXPORT = 'INVENTORY_EXPORT'
export const AI_INVENTORY_BULK_UPDATE = 'INVENTORY_BULK_UPDATE'
export const AI_INVENTORY_EDIT_START = 'INVENTORY_EDIT_START'
export const AI_INVENTORY_EDIT_CANCEL = 'INVENTORY_EDIT_CANCEL'
export const AI_INVENTORY_EDIT_SAVE = 'INVENTORY_EDIT_SAVE'

/* ==================== FULFILLMENT ========================= */
export const AI_FULFILLMENT_SWITCH_TAB = 'FULFILLMENT_SWITCH_TAB'
export const AI_FULFILLMENT_SWITCH_ORDER_TYPE = 'FULFILLMENT_SWITCH_ORDER_TYPE'
export const AI_FULFILLMENT_DOWNLOAD_PICK_LIST = 'FULFILLMENT_DOWNLOAD_PICK_LIST'
export const AI_FULFILLMENT_OPEN_UPDATE_POPUP = 'FULFILLMENT_OPEN_UPDATE_POPUP'
export const AI_FULFILLMENT_CLOSE_UPDATE_POPUP = 'FULFILLMENT_CLOSE_UPDATE_POPUP'
export const AI_FULFILLMENT_CLOSE_UPDATE_POPUP_MISTAKE = 'FULFILLMENT_CLOSE_UPDATE_POPUP_MISTAKE'
export const AI_FULFILLMENT_EXPORT_ORDERS = 'FULFILLMENT_EXPORT_ORDERS'
export const AI_FULFILLMENT_BULK_UPDATE = 'FULFILLMENT_BULK_UPDATE'
export const AI_FULFILLMENT_UPDATE_SUCCESS = 'FULFILLMENT_UPDATE_SUCCESS'
export const AI_FULFILLMENT_UPDATE_FAILURE = 'FULFILLMENT_UPDATE_FAILURE'

/* ===================== EXCEPTIONS ========================= */
export const AI_PICKLIST_GENERATION_FAILED = 'PICKLIST_GENERATION_FAILED'
export const AI_FEED_ERROR_FILE_DOWNLOAD_FAILED = 'FEED_ERROR_FILE_DOWNLOAD_FAILED'
export const AI_FULFILLMENT_BULK_SINGLE_LINE_FAILED = 'FULFILLMENT_BULK_SINGLE_LINE_FAILED'
export const AI_INVENTORY_BULK_SINGLE_LINE_FAILED = 'INVENTORY_BULK_SINGLE_LINE_FAILED'
export const AI_PERFORMANCE_BULK_SINGLE_LINE_FAILED = 'PERFORMANCE_BULK_SINGLE_LINE_FAILED'
export const AI_FULFILLMENT_BULK_SINGLE_LINE_RETRY = 'FULFILLMENT_BULK_SINGLE_LINE_RETRY'
export const AI_INVENTORY_BULK_SINGLE_LINE_RETRY = 'INVENTORY_BULK_SINGLE_LINE_RETRY'
export const AI_PERFORMANCE_BULK_SINGLE_LINE_RETRY = 'PERFORMANCE_BULK_SINGLE_LINE_RETRY'

/* ===================== LEAD TIME ========================= */
export const AI_LTM_GET_HISTORY_SUCCESS = 'LTM_GET_HISTORY_SUCCESS'
export const AI_LTM_SAVE_CHANGE_REQUEST = 'LTM_SAVE_CHANGE_REQUEST'
export const AI_LTM_SHOW_NEW_REQUEST_POPUP = 'LTM_SHOW_NEW_REQUEST_POPUP' // when user opens new request popup
export const AI_LTM_SUBMIT_NEW_REQUEST_POPUP = 'LTM_SUBMIT_NEW_REQUEST_POPUP' // when user clicks submit
export const AI_LTM_CLICK_REQUEST_PENDING_ERROR = 'LTM_CLICK_REQUEST_PENDING_ERROR'
// when it shows error that you already have pending LT request
export const AI_LTM_CLICK_REQUEST_CANCEL_PENDING = 'LTM_CLICK_REQUEST_CANCEL_PENDING'
// when user cancels pending LT request
export const AI_LTM_MERCHANT_TAKE_ACTION = 'LTM_MERCHANT_TAKE_ACTION' // when merchant approves / rejects
export const AI_LTM_MERCHANT_VIEW_PENDING = 'LTM_MERCHANT_VIEW_PENDING' // when merchant clicks view pending
export const AI_LTM_MERCHANT_VIEW_HISTORY = 'LTM_MERCHANT_VIEW_HISTORY' // when merchant clicks view history
export const AI_DOWNLOAD_LEAD_TIME_REPORT = 'AI_DOWNLOAD_LEAD_TIME_REPORT' // when merchant clicks view history

/* ===================== SHIP LABEL========================= */
export const AI_FETCH_ORDER_DETAILS = 'AI_FETCH_ORDER_DETAILS' // fetch order details
export const AI_UPDATE_ITEM_DETAILS_CHANGES = 'AI_UPDATE_ITEM_DETAILS_CHANGES'
export const AI_UPDATE_ITEM_DETAILS_CHANGES_FAILED = 'AI_UPDATE_ITEM_DETAILS_CHANGES_FAILED'
export const AI_UPDATE_ITEM_SHIPMENT_QUANTITY = 'AI_UPDATE_ITEM_SHIPMENT_QUANTITY'
export const AI_SET_SHIPMENT_FLAG = 'AI_SET_SHIPMENT_FLAG'
export const AI_GET_SHIPPING_LABEL = 'AI_GET_SHIPPING_LABEL'
export const AI_SHIPMENT_ITEMS = 'AI_SHIPMENT_ITEMS'
export const AI_CANCELED_ORDER_UNITS = 'AI_CANCELED_ORDER_UNITS'

/* ===================== MANTIS LEAD TIME ========================= */
export const AI_FETCH_NETWORK_LEVEL_FLAGS = 'AI_FETCH_NETWORK_LEVEL_FLAGS' // get network level flag
export const AI_SET_NETWORK_LEVEL_FLAG = 'AI_SET_NETWORK_LEVEL_FLAG' // set network level flag
export const AI_GET_SHIP_NODE_LEVEL_FLAG = 'AI_GET_SHIP_NODE_LEVEL_FLAG' // get ship  node level flag
export const AI_SET_SHIP_NODE_LEVEL_FLAG = 'AI_SET_SHIP_NODE_LEVEL_FLAG' // set ship node level flag

/* ===================== Download Reports ========================== */
export const AI_FETCH_DOWNLOAD_REPORT = 'AI_FETCH_DOWNLOAD_REPORT'
export const AI_SEARCH_DOWNLOAD_REPORT = 'AI_SEARCH_DOWNLOAD_REPORT'
export const AI_DOWNLOAD_REPORT_TABLE = 'AI_DOWNLOAD_REPORT_TABLE'
export const AI_DOWNLOAD_REPORT_NOTIFICATION = 'AI_DOWNLOAD_REPORT_NOTIFICATION'
export const AI_DOWNLOAD_REPORT_RETRY = 'AI_DOWNLOAD_REPORT_RETRY'
export const AI_DOWNLOAD_REPORT_RETRY_NOTIFICATION = 'AI_DOWNLOAD_REPORT_RETRY_NOTIFICATION'

/* ===================== SUPPLIER ON BOARDING ========================= */
export const AI_ONBOARD_SUPPLIER_REQUEST = 'AI_ONBOARD_SUPPLIER_REQUEST' // get network level flag

/* ===================== Smart Inventory ========================= */
export const AI_FETCH_SMART_INVENTORY_AGGREGATED = 'AI_FETCH_SMART_INVENTORY_AGGREGATED'
export const AI_FETCH_SMART_INVENTORY_ITEM = 'AI_FETCH_SMART_INVENTORY_ITEM'
export const AI_SMART_INVENTORY_ITEM_SEARCH = 'AI_SMART_INVENTORY_ITEM_SEARCH'
export const AI_SMART_INVENTORY_AGGREGATED_SEARCH = 'AI_SMART_INVENTORY_AGGREGATED_SEARCH'
export const AI_SMART_INVENTORY_DOWNLOAD = 'AI_SMART_INVENTORY_DOWNLOAD'
export const AI_SMART_INVENTORY_UPLOAD = 'AI_SMART_INVENTORY_UPLOAD'
export const AI_SMART_INVENTORY_RESET = 'AI_SMART_INVENTORY_RESET'
export const AI_SMART_INVENTORY_REMOVE_FILTER = 'AI_SMART_INVENTORY_REMOVE_FILTER'
export const AI_SMART_INVENTORY_FETCH_SUPPLIERS = 'AI_SMART_INVENTORY_FETCH_SUPPLIERS'
export const AI_SMART_INVENTORY_FETCH_CATEGORIES = 'AI_SMART_INVENTORY_FETCH_CATEGORIES'
export const AI_SMART_INVENTORY_ACTION = 'AI_SMART_INVENTORY_ACTION'
export const AI_SMART_INVENTORY_FETCH_DIVISIONS = 'AI_SMART_INVENTORY_FETCH_DIVISIONS'
export const AI_SMART_INVENTORY_PAST_TRENDS = 'AI_SMART_INVENTORY_PAST_TRENDS'

/* ===================== Penalty ========================= */
export const AI_PENALTY_SEARCH = 'AI_PENALTY_SEARCH'
export const AI_PENALTY_FETCH = 'AI_PENALTY_FETCH'
export const AI_PENALTY_METRICS = 'AI_PENALTY_METRICS'
export const AI_PENALTY_ORDERS = 'AI_PENALTY_ORDERS'
export const AI_PENALTY_EXCLUDE = 'AI_PENALTY_EXCLUDE'
export const AI_PENALTY_CHARGEBACK = 'AI_PENALTY_CHARGEBACK'
export const AI_PENALTY_APPROVE_REJECT = 'AI_PENALTY_APPROVE_REJECT'
export const AI_PENALTY_HISTORY = 'AI_PENALTY_HISTORY'
export const AI_PENALTY_BULK_UPDATE = 'AI_PENALTY_BULK_UPDATE'

/* ===================== Returns Order ========================= */
export const AI_RETURNS_ORDERS = 'AI_RETURNS_ORDERS'
export const AI_RETURNS_ORDERS_FAILURE = 'AI_RETURNS_ORDERS_FAILURE'
export const AI_RETURNS_ORDERS_ACK_SUCCESS = 'AI_RETURNS_ORDERS_ACK_SUCCESS'
export const AI_RETURNS_ORDERS_ACK_FAILURE = 'AI_RETURNS_ORDERS_ACK_FAILURE'

/* ===================== Supplier Management ========================= */
export const AI_SHIPNODE_DETAILS = 'AI_SHIPNODE_DETAILS'
export const AI_SHIPNODE_CREATE_UPDATE = 'AI_SHIPNODE_CREATE_UPDATE'
export const AI_SHIPNODE_DEACTIVATE = 'AI_SHIPNODE_DEACTIVATE'
export const AI_SHIPNODE_HISTORY = 'AI_SHIPNODE_HISTORY'
export const AI_SHIPNODE_APPROVE_REJECT = 'AI_SHIPNODE_APPROVE_REJECT'
export const AI_SUPPLIER_ROLE_REQUEST = 'AI_SUPPLIER_ROLE_REQUEST'

/* ===================== In Transit Inventory ========================= */
export const AI_UPDATE_IN_TRANSIT_INVENTORY = 'AI_UPDATE_IN_TRANSIT_INVENTORY'
