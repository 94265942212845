import React from 'react'
import { TableBandHeader } from '@devexpress/dx-react-grid-material-ui'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import moment from 'moment'

const styles = (theme => ({
    highlight: {
        backgroundColor: '#6CDFBD !important',
        color: '#323A3E !important'
    }
}))

const BandCell = ({ children, tableRow, tableColumn, column, classes, ...restProps }) => {
    const STYLES = (date) => {
        if (moment(date).isSame(moment(), 'day')) {
            return classes.highlight
        }
        return ''
    }
    return (
        <TableBandHeader.Cell {...restProps} column={column} className={STYLES(children)}>
            {children}
        </TableBandHeader.Cell>
    )
}


const mapStateToProps = state => ({
    selectedDate: state.FCapacity.selectedDate
})

export default connect(mapStateToProps, null)(withStyles(styles)(BandCell))