import { cloneDeep, includes, isArray } from 'lodash'
import { taskStatusParser } from '../../utils/parsers'
import {
  CHANGE_SELECTED_ORDER_TYPE,
  CHANGE_TAB_FULFILLMENT,
  CHECK_PICK_LIST_DL_STATUS_FAILURE,
  CHECK_PICK_LIST_DL_STATUS_REQUEST,
  CHECK_PICK_LIST_DL_STATUS_SUCCESS,
  GET_PICK_LIST_FILE_SUCCESS,
  GET_PICK_LIST_FILE_FAILURE,
  FETCH_CARRIER_METHODS_FAILURE,
  FETCH_CARRIER_METHODS_REQUEST,
  FETCH_CARRIER_METHODS_SUCCESS,
  FETCH_CARRIER_ACCOUNT_FAILURE,
  FETCH_CARRIER_ACCOUNT_REQUEST,
  FETCH_CARRIER_ACCOUNT_SUCCESS,
  FETCH_ORDER_COUNT_SUCCESS,
  INITIATE_PICK_LIST_DL_FAILURE,
  INITIATE_PICK_LIST_DL_REQUEST,
  INITIATE_PICK_LIST_DL_SUCCESS,
  RESET_FULFILLMENT_DATA,
  TOGGLE_CARRIER_REGISTRATION_POPUP,
  MAINTAIN_STATUS_TAB
} from './Fulfillment.types'

export const INITIAL_STATE = {
  carrierMethods: {
    isFetching: false,
    list: [],
    selected: {
      carrierMethodId: null,
      carrierMethodName: null
    },
    shipLabelAccount: {
      showPopup: false,
      isFetching: false,
      accountDetails: []
    },
    supportedCarrierMethods: []
  },
  orderCount: {
    pastDue: null,
    pending: null,
    dueToday: null,
    shipped: null,
    delivered: null,
    cancelled: null,
    activeOrdersCount: null,
    pageLimit: 10000
  },
  selectedTabView: 'Active', // Active or Closed
  selectedOrderType: 'Active', // Active, Pending, Past Due, etc...
  orderDetails: {
    isFetching: false,
    isUpdating: false,
    orderDetailsUpdateStatus: [],
    orderDetailsList: [],
    selectedSoIndex: 0,
    selectedRoIndex: 0,
    showPopUp: false
  },
  selectedPoList: [],
  pickList: {
    isFetching: false,
    ackId: null,
    status: null,
    statusDetails: {},
    poNumbers: []
  },
  maintainStatusTab: false
}

export default (state = cloneDeep(INITIAL_STATE), action) => {
  switch (action.type) {
    case RESET_FULFILLMENT_DATA:
      return {
        ...state,
        selectedPoList: INITIAL_STATE.selectedPoList,
        orderDetails: INITIAL_STATE.orderDetails,
        orderCount: INITIAL_STATE.orderCount
      }

    case FETCH_CARRIER_METHODS_REQUEST:
      return {
        ...state,
        carrierMethods: {
          isFetching: true,
          ...state.carrierMethods
        }
      }

    case FETCH_CARRIER_METHODS_SUCCESS:
      return {
        ...state,
        carrierMethods: {
          ...state.carrierMethods,
          list: action.response.payload.carrierMethods
        }
      }

    case FETCH_CARRIER_METHODS_FAILURE:
      return {
        ...state,
        carrierMethods: {
          isFetching: false,
          ...state.carrierMethods
        }
      }

    case FETCH_CARRIER_ACCOUNT_REQUEST:
      return {
        ...state,
        carrierMethods: {
          ...state.carrierMethods,
          shipLabelAccount: {
            ...INITIAL_STATE.carrierMethods.shipLabelAccount,
            isFetching: true
          }
        }
      }

    case FETCH_CARRIER_ACCOUNT_SUCCESS:
      return {
        ...state,
        carrierMethods: {
          ...state.carrierMethods,
          shipLabelAccount: {
            accountDetails: isArray(action.response.payload.vendorCarrierAccountDetails)
              ? action.response.payload.vendorCarrierAccountDetails
              : [],
            isFetching: false
          },
          supportedCarrierMethods: isArray(action.response.payload.supportedCarrierDetails)
            ? action.response.payload.supportedCarrierDetails
            : []
        }
      }

    case FETCH_CARRIER_ACCOUNT_FAILURE:
      return {
        ...state,
        carrierMethods: {
          ...state.carrierMethods,
          shipLabelAccount: {
            ...INITIAL_STATE.carrierMethods.shipLabelAccount
          },
          supportedCarrierMethods: [...INITIAL_STATE.carrierMethods.supportedCarrierMethods]
        }
      }

    case TOGGLE_CARRIER_REGISTRATION_POPUP:
      return {
        ...state,
        carrierMethods: {
          ...state.carrierMethods,
          shipLabelAccount: {
            ...state.carrierMethods.shipLabelAccount,
            showPopup: !state.carrierMethods.shipLabelAccount.showPopup
          }
        }
      }

    case FETCH_ORDER_COUNT_SUCCESS: {
      const { key } = action.payload
      return {
        ...state,
        orderCount: {
          ...state.orderCount,
          [key]: action.response.payload.poCount
        }
      }
    }

    case CHANGE_SELECTED_ORDER_TYPE:
      return {
        ...state,
        selectedOrderType: action.payload.orderType,
        orderRows: cloneDeep(INITIAL_STATE.orderRows)
      }

    case CHANGE_TAB_FULFILLMENT:
      return {
        ...state,
        selectedTabView: action.payload.tabView
      }

    case INITIATE_PICK_LIST_DL_REQUEST:
      return {
        ...state,
        pickList: {
          ...INITIAL_STATE.pickList,
          isFetching: true
        }
      }

    case INITIATE_PICK_LIST_DL_SUCCESS:
      return {
        ...state,
        pickList: {
          ...state.pickList,
          isFetching: true,
          ackId: action.response.payload.ackId,
          status: action.response.payload.status
        }
      }

    case INITIATE_PICK_LIST_DL_FAILURE:
    case CHECK_PICK_LIST_DL_STATUS_FAILURE: // merge both condition return same code
      return {
        ...state,
        pickList: {
          ...state.pickList,
          isFetching: false,
          status: 'FAILED',
          statusDetails: {}
        }
      }

    case CHECK_PICK_LIST_DL_STATUS_REQUEST:
      return { ...state }

    case CHECK_PICK_LIST_DL_STATUS_SUCCESS: {
      return {
        ...state,
        pickList: {
          ...state.pickList,
          isFetching: includes(['INPROGRESS', 'INITIATED', 'COMPLETED'], action.response.payload.status),
          ...taskStatusParser(action.response.payload)
        }
      }
    }

    case GET_PICK_LIST_FILE_SUCCESS:
    case GET_PICK_LIST_FILE_FAILURE:
      return {
        ...state,
        pickList: {
          ...state.pickList,
          isFetching: false,
          status: 'FAILED'
        }
      }

    case MAINTAIN_STATUS_TAB:
      return {
        ...state,
        maintainStatusTab: action.isMaintain
      }

    default:
      return { ...state }
  }
}
