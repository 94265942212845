import { Box, Button } from '@mui/material'
import { withStyles } from '@mui/styles'
import clsx from 'clsx'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

export const INVENTORY_TABS = {
  ACTIVE: 'Active'
}

const styles = theme => ({
  button: {
    marginRight: theme.spacing(2.5),
    color: theme.palette.primary.blue,
    fontWeight: 400,
    fontSize: theme.spacing(1.75),
    paddingBottom: theme.spacing(0.2),
    paddingLeft: 0,
    paddingRight: 0,
    display: 'block',
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    }
  },
  active: {
    color: theme.palette.primary.blue,
    borderBottom: `2px solid ${theme.palette.primary.blue}`
  },
  container: {
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.primary.headerBackground,
    marginBottom: '2px'
  }
})

const InventoryTabSwitcher = ({ classes, isFetching, setActiveTab, selectedTab }) => {
  const BTN_STYLES = tabView =>
    clsx(classes.button, {
      [classes.active]: tabView === selectedTab
    })

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="flex-start" className={classes.container}>
      <Button
        variant="text"
        className={BTN_STYLES(INVENTORY_TABS.ACTIVE)}
        disabled={isFetching}
        onClick={() => setActiveTab({ tabName: INVENTORY_TABS.ACTIVE })}>
        Active
      </Button>
    </Box>
  )
}

export const InventoryTabSwitcherNaked = InventoryTabSwitcher

InventoryTabSwitcher.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  selectedTab: PropTypes.func.isRequired
}

export default connect(null, null)(withStyles(styles)(InventoryTabSwitcher))
