export const FILTER_IDs = ["vendor_#", "state", "cat_desc"]

export const PRICING_SERVICES_TABS = [
    { name: "QDs", value: "price", filters: FILTER_IDs },
    { name: "Deals", value: "deals", filters: [FILTER_IDs[0], FILTER_IDs[1]] },
    { name: "RIPs", value: "rips", filters: [FILTER_IDs[0], FILTER_IDs[2]] }
]

export const FILTER_LABELS = {
    CAT_DESC: 'Category',
    STATE: 'State',
    VENDOR_NO: 'Supplier Id'
}

export const DOWNLOAD_SUCCESS_MSG = 'Pricing services downloaded successfully.'
export const DOWNLOAD_FAILURE_MSG = 'Pricing services download has failed. Please try again later.'
export const DOWNLOAD_MSG = 'Download the pricing services'
