export const FEEDSTATUS_ITEM_LISTS_REQUEST = 'FEEDSTATUS_ITEM_LISTS_REQUEST'
export const FEEDSTATUS_ITEM_LISTS_SUCCESS = 'FEEDSTATUS_ITEM_LISTS_SUCCESS'
export const FEEDSTATUS_ITEM_LISTS_FAILURE = 'FEEDSTATUS_ITEM_LISTS_FAILURE'
export const FEEDSTATUS_ITEM_LISTS_DOWNLOAD_REQUEST = 'FEEDSTATUS_ITEM_LISTS_DOWNLOAD_REQUEST'
export const FEEDSTATUS_ITEM_LISTS_DOWNLOAD_SUCCESS = 'FEEDSTATUS_ITEM_LISTS_DOWNLOAD_SUCCESS'
export const FEEDSTATUS_ITEM_LISTS_DOWNLOAD_FAILURE = 'FEEDSTATUS_ITEM_LISTS_DOWNLOAD_FAILURE'
export const FEEDSTATUS_ITEM_LISTS_UPLOAD_REQUEST = 'FEEDSTATUS_ITEM_LISTS_UPLOAD_REQUEST'
export const FEEDSTATUS_ITEM_LISTS_UPLOAD_SUCCESS = 'FEEDSTATUS_ITEM_LISTS_UPLOAD_SUCCESS'
export const FEEDSTATUS_ITEM_LISTS_UPLOAD_FAILURE = 'FEEDSTATUS_ITEM_LISTS_UPLOAD_FAILURE'
export const FEEDSTATUS_ITEM_SEARCH_REQUEST = 'FEEDSTATUS_ITEM_SEARCH_REQUEST'
export const FEEDSTATUS_ITEM_SEARCH_SUCCESS = 'FEEDSTATUS_ITEM_SEARCH_SUCCESS'
export const FEEDSTATUS_ITEM_SEARCH_FAILURE = 'FEEDSTATUS_ITEM_SEARCH_FAILURE'
export const FEEDSTATUS_ITEM_UPDATE_REQUEST = 'FEEDSTATUS_ITEM_UPDATE_REQUEST'
export const FEEDSTATUS_ITEM_UPDATE_SUCCESS = 'FEEDSTATUS_ITEM_UPDATE_SUCCESS'
export const FEEDSTATUS_ITEM_UPDATE_FAILURE = 'FEEDSTATUS_ITEM_UPDATE_FAILURE'
export const FEEDSTATUS_CLEAR_ERROR = 'FEEDSTATUS_CLEAR_ERROR'
export const FEEDSTATUS_ITEM_LISTS_FILE_DOWNLOAD_REQUEST = 'FEEDSTATUS_ITEM_LISTS_DOWNLOAD_REQUEST'
export const FEEDSTATUS_ITEM_LISTS_FILE_DOWNLOAD_SUCCESS = 'FEEDSTATUS_ITEM_LISTS_FILE_DOWNLOAD_SUCCESS'
export const FEEDSTATUS_ITEM_LISTS_FILE_DOWNLOAD_FAILURE = 'FEEDSTATUS_ITEM_LISTS_FILE_DOWNLOAD_FAILURE'
export const FEEDSTATUS_RESET_DATA = 'FEEDSTATUS_RESET_DATA'
export const FEEDSTATUS_ERROR = 'FEEDSTATUS_ERROR'
export const FEEDSTATUS_EMPTY_ERROR = 'FEEDSTATUS_EMPTY_ERROR'
export const FEEDSTATUS_ITEM_LISTS_SUCCESS_EMPTY = 'FEEDSTATUS_ITEM_LISTS_SUCCESS_EMPTY'
export const FEEDSTATUS_NOSEARCH = 'FEEDSTATUS_NOSEARCH'
export const FEEDSTATUS_DOWANLOAD_SUCCESS = 'FEEDSTATUS_DOWANLOAD_SUCCESS'
export const FEEDSTATUS_DOWANLOAD_STATUS = 'FEEDSTATUS_DOWANLOAD_STATUS'
export const FEEDSTATUS_DOWANLOAD_FAILURE = 'FEEDSTATUS_DOWANLOAD_FAILURE'
export const FEEDSTATUS_RESET_UPLOAD_STATUS = 'FEEDSTATUS_RESET_UPLOAD_STATUS'
export const FEEDSTATUS_CLEAR_FEED_DATA = 'FEEDSTATUS_CLEAR_FEED_DATA'

export const SET_FEED_ROW_COUNT = 'SET_FEED_ROW_COUNT'

export const GET_TOTAL_FEED_COUNT_REQUEST = 'GET_TOTAL_FEED_COUNT_REQUEST'
export const GET_TOTAL_FEED_COUNT_SUCCESS = 'GET_TOTAL_FEED_COUNT_SUCCESS'
export const GET_TOTAL_FEED_COUNT_FAILURE = 'GET_TOTAL_FEED_COUNT_FAILURE'

export const INITIATE_ERROR_FILE_DOWNLOAD_REQUEST = 'INITIATE_ERROR_FILE_DOWNLOAD_REQUEST'
export const INITIATE_ERROR_FILE_DOWNLOAD_SUCCESS = 'INITIATE_ERROR_FILE_DOWNLOAD_SUCCESS'
export const INITIATE_ERROR_FILE_DOWNLOAD_FAILURE = 'INITIATE_ERROR_FILE_DOWNLOAD_FAILURE'

export const DOWNLOAD_ERROR_FILE_REQUEST = 'DOWNLOAD_ERROR_FILE_REQUEST'
export const DOWNLOAD_ERROR_FILE_SUCCESS = 'DOWNLOAD_ERROR_FILE_SUCCESS'
export const DOWNLOAD_ERROR_FILE_FAILURE = 'DOWNLOAD_ERROR_FILE_FAILURE'

export const REMOVE_ERROR_DOWNLOAD_FILE = 'REMOVE_ERROR_DOWNLOAD_FILE'
export const CLEAR_ERROR_DOWNLOAD_FILES = 'CLEAR_ERROR_DOWNLOAD_FILES'

export const SEARCH_FEED_ITEM_REQUEST = 'SEARCH_FEED_ITEM_REQUEST'
export const RESET_FEED_ITEM_SEARCH = 'RESET_FEED_ITEM_SEARCH'
