import { Grid, Typography, Alert } from '@mui/material'
import { isEmpty, get } from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SSHModal from '../../../../components/SSHModal'
import SSHSelectBox from '../../../../components/SSHSelectBox'
import { CARRIER_ACCOUNT_REGISTRATION_FIELDS, AUTO_LABEL_CARRIER_ACCOUNT } from '../../../../config/genericConstants'
import PopupActions from '../../../../templates/PopupActions'
import { validateEmail } from '../../../../utils/common'
import { FETCH_CARRIER_ACCOUNT_SUCCESS } from '../../../FulfillmentNew/Fulfillment.types'
import {
  toggleCarrierRegistrationPopup,
  updateCarrierAccount,
  fetchCarrierAccount
} from '../../../FulfillmentNew/FulfillmentAction'
import FieldComponent from './FieldComponent'
import { APP_CONFIG } from '../../../../config/appConfigs'

class CarrierRegistration extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isUpdated: false,
      errors: {},
      errorStrings: []
    }
    this.inputRef = React.createRef()
    this.fields = [...CARRIER_ACCOUNT_REGISTRATION_FIELDS]
    this.mandatoryFields = [...CARRIER_ACCOUNT_REGISTRATION_FIELDS.filter(field => field.mandatory).map(f => f.name)]
    this.defaultValues = {
      accountNumber: props.carrierAccountId
    }
  }

  validateRequest = () => {
    this.setState({ errors: {}, errorStrings: [], isUpdated: false })
    const requestParams = {}
    const errors = {}
    const errorStrings = []
    let isAnyFieldEmpty = false
    this.inputRef.current.querySelectorAll('input').forEach(field => {
      if (field.name === 'corporateEmailAddress' && !validateEmail(field.value)) {
        errorStrings.push('Invalid email address')
        errors[field.name] = true
      }
      if (this.mandatoryFields.indexOf(field.name) && isEmpty(field.value)) {
        errors[field.name] = true
        isAnyFieldEmpty = isAnyFieldEmpty || true
      }
      requestParams[field.name] = field.value
    })
    if (isAnyFieldEmpty) errorStrings.push('All fields are mandatory')

    if (!isEmpty(errors)) {
      this.setState({ errors, errorStrings })
    } else {
      this.fields
        .filter(field => !!field.mapFrom)
        .forEach(field => {
          requestParams[field.name] = requestParams[field.mapFrom]
        })

      requestParams['carriers'] = String(APP_CONFIG.ENV().carriers)
      this.submitToAPI(requestParams)
    }
  }

  submitToAPI = requestParams => {
    const { updateCarrierAccount, fetchCarrierAccount } = this.props
    this.setState({ isLoading: true })
    updateCarrierAccount(requestParams)
      .then(({ type }) => {
        if (type === FETCH_CARRIER_ACCOUNT_SUCCESS) {
          this.setState({ isUpdated: true })
          fetchCarrierAccount()
        }
      })
      .catch(error => {
        this.setState({
          errors: { apiFailed: true },
          errorStrings: [
            (error.message || 'An error occurred.').concat(
              ' Please try again with correct information, or raise a ticket.'
            )
          ]
        })
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  render = () => {
    const { toggleCarrierRegistrationPopup } = this.props
    const { errors, errorStrings, isLoading, isUpdated } = this.state

    return (
      <SSHModal
        title="Register a new carrier"
        size="md"
        onClose={toggleCarrierRegistrationPopup}
        showDivider={false}
        actions={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <PopupActions
            cancelText={isUpdated ? 'Go back' : 'Cancel'}
            confirmText={isUpdated ? 'Re-register' : 'Register'}
            onCancel={toggleCarrierRegistrationPopup}
            onConfirm={this.validateRequest}
            disabled={isLoading}
            isLoading={isLoading}
            alignRight={true}
          />
        }>
        <Grid container spacing={2} justifyContent="flex-start" alignItems='center' ref={this.inputRef} direction='row'>
          <Grid item xs={2} >
            <Typography variant='span'>
              Carrier name <Typography variant="caption" color='error'>*</Typography> :
            </Typography>
          </Grid>
          <Grid item xs={4} >
            <SSHSelectBox
              // label="Carrier"
              name="carriers"
              size="small"
              options={[{ name: 'FedEx', value: AUTO_LABEL_CARRIER_ACCOUNT }]}
              defaultVal={{ name: 'FedEx', value: AUTO_LABEL_CARRIER_ACCOUNT }}
              readOnly
              disabled
            />
            {this.fields
              .filter(field => !!field.defaultValue)
              .map(field => (
                <input type="hidden" value={field.defaultValue} name={field.name} />
              ))}
          </Grid>
          {this.fields
            .filter(field => !field.hidden)
            .map(field => (
              <>
                <Grid item xs={2} >
                  <Typography variant='body2'>
                    {field.label} <Typography variant="caption" color='error'>*</Typography> :
                  </Typography>
                </Grid>
                <Grid item xs={4} >
                  <FieldComponent
                    {...field}
                    defaultValues={this.defaultValues}
                    errors={errors}
                    inputRef={this.inputRef.current}
                  />
                </Grid>
              </>
            ))}
          <Grid item xs={12}>
            {isUpdated && (
              <Alert severity="success">Carrier registration completed. You can go back to shipping items.</Alert>
            )}
            {!isEmpty(errorStrings) && (
              <Alert severity="error" icon={false}>
                {errorStrings.map(str => (
                  <Typography variant='body2'>
                    {str}
                  </Typography>
                ))}
              </Alert>
            )}
          </Grid>
        </Grid>
      </SSHModal>
    )
  }
}

export const CarrierRegistrationNaked = CarrierRegistration

CarrierRegistration.propTypes = {
  carrierAccountId: PropTypes.string,
  updateCarrierAccount: PropTypes.func.isRequired,
  toggleCarrierRegistrationPopup: PropTypes.func.isRequired
}

CarrierRegistration.defaultProps = {
  carrierAccountId: null
}

const mapStateToProps = state => ({
  carrierAccountId: get(
    state.FulfillmentNew,
    'carrierMethods.shipLabelAccount.accountDetails[0].carrierAccountId',
    null
  )
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ toggleCarrierRegistrationPopup, updateCarrierAccount, fetchCarrierAccount }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(CarrierRegistration)
