import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { find, isEmpty } from 'lodash'
import AsyncSearchBox from '../../components/AsyncSearchBox'
import { searchVendor, selectVendor, clearSearchList, clearShipNode } from '../../reduxSetup/commonActions/AppConfig'
import PartnerSelect from './PartnerSelect'

const VendorSearch = ({ isFetching, vendorList, selectedVendor, selectVendor, ...restProps }) => {
  const { isVendor, clearSearchList, clearShipNode, searchVendor, size, scopes, currentPath } = restProps
  if (isVendor && !isEmpty(vendorList) && !!selectedVendor.vendorId) {
    return (
      <PartnerSelect
        vendorList={vendorList}
        selectedVendor={selectedVendor}
        selectVendor={selectVendor}
        size={size}
        searchVendor={searchVendor}
      />
    )
  }

  return (
    <AsyncSearchBox
      disabled={currentPath === '/reports' && find(scopes, { selected: true })?.value === 'all'}
      isFetching={isFetching}
      size={size}
      asyncSearch={searchVendor}
      options={vendorList}
      selectedOption={selectedVendor}
      selectOption={selectVendor}
      onClose={clearSearchList}
      selectorType="supplier"
      clearShipNode={clearShipNode}
    />
  )
}

VendorSearch.propTypes = {
  size: PropTypes.string,
  isFetching: PropTypes.bool,
  vendorList: PropTypes.arrayOf(PropTypes.shape({ vendorId: PropTypes.string, displayName: PropTypes.string })),
  selectedVendor: PropTypes.object,
  isVendor: PropTypes.bool,
  clearSearchList: PropTypes.func.isRequired,
  searchVendor: PropTypes.func.isRequired,
  selectVendor: PropTypes.func.isRequired
}

VendorSearch.defaultProps = {
  size: 'small',
  isVendor: false,
  isFetching: false,
  vendorList: [],
  selectedVendor: {}
}

export const VendorSearchNaked = VendorSearch

const mapStateToProps = state => ({
  isFetching: state.AppConfig.vendor.isFetching,
  vendorList: state.AppConfig.vendor.vendorList,
  selectedVendor: state.AppConfig.vendor.selectedVendor,
  isVendor: state.UserInfo.isVendor,
  scopes: state.PredefinedReports.scopes,
  currentPath: state.router.location.pathname
})
const mapDispatchToProps = dispatch =>
  bindActionCreators({ searchVendor, selectVendor, clearSearchList, clearShipNode }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(VendorSearch)
