/* eslint-disable no-case-declarations */
import { cloneDeep, get, isEmpty, filter } from 'lodash'
import {
  APPROVE_REJECT_LEAD_TIME_FAILURE,
  APPROVE_REJECT_LEAD_TIME_REQUEST,
  APPROVE_REJECT_LEAD_TIME_SUCCESS,
  CLEAR_CURRENT_LEAD_TIME,
  FETCH_CURRENT_LEAD_TIME_FAILURE,
  FETCH_CURRENT_LEAD_TIME_REQUEST,
  FETCH_CURRENT_LEAD_TIME_SUCCESS,
  FETCH_REQUEST_LEAD_TIME_FAILURE,
  FETCH_REQUEST_LEAD_TIME_REQUEST,
  FETCH_REQUEST_LEAD_TIME_SUCCESS,
  SAVE_LEAD_TIME_FAILURE,
  SAVE_LEAD_TIME_REQUEST,
  SAVE_LEAD_TIME_SUCCESS,
  TOGGLE_REQUEST_BOX,
  TOGGLE_PENDING_BOX,
  CANCELLED_LEAD_TIME_REQUEST,
  CANCELLED_LEAD_TIME_SUCCESS,
  CANCELLED_LEAD_TIME_FAILURE,
  MARK_TASK_AS_CANCELED_STORE,
  RESET_LEAD_TIME_HISTORY,
  SET_LEAD_TIME_COUNT,
  SET_REPORT_DATE,
  SET_LOADING_FLAG
} from './LeadTime.actionTypes'

export const INITIAL_STATE = {
  showRequestBox: false,
  showPendingBox: false,
  isSaving: false,
  currentLeadTime: [],
  requestHistory: [],
  selectedShipNode: null,
  selectedShipNodeName: null,
  pendingRequests: [],
  completedRequests: [],
  isCancel: false,
  noOfDays: 0,
  isFetching: [],
  totalRows: 0,
  selectedDate: {
    fromDate: '',
    toDate: ''
  },
  lTOrCapLoading: false
}

/**
 * @description get number of days requested new lead time
 * @param {object} redux store
 * @param {string} shipNodeId
 * @return {number} noOfDays
 */
const getNoOfDays = (state, shipNodeId) => {
  const noOfDays =
    !state.showPendingBox && filter(state.requestHistory, { shipNodeId, status: 'pending' })[0].newLeadTime
  if (!noOfDays) return 0

  return noOfDays
}

/**
 * @descripion toggel request box
 * @param {redux store *} state
 * @returns {redux store}
 */
const toggleRequestBox = (state, action) => {
  return {
    ...state,
    showRequestBox: !state.showRequestBox,
    selectedShipNode: !state.showRequestBox ? action.payload.shipNodeId : null,
    selectedShipNodeName: !state.showRequestBox ? action.payload.shipNodeName : null
  }
}

export default (state = cloneDeep(INITIAL_STATE), action) => {
  switch (action.type) {
    case TOGGLE_REQUEST_BOX:
      return toggleRequestBox(state, action)
    case FETCH_CURRENT_LEAD_TIME_REQUEST:
      return {
        ...state
      }
    case FETCH_CURRENT_LEAD_TIME_SUCCESS:

      const { payload: {
        currentLeadTime, shipNodeId
      } } = action.response
      const { payload: {
        shipNodeName
      } } = action
      const temp = {
        currentLeadTime,
        shipNodeId,
        shipNodeName
      }

      return {
        ...state,
        currentLeadTime: [...state.currentLeadTime, temp]
      }
    case FETCH_CURRENT_LEAD_TIME_FAILURE:
      return {
        ...state,
        currentLeadTime: ['fail']
      }
    case FETCH_REQUEST_LEAD_TIME_REQUEST:
      return {
        ...state,
        isFetching: [
          ...state.isFetching,
          {
            isLoading: true,
            shipNodeId: action.payload.shipNodeId
          }
        ]
      }
    case FETCH_REQUEST_LEAD_TIME_SUCCESS: {
      const fetching = state.isFetching.find(item => item.shipNodeId === action.payload.shipNodeId)
      if (!isEmpty(fetching)) {
        fetching.isLoading = false
      }

      return {
        ...state,
        requestHistory: !isEmpty(action.response.payload.tasks)
          ? [...state.requestHistory, ...action.response.payload.tasks]
          : [...state.requestHistory, 'no records'],
        isFetching: [...state.isFetching]
      }
    }
    case FETCH_REQUEST_LEAD_TIME_FAILURE: {
      const failRecords = state.isFetching.find(item => item.shipNodeId === action.payload.shipNodeId)
      if (!isEmpty(failRecords)) {
        failRecords.isLoading = false
      }

      return {
        ...state,
        requestHistory: [...state.requestHistory, 'fail'],
        isFetching: [...state.isFetching]
      }
    }
    case CLEAR_CURRENT_LEAD_TIME: {
      return {
        ...state,
        currentLeadTime: [],
        requestHistory: [],
        isFetching: []
      }
    }
    case RESET_LEAD_TIME_HISTORY: {
      const filterHistory = state.requestHistory.filter(item => {
        return item.shipNodeId !== action.shipNode
      })
      const newLeadTime = state.currentLeadTime.filter(time => {
        return time.shipNodeId !== action.shipNode
      })
      const newRecords = state.isFetching.filter(item => {
        return item.shipNodeId !== action.shipNode
      })

      return {
        ...state,
        requestHistory: [...filterHistory],
        currentLeadTime: [...newLeadTime],
        isFetching: newRecords
      }
    }
    case SAVE_LEAD_TIME_REQUEST:
      return {
        ...state,
        isSaving: true
      }
    case SAVE_LEAD_TIME_SUCCESS:
      return {
        ...state,
        isSaving: false,
        showRequestBox: false
      }

    case SAVE_LEAD_TIME_FAILURE:
      return {
        ...state,
        isSaving: false
      }

    case APPROVE_REJECT_LEAD_TIME_REQUEST:
    case APPROVE_REJECT_LEAD_TIME_SUCCESS:
      return { ...state }

    case APPROVE_REJECT_LEAD_TIME_FAILURE:
      return { ...state }

    case TOGGLE_PENDING_BOX: {
      const shipNodeId = get(action, 'payload.shipNodeId', null)
      const noOfDays = getNoOfDays(state, shipNodeId)
      return {
        ...state,
        showRequestBox: false,
        selectedShipNode: shipNodeId,
        showPendingBox: !state.showPendingBox,
        noOfDays
      }
    }
    case CANCELLED_LEAD_TIME_REQUEST:
      return {
        ...state,
        isCancel: true
      }
    case CANCELLED_LEAD_TIME_SUCCESS: {
      return {
        ...state,
        isCancel: false,
        showPendingBox: !state.showPendingBox
      }
    }

    case CANCELLED_LEAD_TIME_FAILURE:
      return {
        ...state,
        isCancel: false
      }

    case MARK_TASK_AS_CANCELED_STORE: {
      const history = state.requestHistory
      const task = history.find(
        taskCancel => taskCancel.shipNodeId === action.shipNodeId && taskCancel.status === 'pending'
      )
      task.status = 'canceled'

      return {
        ...state,
        requestHistory: [...history]
      }
    }
    case SET_LEAD_TIME_COUNT:
      return {
        ...state,
        count: action.payload.count
      }
    case SET_REPORT_DATE:
      const { dates } = action.payload
      return {
        ...state,
        selectedDate: {
          fromDate: dates.fromDate,
          toDate: dates.toDate
        }
      }

    case SET_LOADING_FLAG: {
      const { payload } = action
      return {
        ...state,
        lTOrCapLoading: payload
      }
    }

    default:
      return { ...state }
  }
}
