import React from 'react'
import { Box } from '@mui/material'
import { withStyles } from '@mui/styles'
import {
  FeedbackLoader,
  StyleRoot,
  FeedbackLanguage,
  FeedbackEnvironment,
  AuthType
} from '@atmt/feedback-component-web'
import { APP_CONFIG } from '../../config/appConfigs'
import SSHModal from '../../components/SSHModal'
import packageJson from '../../../package.json'

const styles = theme => ({
  root: { position: 'relative' }
})

const PROD = 'PROD'

const ATMTFeedback = ({ classes, toggleFeedback, isToggleAtmtFeedback, loginId, iamToken }) => {
  if (!isToggleAtmtFeedback) {
    return null
  }
  return (
    <SSHModal open={isToggleAtmtFeedback} onClose={toggleFeedback} size="md" title="" showDivider={false}>
      <Box className={classes.root}>
        <StyleRoot>
          <FeedbackLoader
            appId={APP_CONFIG.ENV().atmtAppId}
            environment={
              String(process.env.REACT_APP_ENV).toString() === PROD
                ? FeedbackEnvironment.PROD_TORBIT
                : FeedbackEnvironment.STG_TORBIT
            }
            baseUrl={
              String(process.env.REACT_APP_ENV).toString() === PROD
                ? FeedbackEnvironment.PROD_TORBIT
                : FeedbackEnvironment.STG_TORBIT
            }
            countryCode={FeedbackLanguage.ENGLISH}
            version={packageJson.version}
            userId={loginId}
            authentication={{
              type: AuthType.iam,
              config: {
                token: iamToken,
                consumerId: process.env.REACT_APP_CONSUMER_ID
              }
            }}
            disableAnnouncements
          />
        </StyleRoot>
      </Box>
    </SSHModal>
  )
}

export default withStyles(styles)(ATMTFeedback)
